var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor main-editor" },
    [
      _c("transition", { attrs: { name: "transition" } }, [
        _vm.currentEditor
          ? _c(
              "div",
              { staticClass: "editor", class: _vm.transition },
              [
                _vm.currentEditor.value.type != "object"
                  ? _c("schematic", {
                      staticClass: "scene",
                      attrs: { "app-state": _vm.appState, target: _vm.target }
                    })
                  : _vm._e(),
                _vm.currentEditor.value.type == "object" &&
                _vm.currentEditor.custom.tag == "ui"
                  ? _c("uieditor", {
                      staticClass: "scene",
                      attrs: { "app-state": _vm.appState, target: _vm.target }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      _c("bloc-menu", {
        attrs: { menu: _vm.appState.editor.menu, target: _vm.currentEditor }
      }),
      _vm.appState.editor.menu.dragging
        ? _c("dragged-item", { attrs: { "app-state": _vm.appState } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }