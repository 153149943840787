import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class AddToHistory extends ActionAbstract {

    static get ID() {
        return 'actions:main:addToHistory';
    }

    run(state) {
        
        if( !state.breadcrumb || !state.breadcrumb.history )
            return;

        let crtHistory = ( state.breadcrumb && state.breadcrumb.crtHistory ) ? crtHistory : 0 ;

        return this.composeState( state, {
            breadcrumb : {
                crtHistory : 0,
                history : this.addHistory( state.breadcrumb.history, crtHistory, this.getParam('id') )
            }
        })
        
    }

    addHistory( history, crtHistory, newItem ){
        if( crtHistory > 0 )
			history.splice(0, crtHistory);

		history.unshift( newItem );
        
        return history
    }

}

export default AddToHistory;