<!-- Field vue -->

<template lang="pug">
div.array-inspector
	div.array-inspector-cover(v-on:click.stop="close()")
	div.array-window
		div.array-centerer
			div.array-ctn

				//- div.cover(v-bind:class="{'open':pickerOpen}", v-on:click.stop="clickCoverHandler()")

				div.array-header
					table(cellpadding="0", cellspacing="0")
						tr
							td(v-for="(col,i) in localHeader", v-bind:class="col.widget")
								input.invisible(v-on:change="triggerColUpdates()", v-model="col.name", v-bind:placeholder="'column '+i")
								span
									.select-arrow
								select(v-on:change="typeUpdated(i)", v-model="col.widget", :disabled="!!inspector.field.pattern")
									option(value="int") int
									option(value="float") float
									option(value="string") string
									option(value="boolean") boolean
									option(value="percent") percent
									option(value="angle") angle
									option(value="color") color
									option(value="vector2") vector2
									option(value="vector3") vector3
									option(value="asset") asset
									option(value="object") object
									option(value="jsontype") JSONType
									option(value="geo") Geo Point
									option(value="geoshape") Geo Shape
									option(value="shape2d") 2D Shape
								span.action-col.bts-ctn(v-if="!inspector.field.pattern")
									div.action-bt.bt-insert(v-on:click.stop="insertColBefore(i)")
										span +
									div.action-bt.bt-trash.bt-trash-white(v-on:click.stop="deleteCol(i)")

					div.bt-more(v-on:click.stop="addCol()", v-if="!inspector.field.pattern")
						span +
				div

				div.array-data
					table(cellpadding="0", cellspacing="0")
						tr(v-for="(row,i) in values")
							td.column {{ i }}
								span.action-col.bts-ctn
									div.action-bt.bt-insert(v-on:click.stop="insertRowBefore(i)")
										span +
									div.action-bt.bt-trash.bt-trash-white(v-on:click.stop="deleteRow(i)")

							td(v-for="(head,col) in localHeader", v-bind:class="[head.widget, {'setted':row[col] != null}]")

								// BOOLEAN
								boolean-field(v-if="head.widget=='boolean'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]")

								// STRING
								string-field(v-if="head.widget=='string'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]")

								// JSON
								json-field(v-if="head.widget=='jsontype'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]")

								// *shape
								string-field(v-if="head.widget=='geoshape'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]", :placeholder="'{GeoJSON}'")
								string-field(v-if="head.widget=='shape2d'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]", :placeholder="'[[x,y],[x,y], … [x,y]]'")

								// FLOAT
								float-field(v-if="head.widget=='float'", :field="createFakeField(head, i, col)",  :fieldvalue="values[i][col]")

								// INT
								int-field(v-if="head.widget=='int'", :field="createFakeField(head, i, col)",  :fieldvalue="values[i][col]")

								// PERCENT
								percent-field(v-if="head.widget=='percent'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]")

								// ANGLE
								angle-field(v-if="head.widget=='angle'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]")

								// COLOR
								color-field.color(v-if="head.widget=='color'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]" )

								// VECTOR 2
								vector2d.vector2(v-if="head.widget=='vector2'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]" )

								// VECTOR 3
								vector3d.vector3(v-if="head.widget=='vector3'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]" )

								// ASSET
								asset-field(v-if="head.widget=='asset'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]", :bloc="target", :parentField="inspector.field", :library="library")

								// OBJECT
								object-field(v-if="head.widget=='object'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]", :bloc="target")

								// GEO
								geo-field(v-if="head.widget=='geo'", :field="createFakeField(head, i, col)", :fieldvalue="values[i][col]", :geomodalState="geomodal" )

					div.bt-more(v-on:click.stop="addRow()")
						span +
</template>


<script>

import actionManager from '../../actions';
import * as helper from '../../helper';

import { stores } from '../../stores/MainStore.js';
import FloatField from './fields/FloatField.vue';
import IntField from './fields/IntField.vue';
import PercentField from './fields/PercentField.vue';
import StringField from './fields/StringField.vue';
import JSONField from './fields/JSONField.vue';
import ColorField from './fields/ColorField.vue';
import BooleanField from './fields/BooleanField.vue';
import AssetField from './fields/AssetField.vue';
import AngleField from './fields/AngleField.vue';
import Vector2DField from './fields/Vector2DField.vue';
import Vector3DField from './fields/Vector3DField.vue';
import ObjectField from './fields/ObjectField.vue';
import GeoField from "./fields/GeoField.vue";

// Float, Int, Percent, String, Asset, Color, Boolean

export default {

	props:['project', 'inspector', 'library', 'geomodal'],

	components: { FloatField, IntField, PercentField, StringField, JsonField: JSONField, ColorField, BooleanField, AssetField, AngleField, vector2d: Vector2DField, vector3d: Vector3DField, ObjectField, GeoField },

	data() {
		return {
			values: [],
			pickerOpen: false,
			localHeader: [],
			//valuesCopy: [],
		}
	},
	watch: {
		values: function(newval, oldval){
		},
		selectedAsset: function(newval, oldval) {
			this.getFieldValue()
		},
	},
	computed: {
		selectedAsset: function(){
			return this.library.assetSelected
		},
		targetId: function () {
			return this.inspector.targetId;
		},
		target: function() {
			let mem = helper.block.getEntityByIDInWholeProject(this.project, this.targetId);
			return mem;
		},
		fieldValue: function () {
			return this.getFieldValue()
		},
		header: function () {
			return this.fieldValue.header || [];
		},
		pattern: function () {
			return this.fieldValue.pattern;
		}
	},

	created: function(){
		this.values = this.fieldValue.value;
	},

	methods:{
		getFieldValue: function() {

			let f = helper.block.getField(this.target, this.inspector.field.name);
			if( (f === null ) || ( !f.header && !this.inspector.field.pattern)  ) {
				f = {
					header: [ { name: "", widget: "int" } ], // default header
					value: [ [ { value: null } ] ]
				}
			}

			if (this.inspector.field.pattern) {
				f.header = [];
				f.value = f.value !== null ? f.value : [];
				f.pattern = this.inspector.field.pattern;
			}

			this.values = f.value ? f.value : [];

			this.generateLocalHeaders(f.pattern, f.header);
			return f;
		},
		generateLocalHeaders: function (pattern, header) {
			let localHeader = [];

			if( pattern && pattern.length !== 0 && header && header.length === 0 ) {
				// Si un pattern est défini
				pattern.forEach( (col) => {
					localHeader.push( col );
				})
			} else {
				if( header.length == 0) {
					let nbColDefault = 1;
					for( let i = 0 ; i < nbColDefault ; i++ ){
						localHeader.push( { name : '' } );
					}
				} else {
					for( let i = 0 ; i < header.length ; i++ ){
						localHeader.push( header[i] );
					}
				}
			}

			// check integrity
			if(Array.isArray(this.values)){
				this.values.forEach( (rowValues) => {
					if (rowValues.length > localHeader.length) {
						rowValues.splice(rowValues.length - localHeader.length);
					} else {
						for(let i=0; i< localHeader.length - rowValues.length; i++) {
							rowValues.push(this.createValue(null));
						}
					}
				});
			}
			this.localHeader = localHeader;
			this.headerUpdated();
		},

		addRow: function(){
			this.values.push( [] );
			for (let i = 0; i < this.localHeader.length; i++) {
				this.values[this.values.length-1].push( this.createValue(null) );
			}

			this.valueUpdated();

		},

		addCol: function(){
			this.localHeader.push({name:'', widget:'int'});

			for( let i = 0 ; i < this.values.length ; i++ ){
				this.values[i].push( this.createValue(null) );
			}

			this.headerUpdated();
		},

		insertRowBefore: function(row){
			this.values.splice(row, 0, []);
			for (let i = 0; i < this.localHeader.length; i++) {
				this.values[row].push( this.createValue(null) );
			}
			this.valueUpdated();
		},

		insertColBefore: function(col){
			this.localHeader.splice(col, 0, {name:'', widget:'int'});
			for( let i = 0 ; i < this.values.length ; i++ ){
				this.values[i].splice(col, 0, this.createValue(null) );
			}
			this.headerUpdated();
		},

		deleteRow: function( row ){
			this.values.splice(row, 1);
			this.valueUpdated();
		},

		deleteCol: function( col ){
			this.localHeader.splice(col, 1);
			for( let i = 0 ; i < this.values.length ; i++ ){
				if(  this.values[i] ){
					this.values[i].splice(col, 1);
				}
			}

			this.headerUpdated();
		},

		triggerColUpdates: function (){
			this.headerUpdated();
		},

		close: function(){
			actionManager.trigger('main:toggleArrayInspector', { open: false });
		},

		valueUpdated: function ( field, newValue ){
			if (field) {
				let valuesCopy = [].concat(this.values)
				valuesCopy[field.rowIndex][field.colIndex] = this.createValue(newValue);
				this.values = [].concat(valuesCopy)
			}
			actionManager.trigger('inspector:updateArray', { subaction: 'arrayValues', fieldName: this.inspector.field.name, targetId: this.targetId, newValues: this.values });
		},

		headerUpdated: function () {
			actionManager.trigger('inspector:updateArray', { subaction: 'arrayHeaders', fieldName: this.inspector.field.name, targetId: this.targetId, newHeaders: this.localHeader });
			this.valueUpdated(false);
		},
		createFakeField( head, rowIndex, colIndex ){
			const colName = this.localHeader[colIndex].name ? this.localHeader[colIndex].name : "column "+colIndex;
			const field = {
				widget: head.widget,
				rowIndex: rowIndex,
				colIndex: colIndex,
				name: "array-asset-"+colIndex+"-"+rowIndex,
				label : colName+'-'+rowIndex
			};

			// specify the target field for template instance
			if (this.target.value.type==='template-process') field.target = this.inspector.field.name;
			// add asset filter
			if (head.assetType) field.assetType = head.assetType;

			return field;
		},

		createValue (val) {
			return { value: val }
		},

		typeUpdated( colIndex ){
			this.values.forEach( (row) =>{
				row[colIndex] = this.createValue(null);
			});

			this.headerUpdated();
		},
	}

}

</script>

<style lang="stylus">

.array-inspector, .array-inspector-cover
	display: block
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: 90
	background-color: var(--modalTransparentBgColor)

.array-window
	display: block
	position: fixed
	top: 0px
	left: 40px
	right: 40px
	bottom: 40px
	background-color: var(--modalColor)
	z-index: 91
	box-shadow: 0px 0px 10px var(shadowColor)
	border-radius: 0 0 20px 20px
	overflow: auto

span.action-col.bts-ctn
	display: block
	position: absolute
	background-color: var(--bgColor)
	border-radius: 28px
	opacity: 0
	visibility: hidden
	height: 28px
	width: 54px
	box-shadow: 0 0 10px var(--shadowColor)

	.action-bt
		display: inline-block
		vertical-align: middle
		position: relative
		width: 20px
		height: 20px
		margin: 3px
		background-color: #939498
		border-radius: 20px

		&.bt-insert
			position: absolute
			top: 1px
			left: 2px
			color: white
			font-size: 20px
			line-height: 20px
			height: 0
			text-align: center
			font-weight: bold
			background: var(--breadColor)
			height: 20px
			width: 20px

			&:hover
				cursor: pointer
				background: #707070

		&.bt-trash
			background: var(--breadColor) !important
			height: 20px
			width: 20px
			position: absolute !important
			top: 1px
			right: 1px
			background-image: url(../../assets/images/btnDeleteOver.png) !important
			background-size: 16px !important
			background-repeat: no-repeat !important
			background-position: center !important
			transition: linear all .1s !important

		&.bt-trash:hover
			cursor: pointer
			background: #707070 !important
			background-image: url(../../assets/images/btnDeleteOver.png) !important
			background-size: 16px!important
			background-position: center!important;
			transition: linear all .1s !important

.array-centerer
	display: table
	vertical-align: middle
	text-align: center
	width: 100%
	height: 100%
	position relative

	table tr td.geo
	table tr td.geo .object-fields-ctn
	table tr td.vector2
	table tr td.vector3
	.array-ctn .array-data td .textinput-ctn.geo
	.array-ctn .array-data td .textinput-ctn.vector2 
	.array-ctn .array-data td .textinput-ctn.vector3
		width: 228px !important

	input
		text-align: center
		width: 116px

	table
		border-spacing: 0
		border-collapse: collapse
		table-layout:fixed
		border-spacing: 5px!important
		border-collapse: separate
		border-radius: 4px

		tr
			border-radius: 4px
			td
				width: 120px
				height: 40px
				position: relative
				border-radius: 4px

				&.object
					width 120px !important

				&:hover
					span.action-col.bts-ctn
						opacity: 1
						visibility: visible

				.object-fields-ctn
					border-top: none
					padding-top: 0px
					margin-top: 0px
					padding-bottom: 0px
					width: 120px

				&.geo
					.object-fields-ctn
						.field-wrapper
							.input-fields.groupfield
								margin-left: 36px
							.map-modal-icon
								right: 196px !important
								top: 15px !important
							.geomodal
								margin-left: -510px !important
								margin-top: -170px !important

	.bt-more
		display: block
		width: 30px
		height: 30px
		border-radius: 30px
		line-height: 30px
		vertical-align: middle
		color: var(--inputTextColor)
		text-align: center
		cursor: pointer
		margin-left: 10px
		background: var(--bgColor)
		font-size: 16px !important
		font-family: 'DinBold'

		position: absolute

		span
			display: inline-block
			line-height: 15px
			font-size: 15px

	.array-ctn
		display: table-cell
		vertical-align: middle
		text-align: center
		padding: 100px
		padding-bottom: 50px

		// .cover
		// 	position: absolute
		// 	z-index: 500
		// 	top: 0
		// 	right: 0
		// 	left: 0
		// 	bottom: 0
		// 	background-color: #000
		// 	display: none
		// 	opacity: 0

		// 	&.open
		// 		display: block

		.array-header
			position: relative
			display: inline-block
			margin-left: 55px
			margin-bottom: -11px;

			td
				background-color: var(--bgColor)
				font-size: 14px
				padding-top: 5px

				&:before
					content:''
					position: absolute
					width: 100%
					height: 40px
					top: -40px
					left: 0

				span.action-col.bts-ctn
					top: -35px
					left: 50%
					transform: translateX(-50%)

				select
					background-color: var(--mainTitleColor)
					color: var(--inspectorText)
					width: 110px
					margin: 0px 0px 5px 0px
					border-radius: 32px
					font-size: 14px
					font-family: 'DinRegular'
					text-align: center
					height: 30px
					line-height: 32px

			.bt-more
				right: -30px
				top: 3px

		.array-data
			position: relative
			display: inline-block

			.bt-more
				bottom: -30px
				left: 5px

			tr:nth-child(even)
				background: var(--arrayColumnEvenColor)

			tr:nth-child(odd)
				background: var(--arrayColumnOddColor)

			td.column
				border: none
				width: 50px
				position: relative
				font-size: 14px
				font-family: 'DinBold'
				color: var(--inputTextColor)
				text-align: center
				margin: 0
				border-radius: 2px
				line-height: 54px

				&:after
					content: ''
					position: absolute
					width: 10px
					height: 100%
					top: 0
					right: 0

				&:before
					content:''
					position: absolute
					width: 40px
					height: 100%
					left: -40px
					top: 0

				span.action-col.bts-ctn
					top: 50%
					left: -60px
					transform: translateY(-50%)


			td
				font-size: 10px

				.textinput-ctn
					width 120px!important
					height 100%
					padding-left: 5px

					input.value
						//height: 100%
						border: none
						font-size 14px
						margin: 5px
						margin-top: 5px
						//width: calc(100% - 20px)
						width: calc(100% - 20px)
						text-align: left
						padding-left: 5px
						padding-right: 5px
						height: 38px
						font-family: 'DinRegular'
						color: #ffffff
						background: var(--inputValue)
						outline: none!important

					input.value.vector2D.x
						margin-top: 5px !important
						margin-right: 0px
						height: 40px !important
						font-size: 14px
						padding: 0px 5px
						float: left !important
						outline: none!important

					input.value.vector2D.y
						height: 38px !important
						font-size: 14px
						madding: 0px 5px
						outline: none!important

					&.has-unit
						.unit
							position absolute
							//top 50%
							top: 0
							height: 40px
							line-height 40px
							right 0
							//transform translateY(-50%)
							font-size: 14px
							font-family: 'DinRegular'
							color: #ffffff
							background: var(--inputValue)
							//margin-right: 5px
							border-radius: 4px
							width: 20px
							text-align: left
						input.value
							padding-right 0
							width 70px
							outline: none!important

					&.color
						input.value
							width 66px

						.color-square
							vertical-align middle
							position absolute
							top 50%
							right 0
							transform translateY(-50%)

						/*.color-picker
							top -95px*/

					&.geo
						input.value
							width: 35% !important
							margin-right: 0px !important
							margin-left: 3px !important
							
					&.vector2
						input.value
							width: 42% !important

					&.vector3
						input.value
							width: 26% !important
							padding: 0 5px
							margin: 5px 0px 0px 5px
							height: 40px

				.textinput-ctn.color
					.color-square
						width: 30px
						height: 40px
						padding: 0 !important
						vertical-align: middle
						position: absolute
						top: 20px
						right: 4px
						transform: translateY(-50%)
						.color
							width: 30px
							height: 40px
							top: 0px

			td.boolean.setted
				height: 40px
				line-height: 35px
				span.checkbox
					margin-top: 5px !important
					width: 75px!important
					margin-left: 45px

			td.object.setted
				.object-fields-ctn
					.field-ctn
						margin-top: 1px !important
						span.text
							height: 0px !important
							color: transparent
						.input-fields.groupfield
							/*background: #f8c5a6*/
							height: 15px
							border-radius: 4px
							width: 110px!important
							.object.textinput.ctn
								width: 110px !important
								height: 15px
							.select-arrow /////////////
								height: 15px
								width: 15px
								margin-top: -1px
								margin-right: 4px
								/*background-image: url(../../assets/images/arrowDownWhite.png)*/
								background-size: 8px
								background-repeat: no-repeat
								background-position: center

							select
								margin:0
								padding: 0
								line-height: 10px
								/*color: white !important*/
								margin-top: -2px!important
								font-family: 'DinBold'
								font-size: 12px
								height: 19px !important
								width: 110px !important
								padding: 0 5px
								float: left
								background: var(--inputValue)

			td.asset.setted, td.jsontype.setted
				.textinput-ctn
					.edit-bt
						background: var(--bgColor)
						height: 40px
						margin-top: 5px
						margin-left: 5px
						width: 110px
						border-radius: 4px
						span.label
							margin-top: 0 !important
				.file-action-bts
					width: auto
					height: 46px
					height: 40px
					position: absolute
					top: 10px
					right: 5px
					left: auto
					.action-bt.bt-view
						background: #ffffff
						border: 0
						height: 20px
						width: 20px
						margin-top: 5px
						background-image: url(../../assets/images/btnView.png)
						background-size: 16px
						background-repeat: no-repeat
						background-position: center
						opacity: .3
						transition: all .3s .3s ease
					.action-bt.bt-delete
						background: #ffffff
						border: 0
						height: 20px
						width: 20px
						margin-top: 5px
						background-image: url(../../assets/images/btnDelete.png)
						background-size: 16px
						background-repeat: no-repeat
						background-position: center
						opacity: .3
						transition: all .3s .3s ease
					.action-bt.bt-view:hover
						opacity: 1
						transition: all .3s .3s ease
					.action-bt.bt-delete:hover
						opacity: 1
						transition: all .3s .3s ease

				.textinput-ctn
					padding-bottom: 0

.array-centerer
	.array-ctn
		.array-header
			td
				.select-arrow
					margin-top: 25px
					margin-right: 7px
					width: 8px
		.array-data 
			td.asset.setted, td.jsontype.setted
				.textinput-ctn .edit-bt span.label
					font-family: 'DinBold'
					height: 40px
					line-height: 40px
					color: var(--inspectorText)
		.array-data td.object.setted .object-fields-ctn .field-ctn
			margin-top: 6px !important
			height: 21px!important
			background: transparent!important

</style>
