import ActionAbstract from '../ActionAbstract';

class Open extends ActionAbstract {

    static get ID() {
        return 'actions:template:open';
    }

    run(state) {
        
        this.trigger('template:listAll');
        this.trigger('main:toggleTemplate', { open: true });

        return this.composeState(state, {
            templates: {
                types: this.deps.mainStore.template.types,
                waiting: false
            }
        });
    }

}

export default Open;