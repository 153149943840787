
<template lang="pug">

div#content
    h1 Node Experience

    h2 Presentation
    p An experience is an app that organizes the user experience. It contains a series of screens linked by conditions and settings shared between screens.<br /> An experience has a target that defines on what it is displayed (Mobile, Tablet, Desktop, ...).<br /> The authoring interface adapts according to the target (available modules).

    h2 Parameters
    table#params
        tr
            td#col1 <strong>Name</strong>
            td#col2 String
        tr
            td#col1 <strong>Owner</strong> 
            td#col2 User who create the project
        tr
            td#col1 <strong>User(s)</strong>
            td#col2 List of users who can access the experience with specific rights (view only, edit, admin)

    h2 Actions
    table#params
        tr
            td#col1 <strong>Delete</strong>
            td#col2 Delete the experience
        tr
            td#col1 <strong>Duplicate</strong>
            td#col2 Create a copy of the experience
        tr
            td#col1 <strong>Share</strong>
            td#col2 Share the experience with specific rights (view only, edit, admin)
        tr
            td#col1 <strong>Export</strong>
            td#col2 Export the experience in JSON

    h2 Settings
    h3 Geo Beacon Settings
    p Not documented yet.

    h3 BLE Beacon Settings
    p Not documented yet.

    h3 Targets
    p An experience has to be deployed for at least one target.<br /> The authoring interface adapts according to the target (available modules).<br /> Different targets are available :
        ul.listing
            li Mobile
            li Tablet 
            li Desktop
            //li IOT (Soon)
            //li Light (Soon)

    h3 Home Screen
    p A screen has to be selected as the default screen to be opened when the experience is launched.

    h3 Memory
    p A shared memory is accessible to all the users of the project.<br /> It has different types :
        ul.listing
            li Angle
            li Array (A specific interface allows to parameter the array
            li Asset (It can be chosen via the Library)
            li Boolean
            li Color
            li Float
            li Integer
            li Percent
            li String
            li Vector
    p A persistent memory is kept from one session to another.

    div#back
        a(v-on:click.stop="displayTab('nodes')") &larr; Back


    p <br /><br /><br />

</template>

<script>

	export default {


		props:['displayTab'],

		data() {
			return {
			};
		},

	    methods:{


	    }

    }

</script>

<!-- FIXME: use pure CSS style in webpack.config.js (see below) -->
<style lang="stylus">
.listing
    margin-left: 20px;
</style>


<!-- ORIGINAL CSS
<style>

.listing{
    margin-left: 20px;
}
</style>
-->