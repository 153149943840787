<!--
    Search bar component

    @desc: Search field component
    @event:
        change   emit the current search tag
-->
<template lang="pug">
div.searchbar
    icon.search(name="search")
    input#searchIN(type="search" v-model="search" :placeholder="placeholder")
    icon.close(v-if="tag", name="search-close", v-on:click.native="clear")
</template>

<script>

import Icon from  './Icon.vue';
import actionManager from '../../actions';

export default {

    components: { Icon },
    props: {
        projects: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Search project',
        }
    },
    data() {
        return {
            search: '',
            tag: ''
        }
    },

    watch: {

        search: function(value) {
            // Search for projects if props project is true. [Projects Page]
            if (this.projects) {
                if (value) actionManager.trigger('projects:setFilter', { filterValue: `search_${value}`});
				else actionManager.trigger('projects:setFilter', { filterValue: `all`});
            }
            this.$emit("change", value)
            this.tag = value
        }
    },

    methods: {
        clear: function() {
            this.tag = ''
            this.search = ''
        }
    }
}

</script>
<style>
#searchIN::-webkit-search-cancel-button{
    display: none;
    right:0px;   
}
</style>

<style lang="stylus">
/* SearchBar */

.searchbar
    display: inline-flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    background: white
    border-radius: 4px
    padding-left: 8px
    height: 26px
    width: 180px
    margin-right: 11px

    input
        border: none
        width: 125px
        height: 100%
        padding: 0
        line-height: 18px
        font-size: 14px
        font-family: 'dinBold'
        color: var(--textColor)
        border-radius: 4px
        margin-left: 10px

    .icon
        width: 14px
        height: 14px
        background-color: var(--textColor)
        margin-top: 14px
        display: inline-block
        position: relative
        margin-right: 6px
        &.close:hover
            opacity: 0.5
            cursor: pointer
</style>
