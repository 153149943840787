/*
    Template Store instance

    @desc: All function to get information or create and manage Templates
*/

import { EventEmitter } from 'events'
import Vue from 'vue'
import VueResource from 'vue-resource'
import * as helper from '../helper'

Vue.use(VueResource)

class TemplateStore extends EventEmitter {
    constructor() {
        super()

        this.types = [
            'screen',
            'widget',
            'process'
        ]
    }

    setMain(main) {
        this.main = main
    }

    /**
     * List all templates async.
     * @returns Promise
     */
    listTemplates() {
        return new Promise((resolve, reject) => {
            let url = (this.main.config.baseURLAuth + "_design/lists/_view/template-list?keys=[\""
                    + encodeURIComponent(this.main.state.user)
                    + "\",\"public\"]")

            Vue.http.get(url).then(
                response => resolve(response.body.rows),
                error => reject(error))
        })
    }

    /**
     * Retrieve async all informations about each template specified in the given list.
     * @param {Array} list - The list of template ID
     * @returns Promise
     */
    fetchTemplates(list) {
        return new Promise((resolve) => {

            let promises  = []                   // the fetch promise list
            let templates = {
                valid: { keys: [], docs: [] },  // valid template store by ID and DB content
                unvalid: []                     // unvalid or missing template
            }

            // fecth each template
            // and store the returned promise
            list.forEach(id => {
                promises.push(this.getTemplate(id).then(
                    response => {
                        templates.valid.keys.push(id)
                        templates.valid.docs.push(response)
                    },
                    error => {
                        error.id = id
                        templates.unvalid.push(error)
                    }
                ))
            })

            // wait for all promises to be resolved to output the result
            Promise.all(promises).then(() => {
                resolve(templates)
            })
        })
    }

    /**
     * Read all documents, add requirements and organize all by types.
     * @param {string} docs - The template documents in a single JSON.
     * @returns {Object} All template documents in a single Object
     */
    parse(docs) {
        let objs = []

        objs["module"] = []
        objs["template"] = []

        docs.forEach(data => {
            if (!data.doc.type) return

            let doc = data.doc

            if (!doc.fields) doc.fields = []
            if (doc.type == "trigger" && !doc.events) doc.events = []
            if (doc.type == "trigger" && !doc.states) doc.states = []

            doc = { value: Object.assign({}, doc), custom: { children: [], objects: [] } }

            if (data.doc.type == "object") {
                let tag = data.doc.format.substr(0, data.doc.format.indexOf('-'))
                doc.custom.tag = tag
            }

            if (data.doc.level && data.doc.level == "template") {
                objs["template"].push(doc)
                return
            }

            if (data.doc.ref_process && data.doc.type != "memory") {
                objs["module"].push(doc)
                return
            }

            if (!objs[data.doc.type]) objs[data.doc.type] = []
            objs[data.doc.type].push(doc)

        })

        // update media list
        //this.main.lib.addMedia(objs['media'] || [])
        return objs
    }

    /**
     * Organize all given objects to work in the authoring structure.
     * @param {Object} objs - The template documents as objects.
     * @returns {Object} The final template tree.
     */
    organize(objs) {

        if ( !objs ) return null

        let template = objs['template'][0]
        if (!template) return null // should never occur

        this.main.project.divideIntoParents(objs['process'],  objs['module'], 'ref_process', 'children')
        this.main.project.divideIntoParents(objs['template'], objs['process'], 'ref_screen', 'children')
        this.main.project.divideIntoParents(objs['template'], objs['trigger'], 'ref_screen', 'children')
        this.main.project.divideIntoParents(objs['template'], objs['module'], 'ref_process', 'children')
        this.main.project.divideIntoParents(objs['template'], objs['process'], 'ref_widget', 'children')
        this.main.project.divideIntoParents(objs['template'], objs['trigger'], 'ref_widget', 'children')


        this.main.project.divideSubObjectsIntoUiScreen(objs['object'])
        let mainobjs = []
        if (objs['object'] && objs['object'].length > 0) {
            mainobjs = objs['object'].filter((obj) => {
                if (!obj.custom.subobj)
                    return obj
                return null
            })
        }
        this.main.project.divideIntoParents(objs['template'], mainobjs, 'ref_block', 'objects')


        template.media = [] // !important: should be a list of assets ID. The media object is stored in the library
        template.memory = objs['memory'] || []
        //template.templates = objs['template'] ? objs['template'] : [] // embed template in template ?


        // register assets in library and reference in template
        if (objs['media']) {
            objs['media'].forEach((media) => {
                let doc = this.main.lib.processMediaDocFromCouch(media.value)
                this.main.lib.addMedia(doc)
                template.media.push(doc._id)
            })
        }


        return template
    }

    /**
     * List all documents in the specified template and organize all to works in the authoring structure.
     * @param {string} id - The template identifer.
     * @returns Promise
     */
    getTemplate(id) {
        return new Promise((resolve, reject) => {
            let url = this.main.config.bdd + 'nodal-template-' + id + '/_design/lists/_view/all-list'

            Vue.http.get(url, { params: { include_docs: true } }).then(
                response => {

                    let objs = this.parse(response.body.rows)
                    let template = this.organize(objs)

                    if (template && this.main.authoring.getAuthoringConfig()) // Get authoring config for safe
                        resolve(template)
                    else reject({ code: -1, message: "Error while parsing template data" })
                },
                error => reject({ code: error.status || -1, message: error.statusText||"Error while fetching template document" })
            )
        })
    }

    /**
     * Define the CoucheDB based document.
     * @param {string} type - The document type.
     * @param {string} name - The document name.
     * @returns {Object} The based document.
     */
    createNewDoc(type,name) {
        let time = (new Date()).getTime()
        let doc = {
            "level": "template",
            "type": type,
            "name": name,
            "created": time,
            "updated": time
        }

        return doc
    }

    /**
     * Define the CoucheDB document reference.
     * The date property means the last saved date or updated.
     * @param {Object} doc - Some document properties.
     * @param {string} user - The user name.
     * @returns {Object} The document reference.
     */
    createNewRef(doc, user) {
        return {
            "_id": "ref_" + doc._id,
            "template": doc._id,
            "type": doc.type,
            "name": doc.name,
            "date": doc.updated,
            "owner": user,
            "users": [],
            "public": false
        }
    }

    /**
     * Create a new template database in couchDB with the specified ID.
     * @param {string} id — The template ID.
     * @returns Promise
     */
    createDB(id) {
        return new Promise((resolve, reject) => {
            let url = this.main.config.previewURL + 'authoring/createdb/nodal-template-' + id
            Vue.http.get(url).then(
                response => {
                    if (response.body.error === null) resolve(response.body)
                    else reject( { message: response.body.error })
                },
                error => reject( {code: error.status, message: error.statusText} )
            )
        })
    }

    /**
     * Delete the specified template database.
     * @param {string} id — The template ID.
     * @returns Promise
     */
    deleteDB(id) {
        return new Promise((resolve, reject) => {
            let url = this.main.config.previewURL + 'authoring/destroydb/nodal-template-' + id
            Vue.http.get(url).then(
                response => resolve(response),
                error => reject({ code: error.status, message: error.statusText })
            )
        })
    }

    /**
     * Update and deploy the current node to list all documents to be saved or deleted.
     * @param {*} node - The node to be parsed.
     * @param {Array} tosave - The list of all documents to be saved or updated.
     * @param {Array} todelete - The list of id for all document to be deleted.
     */
    parseNodeBeforeUpdate(node, tosave, todelete) {
        // mark now
        node.value.updated = (new Date()).getTime()

        // add all values to be saved or updated (execpt duplicate) even if the document must be deleted
        // see CouchDB _bulk_docs API for more explaination
        const exists = tosave.find((doc) => doc._id === node.value._id);
        if (!exists) tosave.push(JSON.parse(JSON.stringify(node.value)));

        // mark to be deleted
        if (node.value._deleted) todelete.push(node.value._id)

        // include all memories
        if (node.memory) {
            for (let memory of node.memory) {
                this.parseNodeBeforeUpdate(memory, tosave, todelete)
            }
        }

        // include all childrens
        if (node.custom && node.custom.children && node.custom.children.length > 0) {
            for (let subnode of node.custom.children) {
                this.parseNodeBeforeUpdate(subnode, tosave, todelete)
            }
        }

        // include all objets
        if (node.custom && node.custom.objects && node.custom.objects.length > 0) {
            for (let object of node.custom.objects) {
                this.parseNodeBeforeUpdate(object, tosave, todelete)
            }
        }
    }

    /**
     * Update the document reference in the authoring database with the given data.
     * @param {Object} id - The template ID.
     * @param {Object} data - The new data.
     */
    updateTemplateReference(id, data) {

        let url = this.main.config.baseURLAuth + '_design/update/_update/inplace/ref_' + id
        Vue.http.post(url, data).then(
            response => {},
            error => {
                // @todo: implement notification
                //this.trigger('notification', { message: "Failed to update the template document reference.", type: 'error' })
            }
        )
    }

    /**
     * Save the one or more documents in the specified template database.
     * @param {Object} id - The template tree.
     * @param {Object} node - The node the be saved.
     * @param {boolean} partial - save the entire template or only some of those documents.
     * @returns Promise
     */
    saveTemplate(template, node, partial) {
        const id = template.value._id
        const name = template.value.name
        let tosave   = []
        let todelete = []

        // parse the current node to build the lists
        this.parseNodeBeforeUpdate(node, tosave, todelete)

        // update media data (for old docs) and add media to bulk save
        template.media.forEach((mid) => {
            let media = this.main.lib.findById(mid);
            if (media.length) {
                let doc = media[0].doc;
                // update old doc to include missing props
                // @todo: code below will be dead in one or two years,
                // when all old projects are saved again
                media = helper.media.updateDocument(doc, id, this.main.state.user);
                // add to bulk save
                if (media.mediaAddedFrom === id) {
                    if (media._deleted) todelete.push(media._id);
                    tosave.push(doc);
                }
            }
        })

        // update document reference
        if (partial) this.updateTemplateReference(id,{ updated: node.value.updated })
        else this.updateTemplateReference(id,{ name: node.value['name'], updated: node.value.updated })

        // save
        return new Promise((resolve, reject) => {
            let url  = this.main.config.bdd + 'nodal-template-' + id + '/_bulk_docs'
            Vue.http.post(url,{ docs: tosave }).then(
                response => {
                    // update rev
                    // @todo: do we need to update revisions while there are not used ?
                    // @todo:fixme: getEntityByIDInProject is marked deprecated, use helper instead ?
                    for (let doc of response.body) {
                        // notify errors
                        if (doc.error) {
                            let bloc = this.main.block.getEntityByIDInWholeProject(doc.id)
                            const bname = bloc ? (bloc.value ? bloc.value.name : bloc.filename) : 'unknown'
                            const btype = bloc ? (bloc.value ? bloc.value.type : bloc.type) : 'unknown'
                            const msg = `Error while saving template '${name}' [${id}], ${btype} '${bname}' [${doc.id}]: ${doc.reason}`
                            // @todo: remove warning for media while the ticket NS-472 is fully treated
                            if (btype === 'media') console.warn('notification', { type: 'warning', message: msg })
                            else console.error('notification', { type: 'error', message: msg })
                            // @todo: uncomments while conflicts can be treated
                            // // stop on error
                            // reject({
                            //     code: -1,
                            //     message: `Error while saving template '${name}' [${id}], ${btype} '${bname}' [${doc.id}]: ${doc.reason}`
                            // })
                        }

                        let entity = this.main.block.getEntityByIDInProject(node, doc.id)
                        if (entity) entity.value._rev = doc.rev
                        else {
                            let memory = helper.block.getMemoryById(node, doc.id)
                            if (memory) memory.value._rev = doc.rev
                            else {
                                let media = this.main.lib.getLoadedMediaByID(doc.id)
                                if (media) media._rev = doc.rev
                                else console.warn(`Warning: failed to find component while updating rev for document[${doc.id}] in template '${name}' [${id}]`)
                            }
                        }
                    }

                    // clean node
                    if (todelete.length > 0) helper.block.cleanNode(node, todelete)

                    resolve(true)
                },
                error => reject({
                    code: error.status || -1,
                    message: error.statusText || `Error while saving template '${name}' documents`
                })
            )
        })
    }
}

export let template = new TemplateStore()
