import TextUiObject from '../TextUiObject';

/**
 * The ChooseFile UI is a wrapper for the HTML <input> tag with the type "file".
 * This UI is used as a way to select and import one or multiple files in runtime.
 * Depending on the device (desktop, android or ios) and the selected parameters,
 * it will either open a file selector, the default camera or the default dictaphone.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
 */
class UiChooseFile extends TextUiObject {
    static getAuthConfig() {
        return {
            exportFormat: 'ui-choose-file',
            label: 'Choose File',
            parent: 'ui-inputs',
            isDisplayed: true,
            isEmbeddable: true,
        };
    }

    /**
     * ChooseFile constructor.
     *
     * @param {string} id - The UI object identifier.
     * @param {Array} props - All UI object parameters.
     */
    constructor(id, props) {
        super(id, props, 'ChooseFile');
    }

    initParameters() {
        super.initParameters();

        // Default style
        this.setDefaultValue('innerText', 'Choose File');
        this.setDefaultValue('height', '36px');
        this.setDefaultValue('fontSize', '14');
        this.setDefaultValue('lineHeight', '36');
        this.setDefaultValue('textAlign', 'center');

        // Can't have a child
        this.setParamInvisible('addChild');
        this.setParamInvisible('removeChild');
        this.setParamInvisible('removeAllChildren');

        this.updateParamConnexion('innerText', 'in', { pluggable: true, default: false });

        const chooseFileSettings = this.createInspectorNode('topic', 'settings', 'Choose File Settings');

        const dataGroup = this.createInspectorNode('group', 'data', 'Data');
        dataGroup.children.push(this.createInspectorNode('group-field', 'group-field-data'));

        const eventsGroup = this.createInspectorNode('group', 'events', 'Events');
        eventsGroup.children.push(this.createInspectorNode('group-field', 'group-field-events'));

        chooseFileSettings.children = [dataGroup, eventsGroup];

        this.inspector.unshift(chooseFileSettings);

        this.addToParameters({
            mode: {
                type: 'String',
                connection: null,
                default: 'file',
                auth: {
                    label: 'Mode',
                    container: 'settings',
                    widget: 'select',
                    options: [
                        { label: 'Open File Selector', value: 'file' },
                        { label: 'Open Camera', value: 'camera' },
                        { label: 'Open Dictaphone', value: 'dictaphone' },
                    ],
                    description: 'Choose whether to open the file explorer, the camera or the dictaphone. Note that on desktop, as there is no default camera application or dictaphone, it will probably open the file explorer on all modes.',
                },
            },
            allowMultiple: {
                type: 'Boolean',
                connection: null,
                default: false,
                auth: {
                    label: 'Allow multiple files',
                    container: 'settings',
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                    description: 'Allows users to select multiple files.',
                },
            },
            fileType: {
                type: 'String',
                connection: null,
                default: 'any',
                auth: {
                    label: 'Accepted File Type',
                    container: 'settings',
                    widget: 'select',
                    options: [
                        { label: 'Any', value: 'any' },
                        { label: 'Image', value: 'image' },
                        { label: 'Video', value: 'video' },
                        { label: 'Audio', value: 'audio' },
                        { label: 'JSON', value: 'json' },
                    ],
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                    description: 'Adds a restriction on the type of file that can be imported by the user.',
                },
            },
            cameraType: {
                type: 'String',
                connection: null,
                default: 'any',
                auth: {
                    label: 'Accepted File Type',
                    container: 'settings',
                    widget: 'select',
                    options: [
                        { label: 'Any', value: 'any' },
                        { label: 'Photo', value: 'image' },
                        { label: 'Video', value: 'video' },
                    ],
                    conditions: [{ field: 'mode', operator: '==', value: 'camera' }],
                    description: 'Adds a restriction on the type of file that can be imported by the user.',
                },
            },
            cameraDefaultOrientation: {
                type: 'String',
                connection: null,
                default: 'environment',
                auth: {
                    label: 'Default Camera Orientation',
                    container: 'settings',
                    widget: 'select',
                    options: [
                        { label: 'Rear', value: 'environment' },
                        { label: 'Front', value: 'user' },
                    ],
                    conditions: [{ field: 'mode', operator: '==', value: 'camera' }],
                    description: 'Specifies which camera or microphone (front/user or rear/environment) should be used when capturing audio and/or video.',
                },
            },
            objectURL: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: true },
                },
                auth: {
                    deprecated: true,
                    container: 'group-field-data',
                    label: 'File URL',
                    widget: 'calculated',
                    conditions: [{ field: 'allowMultiple', operator: '==', value: false }],
                    description: 'Outputs a local URL for the selected or captured file.',
                },
            },
            blob: {
                type: 'Blob',
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: true },
                },
                auth: {
                    container: 'group-field-data',
                    label: 'File',
                    widget: 'calculated',
                    conditions: [{ field: 'allowMultiple', operator: '==', value: false }],
                    description: 'Outputs the selected or captured file.',
                },
            },
            multipleObjectURLs: {
                type: 'JSONType',
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: true },
                },
                auth: {
                    container: 'group-field-data',
                    label: 'Files URLs',
                    widget: 'calculated',
                    conditions: [{ field: 'allowMultiple', operator: '==', value: true }],
                    description: 'Outputs a JSON Array containing all the local URLs for the selected files.',
                },
            },
            multipleBlobs: {
                type: 'JSONType',
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: true },
                },
                auth: {
                    container: 'group-field-data',
                    label: 'Files',
                    widget: 'calculated',
                    conditions: [{ field: 'allowMultiple', operator: '==', value: true }],
                    description: 'Outputs a JSON Array containing all the selected files.',
                },
            },
            error: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    container: 'group-field-events',
                    label: 'Error',
                    widget: 'calculated',
                    description: 'Outputs a message when an error occurred.',
                },
            },
        });
    }
}

export default UiChooseFile;
