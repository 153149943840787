
import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';
import Vue from 'vue';
import store from 'store';

class UpdateCustomPreview extends ActionAbstract {

    /**
     * @param id 
     * @param customPreview 
     */
    static get ID() {
        return 'actions:editor:ui:update-custom-preview';
    }

    run(state) {
        
        let customPreview = store.get('nodal-customPreview');

        if( !customPreview )
            customPreview = {};

        customPreview[this.getParam('id')] = this.getParam('customPreview'); 

        store.set('nodal-customPreview', customPreview );
        
        return null;
        
    }


}

export default UpdateCustomPreview;