import AdvancedUiObject from '../AdvancedUiObject';

class UiImage extends AdvancedUiObject {
    constructor(id, props) {
        super(id, props, 'Image');


    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-image",
            label: "Image",
            parent : "ui-elements",
            isDisplayed : true,
            isEmbeddable: true
        };
    }


    
    initParameters(){
        super.initParameters();
        
        let specific = this.createInspectorNode('group', 'specifics', 'Image style' );   
        specific.children.push( this.createInspectorNode('group-field', 'specifics-fields') );

        let imageTopic = this.createInspectorNode( 'topic', 'specStyles', 'Specific styles' );
        imageTopic.children = [ specific ];

        this.inspector.unshift( imageTopic );


        let otherParameters = {
            // Add param to positionning common settings
            image: {
                type: 'Asset', 
                default: null, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Image",
                    container: "specifics-fields",
                    widget:'asset',
                    assetType:'image',
                    description: "the image file"
                }
            }
        };

        this.addToParameters( otherParameters );

        this.setParamInvisible( 'addChild' );
        this.setParamInvisible( 'removeChild' );

        this.setDefaultValue( 'width', '100%' );

        this.disableParam( 'backgroundImage' );
        this.disableParam( 'hoverColor' );
        this.disableParam( 'hoverBackgroundColor' );

        
    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }

    init(){
        super.init();

    }
}

export default UiImage;