import UiObject from '../UiObject';

class UiSliderfield extends UiObject {

    constructor(id, props) {
        super(id, props, 'InputSliderField');
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-sliderfield",
            label: "Slider Field",
            parent : "ui-inputs",
            isDisplayed : true,
            isEmbeddable: true
        };
    }

    initParameters(){
        super.initParameters();

        
        let groupSlider = this.createInspectorNode('group', 'general', 'General' );
        groupSlider.children.push( this.createInspectorNode('group-field', 'slider-fields') );
        groupSlider.children.push( this.createInspectorNode('group-field', 'slider-fields-2') );

        let groupSliderStyle = this.createInspectorNode('group', 'general', 'Style' );
        groupSliderStyle.children.push( this.createInspectorNode('group-field', 'slider-fields-3') );

        let sliderTopic = this.createInspectorNode( 'topic', 'styles', 'Slider Settings' );
        sliderTopic.children = [ groupSlider , groupSliderStyle ];

        this.inspector.unshift( sliderTopic  );

        let otherParameters = {
            // Add param to positionning common settings
            type : {
                type: 'String',
                default: "slider",
                partial: null,
                connection: null,
                auth :  {
                    label: "Type",
                    container: "slider-fields",
                    description: "Slider or Range type",
                    widget: "select",
                    options: [{value:'slider', label:'Slider'}, {value:'range', label:'Range'}],
                }
            },

            defaultValue : {
                type: 'Float',
                default: 0,
                partial: "slider",
                connection: {
                    in: {pluggable: false, default: false},
                    out: {pluggable: false, default: false} },
                auth :  {
                    label: "Default value",
                    container: "slider-fields",
                    description: "Set current value of slider.",
                    conditions: [{field:'type', value:"slider", operator:'=='}]
                }
            },

            defaultValueRange: {
                type: 'Vector2',
                default: { x : 0, y: 100} ,
                partial: 'ranged',
                connection: {
                    in: {pluggable: false, default: false},
                    out: {pluggable: false, default: false},
                },
                auth: {
                    label: 'Default value',
                    container: 'slider-fields',
                    description: 'Set current value of slider.',
                    conditions: [{field: 'type', value: 'slider', operator: '!='}],
                }
            },

            value: {
                type: 'Float',
                partial: 'slider',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: true },
                },
                auth: {
                    label: 'Value',
                    container: 'slider-fields',
                    widget: 'calculated',
                    conditions: [{ field: 'type', value: 'slider', operator: '==' }],
                },
            },

            range: {
                type: 'Vector2',
                partial: 'ranged',
                default: { x: 0, y: 100 },
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: true },
                },
                auth: {
                    label: 'Value Range',
                    container: 'slider-fields',
                    widget: 'calculated',
                    conditions: [{ field: 'type', value: 'slider', operator: '!=' }],
                },
            },


            min: {
                type: 'Float',
                default: 0,
                partial: "slider",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Min",
                    container: "slider-fields-2",
                    description: "The minimum value of the slider"
                }
            },

            max: {
                type: 'Float',
                default: 100,
                partial: "slider",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Max",
                    container: "slider-fields-2",
                    description: "The maximum value of the slider"
                }
            },

            step: {
                type: 'Int',
                default: 1,
                partial: "slider",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Step",
                    container: "slider-fields-2",
                    description: "Value to be added or subtracted on each step the slider makes. Must be greater than zero, and max - min should be evenly divisible by the step value.  When marks is not an empty object, step can be set to null, to make marks as steps."
                }
            },
            marks: {
                type: 'ArrayType', 
                default: null, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Marks",
                    container: "slider-fields-2",
                    widget: "array",
                    pattern: [
                        {name:'position',type:'float', widget:'float'},
                        {name:'label',type:'string', widget:'string'},
                        {name:'color',type:'Color', widget:'color'}
                    ],
                    description: "marks listed on the slider."
                }
            },
            showMarkOnlyActivated: {
                type: 'Boolean',
                default: false,
                partial: "slider",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Show Marks Only Activated",
                    container: "slider-fields-2",
                    description: "Show Marks Only Activated"
                }
            },

            vertical: {
                type: 'Boolean',
                default: false,
                partial: "slider",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Vertical",
                    container: "slider-fields-2",
                    description: "If vertical is true, the slider will be vertical."
                }
            },
            included: {
                type: 'Boolean',
                default: false,
                partial: "slider",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Included",
                    container: "slider-fields-2",
                    description: "If the value is true, it means a continuous value interval, otherwise, it is a independent value."
                }
            },
            disabled: {
                type: 'Boolean',
                default: false,
                partial: "slider",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Disabled",
                    container: "slider-fields-2",
                    description: "If true, handles can't be moved."
                }
            },
            dots: {
                type: 'Boolean',
                default: false,
                partial: "slider",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Dots",
                    container: "slider-fields-2",
                    dots: "When the step value is greater than 1, you can set the dots to true if you want to render the slider with dots."
                }
            },
            label: {
                type: 'Boolean',
                default: true,
                partial: null,
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Label",
                    container: "slider-fields-2",
                    dots: "Display or not the tooltip label during slide."
                }
            },



            // Style

            
            railColor: {
                type: 'Color',
                default:  { hex : "#e9e9e9" }, 
                partial: "style",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Rail color",
                    container: "slider-fields-3",
                    dots: "Color of slider's rail"
                }
            },
            dotsColor: {
                type: 'Color',
                default:  { hex : "#ffffff" }, 
                partial: "style",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Dots color",
                    container: "slider-fields-3",
                    dots: "Filled color of slider's dots"
                }
            },
            dotsStrokeColor: {
                type: 'Color',
                default:  { hex : "#96dbfa" }, 
                partial: "style",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Dots Stroke color",
                    container: "slider-fields-3",
                    dots: "Stroke color of slider's dots"
                }
            },
            activeDotsColor: {
                type: 'Color',
                default:  { hex : "#ffffff" }, 
                partial: "style",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Active Dots color",
                    container: "slider-fields-3",
                    dots: "Filled color of slider's active dots"
                }
            },
            activeDotsStrokeColor: {
                type: 'Color',
                default:  { hex : "#96dbfa" }, 
                partial: "style",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Active Dots Stroke color",
                    container: "slider-fields-3",
                    dots: "Stroke color of slider's active dots"
                }
            },
            trackColor: {
                type: 'Color',
                default:  { hex : "#abe2fb" }, 
                partial: "style",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: "Track color",
                    container: "slider-fields-3",
                    dots: "Color of slider's track"
                }
            },

            
        };

        this.addToParameters( otherParameters );

        this.setDefaultValue( 'width', '100%' );
        this.setDefaultValue( 'height', '30px' );
    }
}

export default UiSliderfield;

