import InspectorActionAbstract from './InspectorActionAbstract';

class UpdateHomeScreen extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateHomeScreen';
    }

    /**
     * @param targetId
     * @param newName
     */
    run (state) {
        let composite = {project: {}};
        composite.project[this._path + '.value.homeScreenId'] = this.getParam('screen');
        return this.composeState(state, composite);
    }
}

export default UpdateHomeScreen;