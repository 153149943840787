import InspectorActionAbstract from './InspectorActionAbstract';
import * as helper from '../../helper';

class UpdateState extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateState';
    }

    /**
     * @param targetId
     * @param side ("in" or "out")
     * @param state (name of the state)
     */

    runAdd (state) {
        let composite = {project: {}};

        if (!this._target.value.states) {            
            let pattern = helper.config.getConfigByType( state.config , this._target.value.type, this._target.value.format );
            
            let doc = {};
            this.deps.mainStore.block.createBlockStates(doc, pattern);
            let index = doc.states[this.getParam('side')].indexOf( this.getParam('state') );

            if ( index == -1 ) {
                doc.states[this.getParam('side')].push( this.getParam('state') );
            }

            composite.project[this._path + '.value'] = doc;
        } else {

            let index = this._target.value.states[this.getParam('side')].indexOf( this.getParam('state') );

            if ( index == -1 ) {
                composite.project[this._path + '.value.states.'+this.getParam('side')] = this.arrayPush( this.getParam('state') );
            }

        }
        return this.composeState(state, composite);
    }

    runRemove( state ){
        let composite = {project: {}};

        let index = this._target.value.states[this.getParam('side')].indexOf( this.getParam('state') );

        if ( index != -1 ){
            composite.project[this._path + '.value.states.'+this.getParam('side')] = this.arraySplice( index, 1 );
        }

        return this.composeState(state, composite);
    }

    postRun(){
        this.trigger('block:updateFields', {id : this.getParam('targetId') });

        let parentId = this._target.value['ref_'+this._target.value.level];
        this.trigger('editor:schematic:checkLinks', { bloc_id: parentId } );
    }
}

export default UpdateState;