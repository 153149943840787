var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "datepicker-ctn" },
    [
      _c("date-picker", {
        attrs: { time: _vm.value, option: _vm.option },
        on: {
          "update:time": function($event) {
            _vm.value = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }