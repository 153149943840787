import ActionAbstract from '../ActionAbstract'
import * as helper from '../../helper'

class UpdateFields extends ActionAbstract {

    static get ID() {
        return 'actions:template:updateFields'
    }

    run(state) {

        let id = this.getParam('blocId')
        let bloc = helper.block.getTemplateById(state.project, id)

        if ( !bloc ) return false

        switch (bloc.value.type) {
            case "process":
                bloc.value.fields[0].value = true // force process template
                break;
        
            default:
                break;
        }
        
    }
}

export default UpdateFields
