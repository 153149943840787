<template lang="pug">
div#dropdown
	button.anchor(v-on:mouseover='toggleShow') {{defaultType}}
	div.menu(v-if='showMenu', v-on:mouseleave="toggleShow")
		div.menu-item(v-for='item in items' v-on:click='itemClicked(item)') {{item}}
</template>

<script>

export default {

	data: function() {
		return {
			showMenu: false,
			defaultType: 'Viewer'
		}
	},

	props: ['items', 'defaultItem'],

	created: function() {
		this.internalItems = this.items
		this.internalEditedItem = this.editedItem
	},

	methods: {

		toggleShow: function() {
			this.showMenu = !this.showMenu
		},

		itemClicked: function(item) {
			this.toggleShow();
			this.changeType(item)
			// this.onClick(item);
		},

		changeType: function(type) {
			this.activeType = type
			this.defaultType = this.activeType
			this.$emit("update-type", this.activeType);
		},

	}
}

</script>

<style lang="stylus">

#dropdown
	width:  97px
	height: 40px
	.anchor
		display: flex
		align-items: center
		justify-content: center
		border: 0
		font-size: 14px
		border-radius: 8px
		transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
		font-family: dinBold
		color: var(--textColor)
		background-color: transparent
		height: 40px
		width: 90px
		outline: none
		padding-top: 6px
		&::after
			display: inline-block
			width: 0
			height: 0
			margin-left: .5em
			vertical-align: .255em
			content: ""
			border-top: .3em solid
			border-right: .28em solid transparent
			border-bottom: 0
			border-left: .28em solid transparent

		&:hover
			color: #585857
			cursor: pointer
			outline: none
		&:hover .shareUserInput
			border-bottom-left-radius: 0
	.menu
		font-family: dinBold
		background-color: #EDEDED
		background-clip: padding-box
		border-top-left-radius: 0
		border-top-right-radius: 0
		border-bottom-right-radius: 7px
		border-bottom-left-radius: 7px
		color: #585857
		cursor: pointer
		display: flex
		flex-direction: column
		font-size: 14px
		position: absolute
		list-style: none
		margin: .125rem 0 0
		padding: 5px 10px
		text-align: left
		z-index: 3
		width: 90px
		box-sizing: border-box
		.menu-item
			text-align: start
			padding: 3px 6px 3px 12px
			transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
			&:hover
				background-color: #F4F6F6
				cursor: pointer

</style>
