import ActionAbstract from '../../ActionAbstract';

class DragDropBlock extends ActionAbstract {

    /**
     * @param pattern : block pattern to create
     * @param position : position where drag has started
    **/

    static get ID() {
        return 'actions:editor:menu:dragDropBlock';
    }

    runStart(state) {
    
        return this.composeState(state, {
            editor: {
                menu : {
                    dragging: true,
                    dragPos : this.getParam('position'),
                    dragPattern : this.getParam('pattern'),
                    dropZone: state.editor.targetId,
                    dropSection : null
                }
            } 
        });
    }

    runRefreshPos(state) {
    
        this.composeState(state, {
            editor: {
                menu : {
                    dragPos : this.getParam('position'),
                }
            } 
        });

        return false;
    }

    runUpdateDropZone(state) {
    
        this.composeState(state, {
            editor: {
                menu : {
                    dropZone: this.getParam('dropZone'),
                    dropSection : this.getParam('dropSection'),
                }
            } 
        });

        return false;
    }

    runDrop(state) {

        if( state.editor.menu.dragPattern )
            this.trigger('block:createBlock', this.getMenuObject(state) );
    
        return this.composeState(state, {
            editor: {
                menu : {
                    dragging: false,
                    dragPattern : null
                }
            } 
        });
    }

    getMenuObject( state ){
        let pattern = Object.assign( {}, state.editor.menu.dragPattern );
        return {
            pattern : pattern, 
            parentId : state.editor.menu.dropZone, 
            section : state.editor.menu.dropSection
        }
    }



}

export default DragDropBlock;