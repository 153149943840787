<!-- GroupField Inspector Bloc vue -->

<template lang="pug">
div.big-bt.field-group(v-bind:class="{'hide':isObj && bloc.hideForObjInspector === true}")

	div.children(v-for="subbloc of bloc.children")
		field(v-if="subbloc.type == 'field' || subbloc.type == 'param' && subbloc.hideForObjInspector !== true", :field="subbloc", :bloc="target", :groupfield="true", :state="state", :inspectorField="inspectorField")

</template>

<script>

import FieldManager from  '../fields/FieldManager.vue';

export default {

	props:[ 'project', 'target', 'bloc', 'values', 'state', 'inspectorField' ],

	components: { field: FieldManager },

	data() {
		return {
		}
	},

	created: function(){

	},

	computed:{

		isObj : function(){
			if( this.target && this.target.value.type == "object" )
				return true;
			return false;
		}
	},
	methods: {
		selectHasChanged: function(newVal){
			this.$parent.selectHasChanged(newVal);
		},

	}

}

</script>

<style lang="stylus">


</style>
