<!--
    Popup window content for template deletion
    
    @desc: Content of the template deletion popup
    @require: PopupWindow.vue
    @props:
        settings    the component settings
-->
<template lang="pug">
div#template-delete
    div.section
        div.content
            div.icon
                icon(name="template-delete")
            h3.title Delete a template
            p.message
                span.before-name You are about to delete the 
                span.name {{ name }} 
                span.after-name template
                br
                | Be sure that no projects use this template, all data will definitely be lost
    div.section
        div.action
            input(v-on:click.stop="cancel", value="Cancel", type="button")
            input(v-on:click.stop="remove", value="Continue", type="button", class="nofocus")

</template>

<script>

import Icon from  '../common/Icon.vue'

export default {

    props: ['settings'],

    components: { Icon },

    computed: {
        name: function() {
            return this.settings.name
        }
    },

    methods: {

        cancel: function() {
            this.$parent.closeWindow(false)
        },

        remove: function() {
            this.$parent.closeWindow(true)
        }
        
    }
}

</script>

<style lang="stylus">
/*
    TemplateDeletePopup
     see PopupWindow for mutualized styles
*/

// #template-delete

</style>
