<!-- Bloc vue -->
<template lang="pug">

div.puzzle-bloc
	div.bloc
		div.title
			span.type Experience
			span.name {{bloc.value.name}}

		div.puzzle-part(v-bind:class="getTargetNames()")
			puzzle
		
		div.content.fake

	div.bottom-menu
		div.action-bts
			div.action-bt.bt-inspect(v-on:click.stop.prevent="inspectBloc")
		div.action-bts
			div.action-bt.bt-duplicate-white(v-on:click.stop.prevent="duplicateBloc")
		div.action-bts
			div.action-bt.bt-export-white(v-on:click.stop.prevent="exportBloc")
		div.action-bts
			div.action-bt.bt-download-white(v-on:click.stop="downloadBloc")
		div.action-bts
			div.action-bt.bt-trash-white(v-on:mousedown.stop="deleteBloc")

</template>

<script>

// STORE
import actionManager from '../../../../actions';
import Puzzle from  '../../../svgs/Puzzle.vue';

export default {

	props: ['bloc', 'appState'],

	components: { Puzzle },

	data() {
		return {
			selected: null,
			inSelected: false,
			outSelected: false
		}
	},

	created: function(){
		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$on('clickDrop', this.onClickDrop)
	},

	beforeDestroy: function(){
		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// If limits appear, have a look at https://vuex.vuejs.org
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$off('clickDrop', this.onClickDrop)
	},

	watch: {
	},

	methods:{

		inspectBloc: function() {
			actionManager.trigger("inspector:load", {targetId: this.bloc.value._id });
		},

		downloadBloc: function() {
			actionManager.trigger('main:modal:openModal', {
				title:`Download Experience: ${this.bloc.value.slug}`,
				icon: 'share',
				text: '',
				download: Object.assign({experienceName: this.bloc.value.slug}, this.appState.project.value),
				cancelButton: 'none',
				continueButton: 'none',
			})
		},

		duplicateBloc: function() {
			actionManager.trigger('block:copy', {blocks : [this.bloc.value._id], duplicate: true});
		},

		getTargetNames: function(){
			if( this.bloc.value.targets.length > 1 )
				return 'multiplatform';

			let targets = "";
			for (var i = 0; i < this.bloc.value.targets.length; i++) {
				targets += this.bloc.value.targets[i].name+" ";
			};
			return targets;
		},

		deleteBloc: function(){
			actionManager.trigger('block:deleteBlock', {id : this.bloc.value._id} );
		},

		exportBloc: function(){
			actionManager.trigger('block:copy', {blocks : [this.bloc.value._id], saveToFile: true, fileName: "nodal-export-"+this.appState.project.value.name+"-"+this.bloc.value.name.replace(' ', '-')+".json" } );
		},

		// ORIGINAL clickDrop EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
		// (migration Vue v1 to v2)
		onClickDrop: function(){
			this.inSelected = false;
			this.outSelected = false;
			this.selected = null;
		},
	},
	
}

</script>

<style lang="stylus">

.puzzle-bloc
	display: flex
	flex-flow: column
	align-items: flex-end
	width: 230px
	position: absolute
	z-index: 1

	&.dragging
		z-index: 10
		
	.bloc
		-webkit-filter: drop-shadow( 0px 0px 10px rgba(0, 0, 0, .1))
		filter: drop-shadow( 0px 0px 10px var(--shadowColor))
		font-family: 'DinRegular', 'ComfortaaLight'
		border-radius: 8px
		color: #707070
		width: 100%

	.title
		display: block
		background-color: var(--moduleTitleColor)
		font-family: 'DinRegular'
		font-size: 14px
		color: var(--moduleTextColor)
		border-radius: 4px 4px 0px 0px
		z-index: 1
		position: relative
		height: 40px
		line-height: 40px
		padding: 0 10px
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		transition: linear all .1s !important
		
		.type
			font-family: 'DinBold'
			font-size: 14px
			text-transform: capitalize;
			margin-right 5px

		.name 
			font-family: 'DinRegular'
			font-size: 14px
			color: var(--moduleTextColor)

	.ico-drag
		display: block
		position: absolute
		right: 0
		top: 0
		width: 20px
		height: 20px
		background-color: #333

	.content
		display: block
		
		&.fake
			position: relative
			width:123%
			padding-bottom: 77%
			opacity: 0

	&.experience
		border: 0px
		background-color: transparent
		.title
			border: 1px solid #58585B

	&.selected
		.title
			background-color: #f39a74
			.name 
				color: white
			
	&.experience.selected
		border: 0px
		.title
			border: 1px solid #e99471

	.bottom-menu
		display: none
		background-color: var(--bgColor)
		border-radius: 20px
		box-shadow: 0 0 10px var(--shadowColor)
		margin-top: 4px

		.action-bts
			display:inline-block
			vertical-align: middle;
			background-color: var(--breadColor)
			border-radius: 20px
			width: 20px
			height: 20px
			margin: 3px
			.action-bt
				width: 100%
				height: 100%
				background-size: auto 50%
				background-repeat: no-repeat
				background-position: center center


	.puzzle-part
		position: absolute
		width: 100%
		padding: 2px
		padding-bottom: 90%
		top: 0px
		left: -2px
		z-index: 0
		.puzzle
			position: absolute
			width: 120%
			height: 100%
			
			fill: #FFFFFF
		
		&:after
			content: ""
			display: block
			width: 99%
			height: 65%
			position: absolute
			top: 30%
			left: 0px	
			

		&.mobile
			&:after
				background-color: var(--iconPuzzleColor)
				-webkit-mask: url(../../../../assets/images/icons/mobile.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/mobile.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.tablet
			&:after
				background-color: var(--iconPuzzleColor)
				-webkit-mask: url(../../../../assets/images/icons/tablet.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/tablet.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.desktop
			&:after
				background-color: var(--iconPuzzleColor)
				-webkit-mask: url(../../../../assets/images/icons/desktop.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/desktop.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.light
			&:after
				background-color: var(--iconPuzzleColor)
				-webkit-mask: url(../../../../assets/images/icons/light.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/light.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.iot
			&:after
				background-color: var(--iconPuzzleColor)
				-webkit-mask: url(../../../../assets/images/icons/iot.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/iot.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.multiplatform
			&:after
				background-color: var(--iconPuzzleColor)
				-webkit-mask: url(../../../../assets/images/icons/desktop.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/desktop.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		
.puzzle-bloc
	&:hover
		z-index: 2
		.bottom-menu
			display: inline-block

</style>

