import { EventEmitter } from 'events';
import store from 'store';

import serviceManager, { ServiceAbstract } from '../services'

class StoreAbstract extends ServiceAbstract {

    beforeCreate() {
        this.state = {};
        this._stateHistory = [];
        this._historyMaxLength = 0;
    }

    get stateHistory() {
        return this._stateHistory;
    }

    get historyMaxLength () {
        return this.historyMaxLength;
    }

    set historyMaxLength (l) {
        this._historyMaxLength = l;
    }    

    saveCurrentState() {
        // if (this._historyMaxLength !== -1 && this._stateHistory.length >= this._historyMaxLength) {
        //     this._stateHistory.shift();
        // }
        // let stateAsStr = JSON.stringify(this.state)
        this._stateHistory.push(this.state);
        if (this._historyMaxLength !== -1) {        
            this._stateHistory = this._stateHistory.splice(-this._historyMaxLength);
        }

        // store.set('last_state', stateAsStr);
        // store.set('last_state_date', Date.now());
    }

    getInitialState() {
        // let recordedState = store.get('last_state');
        // let recordedStateDate = store.get('last_state_date');
        
        // if (recordedState && Date.now() - recordedStateDate < 1000 * 20) {
        //     return JSON.parse(recordedState);
        // } else {
        //     return null;
        // }

        return false;
    }

}

export default StoreAbstract;