<!-- Editor vue -->
<template lang="pug">
div.editor.main-editor

	// FIXME: dynamic transition, 
	<transition name="transition">
	div.editor(v-if="currentEditor", :class="transition")
		schematic.scene(v-if="currentEditor.value.type!='object'", :app-state="appState", :target="target")
		uieditor.scene(v-if="currentEditor.value.type=='object' && currentEditor.custom.tag == 'ui'", :app-state="appState", :target="target")
	</transition>

	bloc-menu(:menu="appState.editor.menu", :target="currentEditor")
	
	//dragged-item(v-if="dragged.item && dragged.position && currentEditor && currentEditor.value.type=='object'", :data="dragged")
	dragged-item(v-if="appState.editor.menu.dragging", :app-state="appState")

	//test

</template>

<script>

// STORE
import actionManager from '../../actions';
import { stores } from '../../stores/MainStore.js';

import Schematic from  './schematic/Schematic.vue';
import UIEditor from  './ui/UIEditor.vue';

import BlocMenu from  './blocmenu/BlocMenu.vue';

import DraggedItem from  './DraggedItem.vue';

import * as helper from '../../helper';

export default {

    components: { BlocMenu, Schematic, uieditor : UIEditor, DraggedItem },

	props: [ 'appState' ],

	data() {
		return {
            transition: 'go-inside',
            currentEditor: null,
			timerResize: null,
			updateOriginFreq: 250,
			mainContainer: null,
			oldLevel : null,
			
            dragged : {
				item : null,
				position : null
			}
		}
	},

	created: function(){
		this.loadEditor();
		this.appState.editor.origin = {x:0, y:0};
	},

	destroyed: function(){
		window.removeEventListener('resize', this.windowResize);
	},
	
	mounted: function(){
		window.addEventListener('resize', this.windowResize);
		actionManager.trigger('editor:schematic:updateOrigin');
	},

	watch: {
        'appState.editor.targetId': function( newVal, oldVal ){
			if( oldVal && newVal == oldVal )
				return;
            
			this.loadEditor();
		}
	},

	computed: {
		'target': function(){

			let target = null;

			if( this.currentEditor){
				const blocTarget = helper.block.getBlockById( this.appState.project, this.currentEditor.value._id );
				const templateTarget = helper.block.getTemplateById(this.appState.project, this.currentEditor.value._id);
				if(blocTarget && blocTarget.value._deleted){
					let parentID = stores.block.getParentsID( blocTarget );
					let parent = helper.block.getBlockById( this.appState.project, parentID );

					let grandParentID = stores.block.getParentsID( parent );
					let grandParent = helper.block.getBlockById( this.appState.project, grandParentID );

					this.appState.editor.targetLayoutId = null;

					if(blocTarget.value.type === 'object'){
						target = grandParent
						this.appState.editor.targetId = grandParentID;
						actionManager.trigger('main:updateBreadcrumb' , { currentId : grandParentID });
					} else {
						target = parent;
						this.appState.editor.targetId = parentID;
						actionManager.trigger('main:updateBreadcrumb' , { currentId : parentID });
					}
				} else if(this.currentEditor.value.level === 'template' && this.templateIsLinkedToProject === false) {
					target = this.appState.project;
					actionManager.trigger('main:updateBreadcrumb' , { currentId : target.value._id });
				} else {
					target = blocTarget
				}
			}
			return target;
		},

		'templateIsLinkedToProject': function(){
			const projectTemplates = this.appState.project.templates;
			const templateTarget = helper.block.getTemplateById(this.appState.project, this.currentEditor.value._id);
			if(this.currentEditor.value.level === 'template'){
				if(projectTemplates.length === 0){
					return false
				} else if(projectTemplates.length > 0){
					const templateIds = [];
					projectTemplates.forEach(template => {
						templateIds.push(template.value._id)
					})
					if(templateIds.indexOf(this.currentEditor.value._id) === -1){
						return false
					} else {
						return true
					}
				}
			} else {
				return null
			}
		}
	},

	methods:{

		loadEditor(){
			if (this.appState.xpConfig) Object.keys( this.appState.xpConfig ).forEach( ( param ) => {
				this.$xpConfig[param] = this.appState.xpConfig[param];
			});
			let targetId = this.appState.editor.targetId
			let newTarget = helper.block.getBlockById( this.appState.project, this.appState.editor.targetId );
			if(newTarget.value._deleted === false){
				let parentID = stores.block.getParentsID( newTarget );
				let parent = helper.block.getBlockById( this.appState.project, parentID );
				newTarget = parent
				this.currentEditor = parent
			}
			if(newTarget.value.level === 'template' && this.templateIsLinkedToProject === false){
				newTarget = this.appState.project;
				this.currentEditor = this.appState.project;
			}

			let newLevel = newTarget.value.type;
			this.transition = this.getTransition( this.oldLevel, newLevel );

            this.currentEditor = null;

            setTimeout(function() {
				this.currentEditor = newTarget;
				this.oldLevel = newTarget.value.type;
            }.bind(this), 100);
		},

		windowResize: function(){
			actionManager.trigger('editor:schematic:updateOrigin');
		},


        getTransition: function( oldLevel, newLevel ){
            let order = ['project', 'experience', 'screen', 'widget', 'object', 'process', 'module'];
            let indexOld = order.indexOf(oldLevel);
            let indexNew = order.indexOf(newLevel);

            if( indexOld != -1 && indexNew != -1 && indexNew < indexOld ){
                return 'go-outside';
            }
            return 'go-inside';
        },

	}

}

</script>

<style lang="stylus">
.editor
    display block
    position: absolute
    width 100%
    height 100%
    top 0
    left 0
    right 0
    bottom 0
	-webkit-touch-callout: none
	-webkit-user-select: none
	-khtml-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	user-select: none;


.go-inside
	transform: scale(1)
	opacity: 1
	transition: all .3s .1s cubic-bezier(0.215, 0.610, 0.355, 1.000) // ease out cubic
	z-index: 100

	&.go-inside-enter
		opacity: 0
		transform: scale(0.5)
	&.go-inside-leave-active 
		opacity: 0
		transform: scale(1.5)
		transition: all .3s cubic-bezier(0.550, 0.055, 0.675, 0.190) // ease in cubic
		z-index: 150

.go-outside
	transform: scale(1)
	opacity: 1
	transition: all .3s .1s cubic-bezier(0.215, 0.610, 0.355, 1.000) // ease out cubic
	z-index: 100

	&.go-outside-enter
		opacity: 0
		transform: scale(1.5)
	&.go-outside-leave-active
		opacity: 0
		transform: scale(0.5)
		transition: all .3s cubic-bezier(0.550, 0.055, 0.675, 0.190) // ease in cubic
		z-index: 50

</style>

