<!-- String vue -->

<template lang="pug">

span.datepicker-ctn
	date-picker(v-bind:time.sync="value" v-bind:option="option")
	//- input.value(type="text", v-model="value", v-bind:placeholder="placeholder", v-on:focusout="valueUpdated(field)", :disabled="field.disabled")
	
</template>

<script>

// import DatePicker from 'vue-datepicker';
import DatePicker from 'vue-datepicker/vue-datepicker-1.vue'


export default {

	components: { DatePicker },

	props:[ 'field' , 'fieldvalue', 'placeholder' ],

	data() {
		return {
			value: "",
			option: {
				type: 'min',
				week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
				month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
				format: 'YYYY-MM-DD HH:mm',
				color: {
					checkedDay : "#eebd91",
					header: '#eebd91',
					headerText: '#58585b'
				},				
			}
		}
	},

	created:  function(){
		const nbrpad = (str, char = '0', length = 2) => {
			return (char + str).slice(-length);
		}

		let d = this.fieldvalue && this.fieldvalue.value ? new Date(this.fieldvalue.value) : new Date();
		this.value = `${d.getFullYear()}-${nbrpad(d.getMonth() + 1)}-${nbrpad(d.getDate())} ${nbrpad(d.getHours())}:${nbrpad(d.getMinutes())}`
	},

	destroyed: function(){

	},

	watch:{
		"value": function (newVal, oldVal) {
			this.valueUpdated((new Date(newVal)).getTime());
		}
	},

	methods:{

		valueUpdated: function( val ){
			this.$parent.valueUpdated( this.field, val );
		}


	}

}

</script>

<style lang="stylus">
 
 .datepicker-ctn
	.cov-vue-date
		width: 69%;

	.datepickbox
		input
			background-color: var(--inputValue)
			border: none
			border-radius: 4px
			height: 30px
			margin-top: 5px
			width: calc(100% - 10px)
			text-align: left
			color: var(--inspectorText)!important
			padding-left: 10px!important
			font-family: 'DinBold'!important
			font-size: 14px!important

	.watch-box
		.active
			background-color: #eebd91 !important

.datepicker-overlay
	background: var(--modalTransparentBgColor) !important

.cov-picker-box
	background: var(--bgColor) !important

.cov-date-body
	box-shadow: 0px 0px 10px var(--shadowColor) !important
	border-radius: 8px !important
	font-family: 'DinRegular' !important

.cov-date-monthly
	background: var(--modalColor) !important

.cov-date-caption
	color: var(--mainColor)
	font-family: 'DinBold'
	font-size: 30px!important
	line-height: 26px  !important

.cov-date-next
	&:before
		background: var(--mainColor) !important
	&:after
		background: var(--mainColor) !important

.cov-date-previous
	&:before
		background: var(--mainColor) !important
	&:after
		background: var(--mainColor) !important

.week
	color: var(--mainColor) !important
	ul
		li
			color: var(--mainColor) !important
			font-family: 'DinBold'

.day
	color: var(--mainColor) !important
	background: var(--bgColor) !important
	ul
		li
			color: var(--mainColor) !important
			line-height: 37px !important

.checked
	color: #FFF !important
	border-radius: 33px !important
	background: var(--modalBgColor) !important

.button-box
	text-align: center !important 
	padding-bottom: 10px !important 

	span
		cursor: pointer !important 
		padding: 10px 20px !important 
		background: var(--inputValue) !important 
		border-radius: 30px !important 
		width: 50px!important
		font-size: 18px !important
		font-family: 'DinBold' !important 
		color: var(--descriptionTextColor) !important 

.watch-box
	.active
		background-color: var(--modalBgColor)!important

</style>