var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "textinput-ctn clock", class: { "has-unit": _vm.unit } },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value.freq,
            expression: "value.freq"
          }
        ],
        staticClass: "clock value",
        attrs: { type: "text", disabled: _vm.field.disabled },
        domProps: { value: _vm.value.freq },
        on: {
          focusout: function($event) {
            return _vm.valueUpdated()
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.value, "freq", $event.target.value)
          }
        }
      }),
      _vm.unit
        ? _c("span", { staticClass: "unit" }, [_vm._v(_vm._s(_vm.unit))])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }