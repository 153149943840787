import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import store from 'store';

class OpenEditor extends ActionAbstract {

    /**
     * @param id : id of block requested
    **/

    static get ID() {
        return 'actions:editor:openEditor';
    }

    run(state) {
        
        let target = helper.block.getBlockById( state.project, this.getParam('id') );

        if (target) {
            if( this.deps.mainStore.config.editorView.indexOf( target.value.type ) == -1 )
                return state;
                
            this.trigger('main:deselectAll');
            this.trigger('editor:menu:filter', {type : target.value.type, id: target.value._id});
            this.trigger('editor:schematic:calculateBoudingBox');
            this.trigger('editor:schematic:drawLink', { subaction: 'cancel' });
            this.trigger('editor:schematic:updateOrigin');

            this.trigger('main:updateBreadcrumb' , { currentId : this.getParam('id') });

            if( !this.getParam('openFromHistory') )
                this.trigger('main:addToHistory' , { id : this.getParam('id') });

            let currentScenePos = { x: 0, y : 0 };

            if( target.value.scene && target.value.scene.coordinate ){
                currentScenePos = target.value.scene.coordinate;
            }

            if(target.value.type === "object"){
                this.trigger('block:updateFields', { id : target.value._id } );
            } else {
                let children = (!target.custom.objects) ? target.custom.children : target.custom.children.concat( target.custom.objects );
                children.forEach( (child) => {
                   // if( child.custom.fields == null && child.custom.states == null  ) 
                   // @todo : find a better condition to do update field only if necessary (for ui included blocks, need to update if value of ui changed)
                        this.trigger('block:updateFields', { id : child.value._id } );
                });
            }
                       

            this.trigger('editor:schematic:checkLinks', { bloc_id: target.value._id });

            

            return this.composeState(state, {
                editor: {
                    customPreview : store.get('nodal-customPreview'),
                    targetId : this.getParam('id'),
                    targetLayoutId: null,
                    draggingBlock : false,
                    scene: {
                        coordinate: currentScenePos,
                        scale : 1
                    },
                    menu: {
                        list : [],
                        open : false,
                    },
                    linkmenu : {
                        open : false
                    },
                    selection: {
                        items: [],
                    }
                } 
            });
        }

        return false;
    }


}

export default OpenEditor;