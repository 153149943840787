<!-- Filter vue -->

<template lang="pug">
	div.filters
		div.nav(v-bind:class="{'open': navigatorOpen}")
			ul
				li 
					a(v-on:click="filterButtons('all')", v-bind:class="{ 'active': currentFilter=='all' }") 
						span All projects
				li 
					a(v-on:click="filterButtons('owner')", v-bind:class="{ 'active': currentFilter=='owner' }") 
						span Created by me
				li 
					a(v-on:click="filterButtons('user')", v-bind:class="{ 'active': currentFilter=='user' }") 
						span Shared with me

		div.bt-nav(v-bind:class="{ 'open': navigatorOpen}", v-on:click.prevent="toggleNavigator")
</template>

<script>

import actionManager from '../../actions';

export default {

	data() {
		return {
			// currentFilter: 'all',
			// open: true
		}
	},

	props: ['currentFilter', 'navigatorOpen'],

	methods:{
		filterButtons(filter){
			actionManager.trigger('projects:setFilter', { filterValue: filter });
		},
		toggleNavigator(){
			actionManager.trigger('main:toggleNavigator');
			// this.open = !this.open;
		}
	}

}

</script>

<style lang="stylus">
.filters .nav
	display: block
	position: fixed
	width: 420px
	height: 100%
	position: fixed
	top: 41px
	left: 0px
	background-color: var(--menuColor)
	box-shadow: 0 0 10px var(--shadowColor)
	z-index: 90
	transform:translateX(-100%)
	transition: all 0.1s ease-in-out;

	&.open
		transform:translateX(0)

	ul
		list-style-type: none
		margin: 0
		padding: 0
		
		li
			a
				display: block
				cursor: pointer
				padding 10px 10px
				background-color: var(--menuElementColor)
				font-size: 14px
				text-decoration: none
				color: var(--textColor)
				margin-bottom: 2px
				font-family: 'DinBold'
				transition: linear all .1s !important
				&.active
					background: var(--inputValue)
					color: #ffffff
</style>