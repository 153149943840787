<!-- Bloc vue -->
<template lang="pug">

div.draggable-bloc(v-bind:class="[bloc.value.type]", v-bind:id="'bloc-'+bloc.value._id")
	post-it(v-if="renderer == 'post-it'", :bloc="bloc", :dragging="dragging")
	puzzle-bloc(v-if="renderer == 'puzzle'", :bloc="bloc", :app-state="appState", v-bind:class="{'selected':selected}")
	classic-bloc(v-if="renderer == 'classic'", :bloc="bloc", :family="family", :bloc-type-name="blocName", :bloc-type-icon="parentBlocIcon", :app-state="appState", v-bind:class="{'selected':selected}", :workspace='workspace')

</template>

<script>

// STORE
import { stores } from '../../../../stores/MainStore.js';
import actionManager from '../../../../actions';

import ClassicBloc from  './ClassicBloc.vue';
import PuzzleBloc from  './PuzzleBloc.vue';
import PostIt from  './PostIt.vue';

import * as helper from '../../../../helper';

export default {

	props: ['appState', 'bloc', 'dragging', 'family', 'workspace'],

	components: { ClassicBloc, PostIt, PuzzleBloc },

	data() {
		return {
			type: null,
			pluglistInput: [],
			pluglistOutput: [],
			config: null,
			inspector : null
		}
	},

	created: function(){

		this.type = this.bloc.value.type;

		this.renderer = 'classic';

		if( this.type == 'note')
			this.renderer = 'post-it';
		if( this.type == 'experience' )
			this.renderer = 'puzzle';
		this.config = helper.config.getConfigByType( this.appState.config, this.type );

		this.parentBlocIcon = null;
		if( this.bloc.value.level == "process" && this.config && this.appState.editor.menu && this.appState.editor.menu.list && this.appState.editor.menu.list[0] ){
			let index = this.appState.editor.menu.list[0].name=='templates' ? 1 : 0;	// WARNING: editor.menu.list[0] could refer to templates
			let modParentsList = this.appState.editor.menu.list[index].children;
			let modParentType = this.config.value.parent;
			let result = modParentsList.find( (t) => { return t.name === modParentType; });
			if( result )
				this.parentBlocIcon = result.icon;
		}

		if ( this.config ) {
			this.inspector = this.config.value.inspector;
			this.blocName = this.config.value.name;
		}
		else {
			this.blocName = this.type;
			const message = `The bloc configuration is missing for the module '${this.blocName}'`;
			/// @todo: implement notification
			//this.trigger('notification', { message, type: 'warning' });
			console.warn('notification', { message, type: 'warning' });
		}


		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$on('clickPlug', this.onClickPlug)

	},

	beforeDestroy: function(){
		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// If limits appear, have a look at https://vuex.vuejs.org
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$off('clickPlug', this.onClickPlug)
	},

	computed: {
		'selected': function(){
			return this.appState.editor && this.appState.editor.selection.items.indexOf(this.bloc.value._id) !== -1;
		},
	},

	methods: {

		// ORIGINAL clickPlug EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
		// (migration Vue v1 to v2)
		onClickPlug: function( plug )
		{
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			this.$root.$emit('link-bloc', {bloc:this.bloc, plug:plug}) // <---- ORIGINAL linkBloc LISTENER NOT FOUND
		},


	}


}

</script>

<style lang="stylus">

.draggable-bloc
	position: absolute
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.dragging
		z-index: 20

</style>
