var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "content" } }, [
    _c("h1", [_vm._v("Node Project")]),
    _c("h2", [_vm._v("Presentation")]),
    _vm._m(0),
    _c("h2", [_vm._v("Parameters")]),
    _vm._m(1),
    _c("h2", [_vm._v("Actions")]),
    _vm._m(2),
    _c("h2", [_vm._v("Settings")]),
    _c("h3", [_vm._v("Memory")]),
    _vm._m(3),
    _c("p", [
      _vm._v("A persistent memory is kept from one session to another.")
    ]),
    _c("h3", [_vm._v("Sessions")]),
    _vm._m(4),
    _vm._m(5),
    _vm._m(6),
    _c("p", [_vm._v("The session is closed permanently when it is emptied.")]),
    _c("div", { attrs: { id: "back" } }, [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.displayTab("nodes")
            }
          }
        },
        [_vm._v("← Back")]
      )
    ]),
    _vm._m(7)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "A project, contains n (experiences), which allows to create various roles that can interact with each other within the same project."
      ),
      _c("br"),
      _vm._v(
        "A project has different settings that are shares with all its experiences."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { attrs: { id: "params" } }, [
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Name")])]),
        _c("td", { attrs: { id: "col2" } }, [_vm._v("String")])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Owner")])]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v("User who create the project")
        ])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [
          _c("strong", [_vm._v("User(s)")])
        ]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v(
            "List of users who can access the project with specific rights (view only, edit, admin)"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { attrs: { id: "params" } }, [
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Delete")])]),
        _c("td", { attrs: { id: "col2" } }, [_vm._v("Delete the project")])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [
          _c("strong", [_vm._v("Duplicate")])
        ]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v("Create a copy of the project")
        ])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Share")])]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v(
            "Share the project with specific rights (view only, edit, admin)"
          )
        ])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Export")])]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v("Export the project in JSON")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("A shared memory is accessible to all the users of the project."),
      _c("br"),
      _vm._v("It has different types :"),
      _c("ul", { staticClass: "listing" }, [
        _c("li", [_vm._v("Angle")]),
        _c("li", [
          _vm._v("Array (A specific interface allows to parameter the array")
        ]),
        _c("li", [_vm._v("Asset (It can be chosen via the Library)")]),
        _c("li", [_vm._v("Boolean")]),
        _c("li", [_vm._v("Color")]),
        _c("li", [_vm._v("Float")]),
        _c("li", [_vm._v("Integer")]),
        _c("li", [_vm._v("Percent")]),
        _c("li", [_vm._v("String")]),
        _c("li", [_vm._v("Vector")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "A session represents a virtual room that can be joined by one or several persons using the platform."
      ),
      _c("br"),
      _vm._v(" All shared resources are shared between the users of a session"),
      _c("br"),
      _vm._v(
        " A project can have several sessions parameters. Without parameters, a default session is created."
      ),
      _c("br"),
      _vm._v(
        " The clients will automatically enter this session (with no filtrage)."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "To check if two clients belong to the same session, several options can be set :"
      ),
      _c("ul", { staticClass: "listing" }, [
        _c("li", [
          _vm._v(
            "By the distance according to the start position of the first client joining the session."
          )
        ]),
        _c("li", [
          _vm._v(
            "By the duration since the connection of the first client joining the session."
          )
        ]),
        _c("li", [
          _vm._v("By the choice within a list of sessions already created.")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("The session can have a name and a duration."),
      _c("br"),
      _vm._v(
        " At the end of this duration, an even will be sent to the authoring, and can be used to create a specific scenario of the experience."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("br"), _c("br"), _c("br")])
  }
]
render._withStripped = true

export { render, staticRenderFns }