import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class ToggleLinkMenu extends ActionAbstract {

    /**
     * @param TODO : TODO
     * 
    **/

    static get ID() {
        return 'actions:editor:schematic:toggleLinkMenu';
    }

    runOpen(state) {

        let status = true;
        this.state = state;

        let link = this.getParam('link');

        if (link && !link.priority) {
            link.priority = 0;
        }

        let id = link.from.blocID+"-"+link.from.side+"-"+link.from.name+"__"+link.to.blocID+"-"+link.to.side+"-"+link.to.name;

        let conversion = helper.links.getCastOptions(link, state);
        
        if( conversion === false ){
            // one of the field is not defined (inspector), link impossible
            // or link (cast) impossible
            this.trigger('editor:schematic:deleteLink', { blocId : link.from.blocID, link : link }); 

            status = "error";
            setTimeout(function() {
                this.trigger('editor:schematic:toggleLinkMenu', { subaction : "close" }); 
            }.bind(this), 500);

        }
        
        let inputInsp = helper.links.getInspector( helper.links.getInputSide( link ), state );

        let conversionList = helper.links.getConversions( conversion );
        let activation = helper.links.getActivation(inputInsp );
    
        if( conversionList == "" && !activation.isActivable && this.getParam('new') === true )
            return false; // do not open link menu , no options needed for this fresh new link

        if( activation.forceActivable )
            this.trigger('editor:schematic:updateLinkActivation', { blocId : link.from.blocID, link : link, value : true });
        
        return this.composeState(state, {
            editor : {
                linkmenu : {
                    open : status,
                    id : id,
                    link : link,
                    conversions : conversionList,
                    activation : activation,
                    pos:  {
                        x: this.getParam('x') - 0,
                        y: this.getParam('y') - 5 
                    }
                }
            }
        });
    }

    runClose(state) {

        let status = false;

        return this.composeState(state, {
            editor : {
                linkmenu : {
                    open : status,
                    id : null,
                    pos : null,
                    link : {
                        activated: null,
                        conversion: null,
                        priority: 0,
                        from: null, 
                        to : null
                    }
                }
            }
        });
    }

}

export default ToggleLinkMenu;