<!-- CLOCK vue -->
<template lang="pug">

    span.textinput-ctn.clock(v-bind:class="{'has-unit':unit}")
        input.clock.value(type="text", v-model="value.freq", v-on:focusout="valueUpdated()", :disabled="field.disabled")
        span.unit(v-if="unit") {{unit}}

</template>

<script>
export default {
    components: {},

    props:[ 'field' , 'fieldvalue', 'timemetric' ],

    data() {
        return {
            unit: '',
        }
    },

    computed: {
        'value': function() {
            if (!this.fieldvalue.value) {
                // a clock (see the default in nodal-app/src/client/nodal-lib/data-types/Clock.js or nodal-app/public/authoring/memories.json)
                const defval = JSON.parse(JSON.stringify(this.field.default));
                this.fieldvalue.value = defval;
            }
            return this.fieldvalue.value;
        }
    },
    
    created: function() {
        if (this.field.type === 'field') this.unit = this.value.mode.startsWith('second') ? 's' : this.value.mode;
    },

    watch: {
        'timemetric': function(newval, oldval) {
            if (newval === oldval || !this.value.freq) return;

            // convert form BPM
            if (oldval === 'bpm') {
                const sec = 60 / this.value.freq;
                if (newval === 'samples') this.value.freq = sec * 48000;
                if (newval === 'seconds') this.value.freq = sec;
            }
            // convert form smples
            if (oldval === 'samples') {
                const sec = this.value.freq / 48000;
                if (newval === 'bpm') this.value.freq = 60 / sec;
                if (newval === 'seconds') this.value.freq = sec;
            }
            // convert form seconds
            if (oldval === 'seconds') {
                const sec = this.value.freq;
                if (newval === 'bpm') this.value.freq = 60 / sec;
                if (newval === 'samples') this.value.freq = sec * 48000;
            }
            this.valueUpdated();
        }
    },

    methods: {
        valueUpdated: function() {
            const value = parseFloat(this.value.freq);

            if (!isNaN(value)) this.value.freq = value;
            if (this.timemetric) this.value.mode = this.timemetric;

            this.$parent.valueUpdated( this.field, this.value );
        },
    },
}
</script>