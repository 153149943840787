import ActionAbstract from "../../ActionAbstract";
import * as helper from '../../../helper';
import Vue from 'vue';

class UpdateWidgetOrder extends ActionAbstract {

    /* params :
    *  @screenId (String)
    *  @widgetId (String)
    *  @subaction (String)
    */

    static get ID() {
        return 'actions:inspector:widgets:updateWidgetOrder';
    }

    preRun(state){

        this.screen = null;
        this.screenPath = null;

        [this.screen, this.screenPath] = helper.block.getBlockById(state.project, this.getParam('screenId'), true);

        if( ! this.screen.value.widgetsOrder )
            Vue.set( this.screen.value, 'widgetsOrder' , [] );
        
        this.current = this.screen.value.widgetsOrder;

        this.xp = helper.block.getBlockById( state.project, this.screen.value.ref_experience );
    }

    runInit(state){
        // check if new widget are found, put them at start of order array

        
        let widgets = [];
        let allWidgetsIds = [];
        if( this.xp && this.xp.custom.children ){
            widgets = this.xp.custom.children.filter( ( c ) => { if ( c.value.type === "widget" ) return c ; return null } );
            widgets.forEach( (w) => { allWidgetsIds.push( w.value._id ) });
        }

        let unkonwnWidgets = widgets.filter( ( w ) => { 
            if( this.screen.value.widgetsOrder.indexOf( w.value._id ) === -1 )
                return w;
            return null; 
        });

        unkonwnWidgets.forEach( (nw) => {
            this.current.splice( 0, 0, nw.value._id );
        });

        // @todo: remove form order tab missing widgets
        let deletedWidgets = [];
        this.current.forEach( (wid) => {
            if( allWidgetsIds.indexOf( wid ) === -1 && wid != this.getParam('screenId') )
                deletedWidgets.push(wid);
        })
        deletedWidgets.forEach( (dw) => {
            let index = this.current.indexOf( dw );
            if( index != -1 ) this.current.splice( index , 1 );
        })

        // add screen id if not listed
        let screenIndex = this.current.indexOf( this.getParam('screenId') );
        if( screenIndex === -1 )
            this.current.push( this.getParam('screenId') );



    }

    runUp(state) {

        let wid = this.getParam('widgetId');
        let index = this.current.indexOf( wid );

        if( index > 0 ){
            this.current.splice( index, 1 ); // remove wid
            this.current.splice( index-1, 0, wid ); // put wid upper
        }
        
    }

    runDown(state) {

        let wid = this.getParam('widgetId');
        let index = this.current.indexOf( wid );

        if( index < this.current.length-1 ){
            this.current.splice( index, 1 ); // remove wid
            this.current.splice( index+1, 0, wid ); // put wid upper
        }


    }

    runRefresh(){
        

    }

    getWidgetUiOrder(){

        let widgetsUiIds = [];
        if( this.xp && this.xp.custom.children ){
            let widgets = this.xp.custom.children.filter( ( c ) => { if ( c.value.type === "widget" ) return c ; return null } );
            widgets.forEach( (w) => { 
                if( w.custom.objects && w.custom.objects.length > 0 ) {
                    let wui = w.custom.objects[0];
                    widgetsUiIds[w.value._id] = wui.value._id;
                }
            });
        }

        let widgetUiOrder = [];
        let widgetActivated = this.screen.value.widgetsActivated ? this.screen.value.widgetsActivated : [];
        this.current.forEach( (wid) =>{
            if( widgetsUiIds[wid] && widgetActivated.indexOf( wid ) != -1 )
                widgetUiOrder.push( widgetsUiIds[wid] );
            else if( wid == this.screen.value._id && this.screen.custom.objects && this.screen.custom.objects.length > 0 ){
                let uiscreen = this.screen.custom.objects[0].value._id;
                widgetUiOrder.push(uiscreen);
            }
        });

        return widgetUiOrder;

    }

    postRun(state){

        let widgetUiOrder = this.getWidgetUiOrder();

        Vue.set( this.screen.value, 'widgetsOrder' , this.current );

        this.trigger("editor:ui:updateUiState", {widgetOrder : widgetUiOrder });

        return state;
    }

}

export default UpdateWidgetOrder;