<!-- Number vue -->

<template lang="pug">

span.textinput-ctn
	input.value.euler.alpha(type="text", v-model="value.alpha", v-on:focusout="updateAlpha()", v-bind:placeholder="placeHldAlpha", :disabled="field.disabled")
	input.value.euler.beta(type="text", v-model="value.beta", v-on:focusout="updateBeta()", v-bind:placeholder="placeHldBeta", :disabled="field.disabled")
	input.value.euler.gamma(type="text", v-model="value.gamma", v-on:focusout="updateGamma()", v-bind:placeholder="placeHlGamma", :disabled="field.disabled")
	
</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue', 'placeholder' ],

	data() {
		return {
			value: {
				alpha: null,
				beta: null,
				gamma: null
			},
			placeHldAlpha : null,
			placeHldBeta : null,
			placeHlGamma : null
		}
	},

	created: function(){
		this.value = this.fieldvalue ? this.fieldvalue.value : {alpha: null, beta: null, gamma: null};

		if( this.placeholder && this.placeholder.alpha )
			this.placeHldAlpha = this.placeholder.alpha;

		if( this.placeholder && this.placeholder.beta )
			this.placeHldBeta = this.placeholder.beta;

		if( this.placeholder && this.placeholder.gamma )
			this.placeHlGamma = this.placeholder.gamma;

	},

	destroyed: function(){

	},

	watch:{

	},

	methods:{

		checkNumberValue: function(val){
			let floatVal = parseFloat(val);
			if( val == "" || floatVal == NaN || floatVal == 'NaN' )
				val = null;
			else
				val = floatVal;
			return val;
		},

		updateAlpha: function(){
			this.value.alpha = this.checkNumberValue( this.value.alpha );

			if (this.fieldvalue.value.alpha !== this.value.alpha) {
				this.valueUpdated();
			}
		},

		updateBeta: function(){
			this.value.beta = this.checkNumberValue( this.value.beta );

			if (this.fieldvalue.value.beta !== this.value.beta) {
				this.valueUpdated();
			}
		},

		updateGamma: function(){
			this.value.gamma = this.checkNumberValue( this.value.gamma );

			if (this.fieldvalue.value.gamma !== this.value.gamma) {
				this.valueUpdated();
			}
		},

		valueUpdated: function(){
			this.$parent.valueUpdated( this.field, this.value );
		}


	}

}

</script>

<style lang="stylus">

input.value.euler
	width: 16%
	
	&.alpha , &.beta
		margin-left: 2%
</style>