<!-- Memory vue -->

<template lang="pug">
div.accordion.targets-bloc
	div.accordion-title(v-on:click.stop="accordion = !accordion ")
		span.text Targets
		span.arrow(v-bind:class="{'close':!accordion}")

	div.form-content(v-bind:class="{'open':accordion}")

		div.form-accordion.isAccordion()
			div.title.tab-title
				span.col.col1 Type
				span.col.col2 
				div.clear

			div.form-accordion-content.open

				div.big-bt.field-group

						div.field-ctn.target-line(v-for="clientTarget of availableTargets.value.targets")
							span.text {{clientTarget.title}}

							div.param-ctn
								span.checkbox.plug(v-bind:class="{'selected':getIndexOfClient(clientTarget.name)!=-1}", v-on:click.stop="toggleTarget(clientTarget)")
									input(type="checkbox")
								div.clear
							div.clear
						div.clear
				
								
</template>

<script>

import actionManager from '../../../actions';

export default {

	props:[ 'target', 'availableTargets'],

	data() {
		return {
			accordion: true,
		}
	},

	created: function(){
	},

	methods:{

		getIndexOfClient: function(client){
			for (var i = 0; i < this.target.value.targets.length; i++) {
				if( this.target.value.targets[i].name == client ){
					return i;
				}
			}
			return -1;
		},


		toggleTarget: function(target){

			// let indexTarget = this.getIndexOfClient( target.name );
			actionManager.trigger('project:changeXpTarget', { target: target, xpid: this.target.value._id })
		}
	}

}

</script>

<style lang="stylus">
.targets-bloc
	font-size: 12px
	
	span.col
		float:left
		display: inline-block
		width: 33%
		text-align: left
		line-height: 23px
		vertical-align: middle

		&.col1
			width: 70%
			line-height: 40px

		&.col2
			width: 30%
			text-align: right

	.tab-title
		.col
			line-height: 40px
			height: 40px
			
	.target-line
		position: relative
		
		.param-ctn
			top: 0px
			right: 2px
			
			.checkbox
				margin-top: 0px
					
		

</style>



