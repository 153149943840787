<template lang="pug">

div.video-ctn
	video(controls)
		source(v-for="source of video.sources", :src="source.src", :type="source.type")
	
</template>

<script>

//import video from 'vue-video'

export default {
	
	props: ['media'],
	data() {

		let mimetype = '';
		const ext = this.media.url.match(/\.([0-9a-z]{1,5})$/i);
        if (ext) {
			switch(ext[1].toLowerCase()) {
				case 'ogg':
				case 'ogv':
					mimetype = 'video/ogg';
					break;
				case 'webm' :
					mimetype = 'video/webm';
					break;
				case 'mov':
				case 'mp4':
					mimetype = 'video/mp4';
					break;
			}
        }

		return {
			video: {
                sources: [{
                    src: this.media.url,
                    type: mimetype
                }]
            }
		}
	},
}
</script>

<style lang="stylus">

.video-ctn
	width: 100%
	height: 100%
	display: flex
	justify-content : center
	align-items: center
	video
		width: auto
		height: auto
		max-width: 90%
		max-height: 90%
			
</style>