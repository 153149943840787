import ActionAbstract from '../ActionAbstract';

class SignUp extends ActionAbstract {

    static get ID () {
        return "actions:SignUp";
    }

    run(state) {

        switch (this.getParam('subaction')) {
            case 'success':
                return this.composeState(state, {
                    isLoading: false,
                    signup : {
                        errorMessage: 'User account created',
                    }
                });
            case 'fail':
                return this.composeState(state, {
                    isLoading: false,
                    signup : {
                        errorMessage: 'An error has occurred',
                    }
                });
            default:
                return this.composeState(state, {
                    signup : {
                        errorMessage: this.signup,
                    }
                });
        }

        this.composeState(state, {
            isLoading
        });
    }

    signup() {
        let form = this.getParams(),
            errorMessage = '';

        if( !this.deps.mainStore.user.check( form.apassword ) ){
            errorMessage = "You do not have permission to create an account";
            return errorMessage;
        }

        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        if( form.email == '' || form.password == '' || form.cpassword == '' ){
            errorMessage = 'You must fill all the fields';
            return errorMessage;
        }

        if( !re.test(form.email) ){
            errorMessage = "Your email is invalid";
            return errorMessage;
        }

        if( form.password != form.cpassword ){
            errorMessage = 'Both passwords do not match';
            return errorMessage;
        }

        if (errorMessage !== '') {
            return errorMessage;
        }

        this.deps.mainStore.user.db.signup(form.email, form.password, {
            metadata : {
                date : Math.round(new Date().getTime()/1000)
            }
        }, (err, response) => {
            if( err ){
                this.trigger('SignUp', { subaction: 'fail' });
            }
            else{
                if( response.ok ){
                    this.trigger('SignUp', { subaction: 'success' });
                }
            }
        });
    }


}

export default SignUp;
