import ActionAbstract from './ActionAbstract';

class Dummy extends ActionAbstract {

    static get ID() {
        return 'actions:Dummy';
    }

    run(state) {

       let newState = this.composeState(state, {
            text: this.text,
            inspector: {
                montab: [1,2,3],
                text: "should i stay or should i go?"
            }
        });

        // fake async action
        setTimeout(() => {
            this.trigger('DummyEnd', {index: 2, newValue: 10});
        }, 5000);


        return newState;
    }

    text() {
        return this.getParam('message');
    }

}

export default Dummy;
