import UiObject from './UiObject';

class ChartsUiObject extends UiObject {
    constructor(id, props = [], type = 'NodalUi') {
        super(id, props, type);

    }


    initParameters(){
        super.initParameters();

        let commonGroup = this.createInspectorNode('group', 'common', 'Common Properties' );
        commonGroup.children.push( this.createInspectorNode('group-field', 'common-other-fields') );
        commonGroup.children.push( this.createInspectorNode('group-field', 'domain-fields') );

        let commonCartTopic = this.createInspectorNode( 'topic', 'commonChartSetting', 'Common chart settings' );
        commonCartTopic.children = [ commonGroup ];

        this.inspector.unshift( commonCartTopic );


        let chartsParameters = {
            animate: {
                type: 'Int', 
                default: 0, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Animate",
                    container: "common-other-fields",
                    min: 0,
                    unit : 'ms',
                    description : "duration of the chart\'s animation"
                }
            },
            padding: {
                type: 'Int', 
                default: 0, 
                partial: 'chart',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Padding",
                    container: "common-other-fields",
                    min: 0,
                    description : "padding to apply on the four sides of the chart, in pixels"
                }
            },
            domainX: {
                type: 'Vector2', 
                default: null, 
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Domain X",
                    container: "domain-fields",
                    widget: 'vector2'
                }
            },
            domainY: {
                type: 'Vector2', 
                default: null, 
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Domain Y",
                    container: "domain-fields",
                    widget: 'vector2'
                }
            },
            domainPaddingX: {
                type: 'Vector2', 
                default: null, 
                connection: { in: {pluggable: false, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Domain padding X",
                    container: "domain-fields",
                    widget: 'vector2'
                }
            },
            domainPaddingY: {
                type: 'Vector2', 
                default: null, 
                connection: { in: {pluggable: false, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Domain padding Y",
                    container: "domain-fields",
                    widget: 'vector2'
                }
            },
        };

        this.addToParameters( chartsParameters );
        
    }

}

export default ChartsUiObject;