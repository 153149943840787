
import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';
import Vue from 'vue';

class InitUiState extends ActionAbstract {

    static get ID() {
        return 'actions:editor:ui:initUiState';
    }

    run(state) {
        
        let targetId = this.getParam('targetId') || state.editor.targetId;

        let block = helper.block.getBlockById( state.project, targetId );
        this.initUiObjects( block );

        let screenBlock = helper.block.getBlockById( state.project, block.value.ref_block );
        let xpBlock = helper.block.getBlockById( state.project, screenBlock.value.ref_experience );

        let allWidgets = xpBlock.custom.children.filter( (c) => { if( c.value.type == 'widget' ) return c; return null; });

        let widgetOrder = this.getWidgetOrder(screenBlock);
        let xpObj = { value : { _id : 'current-route', type: 'object', format: 'ui-experience', fields:[] } };
        
        let xp = this.deps.mainStore.ui.createUIObject( xpObj );

        let widgetUiList = this.createAndAddWidgets( state, xp, allWidgets, screenBlock.value._id );

        let widgetUiOrder = [];
        widgetOrder.forEach( (wid) => {
            if( widgetUiList[wid]  )
                widgetUiOrder.push( widgetUiList[wid] );
            else
                widgetUiOrder.push( this.getParam('targetId') );
        });

        xp.set('widgetsOrder', widgetUiOrder);
        xp.setAssetManager( this.deps.mainStore.ui );
        xp.setCtx();

        
        let screen = this.deps.mainStore.ui.get( targetId );
        screen.set('type', 'screen');

        if( screen ){
            
            xp.addChild( screen );

			screen.setAssetManager( this.deps.mainStore.ui );
			//screen.setMediaManager( this.deps.mainStore.lib );
            screen.setCtx();

            // reset lock state (for widget preview)
            let lockField = helper.block.getField( block, 'lock' );
            screen.set('lock', lockField.value);
            
			xp.render();
            
            // @todo : prefer use compose state when possible
            Vue.set(state.editor, 'uiState' , xp.getState()  );

            /*this.composeState( state, {
                    editor : {
                        uiState : screen.getState() 
                    } 
            });*/
        }
        
        return false;
    }

    getWidgetOrder( screenBlock ){


        let widgetsBackground = [];
        let widgetsOverlay = [];
        let screenId = screenBlock.value._id;

        if( !screenBlock.value.widgetsOrder )
            return [screenId];

        let screenIndex = screenBlock.value.widgetsOrder.indexOf( screenId );
        let widgetsActivated = screenBlock.value.widgetsActivated ? screenBlock.value.widgetsActivated : [];
        screenBlock.value.widgetsOrder.forEach( (wid, index) =>{
            if( wid != screenId && widgetsActivated.indexOf( wid ) !== -1 ){
                if( index < screenIndex )
                    widgetsBackground.push( wid );
                else    
                    widgetsOverlay.push( wid );
            }
        });

        return [ ...widgetsBackground, screenId, ...widgetsOverlay ];
    }

    initUiObjects( node , locked = false ){

        if( node.value.type == 'object' && node.custom.tag == 'ui' && !this.deps.mainStore.ui.get( node.value._id ) )
            this.deps.mainStore.ui.createUIObject( node );

        if( node.custom.objects && node.custom.objects.length > 0 ){
            node.custom.objects.forEach( (obj) => {
                if( obj.custom.tag == 'ui' && !this.deps.mainStore.ui.get( obj.value._id ) )
                    this.deps.mainStore.ui.createUIObject( obj );

                let ui = this.deps.mainStore.ui.get( obj.value._id );
                if( locked ){
                    ui.set('lock', true);
                }
                else{
                    let lockField = helper.block.getField( obj, 'lock' );
                    if( lockField )
                       ui.set('lock', lockField.value);
                }

                this.initUiObjects( obj, locked );
            });
        }

        if( node.custom.children && node.custom.children.length > 0 ){
            node.custom.children.forEach( (child) => {
                this.initUiObjects( child, locked );
            });
        }
       
    }

    createAndAddWidgets( state, xpUi , widgets, screenId ){
        let widgetUiList = [];
        widgets.forEach( ( w ) => {
            let wid = w.value._id;
            if( wid != screenId ){

                if( w.custom.objects && w.custom.objects.length > 0 ){

                    let wobj = w.custom.objects[0];
                    this.deps.mainStore.ui.createUIObject( wobj );
                    this.initUiObjects( wobj , true );

                    let wui = this.deps.mainStore.ui.get( wobj.value._id );
                    wui.set('type', 'widget');
                    wui.set('lock', true);
                    wui.setCtx();

                    xpUi.addChild( wui );

                    widgetUiList[wid] = wobj.value._id;
                }

            }
            /*else 
                widgetUiOrder.push( this.getParam('targetId') ); // add current screen ui*/
        });
        return widgetUiList;
    }

}

export default InitUiState;