import React from 'react';
import AdvancedUi from '../AdvancedUi';

import LinearProgress from 'material-ui/LinearProgress';

export default class LinearProgressBar extends AdvancedUi {
    
    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            display : 'block',
        });

    }

    
    render() {

        if( !this.props.input.style )
            this.props.input.style = {};

        this.props.input.style.fontFamily = 'inherit';
        this.props.input.style.backgroundColor = this.props.progressBackground;
        
        return this.wrapHelper(  
            <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                <LinearProgress {...this.props.input} />
            </div>
        );
       

    }

}