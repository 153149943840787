var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tagModal" }, [
    _c("div", {
      staticClass: "cover-back",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.closeModal($event)
        }
      }
    }),
    _c("div", { staticClass: "modal" }, [
      _c("div", {
        staticClass: "bt-close",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.closeModal($event)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "visuAll" }, [
            _vm._m(0),
            _vm._m(1),
            _c(
              "div",
              { staticClass: "taglistMedias" },
              _vm._l(_vm.state.mediasUploaded, function(infos) {
                return _c(
                  "div",
                  { staticClass: "media", on: { click: function($event) {} } },
                  [_vm._v(_vm._s(infos.type) + " - " + _vm._s(infos.filename))]
                )
              }),
              0
            )
          ]),
          _c("Tagging", { attrs: { library: _vm.state, tagModal: true } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("Files uploaded")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mess" }, [
      _c("h3", [_vm._v("Would you like to apply a tag on those files ?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }