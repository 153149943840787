<!-- DraggedItem vue -->
<template lang="pug">
div.dragged-item( v-bind:style="{ transform : 'translate('+this.appState.editor.menu.dragPos.x+'px , '+this.appState.editor.menu.dragPos.y+'px)' }" )
    div.zone

    div.visu(v-if="appState.editor.menu.dragPattern")
        //schematic-bloc(:bloc="fakebloc")

</template>

<script>

// STORE
import { stores } from '../../stores/MainStore.js';

import BlocManager from  './schematic/blocs/BlocManager.vue';

export default {

    /* 
    Props :
        @data = { 
            item : New Object to dragg 
            position : position {x,y} on screen of the dragged Object
        }
    */
	props: [ 'appState' ],

    components: { schematicBloc: BlocManager },

	data() {
		return {
            ctn: null
		}
	},

	created: function(){
        
	},

	watch: {


	},

	methods:{
	}

}

</script>

<style lang="stylus">
.dragged-item
    position fixed
    top 0
    left 0
    z-index 200
    cursor: pointer
    pointer-events: none;

    .zone
        position absolute
        width 50px
        height 30px
        background-color #CCC
        opacity: 0.5
        transform: translate(-50%, -50%)
    
    .visu
        position absolute
        top 0
        left 0
        transform: translate(-50%, -10%)



</style>

