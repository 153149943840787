var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "textinput-ctn displayunit" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.displayedValue,
          expression: "displayedValue"
        }
      ],
      staticClass: "value",
      attrs: {
        type: "text",
        placeholder: _vm.placeholderVal,
        disabled: _vm.field.disabled
      },
      domProps: { value: _vm.displayedValue },
      on: {
        focusout: function($event) {
          return _vm.focusOutField(_vm.field)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.displayedValue = $event.target.value
        }
      }
    }),
    _c("span", { staticClass: "textinput-ctn unit" }, [
      _c("span", { staticClass: "select-arrow" }, [_vm._v(">")]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.unit,
              expression: "unit"
            }
          ],
          attrs: { disabled: _vm.field.disabled },
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.unit = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function($event) {
                return _vm.valueUpdated(_vm.field)
              }
            ]
          }
        },
        [
          _vm.placeholderUnit != "%"
            ? _c("option", { attrs: { value: "%" } }, [_vm._v("%")])
            : _vm._e(),
          _vm.placeholderUnit == "%"
            ? _c("option", { attrs: { value: "%" } }, [_vm._v("% (default)")])
            : _vm._e(),
          _vm.placeholderUnit != "px"
            ? _c("option", { attrs: { value: "px" } }, [_vm._v("px")])
            : _vm._e(),
          _vm.placeholderUnit == "px"
            ? _c("option", { attrs: { value: "px" } }, [_vm._v("px (default)")])
            : _vm._e(),
          _vm.placeholderUnit != "calc"
            ? _c("option", { attrs: { value: "calc" } }, [_vm._v("calc")])
            : _vm._e(),
          _vm.placeholderUnit == "calc"
            ? _c("option", { attrs: { value: "calc" } }, [
                _vm._v("calc (default)")
              ])
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }