import UiObject from '../UiObject';

class UiLinePath extends UiObject {
    constructor(id, props) {
        super(id, props, 'LinePath');

        this.points = [];

    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-line-path",
            label: "LinePath",
            parent : "ui-elements",
            isDisplayed : true,
            deprecated: true,
            isEmbeddable: true
        };
    }



    initParameters(){
        //super.initParameters();

        let general = this.createInspectorNode('group', 'general', 'General settings' );
        general.children.push( this.createInspectorNode('group-field', 'general-fields') );

        let specific = this.createInspectorNode('group', 'specifics', 'Line style' );
        specific.children.push( this.createInspectorNode('group-field', 'specifics-fields') );

        let actions = this.createInspectorNode('group', 'linepoints', 'Line points' );
        actions.children.push( this.createInspectorNode('group-field', 'linepoints-fields') );

        let lineTopic = this.createInspectorNode( 'topic', 'specStyles', 'Specific styles' );
        lineTopic.children = [ general, specific, actions ];

        let transformStyles = this.createInspectorNode('group', 'transform', 'Transform');
        transformStyles.children.push(this.createInspectorNode('group-field', 'transform-fields'));

        let advancedTopic = this.createInspectorNode('topic', 'advancedStyles', 'Advanced styles');
        advancedTopic.children = [transformStyles];


        this.inspector.unshift(lineTopic);
        this.inspector.push(advancedTopic);

        let commonParameters = {

            parent:{
                type: 'String',
                //method: 'addToParent',
                callable: true,
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{
                    container: 'general-fields',
                    widget: 'calculated',
                    label: 'Map container',
                }
            },


            visibility: {
                type: 'Boolean',
                default: true,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Visibility",
                    container: "general-fields"
                }
            },

            opacity: {
                type: 'Float',
                default: 1,
                partial: "marker",
                connection: { in: { pluggable: true, default: false }, out: { pluggable: true, default: false } },
                auth: {
                    label: "Opacity",
                    container: "general-fields"
                }
            },


            customTags: {
                type: 'String',
                default: "",
                partial: null,
                method: 'setCustomTags',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Add tags",
                    container: "general-fields"
                }
            },
            removeTags: {
                type: 'String',
                default: "",
                partial: null,
                method: 'removeTags',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Remove tags",
                    container: "general-fields"
                }
            },

        };

        let otherParameters = {
            // Add param to positionning common settings
            fill: {
                type: 'Color',
                default: { hex : "#FFFFFF" },
                partial: "lineDom",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Fill",
                    container: "specifics-fields"
                }
            },
            stroke: {
                type: 'Color',
                default: { hex : "#000000" },
                partial: "lineDom",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Stroke",
                    container: "specifics-fields"
                }
            },
            strokeWidth: {
                type: 'Float',
                default: 1,
                partial: "lineDom",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Stroke",
                    container: "specifics-fields"
                }
            },
            strokeLinejoin: {
                type: 'String',
                default: "miter",
                partial: "lineDom",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Stroke Line Join",
                    container: "specifics-fields",
                    widget: "select",
                    options : [{value:"miter", label:"Miter"}, {value:"round", label:"Round"}, {value:"bevel", label:"Bevel"}]
                }
            },
            strokeLinecap: {
                type: 'String',
                default: "round",
                partial: "lineDom",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Stroke Line Cap",
                    container: "specifics-fields",
                    widget: "select",
                    options : [{value:"butt", label:"Butt"}, {value:"round", label:"Round"}, {value:"square", label:"Square"}]
                }
            },

            useDash: {
                type: 'Boolean',
                default: false,
                connection: { in: { pluggable: true, default: false }, out: { pluggable: false, default: false } },
                auth: {
                    label: "Use Dashed Line.",
                    container: "specifics-fields",
                    description: "Use dashes and gaps to draw the LinePath."
                }
            },
            lineDashPattern: {
                type: 'String',
                default: '',
                connection: { in: { pluggable: true, default: false }, out: { pluggable: true, default: false } },
                auth: {
                    label: "Dash Pattern",
                    description: "List of numbers defining the LinePath dash pattern (e.g. '0, 20' for dotted line). Separate all values with a comma or space.",
                    container: "specifics-fields",
                    conditions: [{ field: 'useDash', value: true, operator: '==' }]
                }
            },
            lineDashOffset: {
                type: 'Float',
                default: 0,
                connection: { in: { pluggable: true, default: false }, out: { pluggable: true, default: false } },
                auth: {
                    label: "Dash offset",
                    description: "Line dash offset.",
                    container: "specifics-fields",
                    conditions: [{ field: 'useDash', value: true, operator: '==' }]
                }
            },


            closedPath: {
                type: 'Boolean',
                default: false,
                connection:  { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Closed Path",
                    container: "specifics-fields"
                }
            },

            addPoint: {
                type: 'Vector2',
                method: 'addPoint',
                connection:  { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                default: null,
                auth :  {
                    label: "Add point",
                    container: "linepoints-fields",
                    widget: "calculated",
                    conditions: [{ field: 'moduleMode', value: 'update', operator: '==' }]
                }
            },
            clear: {
                type: 'Mixed',
                method: 'clearPoints',
                connection:  { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                default: null,
                auth :  {
                    label: "clear line",
                    container: "linepoints-fields"
                }
            },

        };

        let advancedParameters = {

            // Transform
            scale: {
                type: 'Float',
                default: null,
                partial: 'transform',
                connection: { in: { pluggable: true, default: false }, out: { pluggable: true, default: false } },
                auth: {
                    label: "Scale",
                    container: "transform-fields",
                    description: "Scale to apply on all elements of LinePath."
                }
            },

            transformEasing: {
                type: 'String',
                default: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false }
                },
                auth: {
                    widget: 'select',
                    partial: 'transform',
                    options: [
                        { value: 'linear', label: 'linear' },
                        { value: 'easeIn', label: 'easeIn' },
                        { value: 'easeOut', label: 'easeOut' },
                        { value: 'inAndOut', label: 'inAndOut' },
                        { value: 'upAndDown', label: 'upAndDown' }
                    ],
                    label: 'Easing',
                    container: "transform-fields",
                    description: "Type of tansform to apply on LinePath (see https://openlayers.org/en/latest/apidoc/module-ol_easing.html)."
                }
            },
            transformProperty: {
                default: null,
                type: 'String',
                label: 'Property',
                widget: 'String',
                partial: 'transform',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false }
                },
                auth: {
                    label: 'Property',
                    container: "transform-fields",
                    description: "Which property of LinePath should be impacted during transform changes (e.g. 'all', 'opacity,scale', …)"
                }
            },
            transformDuration: {
                type: 'Float',
                default: null,
                partial: 'transform',
                widget: 'Float',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false }
                },
                auth: {
                    unit: "s",
                    label: 'Duration',
                    container: "transform-fields",
                    description: "Duration on which to apply transform changes."
                }
            }
        };

        this.addToParameters( commonParameters );
        this.addToParameters( otherParameters );
        this.addToParameters(advancedParameters);


        // -> @todo : remove all html parameters from UiObject (to declinate in a UiHtmlObject)


    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }

    init(){
        super.init();
        this.lastInserted = null;

        this.set( 'points', [] );
    }

    addPoint( value ){
        if(!value || value == undefined ) return;

        if( value.x != null && value.y != null && value.x != undefined && value.y != undefined ){
            if( this.lastInserted == null || ( this.lastInserted.x != value.x || this.lastInserted.y != value.y ) ){
                this.points.push( value );
                this.lastInserted = value;

                this.set( 'points', this.points.filter( (a) => { return true; } ) );
            }
        }

    }

    clearPoints( values ){
        if( values === null) return;

        this.points = [];
        this.set( 'points', this.points.filter( (a) => { return true; } ) );

    }

}

export default UiLinePath;
