import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import deepCopy from 'deepcopy';

class DeleteSession extends ActionAbstract {

    /**
     * @param sessId - id of the memory to delete
     */

    static get ID() {
        return 'actions:project:deleteSession';
    }

    run (state) {

        let parent;

        // let projectCopy = deepCopy(state.project);

        // let session = helper.block.getSessionById(projectCopy, this.getParam('sessId'), true)

        let index = state.project.sessions.findIndex((el) => {
            return el.value._id === this.getParam('sessId');
        });

        // session.value._deleted = true;
        // projectCopy.sessions.splice(index, 1);
        let compositeState =  {
            project: { }
        }

        compositeState.project[`sessions[${index}].value`] = { _deleted: true };

        return this.composeState(state, compositeState);

    }

}

export default DeleteSession;