var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c(
      "div",
      { style: { "z-index": _vm.zIndex }, attrs: { id: "slider-panel" } },
      [
        _c("div", {
          staticClass: "cover-back",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.closePanel($event)
            }
          }
        }),
        _c("transition", { attrs: { appear: "", name: "slide" } }, [
          _c("div", { staticClass: "window" }, [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("div", { staticClass: "title-bar" }, [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("icon", { attrs: { name: _vm.name } })],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workspace
                          ? _vm.title +
                              " of " +
                              _vm.capitalize(_vm.workspace.target) +
                              ": " +
                              _vm.capitalize(_vm.workspace.workspaceName)
                          : _vm.title
                      )
                    )
                  ]),
                  _c("div", {
                    staticClass: "bt-close",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.closePanel($event)
                      }
                    }
                  })
                ]),
                _c(_vm.embedComponent, {
                  tag: "component",
                  staticClass: "main",
                  attrs: {
                    state: _vm.state,
                    project: _vm.project,
                    user: _vm.user,
                    workspace: _vm.workspace
                  }
                })
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }