import InspectorActionAbstract from './InspectorActionAbstract';

class UpdateProcessAutoStart extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateProcessAutoStart';
    }

    /**
     * @param targetId
     * @param newName
     */
    run (state) {
        let composite = {project: {}};
        composite.project[this._path + '.value.autoStart'] = this.getParam('choice');

        return this.composeState(state, composite);
    }
}

export default UpdateProcessAutoStart;