<!-- SelectTemplateField vue -->

<template lang="pug">

span.textinput-ctn
	input.value(type="text", v-model="value", disabled="true")
</template>

<script>

import * as helper from '../../../helper'

export default {

	// field    the current field definition
	// bloc     the associated bloc
	// referent the template reference ID
	props:[ 'field', 'bloc', 'referent' ],

	data() {
		return {
			value: null,
			// TODO: prefers pass app.state to childrens, $root or $children references could change
			// (migration from v1: this.$root)
			app: this.$root.$children[0]
		}
	},

	created: function() {
		if ( this.field.widget=='select-template-id' ) this.value = this.referent
		if ( this.field.widget=='select-template-name' ) {
			let template = helper.block.getTemplateById(this.app.state.project,this.referent)
			if ( template ) this.value = template.value.name
		}
		if (this.value) this.update();
	},

	methods: {
		update: function() {
			this.$parent.valueUpdated( this.field , this.value );
		},
	}
}

</script>
