/*
	Field Filter -
	all function to get default Value of node fields
*/

const FieldsFilter = function(defaultRef){

    let self = this
    this.refs = defaultRef

    this.deepEqual = function (x, y) {
        const ok = Object.keys, tx = typeof x, ty = typeof y;
        return x && y && tx === 'object' && tx === ty ? (
          ok(x).length === ok(y).length &&
            ok(x).every(key => this.deepEqual(x[key], y[key]))
        ) : (x === y);
    }

    this.isModule = function (nodeType){
      return self.refs.module[nodeType] ? true : false
    }

    this.isParam = function (mod, fieldName){
      if (self.refs.module[mod].field[fieldName]) return "field"
      else if (self.refs.module[mod].param[fieldName]) return "param"
      return null
    }

    this.isMapBlock = function (type){ // check the right map block
      let tempTestBlock = type
      let actualMapLib = self.refs.mapLibrary
      let mapLibs = ["ui-map-layer", "ui-map-marker", "ui-map-line-path", "ui-map", "ui-map-polygon"]

      // check mapLibrary type
      if (mapLibs.includes(tempTestBlock)) return tempTestBlock.replace("ui-map", actualMapLib);
      else return false;
    }

    this.isModuleUsingObject = function (type, name, blockIncluded, format) {
      // Handle exception ui-Module / Nodal Object

      let objref = self.refs.object

      if ( this.isMapBlock(blockIncluded) ) return objref[this.isMapBlock(blockIncluded)];
      else if ( this.isMapBlock(format) ) {
        return objref[this.isMapBlock(format)]
      } else {

        if (objref[blockIncluded]) return objref[blockIncluded];
        else if (type == "ui" || type == "nodal-object") return objref["ui-object"]
        else return false

      }

    }

    /**
     * Check default value of a field for process / Objects / ui-object and return true if value is modified
     *
     * @param  {Object} field object from an array of field (node of the data project)
     * @param  {String} type the node parent type of the field
     * @param  {String} format the node parent format of the field.
     * @param  {Bool} blockIncluded true if a block is include in the parent node.
     * @param  {String} nodeName Name of the current node of the checked field (debug)
    */

    this.checkDefaultValue = function (field, type, format, blockIncluded, nodeName){ // Compare field with his default value

      let ignoredType = ["note", "space-process", "process"]
      let ignoredFields = [ "olevents", "children", "MapLibrary" ] // fields composés (aucune ref nodal-App)

      let objRef = self.refs.object
      let modRef = self.refs.module
      let change = false

      let isModified = (bool) => {
        return change = bool
      }
      
      if( ignoredFields.includes(field.name) || ignoredType.includes(type) || field.repeatedField) return isModified(true)

      // If the field belongs to a module
      let typeTarget = this.isModule(type) ? this.isParam(type, field.name) : null
      let fieldTarget = typeTarget ? modRef[type][typeTarget] : objRef[format]
      if (fieldTarget) {
        if (fieldTarget[field.name]) {
          if (fieldTarget[field.name].selectType) return isModified(true)  // always keep select field/param
        }
      }

      if (!fieldTarget) fieldTarget = objRef[format] ? objRef[format] : null
      if (!fieldTarget) fieldTarget = this.isModuleUsingObject(type, field.name, blockIncluded, format)

      if (fieldTarget) {
        if (!fieldTarget[field.name])  return isModified(true) // outside normal pattern -> keep the field
      } else return isModified(true) 

      if ( typeof field.value == "object" && fieldTarget[field.name]) {

        let testValue = JSON.parse(JSON.stringify(field.value)) //Remove vueJS meta observer
        if (!this.deepEqual(fieldTarget[field.name].value, testValue)) return isModified(true)

      } else if (fieldTarget[field.name] == undefined || fieldTarget[field.name].value !== field.value) return isModified(true)

      if (fieldTarget[field.name].in !== field.in   && typeTarget !== "param") return isModified(true)
      if (fieldTarget[field.name].out !== field.out && typeTarget !== "param") return isModified(true)

      return change
    }

    /**
     * Repopulate field List of object using json ref declaration from Nodal-App declaration.
     *
     * @param  {Object} child the node block to be set in the hierarchy of the project
    */

    this.fieldListReconstructor = function(child) {
      let targetDefaultRef = {}
      let isModuleNode;

      // check if the child node is an nodal object
      if (child.value.includedBlockType) targetDefaultRef = this.refs.object[child.value.includedBlockType[0]]
      else targetDefaultRef = this.refs.object[child.value.format] ? this.refs.object[child.value.format] : null

      // check if the child node is an nodal module
      if (!targetDefaultRef) {
        isModuleNode = this.refs.module[child.value.type] ? this.refs.module[child.value.type] : null
        if (isModuleNode) {
          targetDefaultRef = Object.assign(isModuleNode.param, isModuleNode.field) 
        }
      }

      let rebuildedChild = child

      if(targetDefaultRef) { // Build Missing fields

        let targetRefCopy = JSON.parse(JSON.stringify(targetDefaultRef))
        
        // node fields from couchDB document
        // Need to handle link to check field without value key.
        child.value.fields.forEach( (nodefield) => {
          targetRefCopy[nodefield.name] = nodefield
        })

        let completeFieldList = []

        for (var fieldref in targetRefCopy) {
          let rebuildObject = {name: fieldref}
          let refDefObject = JSON.parse(JSON.stringify(targetRefCopy[fieldref])) // Avoid VueJS observer
          let rebuilded = Object.assign(refDefObject, rebuildObject)
          completeFieldList.push(rebuilded)
        }
        rebuildedChild.value.fields = completeFieldList
        return rebuildedChild
      }

      return null
    }
}

export default FieldsFilter;
