import ServiceManager from '../services/ServiceManager';

const PREFIX = 'actions:'

class ActionManager extends ServiceManager{

    static get ID () {
        return 'actions:ActionManager';
    }

    trigger(action, payload) {
        let actionInst = this.get(PREFIX + action, false);
        actionInst.setParams(payload);

        this.deps.actionHandler.pushStack(actionInst);
    }

    static register(action, deps = []) {
        deps.unshift('actions:ActionManager');
        ServiceManager.register(action, deps);
    }

}

export default ActionManager;