import ActionAbstract from '../../ActionAbstract';
import Vue from 'vue';

/**
 * Action parameters
 *
 * @param {String} parentId - The parent identifier to reorder childrens on (optinal but required with 'childrenOrder')
 * @param {Array} childrenOrder — The list of children's identifier in order (optinal but required with 'parentId')
 * @param {Array} widgetOrder — The widget order for the current screen (optinal)
 */
class UpdateUiState extends ActionAbstract {

    static get ID() {
        return 'actions:editor:ui:updateUiState';
    }

    run(state) {
        if (!state.editor || !state.editor.targetId) return false;

        let xp = this.deps.mainStore.ui.get('current-route');
        if (xp) {
            if (this.getParam("widgetOrder")) xp.set('widgetsOrder', this.getParam("widgetOrder"));
            if (this.getParam("childrenOrder")) {
                const uiobj = this.deps.mainStore.ui.get(this.getParam("parentId"));
                uiobj.setChildrenOrder(this.getParam("childrenOrder"));
            }
            Vue.set(state.editor, 'uiState', Object.assign({}, xp.getState()));
        }

        return false;
    }
}

export default UpdateUiState;
