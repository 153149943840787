var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "textinput-ctn", class: { "has-unit": _vm.field.unit } },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value"
          }
        ],
        staticClass: "value",
        attrs: {
          type: "text",
          placeholder: _vm.placeholder,
          disabled: _vm.field.disabled
        },
        domProps: { value: _vm.value },
        on: {
          focusout: function($event) {
            return _vm.focusOutField(_vm.field)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.value = $event.target.value
          }
        }
      }),
      _vm.field.unit
        ? _c("span", { staticClass: "unit" }, [_vm._v(_vm._s(_vm.field.unit))])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }