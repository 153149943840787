var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "textinput-ctn" }, [
    _c("span", { staticClass: "select-arrow" }, [_vm._v(">")]),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value"
          }
        ],
        attrs: { disabled: _vm.field.disabled },
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.value = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            function($event) {
              return _vm.valueUpdated(_vm.field)
            }
          ]
        }
      },
      [
        _vm._l(_vm.options, function(option) {
          return !_vm.isGrouped
            ? _c("option", { domProps: { value: option.value } }, [
                _vm._v(_vm._s(option.text))
              ])
            : _vm._e()
        }),
        _vm._l(_vm.options, function(group, name) {
          return _vm.isGrouped
            ? _c(
                "optgroup",
                { attrs: { label: name } },
                _vm._l(group, function(option) {
                  return _c("option", { domProps: { value: option.value } }, [
                    _vm._v(_vm._s(option.text))
                  ])
                }),
                0
              )
            : _vm._e()
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }