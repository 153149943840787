/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable curly */

// @todo: Use the ol package from npm and remove this vendor, retrieve the version from the package
// and review all the code below with the ol module.
import ol from '../../../vendor/ol';
// import { VERSION } from 'ol';
// export const OL_VERSION = VERSION;
export const OL_VERSION = '6.5';

// quiet for the authoring env
// eslint-disable-next-line no-undef
if (typeof env === 'undefined' || !env.isAuthoring) {
    console.log(
        `%c[OpenLayers]%c version vendor ${OL_VERSION}%c`,
        'background: #BBDEFB; color: #FF5722; font-weight: 500',
        'font-weight: 300',
        '',
    );
}

// World Geodetic System 1984 - unit degree (https://epsg.io/4326)
const WGS84 = 'EPSG:4326';
// WGS 84 / Pseudo-Mercator - unit meter  (https://epsg.io/3857)
const PSEUDO_MERCATOR = 'EPSG:3857';

/**
 * The OpenLayer context containing a map and some layer references, including methods to create
 * manipulate and interact with map features as well as event listeners.
 */
class OpenLayersContext {
    /**
     * Create a new OpenLayer context contructor.
     * @param {HTMLElement/string} target - The container for the map, either the element itself or
     * the id of the element.
     */
    constructor(target) {
        this.map = null;
        this.layers = new Map();

        // list of current feature interacton
        this.interactions = {
            features: new ol.Collection(),
        };

        // list of listeners
        // key => feature id, value => callbacks
        this.listeners = new Map();
    }

    // createMap() {
    //     this.map = new Map({
    //         target,
    //         layers: [
    //             new TileLayer({
    //                 source: new OSM(),
    //             }),
    //         ],
    //         view: new View({
    //             center: [0, 0],
    //             zoom: 0,
    //         }),
    //     });
    // }

    /**
     * Attach and set an existing map as the core component for this context.
     *
     * @param {ol.Map} map - The map to be attached.
     * @returns {String} - The map unique identifier
     */
    registerMap(map) {
        this.map = map;
        //console.log('---> register map', this.map.ol_uid);

        // attach listeners to this map
        this.map.on('singleclick', this.mapClickHandler.bind(this));
        this.map.on('dblclick', this.mapDoubleClickHandler.bind(this));
        this.map.on('pointermove', this.mapMoveHandler.bind(this));
        this.map.on('moveend', this.mapMoveEndHandler.bind(this));

        return this.map.ol_uid;
    }

    /**
     * Attach an existing layer to this context.
     *
     * @param {ol.layer} layer - The layer to be registered.
     * @returns {String} - The layer unique identifier or false on failure.
     */
    registerLayer(layer) {
        if (!this.layers.has(layer.ol_uid)) {
            this.layers.set(layer.ol_uid, layer);
            //console.log('---> register map layer', layer.ol_uid);
        }
        //else console.log('---> layer already registered', layer.ol_uid);
        return layer.ol_uid;
    }

    /**
     * Detach a layer from this context.
     *
     * @param {String|int} l_uid - The layer unique identifier to be removed.
     * @returns {Boolean} - True on sucess, false if the layer or the map is not found.
     */
    unregisterLayer(l_uid) {
        if (this.map) {
            if (this.layers.has(l_uid)) {
                const layer = this.map.removeLayer(this.layers.get(l_uid));
                if (layer !== undefined) {
                    this.layers.delete(l_uid);
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Create a new style from the given options.
     *
     * @param {*} options - The style options i.e. user requirements
     * @returns {ol.style.Style} - The style definition
     */
    createStyle(options) {
        let fill = null;
        let stroke = null;

        if (options.fill) {
            fill = new ol.style.Fill({
                color: options.fillColor,
            });
        }
        if (options.border) {
            stroke = new ol.style.Stroke({
                color: options.borderColor,
                width: options.borderWidth,
                lineCap: options.borderLineCap,
                lineJoin: options.borderLineJoin,
            });
            if (options.dashedLine) {
                stroke.setLineDash(options.dashPattern);
                stroke.setLineDashOffset(options.dashOffset);
            }
        }

        return new ol.style.Style({ fill, stroke });
    }

    // temporary fonction to view elments on a particular layer
    listFeatures(source, method) {
        const features = source.getFeatures();
        console.log('... map features size', features.length, ' method --> ', method)
        features.forEach((feature) => {
            console.log('...... feature', feature.getId()); // end callback
        });
    }

    /**
     * Set the style for the specified map feature to override the default layer style.
     *
     * @param {String|int} l_uid - The unique identifier of the parent layer.
     * @param {String|int} f_uid - The map feature unique identifier.
     * @param {ol.Style} style - The new map feature style
     */
    setStyle(l_uid, f_uid, style) {
        const feature = this.getFeatureById(f_uid, l_uid);
        if (feature) feature.setStyle(style);
        this.listFeatures(this.layers.get(l_uid).getSource(), 'set style');
    }

    /**
     * Add a new map feature to the specified layer using an object as definition.
     * The layer must be exits.
     *
     * @param {String|int} l_uid - The unique identifier of the parent layer.
     * @param {Object} source - The map feature definition as Object.
     * @param {ol.Style} style - The map feature style.
     *
     * @returns {String|int} - The feature unique identifier on success or -1 if the layer is not
     * found
     */
    addFeature(l_uid, source, style) {
        if (!this.layers.has(l_uid)) return -1;

        const format = new ol.format.GeoJSON();
        const feature = format.readFeature(source);

        this.layers.get(l_uid).getSource().addFeature(feature, { dataProjection: PSEUDO_MERCATOR });
        feature.setId(feature.ol_uid);
        feature.setStyle(style);
        this.listFeatures(this.layers.get(l_uid).getSource(), 'add feature');
        return feature.ol_uid;
    }

    /**
     * Add a new map feature to the specified layer from a GeoJSON.
     * The layer must be exits.
     *
     * @param {String|int} l_uid - The unique identifier of the parent layer.
     * @param {Sring} data - The GeoJSON data representing the feature.
     * @param {ol.Style} style - The map feature style.
     *
     * @returns {String|int} - The feature unique identifier on success or -1 if the layer is not
     * found
     */
    addFeatureFromGeoJSON(l_uid, data, style) {
        if (!this.layers.has(l_uid)) return -1;

        const format = new ol.format.GeoJSON();
        const features = format.readFeatures(data, { featureProjection: PSEUDO_MERCATOR });
        const feature = features[features.length - 1];

        this.layers.get(l_uid).getSource().addFeature(feature);
        feature.setId(feature.ol_uid);
        feature.setStyle(style);
        this.listFeatures(this.layers.get(l_uid).getSource(), 'add feature (from JSON)');
        return feature.ol_uid;
    }

    /**
     * Remove a single feature from a layer source.
     *
     * @param {String|int} f_uid - The map feature unique identifier.
     * @param {String|int} l_uid - The unique identifier of the parent layer. If this parameter
     * is ommited, the parent layer will be searched beforehand, increasing latency.
     *
     * @returns {Boolean} - True on sucess, false if the feature or the parent layer are not found.
     * @todo: If this parameter is ommited, the parent layer will be searched beforehand
     */
    removeFeature(f_uid, l_uid = null) {
        if (this.layers.has(l_uid)) {
            const source = this.layers.get(l_uid).getSource();
            let feature = source.getFeatureById(f_uid);
            // id may not set, try comparing ol_uid
            if (!feature) feature = source.getFeatures().find((f) => f.ol_uid === f_uid);
            if (feature) {
                source.removeFeature(feature);
                this.removeListeners(f_uid);
                this.listFeatures(source, 'remove feature');
                return true;
            }
        }
        return false;
    }

    /**
     * Get a feature from a layer source.
     * If the parent layer is not specified, seach in all layers (increasing latency).
     *
     * @param {String|int} f_uid - The map feature unique identifier.
     * @param {String|int} l_uid - The unique identifier of the parent layer.
     *
     * @returns {ol.Feature} - The map feature or null and failure.
     * @todo:search in all layers
     */
    getFeatureById(f_uid, l_uid = null) {
        if (this.layers.has(l_uid)) {
            const source = this.layers.get(l_uid).getSource();
            return source.getFeatureById(f_uid);
        }
        return null;
    }

    /**
     * Get the coordinate array for the specified map feature geometry.
     *
     * @param {String|int} f_uid - The map feature unique identifier.
     * @param {String|int} l_uid - The unique identifier of the parent layer.
     *
     * @returns {Array} - The coordinate array of the feature geometry.
     */
    getFeatureCoordinates(f_uid, l_uid) {
        const feature = this.getFeatureById(f_uid, l_uid);
        return feature ? feature.getGeometry().getCoordinates() : [];
    }

    /**
     * Get the GeoJSON definition for the specified map feature.
     *
     * @param {String|int} f_uid - The map feature unique identifier.
     * @param {String|int} l_uid - The unique identifier of the parent layer.
     *
     * @returns {String} - The feature GeoJSON.
     */
    getFeatureGeoJSON(f_uid, l_uid) {
        const feature = this.getFeatureById(f_uid, l_uid);
        return feature ? this.toGeoJSON(feature) : '{}';
    }

    /**
     * Get the extent for the given map feature.
     *
     * @param {ol.Feature} feature - The map feature.
     * @returns {ol.Feature} - The feature extent.
     */
    getFeatureExtent(feature) {
        const extent = feature.getGeometry().getExtent();
        return new ol.Feature({
            // eslint-disable-next-line new-cap
            geometry: new ol.geom.Polygon.fromExtent(extent),
        });
    }

    /**
     * Get the coordinate array for the specified map feature extent.
     *
     * @param {String|int } f_uid - The map feature unique identifier.
     * @param {String|int } l_uid - The unique identifier of the parent layer.
     *
     * @returns {Array} - The coordinate array of the feature extent.
     */
    getExtentCoordinates(f_uid, l_uid) {
        const feature = this.getFeatureById(f_uid, l_uid);
        return feature ? this.getFeatureExtent(feature).getGeometry().getCoordinates() : [];
    }

    /**
     * Get the GeoJSON definition for the specified map feature extent.
     *
     * @param {String|int} f_uid - The map feature unique identifier.
     * @param {String|int} l_uid - The unique identifier of the parent layer.
     *
     * @returns {String} - The extent GeoJSON.
     */
    getExtentGeoJSON(f_uid, l_uid) {
        const feature = this.getFeatureById(f_uid, l_uid);
        return feature ? this.ExtentToGeoJSON(feature) : '{}';
    }

    /**
     * Attach a new interaction to the parent map.
     *
     * @param {String} interaction - The interaction to be added: draw or modify.
     * @param {Object} options - The interaction options containg event callbacks, styles and at
     * least the parent layer.
     *
     * @returns {String} - The error message on failure.
     * @todo: do we need to separate snap and/or interactions per feature
     */
    addInteraction(interaction, options) {
        //console.log('---> add interaction', interaction, options, this.layers)
        const parent = options.layer;
        const source = this.layers.has(parent) ? this.layers.get(options.layer).getSource() : null;
        if (!source) return 'Layer source not found.';

        // be sure to start from zero
        if (interaction in this.interactions) {
            this.removeInteraction(interaction);
        }

        if (options.snap && !this.interactions.snap) {
            this.interactions.snap = new ol.interaction.Snap({ source });
            this.map.addInteraction(this.interactions.snap);
        }

        if (interaction === 'draw') {
            const onDrawEnd = () => {
                const feature = source.getFeatures().find((f) => f.getId() === undefined);
                if (feature) {
                    feature.setId(feature.ol_uid);
                    if (options.style) feature.setStyle(options.style);
                    if (options.drawend) options.drawend(feature.getId()); // end callback
                }
                else {
                    // feature should always exist but in case send message to user
                    const message = 'Failed to find drawn element.';
                    console.warn(message);
                    if (options.drawabort) options.drawabort(message); // error (abort) callback
                }
            }
            this.interactions.draw = new ol.interaction.Draw({ source, type: options.type });
            this.interactions.draw.on('drawabort', () => {
                source.un('addfeature', onDrawEnd);
                if (options.drawabort) options.drawabort(); // abort callback
            });
            this.interactions.draw.on('drawstart', () => {
                if (options.drawstart) options.drawstart(); // start callback
                source.once('addfeature', onDrawEnd);
            });
            this.map.addInteraction(this.interactions.draw);
            return;
        }

        if (interaction === 'modify') {
            const feature = this.getFeatureById(options.feature, options.layer);
            if (!feature) return 'Feature not found.';
            this.interactions.features.push(feature);
            this.interactions.modify = new ol.interaction.Modify({ features: this.interactions.features });
            if (options.modifystart) this.interactions.modify.on('modifystart', options.modifystart); // abort callback
            if (options.modifyend) this.interactions.modify.on('modifyend', (event) => {
                const features = event.features.getArray();
                features.forEach((f) => {
                    options.modifyend(f.getId()); // end callback
                });
            });
            this.map.addInteraction(this.interactions.modify);
            return;
        }
        //console.error('---> add interaction failed', interaction)
        return 'Invalid or not implemented interaction.';
    }

    /**
     * Abort an interaction on the parent map.
     *
     * @param {String} interaction - The interaction to be aborted: draw.
     * @returns {Boolean} - True on success, false otherwise.
     */
    abortInteraction(interaction) {
        //console.log('---> abort interaction', interaction)
        switch (interaction) {
        case 'draw':
            if (this.interactions.draw) {
                this.interactions.draw.abortDrawing();
                return true;
            };
            break;
        }
        //console.error('---> abort interaction failed', interaction)
        return false;
    }

    /**
     * Detach an interaction from the parent map.
     *
     * @param {String} interaction - The interaction to be removed: draw or modify.
     * @returns {Boolean} - True on success, false otherwise.
     */
    removeInteraction(interaction) {
        //console.log('---> remove interaction', interaction)
        if (this.interactions.snap) {
            this.map.removeInteraction(this.interactions.snap);
            delete this.interactions.snap;
        }

        switch (interaction) {
        case 'draw':
            if (this.interactions.draw) {
                this.map.removeInteraction(this.interactions.draw);
                delete this.interactions.draw;
            }
            return true;
        case 'modify':
            if (this.interactions.modify) {
                this.map.removeInteraction(this.interactions.modify);
                this.interactions.features = new ol.Collection();
                delete this.interactions.modify;
            }
            return true;
        }
        //console.error('---> remove interaction failed', interaction)
        return false;
    }

    /**
     * Register events for the specified map feature.
     *
     * @param {String|int } f_uid - The map feature unique identifier.
     * @param {Object} events - An object containg event callback methods.
     */
    addListeners(f_uid, events) {
        this.listeners.set(f_uid, events);
    }

    /**
     * Unregister events for the specified map feature.
     *
     * @param {String|int} f_uid - The map feature unique identifier.
     */
    removeListeners(f_uid) {
        this.listeners.delete(f_uid);
    }

    /**
     * Triggered when user click on the map, with no dragging.
     * @param {ol.MapBrowserEvent} e - The map event.
     * @private
     */
    mapClickHandler(e) {
        let trigged = false;
        try {
            const features = this.map.getFeaturesAtPixel(e.pixel);
            if (features) {
                trigged = features.some((feature) => {
                    const id = feature.getId();
                    if (this.listeners.has(id)) {
                        //console.log('---> polygon clicked', id)
                        this.listeners.get(id).click();
                        e.stopPropagation();
                        return true;
                    }
                });
            }
        }
        catch (err) { console.warn(`Error while clicking on map: ${err.message}`) }

        //if (!trigged) console.log('---> map clicked')
    }

    /**
     * Triggered when user double click on the map, with no dragging.
     * @param {ol.MapBrowserEvent} e - The map event.
     * @private
     */
    mapDoubleClickHandler(e) {
        let trigged = false;
        try {
            const features = this.map.getFeaturesAtPixel(e.pixel);
            if (features) {
                trigged = features.some((feature) => {
                    const id = feature.getId();
                    if (this.listeners.has(id)) {
                        //console.log('---> polygon double clicked', id)
                        this.listeners.get(id).dbclick();
                        e.stopPropagation();
                        return true;
                    }
                })
            }
        }
        catch (err) { console.warn(`Error while double clicking on map: ${err.message}`) }

        //if (!trigged) console.log('---> map double clicked')
    }

    /**
     * Triggered when a pointer is moved.
     * On touch devices this is triggered when the map is panned.
     *
     * @param {ol.MapBrowserEvent} e - The map event.
     * @private
     */
    mapMoveHandler(e) {
        try {
            const features = this.map.getFeaturesAtPixel(e.pixel);
            this.listeners.forEach((callbacks, id) => {
                if (!features) callbacks.move(false);
                else {
                    const over = features.some((feature) => feature && feature.getId() === id);
                    callbacks.move(over);
                }
            });
        }
        catch (err) { console.warn(`Error while moving on map: ${err.message}`) }
    }

    /**
     * Triggered after the map is moved.
     *
     * @param {ol.MapBrowserEvent} e - The map event.
     * @private
     */
    mapMoveEndHandler(e) {
    }

    /**
     * Get and returns the extent of the given feature as GeoJSON.
     * (i.e. get Bounding rectangle corner coordinates)
     *
     * @param {Object} feature - The feature to be converted.
     * @returns {String} Returns the GeoJSON result
     */
    ExtentToGeoJSON(feature) {
        feature = this.getFeatureExtent(feature);
        return this.toGeoJSON(feature);
    }

    /**
     * Convert the given feature to GeoJSON.
     *
     * @param {Object} feature - The feature to be converted.
     * @returns {String} The GeoJSON result
     */
    toGeoJSON(feature) {
        const format = new ol.format.GeoJSON();
        const geometry = feature.getGeometry().clone();
        geometry.transform(PSEUDO_MERCATOR, WGS84);

        return format.writeFeatures([
            new ol.Feature({ geometry }),
        ]);
    }

    /**
     * Converts a geographic coordinate ([latitude,longitude] or {lat:0, lon:0}) to a map point 
     * coordinate.
     *
     * @param {Array|Object} latlon - The coordinate as latitude and longitude.
     * @returns {Array} - The map point coordinate
     * @static
     */
    static geoToMapCoordinate(latlon) {
        if (!Array.isArray(latlon)) latlon = [latlon.lat, latlon.lng];
        return ol.proj.transform(latlon, WGS84, PSEUDO_MERCATOR);
    }

    /**
     * Converts a map point coordinate to a geographic coordinate (i.e. [longitude,latitude]).
     *
     * @param {ol.coordinate} coords - The coordinate of the point.
     * @returns {Array} - The longitude and latitude in array.
     * @static
     */
    static mapToGeoCoordinate(point) {
        return ol.proj.transform(point, PSEUDO_MERCATOR, WGS84);
    }
}
export default OpenLayersContext;
