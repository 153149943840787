var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userLogged != null
    ? _c("div", { staticClass: "links" }, [
        _vm._m(0),
        _c("div", { staticClass: "logging" }, [
          _c("ul", [
            _c("li", [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.goToHome($event)
                    }
                  }
                },
                [_vm._v("Home")]
              )
            ]),
            !_vm.userLogged
              ? _c("li", [
                  _c("a", { on: { click: _vm.goToConnexion } }, [
                    _vm._v("Log In")
                  ])
                ])
              : _vm._e(),
            _vm.userLogged
              ? _c("li", [
                  _c("a", { on: { click: _vm.logout } }, [_vm._v("Log Out")])
                ])
              : _vm._e(),
            !_vm.userLogged
              ? _c("li", [
                  _c("a", { on: { click: _vm.goToSubscribe } }, [
                    _vm._v("Create An Account")
                  ])
                ])
              : _vm._e()
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "categories" }, [
      _c("ul", [
        _c("li", [_c("a", { attrs: { href: "#tour" } }, [_vm._v("Tour")])]),
        _c("li", [
          _c("a", { attrs: { href: "#showcase" } }, [_vm._v("Showcase")])
        ]),
        _c("li", [
          _c("a", { attrs: { href: "#contact" } }, [_vm._v("Participate")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }