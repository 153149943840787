import { EventEmitter } from 'events';

export const STATE_CHANGE = 'STATE_CHANGE';
export const EVENT_CALLBACK = 'EVENT_CALLBACK';


class UiObject extends EventEmitter {

    constructor(id, props = [], type = 'NodalUi', inheritParams = true) {
        super();

        this.version = 0;
        /* temp */
        this.id = id;
        this.cmpType = type;
        this.appRenderer = () => !window?.origin.includes('studio'); // false equal to authoring env

        this.level = 0;
        this.childrenIdByCols = [];
        this.children = [];
        this.customTags = [];

        this.initProps = [];
        this.childrenId = [];
        let reduceTab = function( arr ){  return arr.reduce(  (a, b) => a.concat( Array.isArray(b) ? reduceTab(b) : b  ),  []  ) };

        props.forEach( (prop) => {
            if( prop.name !== "children" ){
                // @todo @fixme: some prop.name are missing with old project and new environnement probably (not sure)
                if (prop.name) this.initProps.push({ name: prop.name, value: prop.value });
            }
            else{
                this.childrenIdByCols = prop.value;
                let value = this.childrenIdByCols && Array.isArray(this.childrenIdByCols) ? reduceTab(this.childrenIdByCols) : null ;
                this.childrenId = value || [];

            }
        });

        //this.objectAsset = serviceManager.require('object-asset');
        this.assetManager = null;
        this.mediaManager = null;

        this.ctx;
        this._state = null;


        this.inspector = [];
        this.parameters = {};

        if( inheritParams === true ) this.initParameters();

        this.cache = false;

        // content all modules triggering this UI
        // ley as module.id
        this.triggerModules = {};
    }

    static getAuthConfig(){
        return {
            exportFormat : null,
            label: null,
            parent : null,
            isDisplayed : false,
            isEmbeddable: false,
            nbSection: 0
        };
    }

    static getMouseEvents(){
        return [ 'onClick', 'onMouseDown', 'onMouseUp', 'onMouseOver', 'onMouseOut', 'onMouseMove', 'onDoubleClick', 'onMouseEnter', 'onMouseLeave', 'onTouchStart', 'onTouchEnd' ];
    }

    static getTouchEvents(){
        return [ 'onTap', 'onPress', 'onPan', 'onSwipe', 'onPinch' ];
    }

    get nbSection(){
        return this.constructor.getAuthConfig().nbSection;
    }

    get state() {
        return this._state;
    }

    set state (value) {
        this._state = value;
    }

    _initProps(){
        // set default values
        let paramKeys = Object.keys( this.getAllParameters() );
        paramKeys.forEach(param => {
            if( param != 'children' && this.getParameter(param).type != 'Repeated' && !this.getParameter(param).method  ){
                this.set( param, this.getParameter(param).default );
            }
        });

        this.initProps.forEach(prop => {
            this.set(prop.name, prop.value);
        });

    }


    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }

    init(){
        //init props

        this.initState();

        this.set( '_id', this.id );
        this.set( 'dom', { className: this.cmpType, id : this.id }, true );
        this.set( 'nbSection', this.nbSection );

        if( this.assetManager )
            this.set('tags', this.assetManager.listTag( this.id ) );

        this._initProps();

        this._eventCallback = this.eventCallback.bind(this);
        this.set( 'eventCallback', this._eventCallback );

        this._onChildStateChange = this._onChildStateChange.bind(this);

        this._stateEventEnabled = true;

        this.inspector = null;
        let paramKeys = Object.keys( this.getAllParameters() );
        paramKeys.forEach(key => {
            // add options for select
            if (this.parameters[key].auth.widget === 'select') {
                this.parameters[key].options = this.parameters[key].auth.options;
            }
            delete this.parameters[key].auth;
            delete this.parameters[key].connection;
        });
    }

    dispose(){
        this.removeAllChildren();
        this.parameters = null;
        this.initProps = null;
    }

    reset( recursivly = false ){

        this._stateEventEnabled = false;

        let paramKeys = Object.keys( this.getAllParameters() );
        paramKeys.forEach(param => {
            if( param != 'children' && this.getParameter(param).type != 'Repeated' && !this.getParameter(param).method  && !this.getParameter(param).event ){
                this.set( param, null );
            }
        });

        this._initProps();

        if( recursivly === true && this.children.length > 0 ){
            this.children.map((child) => {
                child.reset( recursivly );
            });
        }

        this._stateEventEnabled = true;

        this.emit(STATE_CHANGE, this.render());
    }

    resetUi( param ){
        if( param !== null && param !== undefined )
            this.reset( true );
    }

    eventCallback( name, payload ){
        this.emit(EVENT_CALLBACK, {name : name , value: payload });
    }

    setNestedLevel( lvl ){

        this.level = lvl ;
        this.set( 'nestedLevel', this.level );

        this.children.map((child) => {
            if (child) child.setNestedLevel( lvl + 1 );
        });
    }

    createInspectorNode( type , name , label ){
        if( type == "group-field" )
            return { type : type, name : name };

        return { type : type , name : name, title : label, isAccordion : true , children : [] };
    }


    addToParameters( params ){
        this.parameters = Object.assign( {} , params, this.parameters );
    }

    updateParamConnexion( param, io, value ){
        this.getParameter(param).connection[io] = value;
    }

    setDefaultValue( param, value ){
        this.getParameter(param).default = value;
    }

    setParamLabel( param, label ){
        this.getParameter(param).auth.label = label;
    }

    setWidget( param, type ){
        this.getParameter(param).auth.widget = type;
    }

    disableParam( param ){
        this.getParameter(param).auth.disabled = true;
    }

    setParamInvisible( param ){
        this.disableParam( param );
        this.updateParamConnexion( param, 'in', {pluggable: false, default: false} );
        this.updateParamConnexion( param, 'out', {pluggable: false, default: false} );
        this.getParameter(param).auth.invisible = true;
    }

    initParameters(){


        let groupGeneral = this.createInspectorNode('group', 'general', 'General' );
        groupGeneral.children.push( this.createInspectorNode('group-field', 'general-fields') );

        let groudChildhood = this.createInspectorNode('group', 'general', 'Childhood' );
        groudChildhood.hideForObjInspector = true,
        groudChildhood.children.push( this.createInspectorNode('group-field', 'parents-fields') );
        groudChildhood.children.push( this.createInspectorNode('group-field', 'childs-fields') );

        let groupSize = this.createInspectorNode('group', 'size', 'Size' );
        groupSize.children.push( this.createInspectorNode('group-field', 'size-fields') );

        let groupPos = this.createInspectorNode('group', 'positionning', 'Positioning' );
        groupPos.children.push( this.createInspectorNode('group-field', 'pos-fields') );

        let groupBackground = this.createInspectorNode('group', 'background', 'Background' );
        groupBackground.children.push( this.createInspectorNode('group-field', 'background-fields') );

        let groupShadow = this.createInspectorNode('group', 'shadow', 'Box shadow' );
        groupShadow.children.push( this.createInspectorNode('group-field', 'shadow-fields') );

        let groupMargin = this.createInspectorNode('group', 'margin', 'Margin' );
        groupMargin.children.push( this.createInspectorNode('group-field', 'margin-fields') );

        let groupPadding = this.createInspectorNode('group', 'padding', 'Padding' );
        groupPadding.children.push( this.createInspectorNode('group-field', 'padding-fields') );

        let groupBorder = this.createInspectorNode('group', 'border', 'Border' );
        groupBorder.children.push( this.createInspectorNode('group-field', 'border-fields') );

        let stylesTopic = this.createInspectorNode( 'topic', 'styles', 'Common styles' );
        stylesTopic.children = [ groupGeneral, groudChildhood, groupSize, groupPos, groupBackground, groupShadow, groupMargin, groupPadding, groupBorder ];


        let eventsTopic = this.createInspectorNode( 'topic', 'events', 'Events' );


        let mouseEvents = UiObject.getMouseEvents();
        let touchEvents = UiObject.getTouchEvents();

        let mouseEventGroup = this.createInspectorNode('group', 'mouseevents-group', 'Mouse Events List' );
        let touchEventGroup = this.createInspectorNode('group', 'touchevents-group', 'Touch Gesture List' );

        let self = this;

        mouseEvents.forEach( ( evt ) => this.addGroupFieldForEvent( mouseEventGroup, 'mevents', evt ) );
        touchEvents.forEach( ( evt ) => this.addGroupFieldForEvent( touchEventGroup, 'tevents', evt ) );

        eventsTopic.hideForObjInspector = true;
        eventsTopic.children = [ mouseEventGroup, touchEventGroup ];

        this.inspector.push( stylesTopic );
        this.inspector.push( eventsTopic );


        let commonParameters = {

            // GENERAL
            children: {
                type: 'Mixed',
                default: [],
                partial: null,
                auth :  {
                    label: "",
                    container: "childs-fields",
                    invisible : true
                }
            },

            parent:{
                type: 'String',
                //method: 'addToParent',
                callable: true,
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{ container: 'parents-fields',
                    widget: 'calculated',
                    label: 'Parent ref',
                }
            },
            /*
            parent_select:{
                type: 'String',
                callable: true,
                //method: 'addToParent',
                default: null,
                connection: {
                    in: {pluggable: false, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{
                    container: 'parents-fields',
                    label: 'Parent',
                    widget: "object",
                    tag: ['ui']
                }
            },*/
            addChild: {
                type: 'String',
                method: 'addChild',
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{ container: 'childs-fields',
                    widget: 'calculated',
                    label: 'Add Child',
                    description: 'Add a child to the element.'
                }
            },
            removeChild: {
                type: 'String',
                method: 'removeChild',
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{ container: 'childs-fields',
                    widget: 'calculated',
                    label: 'Remove Child',
                    description: 'Removes a children of the element according to the specified reference ID.'
                }
            },
            removeAllChildren: {
                type: 'String',
                callable: true,
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{ container: 'childs-fields',
                    widget: 'calculated',
                    label: 'Remove Children',
                    description: 'Removes all the children of the element.'
                }
            },

            visibility: {
                type: 'Boolean',
                default: true,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Visibility",
                    container: "general-fields",
                    description: "Specifies whether or not an element is visible."
                }
            },
            lock: {
                type: 'Boolean',
                default: false,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Lock",
                    container: "general-fields",
                    description: "Defines whether or not an element reacts to pointer events."
                }
            },
            customTags: {
                type: 'String',
                default: "",
                partial: null,
                method: 'setCustomTags',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Add Tags",
                    container: "general-fields",
                    description: "Tags are used to easily retrieve objects via queries."
                }
            },
            removeTags: {
                type: 'String',
                default: "",
                partial: null,
                method: 'removeTags',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Remove Tags",
                    container: "general-fields",
                    description: "Tags to remove from the object.",
                    hideForUI: true
                }
            },
            resetUi: {
                type: 'Mixed',
                default: null,
                callable: true,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Reset UI",
                    container: "general-fields",
                    description: "Resets the UI object to its initial state.",
                    hideForUI: true
                }
            },
            opacity: {
                type: 'Float',
                default: 1,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Opacity",
                    container: "general-fields",
                    min: 0,
                    max: 1,
                    description: "Sets the opacity level for an element."
                }
            },
            zIndex: {
                type: 'Int',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Disposition",
                    container: "general-fields",
                    min: 0,
                    description: "Sets the stack order of a positioned element."
                }
            },
            fontFamily: {
                type: 'String',
                default: 'inherit',
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Font Family",
                    container: "general-fields",
                    widget: 'select',
                    options : [
                        {value:'inherit', label:'Inherit'},
                        {value:'ComfortaaLight', label:'Comfortaa'},
                        {value:'Roboto', label:'Roboto'},
                        {value:'YaahowuLight', label:'Yaahowu Light'},
                        {value:'YaahowuBold', label:'Yaahowu Bold'},
                        {value:'PoppinsRegular', label:'Poppins Regular'} ,
                        {value:'PoppinsBold', label:'Poppins Bold'} ,
                        {value:'DINNextLTPro-Regular', label:'DIN Next Regular'} ,
                        {value:'DINNextLTPro-Bold', label:'DIN Next Bold'} ,
                        {value:'bemboregular', label:'Bembo Regular'} ,
                        {value:'bembobold', label:'Bembo Bold'} ,
                        {value:'bemboitalic', label:'Bembo Regular Italic'} ,
                        {value:'bembobold_italic', label:'Bembo Bold Italic'} ,
                        {value:'DinCondensed', label:'DIN Condensed'} ,
                        {value:'DinBoldCondensed', label:'DIN Bold Condensed'} ,
                        {value:'Georgia', label:'Georgia'} ,
                        {value:'Arial', label:'Arial'} ,
                        {value:'Verdana', label:'Verdana'} ,
                        {value:'Helvetica', label:'Helvetica'} ,
                        {value:'serif', label:'Serif'} ,
                        {value:'sans-serif', label:'Sans-Serif'},
                        {value:'monospace', label:'Monospace'},
                        {value:'GalanoRegular', label:'Galano Regular'} ,
                        {value:'GalanoBold', label:'Galano Bold'},
                        { value: 'Typold-Black', label: 'Typold Black' },
                        { value: 'Typold-Regular', label: 'Typold Regular'},
                        { value: 'EarthOrbiterTitle', label: 'Earth Orbiter Title' },
                        ],
                    description: "Specifies the font family for text."
                }
            },

            // SIZE
            width: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Width",
                    container: "size-fields",
                    description: "Sets the width of an element."
                }
            },
            height: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Height",
                    container: "size-fields",
                    description: "Sets the height of an element."
                }
            },

            // POSITIONNING
            position: {
                type: 'String',
                default: 'relative',
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Position",
                    container: "pos-fields",
                    widget: 'select',
                    options : [ {value:'relative', label:'relative'}, {value:'absolute', label:'absolute'} ],
                    description: "Specifies the type of positioning method used for an element (relative, absolute)."
                }
            },
            top: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Top",
                    container: "pos-fields",
                    conditions: [{field:'position', value:'absolute', operator:'=='}],
                    description: "Specifies the top position of a positioned element."
                }
            },
            right: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Right",
                    container: "pos-fields",
                    conditions: [{field:'position', value:'absolute', operator:'=='}],
                    description: "Specifies the right position of a positioned element."
                }
            },
            bottom: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Bottom",
                    container: "pos-fields",
                    conditions: [{field:'position', value:'absolute', operator:'=='}],
                    description: "Specifies the bottom position of a positioned element."
                }
            },
            left: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Left",
                    container: "pos-fields",
                    conditions: [{field:'position', value:'absolute', operator:'=='}],
                    description: "Specifies the left position of a positioned element."
                }
            },
            screenfixed: {
                type: 'Boolean',
                default: false,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Fixed To Screen",
                    container: "pos-fields",
                    conditions: [{field:'position', value:'absolute', operator:'=='}],
                    description: "Specifies whether an element is positioned relative to the viewport or not."
                }
            },

            // BACKGROUND
            backgroundColor: {
                type: 'Color',
                default: null, // "#FFFFFF",
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Color",
                    container: "background-fields",
                    description: "Specifies the background color of an element."
                }
            },
            backgroundImage: {
                type: 'Asset',
                default: null,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Image",
                    container: "background-fields",
                    widget:'asset',
                    assetType:'image',
                    description: "Specifies a background image for an element."
                }
            },
            backgroundRepeat: {
                type: 'String',
                default: 'repeat',
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Repetition",
                    container: "background-fields",
                    widget:'select',
                    options: [{value:'repeat', label:'repeat'}, {value:'repeat-x', label:'repeat x'}, {value:'repeat-y', label:'repeat y'}, {value:'no-repeat', label:'no repeat'}],
                    conditions: [{field:'backgroundImage', value:'', operator:'!='}],
                    description: "Sets the background image repetition. Repeat: the background is reaped on both X and Y axes / Repeat X: the background is reaped on the X axis / Repeat Y: the background is reaped on the Y axis / No Repetition: the background is not repeated."
                }
            },
            backgroundSize: {
                type: 'String',
                default: 'auto',
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Size",
                    container: "background-fields",
                    widget:'select',
                    options: [{value:'auto', label:'auto'}, {value:'cover', label:'cover'}, {value:'contain', label:'contain'}, {value:'custom', label:'custom'}],
                    conditions: [{field:'backgroundImage', value:'', operator:'!='}],
                    description: "Specifies the size of the background image."
                }
            },
            backgroundWidth: {
                type: 'DisplayUnit',
                default: '100%',
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Width",
                    container: "background-fields",
                    conditions: [{field:'backgroundSize', value:'custom', operator:'=='}],
                    description: "Sets the width of the background image."
                }
            },
            backgroundHeight: {
                type: 'DisplayUnit',
                default: null,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Height",
                    container: "background-fields",
                    conditions: [{field:'backgroundSize', value:'custom', operator:'=='}],
                    description: "Sets the height of the background image."
                }
            },
            backgroundPositionX: {
                type: 'String',
                default: "center",
                partial: 'styles',
                connection: { in: {pluggable: false, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "X Position",
                    container: "background-fields",
                    widget: 'select',
                    conditions: [{field:'backgroundImage', value:'', operator:'!='}],
                    options: [{value:'left', label:'left'}, {value:'center', label:'center'}, {value:'right', label:'right'}, {value:"", label:'custom'}],
                    description: "Specifies the horizontal positioning method of the background image."
                }
            },
            backgroundPosXCustom: {
                type: 'DisplayUnit',
                default: "100%",
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Custom X Position",
                    container: "background-fields",
                    conditions: [{field:'backgroundPositionX', value:"", operator:'=='}],
                    description: "Sets the horizontal position of the background image."
                }
            },
            backgroundPositionY: {
                type: 'String',
                default: "center",
                partial: 'styles',
                connection: { in: {pluggable: false, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Y Position",
                    container: "background-fields",
                    widget: 'select',
                    conditions: [{field:'backgroundImage', value:'', operator:'!='}],
                    options: [{value:'top', label:'top'}, {value:'center', label:'center'}, {value:'bottom', label:'bottom'}, {value:"", label:'custom'}],
                    description: "Specifies the vertical positioning method of the background image."
                }
            },
            backgroundPosYCustom: {
                type: 'DisplayUnit',
                default: "100%",
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Custom Y Position",
                    container: "background-fields",
                    conditions: [{field:'backgroundPositionY', value:"", operator:'=='}],
                    description: "Sets the vertical position of the background image."
                }
            },

            // SHADOW
            boxShadowColor: {
                type: 'Color',
                default: null,
                partial: 'shadow',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Color",
                    container: "shadow-fields",
                    description: "The color of the shadow. The default value is the text color."
                }
            },
            boxShadowDirection: {
                type: 'Vector2',
                default: { x: 0, y : 0 },
                partial: 'shadow',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Direction",
                    container: "shadow-fields",
                    description: "The horizontal and vertical offset of the shadow."
                }
            },
            boxShadowBlur: {
                type: 'Int',
                default: 0,
                partial: 'shadow',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Blur",
                    unit : "px",
                    container: "shadow-fields",
                    description: "The blur radius. The higher the number, the more blurred the shadow will be."
                }
            },

            // MARGIN
            marginTop: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Top",
                    container: "margin-fields",
                    description: "Sets the top margin of an element."
                }
            },
            marginRight: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Right",
                    container: "margin-fields",
                    description: "Sets the right margin of an element."
                }
            },
            marginBottom: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Bottom",
                    container: "margin-fields",
                    description: "Sets the bottom margin of an element."
                }
            },
            marginLeft: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Left",
                    container: "margin-fields",
                    description: "Sets the left margin of an element."
                }
            },

            // PADDING
            paddingTop: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Top",
                    container: "padding-fields",
                    description: "Sets the top padding of an element."
                }
            },
            paddingRight: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Right",
                    container: "padding-fields",
                    description: "Sets the right padding of an element."
                }
            },
            paddingBottom: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Bottom",
                    container: "padding-fields",
                    description: "Sets the bottom padding of an element."
                }
            },
            paddingLeft: {
                type: 'DisplayUnit',
                default: null,
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Left",
                    container: "padding-fields",
                    description: "Sets the left padding of an element."
                }
            },

            // Border
            hasBorder: {
                type: 'Boolean',
                default: false,
                partial: "border",
                connection: { in: {pluggable: false, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Apply Border",
                    container: "border-fields",
                    description: "Specifies whether an element has a border or not."
                }
            },
            borderSide: {
                type: 'String',
                default: 'all',
                partial: "border",
                connection: { in: {pluggable: false, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Side",
                    container: "border-fields",
                    widget: "select",
                    conditions: [{field:'hasBorder', value:true, operator:'=='}],
                    options : [ {value:'all', label:'all'}, {value:'Top', label:'top'}, {value:'Left', label:'left'}, {value:'Right', label:'right'}, {value:'Bottom', label:'bottom'} ],
                    description: "Specifies which side(s) of an element has(have) a border."
                }
            },
            borderWeight: {
                type: 'Int',
                default: 1,
                partial: "border",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Weight",
                    container: "border-fields",
                    conditions: [{field:'hasBorder', value:true, operator:'=='}],
                    unit : "px",
                    description: "Sets the weight of the border."
                }
            },
            borderRadius: {
                type: 'Int',
                default: 0,
                partial: "border",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Radius",
                    container: "border-fields",
                    conditions: [{field:'hasBorder', value:true, operator:'=='}],
                    unit : "px",
                    description: "Sets the radius of the corners of the element."
                }
            },
            borderStyle: {
                type: 'String',
                default: "solid",
                partial: "border",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Style",
                    widget: "select",
                    container: "border-fields",
                    conditions: [{field:'hasBorder', value:true, operator:'=='}],
                    options: [ {label:'Dotted', value:'dotted'},  {label:'Dashed', value:'dashed'},   {label:'Solid', value:'solid'},   {label:'Double', value:'double'},    {label:'Groove', value:'groove'},   {label:'Ridge', value:'ridge'},  {label:'Inset', value:'inset'},   {label:'Outset', value:'outset'},  {label:'None', value:'none'},  {label:'Hidden', value:'hidden'} ],
                    description: "Specifies the style of the border."
                }
            },
            borderColor: {
                type: 'Color',
                default: { hex : "#FFFFFF" },
                partial: "border",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Color",
                    container: "border-fields",
                    conditions: [{field:'hasBorder', value:true, operator:'=='}],
                    description: "Specifies the color of the border. Default value is the color of the text."
                }
            },

            // EVENTS

            mouseevents:     {
                type : 'Repeated',
                connection : {
                    in: {pluggable: false, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{
                    container: 'events',
                    label: 'Mouse Events -- Deprecated',
                    widget: 'repeated-form',
                    form : {
                        type: {
                            name: 'type',
                            type: 'String',
                            label: 'Type',
                            widget: 'select',
                            default: 'onClick',
                            options:  [  {label:'click', value:'onClick'},  {label:'mouse down', value:'onMouseDown'},  {label:'mouse up', value:'onMouseUp'},  {label:'mouse over', value:'onMouseOver'},  {label:'mouse out', value:'onMouseOut'},  {label:'mouse move', value:'onMouseMove'},  {label:'double click', value:'onDoubleClick'},  {label:'mouse enter', value:'onMouseEnter'},   {label:'mouse leave', value:'onMouseLeave'} ],
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: false, default: false, disabled:true  }
                            }
                        },
                        trigger: {
                            name: 'trigger',
                            type: 'Boolean',
                            label: 'Trigger',
                            widget: 'boolean',
                            disabled: true,
                            default: false,
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: true, default: false  }
                            }
                        },
                        position: {
                            name: 'position',
                            type: 'Vector2',
                            label: 'Position',
                            widget: 'vector2',
                            disabled: true,
                            default: {x:0, y:0},
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: true, default: false  }
                            }
                        }
                    }
                }
            },

            touchevents:     {
                type : 'Repeated',
                connection : {
                    in: {pluggable: false, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{
                    container: 'events',
                    label: 'Touch Events -- Deprecated',
                    widget: 'repeated-form',
                    form : {
                        type: {
                            name: 'type',
                            type: 'String',
                            label: 'Type',
                            widget: 'select',
                            default: 'onTap',
                            options:  [  {label:'tap', value:'onTap'},   {label:'press', value:'onPress'}, {label:'pan', value:'onPan'},  {label:'swipe', value:'onSwipe'} ],
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: false, default: false, disabled:true  }
                            }
                        },
                        trigger: {
                            name: 'trigger',
                            type: 'Boolean',
                            label: 'Trigger',
                            widget: 'boolean',
                            disabled: true,
                            default: false,
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: true, default: false  }
                            }
                        },
                        position: {
                            name: 'position',
                            type: 'Vector2',
                            label: 'Position',
                            widget: 'vector2',
                            disabled: true,
                            default: {x:0, y:0},
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: true, default: false  }
                            }
                        },
                        isFirst: {
                            name: 'isFirst',
                            type: 'Boolean',
                            label: 'is first',
                            widget: 'boolean',
                            disabled: true,
                            default: false,
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: true, default: false  }
                            }
                        },
                        isFinal: {
                            name: 'isFinal',
                            type: 'Boolean',
                            label: 'is final',
                            widget: 'boolean',
                            disabled: true,
                            default: false,
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: true, default: false  }
                            }
                        }
                    }
                }
            },


        };

        this.addToParameters( commonParameters );

        // add events
        mouseEvents.forEach( (evt) => {
            let subParams = [
                { name : 'trigger' , label : 'Trigger', type : 'Boolean', default: false },
                { name : 'triggerObjRef' , label : 'Trigger object ref', type : 'String', default: null },
                { name : 'position' , label : 'Position', type : 'Vector2', default : {x:0,y:0} }
            ]
            let param = this.createEventParameters( 'mevents', evt , subParams );

            this.addToParameters( param );
        });

        touchEvents.forEach( (evt) => {
            let subParams = [
                { name : 'trigger' , label : 'Trigger', type : 'Boolean', default: false },
                { name : 'triggerObjRef' , label : 'Trigger object ref', type : 'String', default: null },
                { name : 'position' , label : 'Position', type : 'Vector2', default : {x:0,y:0} },
                { name : 'isFirst' , label : 'is First', type : 'Boolean', default : false },
                { name : 'isFinal' , label : 'is Final', type : 'Boolean', default : false }
            ]
            let param = this.createEventParameters( 'tevents', evt , subParams );

            this.addToParameters( param );
        });


        let param = this.createEventParameters( 'tevents', 'onPinch' , [{ name : 'scale' , label : 'Scale', type : 'Float', default: 1 }] );

        this.addToParameters( param );



    }

    addGroupFieldForEvent( ctn , type, evt ){
        let groupField = this.createInspectorNode('group-field', type+'fields-'+evt );
        ctn.children.push( groupField );
    };

    createEventParameters( type, evt, subParams, conditions = null ){
        let params = {};

        params[type+'_'+evt] = {
            type: 'Boolean',
            default: false,
            partial: "border",
            connection: { in: {pluggable: false, default: false}, out: {pluggable: false, default: false} },
            auth :  {
                label: evt,
                container: type+'fields-'+evt,
                conditions: conditions
            }
        };

        subParams.forEach( (subParam) => {
            params[type+'_'+evt+'-'+subParam.name] = {
                type: subParam.type,
                default: subParam.default,
                event : true,
                connection: { in: {pluggable: false, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: subParam.label,
                    container: type+'fields-'+evt,
                    conditions: [{ field: type+'_'+evt , value:true, operator:'=='}]
                }
            };
        });


        return params;
    }


    initState(){
        this.state = {
            cache: false,
            type: this.cmpType,
            id: this.id,
            props: { id: this.id , mevents: {}, tevents: {} },
            children: []
        };
    }

    get objectType () {
        return this.__proto__.constructor.name;
    }

    getState() {
        return this.state;
    }

    setAssetManager( manager ){
        this.assetManager = manager;
    }

    setMediaManager( mediaManager ){
        this.mediaManager = mediaManager;
    }

    checkAssetManager( ){
        if( this.assetManager === null ){
            console.warn('Object Asset Manager not defined.');
            return false;
        }
        return true;
    }

    disableStateEvent() {
        this._stateEventEnabled = false;
    }

    enableStateEvent(triggerOne = true) {
        this._stateEventEnabled = true;
        if (triggerOne) {
            this.emit(STATE_CHANGE, this.state);
        }
    }

    emit(event, ...params) {
        if (this._stateEventEnabled) {
            super.emit.apply(this, [event, ...params]);
        }
    }

    castColor( value ){
        if( typeof(value) === 'object' &&  value.rgba != undefined ){
            let col = value.rgba;
            let alpha = col.a || 1;

            value = 'rgba('+col.r+', '+col.g+', '+col.b+', '+alpha+')';
        }
        if( typeof(value) === 'object' &&  value.hex != undefined ){
            value = value.hex;
        }
        if( typeof(value) === 'object' &&  value.red != undefined && value.green != undefined && value.blue != undefined ){
            value = 'rgba('+value.red+', '+value.green+', '+value.blue+', '+value.alpha+')';
        }
        return value;
    }

    getMediaUrl(prop, rawValue, media) {
        if (media) {
            // console.log("getMediaUrl foundMedia", media.url, prop)
            this.set(`${prop}__url`, media.url);
        } else {
            // otherwise, rawValue is maybe a direct url to the media instead of an id, try to use it as url
            this.set(`${prop}__url`, rawValue);
        }
    }

    getMedia(value) {
        return new Promise((resolve) => {
            this.mediaManager.getMediaByID(value).then((media) => {
                if (media) {
                    if (media.assetType === 'image' && this.appRenderer()) {
                        const derivative = this.lookForDerivativeImage(media.filename);
                        resolve(derivative || media);
                    } else {
                        resolve(media);
                    }
                } else {
                    // Resolve without the media, value will be considered a URL.
                    resolve();
                }
            });
        });
    }

    /**
     * Looks for a possible derivative image in Library to match the device's pixel ratio
     * (with @2x, @3x, etc. appended to the filename). If the exact derivative is not found,
     * this function will look for the closest match.
     *
     * @param {String} filename
     * @return {Asset|null} The derivative media from Library (or null if there is none).
     */
    lookForDerivativeImage(filename) {
        if (window.devicePixelRatio === 1) return null;

        let ratio = window.devicePixelRatio;

        const getMediaFromPixelRatio = (pr) => {
            const derivativeName = filename.replace(/(\.[\w\d_-]+)$/i, `@${Math.floor(pr)}x$1`);
            return this.mediaManager.getMedia(derivativeName);
        }

        while (ratio > 1) {
            const media = getMediaFromPixelRatio(ratio);

            if (media) return media;

            ratio -= 1;
        }

        return null;
    }

    invoke(methodName, args) {
        if (this[methodName]) {
            return this[methodName].apply(this, args);
        }
    }

    checkValueCast(prop, value) {
        let rawValue = value && value.dataType ? value.value : value;

        if (this.getParameter(prop) && this.getParameter(prop).type) {
            const type = this.getParameter(prop).type.toLowerCase();

            if (rawValue && type === 'color') {
                rawValue = this.castColor(rawValue);
            }

            if (type === 'asset' && this.mediaManager) {
                if (rawValue) {
                    this.getMedia(rawValue).then( (res)=> {
                        this.getMediaUrl(prop, rawValue, res)
                    });
                } else {
                    this.set(`${prop}__url`, '');
                }
            }

            if (type === 'percent') {
                rawValue *= 100;
            }

            if (type === 'angle') {
                rawValue = value && value.dataType && value.dataType !== 'Float' ? value.toFloat('deg').value : value;
            }
        }

        return rawValue;
    }

    set(prop, value, partial = false) {
        if (prop === undefined) {
            console.error(`Missing parameter name for the UI Object type '${this.cmpType}' [${this.id}] with value: ${value}`);
            return this;
        }

        value = this.checkValueCast( prop, value );

        let newValue = {};

        if( this.getParameter(prop) && this.getParameter(prop).partial != null ){

            let currentVal = value;
            value = {};
            value[prop] = currentVal;

            let currentProp = this.getParameter(prop).partial;

            newValue[currentProp] = Object.assign({}, this.state.props[currentProp], value);
        }
        else if (typeof this.state.props[prop] === 'object' && partial) {
            newValue[prop] = Object.assign({}, this.state.props[prop], value);
        } else {

            let splitedProp = prop.split('_');

            if( splitedProp.length == 3 && splitedProp[1] != '' /* for image__url prop */ ){
                prop = splitedProp[0];
                if( this.getParameter(prop) && this.getParameter(prop).type == "Repeated" ) {
                    partial = true;
                    let composite = this.state.props[prop] ? this.state.props[prop] : {};
                    let existing = {};
                    if( this.state.props[prop] && this.state.props[prop][ splitedProp[1] ] )
                        existing = this.state.props[prop][ splitedProp[1] ];

                    let subValue = {};
                    subValue[ splitedProp[2] ] = value;
                    composite[splitedProp[1]] = Object.assign( existing, subValue );

                    value = composite;

                }
            }
            newValue[prop] = value;

        }

        if( this.getParameter(prop) && this.getParameter(prop).method != null ){
            if (Array.isArray(value)) value = [value]; // force only one argument for the call() method
            this.invoke( this.getParameter(prop).method, [].concat( value ) );
        }

        Object.assign(this.state.props, newValue);



        this.emit(STATE_CHANGE, this.render());
        return this;
    }

    getAllParameters(){
        return this.parameters;
    }

    getParameter(prop){
        return this.parameters[prop];
    }

    get(prop) {
        if( this.getParameter(prop) && this.getParameter(prop).partial != null ){
            return this.state.props[this.getParameter(prop).partial][prop];
        }
        return this.state.props[prop];
    }

    addToParent( parentId ){
        if (parentId === null || parentId === undefined) {
            console.error(`Invalid parent for the UI '${this.cmpType}' [ UID: ${this.id} ].\nVerify the associated module property to display this object.`);
            return;
        }

        let parent = this.assetManager.get( parentId );
        parent.addChild( this.id );
    }

    parent( parentId ){
        this.addToParent( parentId );
    }
    parent_select( parentId ){
        this.addToParent( parentId );
    }

    addChild(child, section=null, pos = null) {

        if( !child ) return;

        if (typeof child === 'string') {
            if( !this.checkAssetManager() ) return;
            child = this.assetManager.get(child, this.ctx);

            if( !child || ( Array.isArray(child) && child.length == 0 ) )
                return;
        }

        if (pos === null) {
            pos = this.children.length;
        }

        if( this.mediaManager && child.setMediaManager )
            child.setMediaManager( this.mediaManager );

        if( this.assetManager && child.setAssetManager )
            child.setAssetManager( this.assetManager );

        child.setNestedLevel( this.level + 1 );
        child.addListener(STATE_CHANGE, this._onChildStateChange);

        // child may not already here (?)
        // do not include in condition below
        this.children.splice(pos, 0, child);
        // child.id is unique, do not add this reference to avoid REACT duplication
        if (this.childrenId.indexOf(child.id) === -1) {
            this.childrenId.splice(pos, 0, child.id);
            if (this.nbSection > 1 && section != null && this.childrenIdByCols[section])
                this.childrenIdByCols[section].push(child.id);
        }

        this.emit(STATE_CHANGE, this.render());

        return child;
    }

    removeChild(child) {

        if( child === null || child === undefined ) return;

        if (typeof child === 'string') {
            if( !this.checkAssetManager() ) return;
            child = this.assetManager.get(child, this.ctx);
        }

        let index;
        if (Number.isInteger(child)) {
            index = child;
        } else {
            index = this.children.indexOf(child);
        }

        let removedChild = this.children.splice(index, 1)[0];
        removedChild.removeListener(STATE_CHANGE, this._onChildStateChange);

        this.childrenId.splice(index, 1);

        if( this.nbSection > 1 && this.childrenIdByCols.length > 0 ){
            let section = -1;
            let indexBlock = -1;
            this.childrenIdByCols.forEach( (col, index ) => {
                let indexB = col.indexOf( child.id );
                if( indexB != -1 ){
                    section = index;
                    indexBlock = indexB;
                }
            });

            if( section != -1 && indexBlock != -1 && this.childrenIdByCols[section] )
                this.childrenIdByCols[section].splice(indexBlock, 1);
        }

        this.emit(STATE_CHANGE, this.render());

        return removedChild;
    }

    removeAllChildren( param ) {
        for( let i = this.children.length-1 ; i >= 0 ; i-- )
            this.removeChild( i );
    }

    /**
     * Reorder childrens order.
     * @param {Array} childrenIds — The list of children's identifier in order.
     */
    setChildrenOrder(childrenIds) {
        this.childrenId = childrenIds;
        this.childrenIdByCols = childrenIds; // @todo: @fixme: for multi column use (currently disabled)
        this.children.sort((a, b) => {
            return this.childrenId.indexOf(a.id) < this.childrenId.indexOf(b.id) ? -1 : 1;
        });
    }

    getTagList( tags ){
        tags = tags.replace(' ', '');
        if( tags === "" ) return;

        let tagList = tags.split(',');

        return tagList;
    }

    setCustomTags( tags ){

        if( !this.assetManager || !tags ) return;
        let tagList = this.getTagList( tags );

        if( !tagList || !Array.isArray(tagList) || tagList.length == 0 )
            return;

        tagList.forEach( (tag) => {
            if( tag !== '' ){
                this.assetManager.addTag(this.id, tag);
                this.customTags.push( tag );
            }
        });


        this.set('tags', this.assetManager.listTag( this.id ) );

    }

    removeTags( tags ){
        if( !this.assetManager || !tags ) return;
        let tagList = this.getTagList( tags );

        if( !tagList || !Array.isArray(tagList) || tagList.length == 0 )
            return;

        tagList.forEach( (tag) => {
            if( tag !== '' ){
                let index = this.customTags.indexOf(tag);
                if( index !== -1 ){
                    this.customTags.splice( index, 1 );
                }
                this.assetManager.removeTag(this.id, tag);
            }
        });
    }


    setCtx(ctx) {
        this.ctx = ctx;

        this._initChild();

        return this;
    }

    render() {

        if (this.children.length !== this.state.children.length) {
            this.state.children = this.children.map((child) => {
                return child ? child.render() : null;
            });
        }
        this.state.props.hash = this.generateHash();
        this.state.cache = false;

        return this.state;
    }

    on(evt, callback) {
        evt = evt.substring(0, 1).toUpperCase() + evt.substring(1);

        this.set('on' + evt, callback);
    }

    registerToEvent( type, event, idModule ){
        if( !this.state.props[type] )
            this.state.props[type] = {};

        if( !this.state.props[type][event] )
            this.state.props[type][event] = new Set();

        if( !this.state.props[type][event].has(idModule))
            this.state.props[type][event].add( idModule );

        this.emit(STATE_CHANGE, this.render());
    }

    unregisterToEvent( type, event, idModule ){
        if( this.state.props[type] && this.state.props[type][event] && this.state.props[type][event].has(idModule) )
            this.state.props[type][event].delete( idModule );

        this.emit(STATE_CHANGE, this.render());
    }

    _initChild() {

        if( !this.checkAssetManager() ) return;

        let childIds = [].concat.apply([],  this.childrenId  );

        this.children = childIds.map((childId) => {
            let child = this.assetManager.get(childId, this.ctx);
            if (child) {
                child.setAssetManager( this.assetManager );
                child.setMediaManager( this.mediaManager );
                child.setCtx(this.ctx);
                child.addListener(STATE_CHANGE, this._onChildStateChange);
            }
            else console.warn("Children not found with ID:", childId, ", parent type", this.cmpType, ", parent ID:", this.id)

            return child;
        });


        this.setNestedLevel( this.level );

    }

    _onChildStateChange(newChildState) {
        let childIndex = this.state.children.findIndex( (child) => {
            return child.id === newChildState.id;
        });

        if( childIndex != -1 )
            this.state.children.splice(childIndex, 1, newChildState);
        else
            this.state.children.push( newChildState );

        // bubble event
        this.state.props.hash = this.generateHash();
        this.state.cache = false;
        this.emit(STATE_CHANGE, this.state);
    }

    generateHash() {
        return ++this.version
    }


    /**
     * Attach all trigger field listener to this object.
     * (This method should be overridden)
     *
     * @param {Object} module - The parent module
     */
    registerTriggerListeners(module) {
        this.triggerModules[module.id] = module;
    }
    
    /**
     * Detach all trigger field listener from this object.
     * (This method should be overridden)
     * 
     * @param {Object} module - The parent module
     */
    unregisterTriggerListeners(module) {
        if (this.triggerModules[module.id]) delete this.triggerModules[module.id];
    }
}

export default UiObject;
