import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import Vue from 'vue';
import uuid from 'uuid/v4';

import store from 'store';
import FileSaver from 'file-saver';

/**
 * Copy temporary in memory or export to a file one or more blocks (as JSON format).
 */
class CopyBlock extends ActionAbstract {

    /**
     * @param{Array} blocks - All blocks ID to be copied (optional)
     * @param{boolean} duplicate - whether blocks should be duplicated (optional, default false)
     * @param{string} parentId - block id of parent to duplicate on (optional, default null)
     * @param{boolean} saveToFile - if true, save the json into a file (optional, default false)
     * @param{string} fileName - The name to use for the export
    **/

    static get ID() {
        return 'actions:block:copy';
    }

    run(state) {
        const saveToFile = this.getParam('saveToFile') ? this.getParam('saveToFile') : false;
        const duplicate = this.getParam('duplicate') ? this.getParam('duplicate') : false;

        this.state = state;
        
        let parentID = this.getParam('parentId') ? this.getParam('parentId') : null;
        let blocks = [];
        
        if( this.getParam('blocks') ) blocks = this.getParam('blocks');
        else if (state.editor.selection.items) blocks = state.editor.selection.items;
        
        if( blocks.length == 0 ) return false;

        let error = false;
        let copies = [];
        let infos = { names: [], blocks };
        let mediaList = {};

        blocks.forEach( ( blocId ) => {
            let block = helper.block.getBlockById( state.project, blocId );
            
            if (!block) return; // should never occur but in case of invalid block 
            if( block.value.type == "memory" ) error = true;

            // for UI element
            if (block.value.type === 'object') {
                if (block.value.format === 'ui-screen') {
                    console.warn("Copy of HTML View is not allowed.");
                    return;
                };
                // duplicate in parent only, not itself (using the keyboard shortcut fro ex.)
                if (duplicate && !parentID) {
                    parentID = block.custom.uiParent;
                }
            }

            this.tabIds = [];
            this.memoriesIds = [];
            this.mediaIds = [];

            this.searchIds( block );

            let copy = JSON.stringify(block);

            // @todo: do not redefine the ID here to export the block data with the same 
            // values/structure -> move to LoadBlock.js file
            this.tabIds.forEach( (sub) => {
                let newId = uuid();
                let regex = new RegExp(sub.id, "g");
                let index = infos.blocks.indexOf(sub.id);
                if (index !== -1) infos.blocks[index] = newId;
                sub.new = newId;
                copy = copy.replace(regex, newId);
            });

            this.memoriesIds.forEach( ( mem ) => {
                let memData = helper.block.getMemoryById( state.project , mem.id );

                if( memData ){
                    let memCopy = JSON.stringify(memData);

                    let newMemId = uuid();
                    mem.new = newMemId;
                    let regexMem = new RegExp( mem.id, "g");
                    
                    memCopy = memCopy.replace(regexMem, newMemId);

                    this.tabIds.forEach( (sub) => {
                        let regexBlock = new RegExp(sub.id, "g");
                        memCopy = memCopy.replace(regexBlock, sub.new);
                    });

                    copies.push( memCopy );

                    copy = copy.replace(regexMem, newMemId);
                }

            });


            this.mediaIds.forEach( ( m ) => {
                let media = this.deps.mainStore.lib.getLoadedMediaByID( m.id );
                if( mediaList[m.id] === undefined )  
                    mediaList[m.id] = media;
            });

            copies.push(copy);
            infos.names.push(block.value.name);
        })

        if( error ){
            console.warn("Unable to copy object or memory typed blocks.");
            return false;
        }

        // nothing wrong or copy not allowed
        if (copies.length === 0) return false;

        // copy to clipboard
        // or export
        if( !saveToFile ){
            store.set('nodalClipboard', []);   // why do we reset this first ?
            store.set('nodalClipboard', copies);
            store.set('nodalClipboardInfo', {} );   // why do we reset this first ?
            store.set('nodalClipboardInfo', infos );
            store.set('nodalClipboardMedia', []);   // why do we reset this first ?
            store.set('nodalClipboardMedia', mediaList );

            // paste all blocks
            if (duplicate) {
                const params = { duplicate: true };
                if (parentID) params.parentId = parentID;
                this.trigger("block:paste", params);
            }
            else if (!this.getParam('quiet')) {
                // @todo: implement notification and remove console log
                //this.trigger('notification', { message: `Copy '${infos.names.join('\', \'')}'` });
                console.log(`Copy '${infos.names.join('\', \'')}'`, infos.blocks)
            }
        }
        else {
            let jsonCombined = { nodalExport: { blocks: copies, media: mediaList, info: infos } };
            var jsonBlob = new Blob( [JSON.stringify(jsonCombined)] , {type: "data:application/json;charset=utf-8;"});
            let fileName = this.getParam("fileName") ? this.getParam("fileName") : "export-nodal.json"
            FileSaver.saveAs(jsonBlob, fileName );
            // @todo: implement notification and remove console log
            //this.trigger('notification', { message: `Export '${infos.names.join('\', \'')}' to '${fileName}'` });
            console.log(`Export '${infos.names.join('\', \'')}' to '${fileName}'`)
        }

        return false;
    }

    
    searchIds( block ){

        this.tabIds.push( { id : block.value._id , new : null } );

        if( block.value.memory ){
            block.value.memory.forEach( ( mem ) => {
                let memData = this.deps.mainStore.memory.getMemoryByIDInProject( this.state.project, mem.id );
                let memDataFieldValue = helper.block.getField(memData, 'value');

                if( memData.value.format == "Asset" && memDataFieldValue && memDataFieldValue.value !== null && memDataFieldValue.value !== ""  ){
                    this.mediaIds.push( { id : memDataFieldValue.value, new : null } ); 
                }

                // do not work with empty array
                if (memData.value.format == "array" && memDataFieldValue && memDataFieldValue.header ){
                    memDataFieldValue.header.forEach( (col, i) => {
                        if( col.widget == "asset" ){
                            memDataFieldValue.value.forEach( (row) =>{
                                if( row[i] && row[i].value !== null && row[i].value !== "" )
                                    this.mediaIds.push( { id : row[i].value, new : null } ); 
                            })
                        }
                    })
                }

                this.memoriesIds.push( { id : mem.id, new : null } );
            });
        }

        if( block.value.fields ){
            block.value.fields.forEach((field)=>{
                if( field.isMedia && field.value && field.value != '' )
                    this.mediaIds.push( { id : field.value, new : null } ); 
            });
        }

        if( block.custom.children && block.custom.children.length > 0 ){
            block.custom.children.forEach( (child) => this.searchIds( child ) );
        }

        if( block.custom.objects && block.custom.objects.length > 0 ){
            block.custom.objects.forEach( (child) => this.searchIds( child ) );
        }
    }



}

export default CopyBlock;