
import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class OpenParent extends ActionAbstract {

    static get ID() {
        return 'actions:editor:openParent';
    }

    run(state) {
        
        let currentBlock = helper.block.getBlockById( state.project, state.editor.targetId );

        let parentID = this.deps.mainStore.block.getParentsID( currentBlock );
        let parent = helper.block.getBlockById( state.project, parentID );

        if( parent && this.deps.mainStore.config.editorView.indexOf( parent.value.type ) != -1 ){
			this.trigger('editor:openEditor', { id : parent.value._id });
        }


        return this.composeState(state, { });
    }


}

export default OpenParent;