<!-- Number vue -->

<template lang="pug">

span.textinput-ctn(v-bind:class="{'has-unit':field.unit}")
	input.value(type="text", v-model="value", v-bind:placeholder="placeholder", v-on:focusout="focusOutField(field)", :disabled="field.disabled")
	span.unit(v-if="field.unit") {{field.unit}}
	
</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue', 'placeholder' ],

	data() {
		return {
			value: null
		}
	},

	created: function () {
		this.value = this.fieldvalue ? this.fieldvalue.value : 0;
	},

	watch: {
		fieldvalue: function (obj) {
			this.value = obj ? obj.value : 0;
		}
	},

	methods: {

		focusOutField: function(fieldUpdated){
			let intVal = Math.round(this.value);

			if( this.value === "" || intVal === NaN || intVal === 'NaN' || this.value === null ){
				this.value = null;
			} else {
				this.value = intVal;
			}

			if (this.fieldvalue.value !== this.value) {
				this.valueUpdated(fieldUpdated);
			}
		},

		valueUpdated: function( fieldUpdated ){

			// Si la valeur du nombre renseigné est plus petite que le nombre min, on tronque a min
			if( fieldUpdated.widget == "number" && fieldUpdated.min != null && this.value < fieldUpdated.min )
				this.value = fieldUpdated.min;
			// Idem pour max
			if( fieldUpdated.widget == "number" && fieldUpdated.max != null && this.value > fieldUpdated.max )
				this.value = fieldUpdated.max;


			this.$parent.valueUpdated( fieldUpdated, this.value );
		}


	}

}

</script>

<style lang="stylus">
</style>