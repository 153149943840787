import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class OpenPreview extends ActionAbstract {

    /**
     * @param blocId : id of screen block
     * 
    **/

    static get ID() {
        return 'actions:main:openPreview';
    }

    run(state) {
        let screen = null, xp = helper.block.getBlockById( state.project, this.getParam('blocId'));

        if( xp.value.type == 'screen' ){
            let xpID = xp.value.ref_experience;
            screen = xp;
            xp = helper.block.getBlockById( state.project, xpID );
            
        }

        let url = helper.project.getPlayerUrl(state, xp.value._id, screen && screen.value._id);

        if (url!=null){
            let w = window.open();
                w.opener = null;
                w.document.location = this.deps.mainStore.config.previewURL+url;            
        }

        return false;

    }

}

export default OpenPreview;