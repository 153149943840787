var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "object-includer", class: _vm.queryMode }, [
    _vm.subInspector && _vm.subtarget
      ? _c(
          "div",
          _vm._l(_vm.subInspector.children, function(bloc) {
            return _c(
              "div",
              [
                bloc.type == "topic"
                  ? _c("sub-topic", {
                      attrs: {
                        project: _vm.project,
                        target: _vm.subtarget,
                        bloc: bloc,
                        state: _vm.state,
                        inspectorField: _vm.inspectorField
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }