import ActionAbstract from '../ActionAbstract';
import deepCopy from 'deepcopy';
import Vue from 'vue';

import * as helper from '../../helper';

class SelectAssetForField extends ActionAbstract {

    static get ID() {
        return 'actions:library:selectAssetForField';
    }

    run(state) {

        let bloc, pathToBloc, field, findex, composite = { project: {}};
        let id = this.getParam('mediaId');

        let subMediaId = null;
        let new_value = null;
        if (this.getParam('target').bloc.value.type === 'memory') {
            let valueField, field = this.getParam('target').field, newValue;
            [bloc, pathToBloc] = helper.block.getEntityByIDInWholeProject(state.project, this.getParam('target').bloc.value._id, true);
            [valueField, findex] = helper.block.getField(this.getParam('target').bloc, "value", true);

            if(bloc.value.format === "Asset"){
              newValue = deepCopy(valueField);
              subMediaId = "array-asset-"+field[findex];
              newValue[field.name] = id;
            } else {
              subMediaId = "array-asset-"+field.colIndex+"-"+field.rowIndex;
              newValue = deepCopy(valueField.value);
              newValue[field.rowIndex][field.colIndex] = { value : id };
              new_value = id
            }

            if( bloc.value.fields[findex].value  && Array.isArray( bloc.value.fields[findex].value ) ){

                bloc.value.fields[findex].value[field.rowIndex][field.colIndex] = {value: new_value};

                let blocCopy = [];
                Vue.set(bloc.value.fields[findex].value[field.rowIndex][field.colIndex], 'value' , new_value );

            } else {
                Vue.set( bloc.value.fields[findex], 'value' , id );
            }
        } else {
            [bloc, pathToBloc] = helper.block.getBlockById(state.project, this.getParam('target').bloc.value._id, true);
            if(this.getParam('target').field.valueIsArray && !('repeatedField' in this.getParam('target').field)){
                [field, findex] = helper.block.getField(this.getParam('target').bloc, this.getParam('target').field.name, true);
                const fieldInfo = this.getParam('target').field.fieldToChange;
                const info = fieldInfo.split('-');
                bloc.value.fields[findex].value[parseInt(info[3])][parseInt(info[2])].value = id
                Vue.set( bloc.value.fields[findex].value[parseInt(info[3])][parseInt(info[2])], 'value', id );
            } else {
                [field, findex] = helper.block.getField(this.getParam('target').bloc, this.getParam('target').field.name, true);
                //composite.project[pathToBloc + '.value.fields[' + findex + '].value'] = id;
                Vue.set( bloc.value.fields[findex], 'value', id );
            }
            
            let ui = this.deps.mainStore.ui.get( this.getParam('target').bloc.value._id );
            if( ui ){
                helper.ui.setUIProp( ui , this.getParam('target').field.name , id );
                this.trigger('editor:ui:updateUiState' );
            }

        }


        // @todo: add a better asset management:
        // register/unregister, projet or template level (ie. workspace), mark deleted, etc…
        // see ticket NS-158 : https://orbe.atlassian.net/jira/software/projects/NS/boards/1?selectedIssue=NS-158
        if (id) {
            // register owner
            this.deps.mainStore.lib.addMediaOwner(id, state.workspace);

            // register media in template
            if (state.workspace.target == 'template')
                this.trigger('template:media', { action: 'register', media: id, target: bloc.value._id });
        }

        this.trigger('library:assetFieldLoad', { bloc : this.getParam('target').bloc, mediaId : id, subMediaId: subMediaId });
        this.trigger('main:toggleLibrary', { open : false });
        this.trigger('main:toggleMediaPreview', { open: false });
        this.trigger('block:updateFields', { id: bloc.value._id });
        state.library.status = null;
        state.library.assetSelected = true;
        state.library.target = {}
        return this.composeState(state, composite);
    }

}

export default SelectAssetForField;
