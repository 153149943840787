var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "textinput-ctn",
      class: { setted: _vm.value, disabled: _vm.field.disabled }
    },
    [
      !_vm.value
        ? _c(
            "div",
            {
              staticClass: "edit-bt",
              class: { disabled: _vm.isDisabled() },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.openLibrary(_vm.field)
                }
              }
            },
            [_c("span", { staticClass: "label" }, [_vm._v("Choose")])]
          )
        : _vm._e(),
      _vm.value && _vm.currentFileInfo
        ? _c("input", {
            staticClass: "value fileinfo",
            attrs: { type: "text", align: "right" },
            domProps: { value: _vm.currentFileInfo.filename },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.openLibrary(_vm.field)
              }
            }
          })
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value"
          }
        ],
        staticClass: "value hide",
        attrs: { type: "text" },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.value = $event.target.value
          }
        }
      }),
      _vm.value && _vm.currentFileInfo
        ? _c("div", { staticClass: "file-action-bts" }, [
            _c("div", {
              staticClass: "action-bt bt-view",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.viewMedia()
                }
              }
            }),
            _c("div", {
              staticClass: "action-bt bt-delete",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.resetFile()
                }
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }