<!-- Modal vue -->

<template lang="pug">
div#duplicateProjectTab(v-if="projectInfo !== undefined")
	div.error(v-if="duplicateError == 'ok'")
		p The project {{projectInfo.name}} has been duplicated as 
			strong {{neoProjectName}}
	div.input.duplicateProjectInput(v-else)
		input.value.inputModal(placeholder="Enter a new ProjectName", v-model="neoProjectName", v-on:keyup.enter="requestDuplicate", type="text")
		div.duplicateProjectAdd(v-on:click.stop="requestDuplicate", :disabled="!(neoProjectName)", value="Duplicate", type="button") Duplicate
	
	div(v-if="duplicateError == ''")
		p Do you want to duplicate this project ? 
		p Depends of the size of the project (This may take several minutes)
	
	p.error(v-if="duplicateError !== '' && duplicateError !== 'ok'")
		strong {{duplicateError}}
</template>


<script>
import Icon from '../common/Icon.vue'
import actionManager from '../../actions';

export default {

	// settings	the modal configuration (ie. app.state.modal)
	props: {
		method: { type: Function },
		index: {type: Number},
		projectInfo: { type: Object },
		name: { type: String },
		duplicateError: {type: String},
	},

	components: {
		Icon
	},

    data() {
        return {
			value: '',
			default: [],
			error: '',
			neoProjectName: '',
			cross: 'cross',
			showMenu: false
      }
    },

	created: function() {
    },

	methods: {

		requestDuplicate: function(){
			actionManager.trigger('project:DuplicateProject', {
				project: this.projectInfo,
				neoProjectName: this.neoProjectName,
				subaction: '',
			})
		},

    }
}
</script>

<style lang="stylus">
#duplicateWraper
	width: 100%
#duplicateProjectTab
	width: 100%
	.typeSelector
		-webkit-appearance: none
		-moz-appearance: none
		appearance: none
		height: auto
		border: 0;
		margin: 0
		padding: .75em
		border-radius: 0
		overflow: hidden
		text-overflow: ellipsis

	.duplicateProjectInput
		width: 100%
		display: inline-flex
		flex-flow: row nowrap
		background-color: var(--iconPuzzleColor)
		border-radius: 8px
		height: 40px
		input
			border: none

		.inputModal
			width: 85%
			text-align: left
			font-size: 16px
			color: black
			padding-left: 15px
			font-family: dinBold
			padding-top: 7px
			outline: none
			background: var(--linkMenuColor)

		.duplicateProjectAdd
			color: white
			display: flex
			font-size: 14px
			text-align: center
			cursor: pointer
			width: 15%
			align-self: center
			align-items: center
			justify-content: center
			background-color: var(--selectedColor)
			height: 40px
			border-bottom-right-radius: 8px
			border-top-right-radius: 8px
			padding-top: 5px
			box-sizing: border-box
			font-family: 'DinBold'
</style>
