<!-- String vue -->

<template lang="pug">

span.textinput-ctn
	input.value(type="text", v-model="value", v-bind:placeholder="placeholder", v-on:focusout="valueUpdated(field)", :disabled="field.disabled")
	
</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue', 'placeholder' ],

	data() {
		return {
			value: null,
			actionOnChange: false,
		}
	},

	created: function(){
		this.value = this.fieldvalue ? this.fieldvalue.value : "";
		if (this.field.action !== undefined) {
			this.field.action.forEach((action) => {
				if (action.on === 'change') this.actionOnChange = action;
				if (action.on === 'load') this.$parent.dynAction(action, this.value);
			});
		}
	},

	watch:{
		fieldvalue: function (obj) {
			this.value = obj ? obj.value : "";
		}
	},

	methods:{

		valueUpdated: function( fieldUpdated ){
			if (this.actionOnChange && this.value !== this.fieldvalue.value) this.$parent.dynAction(this.actionOnChange, this.value);
			this.$parent.valueUpdated( fieldUpdated, this.value );
		}


	}

}

</script>

<style lang="stylus">
</style>