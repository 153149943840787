//
// TODO: Create an OpenLayer context (MapOLContext.js)
// to apply animation or another OpenLayer features
//


import UiObject, { EVENT_CALLBACK } from '../../UiObject';

import { FEATURE_UPDATE } from './UiMapOlLayer';
import { Geo } from '../../../../data-types';

class UiMapOlMarker extends UiObject {
    constructor(id, props) {
        super(id, props, 'MapOLMarker', false);

        // store transform properties
        this.transform = {
            props: [], // list of properties to be animated
            values: { from: {}, to: {} }, // !!! from values will be overridden
        };

        this.followPathInit();

        this.initialized = false;
        this.initParameters();
    }

    static getAuthConfig() {
        return {
            exportFormat: 'ui-map-ol-marker',
            label: 'MapOL Marker',
            parent: 'ui-maps-ol',
            isDisplayed: true,
            canBeCreated: false,
            isEmbeddable: true,
            requireUiParentTypes: ['MapOLLayer'],
        };
    }

    initParameters() {
        // general
        const groupGeneral = this.createInspectorNode('group', 'general', 'General');
        groupGeneral.children.push(this.createInspectorNode('group-field', 'general-fields'));
        groupGeneral.children.push(this.createInspectorNode('group-field', 'general-position'));
        groupGeneral.children.push(this.createInspectorNode('group-field', 'general-options'));

        // viewport
        const groupViewport = this.createInspectorNode('group', 'viewport', 'Style');
        groupViewport.children.push(this.createInspectorNode('group-field', 'viewport-fields-style'));

        // childhood
        const groudChildhood = this.createInspectorNode('group', 'general', 'Childhood');
        groudChildhood.hideForObjInspector = true;
        groudChildhood.children.push(this.createInspectorNode('group-field', 'parents-fields'));

        // detection
        const groupDetection = this.createInspectorNode('group', 'detection', 'Detection');
        groupDetection.children.push(this.createInspectorNode('group-field', 'detection-fields'));
        groupDetection.children.push(this.createInspectorNode('group-field', 'detection-fields-2'));


        // custom advanced styles
        const transformStyles = this.createInspectorNode('group', 'transform', 'Transform');
        transformStyles.children.push(this.createInspectorNode('group-field', 'transform-fields'));

        const advancedTopic = this.createInspectorNode('topic', 'advancedStyles', 'Advanced styles');
        advancedTopic.children = [transformStyles];

        // topic animation
        const groupFollowPath = this.createInspectorNode('group', 'followpath', 'Follow Path');
        groupFollowPath.children.push(this.createInspectorNode('group-field', 'followpath-setting'));
        groupFollowPath.children.push(this.createInspectorNode('group-field', 'followpath-action'));
        groupFollowPath.children.push(this.createInspectorNode('group-field', 'followpath-event'));

        const animationTopic = this.createInspectorNode('topic', 'animations', 'Animations');
        animationTopic.children = [groupFollowPath];


        // events
        const eventsTopic = this.createInspectorNode('topic', 'events', 'Events');
        const mouseEvents = ['onClick', 'onDoubleClick', 'onMouseEnter', 'onMouseLeave'];
        const mouseEventGroup = this.createInspectorNode('group', 'mouseevents-group', 'Mouse Events List');
        mouseEvents.forEach((evt) => this.addGroupFieldForEvent(mouseEventGroup, 'mevents', evt));

        // @deprecated: onAnimationEnded is deprecated since version 3.2.0,
        // use onTransformEnded instead
        const olEvents = ['onAnimationEnded', 'onTransformEnded', 'onFollowPathEnded', 'onFollowPathCycleEnded'];
        const olEventGroup = this.createInspectorNode('group', 'olevents-group', 'Marker Events');
        olEvents.forEach((evt) => this.addGroupFieldForEvent(olEventGroup, 'olevents', evt));

        eventsTopic.hideForObjInspector = true;
        eventsTopic.children = [mouseEventGroup, olEventGroup];

        const stylesTopic = this.createInspectorNode('topic', 'styles', 'Marker settings');
        stylesTopic.children = [groudChildhood, groupGeneral, groupViewport, groupDetection];


        this.inspector.push(stylesTopic);
        this.inspector.push(advancedTopic);
        this.inspector.push(animationTopic);
        this.inspector.push(eventsTopic);

        const commonParameters = {

            parent: {
                type: 'String',
                // method: 'addToParent',
                callable: true,
                connection: {
                    in: { pluggable: true, default: true },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'parents-fields',
                    widget: 'calculated',
                    label: 'Layer container',
                },
            },

            visibility: {
                type: 'Boolean',
                default: true,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Visibility',
                    container: 'general-fields',
                },
            },

            opacity: {
                type: 'Float',
                default: 1,
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Opacity',
                    container: 'general-fields',
                },
            },

            customTags: {
                type: 'String',
                default: '',
                partial: null,
                method: 'setCustomTags',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Add tags',
                    container: 'general-fields',
                },
            },
            removeTags: {
                type: 'String',
                default: '',
                partial: null,
                method: 'removeTags',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Remove tags',
                    container: 'general-fields',
                },
            },

        };

        const mapParameters = {
            // Add param to positionning common settings


            latitude: {
                type: 'Float',
                default: 48.8574825,
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Latitude',
                    container: 'general-position',
                },
            },
            longitude: {
                type: 'Float',
                default: 2.3318665,
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Longitude',
                    container: 'general-position',
                },
            },

            zIndex: {
                type: 'Int',
                default: 1,
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Disposition',
                    description: 'z-Index of the marker',
                    container: 'general-position',
                },
            },

            groupTag: {
                type: 'String',
                default: null,
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Group Tags',
                    description: 'name of source group',
                    container: 'general-position',
                },
            },

            // @todo be implemented in MapOLLayer side
            // outOfCluster: {
            //     type: 'Boolean',
            //     default: false,
            //     partial: null,
            //     connection: null,
            //     auth: {
            //         label: "Out Of Cluster",
            //         description: "Do not cluster this marker.",
            //         container: "general-options"
            //     }
            // },


            useTexture: {
                type: 'Boolean',
                default: false,
                partial: null,
                connection: null,
                auth: {
                    label: 'Use Texture',
                    container: 'viewport-fields-style',
                    description: 'Use Map container texture as icon.',
                },
            },

            iconSize: {
                type: 'Float',
                default: 20,
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Icon Size',
                    container: 'viewport-fields-style',
                    description: 'Default marker icon size.',
                    conditions: [{ field: 'useTexture', value: false, operator: '==' }],
                },
            },
            iconColor: {
                type: 'Color',
                default: { hex: '#DD0000' },
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Icon Color',
                    description: 'Default marker icon color.',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'useTexture', value: false, operator: '==' }],
                },
            },


            texture: {
                type: 'String',
                default: '',
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Texture Reference',
                    description: 'Texture to use to display the pin inside the clustered GeoJSON layer (ref to textures parameter in Map container)',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'useTexture', value: true, operator: '==' }],
                },
            },
            textureSize: {
                type: 'Float',
                default: 1,
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Texture Size',
                    description: 'Marker texture size.',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'useTexture', value: true, operator: '==' }],
                },
            },
            textureAnchor: {
                // TODO: prefers use ArrayType with value like [0.5,0.5]
                type: 'String',
                default: 'center',
                partial: 'marker',
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Texture Anchor',
                    description: 'Icon anchor position inside marker.',
                    container: 'viewport-fields-style',
                    widget: 'select',
                    options: [
                        { value: 'center', label: 'center' },
                        { value: 'left', label: 'left' },
                        { value: 'right', label: 'right' },
                        { value: 'top', label: 'top' },
                        { value: 'bottom', label: 'bottom' },
                        { value: 'top-left', label: 'top-left' },
                        { value: 'top-right', label: 'top-right' },
                        { value: 'bottom-left', label: 'bottom-left' },
                        { value: 'bottom-right', label: 'bottom-right' },
                    ],
                    conditions: [{ field: 'useTexture', value: true, operator: '==' }],
                },
            },

            text: {
                type: 'String',
                default: '',
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Text',
                    description: 'Marker text.',
                    container: 'viewport-fields-style',
                },
            },
            textColor: {
                type: 'Color',
                default: { hex: '#333333' },
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Text Color',
                    description: 'Marker text color.',
                    container: 'viewport-fields-style',
                },
            },
            textOffset: {
                type: 'Vector2',
                default: { x: 0, y: 0 },
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Text Offset',
                    description: 'Marker text offset in pixels relative to center.',
                    container: 'viewport-fields-style',
                },
            },
            fontSize: {
                type: 'Int',
                default: 12,
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Font Size',
                    description: 'Marker text font size.',
                    container: 'viewport-fields-style',
                },
            },
            fontFamily: {
                type: 'String',
                default: 'sans-serif',
                partial: 'marker',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Font Family',
                    description: 'Marker text font family.',
                    container: 'viewport-fields-style',
                    widget: 'select',
                    options: [
                        { value: 'inherit', label: 'Inherit' },
                        { value: 'ComfortaaLight', label: 'Comfortaa' },
                        { value: 'Roboto', label: 'Roboto' },
                        { value: 'YaahowuLight', label: 'Yaahowu Light' },
                        { value: 'YaahowuBold', label: 'Yaahowu Bold' },
                        { value: 'PoppinsRegular', label: 'Poppins Regular' },
                        { value: 'PoppinsBold', label: 'Poppins Bold' },
                        { value: 'DINNextLTPro-Regular', label: 'DIN Next Regular' },
                        { value: 'DINNextLTPro-Bold', label: 'DIN Next Bold' },
                        { value: 'bemboregular', label: 'Bembo Regular' },
                        { value: 'bembobold', label: 'Bembo Bold' },
                        { value: 'bemboitalic', label: 'Bembo Regular Italic' },
                        { value: 'bembobold_italic', label: 'Bembo Bold Italic' },
                        { value: 'DinCondensed', label: 'DIN Condensed' },
                        { value: 'DinBoldCondensed', label: 'DIN Bold Condensed' },
                        { value: 'Georgia', label: 'Georgia' },
                        { value: 'Arial', label: 'Arial' },
                        { value: 'Verdana', label: 'Verdana' },
                        { value: 'Helvetica', label: 'Helvetica' },
                        { value: 'serif', label: 'Serif' },
                        { value: 'sans-serif', label: 'Sans-Serif' },
                        { value: 'monospace', label: 'Monospace' },
                        { value: 'GalanoRegular', label: 'Galano Regular' },
                        { value: 'GalanoBold', label: 'Galano Bold' },
                        { value: 'EarthOrbiterTitle', label: 'Earth Orbiter Title' },
                    ],
                },
            },

            radius: {
                type: 'Int',
                default: 20,
                partial: 'detection',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Radius',
                    container: 'detection-fields',
                    unit: 'm',
                },
            },
            hysteresys: {
                type: 'Float',
                default: 7,
                partial: 'detection',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'detection-fields',
                    label: 'Hysteresys',
                    unit: 'm',
                },
            },
            property: {
                type: 'String',
                default: '',
                partial: 'detection',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Property',
                    container: 'detection-fields',
                },
            },
            testPosition: {
                type: 'Vector2',
                default: { x: 0, y: 0 },
                method: 'testPosition',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Test position',
                    container: 'detection-fields-2',
                },
            },
            distance: {
                type: 'Int',
                default: null,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Distance',
                    container: 'detection-fields-2',
                    widget: 'calculated',
                },
            },
            state: {
                type: 'String',
                default: null,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'State',
                    container: 'detection-fields-2',
                    widget: 'calculated',
                },
            },
        };

        const advancedParameters = {

            // Transform
            scale: {
                type: 'Float',
                default: 1,
                partial: 'transform',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Scale',
                    container: 'transform-fields',
                    description: 'Scale to apply on all elements of this marker.',
                },
            },
            rotation: {
                type: 'Angle',
                default: 0,
                partial: 'transform',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Rotation',
                    container: 'transform-fields',
                    description: 'Rotation to apply on all elements of this marker.',
                },
            },
            translate: {
                type: 'Geo',
                default: { lat: 0, lng: 0 },
                partial: 'transform',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Translate',
                    container: 'transform-fields',
                    description: 'Translation to apply on all elements of this marker.',
                    conditions: [{ field: 'moduleMode', value: 'create', operator: '!=' }],
                },
            },

            transformEasing: {
                type: 'String',
                default: 'linear',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    widget: 'select',
                    partial: 'transform',
                    options: [
                        { value: 'linear', label: 'linear' },
                        { value: 'easeIn', label: 'easeIn' },
                        { value: 'easeOut', label: 'easeOut' },
                        { value: 'inAndOut', label: 'inAndOut' },
                        { value: 'upAndDown', label: 'upAndDown' },
                    ],
                    label: 'Easing',
                    container: 'transform-fields',
                    description: 'Type of tansform to apply on this marker (see https://openlayers.org/en/latest/apidoc/module-ol_easing.html).',
                },
            },
            transformProperty: {
                default: 'all',
                type: 'String',
                label: 'Property',
                widget: 'String',
                partial: 'transform',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Property',
                    container: 'transform-fields',
                    description: 'Which property of this marker should be impacted during transform changes (e.g. \'all\', \'opacity,scale\', …)',
                },
            },
            transformDuration: {
                type: 'Float',
                default: 0,
                partial: 'transform',
                widget: 'Float',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    unit: 's',
                    label: 'Duration',
                    container: 'transform-fields',
                    description: 'Duration on which to apply transform changes.',
                },
            },
        };

        const animationParameters = {

            // Follow Path
            afpPath: {
                type: 'String',
                default: '',
                partial: 'followpath',
                method: 'followPathAddPath',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Path Reference',
                    container: 'followpath-setting',
                    description: 'The path reference (i.e. a MapLinePath unique tag or reference) to be followed by this marker.\nThis action will reset the previous path.',
                },
            },
            afpCoords: {
                type: 'ArrayType',
                default: null,
                partial: 'followpath',
                method: 'followPathAddCoordinates',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Path Coordinates',
                    container: 'followpath-setting',
                    description: 'A list of coordinates to define the path.\nThis action will reset the previous path.',
                },
            },
            afpPoint: {
                type: 'Geo',
                default: null,
                partial: 'followpath',
                method: 'followPathAddPoint',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Add Point',
                    container: 'followpath-setting',
                    description: 'Push a new polar coordinate at the end of the path.',
                },
            },
            afpOffset: {
                type: 'Int',
                default: 0,
                partial: 'followpath',
                method: 'followPathOffset',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Path offset',
                    container: 'followpath-setting',
                    description: 'Set or output the path reference\'s index while following the marker path.',
                },
            },
            afpClear: {
                trigger: true,
                partial: 'followpath',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Clear Path',
                    container: 'followpath-setting',
                    description: 'Clear the currrent path.',
                },
            },
            afpSpeed: {
                type: 'Float',
                default: 1.0,
                partial: 'followpath',
                method: 'followPathSpeed',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Speed',
                    container: 'followpath-setting',
                    description: 'Velocity to apply to the follow path animation for this marker.',
                },
            },
            afpLoop: {
                type: 'Boolean',
                default: false,
                partial: 'followpath',
                method: 'followPathLoop',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Loop',
                    container: 'followpath-setting',
                    description: 'Loop the follow path animation indefinitely for this marker.',
                },
            },
            afpStart: {
                trigger: true,
                partial: 'followpath',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Start',
                    container: 'followpath-action',
                    description: 'Start the follow path animation.',
                },
            },
            afpStop: {
                trigger: true,
                partial: 'followpath',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Stop',
                    container: 'followpath-action',
                    description: 'Stop the follow path animation.',
                },
            },
            afpPause: {
                trigger: true,
                partial: 'followpath',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Pause',
                    container: 'followpath-action',
                    description: 'Pause the follow path animation.',
                },
            },
            afpResume: {
                trigger: true,
                partial: 'followpath',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Resume',
                    container: 'followpath-action',
                    description: 'Resume the follow path animation.',
                },
            },
            afpProgression: {
                type: 'Float',
                default: 0,
                partial: 'followpath',
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    widget: 'calculated',
                    label: 'Progression',
                    container: 'followpath-event',
                    description: 'Returns the follow path animation progression (betweeen 0 and 1) for this marker.',
                },
            },
        };

        this.addToParameters(commonParameters);
        this.addToParameters(mapParameters);
        this.addToParameters(advancedParameters);
        this.addToParameters(animationParameters);

        // add events
        mouseEvents.forEach((evt) => {
            const subParams = [
                {
                    name: 'trigger',
                    label: 'Trigger',
                    type: 'Boolean',
                    default: false,
                },
                {
                    name: 'triggerObjRef',
                    label: 'Trigger object ref',
                    type: 'String',
                    default: null,
                },
                {
                    name: 'position',
                    label: 'Position',
                    type: 'Geo',
                    default: { lat: 0, lng: 0 },
                },
            ];
            const param = this.createEventParameters('mevents', evt, subParams);
            this.addToParameters(param);
        });
        olEvents.forEach((evt) => {
            const subParams = [
                {
                    name: 'trigger',
                    label: 'Trigger',
                    type: 'Boolean',
                    default: false,
                },
            ];
            const params = this.createEventParameters('olevents', evt, subParams);
            // @deprecated: onAnimationEnded is deprecated since version 3.2.0,
            // use onTransformEnded instead
            if (evt === 'onAnimationEnded') {
                params['olevents_onAnimationEnded'].auth.deprecated = true;
                params['olevents_onAnimationEnded'].auth.label = 'On Animation Ended';
                params['olevents_onAnimationEnded'].auth.description = 'Deprecated, use "On Transform Ended" instead';
                params['olevents_onAnimationEnded-trigger'].auth.description = 'Deprecated, use "On Transform Ended" instead';
                params['olevents_onAnimationEnded-trigger'].auth.deprecated = true;
            }
            if (evt === 'onTransformEnded') {
                params['olevents_onTransformEnded'].auth.label = 'On Transform Ended';
                params['olevents_onTransformEnded'].auth.description = 'Enable the transform animation event.';
                params['olevents_onTransformEnded-trigger'].auth.description = 'Triggers when the transform animation is done.';
            }
            if (evt === 'onFollowPathEnded') {
                params['olevents_onFollowPathEnded'].auth.label = 'On Follow Path Ended';
                params['olevents_onFollowPathEnded'].auth.description = 'Enable the follow path animation event.';
                params['olevents_onFollowPathEnded-trigger'].auth.description = 'Triggers when the follow path animation is done.';
            }
            if (evt === 'onFollowPathCycleEnded') {
                params['olevents_onFollowPathCycleEnded'].auth.label = 'On Follow Path Cycle Ended';
                params['olevents_onFollowPathCycleEnded'].auth.description = 'Enable the follow path animation cycle event.';
                params['olevents_onFollowPathCycleEnded-trigger'].auth.description = 'Triggers when the follow path animation cycle is done.';
            }
            this.addToParameters(params);
        });
    }

    // HACK:TODO: check nodal-app requirements and prefers
    // replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init(); }

    init() {
        super.init();

        // whether the mouse is over the marker
        this.mouseover = false;

        this.currentState = 'unknown';
        this.set('state', this.currentState);
        this.set('distance', null);
        this.currentSymbol = null;

        this.initialized = true;

        // IMPORTANT: the parent property is always required while creating an UI object. Depending 
        // of the current schematic, the user could thing that this porperty is stored (but it's not),
        // so we force to display the error message to inform the user about the right strategy.
        if (!this.get('parent')) this.addToParent(null);
    }

    // @todo: never be called
    dispose() {
        super.dispose();
    }

    testPosition(value) {
        if (typeof env !== 'undefined' && env.isAuthoring) return;
        if (!this.get('latitude') || !this.get('longitude')) return;

        const WGSPosition = new Geo({ lat: this.get('latitude'), lng: this.get('longitude') }, 'wgs');
        const lambertPosition = WGSPosition.toVector2('lambert93').value;

        const inRadius = this.get('radius') - this.get('hysteresys') * 0.5;
        const outRadius = this.get('radius') + this.get('hysteresys') * 0.5;

        if (value.x != null) {
            value = { lat: value.x, lng: value.y };
        }
        if (value.lat != null && lambertPosition) {
            const WGSValue = new Geo({ lat: value.lat, lng: value.lng }, 'wgs');
            const lambertValue = WGSValue.toVector2('lambert93').value; // @todo change lambert for something better

            const x = Math.pow(lambertValue.x - lambertPosition.x, 2);
            const y = Math.pow(lambertValue.y - lambertPosition.y, 2);
            const dist = Math.sqrt(x + y);
            this.set('distance', dist);

            if (dist < inRadius && this.currentState !== 'in') {
                this.currentState = 'in';
                this.set('state', this.currentState);
            } else if (dist > outRadius && this.currentState === 'in') {
                this.currentState = 'visited';
                this.set('state', this.currentState);
            }
        }
    }

    hasMouseEventOutput() {
        return this.get('mevents_onMouseEnter') || this.get('mevents_onMouseLeave');
    }

    markerMouseEventCallback(markerId, coords, over) {
        // enter
        if (over && !this.mouseover) {
            this.fireOutput('mevents_onMouseEnter-trigger');
            this.fireOutput('mevents_onMouseEnter-triggerObjRef', markerId);
            this.fireOutput('mevents_onMouseEnter-position', { lat: coords[1], lng: coords[0] });
        }

        // leave
        if (!over && this.mouseover) {
            this.fireOutput('mevents_onMouseLeave-trigger');
            this.fireOutput('mevents_onMouseLeave-triggerObjRef', markerId);
            this.fireOutput('mevents_onMouseLeave-position', { lat: coords[1], lng: coords[0] });
        }

        this.mouseover = over;
    }

    markerClickedCallback(markerId, coords) {
        this.fireOutput('mevents_onClick-trigger');
        this.fireOutput('mevents_onClick-triggerObjRef', markerId);
        this.fireOutput('mevents_onClick-position', { lat: coords[1], lng: coords[0] });
    }

    markerDoubleClickedCallback(markerId, coords) {
        this.fireOutput('mevents_onDoubleClick-trigger');
        this.fireOutput('mevents_onDoubleClick-triggerObjRef', markerId);
        this.fireOutput('mevents_onDoubleClick-position', { lat: coords[1], lng: coords[0] });
    }

    /**
     * Transform animation ended callback.
     */
    onTransformEnded() {
        this.transform.props = []; // remove properties
        this.triggerFeatureUpdate(); // force the transformation target values
        // @deprecated: onAnimationEnded is deprecated since version 3.2.0,
        // use onTransformEnded instead
        this.fireOutput('olevents_onAnimationEnded-trigger');
        this.fireOutput('olevents_onTransformEnded-trigger');
    }

    requestTransform(prop, value) {
        if (this.initialized === false) return false;

        const featureTransformProp = [
            'opacity', 'scale', 'translate',
            // 'rotation',
            // 'iconColor', 'textColor'
        ];

        if (featureTransformProp.indexOf(prop) !== -1) {
            // if value are different set the property to be animated
            // if not, remove it from the animation list
            const raw = value && value.dataType ? value.value : value;
            const index = this.transform.props.indexOf(prop);
            if (raw !== this.get(prop)) {
                if (index === -1) this.transform.props.push(prop);
                this.transform.values.to[prop] = raw;
                return true;
            }
            if (index > -1) this.transform.props.splice(index, 1);
        }

        return false;
    }

    set(prop, value, partial = false) {
        const animate = this.requestTransform(prop, value);
        if (!animate) super.set(prop, value, partial);

        const featureUpdateProp = [
            'sourceGroup',
            'opacity', 'zIndex', 'visibility',
            'iconSize', 'iconColor',
            'texture', 'textureSize', 'textureAngle', 'textureAnchor',
            'text', 'textSize', 'textColor', 'textOffset', 'fontSize', 'fontFamily',
            'longitude', 'latitude',
            'scale', 'rotation', 'translate', 'transformDuration',
        ];

        if (featureUpdateProp.indexOf(prop) !== -1 && this.initialized === true) {
            this.triggerFeatureUpdate();
        }
    }

    // @todo: possible deadcode, never called (?)
    // ==========================================
    update(prop, value) {
        super.set(prop, value, false);
    }
    // ==========================================

    rgb2hex(color) {
        const rgb = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        if (rgb && rgb.length === 4) {
            const r = `0${parseInt(rgb[1], 10).toString(16)}`;
            const g = `0${parseInt(rgb[2], 10).toString(16)}`;
            const b = `0${parseInt(rgb[3], 10).toString(16)}`;
            return `#${r.slice(-2)}${g.slice(-2)}${b.slice(-2)}`;
        }
        return '';
    }

    formatAsFeature() {
        const hexa = this.rgb2hex(this.get('iconColor'));
        const size = this.get('iconSize') !== 'undefined' ? this.get('iconSize') : this.getParameter('iconSize').default;
        const opacity = this.get('opacity') !== 'undefined' ? this.get('opacity') : this.getParameter('opacity').default;
        const texture = this.get('useTexture') && this.get('texture').trim() !== '' ? this.get('texture').trim() : null;

        const symbolNameArray = [hexa, size, opacity];
        const symbolName = texture !== null ? texture : symbolNameArray.join('-');

        this.currentSymbol = symbolName;
        this.transform.easing = this.get('transformEasing');
        this.transform.property = this.get('transformProperty');
        this.transform.duration = this.get('transformDuration') ? this.get('transformDuration') * 1000 : 0;

        return {
            type: 'Feature',
            properties: {
                key: this.id,
                symbol: symbolName,
                type: 'marker',
                outOfCluster: this.get('outOfCluster'),

                opacity,
                scale: this.get('scale') ? this.get('scale') : 1,
                rotation: this.get('rotation') ? this.get('rotation') * Math.PI / 180 : 0, // @todo: Angle type value should be setted in radian
                zIndex: this.get('zIndex'),
                groupTag: this.get('groupTag'),

                iconSize: size,
                iconColor: this.get('iconColor'),

                hasTexture: texture !== null,
                texture,
                textureSize: this.get('textureSize'),
                textureAnchor: this.get('textureAnchor'),

                text: this.get('text') ? this.get('text') : null,
                textColor: this.get('textColor'),
                textOffset: this.get('textOffset'),
                fontSize: this.get('fontSize'),
                fontFamily: this.get('fontFamily'),

                captureClick: this.get('mevents_onClick'),
                captureDbClick: this.get('mevents_onDoubleClick'),

                transform: this.transform,
                animations: {
                    followpath: this.followpath,
                },
            },
            geometry: {
                type: 'Point',
                coordinates: this.followpath.latest || [this.get('longitude'), this.get('latitude')],
            },
        };
    }

    triggerFeatureUpdate() {
        this.emit(FEATURE_UPDATE, {
            id: this.id,
            feature: this.get('visibility') !== false ? this.formatAsFeature() : null,
            visible: this.get('visibility'),
            update: true,
        });
    }

    /**
     * Call the listener attached to the specified output.
     *
     * @param {string} pname - The parameter name of the output.
     * @param {...} pvalue - The parameter value passed to the output listener.
     */
    fireOutput(pname, pvalue = true) {
        this.emit(EVENT_CALLBACK, { name: pname, value: pvalue });
    }

    /**
     * Attach all trigger field listener to this object.
     *
     * @param {Object} module - The parent module
     * @override
     */
    registerTriggerListeners(module) {
        module.addInputListener('afpStart', this.followPathStart);
        module.addInputListener('afpStop', this.followPathStop);
        module.addInputListener('afpPause', this.followPathPause);
        module.addInputListener('afpResume', this.followPathResume);
        module.addInputListener('afpClear', this.followPathClearPath);
    }

    /**
     * Detach all trigger field listener from this object.
     *
     * @param {Object} module - The parent module
     * @override
     */
    unregisterTriggerListeners(module) {
        module.removeInputListener('afpStart', this.followPathStart);
        module.removeInputListener('afpStop', this.followPathStop);
        module.removeInputListener('afpPause', this.followPathPause);
        module.removeInputListener('afpResume', this.followPathResume);
        module.removeInputListener('afpClear', this.followPathClearPath);
    }

    // -- Follow Path animation ----------------------------------------------------

    /**
     * Initialise he FollowPath animation
     */
    followPathInit() {

        // store FollowPath animation properties.
        // this animation could have a status set to 'stopped', 'playing' or 'paused'
        this.followpath = {
            points: [],     // list of coordinates [lon,lat]
            index: 0,       // the current target point
            offset: 0,      // path index offset
            loop: false,    // loop the animation or not
            speed: 1,       // animation velocity
            running: false, // whether the animation is playong or not
            paused: false,  // whether the animation is paused or not
        };


        this.followPathStart = this.followPathStart.bind(this);
        this.followPathStop = this.followPathStop.bind(this);
        this.followPathPause = this.followPathPause.bind(this);
        this.followPathResume = this.followPathResume.bind(this);
        this.followPathClearPath = this.followPathClearPath.bind(this);
    }

    /**
     * Define the FollowPath animation path from the specified MapLinePath reference.
     *
     * @param {Object} param - The afpPath parameter value
     *      */
    followPathAddPath(param) {
        const linepath = param.afpPath;
        if (!linepath || linepath === undefined) return;

        this.followpath.index = 0;
        this.followpath.points = [];

        const modules = this.assetManager.get(linepath);
        if (Array.isArray(modules) && modules.length !== 1) {
            const msg = modules.length > 1 ? 'must be unique' : 'could not be found';
            console.warn(`The path reference '${linepath}' ${msg} for the marker FollowPath animation.`);
            return;
        }

        const module = Array.isArray(modules) ? modules[0] : modules;
        if (module.points.length === 0) {
            console.warn(`The path reference '${linepath}' is empty for the marker FollowPath animation.`);
            return;
        }

        // we assume that points are stored in [longitude, latitude] in MapLinePath
        module.points.forEach(pt => this.followPathAddLonLat(pt[0], pt[1]));

        // restart animation if needed
        if (this.followpath.running) {
            this.followpath.restart = true;
            this.triggerFeatureUpdate();
        }

        // @todo: to be set in verbose mode [ticket NS-113]
        //console.log(`Number of points in the FollowPath animation path ${this.followpath.points.length} [ UID: ${this.id} ]`);
    }

    /**
     * Define the FollowPath animation path with the given list of coordinates.
     *
     * @param {Object} param - The afpCoords parameter value (type Array)
     */
    followPathAddCoordinates(param) {
        const points = param.afpCoords;
        if (!points || points === undefined) return;

        if (!Array.isArray(points) || points.length === 0) {
            console.warn('Invalid list of coordinates for the marker FollowPath animation:', points);
            return;
        }

        this.followpath.index = 0;
        this.followpath.points = [];

        for (let i = 0; i < points.length; i++) {
            switch (points[i].dataTypeName) {
            case 'Vector2':
                // we assume that y is the longitude and x is the latitude
                this.followPathAddLonLat(points[i].value.y, points[i].value.x);
                break;
            case 'Geo':
                this.followPathAddLonLat(points[i].value.lng, points[i].value.lat);
                break;
            default:
                console.warn('Invalid coordinate type for the marker FollowPath animation:', points[i]);
                return;
            }
        }

        // restart animation if needed
        if (this.followpath.running) {
            this.followpath.restart = true;
            this.triggerFeatureUpdate();
        }

        // @todo: to be set in verbose mode [ticket NS-113]
        //console.log(`Number of points in the FollowPath animation path ${this.followpath.points.length} [ UID: ${this.id} ]`);
    }

    /**
     * Push a new polar coordinate at the end of the FollowPath animation path.
     *
     * @param {Object} param - The afpPoint parameter value (type Geo)
     */
    followPathAddPoint(param) {
        const point = param.afpPoint;
        if (!point || point === undefined) return;

        if (typeof (point.lat) !== 'number' || typeof (point.lng) !== 'number') {
            console.warn('Invalid point type for the marker FollowPath animation (require Geo)', point);
            return;
        }

        if (this.followPathAddLonLat(point.lng, point.lat)) {
            // @todo: to be set in verbose mode [ticket NS-113]
            //console.log(`Number of points in the FollowPath animation path ${this.followpath.points.length} [ UID: ${this.id} ]`);
        }
    }

    /**
     * The FollowPath animation store the path points as GeoJSON coodinates, i.e. [lon, lat].
     * see https://tools.ietf.org/html/rfc7946#appendix-A.1 for documentation
     *
     * @param {number} lon - The longitude coordinate
     * @param {number} lat - The latitude coordinate
     * @returns False on duplication otherwise true
     * @private
     */
    followPathAddLonLat(lon, lat) {
        // avoid duplicate
        const latest = this.followpath.points[this.followpath.points.length - 1];
        if (!latest || latest[0] !== lon || latest[1] !== lat) {
            this.followpath.points.push([lon, lat]);
            return true;
        }
        return false;
    }

    /**
     * Set the path reference's index offset.
     *
     * @param {Object} param - The afpOffset parameter value (type Int)
     */
    followPathOffset(param) {
        if (param.afpOffset === undefined) return;
        this.followpath.offset = param.afpOffset;
    }

    /**
     * Clear tke FollowPath animation path.
     */
    followPathClearPath() {
        this.followpath.points = [];
        this.triggerFeatureUpdate();
        this.fireOutput('afpClear');
    }

    /**
     * Apply the specified velocity to the FollowPath animation.
     *
     * @param {Object} param - The afpSpeed parameter (type float)
     */
    followPathSpeed(param) {
        if (param.afpSpeed === undefined) return;
        this.followpath.speed = param.afpSpeed;
        this.triggerFeatureUpdate();
    }

    /**
     * Loop the FollowPath animation indefinitely.
     *
     * @param {Object} param - The afpLoop parameter (type boolean)
     */
    followPathLoop(param) {
        if (param.afpLoop === undefined) return;
        this.followpath.loop = (param.afpLoop === true);
        this.triggerFeatureUpdate();
    }

    /**
     * Start the FollowPath animation.
     */
    followPathStart() {
        if (!this.followpath.status || this.followpath.status === 'stopped') {
            if (this.followpath.points.length) {
                this.followpath.index = 0;
                this.followpath.running = true;
                this.followpath.paused = false;
                this.followpath.status = 'playing';
                this.triggerFeatureUpdate();
                this.fireOutput('afpStart');
            }
        }
    }

    /**
     * Stop the FollowPath animation.
     */
    followPathStop() {
        if (this.followpath.status && this.followpath.status !== 'stopped') {
            this.onFollowPathEnded();
            this.triggerFeatureUpdate();
            this.fireOutput('afpStop');
        }
    }

    /**
     * Pause the FollowPath animation.
     */
    followPathPause() {
        if (this.followpath.running && !this.followpath.paused) {
            this.followpath.paused = true;
            this.followpath.status = 'paused';
            this.triggerFeatureUpdate();
            this.fireOutput('afpPause');
        }
    }

    /**
     * Resume the FollowPath animation.
     */
    followPathResume() {
        if (this.followpath.running && this.followpath.paused) {
            this.followpath.paused = false;
            this.followpath.status = 'playing';
            this.triggerFeatureUpdate();
            this.fireOutput('afpResume');
        }
    }

    /**
     * The Follow path animation ended callback.
     */
    onFollowPathEnded() {
        this.followpath.running = false;
        this.followpath.paused = false;
        this.followpath.status = 'stopped';
        this.fireOutput('olevents_onFollowPathEnded-trigger');
    }

    /**
     * The Follow path animation cycle ended callback.
     */
    onFollowPathCycleEnded() {
        this.fireOutput('olevents_onFollowPathCycleEnded-trigger');
    }

    /**
     * The Follow path progression callback.
     *
     * @param {nulber} index - The path reference's index
     */
    onFollowPathProgression(index) {

        const len = this.followpath.points.length;
        const norm = !len || index === 0 ? 0 : 1.0 / ((len - 1) / index);

        this.followpath.index = index;
        this.followpath.latest = this.followpath.points[index];
        this.fireOutput('afpOffset', this.followpath.index);
        this.fireOutput('afpProgression', norm);
    }
}

export default UiMapOlMarker;
