import ActionAbstract from "../../ActionAbstract";
import * as helper from '../../../helper';
import Vue from 'vue';

class UpdateWidgetStatus extends ActionAbstract {

    /* params :
    *  @screenId (String)
    *  @widgetId (String)
    *  @activated (Bool)
    */

    static get ID() {
        return 'actions:inspector:widgets:updateWidgetStatus';
    }

    run(state) {

        this.screen = null;
        this.screenPath = null;


        [this.screen, this.screenPath] = helper.block.getBlockById(state.project, this.getParam('screenId'), true);

        if( ! this.screen.value.widgetsActivated )
            Vue.set( this.screen.value, 'widgetsActivated' , [] );
        
        let current = this.screen.value.widgetsActivated;
        
        let widgetIndex = current.indexOf( this.getParam('widgetId') );

        if( widgetIndex == -1 && this.getParam('activated') === true )
            current.push( this.getParam('widgetId') );

        if( widgetIndex != -1 && this.getParam('activated') === false )
            current.splice( widgetIndex, 1 );

        
        Vue.set( this.screen.value, 'widgetsActivated' , current );

        this.trigger("inspector:widgets:updateWidgetOrder", { subaction: 'refresh', screenId: this.getParam('screenId') } );

        return state;
    }

}

export default UpdateWidgetStatus;