import AdvancedUiObject from '../AdvancedUiObject';

class FakeUiObject extends AdvancedUiObject {
    constructor(id, props) {
        super(id, props, 'Button');

    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-object",
            label: "Ui Object",
            parent : "ui-layouts",
            isDisplayed : true,
            isEmbeddable: true,
            deprecated: true,
            nbSection: 1,
            childrenType: 'ui'
        };
    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }

    init(){
        super.init();

    }
}

export default FakeUiObject;
