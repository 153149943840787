import UiObject from '../../UiObject';
import { STATE_CHANGE } from '../../UiObject';
import { EVENT_CALLBACK } from '../../UiObject';

export const MARKER_FEATURE_UPDATE = 'MARKER_FEATURE_UPDATE';
export const LINE_FEATURE_UPDATE = 'LINE_FEATURE_UPDATE';

class UiMapbox extends UiObject {

    constructor(id, props) {
        super(id, props, 'Mapbox', false );

        this._onMarkerFeatureUpdated = this._onMarkerFeatureUpdated.bind(this);

        this.initParameters();
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-mapbox",
            label: "Mapbox",
            parent : "ui-maps-mapbox",
            isDisplayed : true,
            canBeCreated : false,
            isEmbeddable: true,
            nbSection: 1,
            childrenType: 'ui'
        };
    }


    
    initParameters(){

        // super.initParameters(); // do not inherit of UiObject parameters

        let groupGeneral = this.createInspectorNode('group', 'general', 'General' );
        groupGeneral.children.push( this.createInspectorNode('group-field', 'general-fields') );

        let groudChildhood = this.createInspectorNode('group', 'general', 'Childhood' );
        groudChildhood.hideForObjInspector = true,
        groudChildhood.children.push( this.createInspectorNode('group-field', 'parents-fields') );
        groudChildhood.children.push( this.createInspectorNode('group-field', 'childs-fields') );

        let groupViewport = this.createInspectorNode('group', 'viewport', 'Viewport' );
        groupViewport.children.push( this.createInspectorNode('group-field', 'viewport-fields-style') );
        groupViewport.children.push( this.createInspectorNode('group-field', 'viewport-fields-size') );
        groupViewport.children.push( this.createInspectorNode('group-field', 'viewport-fields-position') );
        groupViewport.children.push( this.createInspectorNode('group-field', 'viewport-fields-cluster') );
        
        let groupControl = this.createInspectorNode('group', 'control', 'Control' );
        groupControl.children.push( this.createInspectorNode('group-field', 'control-fields-1') );
        groupControl.children.push( this.createInspectorNode('group-field', 'control-fields-2') );
        groupControl.children.push( this.createInspectorNode('group-field', 'control-fields-nav') );

        let groupAnimation = this.createInspectorNode('group', 'animation', 'Animation' );
        groupAnimation.children.push( this.createInspectorNode('group-field', 'animation-fields') );


        let stylesTopic = this.createInspectorNode( 'topic', 'styles', 'Map styles' );
        stylesTopic.children = [ groupGeneral, groudChildhood, groupViewport, groupControl, groupAnimation ];

        this.inspector.push( stylesTopic );
        
        let commonParameters = {
                // GENERAL 
                children: {
                    type: 'Mixed', 
                    default: [], 
                    partial: null,
                    auth :  {
                        label: "",
                        container: "childs-fields",
                        invisible : true
                    }
                },
    
                parent:{
                    type: 'String',
                    //method: 'addToParent',
                    callable: true,
                    connection: {
                        in: {pluggable: true, default: false},
                        out: {pluggable: false, default: false}
                    },
                    auth:{ container: 'parents-fields',
                        widget: 'calculated',
                        label: 'Parent ref',
                    }
                },
                addMarker: { 
                    type: 'String',
                    method: 'addChild',
                    connection: {
                        in: {pluggable: true, default: false},
                        out: {pluggable: false, default: false}
                    },
                    auth:{ container: 'childs-fields',
                        widget: 'calculated',
                        label: 'Add Marker', 
                    } 
                },
                removeMarker: { 
                    type: 'String',
                    method: 'removeChild',
                    connection: {
                        in: {pluggable: true, default: false},
                        out: {pluggable: false, default: false}
                    },
                    auth:{ container: 'childs-fields',
                        widget: 'calculated',
                        label: 'Remove Marker', 
                    } 
                },
                removeAllMarkers: { 
                    type: 'String',
                    method: 'removeAllChildren',
                    connection: {
                        in: {pluggable: true, default: false},
                        out: {pluggable: false, default: false}
                    },
                    auth:{ container: 'childs-fields',
                        widget: 'calculated',
                        label: 'Remove all Markers', 
                    } 
                },
    
                visibility: {
                    type: 'Boolean', 
                    default: true, 
                    partial: null,
                    connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                    auth :  {
                        label: "Visibility",
                        container: "general-fields"
                    }
                },
                
                lock: {
                    type: 'Boolean', 
                    default: false, 
                    partial: null,
                    connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                    auth :  {
                        label: "Lock",
                        container: "general-fields"
                    }
                },

                customTags: {
                    type: 'String', 
                    default: "", 
                    partial: null,
                    method: 'setCustomTags',
                    connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                    auth :  {
                        label: "Add tags",
                        container: "general-fields"
                    }
                },
                removeTags: {
                    type: 'String', 
                    default: "", 
                    partial: null,
                    method: 'removeTags',
                    connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                    auth :  {
                        label: "Remove tags",
                        container: "general-fields"
                    }
                },

        };
        
        let mapParameters = {
            // Add param to positionning common settings
            loaded : {
                type: 'Mixed', 
                event : true,
                connection: {
                    in: {pluggable: false, default: false, disabled: true},
                    out: {pluggable: true, default: true},
                },
                auth :  {
                    label: "Map Loaded",
                    container: "viewport-fields-style"
                }
            },

            
            autoOutputMarkersRendered : {
                type: 'Boolean', 
                default: false,
                connection: null,
                auth :  {
                    label: "Auto Query Markers Rendered",
                    container: "viewport-fields-style"
                }
            },
            autoOutputMarkersRenderedThrottle : {
                type: 'Int', 
                default: 500,
                connection: null,
                auth :  {
                    label: "Query Markers Rendered Throttle",
                    container: "viewport-fields-style",
                    unit: "ms",
                    conditions: [{field:'autoOutputMarkersRendered', value:true, operator:'=='}]
                }
            },
            queryMarkersRendered : {
                type: 'Mixed', 
                callable: true,
                connection: {
                    in: {pluggable: true, default: false },
                    out: {pluggable: false, default: false},
                },
                auth :  {
                    label: "Query Markers Rendered",
                    container: "viewport-fields-style"
                }
            },
            markersRendered : {
                type: 'String', 
                event : true,
                connection: {
                    in: {pluggable: false, default: false, disabled: true},
                    out: {pluggable: true, default: false},
                },
                auth :  {
                    label: "Markers Rendered",
                    container: "viewport-fields-style"
                }
            },
            
            positionClicked : {
                type: 'Vector2', 
                event : true,
                connection: {
                    in: {pluggable: false, default: false, disabled: true},
                    out: {pluggable: true, default: false},
                },
                auth :  {
                    label: "Position clicked",
                    container: "viewport-fields-style"
                }
            },
            
            markerClicked : {
                type: 'String', 
                event : true,
                connection: {
                    in: {pluggable: false, default: false, disabled: true},
                    out: {pluggable: true, default: false},
                },
                auth :  {
                    label: "Marker clicked",
                    container: "viewport-fields-style"
                }
            },

            mapStyle : {
                type: 'String', 
                default: "", 
                partial: 'map',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "MapBox Style",
                    container: "viewport-fields-style"
                }
            },

            textures: {
                type : 'Array',
                connection : null,
                auth:{
                    container: "viewport-fields-style",
                    label: 'Textures',
                    description: "texture to load at map initialization. Textures can be used to display markers (inside clustered layer & custom style param).",
                    pattern: [
                        {name:'color',type:'String', widget:'string'},
                        {name:'texture',type:'String', widget:'string'}
                    ]
                }
            },

            fullWidth : {
                type: 'Boolean', 
                default: false, 
                partial: 'map',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Fullwidth (window)",
                    container: "viewport-fields-size"
                }
            },
            width : {
                type: 'Int', 
                default: 380, 
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Width",
                    container: "viewport-fields-size",
                    unit: "px",
                    conditions: [{field:'fullWidth', value:false, operator:'=='}]
                }
            },
            fullHeight : {
                type: 'Boolean', 
                default: false, 
                partial: 'map',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "FullHeight (window)",
                    container: "viewport-fields-size"
                }
            },
            height : {
                type: 'Int', 
                default: 250, 
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Height",
                    container: "viewport-fields-size",
                    unit: "px",
                    conditions: [{field:'fullHeight', value:false, operator:'=='}]
                }
            },
            

            latitude : {
                type: 'Float', 
                default: 48.8574825, 
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Latitude",
                    container: "viewport-fields-position"
                }
            },
            longitude : {
                type: 'Float', 
                default: 2.3318665, 
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Longitude",
                    container: "viewport-fields-position"
                }
            },
            zoom : {
                type: 'Int', 
                default: 4, 
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Zoom",
                    container: "viewport-fields-position"
                }
            },
            bearing : {
                type: 'Int', 
                default: 0 ,
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Bearing",
                    container: "viewport-fields-position"
                }
            },
            pitch : {
                type: 'Int', 
                default: 0 ,
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Pitch",
                    container: "viewport-fields-position"
                }
            },

            
            clusterMaxZoom : {
                type: 'Int', 
                default: 13 ,
                partial: 'cluster',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Cluster Max Zoom",
                    container: "viewport-fields-cluster",
                    description: "Max zoom to cluster points on"
                }
            },

            clusterRadius : {
                type: 'Int', 
                default: 50 ,
                partial: 'cluster',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Cluster Radius",
                    container: "viewport-fields-cluster",
                    description: "Radius of each cluster when clustering points (defaults to 50)"
                }
            },


            

            minZoom  : {
                type: 'Int', 
                default: 0 ,
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Min Zoom",
                    container: "control-fields-1"
                }
            },
            maxZoom  : {
                type: 'Int', 
                default: 20 ,
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Max Zoom",
                    container: "control-fields-1"
                }
            },
            minPitch : {
                type: 'Int', 
                default: 0 ,
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Min Pitch",
                    container: "control-fields-1"
                }
            },
            maxPitch : {
                type: 'Int', 
                default: 60 ,
                partial: 'viewport',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Max Pitch",
                    container: "control-fields-1"
                }
            },

            
            scrollZoom : {
                type: 'Boolean', 
                default: true ,
                partial: 'mapcontrol',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Scroll Zoom",
                    container: "control-fields-2"
                }
            },
            dragPan : {
                type: 'Boolean', 
                default: true ,
                partial: 'mapcontrol',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Drag Pan",
                    container: "control-fields-2"
                }
            },
            dragRotate : {
                type: 'Boolean', 
                default: true ,
                partial: 'mapcontrol',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Drag Rotate",
                    container: "control-fields-2"
                }
            },
            doubleClickZoom : {
                type: 'Boolean', 
                default: true ,
                partial: 'mapcontrol',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Double Click Zoom",
                    container: "control-fields-2"
                }
            },
            touchZoom : {
                type: 'Boolean', 
                default: true ,
                partial: 'mapcontrol',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Touch Zoom",
                    container: "control-fields-2"
                }
            },
            touchRotate : {
                type: 'Boolean', 
                default: true ,
                partial: 'mapcontrol',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Touch Rotate",
                    container: "control-fields-2"
                }
            },


            // Nav
            
            displayNavigation : {
                type: 'Boolean', 
                default: true ,
                partial: 'map',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Display Navigation Control",
                    container: "control-fields-nav"
                }
            },
            positionNavigation : {
                type: 'String', 
                default: "right" ,
                partial: 'map',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Position Navigation Control",
                    container: "control-fields-nav",
                    widget:"select",
                    options: [{value:'left', label:'Left'}, {value:'right', label:'Right'}],
                }
            },
            marginNavigation : {
                type: 'Int', 
                default: 10 ,
                partial: 'map',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Margin Navigation Control",
                    container: "control-fields-nav",
                    unit: 'px'
                }
            },



            transitionDuration : {
                type: 'Int', 
                default: 0 ,
                partial: 'map',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Transition Duration",
                    container: "animation-fields",
                    unit: 'ms'
                }
            },
            transitionInterpolator : {
                type: 'String', 
                default: 'easeTo',
                partial: 'map',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Transition Interpolator",
                    container: "animation-fields",
                    widget:"select",
                    options: [{value:'easeTo', label:'Ease To'}, {value:'flyTo', label:'Fly To'}],
                }
            },


        };

        this.mergedViewport = {};
        this.markersRendered = [];
        this.updateRequired = false;

        this.markerFeatures = new Map();
        this.lineFeatures = new Map();
        //this.markerFeaturesIndex = [];
        this.addToParameters( commonParameters );
        this.addToParameters( mapParameters );

    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }
    
    init(){
        super.init();

        this.set('maxTileCacheSize', 15);
        this.set('viewportUpdateCallback', this.viewportUpdate.bind(this) );
        this.set('markerRenderedUpdated', this.markerRenderedUpdated.bind(this) );
        this.set('mapLoadedCallback', this.mapLoadedCallback.bind(this) );
        this.set('markerClickedCallback', this.markerClickedCallback.bind(this) );
        this.set('positionClickedCallback', this.positionClickedCallback.bind(this) );

        this.mergedViewport = Object.assign( {} , this.get('viewport') );
        this.set('mergedViewport', this.mergedViewport );
        this.set( 'queryMarkersRendered', false );
        this.set('renderedMarkers', null );
        
        this.setMarkerFeatures();
        this.set('triggerMarkerFeatures', false );

        this.setLineFeatures();
        this.set('triggerLineFeatures', false );

        this._updateIfNecessary = this.updateIfNecessary.bind(this);
        requestAnimationFrame( this._updateIfNecessary );

    }
    

    viewportUpdate( viewport ){

        this.mergedViewport = Object.assign( {}, 
            { 
                latitude : viewport.latitude,
                longitude : viewport.longitude,
                zoom : viewport.zoom,
                bearing : viewport.bearing,
                pitch : viewport.pitch
            } 
        );

        this.set('mergedViewport', this.mergedViewport );

    }

    markerRenderedUpdated( markers ){
        this.set('renderedMarkers', markers );
        this.markersRendered = markers;

        this.emit( EVENT_CALLBACK , { name : "markersRendered", value: this.markersRendered.join(',') } );
    }

    markerClickedCallback( markerId ){
        this.emit( EVENT_CALLBACK , { name : "markerClicked", value: markerId } );
    }

    positionClickedCallback( point ) {
        this.emit( EVENT_CALLBACK , { name : "positionClicked", value: point } );
    }

    get( prop, ignoreMerged = false ){
        
        let isViewportProp = ( this.parameters[prop] && this.parameters[prop].partial === "viewport" ) ? true : false;

        if( ignoreMerged === false &&  isViewportProp && this.mergedViewport && this.mergedViewport[prop] !== undefined ){
            return this.mergedViewport[prop];
        }

        return super.get( prop );
    }
    
    set(prop, value, partial = false) {

        super.set( prop , value , partial );
        
        let isViewportProp = ( this.parameters[prop] && this.parameters[prop].partial === "viewport" ) ? true : false;

        if( isViewportProp ){
            let update = {};
            update[prop] = this.get(prop, true);

            this.mergedViewport = Object.assign( {}, this.mergedViewport, update );
            this.set('mergedViewport', this.mergedViewport );

            this.emit(STATE_CHANGE, this.render());
        }

    }
    
    updateFeature( child, feature ){
        if( !child || !child.id )
            return;

        if( child.cmpType == "MapboxMarker" && this.markerFeatures.has( child.id ) ){
            this.markerFeatures.set( child.id, feature );
        }
        else if( child.cmpType == "LinePath"  ){
            this.lineFeatures.set( child.id, feature );
        }
    }

    addChild( child , section=null , pos = null ) {
        
        if( !child ) return;

        if (typeof child === 'string') {
            if( !this.checkAssetManager() ) return;
            child = this.assetManager.get(child, this.ctx);

            if( !child || ( Array.isArray(child) && child.length == 0 ) )
                return;
        }

        let event = child.cmpType == "MapboxMarker" ? MARKER_FEATURE_UPDATE : LINE_FEATURE_UPDATE;
        child.addListener(event, this._onMarkerFeatureUpdated );

        if( child && child.get('outOfCluster') === false ){
            if( child.cmpType == "MapboxMarker" ){
                this.markerFeatures.set( child.id, child.formatAsFeature() );
                child.triggerMarkerFeatureUpdate();
            }
            else if( child.cmpType == "LinePath" ){
                this.lineFeatures.set( child.id, child.formatAsFeature() );
                child.triggerLineFeatureUpdate();
            }
        }
        else if( child && child.get('outOfCluster') === true ){
            super.addChild( child , section , pos );
        }
        
    }

    removeAllChildren( param ) {
        if( this.children && Array.isArray(this.children) && this.children.length > 0 ){
            for( let i = this.children.length-1 ; i >= 0 ; i-- )
                this.removeChild( i );
        }

        this.markerFeatures.forEach( ( feature, id ) =>{
            if( feature !== null && feature.properties && feature.properties.key !== undefined ){
                let child = this.assetManager.get( feature.properties.key );
                if( child ){
                    child.removeListener(MARKER_FEATURE_UPDATE, this._onMarkerFeatureUpdated );
                    child.removeListener(LINE_FEATURE_UPDATE, this._onMarkerFeatureUpdated );
                }
            }
        });

        this.markerFeatures.clear();
        this.setMarkerFeatures();

        this.lineFeatures.clear();
        this.setLineFeatures();

        this.triggerFeaturesUpdate('marker');
        this.triggerFeaturesUpdate('line');
    }

    
    removeChild( child ) {
        let indexChild =  typeof child === "string" ? this.children.indexOf(child) : child ;

        let childRemoved = null;
        if( indexChild !== -1 ){
            childRemoved = super.removeChild( indexChild );
        }
        else{
            childRemoved = this.assetManager.get(child, this.ctx);
        }

        if( childRemoved && childRemoved instanceof Object ){
            childRemoved.removeListener(MARKER_FEATURE_UPDATE, this._onMarkerFeatureUpdated );
            childRemoved.removeListener(LINE_FEATURE_UPDATE, this._onMarkerFeatureUpdated );

            if( child.cmpType == "MapboxMarker" && this.markerFeatures.has( childRemoved.id ) ){
                this.markerFeatures.delete( childRemoved.id );
                this.setMarkerFeatures();
                this.triggerFeaturesUpdate('marker');
            }
            if( child.cmpType == "LinePath" && this.lineFeatures.has( childRemoved.id ) ){
                this.lineFeatures.delete( childRemoved.id );
                this.setLineFeatures();
                this.triggerFeaturesUpdate('line');
            }

        }
    }

    setMarkerFeatures(){
        let features = Array.from( this.markerFeatures.values() ).filter( ( feature ) => { if( feature !== null ) return feature; } );
        this.set('markerFeatures', features );
    }

    setLineFeatures(){
        let features = Array.from( this.lineFeatures.values() ).filter( ( feature ) => { if( feature !== null ) return feature; } );
        this.set('lineFeatures', features );
    }

    updateIfNecessary( ){
        if( this.updateRequired === true ){
            this.setMarkerFeatures();
            this.setLineFeatures();
            this.triggerFeaturesUpdate('marker');
            this.triggerFeaturesUpdate('line');
            this.updateRequired = false;
        }

        requestAnimationFrame( this._updateIfNecessary );
    }

    _onMarkerFeatureUpdated( payload ){
        
        if( payload && payload.id ){
            let child = this.assetManager.get( payload.id );

            if( child ){
                this.updateFeature( child, payload.feature );
                this.updateRequired = true; // will update at the next request animation frame
                //this.setMarkerFeatures();
                //this.triggerFeaturesUpdate('marker');
            }
        }
    }

    triggerFeaturesUpdate(type){
        let event = type === "marker" ? 'triggerMarkerFeatures' : 'triggerLineFeatures'
        this.set(event, true );
        setTimeout( () => {
            this.set( event, false );
            this.emit(STATE_CHANGE, this.render());
        }, 100);
        this.emit(STATE_CHANGE, this.render());
    }

    queryMarkersRendered(){

        this.set('queryMarkersRendered', true );

        setTimeout( () => {
            this.set( 'queryMarkersRendered', false );
            this.emit(STATE_CHANGE, this.render());
        }, 100);
        
        this.emit(STATE_CHANGE, this.render());

    }


    mapLoadedCallback( evt ){
        this.emit( EVENT_CALLBACK , { name : "loaded", value: this.id } );
    }

        
}

export default UiMapbox;