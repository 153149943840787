var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "textinput-ctn color-field" }, [
    _c("input", {
      directives: [
        { name: "model", rawName: "v-model", value: _vm.hex, expression: "hex" }
      ],
      staticClass: "value uint",
      attrs: { disabled: _vm.field.disabled },
      domProps: { value: _vm.hex },
      on: {
        keyup: function($event) {
          return _vm.valueUpdatedFromInput()
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.hex = $event.target.value
        }
      }
    }),
    _c(
      "div",
      {
        staticClass: "color-square",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.togglePicker(_vm.field)
          }
        }
      },
      [
        _c("div", {
          staticClass: "color",
          style: { "background-color": _vm.visuHex, opacity: _vm.currentAlpha }
        })
      ]
    ),
    _vm.pickerOpen
      ? _c(
          "div",
          { staticClass: "color-picker", class: { open: _vm.pickerOpen } },
          [
            _c("chrome-picker", {
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }