import ActionAbstract from '../../ActionAbstract';

class ToggleMenu extends ActionAbstract {

    /**
     * @param status : (optional) new status (true/false) of the menu
    **/

    static get ID() {
        return 'actions:editor:menu:toggleMenu';
    }

    run(state) {
        
        let status = this.getParam('status');
        
        if( status === undefined ) 
            status = !state.editor.menu.open;
    
        return this.composeState(state, {
            editor: {
                menu : {
                    open: status
                }
            } 
        });
    }


}

export default ToggleMenu;