<!-- Number vue -->

<template lang="pug">

span.textinput-ctn
	input.value.vector3D.x(type="text", v-model="value.x", v-on:focusout="updateX()", v-bind:placeholder="placeholderX", :disabled="field.disabled")
	input.value.vector3D.y(type="text", v-model="value.y", v-on:focusout="updateY()", v-bind:placeholder="placeholderY", :disabled="field.disabled")
	input.value.vector3D.z(type="text", v-model="value.z", v-on:focusout="updateZ()", v-bind:placeholder="placeholderZ", :disabled="field.disabled")
	
</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue', 'dynamicTypeFieldCondition' ],

	data() {
		return {
			value: {
				x: null,
				y: null,
				z: null
			},
			placeholderX: null,
			placeholderY: null,
			placeholderZ: null
		}
	},

	created: function(){
		this.value = this.fieldvalue && this.fieldvalue.value != undefined ? this.fieldvalue.value : {x:null, y:null, z:null};

		if( this.placeholder && this.placeholder.x )
			this.placeholderX = this.placeholder.x;

		if( this.placeholder && this.placeholder.y )
			this.placeholderY = this.placeholder.y;

		if( this.placeholder && this.placeholder.z )
			this.placeholderZ = this.placeholder.z;
	},

	watch: {
		fieldvalue: function (obj) {
			this.value = obj && obj.value != undefined ? obj.value : {x:null, y:null, z:null};
		},
	},

	methods:{

		// checkDynTypeConditionChange: function(val) {
		// 	let dynConditionTypeField = this.field.dynamicType.field

		// },

		checkNumberValue: function(val){
			let floatVal = parseFloat(val);
			if( val === "" || floatVal === NaN || floatVal === 'NaN' || val === null)
				val = null;
			else
				val = floatVal;
			return val;
		},

		updateX: function(){
			this.value.x = this.checkNumberValue( this.value.x );
			if (this.fieldvalue.x !== this.value.x)	{
				this.valueUpdated()
			}			
		},

		updateY: function(){
			this.value.y = this.checkNumberValue( this.value.y );
			if (this.fieldvalue.y !== this.value.y)	{
				this.valueUpdated()
			}			
		},

		updateZ: function(){
			this.value.z = this.checkNumberValue( this.value.z );
			if (this.fieldvalue.z !== this.value.z)	{
				this.valueUpdated()
			}			
		},

		valueUpdated: function(){
			this.$parent.valueUpdated( this.field, this.value );
		}


	}

}

</script>

<style lang="stylus">

input.value.vector3D
	width: 16% !important
	
	&.x , &.y 
		margin-left: 2%
</style>