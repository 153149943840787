var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-editor" }, [
    _c("div", {
      staticClass: "cover",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.clickDrop()
        }
      }
    }),
    _c("div", { staticClass: "ratio-btns" }, [
      _c(
        "div",
        {
          staticClass: "btn",
          class: { active: _vm.ratio == "desktop" },
          on: {
            click: function($event) {
              _vm.ratio = "desktop"
            }
          }
        },
        [_vm._v("desktop")]
      ),
      _c(
        "div",
        {
          staticClass: "btn",
          class: { active: _vm.ratio == "tablet" },
          on: {
            click: function($event) {
              _vm.ratio = "tablet"
            }
          }
        },
        [_vm._v("tablet")]
      ),
      _c(
        "div",
        {
          staticClass: "btn",
          class: { active: _vm.ratio == "mobile" },
          on: {
            click: function($event) {
              _vm.ratio = "mobile"
            }
          }
        },
        [_vm._v("mobile")]
      ),
      _c(
        "div",
        {
          staticClass: "btn",
          class: { active: _vm.ratio == "custom" },
          on: {
            click: function($event) {
              _vm.ratio = "custom"
            }
          }
        },
        [_vm._v("custom")]
      )
    ]),
    _vm.ratio == "custom"
      ? _c(
          "div",
          { staticClass: "ratio-btns", staticStyle: { top: "130px" } },
          [
            _c("div", { staticClass: "btnSize" }, [
              _c("div", { staticClass: "btn" }, [
                _c("span", [_vm._v("width")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.screens.custom.width,
                      expression: "screens.custom.width"
                    }
                  ],
                  domProps: { value: _vm.screens.custom.width },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.screens.custom, "width", $event.target.value)
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "btn" }, [
                _c("span", [_vm._v("height")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.screens.custom.height,
                      expression: "screens.custom.height"
                    }
                  ],
                  domProps: { value: _vm.screens.custom.height },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.screens.custom,
                        "height",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ])
          ]
        )
      : _vm._e(),
    _c("div", {
      staticClass: "ui-ctn",
      style: {
        transform:
          "translate(-50%, -50%) scale(" +
          _vm.scale.x +
          "," +
          _vm.scale.y +
          ")",
        width: _vm.screens[_vm.ratio].width + "px",
        height: _vm.screens[_vm.ratio].height + "px"
      },
      attrs: { id: "react-renderer-" + _vm.screenId }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }