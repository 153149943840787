import { default as helperBlock } from './block';

const config = {

    getConfigByType: function (config, type, format) {
        type = type === "uiobject" ? "ui" : type;
        let key = (format ? format : '') + "_" + type;
        return config.find( (inspector) => {
            return inspector.value.format + "_" + inspector.value.type === key || (format === undefined && ("_" + inspector.value.type) === key);
        });
    },

    isUiToConvert: function( type ){
        let uiToConvert = ['ui-map', 'ui-map-marker', 'ui-map-line-path', 'ui-map-layer', 'ui-map-polygon'];
        if( uiToConvert.indexOf( type ) !== -1 )
            return true;
        return false;
    },

    mapConfigConvertion: function( type, mapLib ){
        
        if( type === "ui-marker" ) 
            type = "ui-map-marker";

        if( this.isUiToConvert(type ) === true ){
            let newType = type.replace( 'ui-map', mapLib );
            type = newType;
        }
        return type;
    },

    getMergedInspector: function( state, block, mapLib ){
        let blockConfig = this.getConfigByType( state.config, block.value.type, block.value.format );

        let inspector = Object.assign( {}, blockConfig.value.inspector );
        inspector.children = [];

        blockConfig.value.inspector.children.forEach( (topic) => { inspector.children.push( topic ) });

        /* /!\ deprecated: /!\*/
        if (block.value.includedInspector) {
            block.value.includedInspector.forEach( (insp) => {
                if (insp) {
                    insp.children.forEach( (topic) => { inspector.children.push( topic ) });            
                }
            });
        }
        
        let mergeTypes = block.value.includedBlockType === undefined ? [] : [].concat( block.value.includedBlockType );
        mergeTypes.forEach( (type) => {
            type = this.mapConfigConvertion( type, mapLib );
            let objInspector = this.getConfigByType(  state.config , 'object', type);				
            let subInspector = objInspector && objInspector.value.inspector ? objInspector.value.inspector : null;

            if( subInspector ){
                subInspector.children.forEach( (topic) => { inspector.children.push( topic ) });         
            }
        });

        let mergeIds = block.value.includedBlocks === undefined ? [] : [].concat( block.value.includedBlocks );

        mergeIds.forEach( ( id ) => {
            let subBlock = helperBlock.getEntityByIDInWholeProject( state.project, id );
            
            if( subBlock ) {
                let subFormat = this.mapConfigConvertion( subBlock.value.format, mapLib );
                let subConfig = this.getConfigByType( state.config, subBlock.value.type, subFormat );
            
                subConfig.value.inspector.children.forEach( (topic) => { inspector.children.push( topic ) });
            }
        });

        return inspector;
    },

    findByPropInInspector: function( inspector, value , prop = 'name' ){
        if( inspector[prop] && inspector[prop] == value )
            return inspector;
        if( inspector.children && inspector.children.length > 0 ){
            let result = null;
            for (var i = 0; i < inspector.children.length; i++) {
                if( inspector.children[i][prop] && inspector.children[i][prop] == value  )
                    return inspector.children[i];
                else
                    result = this.findByPropInInspector( inspector.children[i], prop, value );

                if( result )
                    return result;
            }
            return null;
        }
        return null;
    },

	findFieldInInspector: function ( inspector, fieldName ){
		if( inspector.name && inspector.name == fieldName )
			return inspector;
		if( inspector.children && inspector.children.length > 0 ){
			let result = null;
			for (var i = 0; i < inspector.children.length; i++) {
				if( inspector.children[i].name && inspector.children[i].name == fieldName  )
					return inspector.children[i];
				else
					result = this.findFieldInInspector( inspector.children[i], fieldName );

				if( result )
					return result;
			}
			return null;
		}
		return null;
	}    
}

export default config;