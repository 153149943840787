// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ico-eye-white.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/bt-trash-white.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/shared-asset.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".library-panel .list .content .add input.file {\n  visibility: hidden;\n}\n.library-panel .list .content .add label {\n  display: block;\n  position: absolute;\n  top: 50px;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  opacity: 0;\n  z-index: 20;\n}\n.library-panel .list .content .item .visu {\n  display: block;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1;\n  background-size: cover;\n  background-position: center center;\n}\n.library-panel .list .content .item .actions .view {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.library-panel .list .content .item .actions .delete {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.library-panel .list .content .item .shared {\n  display: inline-block;\n  position: absolute;\n  top: 0;\n  right: 10px;\n  z-index: 1;\n  width: 20%;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n}\n", ""]);
// Exports
module.exports = exports;
