import React from 'react';
import AdvancedUi from './AdvancedUi';

export default class CommonText extends AdvancedUi {

    constructor(){
        super();

    }


    setDefaultStyles( nextProps, nextState ){

        super.setDefaultStyles( nextProps, nextState );

        let styles = {};

        delete this.defaultStyle.fontSize;
        if( nextProps.fontSize )
            styles.fontSize = nextProps.fontSize+"px";
        
        delete this.defaultStyle.lineHeight;
        if( nextProps.lineHeight )
            styles.lineHeight = nextProps.lineHeight+"px";

        delete this.defaultStyle.textDecoration;
        if( nextProps.underline === true )
            styles.textDecoration = "underline";
        
        delete this.defaultStyle.fontStyle;
        if( nextProps.italic === true )
            styles.fontStyle = "italic";

        if( nextProps.bold === true )
            styles.fontWeight = "bolder";
        else
            styles.fontWeight = "lighter";

        styles.textTransform = "none";
        if( nextProps.uppercase === true )
            styles.textTransform = "uppercase";
        else
            styles.textTransform = "none";
        
        this.defaultStyle = Object.assign( {}, this.defaultStyle, styles );
            
    }

}