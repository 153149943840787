var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.appState.project
      ? _c(
          "div",
          {
            staticClass: "nav",
            class: { open: _vm.appState.main.navigatorOpen }
          },
          [
            _c(
              "div",
              { staticClass: "arbo" },
              [
                _c("item", {
                  attrs: {
                    node: _vm.appState.project,
                    "app-state": _vm.appState
                  }
                }),
                _c("div", { staticClass: "spacer" }),
                _c("div", { staticClass: "clear" })
              ],
              1
            ),
            _c("div", { staticClass: "clear" })
          ]
        )
      : _vm._e(),
    _c("div", {
      staticClass: "bt-nav",
      class: { open: _vm.appState.main.navigatorOpen },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.toggleNavigator()
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }