/**
 * Set the save process root target, i.e. the working space (e.g. 'project' or 'template')
 * If the specified target is not an proper space name, the node identifier will be used to search for the associated workspace.
 * 
 * @param {string} target - the target workspace name
 * @param {string} id - the target workspace ID
 */

import ActionAbstract from '../ActionAbstract'
import * as helper from '../../helper'

const WS_PROJECT  = 'project'
const WS_TEMPLATE = 'template'


class Update extends ActionAbstract {

    static get ID() {
        return 'actions:workspace:update'
    }
    run(state) {

        let id = this.getParam('id')
        let target = this.getParam('target')

        // resolve target by searching for the associated workspace
        if (target != WS_TEMPLATE && target != WS_PROJECT) {
            target = WS_PROJECT // set default to 'project'
 
            if (target != 'experience') {
                // in project templates
                let template = helper.block.getTemplateByEntity(state.project, id)
                if (template) {
                    target = WS_TEMPLATE
                    id = template.value._id
                }
            }
        }

        // refers to the right ID (some navigation items could send their own identifier)
        if (target == WS_PROJECT) id = state.project.value._id

        const targetTemplate = helper.block.getTemplateByEntity(state.project, id);
        //const projectName = helper.block.getEntityByIDInWholeProject(state.project, id)

        const workspaceName = targetTemplate ? targetTemplate.value.name : state.project.value.name;

        return this.composeState(state, {
            workspace: {
                target: target,
                id: id,
                workspaceName: workspaceName
            }
        })
    }
}

export default Update