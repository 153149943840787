import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import Vue from 'vue';
import uuid from 'uuid/v4';

import store from 'store';

/**
 * Load one or more blocks from file or memory
 */
class PasteBlock extends ActionAbstract {

    /**
     * @param parentId - (optional) block id of parent
     * @param duplicate - (optional) whether the action is a duplication or not (default false)
     * @param importFromFile - (optional) false by default (use local storage) or use action parameters if true
     * @param nodalClipboard - if importFromFile is true, data contained in a json exported file
     * @param nodalClipboardInfo - if importFromFile is true, information contained in the json exported file
     * @param nodalClipboardMedia - if importFromFile is true, data contained in a json exported file
     *
    **/


    static get ID() {
        return 'actions:block:paste';
    }

    run(state) {

        let storage, storageMedia, storageInfo;
        let importFromFile = this.getParam("importFromFile") ? this.getParam("importFromFile") : false;
        let duplicate = this.getParam("duplicate") ? this.getParam("duplicate") : false;

        if( importFromFile === true ){
            storage = this.getParam('nodalClipboard');
            storageInfo = this.getParam('nodalClipboardInfo');
            storageMedia = this.getParam('nodalClipboardMedia');
        }
        else{
            storage = store.get('nodalClipboard');
            storageInfo = store.get('nodalClipboardInfo');
            storageMedia = store.get('nodalClipboardMedia');
        }

        if( storage ){

            let compositeState = { project: {}, editor: { selection: { items: [] } } };

            let parentId = this.getParam('parentId') || state.editor.targetId;
            let [parent, parentPath] = helper.block.getBlockById( state.project, parentId, true );

            let objParentId = this.getParam('parentId') || state.editor.targetLayoutId;
            let obj = this.deps.mainStore.ui.get(objParentId);

            // If ui object selected is not a container, find it's parent
            if( obj && ( !obj.nbSection || obj.nbSection < 1 ) ){
                let objSelected = helper.block.getBlockById( state.project, objParentId );
                if( objSelected && objSelected.custom.uiParent )
                    objParentId = objSelected.custom.uiParent;
            }

            let objParentPath, objParent;
            [objParent, objParentPath] = helper.block.getBlockById( state.project, objParentId, true );

            // @todo: find the section of the targeted object
            let objSection = 0;
            let copies = storage;

            let changeName = (block) => { block.value.name += " Copy"; block.value.slug += "-copy"; };
            let moveBlock  = (block) => { 
                if (block.value.coordinate) {
                    block.value.coordinate.x += 20;
                    block.value.coordinate.y += 20; 
                }
            };
            let removeRev = (block) => {
                if (block.value._rev) delete block.value._rev;
                if (block.custom.children) block.custom.children.forEach(child => removeRev(child));
                if (block.custom.objects) block.custom.objects.forEach(object => removeRev(object));
            };
            let explore = ( block ) => {
                changeName( block );
                block.custom.children.forEach( (child) => { explore(child) } );
                //block.custom.objects.forEach( (child) => { explore(child) } );
            };

            // import media unknown in this project
            let mediaIds = Object.keys( storageMedia );

            let importedMedia = [];

            mediaIds.forEach( ( id ) => {
                let localMedia = this.deps.mainStore.lib.getLoadedMediaByID( id );
                if( localMedia === null ){
                    // media not found, need to import it
                    let newId = uuid();
                    let newMedia = Object.assign( {}, storageMedia[id] );
                    newMedia._id = newId;

                    importedMedia.push( { id : id , new : newId });
                    this.deps.mainStore.lib.addMedia( newMedia );
                }
            })

            let formatedCopies = [];
            let formatedObjects = []; // UI Objects
            let formatedMemories = [];
            
            copies.forEach( (copyUnparsed) => {

                // search for media imported with new ids
                importedMedia.forEach( ( newMedia ) => {
                    let regex = new RegExp(newMedia.id, "g");
                    copyUnparsed = copyUnparsed.replace(regex, newMedia.new);
                });

                let copy = JSON.parse( copyUnparsed );

                // move a bit
                if (storageInfo && storageInfo.blocks && storageInfo.blocks.indexOf(copy.value._id) !== -1) {
                    moveBlock(copy);
                }

                // for ui copy we need to update ref_block of all children by the new objParent
                if( copy.value.type === "object" ){
                    let currentRefBlock = copy.value["ref_block"];
                    let newRefBlock = objParent.value.ref_block;
                    let regexRefBlock = new RegExp(currentRefBlock, "g");
                    copyUnparsed = copyUnparsed.replace( regexRefBlock, newRefBlock );

                    copy = JSON.parse( copyUnparsed );
                }

                removeRev(copy);
                changeName( copy );

                if( copy.value.type === "memory" ){
                    formatedMemories.push( copy );
                }
                else if( copy.value.type === "object" ){
                    copy.value["ref_block"] = objParent.value.ref_block;
                    copy.custom.uiParent = objParentId;
                    formatedObjects.push(copy);
                }
                else{
                    copy.value["ref_"+copy.value.level] = parentId;

                    if( copy.value.level !== parent.value.type ) {
                        const message = `Unable to paste a '${copy.value.type}' block into a '${parent.value.type}' block. Parent has to be a '${copy.value.level}' block.`;
                        console.warn(message);
                        this.trigger('main:modal:openModal', { title: 'An error occured', text: message, continueButton: 'OK', cancelButton: '', icon: 'error' })
                    }
                    else formatedCopies.push(copy);
                }
            });


            // @todo: redefine all ID here and not in source
            // see SaveBlock.js
            if( importFromFile !== true && !duplicate )
                this.trigger('block:copy', {quiet:true}); // copy the copy of this block, to generate new ids

            if( formatedMemories.length > 0 )
                compositeState.project['.memory'] = this.arrayPush( formatedMemories );
            if( formatedCopies.length > 0 )
                compositeState.project[parentPath+'.custom.children'] = this.arrayPush( formatedCopies );
            if( formatedObjects.length > 0 ){

                let childrenField, childrenFieldIndex;
                [childrenField, childrenFieldIndex] = helper.block.getField(objParent, 'children', true);

                formatedObjects.forEach( ( obj ) => {
                    compositeState.project[objParentPath + '.value.fields[' + childrenFieldIndex + '].value'] = this.arrayPush( obj.value._id );
                    compositeState.project[objParentPath+'.custom.objects'] = this.arrayPush( obj );

                    this.deps.mainStore.ui.createUIObject(obj);
                    this.deps.mainStore.ui.addChildTo(obj.value._id, objParentId, objSection);
                    this.deps.mainStore.ui.createAndAddUIObjects(obj, objSection);
                })
            }

            if( importedMedia.length > 0 )
                this.trigger('library:fetchAssets');

            if (storageInfo) {
                // auto select new blocks
                if (storageInfo.blocks) compositeState.editor.selection.items = storageInfo.blocks;
                // notify
                if (storageInfo.names) {
                    // @todo: implement notification and remove console log
                    const action = importFromFile === true ? 'Import' : (duplicate ? 'Duplicate' : 'Paste');
                    //this.trigger('notification', { message: `${action} '${storageInfo.names.join('\', \'')}'` });
                    console.log(`${action} '${storageInfo.names.join('\', \'')}'`, storageInfo.blocks);
                }
            }

            return this.composeState(state, compositeState);
        }

    }



}

export default PasteBlock;
