import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class CreateAssociatedObject extends ActionAbstract {

    /**
     * @param pattern : block pattern to create
     * @param block : block to check  
     * @param blocId (success) : id of block to associate
     * 
    **/

    static get ID() {
        return 'actions:block:createAssociatedObject';
    }

    run(state) {
        this.create(state);

        return this.composeState(state, {
            isLoading : true
        });
    }

    runSuccess(state) {
        this.trigger('block:updateFields', {id: this.getParam('blocId') });

        return this.composeState(state, {
            isLoading : false
        });
    }

    runCancel(state){
        return this.composeState(state, {
            isLoading : false
        });
    }

    getParent( state ){

        let parentId = this.getParam('parentId') || state.editor.targetId;
        let parent = helper.block.getBlockById( state.project, parentId );

        return parent;
    }


    create(state){
        
        let pattern = helper.config.getConfigByType( state.config , this.getParam('pattern').type, this.getParam('pattern').format );

        this.deps.mainStore.objects.createObjectAssociated( pattern , this.getParam('block') ).then( function(block){
            if( block ){
                let id = block.value._id;
                this.trigger('block:createAssociatedObject', { subaction:'success', blocId : id });
            }
            else{
                this.trigger('block:createAssociatedObject', { subaction:'cancel' });
            }
        }.bind(this))
        
    }


}

export default CreateAssociatedObject;