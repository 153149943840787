import UiObject from '../UiObject';

// will be override by the UiMapOlPolygon object depending on experience MapLibrary parameter
class UiMapPolygon extends UiObject {
    constructor(id, props) {
        super(id, props, props);
        this.initParameters();
    }

    static getAuthConfig() {
        return {
            exportFormat: 'ui-map-polygon',
            label: 'Map Polygon',
            parent: 'ui-maps',
            isDisplayed: true,
            isEmbeddable: true,
            nbSection: 1,
            childrenType: 'ui',
        };
    }

    initParameters() {}
}

export default UiMapPolygon;
