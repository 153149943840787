var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wysiwyg-inspector" }, [
    _c("div", {
      staticClass: "wysiwyg-inspector-cover",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.close()
        }
      }
    }),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wysiwyg-window" }, [
      _c("div", { staticClass: "wysiwyg-centerer" }, [
        _c("div", { staticClass: "wysiwyg-ctn" }, [
          _c("div", { attrs: { id: "tinymce" } })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }