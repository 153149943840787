/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-indent */
import React from 'react';
import AdvancedUi from '../AdvancedUi';

/**
 * This module require the Cordova Plugin - CanvasCamera to works with input stream
 * https://github.com/VirtuoWorks/CanvasCameraPlugin
 */
export default class Video extends AdvancedUi {
    constructor() {
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = {
            position: 'relative',
            overflow: 'hidden',
        };

        // using Cordova
        this.isCordova = !!window.cordovaConfig && window.plugin && window.plugin.CanvasCamera;

        this.videoNode = null;
        this.canvasNode = null;
        this.currentState = null;
        this.currentSource = null;
        this.videoRef = this.videoRef.bind(this);
        this.canvasRef = this.canvasRef.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onLoadedData = this.onLoadedData.bind(this);
        this.onLoadedMetadata = this.onLoadedMetadata.bind(this);
        this.onError = this.onError.bind(this);

        // report error about missing plugin
        if (this.isCordova && !window.plugin.CanvasCamera) {
            this.onError('The Cordova CanvasCamera plugin is not installed. Please install the plugin first to use this module properly.');
        }
    }

    setStreamAsSource(stream) {
        if (!stream) return;

        // cordova
        if (this.isCordova) return;

        // Media Streams API
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            this.videoNode.srcObject = stream;
        }
        // Standard
        else if (navigator.getUserMedia) {
            this.videoNode.src = stream;
        }
        // WebKit-prefixed
        else if (navigator.webkitGetUserMedia) {
            this.videoNode.src = window.webkitURL.createObjectURL(stream);
        }
        // Mozilla-prefixed
        else if (navigator.mozGetUserMedia) {
            this.videoNode.srcObject = stream;
        }

        // stream is async, force play if already started
        if (this.currentState === 'play') this.videoNode.play();
    }

    setStreamState(state) {
        if (this.currentState !== state) {
            this.currentState = state;
        }
    }

    componentDidMount() {
        // DO NOT INTERACT WITH ELEMENT IN THE AUTHORING ENVIRONEMENT
        if (this.props.renderer.auth !== undefined) return;

        if (this.props.mode === 'file' && this.currentSource !== this.props.source__url) {
            this.currentSource = this.props.source__url;
            this.videoNode.src = this.currentSource;
        }
        if (this.props.mode === 'stream' && this.currentSource !== this.props.streamSource) {
            this.setStreamAsSource(this.props.streamSource);
            this.currentSource = this.props.streamSource;
        }
        if (this.props.mode === 'file') {
            this.videoNode.volume = this.props.volume;
        }
    }

    componentDidUpdate(prevProps) {
        // DO NOT INTERACT WITH ELEMENT IN THE AUTHORING ENVIRONEMENT
        if (this.props.renderer.auth !== undefined) return;

        // file source
        if (this.props.mode === 'file' && this.currentSource !== this.props.source__url) {
            this.currentSource = this.props.source__url;
            this.videoNode.src = this.currentSource;
            // @todo: to be set in verbose mode [ticket NS-113]
            //console.log('Video player source:', this.currentSource);
        }

        // stream source
        if (this.props.mode === 'stream' && this.currentSource !== this.props.streamSource) {
            this.setStreamAsSource(this.props.streamSource);
            this.currentSource = this.props.streamSource;
            // @todo: to be set in verbose mode [ticket NS-113]
            //console.log('Video player source:', this.currentSource);
        }

        // volume (file only)
        if (this.props.mode === 'file' && this.props.volume !== prevProps.volume) {
            this.videoNode.volume = this.props.volume;
            // @todo: to be set in verbose mode [ticket NS-113]
            //console.log('Video player volume:', this.videoNode.volume);
        }
    }

    onPlay(evt) {
        evt.preventDefault();
        if (this.props.mode === 'stream') this.setStreamState('play');
        if (this.props.onControls) this.props.onControls('play', this.state.isHover);
        this.eventHandler('played', 'Playback or streaming has started to play.');
    }

    onPause(evt) {
        evt.preventDefault();
        if (this.props.mode === 'stream') this.setStreamState('stop');
        if (this.props.onControls) this.props.onControls('pause', this.state.isHover);
        this.eventHandler('paused', 'Playback or streaming has been paused or stopped.');
    }

    onEnded(evt) {
        evt.preventDefault();
        this.eventHandler('ended', 'Playback or streaming has stopped because the end of the media was reached or because no further data is available.');
    }

    onLoadedData() {
        if (this.props.onControls) this.props.onControls('loaded', this.state.isHover);
        this.eventHandler('loaded', 'Playback position of the media has finished loading.');
    }

    onLoadedMetadata() {
        // @todo: to be set in verbose mode [ticket NS-113]
        //console.log(`Video player media duration: ${this.videoNode.duration}, dimension: ${this.videoNode.videoWidth}x${this.videoNode.videoHeight}`);
        this.eventHandler('mediaWidth', this.videoNode.videoWidth);
        this.eventHandler('mediaHeight', this.videoNode.videoHeight);
        this.eventHandler('duration', this.videoNode.duration);
    }

    onError(err) {
        // exclude missing source
        if (this.props.mode === 'file' && !this.props.source__url) return;
        if (this.props.onError) {
            this.props.onError(err);
        }
    }

    canvasRef(node) {
        this.canvasNode = node;
        if (this.props.setCanvasNode) {
            this.props.setCanvasNode(node);
        }
    }

    videoRef(node) {
        this.videoNode = node;
        if (this.props.setVideoNode) {
            this.props.setVideoNode(node);
        }
    }

    render() {
        const isCordovaStream = this.isCordova && this.props.mode === 'stream';
        const canvasStyle = {
            display: isCordovaStream ? 'block' : 'none',
            position: 'relative',
            width: '100%',
            height: '100%',
        };
        const videoStyle = {
            display: !isCordovaStream ? 'block' : 'none',
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundColor: this.mergedStyle.backgroundColor,
        };
        const isAutoplay = this.props.renderer.auth !== undefined ? false : !!this.props.autoplay;
        const displayControls = !!this.props.displayControls;
        const loop = !!this.props.loop;
        const poster = this.props.poster__url ? this.props.poster__url : null;
        const playsInline = !!this.props.streamSource;

        let mimetype = '';
        if (this.props.source__url) {
            const ext = this.props.source__url.match(/\.([0-9a-z]{1,5})$/i);
            if (ext) {
                switch (ext[1].toLowerCase()) {
                case 'ogg':
                case 'ogv':
                    mimetype = 'video/ogg';
                    break;
                case 'webm':
                    mimetype = 'video/webm';
                    break;
                case 'mov':
                case 'mp4':
                    mimetype = 'video/mp4';
                    break;
                }
            }
        }

        return this.wrapIntoHammer(this.wrapIntoAuthoring(
            <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                <canvas ref={this.canvasRef} style={canvasStyle}></canvas>
                <video
                    ref={this.videoRef}
                    style={videoStyle}
                    autoPlay={isAutoplay}
                    playsInline={playsInline}
                    controls={displayControls}
                    poster={poster}
                    loop={loop}
                    onEnded={this.onEnded}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    onError={this.onError}
                    onLoadedData={this.onLoadedData}
                    onLoadedMetadata={this.onLoadedMetadata}
                >
                    {(
                        this.props.mode === 'file' && this.props.source__url ? (
                            <source src={this.props.source__url} type={mimetype} />
                        ) : null
                    )}
                </video>
            </div>,
            0,
        ));
    }
}
