import React from 'react';
import TextField from 'material-ui/TextField';
import InputField from '../InputField';


// /**
//  * @@deprecated since version 3.2.0, use the InputText instead.
//  */
// export const DEPRECATED = true;

export default class InputTextField extends InputField {
    constructor() {
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = { display: 'block' };

        this.state = {
            placeholderVisible: true,
            value: null,
            init: false,
        };

        this.firstRender = true;
        this.valueChangeHandler = this.valueChangeHandler.bind(this);
    }

    setDefaultStyles(nextProps, nextState) {
        super.setDefaultStyles(nextProps, nextState);

        if (this.state.init === false) {
            // if( this.notEmpty(nextProps.input.defaultValue) ){
            //     this.valueChangeHandler( null, nextProps.input.defaultValue );
            // }

            this.setState({ init: true });
        }

        if (nextState.value !== nextProps.newValue && nextProps.newValue !== null) {
            this.valueChangeHandler(null, nextProps.newValue);
        }
    }


    valueChangeHandler(evt, newValue) {
        if (newValue == null || newValue === '') this.setState({ placeholderVisible: true });
        else this.setState({ placeholderVisible: false });

        this.setState({ value: newValue });

        this.eventHandler('value', newValue);
    }

    render() {
        if (!this.props.input.style) this.props.input.style = {};

        this.props.input.style.fontFamily = 'inherit';
        /*
        this.props.input.style.fontSize = 'inherit';
        this.props.input.style.lineHeight = 'inherit';
        this.props.input.style.textAlign = 'inherit';
        this.props.input.style.lineHeight = 'inherit'; */

        const inputProps = {
            ...this.props.input,
            fullWidth: true,
            onChange: this.valueChangeHandler,
        };


        inputProps.inputStyle = { ...inputProps.inputStyle, ...this.props.commonStyle };

        inputProps.hintStyle = { ...inputProps.hintStyle, ...this.props.commonStyle };
        inputProps.hintStyle.width = '100%';
        // inputProps.hintStyle.opacity = this.state.placeholderVisible ? 1 : 0;

        if (this.firstRender) {
            if (!inputProps.value && inputProps.defaultValue) {
                inputProps.value = inputProps.defaultValue;
            }
            this.name = inputProps.floatingLabelText;
            this.firstRender = false;
        } else if (this.state.value !== null) inputProps.value = this.state.value;
        delete inputProps.defaultValue;

        inputProps.hintStyle.opacity = inputProps.value ? 0 : 1;

        return this.wrapHelper(
            <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                <TextField {...inputProps} />
            </div>,
        );
    }
}
