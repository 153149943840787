import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class ClosePanel extends ActionAbstract {

    static get ID() {
        return 'actions:main:panel:close';
    }

    run(state) {
        return this.composeState(state, {
            panel: {
                open: false,
                name: null,
                type: null
            }
        });

    }

}

export default ClosePanel;