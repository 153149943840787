import ServiceManager from '../services/ServiceManager';

import ActionHandler from './ActionHandler';
import ActionManager from './ActionManager';

import serviceManager from '../services';

ServiceManager.register(ActionHandler, ['stores:MainStore', 'services:EventLogger']);
ServiceManager.register(ActionManager, ['actions:ActionHandler']);

const actionManager = serviceManager.get('actions:ActionManager');


/* ACTIONS */

import * as userAction from './user';
import * as prehomeAction from './prehome';
import * as editorAction from './editor';
import * as blockAction from './block';
import * as project from './project';
import * as projects from './projects';
import * as main from './main';
import * as inspector from './inspector';
import * as library from './library';
import * as template from './template';
import * as space from './workspace';
import * as doc from './doc';


import Dummy from './Dummy';
ActionManager.register(Dummy, ['services:DebugOutput']);

import DummyEnd from './DummyEnd';
ActionManager.register(DummyEnd, ['services:DebugOutput']);

/* END ACTIONS */

export default actionManager;