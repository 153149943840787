import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class AddSession extends ActionAbstract {

    /**
     * @param targetId - the id of the parent block
     */

    static get ID() {
        return 'actions:project:addSession';
    }

    run (state) {

        // let project;

        // project = helper.block.getBlockById(state.project, this.getParam('targetId'));

        // // this.deps.mainStore.block.createNewDoc( block ).then( (newDoc) => {
        // //     this.trigger('project:addMemory', { subaction: 'success', newDoc, targetId: this.getParam('targetId') });
        // // });

        let newDoc = this.deps.mainStore.project.createSession();

        this.trigger('inspector:loadObj', {targetId: newDoc.value._id});
        
        let compositeState = {
            project: {value: {} }
        };

        if (!state.project.sessions) {
            compositeState.project = { sessions : [newDoc] };
        } else {
            compositeState.project["sessions"] = this.arrayPush(newDoc);
        }
        
        return this.composeState(state, compositeState);

    }

}

export default AddSession;