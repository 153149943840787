import UiObject from './UiObject';

class AdvancedUiObject extends UiObject {
    constructor(id, props = [], type = 'NodalUi') {
        super(id, props, type);


    }


    initParameters(){
        super.initParameters();

        let maskStyles = this.createInspectorNode('group', 'mask', 'Mask Style' );
        maskStyles.children.push( this.createInspectorNode('group-field', 'mask-fields') );

        let layoutStyles = this.createInspectorNode('group', 'layout', 'Layout Style' );
        layoutStyles.children.push( this.createInspectorNode('group-field', 'layout-fields') );

        let hoverStyles = this.createInspectorNode('group', 'hover', 'Hover Style' );
        hoverStyles.children.push( this.createInspectorNode('group-field', 'hover-fields') );

        let transformStyles = this.createInspectorNode('group', 'transform', 'Transform' );
        transformStyles.children.push( this.createInspectorNode('group-field', 'transform-fields') );

        let advancedTopic = this.createInspectorNode( 'topic', 'advancedStyles', 'Advanced styles' );
        advancedTopic.children = [ maskStyles, layoutStyles, hoverStyles, transformStyles ];

        this.inspector.splice( this.inspector.length-1, 0 , advancedTopic );


        let advancedParameters = {

            // MASK
            applyMask: {
                type: 'Boolean',
                default: false,
                partial: 'mask',
                connection: { in: {pluggable: false, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Apply mask",
                    container: "mask-fields",
                    description: "Specifies whether an element has a mask or not"
                }
            },
            // mask image
            maskImage: {
                type: 'Asset',
                default: null,
                partial: 'mask',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Image",
                    container: "mask-fields",
                    widget:'asset',
                    assetType:'image',
                    conditions: [{ field: 'applyMask' , value:true, operator:'=='}],
                    description: "Sets the mask image for the element"
                }
            },
            maskImageSize: {
                type: 'String',
                default: 'cover',
                partial: 'mask',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Mask fit",
                    container: "mask-fields",
                    widget:'select',
                    options: [{value:'cover', label:'cover'}, {value:'contain', label:'contain'}],
                    conditions: [{ field: 'applyMask' , value:true, operator:'=='}],
                    description: "Sets the sizing method for the mask image"
                }
            },
            // mask zone
            maskZoneAnchor: {
                type: 'String',
                default: 'tl',
                partial: 'mask',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Mask anchor",
                    container: "mask-fields",
                    widget:'select',
                    options: [{value:'tl', label:'Top-left corner'}, {value:'tr', label:'Top-right corner'}, {value:'bl', label:'Bottom-left corner'}, {value:'br', label:'Bottom-right corner'}, {value:'c', label:'Center'}],
                    conditions: [{ field: 'applyMask' , value:true, operator:'=='}],
                    description: "Sets the anchoring method for the mask relative to the element"
                }
            },
            maskZoneWidth: {
                type: 'Percent',
                default: 1,
                partial: 'mask',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Mask width",
                    container: "mask-fields",
                    widget: "percent",
                    conditions: [{ field: 'applyMask' , value:true, operator:'=='}],
                    description: "Sets the horizontal masking value. The higher the value the more of the element will be visible."
                }
            },
            maskZoneHeight: {
                type: 'Percent',
                default: 1,
                partial: 'mask',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Mask height",
                    container: "mask-fields",
                    widget: "percent",
                    conditions: [{ field: 'applyMask' , value:true, operator:'=='}],
                    description: "Sets the vertical masking value. The higher the value the more of the element will be visible."
                }
            },

            // LAYOUT,
            mixBlendMode: {
                type: 'String',
                default: 'normal',
                partial: 'styles',
                connection: { in: { pluggable: true, default: false }, out: { pluggable: true, default: false } },
                auth: {
                    label: "Blend Mode",
                    widget: "select",
                    options: [{ label: 'normal', value: 'normal' }, { label: 'screen', value: 'screen' }, { label: 'multiply', value: 'multiply' }, { label: 'overlay', value: 'overlay' }, { label: 'hard-light', value: 'hard-light' }, { label: 'soft-light', value: 'soft-light' }, { label: 'difference', value: 'difference' }, { label: 'exclusion', value: 'exclusion' }, { label: 'hue', value: 'hue' }, { label: 'saturation', value: 'saturation' }, { label: 'color', value: 'color' }, { label: 'color-dodge', value: 'color-dodge' }, { label: 'color-burn', value: 'color-burn' }, { label: 'luminosity', value: 'luminosity' }, { label: 'darken', value: 'darken' }, { label: 'lighten', value: 'lighten' }],
                    container: "layout-fields",
                    description: "Specifies how an element's content should blend with its direct parent background"
                }
            },
            overflowHide: {
                type: 'Boolean',
                default: true,
                partial: 'layout',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Hide overflow",
                    container: "layout-fields",
                    description: "Specifies what should happen if content overflows an element's box"
                }
            },
            overflowY: {
                type: 'Boolean',
                default: false,
                partial: 'layout',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Vertical Scroll",
                    container: "layout-fields",
                    description: "Specifies whether the element should be scrollable vertically or not"
                }
            },
            overflowX: {
                type: 'Boolean',
                default: false,
                partial: 'layout',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Horizontal Scroll",
                    container: "layout-fields",
                    description: "Specifies whether the element should be scrollable horizontally or not"
                }
            },
            // HOVER
            hoverColor: {
                type: 'Color',
                default: null,
                partial: 'hover',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Text color",
                    container: "hover-fields",
                    description: "Specifies the text hover color"
                }
            },
            hoverBackgroundColor: {
                type: 'Color',
                default: null,
                partial: 'hover',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Background color",
                    container: "hover-fields",
                    description: "Specifies the background hover color"
                }
            },
            hoverOpacity: {
                type: 'Float',
                default: 1,
                partial: 'hover',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Opacity",
                    container: "hover-fields",
                    min: 0,
                    max: 1,
                    description: "Specifies the element's hover opacity"
                }
            },

            // Transform
            scaleX: {
                type: 'Float',
                default: null,
                partial: 'transform',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Scale X",
                    container: "transform-fields",
                    description: "scale to apply on the element on the X axis"
                }
            },
            scaleY: {
                type: 'Float',
                default: null,
                partial: 'transform',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Scale Y",
                    container: "transform-fields",
                    description: "scale to apply on the element on the Y axis"
                }
            },
            translateX: {
                type: 'DisplayUnit',
                default: null,
                partial: 'transform',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Translate X",
                    container: "transform-fields",
                    description: "translation to apply on the element on the X axis"
                }
            },
            translateY: {
                type: 'DisplayUnit',
                default: null,
                partial: 'transform',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Translate Y",
                    container: "transform-fields",
                    description: "translation to apply on the element on the Y axis"
                }
            },
            rotate: {
                type: 'Angle',
                default: null,
                partial: 'transform',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Rotate",
                    container: "transform-fields",
                    description: "rotation to apply on the element"
                }
            },
            transformOriginX: {
                type: 'DisplayUnit',
                default: "0%",
                partial: 'transform',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Transform origin X",
                    container: "transform-fields",
                    description: "position of the element's pivot on the X axis"
                }
            },
            transformOriginY: {
                type: 'DisplayUnit',
                default: "0%",
                partial: 'transform',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Transform origin Y",
                    container: "transform-fields",
                    description: "position of the element's pivot on the Y axis"
                }
            },
            transitionEase: {
                type: 'String',
                default: null,
                widget: 'select',
                partial: 'transform',
                options:  [  {label:'linear', value:'linear'},  {label:'easeInQuad', value:'easeInQuad'},  {label:'easeInCubic', value:'easeInCubic'},  {label:'easeInQuart', value:'easeInQuart'},  {label:'easeInQuint', value:'easeInQuint'},  {label:'easeInSine', value:'easeInSine'},   {label:'easeInExpo', value:'easeInExpo'},   {label:'easeInCirc', value:'easeInCirc'},    {label:'easeInBack', value:'easeInBack'},   {label:'easeOutQuad', value:'easeOutQuad'},  {label:'easeOutCubic', value:'easeOutCubic'},  {label:'easeOutQuart', value:'easeOutQuart'}, {label:'easeOutQuint', value:'easeOutQuint'},   {label:'easeOutSine', value:'easeOutSine'},   {label:'easeOutExpo', value:'easeOutExpo'},    {label:'easeOutCirc', value:'easeOutCirc'},   {label:'easeOutBack', value:'easeOutBack'},   {label:'easeInOutQuad', value:'easeInOutQuad'},  {label:'easeInOutCubic', value:'easeInOutCubic'},    {label:'easeInOutQuart', value:'easeInOutQuart'},  {label:'easeInOutQuint', value:'easeInOutQuint'},   {label:'easeInOutSine', value:'easeInOutSine'},  {label:'easeInOutExpo', value:'easeInOutExpo'},  {label:'easeInOutCirc', value:'easeInOutCirc'},    {label:'easeInOutBack', value:'easeInOutBack'} ],
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false}
                },
                auth :  {
                    label: 'Transition easing',
                    container: "transform-fields",
                    description: "type of transition to apply on the element (see https://easings.net/fr)"
                }
            },
            transitionProperty: {
                default: null,
                type: 'String',
                label: 'Property',
                widget: 'String',
                partial: 'transform',
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false  }
                },
                auth :  {
                    label: 'Transition Property',
                    container: "transform-fields",
                    description: "which property of the element should be impacted during transform changes"
                }
            },
            transitionDelay: {
                type: 'Float',
                label: 'Delay',
                widget: 'Float',
                unit : "s",
                partial: 'transform',
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false  }
                },
                auth :  {
                    label: 'Transition Delay',
                    container: "transform-fields",
                    description: "delay duration before applying tranform changes"
                }
            },
            transitionDuration: {
                type: 'Float',
                default: null,
                partial: 'transform',
                widget: 'Float',
                unit : "s",
                connection: {
                    in: {pluggable: true, default: false},
                    out: {pluggable: true, default: false  }
                },
                auth :  {
                    label: 'Transition Duration',
                    container: "transform-fields",
                    description: "duration on which to apply transform changes"
                }
            },
            willChange: {
                type: 'String',
                default: null,
                partial: 'styles',
                widget: 'String',
                connection: {
                    in: { pluggable: false, default: false, disabled:true },
                    out: {pluggable: false, default: false, disabled:true }
                },
                auth :  {
                    label: 'CSS property',
                    container: "transform-fields",
                    description: "Specifies which properties (separated by commas) will often be impacted by transform changes and thus should be taken into account by the GPU"
                }
            },

            // TRANSITIONS
            /*
            transitions :     {
                type : 'Repeated',
                connection : {
                    in: {pluggable: false, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{
                    container: 'advancedStyles',
                    label: 'Transitions',
                    widget: 'repeated-form',
                    form : {
                        ease: {
                            name: 'ease',
                            type: 'String',
                            label: 'Ease',
                            widget: 'select',
                            options:  [  {label:'linear', value:'linear'},  {label:'easeInQuad', value:'easeInQuad'},  {label:'easeInCubic', value:'easeInCubic'},  {label:'easeInQuart', value:'easeInQuart'},  {label:'easeInQuint', value:'easeInQuint'},  {label:'easeInSine', value:'easeInSine'},   {label:'easeInExpo', value:'easeInExpo'},   {label:'easeInCirc', value:'easeInCirc'},    {label:'easeInBack', value:'easeInBack'},   {label:'easeOutQuad', value:'easeOutQuad'},  {label:'easeOutCubic', value:'easeOutCubic'},  {label:'easeOutQuart', value:'easeOutQuart'}, {label:'easeOutQuint', value:'easeOutQuint'},   {label:'easeOutSine', value:'easeOutSine'},   {label:'easeOutExpo', value:'easeOutExpo'},    {label:'easeOutCirc', value:'easeOutCirc'},   {label:'easeOutBack', value:'easeOutBack'},   {label:'easeInOutQuad', value:'easeInOutQuad'},  {label:'easeInOutCubic', value:'easeInOutCubic'},    {label:'easeInOutQuart', value:'easeInOutQuart'},  {label:'easeInOutQuint', value:'easeInOutQuint'},   {label:'easeInOutSine', value:'easeInOutSine'},  {label:'easeInOutExpo', value:'easeInOutExpo'},  {label:'easeInOutCirc', value:'easeInOutCirc'},    {label:'easeInOutBack', value:'easeInOutBack'} ],
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: false, default: false, disabled:true  }
                            }
                        },
                        property: {
                            name: 'property',
                            type: 'String',
                            label: 'Property',
                            widget: 'String',
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: false, default: false, disabled:true  }
                            }
                        },
                        duration: {
                            name: 'duration',
                            type: 'Float',
                            label: 'Duration',
                            widget: 'Float',
                            unit : "s",
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: false, default: false, disabled:true  }
                            }
                        },
                        delay: {
                            name: 'delay',
                            type: 'Float',
                            label: 'Delay',
                            widget: 'Float',
                            unit : "s",
                            connection: {
                                in: {pluggable: false, default: false, disabled:true},
                                out: {pluggable: false, default: false, disabled:true  }
                            }
                        }
                    }
                }
            }
            */

        };

        this.addToParameters( advancedParameters );

    }

}

export default AdvancedUiObject;
