// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/textureDeprecated.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".accordion-bloc .navigatorType {\n  display: inline-block;\n  font-family: 'DinBold';\n  margin-right: 5px;\n  text-transform: capitalize;\n  z-index: 0;\n}\n.accordion-bloc.active {\n  color: #fff;\n}\n.accordion-bloc.deprecated {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;\n  background-size: contain;\n}\n.accordion-bloc input.invisible {\n  z-index: 5;\n}\n", ""]);
// Exports
module.exports = exports;
