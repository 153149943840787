import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper' ;

class Load extends ActionAbstract {

    /**
     * @param targetId
    */

    static get ID() {
        return 'actions:inspector:load';
    }

    run(state) {

        let targetId = this.getParam('targetId');
        let target = helper.block.getBlockById( state.project, targetId );
        
        if( target.value._deleted ) return state;

        this.trigger('block:updateFields', { id : targetId } );

        if ( state.main.inspectorOpen ) {
            if (state.inspector.targetId !== targetId) {
                this.trigger('main:toggleInspector', {open: false});
                setTimeout(() => {this.trigger('inspector:load', {targetId: this.getParam('targetId')}) }, 20);
            }
            return state;
        }

        this.trigger('main:toggleInspector', {open: true});

        return this.composeState(state, {
            inspector: {
                targetId: targetId,
                multiTargetId: [],
                queryMode : false
            }
        });
    }

    runUnload(state) {
        this.trigger('main:toggleInspector', { open : false });

        return this.composeState(state, {
            inspector: this.removeKey()
        });
    }

}

export default Load;