import ActionAbstract from '../ActionAbstract';

class Login extends ActionAbstract {

    static get ID() {
        return 'actions:Login';
    }

    run(state) {

        if (this.getParam('subaction') === undefined) {
            this.login();
            return this.composeState(state, {
                isLoading: true
            });
        } else if (this.getParam('subaction') === 'success') {
            return this.composeState(state, {
                user: this.getParam('user'),
                isLoading: false
            });
        } else if (this.getParam('subaction') === 'fail') {
            return this.composeState(state, {
                login: {
                    errorMessage: this.getParam('errorMessage'),
                },
                isLoading: false
            });
        }

    }

    login() {
        this.deps.mainStore.user.login( { email: this.getParam('email'), password: this.getParam('password') } )
                                .then(
                                    () => {
                                        this.trigger('Login', {subaction: 'success', user: 'org.couchdb.user:' + this.getParam('email')} );
                                        //this.trigger('prehome:changeTab', {tab: 'loggedin'} );
                                        //@todo Prefer auto-redirect to projects using the router-link. View Home.vue
                                        window.location="/#/projects";

                                    }
                                    ,
                                    (error) => {
                                        let errorMessage = 'An error has occured while trying to connect.';
                                        if (error.name === 'unauthorized') {
                                            errorMessage = 'Authentification failed : incorrect login or password';
                                        }
                                        this.trigger('Login', {subaction: 'fail', errorMessage } )
                                    }
                                );
    }

}

export default Login;