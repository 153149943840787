var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "dropdown" } }, [
    _c("button", { staticClass: "anchor", on: { mouseover: _vm.toggleShow } }, [
      _vm._v(_vm._s(_vm.defaultType))
    ]),
    _vm.showMenu
      ? _c(
          "div",
          { staticClass: "menu", on: { mouseleave: _vm.toggleShow } },
          _vm._l(_vm.items, function(item) {
            return _c(
              "div",
              {
                staticClass: "menu-item",
                on: {
                  click: function($event) {
                    return _vm.itemClicked(item)
                  }
                }
              },
              [_vm._v(_vm._s(item))]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }