var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "template-delete" } }, [
    _c("div", { staticClass: "section" }, [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "icon" },
          [_c("icon", { attrs: { name: "template-delete" } })],
          1
        ),
        _c("h3", { staticClass: "title" }, [_vm._v("Delete a template")]),
        _c("p", { staticClass: "message" }, [
          _c("span", { staticClass: "before-name" }, [
            _vm._v("You are about to delete the ")
          ]),
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name) + " ")]),
          _c("span", { staticClass: "after-name" }, [_vm._v("template")]),
          _c("br"),
          _vm._v(
            "Be sure that no projects use this template, all data will definitely be lost"
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "section" }, [
      _c("div", { staticClass: "action" }, [
        _c("input", {
          attrs: { value: "Cancel", type: "button" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.cancel($event)
            }
          }
        }),
        _c("input", {
          staticClass: "nofocus",
          attrs: { value: "Continue", type: "button" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.remove($event)
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }