import UiLinePath from '../../elements/UiLinePath';
import { FEATURE_UPDATE } from './UiMapOlLayer';
import { STATE_CHANGE, EVENT_CALLBACK } from '../../UiObject';

class UiMapOlLinePath extends UiLinePath {

    constructor(id, props) {
        super(id, props, 'MapOLLinePath');

        // store transform properties
        this.transform = {
            props: [], // list of properties to be animated
            values: { from: {}, to: {} }, // !!! from values will be overridden
        };

        this.points = [];
        this.initialized = false;
    }

    static getAuthConfig() {
        return {
            exportFormat: "ui-map-ol-line-path",
            label: "MapOL LinePath",
            parent: "ui-maps-ol",
            isDisplayed: true,
            canBeCreated: false,
            isEmbeddable: true,
            requireUiParentTypes: ['MapOLLayer']
        };
    }


    initParameters() {
        super.initParameters();

        let newParameters = {

            groupTag: {
                type: 'String',
                default: null,
                partial: 'marker',
                connection: { in: { pluggable: true, default: false }, out: { pluggable: true, default: false } },
                auth: {
                    label: "Group Tags",
                    description: "name of source group",
                    container: "general-fields"
                }
            }

        };

        this.addToParameters(newParameters);

        this.setParamLabel('parent', 'Layer container');

        delete this.parameters.stroke;



        // add events 
        let evtTopic = this.createInspectorNode('topic', 'events', 'Events');
        // @deprecated: onAnimationEnded is deprecated since version 3.2.0,
        // use onTransformEnded instead
        let olEvents = ["onAnimationEnded", "onTransformEnded"];
        let olEventGroup = this.createInspectorNode('group', 'olevents-group', 'LinePath Events');
        olEvents.forEach((evt) => this.addGroupFieldForEvent(olEventGroup, 'olevents', evt));
        evtTopic.hideForObjInspector = true;
        evtTopic.children = [olEventGroup];
        this.inspector.push( evtTopic );

        olEvents.forEach((evt) => {
            let subParams = [
                { name: 'trigger', label: 'Trigger', type: 'Boolean', default: false }
            ]
            let params = this.createEventParameters('olevents', evt, subParams);
            // @deprecated: onAnimationEnded is deprecated since version 3.2.0,
            // use onTransformEnded instead
            if (evt === 'onAnimationEnded') {
                params['olevents_onAnimationEnded'].auth.deprecated = true;
                params['olevents_onAnimationEnded'].auth.label = 'On Animation Ended';
                params['olevents_onAnimationEnded'].auth.description = 'Deprecated, use "On Transform Ended" instead';
                params['olevents_onAnimationEnded-trigger'].auth.description = 'Deprecated, use "On Transform Ended" instead';
                params['olevents_onAnimationEnded-trigger'].auth.deprecated = true;
            }
            if (evt === 'onTransformEnded') {
                params['olevents_onTransformEnded'].auth.label = 'On Transform Ended';
                params['olevents_onTransformEnded'].auth.description = 'Enable the transform animation event.';
                params['olevents_onTransformEnded-trigger'].auth.description = 'Triggers when the transform animation is done.';
            }
            this.addToParameters(params);
        });

    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }

    init() {
        super.init();
        this.initialized = true;

        // IMPORTANT: the parent property is always required while creating an UI object. Depending 
        // of the current schematic, the user could thing that this porperty is stored (but it's not),
        // so we force to display the error message to inform the user about the right strategy.
        if ( !this.get('parent')  ) this.addToParent(null);
    }

   onTransformEnded(id) {
       this.transform.props = []; // remove properties
       this.triggerFeatureUpdate(); // and force the transformation target values
       // @deprecated: onAnimationEnded is deprecated since version 3.2.0,
       // use onTransformEnded instead
       this.emit(EVENT_CALLBACK, { name: 'olevents_onAnimationEnded-trigger', value: true });
       this.emit(EVENT_CALLBACK, { name: 'olevents_onTransformEnded-trigger', value: true });
    }

    requestTransform(prop, value) {
        if (this.initialized == false) return false;

        let featureTransformProp = [
            'opacity', 'scale', 'fill'
        ];

        if (featureTransformProp.indexOf(prop) !== -1) {
            // if value are different set the property to be animated
            // if not, remove it from the animation list
            const raw = value && value.dataType ? value.value : value;
            const index = this.transform.props.indexOf(prop);
            if (raw !== this.get(prop)) {
                if (index === -1) this.transform.props.push(prop);
                this.transform.values.to[prop] = raw;
                return true;
            }
            if (index > -1) this.transform.props.splice(index, 1);
        }

        return false;
    }

    set(prop, value, partial = false) {

        const animate = this.requestTransform(prop, value);
        if (!animate) super.set(prop, value, partial);

        let featureUpdateProp = ['fill', 'strokeWidth', 'strokeLinejoin', 'strokeLinecap', 'closedPath', 'visibility', 'opacity', 'scale', 'useDash', 'lineDashPattern', 'lineDashOffset'];
        if (featureUpdateProp.indexOf(prop) !== -1 && this.initialized == true) { //&& this.get('outOfCluster') == false ){
            this.triggerFeatureUpdate();
        }
    }

    update(prop, value) {
        super.set(prop, value, false);
    }

    addPoint(value) {
        if (!value || value == undefined) return;

        if (value.x != null && value.y != null && value.x != undefined && value.y != undefined) {
            if (this.lastInserted == null || (this.lastInserted.x != value.x || this.lastInserted.y != value.y)) {
                this.points.push([value.y, value.x]);
                this.lastInserted = value;

                this.set('points', this.points.filter((a) => { return true; }));
                // @todo: to be set in verbose mode [ticket NS-113]
                //console.log("-> linepath add", "number of points", this.points.length, "[ UID:", this.id, "]")
            }
        }

        this.triggerFeatureUpdate();

    }

    clearPoints(values) {
        super.clearPoints(values);
        // @todo: to be set in verbose mode [ticket NS-113]
        //console.log("-> linepath clear", "number of points", this.points.length, "[ UID:", this.id, "]")
        
        //this.triggerFeatureUpdate();
        this.emit(STATE_CHANGE, this.render());
    }

    rgb2hex(rgb) {
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? "#" +
            ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
    }

    formatAsFeature() {
        let coordinates = this.points;
        if (this.get('closedPath') && this.points.length)
            coordinates = coordinates.concat([this.points[0]]);

        let fill = this.get('fill') ? this.get('fill') : "#000000";
        let pattern = this.get('useDash') && this.get('lineDashPattern') ? this.get('lineDashPattern').match(/\d+/g) : undefined;

        this.transform.easing = this.get('transformEasing');
        this.transform.property = this.get('transformProperty');
        this.transform.duration = this.get('transformDuration') ? this.get('transformDuration') * 1000 : 0;

        return {
            type: "Feature",
            properties: {
                key: this.id,
                type: 'linepath',
                outOfCluster: true,

                fill: fill,
                opacity: this.get('opacity') ? this.get('opacity') : this.getParameter('opacity').default,
                scale: this.get('scale') ? this.get('scale') : 1,

                visible: this.get('visibility') ? this.get('visibility') : 1,
                width: this.get('strokeWidth'),
                cap: this.get('strokeLinecap'),
                join: this.get('strokeLinejoin'),
                groupTag: this.get('groupTag'),
                dashPattern: pattern,
                dashOffset: this.get('lineDashOffset'),
                transform: this.transform,
            },
            geometry: {
                type: "LineString",
                coordinates: coordinates
            }
        };
    }

    triggerFeatureUpdate() {
        this.emit(FEATURE_UPDATE, { id: this.id, feature: this.get('visibility') !== false ? this.formatAsFeature() : null, visible: this.get('visibility'), update: true });
    }

}

export default UiMapOlLinePath;