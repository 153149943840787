import UiObject from '../UiObject';

import UiMapbox from './mapbox/UiMapbox';
import UiMapOl from './map-ol/UiMapOl';

// will be override by an UiMapbox or UiMapOl object depending on experience MapLibrary parameter

class UiMap extends UiObject {

    constructor(id, props) {
        super(id, props, null , false );
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-map",
            label: "Map",
            parent : "ui-maps",
            isDisplayed : true,
            isEmbeddable: true,
            nbSection: 1,
            childrenType: 'ui-map-ol-layer'
        };
    }

        
}

export default UiMap;