import ActionManager from '../ActionManager';

import Open from './Open';
ActionManager.register(Open, ['stores:MainStore']);

import FetchAssets from './FetchAssets';
ActionManager.register(FetchAssets, ['stores:MainStore']);

import DeleteMedia from './DeleteMedia';
ActionManager.register(DeleteMedia, ['stores:MainStore']);

import RenameMedia from './RenameMedia';
ActionManager.register(RenameMedia, ['stores:MainStore']);

import AddMedia from './AddMedia';
ActionManager.register(AddMedia, ['stores:MainStore']);

import ReplaceFile from './ReplaceFile';
ActionManager.register(ReplaceFile, ['stores:MainStore']);

import MediaPreview from './MediaPreview';
ActionManager.register(MediaPreview, ['stores:MainStore']);

import Tag from './Tag';
ActionManager.register(Tag, ['stores:MainStore']);

import AssetFieldLoad from './AssetFieldLoad';
ActionManager.register(AssetFieldLoad, ['stores:MainStore']);

import SelectAssetForField from './SelectAssetForField';
ActionManager.register(SelectAssetForField, ['stores:MainStore']);