<!-- Bloc vue -->
<template lang="pug">
div.postit(v-bind:class="{'open':this.isOpen, 'close': !this.isOpen}")
	div.clip(v-on:mouseup="togglePostIt")
		icon(:name="'note'")
	div.postit-value
		div.ghost-txt {{value}}
		textarea(v-model="value")

	div.bottom-menu-postit
		div.action-bts-postit
			div.action-bt-postit.bt-delete-postit(v-on:click.stop="deleteBloc")

</template>

<script>
// STORE
import { stores } from '../../../../stores/MainStore.js';
import actionManager from '../../../../actions';

import Icon from  '../../../common/Icon.vue';

export default {

	props: ['bloc', 'dragging'],

	components: { Icon },

	data() {
		return {
			value: '',
			isOpen: false,
		}
	},

	created: function(){

		if( !this.bloc.value.fields )
			this.bloc.value.fields = [];

		let field = stores.block.getField(this.bloc, "value" );

		if( field )
			this.value = field.value;

	},

	watch: {
		'value': function(val, oldVal ){
			let data = stores.block.getField( this.bloc, "value" );
			actionManager.trigger('inspector:updateValue', { targetId: this.bloc.value._id, fieldName: "value", newValue: val } );
			/*if( !data )
				this.bloc.value.fields.push( { name: 'value', value : val } );
			else
				data.value = val;*/
		}
	},

	methods:{

		deleteBloc: function(){
			actionManager.trigger('block:deleteBlock', {id : this.bloc.value._id} );
		},

		togglePostIt: function(){
				if(!this.dragging && this.isOpen) {
					this.isOpen = false
				} else if(!this.dragging && !this.isOpen){
					 this.isOpen = true
				} else if(this.dragging && !this.isOpen){
					this.isOpen = false
				} else if(this.dragging && this.isOpen){
					this.isOpen = true
				}
		}
	}
}

</script>

<style lang="stylus">
.postit
	width: auto
	background-color: transparent
	border: none
	&.close
		&:hover
			padding-right: 25px
			padding-bottom: 19px
			.bottom-menu-postit
				display: block
				position: absolute
				opacity: 1
				z-index: 10
				bottom: 7px
				right: 18px
				.action-bts-postit
					display:inline-block
					opacity: 1
					.action-bt-postit
						opacity: 1
	&.open
		border: none
		.clip
			background-color: #F39A74
		.postit-value
			transform: scale(1)
			opacity: 1
			overflow: scroll
			max-height: 200px
		.bottom-menu-postit
			display: block
			opacity: 1
			z-index: 5
			bottom: -10px
			right: -158px
			.action-bts-postit
				opacity: 1
				.action-bt-postit
					opacity: 1

.clip
		width: 40px
		height: 40px
		border-radius: 40px
		background-color: var(--moduleTitleColor)
		box-shadow: 0 0 10px var(--shadowColor)
		position: relative
		z-index: 10
		&:hover
			cursor: pointer
		.icon
			width: 25px
			height: 25px
			background-color: white
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)

.postit-value
		position: absolute
		top: 20px
		left 20px
		width: 150px
		padding: 10px 15px
		border-radius: 8px
		border: none
		background-color: white
		min-height: 60px
		z-index: 5
		overflow:hidden
		box-shadow: 0 0 10px var(--shadowColor)

		font-size: 12px
		font-family 'DinRegular', 'ComfortaaLight', Helvetica

		transition: all 0.3s ease-in-out
		transform-origin: 0px 0px
		transform: scale(0)
		opacity: 0

		.ghost-txt
			opacity: 0
			word-wrap: break-word
			padding-bottom: 10px
			padding-right: 15px

		textarea
			padding: 5px
			position: absolute
			width: calc(100% - 45px)
			height: 100%
			overflow:hidden
			top: 25px
			left: 10%
			outline: none
			resize: none
			background-color: transparent
			border: none
			font-size: 14px
			margin-bottom: 15px
			font-family 'DinRegular'
			color: var(--moduleTitleColor)

.bottom-menu-postit
	display: block
	width: auto
	opacity: 0
	position: absolute
	bottom: 22px
	right: 31px
	display: none
	background-color: var(--bgColor)
	border-radius: 20px
	box-shadow: 0px 0px 10px var(--shadowColor)
	transition: linear right 0.18s !important

	.action-bts-postit
		display:inline-block
		opacity: 0
		vertical-align: middle
		background-color: var(--breadColor)
		border-radius: 20px
		width: 20px
		height: 20px
		margin: 3px
		transition: linear all .1s !important
		.action-bt-postit
			width: 100%
			height: 100%
			opacity: 0
			cursor: pointer
			background: var(--breadColor)!important
			background-image: url(../../../../assets/images/icons/close.svg) !important
			background-size: 10px !important
			background-position: center !important
			background-repeat: no-repeat !important
			background-color: white
			border-radius: 40px
			transition: linear all .1s !important
			&:hover
				background: var(--selectedColor)!important
				background-image: url(../../../../assets/images/icons/close.svg) !important
				background-size: 15px !important
				background-position: center !important
				background-repeat: no-repeat !important
				border-radius: 40px
				transition: linear all .1s !important

</style>
