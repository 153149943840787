var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dragged-item",
      style: {
        transform:
          "translate(" +
          this.appState.editor.menu.dragPos.x +
          "px , " +
          this.appState.editor.menu.dragPos.y +
          "px)"
      }
    },
    [
      _c("div", { staticClass: "zone" }),
      _vm.appState.editor.menu.dragPattern
        ? _c("div", { staticClass: "visu" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }