import UiObject from '../UiObject';

// will be override by an UiMapboxLayer or UiMapOlLayer object depending on experience MapLibrary parameter

class UiMapLayer extends UiObject {

    constructor(id, props) {
        super(id, props, props);

        this.initParameters();
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-map-layer",
            label: "Map Layer",
            parent : "ui-maps",
            isDisplayed : true,
            isEmbeddable: true,
            nbSection: 1,
            childrenType: 'ui'
        };
    }

    
    initParameters(){

    }
}

export default UiMapLayer;