import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import store from 'store';

class OpenDoc extends ActionAbstract {

    /**
     * @param type : type of blocks to documents
    **/

    static get ID() {
        return 'actions:doc:openDoc';
    }

    run(state) {

        this.trigger('editor:menu:filter', { type: this.getParam("type") , ignoreIsEmbeddable : true , search : '' });
        
        return this.composeState(state, {
            config: this.deps.mainStore.config.authoring
        });

    }


}

export default OpenDoc;