
<template lang="pug">

div
	div(v-if="subInspector")
		sub-topic(:project="project" , :target="target", :bloc="subInspector" )

	//div.form-content(v-bind:class="{'open':topicAccordion}")
		div.children
			div.form-accordion.array-row-fields.group.isAccordion
				div.title(v-on:click.stop="accordion = !accordion")
					span.text State {{bloc.side}}
					span.arrow(v-bind:class="{'close':!accordion}")

				div.form-accordion-content(v-bind:class="{'open':accordion}")
					div.big-bt.field-group

						div.field-ctn.state(v-for="state in states")

							div.field-wrapper
								span.text {{state}}

								div.input-fields

									calculated-field

									span
										// INPUT
										span.plug.checkbox()
											input(type="checkbox", v-model="connectionTarget.in")

										// OUTPUT
										span.plug.checkbox.disabled()
											input(type="checkbox", v-model="connectionTarget.out")

									div.clear

							div.clear

						div.clear

</template>

<script>

import * as helper from '../../../helper';
import actionManager from '../../../actions';

import SubTopic from "../blocs/Topic.vue";

export default {

	name: 'state-trigger',

	props:[ 'project', 'target', 'bloc' ],

	components : { SubTopic },


	data() {
		return {
			screenId : null,
			topicAccordion: true,
			accordion: true,
			prevWidgetTargetId: null
		}
	},

	computed: {


		'widgetTargetId': function(){

			let f = helper.block.getField(this.target, this.bloc.target);
			if (f) {
				let uiId = f.value;

				return uiId;
			} else {
				return null;
			}

		},

		'widgetTarget': function(){

			let block = null;

			if( this.widgetTargetId != null )
				block = helper.block.getBlockById( this.project , this.widgetTargetId );

			return block;
		},

		'states' : function(){
			let target = this.widgetTarget;
			if( target && target.value.states && target.value.states[ this.bloc.side ] )
				return target.value.states[ this.bloc.side ];
			return null;
		},

		'subInspector': function(){
			let fields = []
			if( this.states ){
				this.states.forEach( (state) => {
					fields.push( {
                      "type": "field",
                      "name": state,
                      "widget": "calculated",
                      "coreFormat": "Mixed",
                      "default": null,
                      "label": state,
                      "connection": {
                        "in": {
                          "pluggable": true,
                          "default": true,
                          "disabled": true
                        },
                        "out": {
                          "pluggable": false,
                          "default": false,
                          "disabled": true
                        },
                        "activate": {
                          "pluggable": true,
                          "default": true,
                          "force": true
                        }
                      }
                    });
				});
			}

			let inspector = this.includeInTopic( this.bloc.title, fields );

			if( this.prevWidgetTargetId != this.widgetTargetId ){
				actionManager.trigger('block:updateIncludedBlocks', { id : this.target.value._id, includedBlocks : null, includedInspector : [ inspector ] });
				this.prevWidgetTargetId = this.widgetTargetId;
			}

			return inspector;
		}

	},

	watch: {

	},

	created: function(){
		let currentVal = helper.block.getField(this.target, this.bloc.target).value;
		this.prevWidgetTargetId = null;

		// Computed property "widgetTargetId" was assigned to but it has no setter (since migration Vue v1 to v2)
		// Does this make sens to assigned this property ?
		//this.widgetTargetId = currentVal ? currentVal : null;

		//this.subInspector = this.target.value.includedInspector ? this.target.value.includedInspector : null;
	},

	destroyed: function(){

	},

	methods:{


		includeInTopic: function( topicName , fields ){
			return {
				"type": "topic",
				"name": "topic1",
				"title": topicName,
				"isAccordion": true,
				"children": [
					{
						"type": "group",
						"name": "group1",
						"title": "Events",
						"isAccordion": true,
						"children": [
							{
								"type": "group-field",
								"name": "group-field1",
								"children": fields
							}
						]
					}
				]
			};
		}



	},

}

</script>

<style lang="stylus">


</style>
