var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "content" } }, [
      _c("h1", [_vm._v("Memories ")]),
      _c("p", { attrs: { id: "chapo" } }, [
        _vm._v(
          "The memories in Nodal.Studio concern the data used by the triggers or shared between experiences, screens or modules. It can be compared to variables used all over the project."
        )
      ]),
      _c("p", [_vm._v("The memories have to be given different properties.")]),
      _c("p", [
        _vm._v(
          "Each time a user add a new memory, he has to set a type first :"
        ),
        _c("ul", { staticClass: "listing" }, [
          _c("li", [_vm._v("Angle")]),
          _c("li", [
            _vm._v("Array (a specific interface allows to parameter the array)")
          ]),
          _c("li", [_vm._v("Asset (chosen via the library)")]),
          _c("li", [_vm._v("Boolean")]),
          _c("li", [_vm._v("Color")]),
          _c("li", [_vm._v("Float")]),
          _c("li", [_vm._v("Integer")]),
          _c("li", [_vm._v("Percent")]),
          _c("li", [_vm._v("String")]),
          _c("li", [_vm._v("Vector")])
        ])
      ]),
      _c("h2", [_vm._v("Global settings")]),
      _c("h3", [_vm._v("Name")]),
      _c("p", [
        _vm._v(
          "The name given to a memory is a String that will be used to reference the memory everywhere in the project."
        )
      ]),
      _c("h3", [_vm._v("Shared")]),
      _c("p", [
        _vm._v(
          "When the Share option of the memory is activated, it will be shared between all the client connected to the same project. If a client changed the value, all the others will be updated with this new value."
        )
      ]),
      _c("h3", [_vm._v("Persistent")]),
      _c("p", [
        _vm._v(
          "When the Persistent option of the memory is activated, it stores the last updated value in the database, even if a default value was set."
        )
      ]),
      _c("h2", [_vm._v("Value")]),
      _c("p", [
        _vm._v("When a memory is created, a default value can be given."),
        _c("br"),
        _vm._v(
          " If the Persistent option of the memory is activated, it will overwrite this value every time it is updated."
        )
      ]),
      _c("p", [_c("br"), _c("br"), _c("br")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }