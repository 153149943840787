var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "json-panel" }, [
    _c("div", { staticClass: "criteria-bar" }, [
      _c(
        "div",
        {
          staticClass: "containers compact",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.format()
            }
          }
        },
        [_c("icon", { staticClass: "icons", attrs: { name: "jsonFormat" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "containers format",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.compact()
            }
          }
        },
        [_c("icon", { staticClass: "icons", attrs: { name: "jsonCompact" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "containers download",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.download()
            }
          }
        },
        [
          _vm._v("Download"),
          _c(
            "div",
            { staticClass: "circle" },
            [_c("icon", { staticClass: "icons", attrs: { name: "download" } })],
            1
          )
        ]
      )
    ]),
    _c(
      "div",
      {
        staticClass: "json-editor-container",
        on: {
          focusout: function($event) {
            return _vm.focusOut()
          }
        }
      },
      [
        _c("json-editor-vue", {
          staticClass: "editor-body",
          attrs: {
            mode: "text",
            indentation: "\t",
            tabSize: "4",
            mainMenuBar: false,
            statusBar: false
          },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        })
      ],
      1
    ),
    _vm.state.from === "LIBRARY"
      ? _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            {
              staticClass: "button enabled",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.save()
                }
              }
            },
            [_vm._v("Save Changes")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }