<!-- Memory vue -->

<template lang="pug">
div.accordion.memory-bloc
	div.accordion-title(v-on:click.stop="accordion = !accordion ")
		span.text Sessions
		span.arrow(v-bind:class="{'close':!accordion}")

	div.form-content(v-bind:class="{'open':accordion}")

		div.form-accordion.isAccordion()
			div.title.tab-title
				span.col.col1 Name
				span.col.col2
				span.col.col3
				span.col.col4
				div.clear

			div.form-accordion-content.open

				div.list-memory.list-session

					div.memory-line(v-for="(session,i) of sessionsList", v-on:click.stop="openSessionConfigurator(session.value._id)")
						span.col.col1 
							span.icon.session
						span.col.col2 {{session.value.name}}
						span.col.col3 
						span.col.col4(v-on:click.stop="deleteSession(session)")
							span.bt-trash 
						div.clear
				
				div.big-bt.memory-bt(v-on:click="addSession")
					span.text Add new session template
					span.bt-plus +
								
</template>

<script>

// import { stores } from '../../../stores/MainStore.js';
import actionManager from '../../../actions';
import * as helper from '../../../helper';

export default {

	props:[ 'project', 'target' ],

	data() {
		return {
			accordion: true
		}
	},

	computed: {
		sessionsList: function () {
			return this.target.sessions.filter( function (s) {
				return !s.value._deleted;
			} );
		}
	},

	methods:{

		addSession: function( e ){
			actionManager.trigger('project:addSession', {targetId: this.target.value._id})
		},

		deleteSession: function( session ){
			actionManager.trigger('project:deleteSession', { sessId: session.value._id })
		},

		openSessionConfigurator: function( id ){
			actionManager.trigger('inspector:loadObj', { targetId: id })
		},

	}

}

</script>

<style lang="stylus">
.memory-bloc
	font-family: 'DinBold', 'ComfortaaLight'
	font-size: 13px
	color: #58585B

	.form-accordion .title.tab-title
		padding: 0px 5px

	span.col
		float:left
		display: inline-block
		width: 33%
		text-align: left
		line-height: 40px
		vertical-align: middle

		&.col1
			width: 30px
			span.icon
				width: 24px
				height: 24px
				background: #fff

		&.col2
			width: calc(100% - 80px)

		&.col3
			width: 20%

		&.col4
			width: 10%

	.tab-title
		.col
			line-height: 40px

	.memory-line
		border: 0
		border-radius: 4px
		background: var(--inputValue)
		color: #ffffff
		position: relative
		cursor: pointer
		margin: 5px
		font-size: 14px
		transition: linear all 0.2s !important;
		&:hover
			background: var(--modalTransparentBgColor)
		
	.memory-bt
		cursor: pointer

	.icon
		width: auto
		height: 21px
		display: inline-block
		vertical-align: middle
		transform: none

.itemList
	z-index: 90
	position: absolute
	display:block
	left: 0px
	width: 100%
	background-color: var(--bgColor)
	box-shadow 0px 0px 6px rgba(0,0,0,0.3)
	overflow: hidden
	max-height: 0px
	opacity: 0
	transition: all 0.3s cubic-bezier(0.770, 0.000, 0.175, 1.000)
	transform: scale(0.8)
	overflow: auto

	&.top
		//transform-origin: 50% 100%

	&.bottom
		//transform-origin: 50% 0%

	&.open
		opacity: 1
		transform: scale(1)
		transition: all 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000)

	ul
		padding: 10px
		margin: 0
		list-style-type: none
		overflow: hidden

		&:last-child
			padding-bottom: 10px

		li
			display: block
			padding: 3px 5px
			color: var(--inputTextColor)
			font-size: 14px

			&.title
				background-color: var(--level2Color) !important
				color: white
				border-radius: 2px

			&.item
				cursor: pointer
				&:hover
					background-color: #E6E7E8

.session-set
	.textinput-ctn.has-unit
		.unit
			margin-right: 0px!important
			margin-top: 5px
			width: 41px
			text-align: left

</style>
