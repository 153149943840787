import TextUiObject from '../TextUiObject';

class UiParagraph extends TextUiObject {
    constructor(id, props) {

        super(id, props, 'Paragraph');
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-paragraph",
            parent : 'ui-elements',
            label: "Paragraph",
            isDisplayed : true,
            isEmbeddable: true
        };
    }

    
    initParameters(){
        super.initParameters();

        this.setParamInvisible( 'addChild' );
        this.setParamInvisible( 'removeChild' );
        
        this.setDefaultValue( 'innerText', 'Texte' );
        
    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }
    
    init(){
        super.init();
    }

}

export default UiParagraph;