import React from 'react';
import InputField from '../InputField';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

export default class InputSelectField extends InputField {
    
    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            display : 'block',
        });

        this.state = { value : null };

        this._valueChangeHandler = this.valueChangeHandler.bind(this);
    }

    setDefaultStyles( nextProps, nextState ){

        super.setDefaultStyles( nextProps, nextState );

        if( !nextProps.input.selectedMenuItemStyle )
            nextProps.input.selectedMenuItemStyle = {};

        if( nextProps.input.selectedItemBgColor )
            nextProps.input.selectedMenuItemStyle.backgroundColor =  nextProps.input.selectedItemBgColor;
        delete nextProps.input.selectedItemBgColor;

        if( nextProps.input.selectedItemColor )
            nextProps.input.selectedMenuItemStyle.color =  nextProps.input.selectedItemColor;
        delete nextProps.input.selectedItemColor;

    }

    componentWillMount(){

        super.componentWillMount();

        let defaultVal = [];

        if( this.props.options ){
            
            Object.keys( this.props.options ).forEach( (optId) => {

                let opt = this.props.options[ optId ];

                if( opt.selected && this.props.input.multiple )
                    defaultVal.push( opt.value );

                if( opt.selected && !this.props.input.multiple )
                    defaultVal = opt.value;
            });

        }

        this.setState({ value : defaultVal });
    }

    valueChangeHandler( evt, key, payload ){
        this.setState({value : payload});
        this.eventHandler( 'value',  payload );
    }
    
    render() {

        
        if( !this.props.input.style )
            this.props.input.style = {};
            
        this.props.input.style.fontFamily = 'inherit';

        let inputProps = Object.assign( {}, this.props.input , {
            fullWidth : true,
            onChange: this._valueChangeHandler
        });


        inputProps.inputStyle = Object.assign( {}, inputProps.inputStyle, this.props.commonStyle );

        inputProps.hintStyle.opacity = this.state.placeholderVisible ? 1 : 0;

        /*
        let inputStyle = {
            fontFamily : "inherit",
            textAlign: "left"
        }*/

        let children = [];

        if( this.props.nullable )
            children.push( <MenuItem key="0" value={null} primaryText="" /> );

        if( this.props.options ){
            Object.keys( this.props.options ).forEach( (optId) => {
                let opt = this.props.options[ optId ];
                if( opt.value != '' && opt.label != '' )
                    children.push( <MenuItem key={opt.value} value={opt.value} primaryText={opt.label} />  )
            });
        }

        if (this.props.input.value) {
            this.state.value = this.props.input.value;
            delete this.props.input.value;
        }
        
        return this.wrapHelper(  
            <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                <SelectField value={this.state.value} {...inputProps} >
                    {children}
                </SelectField>
            </div>
        );
       

    }

}