var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "content" } }, [
    _c("h1", [_vm._v("Nodes")]),
    _c(
      "section",
      {
        staticClass: "doc-theme project",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.displayTab("node-project")
          }
        }
      },
      [_c("span", [_vm._v("Project")])]
    ),
    _c(
      "section",
      {
        staticClass: "doc-theme experience",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.displayTab("node-experience")
          }
        }
      },
      [_c("span", [_vm._v("Experience")])]
    ),
    _c(
      "section",
      {
        staticClass: "doc-theme screen",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.displayTab("node-screen")
          }
        }
      },
      [_c("span", [_vm._v("Screen")])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }