import UiObject from '../UiObject';

// will be override by an UiMapboxMarker or UiMapOlMarker object depending on experience MapLibrary parameter

class UiMapMarker extends UiObject {

    constructor(id, props) {
        super(id, props, props);

        this.initParameters();
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-map-marker",
            label: "Map Marker",
            parent : "ui-maps",
            isDisplayed : true,
            isEmbeddable: true,
            nbSection: 1,
            childrenType: 'ui'
        };
    }

    
    initParameters(){

    }
}

export default UiMapMarker;