import ActionAbstract from '../ActionAbstract';
import Vue from 'vue';

class LoadArrayInspector extends ActionAbstract {

    /**
     * @param memoryId
     */

    static get ID() {
        return 'actions:inspector:loadArrayInspector';
    }

    run(state) {

        let targetId = this.getParam('targetId');

        if (state.main.arrayInspectorOpen) {
            if (state.arrayInspector.targetId !== targetId) {
                this.trigger('main:toggleArrayInspector', {open: false});
                setTimeout(() => {this.trigger('inspector:loadArrayInspector', {targetId: this.getParam('targetId'), field: this.getParam('field')}) }, 20);
            }
            return state;
        }

        this.trigger('main:toggleArrayInspector', {open: true});

        Vue.set( state , 'arrayInspector', { targetId: this.getParam('targetId'), field: this.getParam('field') } );

        return false; /*this.composeState(state, {
            arrayInspector: {
                targetId: this.getParam('targetId'),
                field: this.getParam('field')
            }
        });*/
    }

}

export default LoadArrayInspector;