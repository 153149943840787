import InspectorActionAbstract from '../InspectorActionAbstract';
import * as helper from '../../../helper' ;

class UpdateCondition extends InspectorActionAbstract {

    /**
     * @param targetId
     */

    static get ID() {
        return 'actions:inspector:trigger:updateCondition';
    }

    run (state) {

        let target = this.getParam('subaction') === 'event' ? 'events' : 'trigger_states';

        this._target.value[target][this.getParam('index')].params = this.getParam('params');

        return state;
    }

}

export default UpdateCondition;