
<template lang="pug">

div#content
    h1 Node Project

    h2 Presentation

    p A project, contains n (experiences), which allows to create various roles that can interact with each other within the same project.<br />A project has different settings that are shares with all its experiences.

    h2 Parameters

    table#params
        tr  
            td#col1 <strong>Name</strong>
            td#col2 String
        tr  
            td#col1 <strong>Owner</strong>
            td#col2 User who create the project
        tr  
            td#col1 <strong>User(s)</strong>
            td#col2 List of users who can access the project with specific rights (view only, edit, admin)
    
    h2 Actions
    
    table#params
        tr  
            td#col1 <strong>Delete</strong>
            td#col2 Delete the project
        tr  
            td#col1 <strong>Duplicate</strong>
            td#col2 Create a copy of the project
        tr  
            td#col1 <strong>Share</strong>
            td#col2 Share the project with specific rights (view only, edit, admin)
        tr  
            td#col1 <strong>Export</strong>
            td#col2 Export the project in JSON
    
    h2 Settings

    h3 Memory

    p A shared memory is accessible to all the users of the project.<br />It has different types :
        ul.listing
            li Angle
            li Array (A specific interface allows to parameter the array
            li Asset (It can be chosen via the Library)
            li Boolean
            li Color
            li Float
            li Integer
            li Percent
            li String
            li Vector
    p A persistent memory is kept from one session to another.

    h3 Sessions

    p A session represents a virtual room that can be joined by one or several persons using the platform.<br /> All shared resources are shared between the users of a session<br /> A project can have several sessions parameters. Without parameters, a default session is created.<br /> The clients will automatically enter this session (with no filtrage).

    p To check if two clients belong to the same session, several options can be set :
        ul.listing
            li By the distance according to the start position of the first client joining the session.
            li By the duration since the connection of the first client joining the session.
            li By the choice within a list of sessions already created.
    
    p The session can have a name and a duration.<br /> At the end of this duration, an even will be sent to the authoring, and can be used to create a specific scenario of the experience.

    p The session is closed permanently when it is emptied.

    div#back
        a(v-on:click.stop="displayTab('nodes')") &larr; Back


    p <br /><br /><br />

</template>

<script>

	export default {


		props:['displayTab'],

		data() {
			return {
			};
		},

	    methods:{


	    }

    }

</script>

<!-- FIXME: use pure CSS style in webpack.config.js (see below)-->
<style lang="stylus">
.listing
    margin-left: 20px;
</style>

<!-- ORIGINAL CSS
<style>
.listing{
    margin-left: 20px;
}
</style>
-->