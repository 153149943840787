
import ServiceAbstract from './ServiceAbstract';

var _serviceRegistry = {};
var _instanceRegistry = {};

class ServiceManager extends ServiceAbstract {

    _getServiceMeta(service) {
        if (_serviceRegistry[service] !== undefined) {
            return _serviceRegistry[service];
        }
        else {
            return null;
        }        
    }

    static get ID () {
        return 'services:ServiceManager';
    }

    get(service, singleton = true) {
        let instance = _instanceRegistry[service];
        if (instance === undefined) {
            let ctor,
                deps;

            ({ctor, deps} = this._getServiceMeta(service));

            if (ctor !== null) {
                ctor = ctor;
                deps = deps.map(dep => this.get(dep));
            }
            instance = new ctor(deps);

            if (singleton) {
                _instanceRegistry[service] = instance;
            }
        }

        return instance;
    }

    static register(ctor, deps = []) {
        if (_serviceRegistry[ctor.ID] === undefined) {
            _serviceRegistry[ctor.ID] = {ctor, deps};
        }
        else {
            
        }
    }

}

export default ServiceManager;