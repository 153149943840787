import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper' ;

class LoadObjInspector extends ActionAbstract {

    /**
     * @param targetId
     */

    static get ID() {
        return 'actions:inspector:loadObj';
    }

    run(state) {

        let targetId = this.getParam('targetId');
        
        if (state.main.objInspectorOpen) {
            if (state.objinspector.targetId !== targetId) {
                this.trigger('main:toggleObjInspector', {open: false});
                setTimeout(() => { this.trigger('inspector:loadObj', { targetId }) }, 20);
            }
            return state;
        }

        let target = helper.block.getEntityByIDInWholeProject( state.project, targetId );
        if (target && target.custom) { // classic bloc (memory)
            if( target.custom.fields == null && target.custom.states == null ) {
                this.trigger('block:updateFields', { id : targetId } );    
            }
        } else { // sessions bloc
            // if (state.project.sessions) {
            //     const match = state.project.sessions.filter((s) => s._id === targetId);
            //     if (match.length) {
            //         target = match[0];
            //     }
            // }
        }

        this.trigger('main:toggleObjInspector', {open: true});

        return this.composeState(state, {
            objinspector: {
                targetId: targetId,
            }
        });
    }

}

export default LoadObjInspector;