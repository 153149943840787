import ActionAbstract from '../ActionAbstract'

class FetchAll extends ActionAbstract {

    static get ID() {
        return 'actions:template:fetchAll'
    }

    run(state) {

        let templates
        switch (this.getParam('action')) {

            // valid documents
            case "success":
                templates = this.getParam('data')
                // @todo: organise by template name ?
                return this.composeState(state, {
                    project: {
                        templates: templates.docs,
                        value: {
                            template: templates.keys
                        }
                    }
                })

            // unvalid or missing documents
            case "error":

                let message = ''

                templates = this.getParam('data')
                templates.forEach(item => message += `\n${item.message}: ${item.id}` )

                console.error('Fecthing template document failed.\n', message)

                return this.composeState(state, {
                    isLoading: false,
                    templates: {
                        error: {
                            code: -1,
                            message: 'Fecthing template document failed.',
                            action: 'fetch'
                        }
                    }
                })

            default:
                
                templates = this.getParam('templates')
                if (!templates) {
                    console.warn("Invalid template list for project", state.project.value.name)
                    return false
                }

                this.deps.mainStore.template.fetchTemplates(templates).then( response => {
                    if (response.valid.keys.length > 0)
                        this.trigger('template:fetchAll', { action: 'success', data: response.valid })
                    if (response.unvalid.length > 0)
                        this.trigger('template:fetchAll', { action: 'error', data: response.unvalid })
                })

                return this.composeState(state, {
                    project: {
                        templates: [],
                        value: {
                            template: []
                        }
                    }
                })
        }
    }
}

export default FetchAll
