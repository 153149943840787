import InspectorActionAbstract from './InspectorActionAbstract';

class UpdateName extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateName';
    }

    /**
     * @param targetId
     * @param newName
     */
    run (state) {
        let composite = {project: {}};
        composite.project[this._path + '.value.name'] = this.getParam('newName');

        if (this._target.value.type === 'memory') {
            this.trigger('inspector:updateValue', { targetId: this._target.value._id, fieldName: 'name', newValue: this.getParam('newName'), disableFieldUpdate: true });
        }

        return this.composeState(state, composite);
    }
}

export default UpdateName;