var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("top-menu", { attrs: { "app-state": _vm.appState } }),
      _c("breadcrumb", {
        attrs: {
          sort: _vm.sortList,
          searchable: _vm.searchable,
          "app-state": _vm.appState
        }
      }),
      _c("project-filter", {
        attrs: {
          "current-filter": _vm.currentFilter,
          "navigator-open": _vm.navigatorOpen
        }
      }),
      _c(
        "div",
        {
          staticClass: "draw-zone",
          class: { navigatorOpen: _vm.navigatorOpen }
        },
        [
          _c("div", { staticClass: "projects-ctn" }, [
            _c(
              "div",
              { staticClass: "padding-ctn" },
              [
                _c(
                  "div",
                  {
                    staticClass: "new-project-bt",
                    on: { click: _vm.addProject }
                  },
                  [_vm._m(0)]
                ),
                _vm.projectsList && _vm.projectsList.length
                  ? _c("project-items", {
                      attrs: {
                        "projects-list": _vm.projectsList,
                        currentUser: this.appState.user
                      },
                      on: { "send-index": _vm.ProjectIndexToModal }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      ),
      _vm.waiting ? _c("spinner") : _vm._e(),
      _vm.appState.modal && _vm.appState.modal.open
        ? _c("modal", {
            attrs: {
              settings: _vm.appState.modal,
              selectedProject: _vm.selectedProject,
              index: _vm.selectedIndex
            },
            on: { "send-refresh": _vm.ProjectIndexToModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-bubble" }, [
      _c("span", [_vm._v("New Project")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }