<!-- BlockItem vue -->
<template lang="pug">
div
	v-touch.block.noselect(v-if="component.value.visibility!==false", tag="li", @tap="addBlockToScene", @pan="dragItem", :workspace="workspace")
		span.text {{component.value.name}} 
	span.docLink(v-if="concatDocLink")
		a(:href="concatDocLink", target="_blank") ?			 
</template>

<script>

import actionManager from '../../../actions';

export default {

	props: ['component', 'workspace'],

	data() {
		return {
			open: false,
			dragging: false,
			dragPos: {x:0, y:0},
			pollFreq : 30,
			prevTime : 0,
			pattern: null
		}
	},

	// The Vue instance lifecycle has changed, pattern must be reinitialized on update.
	// since migration Vue v1 to v2
	created: function () { this.initPattern() },
	updated: function () { this.initPattern() },

	computed: {

		concatDocLink: function()
		{
			if (this.component && this.component.value && this.component.value.type) {
				return "/#/doc/module/"+this.component.value.type;
			}
			return null
		}

	},

	methods:{

		initPattern: function()
		{
			this.pattern = {
				type : this.component.value.type,
				format: this.component.value.format,
				targets : null
			}

			if( this.pattern.type == "experience"){
				this.pattern.targets = this.component.value.targets;
			}

			if( this.component.value.level == "template") {
				this.pattern.type = 'template-'+this.pattern.type;
				this.pattern.template = {
					id: this.component.value._id,
					name: this.component.value.name,
					type: this.component.value.type
				}
			}
		},

		addBlockToScene: function( ){
			if( !this.dragging ){
				actionManager.trigger('block:createBlock', { pattern : this.pattern });
			}
		},

		getTimestamp: function(){
			var d = new Date();
			var n = d.getTime();
			return n;
		},

		dragItem: function( evt ){
			this.dragPos.x = evt.center.x;
			this.dragPos.y = evt.center.y;

			if( !this.dragging ){
				actionManager.trigger( 'editor:menu:dragDropBlock' , { subaction: 'start', pattern :this.pattern , position : this.dragPos } );
			}
			else{
				let time = this.getTimestamp();
				let diff = time - this.prevTime;

				if( diff > this.pollFreq ){
					actionManager.trigger('editor:menu:dragDropBlock', { subaction:'refreshPos', position : this.dragPos } );
					this.prevTime = time;
				}
			}
			
			this.dragging = true;
			

			if( evt.isFinal ){
				actionManager.trigger( 'editor:menu:dragDropBlock' , { subaction: 'drop' } );
				setTimeout(function() {
					this.dragging = false;
				}.bind(this), 0);
			}

			if(evt.preventDefault) evt.preventDefault();
			if(evt.stopPropagation) evt.stopPropagation();
		}

	}

}

</script>

<style lang="stylus">


</style>

