import ActionAbstract from '../ActionAbstract'
// @todo: uuid and Vue are no longer use after the migration into the global method
// see below
import uuid from 'uuid/v4'
import Vue from 'vue'

class Duplicate extends ActionAbstract {

    static get ID() {
        return 'actions:template:duplicate'
    }

    run(state) {
        let url, ref, id

         // @todo: the action fetchDB, createdb, saveRef and saveData should be move globally 
         // see the ticket NS-154
        switch (this.getParam('action')) {

            case 'success':
                ref = this.getParam('ref')
                return this.composeState(state, {
                    isLoading: false,
                    templates: {
                        list: this.arrayPush({ id: ref.template, value: ref })
                    }
                })

            case 'error':
                return this.composeState(state, {
                    isLoading: false,
                    templates: {
                        error: {
                            code: this.getParam('code'),
                            message: this.getParam('message'),
                            action: 'duplicate'
                        }
                    }
                })

            case 'saveData':
                id = this.getParam('ref').template
                this.saveDB(id, this.getParam('data')).then(
                    response => {
                        // all documents must be created, no conflicts should exist
                        // but do we need to analyze the result to grab errors per document?
                        this.trigger('template:duplicate', {
                            action: 'success',
                            ref: this.getParam('ref')
                        })
                    },
                    error => {
                        error.action = 'error'
                        let name = this.getParam('ref').name
                        if (!error.statusText) error.statusText = `Error while copying template '${name}' documents`
                        this.trigger('template:duplicate', error)
                    },
                )
                return false

            case 'saveRef':
                ref = this.deps.mainStore.template.createNewRef(this.getParam('ref'), state.user)
                url = this.deps.mainStore.config.baseURLAuth
                
                this.deps.mainStore.block.saveDocument({ value: ref }, url).then(
                    response => {
                        this.trigger('template:duplicate', {
                            action: 'saveData',
                            data: this.getParam('data'),
                            ref
                        })
                    },
                    error => {
                        this.trigger('template:duplicate', {
                            action: 'error',
                            code: error.status,
                            message: error.statusText
                        })
                    },
                )
                return false

            case 'createdb':
                id = this.getParam('ref')._id
                
                this.deps.mainStore.template.createDB(id).then(
                    response => {
                        this.trigger('template:duplicate', {
                            action: 'saveRef',
                            ref: this.getParam('ref'),
                            data: this.getParam('data')
                        })
                    },
                    error => {
                        error.action = 'error'
                        this.trigger('template:duplicate', error)
                    }
                )
                return false

            // 'fetchDB':
            default:
                const uid = uuid()
                const name = this.getParam('name')

                id = this.getParam('id')
                this.fetchDB(id).then(
                    response => {
                        let docs = []
                        let ref = null

                        // update references
                        // i.e. replace all document identifier with the new unique id
                        let json = JSON.stringify(response)
                        response.forEach( data => {
                            let key = (data.id === id) ? uid : uuid()
                            let regex = new RegExp(data.id, 'g')
                            json = json.replace(regex, key)
                        })

                        JSON.parse(json).forEach(data => {
                            const doc = data.value
                            // update and store template document
                            if (doc._id === uid) {
                                doc.name = name
                                if (doc.slug) doc.slug = this.deps.mainStore.project.slugify(name)
                                ref = doc
                            }

                            // remove rev to create everything
                            // and store result
                            delete doc._rev
                            docs.push(doc)
                        })

                        this.trigger('template:duplicate', { action: 'createdb', ref, data:docs })
                    },
                    error => {
                        error.action = 'error'
                        this.trigger('template:duplicate', error)
                    }
                )

                return this.composeState(state, {
                    isLoading: true
                })
        }
    }



    // ---------------------------------------------------------------------------------------------
    // @todo: no longer used but could be useful in project/template store (to be discussed)
    /**
     * Retrieve all documents in the specified template database.
     * 
     * @param {string} id — The template ID.
     * @returns Promise
     */
    fetchDB(id) {
        /** DATABASE WORKSHOP NS-286
         * ! this call should have an authentification token and pass by the backend server
         * ! nothing is secure and every user could do this call
         * ! We should use the token/cookie used with couchDB login.
        */
        return new Promise((resolve, reject) => {
            let url = this.deps.mainStore.config.bdd + 'nodal-template-' + id + '/_design/lists/_view/all-list'

            Vue.http.get(url, { params: { include_docs: false } }).then(
                response => {
                    if (!response.body.error) resolve(response.body.rows)
                    else reject({ message: response.body.error })
                },
                error => reject({ code: error.status, message: error.statusText })
            )
        })
    }

    /**
     * Save all documents to the specified template database.
     * 
     * @param {string} id — The template ID.
     * @param {Array} data - All documents to be saved
     * @returns Promise
     */
    saveDB(id, data) {
        /** DATABASE WORKSHOP NS-286
         * ! this call should have an authentification token and pass by the backend server
         * ! nothing is secure and every user could do this call
         * ! We should use the token/cookie used with couchDB login.
        */
        return new Promise((resolve, reject) => {
            let url = this.deps.mainStore.config.bdd + 'nodal-template-' + id + '/_bulk_docs'

            Vue.http.post(url, { docs: data }).then(
                response => {
                    if (!response.body.error) resolve(response.body)
                    else reject({ message: response.body.error })
                },
                error => reject({ code: error.status, message: error.statusText })
            )
        })
    }
    // ---------------------------------------------------------------------------------------------
}

export default Duplicate