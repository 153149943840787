import ActionManager from '../ActionManager';

import DeselectAll from './DeselectAll';
ActionManager.register(DeselectAll);

import ToggleBreadcrumb from './ToggleBreadcrumb';
ActionManager.register(ToggleBreadcrumb, ['stores:MainStore']);

import ToggleNavigator from './ToggleNavigator';
ActionManager.register(ToggleNavigator, ['stores:MainStore']);

import ToggleInspector from './ToggleInspector';
ActionManager.register(ToggleInspector, ['stores:MainStore']);

import ToggleObjInspector from './ToggleObjInspector';
ActionManager.register(ToggleObjInspector, ['stores:MainStore']);

import ToggleArrayInspector from './ToggleArrayInspector';
ActionManager.register(ToggleArrayInspector, ['stores:MainStore']);

import ToggleJSONEditor from './ToggleJSONEditor';
ActionManager.register(ToggleJSONEditor, ['stores:MainStore']);

import ToggleLibrary from './ToggleLibrary';
ActionManager.register(ToggleLibrary, ['stores:MainStore']);

import ToggleTemplate from './ToggleTemplate';
ActionManager.register(ToggleTemplate, ['stores:MainStore']);

import ToggleTagModal from './ToggleTagModal';
ActionManager.register(ToggleTagModal, ['stores:MainStore']);

import ToggleMediaPreview from './ToggleMediaPreview';
ActionManager.register(ToggleMediaPreview, ['stores:MainStore']);

import GotoRoute from './GotoRoute';
ActionManager.register(GotoRoute, ['stores:MainStore']);

import OpenPreview from './OpenPreview';
ActionManager.register(OpenPreview, ['stores:MainStore']);

import UpdateBreadcrumb from './UpdateBreadcrumb';
ActionManager.register(UpdateBreadcrumb, ['stores:MainStore']);

import HistoryBack from './HistoryBack';
ActionManager.register(HistoryBack, ['stores:MainStore']);

import HistoryNext from './HistoryNext';
ActionManager.register(HistoryNext, ['stores:MainStore']);

import AddToHistory from './AddToHistory';
ActionManager.register(AddToHistory, ['stores:MainStore']);

import InitHistory from './InitHistory';
ActionManager.register(InitHistory, ['stores:MainStore']);

import UpdateXpConfig from './UpdateXpConfig';
ActionManager.register(UpdateXpConfig, ['stores:MainStore']);


import OpenPanel from './panel/OpenPanel';
ActionManager.register(OpenPanel, ['stores:MainStore']);

import ClosePanel from './panel/ClosePanel';
ActionManager.register(ClosePanel, ['stores:MainStore']);

import OpenPopup from './popup/OpenPopup';
ActionManager.register(OpenPopup, ['stores:MainStore']);

import ClosePopup from './popup/ClosePopup';
ActionManager.register(ClosePopup, ['stores:MainStore']);


import OpenGeoModal from './geomodal/OpenGeoModal';
ActionManager.register(OpenGeoModal, ['stores:MainStore']);

import CloseGeoModal from './geomodal/CloseGeoModal';
ActionManager.register(CloseGeoModal, ['stores:MainStore']);


import OpenModal from './modal/OpenModal';
ActionManager.register(OpenModal, ['stores:MainStore']);

import ConfirmModal from './modal/ConfirmModal';
ActionManager.register(ConfirmModal, ['stores:MainStore']);

import CloseModal from './modal/CloseModal';
ActionManager.register(CloseModal, ['stores:MainStore']);
