import React from 'react';
import NodalUi from '../NodalUi';

export default class LinePath extends NodalUi {

    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {

        });

        this.specificStyle = {
            
        };


        // merge style order :  
        // mergedStyle <== defaultStyle(parameters) < specificStyle < field props < forcedStyle(auto calculated)
    }

    setDefaultStyles( nextProps, nextState ){

        super.setDefaultStyles( nextProps, nextState );
        
    }

    getCurrentPath(){
        let path = "";
        let first = true;

        this.props.points.forEach( ( p ) => {
            if( first ){
                path += "M"+p.x+" "+p.y;
                first = false;
            }else{
                path += " L"+p.x+" "+p.y;
            }
        });

        if( this.props.closedPath === true )
            path += "Z";

        return path;
    }

    render() {

        let newProps = {
            d : this.getCurrentPath(),
            fill: this.props.lineDom.fill === null ? "transparent" : this.props.lineDom.fill
        };

        newProps = Object.assign( this.props.lineDom , newProps );

        return this.wrapIntoHammer( this.wrapIntoAuthoring( 
            <path {...newProps} {...this.otherEvents} />
         , 0  )) ;
       

    }

}