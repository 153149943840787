<!--
    SliderPanel component
    ---------------------
    Base panel to display contents above editor (see Library, Template or JsonEditor vue)
-->
<template lang="pug">
<transition appear name="fade">
div#slider-panel(:style="{ 'z-index': zIndex }")
    div.cover-back(v-on:click.stop="closePanel")
    <transition appear name="slide">
    div.window
        div.container
            div.title-bar
                div.icon
                    icon(:name="name")
                div.title {{ workspace ? `${title} of ${capitalize(workspace.target)}: ${capitalize(workspace.workspaceName)}` : title }}
                div.bt-close(v-on:click.stop="closePanel")
            <component class="main" :is="embedComponent" :state="state" :project="project" :user="user" :workspace="workspace"></component>
    </transition>
</transition>
</template>

<script>
// STORE
import { stores } from '../../stores/MainStore.js';
import actionManager from '../../actions';

import LibraryPanel from  '../library/Library.vue';
import TemplatePanel from  '../template/Template.vue';
import JsonPanel from  '../common/JsonEditor.vue';
import Icon from './Icon.vue';

export default {

    // title    the embed component title
    // name     the embed component icon name, also use to define this panel name
    // state    the embed component state
    // panel    the current panel state (ie. app.state.panel object)
    // project  the current project state
    // user     the current user
    props: ['title', 'name', 'state', 'panel', 'project', 'user', 'workspace'],

    // panel components must have the suffix 'Panel' or uncomment and update the switch code below.
    // see the embedComponent() method
    components: { Icon, LibraryPanel, TemplatePanel, JsonPanel },

    data() {
        return {
        }
    },

    created: function() {
        //if ( this.panel && this.panel.open ) this.closePanel()
        actionManager.trigger('main:panel:open', {name: this.name.toLowerCase(), type: "slider" })
    },

    computed: {
        embedComponent: function () {
            /*switch (this.name.toLowerCase()) {
                case "library":
                    return "library-panel"
                case "templat":
                    return "template-panel"
                default:
                    throw new Error(`Slider panel not found ${this.name}`);
                    break;
            } */
            return this.name.toLowerCase() + "-panel"
        },
        // z-index exception for JSON Editor which has to be displayed on top of Tag Modal.
        zIndex() {
            return (this.name === 'JSON') ? 110 : 92;
        },
    },

    methods: {

        // We assume that action are registered in the format toggle + panel name (capitalized)
        // If not or nor more appropriate, a solution is to add another 'main:panel' property and pass
        // wich panel must be used
        closePanel: function() {
            let pname  = this.panel && this.panel.name || this.name
            pname= pname.charAt(0).toUpperCase() + pname.substr(1).toLowerCase()
            actionManager.trigger('main:toggle'+pname, { open : false })
            actionManager.trigger('main:panel:close')
        },

        capitalize: function(word){
            return word.charAt(0).toUpperCase() + word.slice(1)
        }
    }
}
</script>

<style lang="stylus">

.fade-enter
.fade-leave-to
    opacity: 0

.fade-enter-active
.fade-leave-active
    transition: opacity .5s


.slide-enter
.slide-leave-to
    transform: translateY(-100%)

.slide-enter-active
.slide-leave-active
    transition: transform .5s ease


#slider-panel
    display: block
    position: fixed
    width: 100%
    height: 100%
    top: 0
    padding: 0 40px

    .cover-back
        display: block
        width: 100%
        height: 100%
        position: fixed
        top: 0
        left: 0
        background-color: var(--modalBgColor)
        opacity: 0.7

    .window
        background-color: var(--modalColor)
        border-radius: 0px 0px 20px 20px
        position: relative
        width: calc(100% - 120px)
        height: calc(100% - 60px)
        box-shadow 0px 0px 10px var(--shadowColor)
        padding: 20px 20px 0px 20px

        .container
            display: flex
            flex-flow: column
            background-color: #FFF6EC
            box-shadow 0px 0px 10px var(--shadowColor)
            height: calc(100% - 20px)

            .title-bar
                display: flex
                align-items: center
                padding: 0px 10px
                border-radius: 8px 8px 0px 0px
                background-color: var(--bgColor)

                div.title
                    flex-grow: 1
                    padding-left: 10px
                    font-size: 16px
                    font-family: 'DinBold'
                    color: url(../../assets/images/arrow-bottom.png)
                    line-height: 42px
                    height: 40px
                    color: var(--inputTextColor)

                div.bt-close
                    width: 22px
                    height: 22px
                    position: relative
                    transform: rotate(45deg)
                    cursor: pointer
                    opacity: 1

                    &:hover
                        opacity: 0.5

                    &:before
                        content:''
                        display: block
                        width: 100%
                        height: 2px
                        background-color: #575756
                        position: absolute
                        top: calc(50% - 1px)
                        left: 0

                    &:after
                        content:''
                        height: 100%
                        width: 2px
                        background-color: #575756
                        position: absolute
                        left: calc(50% - 1px)
                        top: 0

                .icon
                    width: 24px
                    height: 24px
                    border-radius: 20px
                    background-color: var(--moduleTitleColor)
                    transform: translateY(0)

                    span
                        display: inline-block
                        background-color: white

        .container
            border-radius: 10px
        .main
        .footer
            border-bottom-left-radius: 10px
            border-bottom-right-radius: 10px

        // COMMON MAIN STYLES
        .main
            display: flex
            flex-flow: column
            flex-grow: 1
            background-color: var(--modalMainColor)
            height: calc(100% - 20px)

            .criteria-bar
                padding: 0px 10px
                background-color: var(--modalCriteriaBarColor)
                display: flex
                align-items: center
                min-height: 40px
                line-height: 40px
                color: #707070

                .arrange-by
                    display: inline-block
                    height: 40px

                    .button
                        display: inline-block
                        margin-right: 5px
                        margin-top: 7px
                        border-radius: 4px
                        border-width: 0px
                        background: var(--iconPuzzleColor)
                        &.active
                            background-color: #707070
                            span
                                background-color: white
                        span
                            display: block
                            background-color: #575756
                            width: 24px
                            height: 24px
                            transform: translateY(0)


                div.sort
                    flex-grow: 1
                    padding-left: 10px

                    select
                        // FIXME: override previous settings
                        width: auto
                        border: none
                        font-size: 14px
                        font-family: 'DinBold'
                        padding-right: 40px
                        // ---------------------------------
                        text-transform: capitalize
                        background: var(--menuColor)
                        margin-top: 7px
                        height: 24px
                        line-height: 27px
                        margin-left: 6px

                    .icon.arrow-select
                        display: inline-block
                        width: 16px
                        height: 16px
                        margin-top: 11px
                        margin-left: -25px
                        background-color: #707070
                        transform: translateY(0px)
                        vertical-align: top

            .filter
                padding: 0px 10px
                height: 40px
                background-color: var(--moduleTitleColor)
                text-align: center

                .tab
                    color: white
                    background-color: var(--modalFilterColor)
                    display: inline-block
                    padding: 0px 10px
                    border-radius: 10px
                    min-width: 100px
                    cursor: pointer
                    height: 24px
                    margin-top: 7px
                    margin-left: 4px
                    margin-right: 4px
                    line-height: 26px
                    &.active
                        background-color: var(--bgColor)
                        color: var(--inspectorText)

            .list
                flex-grow: 1
                position: relative
                height: calc(100% - 220px)

                .content
                    font-family: 'DinRegular', 'ComfortaaLight'
                    font-size: 14px
                    padding: 10px
                    height: calc(100% - 20px)
                    overflow-y: auto

                    .add
                    .item
                        background-color: var(--bgColor)
                        display: inline-block
                        width: 140px!important
                        width: 10%
                        margin: 10px
                        position: relative
                        box-shadow 0px 0px 10px var(--shadowColor)
                        background-size: cover
                        background-position: center center
                        cursor: pointer
                        box-shadow: 0px 0px 10px var(--shadowColor)

                        &:before
                            content: ''
                            display: block
                            width: 100%
                            padding-bottom: 100%

                    .add
                        span.ico
                            display: block
                            width: 40px
                            height: 40px
                            background-color: var(--moduleTitleColor)
                            color: white
                            font-family: 'DinRegular', 'ComfortaaLight'
                            font-size: 20px
                            position: absolute
                            top: 50%
                            left: 50%
                            margin-left: -20px
                            margin-top: -20px
                            text-align: center
                            border-radius: 40px
                            z-index: 10

                            &:before
                                content:''
                                display: block
                                width: 40%
                                height: 1px
                                background-color: white
                                position: absolute
                                top: 50%
                                left: 30%

                            &:after
                                content:''
                                display: block
                                height: 40%
                                width: 1px
                                background-color: white
                                position: absolute
                                left: 50%
                                top: 30%
                    .item
                        &.hide
                            display : none

                        &:hover
                            .flags
                                opacity: 0
                            .actions
                                opacity: 1

                        &.selected
                            &:after
                                content:''
                                position: absolute
                                top:0px
                                left:0px
                                bottom:0px
                                right:0px
                                border: 3px solid #F39A74
                                z-index: 1
                            .icon
                                background-color: #F39A74

                         .icon
                            background-color: var(--topSelectColor)
                            width: 80px
                            height: 80px
                            position: absolute
                            top: calc(50% - 10px)
                            left: 50%
                            transform: translate(-50%,-50%)

                        .name
                            display: block
                            width: 100%
                            height: 40px
                            position: absolute
                            bottom: 0
                            left: 0
                            background-color: var(--moduleTitleColor)
                            z-index: 1;
                            overflow: hidden
                            text-overflow: ellipsis

                            span
                                display: inline-block
                                text-align: left
                                color: white
                                font-family: 'DinRegular', 'ComfortaaLight'
                                font-size: 14px
                                white-space: nowrap
                                overflow: hidden
                                text-overflow: ellipsis
                                position: absolute
                                top: 10px
                                left: 10px
                                right: 10px
                                height: 20px
                                line-height: 20px

                        .flags
                        .actions
                            display: block
                            width: 100%
                            text-align: left
                            position: absolute
                            top: 0
                            left: 0
                            z-index: 2

                            .button
                                display: inline-block
                                margin: 7px
                                margin-right: 0px
                                width: 20px
                                height: 20px
                                border-radius: 20px
                                cursor: pointer
                                background-position: center center
                                background-repeat: no-repeat
                                background-size: auto 70%

                        .flags
                            opacity: 1
                            .button
                                background-color: #F39A74

                        .actions
                            opacity: 0
                            .button
                                background-color: #939597
                                &:hover
                                    background-color: #F39A74

                    .rounded
                    .selected:after
                        border-radius: 8px
                    .name
                        border-top-left-radius: 0
                        border-top-right-radius: 0

            .footer
                background-color: rgba(244,158,117,0.7)
                text-align: center
                // FIXME: padding-bottom is already set
                padding: 0px
                min-height: 40px
                font-family: 'DinBold'
                font-size: 14px

                .button
                    cursor: pointer
                    opacity: 0.3
                    &.enabled
                        opacity: 1
                        line-height: 40px
                        font-size: 16px


</style>