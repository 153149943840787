import ActionAbstract from '../ActionAbstract';

export const FILTERS = {
    ALL: 'all', 
    OWNER: 'owner', 
    USER: 'user'
};

class SetFilter extends ActionAbstract {

    static get ID() {
        return 'actions:projects:setFilter';
    }

    run(state) {
        this.trigger('projects:listAll');
        return this.composeState(state, {
            projects: {
                currentFilter: this.setFilter
            }
        });
    }

    setFilter() {
        let filters = Object.keys(FILTERS).map(f => f.toLowerCase());
        let neoFilter = this.getParam('filterValue')

        if (filters.indexOf(neoFilter !== -1)) return neoFilter;
        
        if (neoFilter.includes('search_')) {
            let searchFilter = neoFilter.replace('search_', '');
            return searchFilter
        }
        
        return FILTERS.ALL;
    }
}

export default SetFilter;