import ActionAbstract from '../ActionAbstract';

class QuitProject extends ActionAbstract {

    /**
     */

    static get ID() {
        return 'actions:project:quitProject';
    }

    run(state) {

		this.trigger('main:gotoRoute', { routeParam : '/projects' });

        return this.composeState( state, {
            project: undefined,
            main: {
              arrayInspectorOpen: false
            },
            arrayInspector: {}
        });
    }



}

export default QuitProject;
