//
// TODO: Create an OpenLayer context (MapOLContext.js)
// to apply animation or another OpenLayer features
//

import UiObject, { STATE_CHANGE, EVENT_CALLBACK } from '../../UiObject';


export const FEATURE_UPDATE = 'FEATURE_UPDATE';

class UiMapOlLayer extends UiObject {
    constructor(id, props) {
        super(id, props, 'MapOLLayer', false);

        this.featureUpdateHandler = this.featureUpdateHandler.bind(this);
        this.initParameters();
    }

    static getAuthConfig() {
        return {
            exportFormat: 'ui-map-ol-layer',
            label: 'MapOL Layer',
            parent: 'ui-maps-ol',
            isDisplayed: true,
            canBeCreated: false,
            isEmbeddable: true,
            nbSection: 1,
            childrenType: 'ui',
            requireUiParentTypes: ['MapOL'],
        };
    }


    initParameters() {
        // super.initParameters(); // do not inherit of UiObject parameters

        const groupGeneral = this.createInspectorNode('group', 'general', 'General');
        groupGeneral.children.push(this.createInspectorNode('group-field', 'general-fields'));

        const groudChildhood = this.createInspectorNode('group', 'general', 'Childhood');
        groudChildhood.hideForObjInspector = true;
        groudChildhood.children.push(this.createInspectorNode('group-field', 'parents-fields'));
        groudChildhood.children.push(this.createInspectorNode('group-field', 'childs-fields'));

        const groupViewport = this.createInspectorNode('group', 'viewport', 'Viewport');
        groupViewport.children.push(this.createInspectorNode('group-field', 'viewport-fields-position'));
        groupViewport.children.push(this.createInspectorNode('group-field', 'viewport-fields-style'));

        const groupCulling = this.createInspectorNode('group', 'culling', 'Group Culling');
        groupCulling.children.push(this.createInspectorNode('group-field', 'fields-culling'));

        const groupControl = this.createInspectorNode('group', 'rendered', 'Marker Rendered List');
        groupControl.children.push(this.createInspectorNode('group-field', 'control-fields-rendered'));


        const stylesTopic = this.createInspectorNode('topic', 'styles', 'Map Layer Settings');
        stylesTopic.children = [
            groupGeneral, groudChildhood, groupViewport, groupCulling, groupControl,
        ];

        this.inspector.push(stylesTopic);

        // cluster topic
        const groupClusterSettings = this.createInspectorNode('group', 'settings', 'Settings');
        groupClusterSettings.children.push(this.createInspectorNode('group-field', 'cluster-settings'));
        const groupClusterStyles = this.createInspectorNode('group', 'styles', 'Styles');
        groupClusterStyles.children.push(this.createInspectorNode('group-field', 'cluster-styles'));
        const clusterTopic = this.createInspectorNode('topic', 'cluster', 'Cluster Options');
        clusterTopic.children = [groupClusterSettings, groupClusterStyles];
        this.inspector.push(clusterTopic);

        // events topic
        const groupEventsFields = this.createInspectorNode('group', 'events', 'Events');
        groupEventsFields.children.push(this.createInspectorNode('group-field', 'events-fields'));
        const feedbackTopic = this.createInspectorNode('topic', 'feedback', 'Feedback');
        feedbackTopic.children = [groupEventsFields];
        this.inspector.push(feedbackTopic);


        const commonParameters = {
            // GENERAL
            children: {
                type: 'Mixed',
                default: [],
                partial: null,
                auth: {
                    label: '',
                    container: 'childs-fields',
                    invisible: true,
                },
            },

            parent: {
                type: 'String',
                // method: 'addToParent',
                callable: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'parents-fields',
                    widget: 'calculated',
                    label: 'Map parent',
                },
            },
            addMarker: {
                type: 'String',
                method: 'addChild',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'childs-fields',
                    widget: 'calculated',
                    label: 'Add Marker',
                },
            },
            removeMarker: {
                type: 'String',
                method: 'removeChild',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'childs-fields',
                    widget: 'calculated',
                    label: 'Remove Children',
                },
            },
            removeAllMarkers: {
                type: 'String',
                method: 'removeAllChildren',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'childs-fields',
                    widget: 'calculated',
                    label: 'Remove all Childrens',
                },
            },
            onRemoveChildren: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Remove Children',
                    description: 'Outputs the children identifier when it has been removed.',
                    container: 'events-fields',
                    widget: 'calculated',
                },
            },
            onRemoveAllChildrens: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Remove All Childrens',
                    description: 'Outputs a message when all childrens has been removed.',
                    container: 'events-fields',
                    widget: 'calculated',
                },
            },

            visibility: {
                type: 'Boolean',
                default: true,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Visibility',
                    container: 'general-fields',
                },
            },

            customTags: {
                type: 'String',
                default: '',
                partial: null,
                method: 'setCustomTags',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Add tags',
                    container: 'general-fields',
                },
            },
            removeTags: {
                type: 'String',
                default: '',
                partial: null,
                method: 'removeTags',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Remove tags',
                    container: 'general-fields',
                },
            },

        };

        const mapParameters = {

            autoOutputMarkersRendered: {
                type: 'Boolean',
                default: false,
                connection: null,
                auth: {
                    label: 'Auto Query Markers Rendered',
                    container: 'control-fields-rendered',
                },
            },
            autoOutputMarkersRenderedThrottle: {
                type: 'Int',
                default: 500,
                connection: null,
                auth: {
                    label: 'Query Markers Rendered Throttle',
                    container: 'control-fields-rendered',
                    unit: 'ms',
                    conditions: [{ field: 'autoOutputMarkersRendered', value: true, operator: '==' }],
                },
            },
            queryMarkersRendered: {
                type: 'Mixed',
                callable: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Query Markers Rendered',
                    container: 'control-fields-rendered',
                },
            },
            markersRendered: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Markers Rendered',
                    container: 'control-fields-rendered',
                },
            },


            culling: {
                type: 'Boolean',
                default: false,
                connection: null,
                auth: {
                    label: 'Activate culling',
                    container: 'fields-culling',
                    description: 'Activate layer option to filter features (markers or lines) by their group tags.',
                },
            },

            cullingMethod: {
                type: 'String',
                default: 'visible-boundingbox',
                connection: null,
                auth: {
                    label: 'Culling Method',
                    container: 'fields-culling',
                    description: 'Determine the method use to filter markers bounding box',
                    widget: 'select',
                    options: [
                        { value: 'nearest-center', label: 'Nearest Center' },
                        { value: 'visible-boundingbox', label: 'All visible bounding boxs' },
                    ],
                },
            },
            createCullingZones: {
                type: 'Mixed',
                callable: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Create culling zones',
                    container: 'fields-culling',
                    conditions: [{ field: 'culling', value: true, operator: '==' }],
                    description: 'Activate layer option to filter features (markers or lines) by their group tags.',
                },
            },
            currentCullingTag: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Current Culling tag',
                    description: 'Output the current culling tag name displayed in the viewport',
                    container: 'fields-culling',
                    widget: 'calculated',
                    conditions: [{ field: 'culling', value: true, operator: '==' }],
                },
            },


            markerClicked: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Marker clicked',
                    container: 'viewport-fields-style',
                },
            },

            zIndex: {
                type: 'Int',
                default: null,
                partial: 'options',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Disposition',
                    container: 'viewport-fields-position',
                },
            },
            minZoom: {
                type: 'Int',
                default: null,
                partial: 'options',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Min zoom',
                    container: 'viewport-fields-position',
                },
            },
            maxZoom: {
                type: 'Int',
                default: null,
                partial: 'options',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Max zoom',
                    container: 'viewport-fields-position',
                },
            },


        };
        const clusterParameters = {

            enable: {
                type: 'Boolean',
                default: false,
                partial: 'cluster',
                connection: null,
                auth: {
                    label: 'Use Cluster',
                    container: 'cluster-settings',
                    description: 'Do clustering with markers.',
                },
            },
            distance: {
                type: 'Int',
                default: 20,
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Distance',
                    container: 'cluster-settings',
                    description: 'Minimum distance in pixels between clusters.',
                },
            },


            useTexture: {
                type: 'Boolean',
                default: false,
                partial: 'cluster',
                connection: null,
                auth: {
                    label: 'Use Texture',
                    container: 'cluster-styles',
                    description: 'Use Map container texture as icon.',
                },
            },

            iconSize: {
                type: 'Float',
                default: 20,
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Icon Size',
                    container: 'cluster-styles',
                    description: 'Default cluster icon size.',
                    conditions: [{ field: 'useTexture', value: false, operator: '==' }],
                },
            },
            iconColor: {
                type: 'Color',
                default: { hex: '#FFFFFF' },
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Icon Color',
                    description: 'Default cluster icon color.',
                    container: 'cluster-styles',
                    conditions: [{ field: 'useTexture', value: false, operator: '==' }],
                },
            },


            texture: {
                type: 'String',
                default: '',
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Texture Reference',
                    description: 'Texture to use to display the cluster (ref to textures parameter in Map container)',
                    container: 'cluster-styles',
                    conditions: [{ field: 'useTexture', value: true, operator: '==' }],
                },
            },
            textureSize: {
                type: 'Float',
                default: 1,
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Texture Size',
                    description: 'Cluster texture size.',
                    container: 'cluster-styles',
                    conditions: [{ field: 'useTexture', value: true, operator: '==' }],
                },
            },
            textureAnchor: {
                // TODO: prefers use ArrayType with value like [0.5,0.5]
                type: 'String',
                default: 'center',
                partial: 'cluster',
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Texture Anchor',
                    description: 'Icon anchor position inside the cluster.',
                    container: 'cluster-styles',
                    widget: 'select',
                    options: [
                        { value: 'center', label: 'center' },
                        { value: 'left', label: 'left' },
                        { value: 'right', label: 'right' },
                        { value: 'top', label: 'top' },
                        { value: 'bottom', label: 'bottom' },
                        { value: 'top-left', label: 'top-left' },
                        { value: 'top-right', label: 'top-right' },
                        { value: 'bottom-left', label: 'bottom-left' },
                        { value: 'bottom-right', label: 'bottom-right' },
                    ],
                    conditions: [{ field: 'useTexture', value: true, operator: '==' }],
                },
            },

            useNumber: {
                type: 'Boolean',
                default: true,
                partial: 'cluster',
                connection: null,
                auth: {
                    label: 'Show Cluster Number',
                    description: 'Display the number of clustered markers.',
                    container: 'cluster-styles',
                },
            },
            numberColor: {
                type: 'Color',
                default: { hex: '#333333' },
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Number Color',
                    description: 'Cluster number color.',
                    container: 'cluster-styles',
                    conditions: [{ field: 'useNumber', value: true, operator: '==' }],
                },
            },
            numberOffset: {
                type: 'Vector2',
                default: { x: 0, y: 0 },
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Number Offset',
                    description: 'Cluster number offset in pixels relative to center.',
                    container: 'cluster-styles',
                    conditions: [{ field: 'useNumber', value: true, operator: '==' }],
                },
            },
            fontSize: {
                type: 'Int',
                default: 12,
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Font Size',
                    description: 'Cluster number font size.',
                    container: 'cluster-styles',
                    conditions: [{ field: 'useNumber', value: true, operator: '==' }],
                },
            },
            fontFamily: {
                type: 'String',
                default: 'sans-serif',
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Font Family',
                    description: 'Cluster number font family.',
                    container: 'cluster-styles',
                    widget: 'select',
                    options: [
                        { value: 'inherit', label: 'Inherit' },
                        { value: 'ComfortaaLight', label: 'Comfortaa' },
                        { value: 'Roboto', label: 'Roboto' },
                        { value: 'YaahowuLight', label: 'Yaahowu Light' },
                        { value: 'YaahowuBold', label: 'Yaahowu Bold' },
                        { value: 'PoppinsRegular', label: 'Poppins Regular' },
                        { value: 'PoppinsBold', label: 'Poppins Bold' },
                        { value: 'DINNextLTPro-Regular', label: 'DIN Next Regular' },
                        { value: 'DINNextLTPro-Bold', label: 'DIN Next Bold' },
                        { value: 'bemboregular', label: 'Bembo Regular' },
                        { value: 'bembobold', label: 'Bembo Bold' },
                        { value: 'bemboitalic', label: 'Bembo Regular Italic' },
                        { value: 'bembobold_italic', label: 'Bembo Bold Italic' },
                        { value: 'DinCondensed', label: 'DIN Condensed' },
                        { value: 'DinBoldCondensed', label: 'DIN Bold Condensed' },
                        { value: 'Georgia', label: 'Georgia' },
                        { value: 'Arial', label: 'Arial' },
                        { value: 'Verdana', label: 'Verdana' },
                        { value: 'Helvetica', label: 'Helvetica' },
                        { value: 'serif', label: 'Serif' },
                        { value: 'sans-serif', label: 'Sans-Serif' },
                        { value: 'monospace', label: 'Monospace' },
                        { value: 'GalanoRegular', label: 'Galano Regular' },
                        { value: 'GalanoBold', label: 'Galano Bold' },
                        { value: 'EarthOrbiterTitle', label: 'Earth Orbiter Title' },
                    ],
                    conditions: [{ field: 'useNumber', value: true, operator: '==' }],
                },
            },

            growFactor: {
                type: 'Float',
                default: 0,
                partial: 'cluster',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Grow factor',
                    description: 'Scale factor applied to the size according to the number of clustered markers. 0 means no scale, 1 proportionally to the number of clustered markers.',
                    container: 'cluster-styles',
                    conditions: [{ field: 'useNumber', value: true, operator: '==' }],
                },
            },
        };

        this.markersRendered = [];
        this.updateRequired = false;

        // to optimize mouse move event process
        // stores instances to work only with markers who has an output attached
        this.mouseListeners = new Map();

        this.features = new Map();

        this.addToParameters(commonParameters);
        this.addToParameters(mapParameters);
        this.addToParameters(clusterParameters);

        // store OL instances
        this.map = null;
        this.layer = null;
    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(),
    // or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init(); }

    init() {
        super.init();

        this.set('markerRenderedUpdated', this.markerRenderedUpdated.bind(this));
        this.set('mapLayerCreated', this.mapLayerCreated.bind(this));
        this.set('markerClickedFromLayerCallback', this.markerClickedFromLayerCallback.bind(this));
        this.set('markerDoubleClickedFromLayerCallback', this.markerDoubleClickedFromLayerCallback.bind(this));
        this.set('currentCullingTagCallback', this.currentCullingTagCallback.bind(this));
        this.set('onFeatureStylesUpdated', this.onFeatureStylesUpdated.bind(this));
        this.set('onFeatureUpdated', this.onFeatureUpdated.bind(this));
        this.set('onTransformEnded', this.onTransformEnded.bind(this));
        this.set('onFollowPathEnded', this.onFollowPathEnded.bind(this));
        this.set('onFollowPathCycleEnded', this.onFollowPathCycleEnded.bind(this));
        this.set('onFollowPathProgression', this.onFollowPathProgression.bind(this));
        this.set('onMouseMoved', this.onMouseMoved.bind(this));

        this.set('haveMouseListeners', false);
        this.set('queryMarkersRendered', false);
        this.set('createCullingZones', false);
        this.set('renderedMarkers', null);


        this.setFeatures();
        this.set('triggerFeatures', false);

        this.updateIfNecessary = this.updateIfNecessary.bind(this);
        requestAnimationFrame(this.updateIfNecessary);
    }

    markerRenderedUpdated(markers) {
        this.set('renderedMarkers', markers);
        this.markersRendered = markers;

        this.emit(EVENT_CALLBACK, { name: 'markersRendered', value: this.markersRendered.join(',') });
    }

    /**
     * Openlayer single click callback.
     * @param {String} markerId - the marker identifier.
     * @param {Array} lonlat - the longitude/latitude of the current mouse coordinate in openlayer.
     */
    markerClickedFromLayerCallback(markerId, lonlat) {
        if (markerId && typeof markerId === 'string') {
            const child = this.assetManager.get(markerId, this.ctx);
            child.markerClickedCallback(markerId, lonlat);
        }

        this.emit(EVENT_CALLBACK, { name: 'markerClicked', value: markerId });
    }

    /**
     * Openlayer double click callback.
     * @param {String} markerId - the marker identifier.
     * @param {Array} lonlat - the longitude/latitude of the current mouse coordinate in openlayer.
     */
    markerDoubleClickedFromLayerCallback(markerId, lonlat) {
        if (markerId && typeof markerId === 'string') {
            const child = this.assetManager.get(markerId, this.ctx);
            child.markerDoubleClickedCallback(markerId, lonlat);
        }

        this.emit(EVENT_CALLBACK, { name: 'markerClicked', value: markerId });
    }


    updateFeature(child, feature) {
        if (!child || !child.id) return;

        this.features.set(child.id, feature);
    }

    addChild(child) {
        if (!child) return;

        if (typeof child === 'string') {
            if (!this.checkAssetManager()) return;
            child = this.assetManager.get(child, this.ctx);
            if (!child || (Array.isArray(child) && child.length === 0)) return;
        }

        // do not work with polygon
        if (child.cmpType === 'MapOLPolygon') {
            this.features.set(child.id, child);
            if (this.map && this.layer) child.setContext(this.map, this.layer);
            return;
        }

        child.addListener(FEATURE_UPDATE, this.featureUpdateHandler);

        // register marker has mouse events listener
        if (typeof child.hasMouseEventOutput === 'function' && child.hasMouseEventOutput()) {
            this.mouseListeners.set(child.id, child);
            this.triggerMouseListenersUpdate();
        }

        this.features.set(child.id, child.formatAsFeature());
        child.triggerFeatureUpdate();
    }

    removeAllChildren() {
        if (this.children && Array.isArray(this.children) && this.children.length > 0) {
            for (let i = this.children.length - 1; i >= 0; i--) this.removeChild(i);
        }

        this.features.forEach((feature) => {
            if (feature !== null) {
                if (feature.cmpType === 'MapOLPolygon') feature.onDelete();
                else if (feature.properties && feature.properties.key !== undefined) {
                    const child = this.assetManager.get(feature.properties.key);
                    if (child && child.cmpType !== 'MapOLPolygon') {
                        child.removeListener(FEATURE_UPDATE, this.featureUpdateHandler);
                    }
                }
            }
        });

        this.mouseListeners.clear();
        this.triggerMouseListenersUpdate();

        this.features.clear();
        this.setFeatures();
        this.triggerFeaturesUpdate();
        this.eventCallback('onRemoveAllChildrens', 'All childrens has been removed');
    }

    removeChild(child) {
        const indexChild = typeof child === 'string' ? this.children.indexOf(child) : child;

        let childRemoved = null;
        if (indexChild !== -1) {
            childRemoved = super.removeChild(indexChild);
        } else {
            childRemoved = this.assetManager.get(child, this.ctx);
        }

        if (childRemoved && childRemoved.id) {
            if (childRemoved.cmpType !== 'MapOLPolygon') {
                childRemoved.removeListener(FEATURE_UPDATE, this.featureUpdateHandler);
            }

            if (this.features.has(childRemoved.id)) {
                this.features.delete(childRemoved.id);
                this.setFeatures();
                this.triggerFeaturesUpdate();
            }

            if (this.mouseListeners.has(childRemoved.id)) {
                this.mouseListeners.delete(childRemoved.id);
                this.triggerMouseListenersUpdate();
            }
            this.eventCallback('onRemoveChildren', childRemoved.id);
        }
        // MapOLPolygon or ?
        else if (typeof child === 'string') {
            if (this.features.has(child)) {
                if (child.cmpType === 'MapOLPolygon') child.onDelete();
                this.features.delete(child);
                this.setFeatures();
                this.triggerFeaturesUpdate();
                this.eventCallback('onRemoveChildren', child);
            }
        }
    }

    setFeatures() {
        const features = Array.from(this.features.values()).filter((feature) => {
            if (feature !== null && feature.type === 'Feature') return feature;
        });
        this.set('features', features);
    }

    updateIfNecessary() {
        if (this.updateRequired === true) {
            this.setFeatures();
            this.triggerFeaturesUpdate();
            this.updateRequired = false;
        }

        requestAnimationFrame(this.updateIfNecessary);
    }

    /**
     * Set if transformation animation is required and update properties accordingly.
     *
     * @param {Object} transform - The feature properties
     */
    featureUpdateTransformHandler(feature) {
        if (feature.properties.transform) {
            const { transform } = feature.properties;
            transform.animate = transform.duration > 0 && transform.props.length > 0;
            // if no animation is required, update all properties values to match user settings.
            // note: keys must match feature.properties keys to be updated properly
            if (!transform.animate) {
                transform.props.forEach(key => {
                    if (key === 'translate') {
                        const coords = [transform.values.to[key].lng, transform.values.to[key].lat];
                        feature.geometry.coordinates = coords;
                    }
                    else feature.properties[key] = transform.values.to[key];
                });
            }
        }
    }

    // Child update handler
    featureUpdateHandler(payload) {
        if (payload && payload.id) {
            const child = this.assetManager.get(payload.id);
            if (child) {
                if (payload.feature && payload.feature.properties) {
                    payload.feature.properties.hasChanged = payload.update || false;
                    this.featureUpdateTransformHandler(payload.feature);
                }
                this.updateFeature(child, payload.feature);
                this.updateRequired = true; // will update at the next request animation frame
            }
        }
    }

    // OpenLayer feature update callback
    onFeatureUpdated(id) {
        if (this.features.has(id)) {
            const feature = this.features.get(id);

            // mark the specified feature unchanged
            if (feature !== null && feature.properties) {
                feature.properties.hasChanged = false;
                feature.properties.transform.animate = false;
            }
        }
    }

    // OpenLayer feature styles update callback
    onFeatureStylesUpdated(id, styles) {
        // @todo: to be set in verbose mode [ticket NS-113]
        const child = this.assetManager.get(id, this.ctx);
        if (child && child.update) {
            for (const prop in styles) {
                child.update(prop, styles[prop]);
            }
        } else console.log('[', child.state.type, '] Callback not found for update styles');
    }

    /**
     * OpenLayer transform animation end callback.
     * @param {string} id - The module idientifier
     */
    onTransformEnded(id) {
        // @todo: to be set in verbose mode [ticket NS-113]
        const child = this.assetManager.get(id, this.ctx);
        if (child && child.onTransformEnded) child.onTransformEnded(id);
        else console.log('[', child.state.type, '] Callback not found for transform animation end');
    }

    /**
     * OpenLayer FollowPath animation end callback.
     * @param {string} id - The module idientifier
     */
    onFollowPathEnded(id) {
        // @todo: to be set in verbose mode [ticket NS-113]
        const child = this.assetManager.get(id, this.ctx);
        if (child && child.onFollowPathEnded) child.onFollowPathEnded(id);
        else console.log('[', child.state.type, '] Callback not found for FollowPath animation end');
    }

    /**
     * OpenLayer FollowPath animation cycle end callback.
     * @param {string} id - The module idientifier
     */
    onFollowPathCycleEnded(id) {
        // @todo: to be set in verbose mode [ticket NS-113]
        const child = this.assetManager.get(id, this.ctx);
        if (child && child.onFollowPathCycleEnded) child.onFollowPathCycleEnded(id);
        else console.log('[', child.state.type, '] Callback not found for FollowPath animation cycle end');
    }

    /**
     * OpenLayer FollowPath animation progression callback.
     * @param {string} id - The module idientifier
     * @param {nulber} index - The path reference's index
     */
    onFollowPathProgression(id, index) {
        // @todo: to be set in verbose mode [ticket NS-113]
        const child = this.assetManager.get(id, this.ctx);
        if (child && child.onFollowPathProgression) child.onFollowPathProgression(index);
        else console.log('[', child.state.type, '] Callback not found for FollowPath animation progression');
    }

    /**
     * Openlayer mouse move event callback.
     * @param {Array} features - all markers that intersect the mouse position on the map viewport.
     * @param {Array} lonlat - the longitude/latitude of the current mouse coordinate in openlayer.
     */
    onMouseMoved(features, lonlat) {
        this.mouseListeners.forEach((listener, id) => {
            // no features means  all markers are leaved
            if (!features) listener.markerMouseEventCallback(id, lonlat, false);
            else {
                // check if the marker exists in the features list
                const over = features.some((feature) => feature && feature.get('key') === id);
                // apply the current mouse state for this marker
                listener.markerMouseEventCallback(id, lonlat, over);
            }
        });
    }

    triggerEvent(eventName) {
        this.set(eventName, true);
        setTimeout(() => {
            this.set(eventName, false);
            this.emit(STATE_CHANGE, this.render());
        }, 100);
        this.emit(STATE_CHANGE, this.render());
    }

    triggerFeaturesUpdate() {
        this.triggerEvent('triggerFeatures');
    }

    triggerMouseListenersUpdate() {
        this.set('haveMouseListeners', this.mouseListeners.size > 0);
    }

    queryMarkersRendered() {
        this.triggerEvent('queryMarkersRendered');
    }

    createCullingZones() {
        this.triggerEvent('createCullingZones');
    }

    currentCullingTagCallback(groupTag) {
        this.emit(EVENT_CALLBACK, { name: 'currentCullingTag', value: groupTag });
    }

    // temporary wrapper to send the parent ol.Map and this ol.layer.Vector
    // to the new OpenLayer context
    mapLayerCreated(map, layer) {
        this.map = map;
        this.layer = layer;
        this.features.forEach((feature) => {
            if (feature.cmpType === 'MapOLPolygon') {
                feature.setContext(this.map, this.layer);
            }
        });
    }
}

export default UiMapOlLayer;
