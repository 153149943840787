var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-items" },
    _vm._l(_vm.projectsList, function(project, index) {
      return _c("project-item", {
        attrs: {
          projectIndex: index,
          project: project,
          currentUser: _vm.currentUser
        },
        on: { "send-indexToProjectItems": _vm.ProjectToModal }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }