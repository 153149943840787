<!-- WidgetManager vue -->

<template lang="pug">
div.widget
	array-row-fields(v-if="bloc.type == 'array-row-field'", :target="target", :bloc="bloc")
	object-includer(v-if="bloc.type == 'object-includer'", :project="project", :target="target", :bloc="bloc", :state="state", :inspectorField='inspectorField')
	state-manager(v-if="bloc.type == 'state-manager'", :project="project", :target="target", :bloc="bloc")
	state-trigger(v-if="bloc.type == 'state-trigger'", :project="project", :target="target", :bloc="bloc")
	node-memories(v-if="bloc.type == 'node-memories'", :project="project", :target="target", :bloc="bloc", :schematicLevel="schematicLevel", :state="state")
	training-set-list(v-if="bloc.type == 'training-set'", :project="project", :target="target", :bloc="bloc")

</template>

<script>

import ArrayRowFields from "./ArrayRowFields.vue";
import ObjectIncluder from "./ObjectIncluder.vue";
import StateManager from "./StateManager.vue";
import StateTrigger from "./StateTrigger.vue";
import TrainingSetList from "./TrainingSetList.vue";
import NodeMemoriesList from "./NodeMemoriesList.vue";

export default {

	props:['project', 'target', 'bloc', 'schematicLevel', 'state', 'inspectorField'],

	components: { ArrayRowFields, ObjectIncluder, StateManager, StateTrigger, TrainingSetList, NodeMemories : NodeMemoriesList },

	data() {
		return {

		}
	},

	created: function(){

	},

	methods:{

	}

}

</script>

<style lang="stylus">


</style>
