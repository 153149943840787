<!--
    Lbrary component
-->
<template lang="pug">

div.library-panel

	div.criteria-bar
		div.arrange-by
			span.button.active
				icon(name="grid")
			span.button
				icon(name="list")
		div.sort(v-bind:class="{'disabled':forceFilter}") Filter by
			select(v-model="currentFilter", v-bind:disabled="forceFilter")
				option(v-for="(values,type) of state.types", v-bind:value="type") {{ type }}
				option(value="all") All types
			icon.arrow(name="arrow-select")
		search-bar(v-on:change="searchResult")

	div.filter
			div.tab.active
				span Ressources
			//div.tab
			//	span SmartObjects
			//div.tab
			//	span Freesound
			//div.tab
			//	span From URL

	div.list
		div.content
			div.add.rounded
				input.file(type="file", id="asset-new-file", @change="uploadFile", class="input-file" multiple)
				label(for="asset-new-file", class="input-file-trigger")
				span.ico

			div.item.rounded(v-for="(media,i) of mediaList", v-on:click="selectMedia(i)", v-bind:class="{'selected':selected==media._id , 'hide':!isInSearchResult(searchTag, media)}", v-show="currentFilter == 'all' || currentFilter == media.assetType && media._deleted !== true")
				div.visu.rounded(v-if="media.assetType == 'image'", v-bind:style="{'background-image':'url('+media.url+')'}")
				icon.visu.rounded(v-else, :name="media.assetType")
				div.name.rounded
					span.url(v-if="!media.filename") {{cleanURL(media.url)}}
					span.url(v-else) {{media.filename}}
				div.actions
					div.button.view(v-on:click.stop="viewMedia(media)")
					div.button.delete(v-if="!isShared(media)", v-on:click.stop="deleteMedia(media)")
				div.shared(v-if="isShared(media)")

		spinner(v-if="waiting")

	div.footer
		div.button(v-if="state.status==state.statusList.LIBRARY_STATE_SELECT", :class="{'enabled':selected}", v-on:click.stop="chooseFile()") Choose media

</template>

<script>
// STORE
import { stores } from '../../stores/MainStore.js';

import actionManager from '../../actions';

import Icon from  '../common/Icon.vue';
import Spinner from  '../common/Spinner.vue';
import SearchBar from  '../common/SearchBar.vue';

export default {

	props: ['project', 'state', 'target', 'workspace'],

	components: { Icon , Spinner, SearchBar },

	data() {
		return {

			selected: null,
			selectedItem: null,

			currentFilter: 'all',
			forceFilter: false,
			searchTag: ''
		}
	},

	computed: {
		mediaList: function () {
			let list = this.state.mediaList;
			let workspaceId = this.workspace.id;

			let libMedias = list.filter( (media) => {
				if( media._deleted !== true) {
					if(media.mediaAddedFrom === workspaceId || !media.mediaAddedFrom){
						return media;
					}
				}	
			})

			return libMedias;
		},
		waiting: function () {
			return this.state.waiting;
		}
	},

	created: function(){
		if( this.state.target && this.state.target.field ){
			if( this.state.mediaId ) this.selected = this.state.mediaId;
		}

		if( this.state.fieldDef && this.state.fieldDef.assetType ){
			this.currentFilter = this.state.fieldDef.assetType;
			this.forceFilter = true;
		}
	},

	methods:{

		refresh: function(){
			actionManager.trigger('main:fetchAssets');
		},

		// closeLibrary: function(){
		// 	actionManager.trigger('main:toggleLibrary', { open : false });
		// },

		selectMedia: function( index ){
			if( this.state.status == this.state.statusList.LIBRARY_STATE_CONSULT )
				return;

			if( this.selected != this.mediaList[index]._id ){
				this.selected = this.mediaList[index]._id;
				this.selectedItem = this.mediaList[index];
			}
			else{
				this.selected = null;
				this.selectedItem = null;
			}
		},

		viewMedia: function( media ){
			actionManager.trigger('library:mediaPreview', { media });
		},

		uploadFile: function( event ){
			var files = event.target.files || event.dataTransfer.files;

			if (!files.length) return;
			
			let fileList = []
			for (const key in files) {
				if (files[key].name) fileList.push(files[key])
			}
			
			actionManager.trigger('library:addMedia', { file: fileList });
		},

		deleteMedia: function (media) {
			actionManager.trigger('main:modal:openModal', {
				title: 'Are you sure?',
				text: 'The action will permanently delete the file.',
				nextAction: 'library:deleteMedia',
				nextActionParam: { media },
				icon: 'delete',
			});
		},

		chooseFile: function(){
			if( this.state.import === true ){
				actionManager.trigger('block:importJson', { mediaId: this.selected , mediaURL : this.selectedItem.url });
			}
			else{
				actionManager.trigger('library:selectAssetForField', { mediaId: this.selected, target: this.state.target, ArrayisBeingUpdated: true});
			}
			actionManager.trigger('main:panel:close')
		},

		cleanURL: function(url) {
			return url ? new URL(url).pathname.replace("/upload/", '') : '';
		},

		searchResult: function(tag) {
			this.searchTag = tag;
		},

		isInSearchResult: function( pattern, media ) {
			if( pattern == '' ) return true;

			const searchRegExp = new RegExp(pattern, 'i');

			if (media.filename && media.filename.search(searchRegExp) != -1 ) return true; // tag found in filename
			if (media.url && media.url.split('/').pop().search(searchRegExp) != -1 ) return true; // tag found in URL

			for (var i = 0; i < media.tags.length; i++) {
				let searchInTag = media.tags[i].search( searchRegExp );
				if( searchInTag != -1 ) return true; // found tag in media tags
			}

			// not found
			return false;
		},

		isShared: function(media) {
			let shared = media.usedby && media.usedby.templates && media.usedby.templates.length >0;
			if (shared && media.usedby.templates[0] === media.mediaAddedFrom) shared = false;
			return shared;
		}
	}

}
</script>

<style lang="stylus">

.library-panel
	.list
		.content
			.add
				input.file
					visibility: hidden
				label
					display: block
					position: absolute
					top: 50px
					left: 0
					right: 0
					bottom: 0
					opacity: 0
					z-index: 20

			.item
				.visu
					display: block
					width: 100%
					height: 100%
					position: absolute
					top: 0
					left: 0
					right: 0
					bottom: 0
					z-index: 1
					background-size: cover
					background-position: center center

				.actions
					.view
						background-image: url(../../assets/images/ico-eye-white.png)
					.delete
						background-image: url(../../assets/images/bt-trash-white.png)

				.shared
					display: inline-block
					position: absolute
					top: 0
					right: 10px
					z-index: 1
					width: 20%
					height: 100%
					background-image: url(../../assets/images/shared-asset.png)
					background-repeat: no-repeat
					background-size: contain






</style>
