import React from 'react';
import NodalUi from './NodalUi';

export default class AdvancedUi extends NodalUi {

    constructor(){
        super();

        this.styleOut = { 
            opacity : null,
            backgroundColor: null,
            color: null
        };

        this.state = {
            isHover : false
        };

        
        this._mouseOverHandler = this.mouseOverHandler.bind(this);
        this._mouseOutHandler = this.mouseOutHandler.bind(this);

        let rollOver = {
            onMouseOver: this._mouseOverHandler,
            onMouseOut : this._mouseOutHandler
        };

        this.otherEvents = Object.assign( {}, this.otherEvents , rollOver );
    }

    mouseOverHandler(){
        this.setState( { isHover : true } );
    }

    mouseOutHandler(){
        this.setState( { isHover : false } );
    }


    setDefaultStyles( nextProps, nextState ){

        super.setDefaultStyles( nextProps, nextState );

        // set styles for UiObjects parameters

        let styles = {};

        if( nextProps.layout && nextProps.layout.overflowHide ){
            styles.overflow = "hidden";
        }
        /*
        let classicOverflow = nextProps.layout && nextProps.layout.overflowHide === "true" ? "hidden" : "auto"; // issue with "auto" value, generate scroll bar almost everywhere...
        */
        
        if( nextProps.layout ){
            if( nextProps.layout.overflowX === true || nextProps.layout.overflowX === 'scroll' )
                styles.overflowX = nextProps.layout.overflowX = 'scroll';
            if( nextProps.layout.overflowY === true || nextProps.layout.overflowY === 'scroll' )
                styles.overflowY = nextProps.layout.overflowY = 'scroll';
        }

        if( nextProps.transform ){
            styles.transform = "";

            if( this.notEmpty( nextProps.transform.scaleX ) )
                styles.transform += 'scaleX('+(nextProps.transform.scaleX/100)+') ';

            if( this.notEmpty( nextProps.transform.scaleY ) )
                styles.transform += 'scaleY('+(nextProps.transform.scaleY/100)+') ';

            if( this.notEmpty( nextProps.transform.rotate ) )
                styles.transform += 'rotate('+(nextProps.transform.rotate)+'deg) ';
            
            if( this.notEmpty( nextProps.transform.translateX ) ){
                let transX = nextProps.transform.translateX == "auto" ? "0px" : nextProps.transform.translateX;
                styles.transform += 'translateX('+(transX)+') ';
            }

            if( this.notEmpty( nextProps.transform.translateY ) ){
                let transY = nextProps.transform.translateY == "auto" ? "0px" : nextProps.transform.translateY;
                styles.transform += 'translateY('+(transY)+') ';
            }

            if ( this.notEmpty( nextProps.transform.transitionDuration ) )
                styles.transitionDuration = nextProps.transform.transitionDuration + 's';

            if ( this.notEmpty( nextProps.transform.transitionEase ) )
                styles.transitionTimingFunction = nextProps.transform.transitionEase;

            if ( this.notEmpty( nextProps.transform.transitionProperty ) )
                styles.transitionProperty = nextProps.transform.transitionProperty;

            if ( this.notEmpty( nextProps.transform.transitionDelay) )
                styles.transitionDelay = nextProps.transform.transitionDelay + 's';    
            
            let originX = this.notEmpty( nextProps.transform.transformOriginX ) ? nextProps.transform.transformOriginX : '0px';
            let originY = this.notEmpty( nextProps.transform.transformOriginY ) ? nextProps.transform.transformOriginY : '0px';

            styles.transformOrigin = originX+" "+originY;

        }


        if( nextProps.hover && nextState.isHover ){
           /* let styleHover = {};
            styleHover.opacity = nextProps.hover.hoverOpacity;

            if( nextProps.hover.hoverBackgroundColor )
                styleHover.backgroundColor = nextProps.hover.hoverBackgroundColor;
        
            this.forcedStyle = Object.assign( {}, this.forcedStyle , styleHover );
            */
        }
        else{
            delete this.forcedStyle.opacity;
            delete this.forcedStyle.backgroundColor;
        }


        // Mask
        styles.clipPath = null;
        styles.WebkitClipPath = null;
        styles.maskImage =  styles.WebkitMaskImage = null;
        styles.maskSize =  styles.WebkitMaskSize = null;

        if( nextProps.mask && nextProps.mask.applyMask ){

            if( nextProps.mask.maskImage && nextProps['maskImage__url'] ){

                let url = nextProps['maskImage__url'];

                styles.maskImage = 'url('+url+')';
                styles.WebkitMaskImage = 'url('+url+')',
                styles.maskSize = nextProps.mask.maskImageSize;
                styles.WebkitMaskSize = nextProps.mask.maskImageSize;

            }

            let maskWidth = nextProps.mask.maskZoneWidth;
            let maskHeight = nextProps.mask.maskZoneHeight;

            if( maskWidth + maskHeight !== 200 ){

                let fromTop, fromRight, fromBottom, fromLeft;

                switch( nextProps.mask.maskZoneAnchor ){
                    case "tl" :
                        fromTop = 0;
                        fromLeft = 0;
                        fromRight = 100 - maskWidth;
                        fromBottom = 100 - maskHeight;
                        break;
                    case "tr" :
                        fromTop = 0;
                        fromRight = 0;
                        fromLeft = 100 - maskWidth;
                        fromBottom = 100 - maskHeight;
                        break;
                    case "bl" :
                        fromBottom = 0;
                        fromLeft = 0;
                        fromRight = 100 - maskWidth;
                        fromTop = 100 - maskHeight;
                        break;
                    case "br" :
                        fromBottom = 0;
                        fromRight = 0;
                        fromLeft = 100 - maskWidth;
                        fromTop = 100 - maskHeight;
                        break;
                    case "c" :
                        fromRight = ( 100 - maskWidth ) * 0.5 ;
                        fromLeft = ( 100 - maskWidth ) * 0.5 ;
                        fromTop = ( 100 - maskHeight ) * 0.5 ;
                        fromBottom = ( 100 - maskHeight ) * 0.5 ;
                        break;

                }

                styles.clipPath = "inset("+fromTop+"% "+fromRight+"% "+fromBottom+"% "+fromLeft+"%)";
                styles.WebkitClipPath = "inset("+fromTop+"% "+fromRight+"% "+fromBottom+"% "+fromLeft+"%)";

            }   
        }
        
        this.defaultStyle = Object.assign( {}, this.defaultStyle, styles );


    }

}