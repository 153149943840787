<!-- Navigator Item vue -->

<template lang="pug">
div.navigator-ui-item

    // simple object or simple container
    div.accordion-bloc(v-on:click="openEditor()", v-bind:id="uiobject.value._id", v-bind:class="{'active':isActive, 'selected':isSelected, 'deprecated': isDeprecated}")
        div.zone
        icon(:name="uiobject.value.type+'-'+uiobject.value.format")
        input.invisibleSimple(v-model="uiobject.value.name")


        div.action-bts(v-bind:class="{'arrow-space':hasChildren}")
            div.action-bt.bt-trash(v-if="uiobject.value.format != 'ui-screen'", v-on:click.stop.prevent="deleteNode()")
            div.action-bt.bt-duplicate(v-if="uiobject.value.format != 'ui-screen'", v-on:click.stop.prevent="duplicateNode()")
            div.action-bt.bt-inspector(v-on:click.stop.prevent="openInspector()", v-bind:class="{'active': inspectorActive}")

        div.inspector-open-bts(v-bind:class="[{'arrow-space':hasChildren}, {'active': inspectorActive}]")
            div.action-bt.bt-inspector

        span.ico-arrow(v-if="hasChildren", v-bind:class="{'close': !accordion.main}", v-on:click.stop="switchAccordion('main')")

    div.accordion-ctn(v-show="accordion.main", v-bind:class="{'active':isActive}")

        // multiple children
        div(v-if="nbSection > 1")
                div(v-for="(section,i) in sections")
                    div.accordion-bloc
                        div.zone
                        icon(name="section")
                        span Section {{i+1}}
                    div.accordion-ctn
                        div(v-for="subobj in section")
                            //transition-group(type="transition" :name="'flip-list'"  :key="navigator-ui-item")
                            navigator-ui-item(:uiobject="subobj", :app-state="appState", :main="main")

        div(v-else)
            draggable(v-model="objectToDisplay", tag="div" @start="isDragging=true" @end="isDragging=false" :disabled="appState.editor.uiState ? false : true")
                div(v-for="(subobj, index) in objectToDisplay")
                    //transition-group(type="transition" :name="'flip-list'" :key="navigator-ui-item")
                    navigator-ui-item(:uiobject="subobj", :app-state="appState", :main="main")

</template>

<script>

import ItemUI from  './NavigatorUiItem.vue';
import Icon from  './Icon.vue';

import draggable from 'vuedraggable'

import actionManager from '../../actions';
import * as helper from '../../helper';

export default {

	name: 'navigator-ui-item',

	components: { itemUi : ItemUI, Icon, draggable},

	props: ['uiobject', 'appState', 'main'],

	data() {
		return {
            inspectorObj : null,
            nbSection: -1,
            childrenField : null,
            isDragging: false
		}
	},

    watch: {
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true;
                return;
            }
            this.$nextTick(() => {
                this.delayedDragging = false;
            });
        },

    },
    computed: {
        accordion: function(){
			if( this.uiobject && this.uiobject.value && this.uiobject.value.accordion ) return this.uiobject.value.accordion;

			actionManager.trigger('inspector:updateAccordion', { targetId : this.uiobject.value._id, type : 'main' , state: true });

			return { main : true };
		},

        hasChildren: function(){

            let result = false;

            if( this.inspectorObj.value.nbSection >= 2 )
                return true;

            if( this.uiobject.custom.objects && this.uiobject.custom.objects.length > 0 )
                result = true;
            else
                result = false;

            return result;
        },

        isDeprecated: function(){
          if( this.inspectorObj.value.deprecated)
              return true;
        },

        sections: function( ){
			if( this.uiobject.custom.objects || this.nbSection > 1){
                let self = this;

                let order = [];
				this.childrenField.value.forEach( (child) => { order = order.concat( child ); });

                let sections = [];
                for( let i = 0 ; i < this.nbSection ; i++ ){

                    if( this.uiobject.custom.objects ){
                        sections[i] = this.uiobject.custom.objects.filter( function(obj){
                            if( this.childrenField.value[i] && this.childrenField.value[i].indexOf( obj.value._id ) != -1 )
                                return obj;
                            return null;
                        }.bind(this) );

                        sections[i].sort( function(a, b){ return order.indexOf( a.value._id ) - order.indexOf( b.value._id ); }.bind(this));
                    }
                    else{
                        sections[i] = [];
                    }

                }



                return sections;
			}
			return null;
        },

        objectToDisplay: {
            get: function() {
                if( this.appState.project && this.uiobject.custom.objects ){
                    return this.uiobject.custom.objects.filter( (obj) => { if( !obj.value._deleted ) return obj; });
                }
                return null;
            },
            set: function(newValue) {
                if (newValue) {
                    const childrenOrder = newValue.map((obj) => obj.value._id)
                    this.uiobject.custom.objects = newValue
                    actionManager.trigger('editor:ui:UpdateUiFields', { parentUi: this.uiobject, newOrderChilren: newValue })
                    actionManager.trigger('editor:ui:updateUiState', { parentId: this.uiobject.value._id, childrenOrder })
                }
                return newValue
            }
        },

		inspectorActive: function(){
			if( this.appState.inspector && this.appState.inspector.targetId == this.uiobject.value._id && this.appState.main.inspectorOpen )
				return true;
			return false;
		},

		isActive: function(){
			if( this.appState.editor && this.appState.editor.targetId == this.uiobject.value._id )
				return true;
			return false;
        },

        isSelected: function(){
			if( !this.isActive && this.appState.editor && this.appState.editor.targetLayoutId == this.uiobject.value._id )
				return true;
			return false;
        }

    },

	created: function(){
        this.inspectorObj = helper.config.getConfigByType( this.appState.config, 'object', this.uiobject.value.format );
        this.nbSection = this.inspectorObj.value.nbSection;
        this.childrenField = helper.block.getField( this.uiobject , 'children' );

        if (this.childrenField) {
            let order = [];

            this.childrenField.value.forEach( (child) => { order = order.concat( child ); });
            this.uiobject.custom.objects.sort( function(a, b){ return order.indexOf( a.value._id ) - order.indexOf( b.value._id ); }.bind(this));
        }

	},

	methods:{
        objectToGetTest: function() {
            return this.objectToDisplay
        },

		openInspector: function(){
            // ajouter le default ref pour pouvoir rebuilder sur le load de l'inspecteur
            actionManager.trigger('inspector:load', {targetId: this.uiobject.value._id });
            actionManager.trigger('workspace:update', { id: this.uiobject.value._id, target: this.uiobject.value.level });
		},

		openEditor: function(){
            // always main object (the top level ui object of this hierarchie, an ui-screen for example)
            actionManager.trigger('editor:openEditor', { id: this.main.value._id })
            actionManager.trigger('editor:ui:select', { id: this.uiobject.value._id })
            actionManager.trigger('workspace:update', { id: this.uiobject.value._id, target: this.uiobject.value.level });

		},

		duplicateNode: function() {
			actionManager.trigger('block:copy', {blocks: [this.uiobject.value._id], parentId: this.uiobject.custom.uiParent, duplicate: true});
		},

		deleteNode: function(){
            let blocIsUsedBy = helper.block.blockIsUsedIn(this.appState.project, this.uiobject.value._id)
            actionManager.trigger('main:modal:openModal', {project: this.appState.project, config: this.appState.config, title:'Are you sure you want to delete this block?', text:'You will lost definitely all attached data', isUsedBy: blocIsUsedBy, nextAction:'block:deleteBlock', nextActionParam: { id :  this.uiobject.value._id }, icon: 'delete' })

            // DEAD CODE ???
            /*
            let parent = stores.block.getEntityByIDInWholeProject( this.uiobject.custom.uiParent );
            let parentChildren = stores.block.getField( parent , 'children' );

            if( parentChildren ){
                stores.ui.deleteFrom( this.uiobject.value._id, parent.value._id );
                parentChildren.value = stores.objects.deleteIDFromChildrenField( parentChildren.value , this.uiobject.value._id );
			    stores.block.deleteBloc( this.uiobject );
            }
            */

		},

		switchAccordion: function( type ){
			let current = this.accordion && this.accordion[type] !== undefined ? this.accordion[type] : true;
			actionManager.trigger('inspector:updateAccordion', { targetId : this.uiobject.value._id, type : type , state: !current  });
		}

	}
}

</script>

<style lang="stylus">

.zone
    background: var(--selectedColor)
    opacity: 0
    position: absolute
    top: 0
    left: 0
    right: 30px
    height: 40px
    border-radius: 0 20px 20px 0
    box-shadow: 0 0 10px rgba(0,0,0,.2)
    z-index: -1
    transition: linear all .1s !important 

.nav
    .arbo
        .accordion-bloc.active
            .zone
                opacity: 1


</style>
