<!-- Textarea vue -->

<template lang="pug">

div
	div.field-ctn.field-area
		div.clear
	div.clear
	textarea.value(v-model="value", v-bind:placeholder="placeholder", v-on:focusout="valueUpdated(field)", :disabled="field.disabled")

	
</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue', 'placeholder' ],

	data() {
		return {
			value: null
		}
	},

	created: function(){
		this.value = this.fieldvalue ? this.fieldvalue.value : "";
	},

	destroyed: function(){

	},

	watch:{

	},

	methods:{

		valueUpdated: function( fieldUpdated ){
			this.$parent.valueUpdated( fieldUpdated, this.value );
		}


	}

}

</script>

<style lang="stylus">


.input-fields.groupfield.textarea-outputs
	background: transparent
	position: absolute
	top: 0
	right: 0px

.field-ctn.field-area
	height: 45px!important
	background: transparent !important

</style>