<!-- Modal vue -->

<template lang="pug">
div#downloadProjectTab(v-if="download !== undefined")
    div.mainOptDownload
        div.left
            p Please select the differents options that you would like to include or exclude in your download.
            p An archive will be generated allowing you to use it directly on your computer with the selected elements.
        div.right
            div.options(v-for="(opt, key) in this.options")
                span.choiceOpt.plug.checkbox(v-bind:class="{'disabled': opt.disabled,'selected':opt.selected }",v-on:click="updateOptions(key)")
                    input(type="checkbox", v-model="opt.selected")
                p {{opt.name}}

            p.error(v-if="responseLogs")
                strong {{responseLogs}}
    div.action
        div.button.confirm(v-on:click.once="validate")
            p Download
</template>

<script>
import Icon from '../common/Icon.vue'
import actionManager from '../../actions';

export default {

	// settings	the modal configuration (ie. app.state.modal)
	props: {
		download: { type: Object },
	},

	components: {
		Icon
	},

    data() {
        return {
			value: '',
			default: [],
			responseLogs: null,

            options: {
                assets: {
                    name: "Static media",
                    active: true,
                    desactivated: false,
                    selected: false,
                },
                dataProject: {
                    name: "Data Project",
                    active: true,
                    desactivated: false,
                    selected: true,
                },
                player: {
                    name: "Player",
                    active: true,
                    desactivated: false,
                    selected: true,
                },
                logs: {
                    name: "Logs",
                    active: false,
                    desactivated: false,
                    selected: true,
                },
            },
        }
    },

	created: function() {
    },

	methods: {

		updateOptions: function(opt){ 
            this.options[opt].selected = this.options[opt].selected ? false : true
		},

        validate: function(){
            let selectedOptions = () => {
                let selec = []
                for (const key in this.options) {
                    if (this.options[key].selected) selec.push(key)
                }
                return selec.join(",")
            }

            actionManager.trigger('project:download', {
                experience: this.download.experienceName,
                options: selectedOptions(),
                nextAction:'main:modal:closeModal', 
            });
        }

    }
}
</script>

<style scoped>
#downloadProjectWraper {
    width: 100%;
}
.mainOptDownload {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}
.left, .right {
    width: 50%;
    justify-content: center;
    align-self: center;
    padding: 5px;
}

#downloadProjectTab .typeSelector{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: auto;
    border: 0;
    margin: 0;
    padding: .75em;
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.options {
    display: flex;
    flex-flow: row nowrap;
}

.choiceOpt{
    margin: 10px;
}
</style>
<style lang="stylus" scoped>
.action
    .button
        font-family: 'DinBold'
        display: inline-block
        text-align: center
        color: white
        background-color:var(--selectedColor)
        width: 160px
        height: 20px
        padding: 8px 10px
        border: none
        border-radius: 40px
        cursor: pointer
        font-size: 16px
        margin: 4px
        opacity: 0.5
        transition: linear all .1s !important

        &.nofocus
            opacity: 0.3
        &.button:hover
            opacity: 1

        &:disabled
            opacity: 0.3
            cursor: inherit
        p
            margin: 0
            padding: 0
        &.isUsedBy
            opacity: 0.3
            cursor: inherit
            pointer-events: none;
</style>