var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayGroup
    ? _c(
        "div",
        {
          staticClass: "block-group",
          class: [{ open: _vm.open }, "level" + _vm.level]
        },
        [
          _c(
            "ul",
            [
              _c(
                "li",
                { staticClass: "title" },
                [
                  _c("icon", { attrs: { name: _vm.group.icon } }),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.group.title))
                  ])
                ],
                1
              ),
              _vm._l(_vm.group.children, function(subgroup) {
                return _c("group-bloc", {
                  attrs: { group: subgroup, level: _vm.level + 1 }
                })
              }),
              _vm._l(_vm.group.components, function(component) {
                return _c("bloc-item", {
                  attrs: { component: component, workspace: _vm.workspace }
                })
              })
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }