
<template lang="pug">

div#content
    h1 Node Screen

    h2 Presentation
    p A Screen is one step of the experience. It contains an inspector with various parameters.

    p Each screen contains two layers :
        ul.listing
            li <strong>A HTML View</strong> which opens the screen editor to design the graphics of the screen.
            li <strong>A Logic Layer</strong> that contains Processes and Triggers to design the interactivity and behavior.

    p The user can build the experience by associating and setting different screens.<br /> Different types of screens and modules are available at the screen level :
        ul.listing
            li Screen : Regular screen that can be designed.
            li Widget : Graphic elements that can be reused in different screens.
            li Process : Logical modules that create a common behaviors to several screens.

    h2 Parameters
    table#params
        tr
            td#col1 <strong>Name</strong>
            td#col2 String
        tr
            td#col1 <strong>Owner</strong>
            td#col2 User who create the project

        tr
            td#col1 <strong>User(s)</strong>
            td#col2 List of users who can access the project with specific rights (view only, edit, admin)

    h2 Actions
    table#params
        tr
            td#col1 <strong>Delete</strong>
            td#col2 Delete the process
        tr
            td#col1 <strong>Duplicate</strong>
            td#col2 Create a copy of the process
        tr
            td#col1 <strong>Share</strong>
            td#col2 Share the process with specific rights (view only, edit, admin)
        tr
            td#col1 <strong>Export</strong>
            td#col2 Export the process in JSON

    h2 Settings
    h3 Process triggering
    p A process can be triggered automatically when its parent screen is played, or with a specific trigger added in the authoring

    h3 Memory
    p A shared memory is accessible to all the users of the project.
        ul.listing
            li Angle
            li Array (A specific interface allows to parameter the array
            li Asset (It can be chosen via the Library)
            li Boolean
            li Color
            li Float
            li Integer
            li Percent
            li String
            li Vector

    p A persistent memory is kept from one session to another.

    div#back
        a(v-on:click.stop="displayTab('nodes')") &larr; Back


    p <br /><br /><br />

</template>

<script>

	export default {


		props:['displayTab'],

		data() {
			return {
			};
		},

	    methods:{


	    }

    }

</script>

<!-- FIXME: use pure CSS style in webpack.config.js (see below)-->
<style lang="stylus">
.listing
    margin-left: 20px;
</style>

<!-- ORIGINAL CSS
<style>
.listing{
    margin-left: 20px;
}
</style>
-->
