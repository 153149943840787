<!-- Secret vue -->

<template lang="pug">

span.textinput-ctn(v-if="checkRights()")
	span.secret-eye-icon(v-on:click="switchVisibility()")
	input.value(type="password" ref='inputPassword' v-model="value", v-bind:placeholder="placeholder", v-on:focusout="valueUpdated(field)", :disabled="field.disabled")
</template>

<script>
import { stores } from '../../../stores/MainStore.js';

export default {

	components: { },

	props:[ 'field' , 'fieldvalue', 'placeholder', 'user'],

	data() {
		return {
            value: null,
            fieldType: 'password'
		}
	},

	created: function(){
		this.value = this.fieldvalue ? this.fieldvalue.value : "";
	},

	watch:{
		fieldvalue: function (obj) {
			this.value = obj ? obj.value : "";
		}
    },

    computed: {
        memoryIsKnown: function(){
            if(this.value === "Value not available"){
                return "text"
            } else {
                return "password"
            }
        }
    },

	methods: {
        checkRights(){

            if(this.$root.$children[0].state.project.currentUserType !=="owner") return false //NS-1176 empecher l'utilisateur non owner d'avoir accès à la mémory
            else return true
        },
		valueUpdated: function(fieldUpdated){
			this.$parent.valueUpdated(fieldUpdated, this.value);
        },

        switchVisibility: function() {
            this.fieldType = (this.fieldType === 'password') ? 'text' : 'password';
            this.$refs.inputPassword.setAttribute('type', this.fieldType);
        },
	},
}

</script>

<style lang="stylus">
    .secret-eye-icon
        background-image: url('../../../assets/images/icon-eye.svg')
        background-size: contain
        background-repeat: no-repeat
        position: absolute
        height: 100%
        width: 20%
        opacity: 0.4
        margin-left: -37px
        &:hover
            cursor: pointer
            opacity: 1
</style>