import React from 'react';
import NodalUi from '../NodalUi';
import Slider, { Range } from 'rc-slider';
import Tooltip from 'rc-tooltip';

const Handle = Slider.Handle;

const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
};


export default class InputSliderField extends NodalUi {
    constructor() {
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            display : 'block',
        });

        this.state = { value: null };
        this._valueChangeHandler = this._valueChangeHandler.bind(this);
    }

    setDefaultStyles( nextProps, nextState ) {

        super.setDefaultStyles( nextProps, nextState );

    }

    componentWillMount(){
        super.componentWillMount();
        //this.setState({value : defaultVal});
    }

    _valueChangeHandler(value) {
        this.setState({ value });

        if (this.props.type === 'slider') {
            this.eventHandler('value', value);
        } else {
            this.eventHandler('range', { x: value[0], y: value[1] });
        }
    }

    render() {
        let slider = null;
        let tooltip = this.props.label === true ? { handle : handle } : null;
        
        // STYLES
        let styles = {
            trackStyle: [ {
                backgroundColor: this.props.style.trackColor
            }],
            railStyle : {
                backgroundColor: this.props.style.railColor
            },
            dotStyle : {
                backgroundColor: this.props.style.dotsColor,
                borderColor: this.props.style.dotsStrokeColor
            },
            handleStyle : [{ backgroundColor: this.props.style.activeDotsColor,   borderColor: this.props.style.activeDotsStrokeColor }, { backgroundColor: this.props.style.activeDotsColor,   borderColor: this.props.style.activeDotsStrokeColor }]
        };
        
        // MARKS
        let marks = {}
        if( this.props.marks !== undefined && Array.isArray(this.props.marks) && this.props.marks.length > 0 ){
            this.props.marks.forEach( (mark) => {
                marks[mark[0].value] = { label : mark[1].value };
                if( mark[2] !== null && mark[2].value !== null && mark[2].value.hex !== undefined )
                    marks[mark[0].value].style = { color : mark[2].value.hex };
            });
        }   

        let className = this.props.slider.showMarkOnlyActivated === true ? 'onlyactivated' : '';

        if (this.props.type === 'slider') {
            // If value is set from the module's input
            if (this.props.slider.value) {
                this.state.value = this.props.slider.value;
                delete this.props.slider.value;
            }

            if ('value' in this.props.slider && this.props.slider.value === undefined) {
                delete this.props.slider.value;
            }

            slider = <Slider className={className} {...styles} value={this.state.value} {...this.props.slider} marks={marks} onChange={this._valueChangeHandler} {...tooltip}  />
        } else {
            const defaultVal = this.props.ranged && this.props.ranged.defaultValueRange ? [ this.props.ranged.defaultValueRange.x , this.props.ranged.defaultValueRange.y ] : [0, 100];
            delete this.props.slider.defaultValue;
            delete this.props.slider.value;

            // If value is set from the module's input
            if (this.props.ranged.range) {
                this.state.value = [ this.props.ranged.range.x, this.props.ranged.range.y ];
                delete this.props.ranged.range;
            }

            slider = <Range className={className} {...styles} value={this.state.value} {...this.props.slider} marks={marks} defaultValue={defaultVal} onChange={this._valueChangeHandler} {...tooltip} />
        }

        return this.wrapHelper(
            <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                {slider}
            </div>
        );
    }
}
