import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import Vue from 'vue';

class UpdateCompositeName extends ActionAbstract {

    /**
     * @param blocId :  id of block to update the composite name
     * 
    **/

    static get ID() {
        return 'actions:block:updateCompositeName';
    }

    run(state) {
        
        let blockPath;
        [this.block, blockPath] = helper.block.getBlockById( state.project, this.getParam('blocId'), true );

        if( !this.block ) return null;

        let pattern = helper.config.getConfigByType( state.config , this.block.value.type );


        if( !pattern || pattern.value.compositeName === undefined || pattern.value.compositeName === null ){
            return null;
        }

        let compositeName = "";
        pattern.value.compositeName.forEach( (param) => {
            
			let inspectorField = this.deps.mainStore.authoring.findFieldInInspector( pattern.value.inspector , param );
            let field = helper.block.getValueOfField(this.block, inspectorField );
            if( field && field.value )
                compositeName += this.capitalizeFirstLetter(field.value)+" ";
        });

        if( compositeName !== "" )
            compositeName  += "- "; 

        let compose = { project: {} };
        compose.project[blockPath + '.custom.compositeName'] = compositeName;

        return this.composeState(state, compose);     
    }


    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }





}

export default UpdateCompositeName;