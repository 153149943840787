import InspectorActionAbstract from './InspectorActionAbstract';

class UpdateAccordion extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateAccordion';
    }

    /**
     * @param targetId
     * @param type
     * @param state
     */

    run (state) {
        let composite = {project: {}};

        if( !this._target.value.accordion ) composite.project[this._path + '.value.accordion'] = {};

        composite.project[this._path + '.value.accordion.'+this.getParam('type')] = this.getParam('state');

        return this.composeState(state, composite);
    }
}

export default UpdateAccordion;