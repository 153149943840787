import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import Vue from 'vue';

class CreateBlock extends ActionAbstract {

    /**
     * @param pattern : block pattern to create
     * @param parentId : (optional) id of parent , default : appState.editor.target id
     * @param section : (optional) for ui objects only , index of the sub section
     *
     * @param block :  (when success) block added in database
     * @param parent :  (when success) parent of the new block
     *
    **/

    static get ID() {
        return 'actions:block:createBlock';
    }

    run(state) {
        this.create(state);

        return this.composeState(state, {
            isLoading : true
        });
    }

    runSuccess(state) {

        let compositeState = { project: {} };

        let parentId = this.getParam('parentId') || state.editor.targetId;
        let parentPath, parent;
        [parent, parentPath] = helper.block.getBlockById( state.project, parentId, true );

        if( !parent.custom ){
            compositeState.project[parentPath+'.custom'] = {};
        }

        if( !parent.custom.children ){
            compositeState.project[parentPath+'.custom.children'] = [];
        }

        let newBlock = this.getParam('block');
        let pattern = this.getParam('pattern');

        /// FOR OBJECTS
        if( ( newBlock && newBlock.value && newBlock.value.type === "object" ) || (pattern && pattern.type === 'object' ) ){

            // add a reference to the parent id in the child
            let tag = newBlock.value.format.substr(0, newBlock.value.format.indexOf('-'));
            newBlock.custom.tag = tag;

            if( tag == 'ui' ) {

                // Abord or manage creation if a restriction is found
                let childConf = this.deps.mainStore.ui.getUIObjectConfig(newBlock);
                let parentType = this.deps.mainStore.ui.get(parentId).cmpType;

                // TODO: prefers use accept children types instead of require parent types
                if (childConf.requireUiParentTypes && childConf.requireUiParentTypes.indexOf(parentType)==-1) {

                    let parents = this.deps.mainStore.block.getParentsID(parent);

                    // TODO: create dependencies if needed
                    this.trigger('main:modal:openModal', { title: "Creation aborted", text: "The UI parent does not match. This module require one of the following parent: '" + helper.ui.listParentTypes(childConf.requireUiParentTypes) + "'", continueButton: 'OK', cancelButton: '', icon: 'error' })
                    return this.composeState(state, {
                        isLoading: false
                    });
                }


                // add helper to get easilly to ui parent
                newBlock.custom.uiParent = parentId;

                // for ui object, need to add id bloc into parent's 'children' field
                let currentInspector = helper.config.getConfigByType( state.config, 'object' , parent.value.format );
                let parentNbSection = currentInspector.value.nbSection;


                let childrenField, childrenFieldIndex;
                [childrenField, childrenFieldIndex] = helper.block.getField(parent, 'children', true);


                let section = this.getParam('section');

                // for multiple section parent, need to add this id into the correct sub array of children list
                if( parentNbSection > 1 ){
                    let randSection = Math.floor( Math.random()*parentNbSection );

                    section = ( section !== null ) ? section : randSection;

                    if( !childrenField.value[section] )
                        compositeState.project[parentPath + '.value.fields[' + childrenFieldIndex + '].value['+ section +']'] = [];

                    compositeState.project[parentPath + '.value.fields[' + childrenFieldIndex + '].value['+ section +']'] = this.arrayPush( newBlock.value._id );

                }
                else{
                    compositeState.project[parentPath + '.value.fields[' + childrenFieldIndex + '].value'] = this.arrayPush( newBlock.value._id );
                }

                this.deps.mainStore.ui.createUIObject( newBlock );
                this.deps.mainStore.ui.addChildTo( newBlock.value._id ,  parentId , section );
            }

            //compositeState.project[parentPath+'.custom.objects'] = this.arrayPush( newBlock );
            if( ! parent.custom.objects ) {
                Vue.set( parent.custom, 'objects', [] );
            }

            Vue.set( parent.custom.objects , parent.custom.objects.length, newBlock );
        }
        else{
            if( pattern )
               this.trigger('block:createAssociatedObject', { pattern : this.getParam('pattern') , block : newBlock });
            this.trigger('block:updateFields', { id : newBlock.value._id } );

            compositeState.project[parentPath+'.custom.children'] = this.arrayPush( newBlock );
        }

        compositeState.isLoading = false;

        // close block menu
	      this.trigger('inspector:load', {targetId : newBlock.value._id });
        this.trigger('editor:menu:toggleMenu', {status: false});

        return this.composeState(state, compositeState);

    }

    create(state){

        let pattern = this.getParam('pattern');
        let targetId = state.editor.targetLayoutId || state.editor.targetId;
        let parentId = this.getParam('parentId') || targetId;
        let bloc = helper.config.getConfigByType(state.config, pattern.type, pattern.format);
        let parent = helper.block.getBlockById(state.project, parentId);

        let doc = null;

        if (pattern.type == 'object') doc = this.deps.mainStore.objects.generateObjectBlockDoc(bloc, parent);
        else doc = this.deps.mainStore.block.generateNewBlockDoc(bloc, parent, pattern);

        this.deps.mainStore.block.createNewDoc(doc)
            .then(function (newBlock) {
                //console.log('parentId', parent.value._id)
                this.trigger('block:createBlock', { subaction: 'success', block: newBlock, parentId: parent.value._id, pattern: pattern, section: this.getParam('section') });
            }.bind(this));
    }


}

export default CreateBlock;
