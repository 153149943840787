<!-- Memory vue -->

<template lang="pug">
div.accordion.targets-bloc
	div.accordion-title(v-on:click.stop="accordion = !accordion ")
		span.text Home screen
		span.arrow(v-bind:class="{'close':!accordion}")

	div.form-content(v-bind:class="{'open':accordion}")

		div.form-accordion.isAccordion()

			div.title 
				span.text Screen Selection
				
			div.form-accordion-content.open.homeScreen
				span.textinput-ctn.trigger-select
					span.select-arrow &gt;
					select(v-on:change="changeScreen()", v-model="selectedScreenId")
						option(v-for="(screen,id) in screensList", v-bind:value="screen.id", ) {{ screen.name }}

		div.clear(style="height:10px")

</template>

<script>

import actionManager from '../../../actions';

export default {

	props:[ 'target' ],

	data() {
		return {
			accordion: true,
			selectedScreenId: null
		}
	},

	computed: {
		screensList: function () {
			let screens = [];
			if (this.target.custom.children) {
				this.target.custom.children.forEach( (child) => {
					if (child.value.type === "screen") {
						screens.push({id: child.value._id, name: child.value.name});
					}
				});
			}
			return screens;		
		}
	},

	created: function(){

		if (this.target.value.homeScreenId) {
			this.selectedScreenId = this.target.value.homeScreenId
		}
		else{
			if( this.screensList && this.screensList.length > 0 ){
				this.selectedScreenId = this.screensList[0].id;
				actionManager.trigger('inspector:updateHomeScreen', { targetId: this.target.value._id, screen: this.selectedScreenId });
			}
		}

	},

	methods:{

		changeScreen: function(){
			actionManager.trigger('inspector:updateHomeScreen', { targetId: this.target.value._id, screen: this.selectedScreenId });
		},

	}

}

</script>

<style lang="stylus">
.targets-bloc
	font-size: 12px
	
	span.col
		float:left
		display: inline-block
		width: 33%
		text-align: left
		line-height: 40px
		height: 40px
		color: var(--inspectorText)
		vertical-align: middle

		&.col1
			width: 70%

		&.col2
			width: 30%
			text-align: right

	.tab-title
		.col
			line-height: 29px
			
	.target-line
		position: relative
		
		.param-ctn
			top: 0px
			right: 2px
			
			.checkbox
				margin-top: 5px !important
				margin-right: 3px !important
				margin-right: 12px
					
.trigger-select
	
	display: block
	width: 100% !important
	height: 40px
	margin: 10px

	.select-arrow
		display: inline-block
		position: absolute
		top: 6px
		right: 7px
		font-family: 'DinRegular', 'ComfortaaLight'
		font-size: 13px
		transform: rotate(90deg)

	select
		background-color: var(--inputValue)
		width: 100%
		color: var(--inputTextColor) !important
		text-transform: capitalize
		font-family: 'DinRegular', 'ComfortaaLight'
		font-size: 14px
		border-radius: 2px
		text-align: right
		display: inline-block
		height: 24px
		vertical-align: top
		-webkit-appearance: none
		-moz-appearance: none
		appearance: none
		text-align: right
		padding-left: 4px
		padding-right: 20px
		position: relative
		margin-left: 0

	.textinput-ctn
		width: 100%

	input.value
		background-color: transparent
		width: 95%
		color: #58585B !important
		font-family: 'DinRegular', 'ComfortaaLight'
		font-size: 12px
		border: 1px solid white
		border-radius: 2px
		text-align: right
		display: block
		height: 20px
		vertical-align: top
		text-align: right
		padding-right: 3%

	.field-ctn span.text
		height: 23px
		line-height: 23px


</style>



