var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { staticClass: "lines" }, [
    _c(
      "g",
      {
        staticClass: "line-styler",
        style: {
          transform:
            "translate(" +
            _vm.scene.coordinate.x +
            "px , " +
            _vm.scene.coordinate.y +
            "px ) scale(" +
            _vm.scene.scale +
            ")"
        }
      },
      [
        _vm._l(_vm.links, function(link) {
          return _c("bloc-link", {
            attrs: { "app-state": _vm.appState, link: link }
          })
        }),
        _c("path", { staticClass: "line", attrs: { d: _vm.linkingPath } })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }