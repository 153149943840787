<template lang="pug">
	div.file-ctn
		icon.json(v-if="isJSON", name="jsonWatermark")
</template>

<script>
	import Icon from '../common/Icon.vue';

	export default {
		props: ['media'],
		components: { Icon },
		computed: {
			isJSON() {
				return (
					this.media &&
					this.media.mimeType &&
					this.media.mimeType === 'application/json'
				);
			},
		},
	}
</script>

<style lang="stylus">
	.file-ctn
		display: flex
		width: 100%
		height: 100%

		.json
			width: 50%
			height: 50%
			margin: auto
			transform: translateY(0);
			background-color: var(--inspectorTargetColor)
</style>