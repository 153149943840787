import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class CalculateBoundingBox extends ActionAbstract {

    /**
     * @param none
    **/

    static get ID() {
        return 'actions:editor:schematic:calculateBoudingBox';
    }

    run(state) {
        let target = helper.block.getBlockById( state.project, state.editor.targetId );

        let boundingBox = { xmin: null, xmax: null, ymin: null, ymax: null };

        if( target.custom.children && target.custom.children.length > 0 ){
            for( let child of target.custom.children ){
                if( child.value.coordinate ){

                    if( !boundingBox.xmin || child.value.coordinate.x < boundingBox.xmin ){
                        boundingBox.xmin = child.value.coordinate.x;
                    }
                    if( !boundingBox.xmax || child.value.coordinate.x > boundingBox.xmax ){
                        boundingBox.xmax = child.value.coordinate.x;
                    }

                    if( !boundingBox.ymin || child.value.coordinate.y < boundingBox.ymin ){
                        boundingBox.ymin = child.value.coordinate.y;
                    }
                    if( !boundingBox.ymax || child.value.coordinate.y > boundingBox.ymax ){
                        boundingBox.ymax = child.value.coordinate.y;
                    }
                }

            }
        }

        return this.composeState(state, {
            editor: {
                scene: {
                    boudingbox : boundingBox
                }
            } 
        });
    }


}

export default CalculateBoundingBox;