var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.download !== undefined
    ? _c("div", { attrs: { id: "downloadProjectTab" } }, [
        _c("div", { staticClass: "mainOptDownload" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "right" },
            [
              _vm._l(this.options, function(opt, key) {
                return _c("div", { staticClass: "options" }, [
                  _c(
                    "span",
                    {
                      staticClass: "choiceOpt plug checkbox",
                      class: { disabled: opt.disabled, selected: opt.selected },
                      on: {
                        click: function($event) {
                          return _vm.updateOptions(key)
                        }
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: opt.selected,
                            expression: "opt.selected"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(opt.selected)
                            ? _vm._i(opt.selected, null) > -1
                            : opt.selected
                        },
                        on: {
                          change: function($event) {
                            var $$a = opt.selected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(opt, "selected", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    opt,
                                    "selected",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(opt, "selected", $$c)
                            }
                          }
                        }
                      })
                    ]
                  ),
                  _c("p", [_vm._v(_vm._s(opt.name))])
                ])
              }),
              _vm.responseLogs
                ? _c("p", { staticClass: "error" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.responseLogs))])
                  ])
                : _vm._e()
            ],
            2
          )
        ]),
        _c("div", { staticClass: "action" }, [
          _c(
            "div",
            {
              staticClass: "button confirm",
              on: {
                "~click": function($event) {
                  return _vm.validate($event)
                }
              }
            },
            [_c("p", [_vm._v("Download")])]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("p", [
        _vm._v(
          "Please select the differents options that you would like to include or exclude in your download."
        )
      ]),
      _c("p", [
        _vm._v(
          "An archive will be generated allowing you to use it directly on your computer with the selected elements."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }