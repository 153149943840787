<!--
    Template component
    ------------------
    @doc: https://orbe.atlassian.net/wiki/spaces/NS/pages/14024705/Templates
-->
<template lang="pug">
div.template-panel

    div.criteria-bar
        div.arrange-by
            span.button.active
                icon(name="grid")
            span.button
                icon(name="list")
        div.sort Sorted by
            select(v-model="sortBy")
                option(value="date") Date
                option(value="name") Name
                option(value="owner") Owner
            icon.arrow(name="arrow-select")
        search-bar(v-on:change="searchResult")

    div.filter
        div.tab(:class="{'active':currentFilter=='all'}", v-on:click.stop="filterBy('all')")
            span All
        div.tab(:class="{'active':currentFilter=='screen'}", v-on:click.stop="filterBy('screen')")
            span Screens
        div.tab(:class="{'active':currentFilter=='widget'}", v-on:click.stop="filterBy('widget')")
            span Widgets
        div.tab(:class="{'active':currentFilter=='process'}", v-on:click.stop="filterBy('process')")
            span Processes

    div.list
        div.content
            div.add.rounded
                span.ico
                div.select
                    span.option(v-for="(type,i) of state.types",  v-on:click.stop="createTemplate(i)") {{ type }}

            div.item.rounded(v-for="(template,i) of templates", v-on:click="selectTemplate(i)", :class="{'selected':selected==template.template, 'hide':isHidden(template)}", :id="template.template")
                icon.rounded(:name="'template-item-'+template.type.toLowerCase()")
                div.name.rounded
                    span {{template.name}}
                div.flags
                    div.button.linked(v-if="isLinked(template.template)")
                div.actions
                    div.button.link(v-if="!isLinked(template.template)",v-on:click.stop="linkTemplate(template)")
                    div.button.unlink(v-if="isLinked(template.template)",v-on:click.stop="unlinkTemplate(template)")
                    div.button.duplicate(v-on:click.stop="duplicateTemplate(template)")
                    div.button.delete(v-if="canBeDeleted(template)",v-on:click.stop="deleteTemplate(template)")

        spinner(v-if="waiting")

    div.footer
        //- div.button(:class="{'enabled':selected}", v-on:click.stop="chooseTemplate") Choose template

    popup-window(v-if="popup.open", :component="popup.component", :settings="popup.settings", v-on:popup-close="popup.callback")


</template>

<script>

import actionManager from '../../actions'

import Icon from '../common/Icon.vue'
import Spinner from '../common/Spinner.vue'
import SearchBar from '../common/SearchBar.vue'
import PopupWindow from '../common/PopupWindow.vue'

import TemplateCreatePopup from './TemplateCreatePopup.vue'
import TemplateDeletePopup from './TemplateDeletePopup.vue'
import TemplateDuplicatePopup from './TemplateDuplicatePopup.vue'

export default {

    // state    the template panel state
    // project  the current project state
    // user     the current user
    props: ['state', 'project', 'user'],

    components: { Icon, Spinner, SearchBar, PopupWindow },

    data() {
        return {

            selected: null,
            selectedItem: null,

            popup: {
                open: false,
                settings: null,
                component: null,
                callback: null
            },

            currentFilter: 'all',
            searchTag: '',
            sortBy: 'date'
        }
    },

    created: function() {
    },

    computed: {
        templates: function() {
            let list = []
            let idx = 0
            for( let item of this.state.list) {
                list.push(item.value)
            }

            let key = this.sortBy
            list.sort((a, b) => {
                a = typeof a[key]=='string' ? a[key].toLowerCase() : a[key]
                b = typeof b[key]=='string' ? b[key].toLowerCase() : b[key]
                if ( a < b ) return key=='date' ? 1 : -1
                if ( a > b ) return key=='date' ? -1 : 1
                return 0
            })

            return list
        },

        waiting: function() {
            return this.state.waiting
        }
    },

    methods: {

        filterBy: function(type) {
            this.currentFilter = type
        },

        listNamesByType: function(type) {
            let list = []
            this.templates.forEach(item => {
                if ( item.type==type ) list.push(item.name.toLowerCase())
            });
            return list
        },

        createTemplate: function(index) {
            let type = this.state.types[index]

            this.popup.settings  = { type: type, names: this.listNamesByType(type) }
            this.popup.component = TemplateCreatePopup
            this.popup.callback  = (response) => {
               this.popup.open = false
            }
            this.popup.open = true
        },

        selectTemplate: function(index) {
            if ( this.selected != this.templates[index].template ) {
                this.selected = this.templates[index].template
                this.selectedItem = this.templates[index]
            }
            else {
                this.selected = null
                this.selectedItem = null
            }
        },

        isLinked(id) {
            return this.project.template && this.project.template.indexOf(id) != -1
        },

        linkTemplate(template) {
            actionManager.trigger('template:link', {id: template.template} )
            this.$parent.closePanel()
        },

        unlinkTemplate(template) {
            actionManager.trigger('template:unlink', {id: template.template, name: template.name} )
        },

        duplicateTemplate(template) {
            let type = template.type

            this.popup.settings  = { type: type, name: template.name+" copy", names: this.listNamesByType(type), source: template }
            this.popup.component = TemplateDuplicatePopup
            this.popup.callback  = (response) => {
                if ( response ) actionManager.trigger('template:listAll')
               this.popup.open = false
            }
            this.popup.open = true
        },

        canBeDeleted(template) {
            return !this.isLinked(template.template) && template.owner == this.user
        },

        deleteTemplate(template) {
            this.popup.settings  = { name: template.name }
            this.popup.component = TemplateDeletePopup
            this.popup.callback  = (response) => {
                if ( response ) {
                    // unlink template from project first,
                    // then delete it on positive result (i.e. not used in the current project)
                    actionManager.trigger('template:unlink', {
                        id: template.template, name: template.name,
                        next: {action:'template:delete', param:{id: template.template}}
                    })
                }
                this.popup.open = false
            }
            this.popup.open = true
        },

        searchResult: function(tag) {
            this.searchTag = tag
        },

        isInSearchResult: function( pattern, template ) {
            if ( pattern == '' ) return true
            let regex = new RegExp(pattern, 'i')
            return template.name.search(regex)!=-1
        },

        isInFilter: function(template) {
            return this.currentFilter=='all' ||
                   this.currentFilter==template.type.toLowerCase()
        },

        isHidden: function( template ) {
            return !this.isInSearchResult(this.searchTag,template) || !this.isInFilter(template)
        }
    }

}
</script>

<style lang="stylus">
.template-panel
    .list
        .content

            .add
                position: relative
                &:hover
                    .select
                        opacity: 1

                .select
                    box-shadow: 0px 0px 10px var(--shadowColor)
                    background-color: var(--bgColor)
                    border-radius: 10px
                    position: absolute
                    z-index: 20
                    width: 110%
                    padding: 5px
                    top: calc(50% + 25px)
                    left: calc(-5% - 5px)
                    opacity: 0

                    &:before
                        content:''
                        display: block
                        width: 20px
                        height: 20px
                        background-color: var(--bgColor)
                        position: absolute
                        transform: rotate(45deg)
                        left: calc(50% - 10px)
                        top: -10px
                        z-index: 0

                    span.option
                        text-transform: capitalize
                        background-color: var(--menuElementColor)
                        display: inline-block
                        text-align: center
                        margin: 5px
                        width: calc(100% - 10px)
                        position: relative
                        z-index: 1
                        height: 30px
                        line-height: 30px
                        border-radius: 5px
                        color: var(--inputTextColor)

                        &:hover
                            background-color: #F49E75
                            color: white

            .item
                .flags
                    .linked
                        background-image: url(../../assets/images/icons/template/template-action-link.svg)
                .actions
                    .link
                        background-image: url(../../assets/images/icons/template/template-action-link.svg)
                    .unlink
                        background-image: url(../../assets/images/icons/template/template-action-unlink.svg)
                    .duplicate
                        background-image: url(../../assets/images/icons/template/template-action-duplicate.svg)
                     .delete
                        background-image: url(../../assets/images/icons/template/template-action-delete.svg)

</style>
