<!-- Experience draw vue -->
<!--

	FIXME OR NOT means choose VueTouch and Hammer.js or the Vue click event
	see ticket NS-454 for more details @ https://orbe.atlassian.net/browse/NS-454
	Try to integrate vue2-hammer: https://github.com/bsdfzzzy/vue2-hammer. Lack of doc about how to add complex event (like doubletap) in vue.js

-->
<template lang="pug">
div.schematic(:key="blocsToDisplay ? blocsToDisplay.length : ''")

	v-touch.draw-scene(v-if="target", v-on:pan="onPanScene", v-bind:id="'scene-'+target.value._id",  v-on:mousemove.native="onMouseMove", v-bind:class="target.value.type", v-bind:style="{'background-position': scene.coordinate.x+'px '+scene.coordinate.y+'px' }" )

		div.scene(v-bind:style="{transform: 'translate('+scene.coordinate.x+'px , '+scene.coordinate.y+'px ) scale('+scene.scale+')'}")

			// FIXME OR NOT: v-touch(ref="hammer", v-for="bloc of blocsToDisplay", @tap="clickBloc($event, bloc)", @doubletap="openEditor(bloc)")
				item(:bloc="bloc", :app-state="appState")
			div(v-for="bloc of blocsToDisplay", @click.stop="blocEventHandler($event, bloc)")
				item(:bloc="bloc", :app-state="appState", v-if="!bloc.value._deleted", :family="blocsToDisplay", :workspace="workspace")

		// FIXME OR NOT: v-touch(ref="hammer", @tap="clickDrop", @doubletap="goToParent")
			bloc-links(:app-state="appState", :target="target", :scene="scene")
		div(@click.stop="linksEventHandler($event)")
			bloc-links(:app-state="appState", :target="target", :scene="scene")

		link-menu(:app-state="appState", :blocs="target.custom.children")

		//div.zoom-bts
			div(v-on:click="zoom()") +
			div(v-on:click="dezoom()") -git


</template>

<script>

import actionManager from '../../../actions';

import BlocLinks from  './BlocLinks.vue';
import LinkMenu from  './LinkMenu.vue';

import SchematicItem from './SchematicItem.vue';

import * as helper from '../../../helper';
import { stores } from '../../../stores/MainStore.js';

import Vue from 'vue';
import VueTouch from 'vue-touch';


//Vue.use(VueTouch);
//Vue.use(VueHammer)

//Vue.use(VueTouch, {name: 'v-touch'})


export default {

	components: { item: SchematicItem,  BlocLinks, LinkMenu },

	props: ['appState', 'target'],

	data() {
		return {
			// local scene
			scene: {
				coordinate: { x : 0 , y : 0 },
				lastCoordinate: { x : 0 , y : 0 },
				scale: 1
			},

			firstPan: true,
			draggingScene : false,
			draggingBlock: false,
			count: 0,
			countBloc: 0
		}
	},

	// FIXME OR NOT:HACK:  tap / doubletap workaround, acutually does not work
	// https://github.com/vuejs/vue-touch/issues/70
	// --> update JANV-2021 : vue-touch is deprecated (the solutions proposed in this issue are not working.)

	computed: {
		blocsToDisplay: function () {
			if (this.appState.project) {
				let nameArray = [];
				let parentId = '';
				let parent = {};
				if(this.target && this.target.custom && !this.target.value.hasOwnProperty("_deleted")){
					this.target.custom.children.forEach(child => nameArray.push(child.value.type))
					return this.target.custom.children;
				} else if(this.target && this.target.value.hasOwnProperty("_deleted") && this.target.type !== 'object'){
					parentId = stores.block.getParentsID( this.target );
					parent = helper.block.getBlockById( this.appState.project, parentId );
					return  parent.custom.children
				} else if(this.target && this.target.value.hasOwnProperty("_deleted") && this.target.type === 'object'){
					let grandParentID = stores.block.getParentsID( parent );
					let grandParent = helper.block.getBlockById( this.appState.project, grandParentID );
					//actionManager.trigger('editor:openEditor', { id : grandParentID } );
					return grandParent.custom.children
				}
			} else {
				return [];
			}
		},
		workspace: function(){
			return { id: this.target.value._id, workspace: this.target.value.level }
		}
	},

	created: function(){

		let ctn = document.getElementsByClassName('main-editor');

		if( this.target ){
			this.scene.coordinate = Object.assign( {}, this.appState.editor.scene.coordinate );
			this.scene.lastCoordinate = Object.assign( {x: 0 , y: 0}, this.scene.coordinate );
		}

		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$on('dragging-bloc', this.onDraggingBlock)
		this.$root.$on('first-pan', this.onFirstPan)
	},

	beforeDestroy: function(){
		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// If limits appear, have a look at https://vuex.vuejs.org
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$off('dragging-bloc', this.onDraggingBlock)
		this.$root.$off('first-pan', this.onFirstPan)
	},

	mounted: function(){
		// @todo: to be removed is tap/doubletap issue is fixed
		this.delay = this.$refs.hammer && this.$refs.hammer.recognizers ? this.$refs.hammer.recognizers['tap'].options.interval : 300
	},

	watch: {
	},

	methods:{

		onDraggingBlock: function(value)
		{
			this.draggingBlock = value
		},

		onFirstPan: function(value)
		{
			this.firstPan = value
		},

		onPanScene: function( e ){
			//if( this.draggingBlock === false && e.target.classList && e.target.classList.contains('lines') === true ) return;
			if( this.appState.editor.draggingGrabber === true )
				return;

			if( this.firstPan && e.target.nodeName != 'svg' ){
				this.firstPan = false;
				return;
			}

			if( this.draggingBlock ){
				e.preventDefault();
				return;
			}

			this.firstPan = false;

			this.draggingScene = true;
			let aroundBBox = 800;

			var pos = {
				x : this.scene.lastCoordinate.x + e.deltaX,
				y : this.scene.lastCoordinate.y + e.deltaY
			}


			if( pos.x > - ( this.appState.editor.scene.boudingbox.xmin - aroundBBox ) )
				pos.x =  - ( this.appState.editor.scene.boudingbox.xmin - aroundBBox );

			if( pos.x < - ( this.appState.editor.scene.boudingbox.xmax ) )
				pos.x = - ( this.appState.editor.scene.boudingbox.xmax );


			if( pos.y > - (this.appState.editor.scene.boudingbox.ymin - aroundBBox ) )
				pos.y = - (this.appState.editor.scene.boudingbox.ymin - aroundBBox);

			if( pos.y < - (this.appState.editor.scene.boudingbox.ymax ) )
				pos.y = - (this.appState.editor.scene.boudingbox.ymax );


			this.scene.coordinate.x = pos.x;
			this.scene.coordinate.y = pos.y;

			if( e.isFinal ){
				this.scene.lastCoordinate.x = this.scene.coordinate.x;
				this.scene.lastCoordinate.y = this.scene.coordinate.y;

				actionManager.trigger('editor:schematic:updateScene', { x : this.scene.coordinate.x, y : this.scene.coordinate.y } );

				setTimeout( function(){
					this.draggingScene = false;
					this.firstPan = true;
				}.bind(this), 0);
			}

		},

		// @todo: to be removed is tap/doubletap issue is fixed
		blocEventHandler(e, bloc) {
			this.countBloc += 1

			if ( this.countBloc === 1) this.timer = setTimeout(() => {
				this.countBloc = 0
				this.clickBloc(e, bloc)
			},  this.delay);
			else {
				if ( this.timer ) clearTimeout(this.timer)
				this.openEditor(bloc)
				this.countBloc = 0
			}

			//if ( e.tapCount==1 ) this.timer = setTimeout(this.clickBloc, this.delay, e, bloc); // using @tap
			// if ( e.detail==1 ) this.timer = setTimeout(this.clickBloc, this.delay, e, bloc);
			// else {
			// 	if ( this.timer ) clearTimeout(this.timer)
			// 	this.openEditor(bloc)
			// }
		},
		// @todo: to be removed is tap/doubletap issue is fixed
		linksEventHandler(e) {
			this.count += 1

			if ( this.count === 1) this.timer = setTimeout(() => {
				this.count = 0
				this.clickDrop(e)
			},  this.delay);
			else {
				if ( this.timer ) clearTimeout(this.timer)
				this.goToParent()
				this.count = 0
			}

			// //if ( e.tapCount==1 ) this.timer = setTimeout(this.clickBloc, this.delay, e, bloc); // using @tap
			// if ( e.detail==1 ) this.timer = setTimeout(this.clickDrop, this.delay);
			// else {
			// 	if ( this.timer ) clearTimeout(this.timer)
			// 	this.goToParent()
			// }
		},

		clickDrop: function( e ){
			if( this.draggingBlock || this.draggingScene )
				return;

			actionManager.trigger('main:deselectAll');
		},

		zoom: function(){
			this.scene.scale += 0.1;
		},

		dezoom: function(){
			this.scene.scale -= 0.1;
		},

		onMouseMove: function(e) {
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			this.$root.$emit( 'mouse-move', e );
		},

		openEditor: function( bloc ){
			actionManager.trigger('editor:openEditor', { id : bloc.value._id });
		},

		goToParent: function(){
			actionManager.trigger('editor:openParent');
		},

		clickBloc: function( event, bloc ){
			if( bloc && !this.draggingBlock && !bloc.value._deleted) {
				if (event.shiftKey) {
					const index = this.appState.editor.selection.items.indexOf(bloc.value._id);
					if (index===-1) this.appState.editor.selection.items.push(bloc.value._id);
					else this.appState.editor.selection.items.splice(index,1);
				}
				else this.appState.editor.selection.items = [bloc.value._id];
			}
		}

	}

}

</script>

<style lang="stylus">
.schematic
	position: absolute
	width: 100%
	height: 100%
	overflow: hidden

.draw-scene
	position: absolute
	width: 100%
	height: 100%
	overflow: hidden

	background-repeat: repeat;

	&.project
		background-image: url(../../../assets/images/grid-lvl1.svg);
	&.experience
		background-image: url(../../../assets/images/grid-lvl2.svg);
	&.screen
		background-image: url(../../../assets/images/grid-lvl3.svg);
	&.process
		background-image: url(../../../assets/images/grid-lvl4.svg);
	&.widget
		background-image: url(../../../assets/images/grid-lvl3.svg);

	.scene
		z-index: 50
		position: absolute
		top: 0
		left: 0
		//width: 100%
		//height: 100%
		z-index: 20

	.cover
		position: fixed
		width: 100%
		height: 100%
		top: 0
		left: 0

	.zoom-bts
		position: absolute
		top: 20px
		right: 20px

</style>
