<!-- Doc vue -->
<template lang="pug">
div#doc
	div#header
		div#title
			a(v-on:click.stop="displayTab('home')") Documentation
		
		div#backAuth
			a(v-on:click.stop="backToStudio()") Back To Studio

	nav#side-menu
		ul.nodes
			li(v-bind:class="{'selected' : currentTab == 'env' }")
				a(v-on:click.stop="displayTab('env')") Environment

			li(v-bind:class="{'selected' : currentTab == 'nodes' }")
				a(v-on:click.stop="displayTab('nodes')", v-bind:class="{'selected' : currentTab == 'nodes' }") Nodes

			li(v-bind:class="{'selected' : currentTab == 'mem' }")
				a(v-on:click.stop="displayTab('mem')", v-bind:class="{'selected' : currentTab == 'mem' }") Memories

			li(v-bind:class="{'selected' : currentTab == 'ref' || currentTab == 'module' }")
				a(v-on:click.stop="displayTab('ref')") Modules

			li.sublist(v-for="li of modules")
				div.title(v-on:click.stop="displayTab('module', li.name)") {{li.title}}
				a(v-for="cp of li.components", v-on:click.stop="displayTab('module', cp.value.type)", v-bind:class="{'selected' : subType == cp.value.type }")  {{cp.value.name}}

			li(v-bind:class="{'selected' : currentTab == 'obj' || currentTab == 'object' }")
				a(v-on:click.stop="displayTab('obj')") Objects

			li.sublist(v-for="li of objects")
				span.title(v-on:click.stop="displayTab('object', li.name)", v-bind:class="{'selected' : subType == li.name }") {{li.title}}
				a(v-for="cp of li.components", v-on:click.stop="displayTab('object', cp.value.format)", v-bind:class="{'selected' : subType == cp.value.format }")  {{cp.value.name}}

			li(v-bind:class="{'selected' : currentTab == 'examples' }")
				a(v-on:click.stop="displayTab('examples')", v-bind:class="{'selected' : currentTab == 'examples' }") Examples


	div#content(v-if="currentTab === 'home'")
		section.doc-theme.env(v-on:click.stop="displayTab('env')")
			span Environment

		section.doc-theme.nodes(v-on:click.stop="displayTab('nodes')")
			span Nodes
		
		section.doc-theme.mem(v-on:click.stop="displayTab('mem')")
			span Memories

		section.doc-theme.ref(v-on:click.stop="displayTab('ref')")
			span Reference

		section.doc-theme.ref(v-on:click.stop="displayTab('obj')")
			span Objects
		
		section.doc-theme.ref(v-on:click.stop="displayTab('examples')")
			span Examples

	environment(v-if="currentTab === 'env'")
	nodes(:display-tab="displayTabCallback", v-if="currentTab === 'nodes'")
	node-project(:display-tab="displayTabCallback", v-if="currentTab === 'node-project'")
	node-experience(v-if="currentTab === 'node-experience'")
	node-screen(v-if="currentTab === 'node-screen'")
	memories(v-if="currentTab === 'mem'")
		
	references(:display-tab="displayTabCallback", :app-state="appState", v-if="currentTab === 'ref'")
	reference(:display-tab="displayTabCallback", :app-state="appState", :type="currentTab", :sub-type="subType", v-if="( currentTab == 'module' || currentTab === 'object' ) && subType != null")

	objects(:display-tab="displayTabCallback", :app-state="appState", v-if="currentTab === 'obj'")

	examples(:display-tab="displayTabCallback", v-if="currentTab === 'examples'")

	div#bottom

</template>

<script>

	import actionManager from '../actions';

	import Environment from './docs/Environment.vue';

	import Nodes from './docs/Nodes.vue';
	import NodeProject from './docs/NodeProject.vue';
	import NodeExperience from './docs/NodeExperience.vue';
	import NodeScreen from './docs/NodeScreen.vue';

	import References from './docs/References.vue';
	import Reference from './docs/Reference.vue';

	import Objects from './docs/Objects.vue';

	import Memories from './docs/Memories.vue';
	import Examples from './docs/Examples.vue';


	export default {

		components: { Environment, Nodes, NodeProject, NodeExperience, NodeScreen, References, Reference, Objects, Memories, Examples },

		props:['appState'],

		data() {
			return {
                currentTab : 'home',
				displayTabCallback : null,
				subType : null
			};
		},

		computed:{

			'modules': function(){
				let editorMenu = this.appState.editor.menu;
				if( ( this.currentTab === 'ref' || this.currentTab === 'module' ) && ( this.appState.editor && editorMenu && editorMenu.list && editorMenu.list.length > 0 ) ){
					let list = editorMenu.list[0].children.filter( (c) => { if( c.components && c.components.length > 0 ) return c; return null; }) ;
					return list;
				}
				return [];
			},

			'objects': function(){
				let editorMenu = this.appState.editor.menu;
				if( ( this.currentTab === 'obj' || this.currentTab === 'object' ) && ( this.appState.editor && editorMenu && editorMenu.list && editorMenu.list.length > 0 ) ){
					let list = editorMenu.list[0].children.filter( (c) => { if( c.components && c.components.length > 0 ) return c; return null; }) ;
					return list;
				}
				return [];
			},


		},

		watch: {
			'$route' (to, from) {
				this.currentTab = to.params.page || 'home';
				this.subType = to.params.subpage || null;
			}
		},

		created: function(){
			actionManager.trigger('doc:openDoc');
			this.displayTabCallback = this.displayTab.bind(this);

			this.currentTab = this.$route.params.page || 'home';
			this.subType = this.$route.params.subpage || null;
		},

	    methods:{

		    displayTab: function( val, subType = null ){
				//this.subType = subType;
                //this.currentTab = val;
				if( subType === null ) 
                	actionManager.trigger('main:gotoRoute', { routeParam: {name: 'doc-page', params: { page: val }} });
				else
                	actionManager.trigger('main:gotoRoute', { routeParam: {name: 'doc-subpage', params: { page: val, subpage: subType }} });
            },

			
			backToStudio: function(){
                actionManager.trigger('main:gotoRoute', { routeParam: {name: 'home', params: {  }} })
			},


	    }

    }

</script>

<!-- FIXME: use pure CSS style in webpack.config.js (see below)-->
<style lang="stylus">
@font-face
	font-family: 'din-light';
	src: url('../assets/fonts/DINNextLTPro-Light.eot');
	src: url('../assets/fonts/DINNextLTPro-Light.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/DINNextLTPro-Light.woff2') format('woff2'),
		url('../assets/fonts/DINNextLTPro-Light.woff') format('woff'),
		url('../assets/fonts/DINNextLTPro-Light.ttf') format('truetype'),
		url('../assets/fonts/DINNextLTPro-Light.svg#DINNextLTPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;

@font-face
	font-family: 'din-bold';
	src: url('../assets/fonts/DINNextLTPro-Bold.eot');
	src: url('../assets/fonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/DINNextLTPro-Bold.woff2') format('woff2'),
		url('../assets/fonts/DINNextLTPro-Bold.woff') format('woff'),
		url('../assets/fonts/DINNextLTPro-Bold.ttf') format('truetype'),
		url('../assets/fonts/DINNextLTPro-Bold.svg#DINNextLTPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;

@font-face
	font-family: 'din-regular';
	src: url('../assets/fonts/DINNextLTPro-Regular.eot');
	src: url('../assets/fonts/DINNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/DINNextLTPro-Regular.woff2') format('woff2'),
		url('../assets/fonts/DINNextLTPro-Regular.woff') format('woff'),
		url('../assets/fonts/DINNextLTPro-Regular.ttf') format('truetype'),
		url('../assets/fonts/DINNextLTPro-Regular.svg#DINNextLTPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;

strong
	font-family: 'din-bold';

body
	background: var(--drawZoneColor) !important;
	font-family: 'din-regular'

#header
	width: 100%;
	height: 40px;
	background: var(--breadColor);
	box-shadow: 0px 0px 10px var(shadowColor);
	position: fixed;
	top:0;
	left:0;
	font-family: sans-serif;
	line-height: 40px;
	padding-left: 20px;
	z-index: 10;
	font-family: 'din-bold';
	font-size: 18px;
	color: #58595b;

#icon
	width: 25px;
	height: 25px;
	position:absolute;
	top:50%;
	transform: translate(0,-50%);

#icon-header
	width: 35px;
	height: 35px;
	margin-right: 10px;
	margin-bottom: -10px;

#title
	margin-left: 0px;
	line-height: 43px;

#title a
	cursor: pointer;
	color: #58595b;
	text-decoration: none;
	background-image: url(../assets/images/doc/icon-documentation.png);
	background-repeat: no-repeat;
	background-size: 20px;
	background-position: left 18px;
	padding-left: 28px;
	padding-top: 20px;

#title a:hover
	color: #e89f7c;
	text-decoration: none;

#backAuth
	position: absolute;
	top: 1px;
	right: 40px;
	font-size: 14px;
	text-transform: capitalize;
	color: #707070;
	cursor: pointer;

#backAuth:hover
	color: #e89f7c;
	text-decoration: none;

#side-menu
	cursor: pointer;
	background: var(--bgColor);
	box-shadow: 0px 0px 10px var(--shadowColor) !important;
	position: fixed;
	top:40px;
	left:0;
	bottom: 0;
	font-family: sans-serif;
	width: 420px;
	line-height: 60px;
	z-index: 8;

#side-menu ul
	padding: 0;
	margin: 0;
	color: #707070;

#side-menu ul li
	background: #ebebeb;
	height: 40px;
	line-height: 40px;
	padding-left: 20px;
	margin-bottom: 2px;
	color: #707070;
	list-style-type: none;
	font-family: 'din-regular';

#side-menu ul li:hover
	background: #F49E75;
	height: 40px;
	line-height: 40px;
	padding-left: 20px;
	margin-bottom: 2px;
	color: #707070;
	list-style-type: none;
	font-family: 'din-regular';

#side-menu ul li.selected
	background: var(--selectedColor);
	color: white;
	font-family: 'din-bold';

#side-menu ul li.selected a
	color:white;

#side-menu ul li a
	text-decoration: none;
	color: #58595b;
	display: block;
	/*margin: 10px;*/

#side-menu ul li a:hover
	text-decoration: none;
	color: #ffffff;
	display: block;
	/*margin: 10px;*/

#content
	position: absolute;
	left: 420px;
	right: 0;
	top: 40px;
	margin: 40px;
	background: var(--drawZoneColor)
	color: var(--inputTextColor)
	padding: 40px !important
	margin: 0 !important

#content h1
	width: 100%;
	margin-top:0;
	font-family: 'din-bold';

#content h2
	margin-top:30px;
	maring-bottom: 0;
	font-family: 'din-bold';
	font-size: 24px;

#content h3
	margin-top:0;
	font-family: 'din-bold';
	font-size: 16px;

#content ul
	padding: 0;
	margin: 0;
	margin-top: 10px;
	margin-left: 15px;

#content p#chapo
	color: #F49E75;
	font-family: 'din-bold';
	font-size: 18px;

#content ul#hierarchy li
	background: none;
	background-image: none;
	height: 20px;
	margin-bottom: 2px;
	border-radius: 5px;
	list-style-type:disc;
	margin-left:40px;

#content ul#hierarchy li:hover
	color:#58595b;

#bottom
	height:20px;

.illustration
	display: inline-block;
	background-size: contain;
	background-repeat: no-repeat;
	background-position : center;
	background-color: #ffffff;
	margin-top: 20px;
	font-size: 12px;
	color: #58595b;
	width:100%;
	height:auto;
	box-shadow: 0px 0px 10px rgba(0,0,0,.1);
	border-radius: 15px;
	overflow: hidden;

#content .nodes {
    margin-left: 0;
}

#content ul.nodes li
	background-color: var(--docElementColor);
	background-image: url(../assets/images/doc/arrow.png);
	background-position: right;
	background-repeat: no-repeat;
	background-size: 10px 15px;
	background-position: 97% 12px;
	height: 40px;
	line-height: 40px;
	margin-bottom: 0px;
	border-radius: 10px;
	list-style-type: none;
	transition: all .2s linear;

#content ul.nodes li:hover
	background: var(--selectedColor);
	color: #ffffff;
	background-image: url(../assets/images/doc/arrow-white.png);
	background-position: right;
	background-repeat: no-repeat;
	background-size: 10px 15px;
	background-position: 97% 12px;

#content ul.nodes li a , #content ul.nodes li span
	text-decoration: none;
	color: #707070;
	display: block;
	margin: 10px;
	font-size: 16px;    
	cursor: pointer;
	text-transform: capitalize;
	transition: all .2s linear;

#content ul.nodes li a:hover, #content ul.nodes li span:hover
	text-decoration: none;
	color: #ffffff;

.columns-ctn
	position: relative;
	width: 100%;
	text-align: left;
	vertical-align: top;

.column
	width: 48%;
	margin-right: 2%;
	display: inline-block;
	text-align: left;
	vertical-align: top;

.column1, .column2, .column3
	width: 50%;

.column1
	position: absolute;
	left:0;
	top:0;

#column2
	position: absolute;
	left:50%;
	top:0;

.refs-ctn
	text-align: left;

.masonry
	box-sizing:border-box;
	display: flex;
	flex-flow: wrap;
	flex-direction: column;
	width: 100%;

.masonry-brick
	min-width: 200px;
	width: 33%;
	margin: 0 8px 8px 0; /* Some gutter */


.module-theme
	background: var(--inspectorTargetColor);
	padding: 20px;
	border-radius: 20px;
	position: relative;
	vertical-align: top;
	margin-bottom: 20px;
	min-width: 200px;

#content .module-theme h1
	font-size: 24px;


.doc-theme
	cursor: pointer;
	background: #fbe6cd;
	background-size: cover;
	padding: 0px;
	margin: 0px;
	margin-right: 20px;
	margin-bottom: 20px;
	border-radius: 15px;
	position: relative;
	vertical-align: top;
	width: 350px;
	height: 250px;
	display: inline-block;
	border: 0;
	box-shadow: 0px 0px 10px var(--shadowColor);

.doc-theme span
	font-family: 'din-bold';
	background: var(--inputValue);
	color: var(--inputTextColor);
	position: absolute;
	bottom: 0;
	left: 0;
	right:0;
	height: 30px;
	line-height: 30px;
	font-size: 16px;
	text-decoration: none;
	padding: 10px;
	border-radius: 0px 0px 15px 15px;

.doc-theme:hover span
	color:#ffffff;
	background: #F49E75;


.doc-theme.env
	background-image: url(../assets/images/doc/img-environment.png);

.doc-theme.nodes
	background-image: url(../assets/images/doc/img-nodes.png);

.doc-theme.mem
	background-image: url(../assets/images/doc/img-memory.png);

.doc-theme.ref
	background-image: url(../assets/images/doc/img-reference.png);


#node-theme
	background: #fbe6cd;
	background-size: cover;
	padding: 0px;
	margin: 0px;
	margin-left: 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	position: relative;
	vertical-align: top;
	width: 46%;
	height: 300px;
	display: inline-block;
	border: 0;
	box-shadow: 0px 0px 10px #aaaaaa;
 
#node-theme a
	font-family: 'din-bold';
	background: #58595b;
	color:#ffffff;
	position: absolute;
	bottom: 0;
	left: 0;
	right:0;
	height: 30px;
	line-height: 35px;
	font-size: 22px;
	text-decoration: none;
	padding: 20px;
	border-radius: 0px 0px 10px 10px;

#node-theme a:hover
	background: #e89f7c;
	color:#ffffff;

#header
	width: 100%;
	height: 40px;
	background: var(--bgColor);
	box-shadow: 0px 0px 10px var(--shadowColor);
	position: fixed;
	top:0;
	left:0;
	line-height: 40px;
	padding-left: 20px;
	z-index: 10;
	font-family: 'din-bold';
	font-size: 18px;
	color: var(--inputTextColor);



#side-menu
	background: var(--bgColor);
	box-shadow: 0px 0px 10px var(shadowColor);
	position: fixed;
	top:40px;
	left:0;
	bottom: 0;
	font-family: sans-serif;
	width: 420px;
	line-height: 60px;
	overflow: auto;

#side-menu ul
	padding: 0;
	margin: 0;
	color: #58595b;

#side-menu ul li
	background: var(--currentStepColor)
	height: 40px;
	line-height: 40px;
	padding-left: 10px;
	margin-bottom: 2px;
	color: #58595b;
	list-style-type: none;
	font-family: 'din-regular';
	transition: all 0.2s linear;

#side-menu ul li:hover
	background: var(--selectedColor)
	color: #58595b;


#side-menu ul li.sublist
	background-color: var(--bgColor);
	height: auto;
	line-height: 17px;
	font-size: 12px;
	padding: 0px;
	.title
		background: white
		padding: 10px 10px 10px 10px
		font-family: din-bold
		font-size: 16px
		color: var(--inspectorText)

#side-menu ul li.sublist span.title
	cursor: auto;
	font-family: 'din-bold';
	font-style: italic;
	font-size: 14px;

#side-menu ul li.sublist a
	display: inline-block;
	width: calc(100% - 18px);
	background: var(--inspectorTargetOverColor);
	height: 40px;
	font-size: 14px;
	line-height: 40px;
	padding: 0 10px;
	padding-left: 20px
	margin-bottom: 2px;
	font-family: 'din-regular';
	transition: all 0.2s linear;
	text-tranform: capitalize

#side-menu ul li.sublist a:hover, #side-menu ul li.sublist a.selected
	color: #e89f7c;
	font-family : 'din-bold';

#side-menu ul li.reflist
	height: 25px;
	line-height: 25px;
	padding-left: 20px;
	margin-bottom: 2px;
	color: #939498;
	list-style-type: none;
	font-family: 'din-light';

#side-menu ul li#selected
	background: var(--selectedColor)
	color:white;
	font-family: 'din-bold';

#side-menu ul li a
	text-decoration: none;
	color: var(--inputTextColor)
	display: block;


#side-menu ul li a:hover
	text-decoration: none;
	color: var(--inputTextColor)
	display: block;
 

#content h1
	margin-top:0;
	font-family: 'din-bold';
	font-size: 36px;
	margin-left: 0px;
	margin-bottom: 10px;

#content h2
	margin-top:30px;
	font-family: 'din-bold';
	font-size: 18px;
	color: var(--selectedColor);

#content h3
	margin-top:30px;
	font-family: 'din-bold';
	font-size: 16px;
	color: var(--selectedColor);

#content a
	color: #e89f7c;
	text-decoration: none;
	border-bottom: 1px dotted #e89f7c;

#content a:hover
	color:#ffffff;
	background-color: #e89f7c;

#content p#note
	color: #e89f7c;
	font-family: 'din-bold';


#params
	text-align: left;
	padding: 0;
	border-collapse: separate;
	border-spacing: 10px;
	line-height: 40px;
	margin-left: -10px;
	width: calc(100% + 10px);

#params tr
	height: 40px;
	margin-bottom: 5px;
	background-color: var(--inspectorTargetOverColor);
	/*line-height: 18px;*/

#params td
	padding-left: 20px;	

#params td#col1
	width:20%;
	border-radius: 10px;

#params td#col2
	width:80%;
	border-radius: 10px;

.inspector
	margin-bottom: 50px;

.inspector h2
	margin-bottom: 0px;

.inspector h3
	margin-top: 20px;
	font-size: 14px !important;

#settings
	text-align: left;
	padding: 0;
	border-collapse: collapse;
	border-spacing: 0em 10px;
	line-height: 14px;
	font-size: 13px;
	width: 100%;
	margin-top: 10px;

#settings tr
	height: 40px;
	margin-bottom: 5px;
	font-family: 'din-regular';
	/*line-height: 18px;*/

#settings tr#line1
	background-color: var(--inspectorColor);

#settings th
	font-family: 'din-bold';

#settings th#col1, #settings tr.noHeader td#col1
	border-top-left-radius: 5px;

#settings th#colOut, #settings tr.noHeader td#colOut
	border-top-right-radius: 5px;

#settings tr:last-child td#col1
	border-bottom-left-radius: 5px;

#settings tr:last-child td#colOut
	border-bottom-right-radius: 5px;

#settings tr:nth-child(even)
	background-color: rgba(232, 159, 124, 0.1); 

#settings tr.pair
	background-color: rgba(232, 159, 124, 0.1) !important;

#settings tr:nth-child(odd)
	background-color: rgba(232, 159, 124, 0.3); 

#settings tr.impair
	background-color: rgba(232, 159, 124, 0.3) !important;

#settings th
	text-transform : capitalize;
	font-size: 16px;

#settings th#col1,
#settings td#col1
	width:20%;
	padding-left: 15px;

#settings th#col2,
#settings td#col2
	width:15%;
	line-height: 22px;
	text-transform: uppercase;
	font-size: 11px;

#settings th#col2
	font-size: 13px;


#settings th#col3,
#settings td#col3
	width:55%;
	line-height: 22px;

#settings th#colIn,
#settings td#colIn,
#settings th#colOut,
#settings td#colOut
	width:5%;
	line-height: 22px;

#settings ul
	padding: 0;
	margin: 0;
	line-height: 18px;
	margin-bottom: 6px;
	margin-top: 6px;

#settings ul li
	list-style-type: none;
	font-family: 'din-regular';
	margin-bottom: 14px;
	line-height: 20px;

#back
	cursor: pointer;
	background: var(--selectedColor);
	padding: 10px 20px 10px 14px;
	border-radius: 40px;
	color:#ffffff;
	font-family: 'din-bold';
	font-size: 20px;
	text-align: center;
	text-decoration: none;
	margin-top: 20px;
	margin-bottom: 20px;
	position: fixed ;
	top:40px;
	right: 40px;

#back a
	color:#ffffff;
	text-decoration: none;

#download
	background: #ffffff;
	padding: 10px 20px;
	border-radius: 10px;
	color:#e89f7c;
	font-family: 'din-bold';
	font-size: 20px;
	width:230px;
	text-align: center;
	text-decoration: none!important;
	margin-top: 20px;
	margin-bottom: 20px;
	border: none!important;
	margin-top: 20px;


.listing
	margin-left: 20px;

</style>

<!-- ORIGINAL CSS
<style>
@font-face {
    font-family: 'din-light';
    src: url('../assets/fonts/DINNextLTPro-Light.eot');
    src: url('../assets/fonts/DINNextLTPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/DINNextLTPro-Light.woff2') format('woff2'),
        url('../assets/fonts/DINNextLTPro-Light.woff') format('woff'),
        url('../assets/fonts/DINNextLTPro-Light.ttf') format('truetype'),
        url('../assets/fonts/DINNextLTPro-Light.svg#DINNextLTPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'din-bold';
    src: url('../assets/fonts/DINNextLTPro-Bold.eot');
    src: url('../assets/fonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/DINNextLTPro-Bold.woff2') format('woff2'),
        url('../assets/fonts/DINNextLTPro-Bold.woff') format('woff'),
        url('../assets/fonts/DINNextLTPro-Bold.ttf') format('truetype'),
        url('../assets/fonts/DINNextLTPro-Bold.svg#DINNextLTPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'din-regular';
    src: url('../assets/fonts/DINNextLTPro-Regular.eot');
    src: url('../assets/fonts/DINNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/DINNextLTPro-Regular.woff2') format('woff2'),
        url('../assets/fonts/DINNextLTPro-Regular.woff') format('woff'),
        url('../assets/fonts/DINNextLTPro-Regular.ttf') format('truetype'),
        url('../assets/fonts/DINNextLTPro-Regular.svg#DINNextLTPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

strong {

	font-family: 'din-bold';

}

body {

	background: #fef6ed;
	font-family: 'din-regular';

}

#header {

	width: 100%;
	height: 40px;
	background: #ffffff;
	box-shadow: 0px 0px 10px #aaaaaa;
	position: fixed;
	top:0;
	left:0;

	font-family: sans-serif;
	line-height: 40px;
	padding-left: 20px;

	z-index: 10;

	font-family: 'din-bold';
	font-size: 18px;
	color: #58595b;

}

#icon {

	width: 25px;
	height: 25px;
	position:absolute;
	top:50%;
	transform: translate(0,-50%);

}

#icon-header {

	width: 35px;
	height: 35px;
	margin-right: 10px;
	margin-bottom: -10px;

}

#title {
	margin-left: 0px;
	line-height: 43px;
}

#title a{
	cursor: pointer;
	color: #58595b;
	text-decoration: none;
	background-image: url(../assets/images/doc/icon-documentation.png);
	background-repeat: no-repeat;
    background-size: 20px;
    background-position: left 18px;
    padding-left: 28px;
    padding-top: 20px;
}

#title a:hover {
	color: #e89f7c;
	text-decoration: none;
}

#backAuth{
	position: absolute;
	top: 1px;
    right: 40px;
    font-size: 14px;
    text-transform: uppercase;
	color: #58595b;
	cursor: pointer;
}
#backAuth:hover {
	color: #e89f7c;
	text-decoration: none;
}

#side-menu {
	cursor: pointer;
	background: #ffffff;
	box-shadow: 0px 0px 10px #aaaaaa;
	position: fixed;
	top:40px;
	left:0;
	bottom: 0;

	font-family: sans-serif;

	width: 300px;
	line-height: 60px;

}

#side-menu ul {

	padding: 0;
	margin: 0;
	color: #58595b;
}

#side-menu ul li {

	background: #e6e7e8;
	height: 40px;
	line-height: 40px;
	padding-left: 20px;
	margin-bottom: 2px;
	color: #58595b;

	list-style-type: none;
	font-family: 'din-regular';

}

#side-menu ul li:hover {

	background: #e89f7c;
	height: 40px;
	line-height: 40px;
	padding-left: 20px;
	margin-bottom: 2px;
	color: #58595b;

	list-style-type: none;
	font-family: 'din-regular';

}

#side-menu ul li.selected {

	background:#58595b;
	color:white;
	font-family: 'din-bold';

}

#side-menu ul li.selected a {
	color:white;
}

#side-menu ul li a {
	text-decoration: none;
	color: #58595b;
	display: block;
	/*margin: 10px;*/
}

#side-menu ul li a:hover {
	text-decoration: none;
	color: #ffffff;
	display: block;
	/*margin: 10px;*/
}	


#content {
	position: absolute;
	left: 300px;
	right: 0;
	top: 60px;
	bottom: 0;
	color: #58595b;
	margin: 20px;
	padding-bottom: 40px;
}

#content h1 {
	width: 100%;
	margin-top:0;
	font-family: 'din-bold';
}

#content h2 {
	margin-top:0;
	font-family: 'din-bold';
	font-size: 20px;
}

#content h3 {
	margin-top:0;
	font-family: 'din-bold';
	font-size: 16px;
}

#content ul {
	padding: 0;
	margin: 0;
	margin-bottom: 10px;
}

#content p#chapo {
	color: #e89f7c;
	font-family: 'din-bold';
	font-size: 20px;
}

#content ul#hierarchy li {
	background: none;
	background-image: none;
	height: 20px;
	margin-bottom: 2px;
	border-radius: 5px;
	list-style-type:disc;
	margin-left:40px;
}

#content ul#hierarchy li:hover {
	color:#58595b;
}

#bottom {
	height:20px;
}

.illustration {
	display: inline-block;
	background-size: contain;
	background-repeat: no-repeat;
	background-position : center;
	background-color: #ffffff;
	margin-right: 20px;
	font-size: 12px;
	color: #58595b;
	width:100%;
	height:auto;
	box-shadow: 0px 0px 10px #aaaaaa;
	border-radius: 5px;
	overflow: hidden;
}

#content ul.nodes li {
	background: #ffffff;
	background-image: url(../assets/images/doc/arrow.png);
	background-position: right;
	background-repeat: no-repeat;
	background-size: 15px 15px;
	background-position: 97% 12px;
	height: 40px;
	line-height: 40px;
	margin-bottom: 2px;
	border-radius: 5px;
	list-style-type: none;
}

#content ul.nodes li:hover {
	background: #e89f7c;
	color: #ffffff;
	background-image: url(../assets/images/doc/arrow-white.png);
	background-position: right;
	background-repeat: no-repeat;
	background-size: 15px 15px;
	background-position: 97% 12px;
}

#content ul.nodes li a , #content ul.nodes li span{
	text-decoration: none;
	color: #58595b;
	display: block;
	margin: 10px;
	font-size: 16px;    
	cursor: pointer;
}

#content ul.nodes li a:hover, #content ul.nodes li span:hover {
	text-decoration: none;
	color: #ffffff;
}

.columns-ctn{
	position: relative;
	width: 100%;
	text-align: center;
	vertical-align: top;
}

.column{
	width: 48%;
	margin-left: 1%;
	margin-right: 1%;
	display: inline-block;
	text-align: left;
    vertical-align: top;
}

.column1, .column2, .column3 {
	width: 50%;
}

.column1 {
	position: absolute;
	left:0;
	top:0;
}

#column2 {
	position: absolute;
	left:50%;
	top:0;
}

.refs-ctn{
	text-align: left;
}

.masonry { 
	box-sizing:border-box;
	display: flex;
	flex-flow: wrap;
	flex-direction: column;
	width: 100%;
}

.masonry-brick {
	min-width: 200px;
	width: 33%;
	margin: 0 8px 8px 0; /* Some gutter */
}


.module-theme {
	background: #fbe6cd;
	padding: 20px;
	border-radius: 10px;
	position: relative;
	vertical-align: top;
	margin-bottom: 20px;
	min-width: 200px;
}

#content .module-theme h1{
	font-size: 24px;
}


.doc-theme {
    cursor: pointer;
	background: #fbe6cd;
	background-size: cover;
	padding: 0px;
	margin: 0px;
	margin-left: 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	position: relative;
	vertical-align: top;
	width: 46%;
	height: 350px;
	display: inline-block;
	border: 0;
	box-shadow: 0px 0px 10px #aaaaaa;
}

.doc-theme span {
	font-family: 'din-bold';
	background: #58595b;
	color:#ffffff;
	position: absolute;
	bottom: 0;
	left: 0;
	right:0;
	height: 30px;
	line-height: 35px;
	font-size: 22px;
	text-decoration: none;
	padding: 20px;
	border-radius: 0px 0px 10px 10px;
}

.doc-theme:hover span {
	color:#ffffff;
	background: #e89f7c;
}


.doc-theme.env{
    background-image: url(../assets/images/doc/img-environment.png);
}
.doc-theme.nodes{
    background-image: url(../assets/images/doc/img-nodes.png);
}
.doc-theme.mem{
    background-image: url(../assets/images/doc/img-memory.png);
}
.doc-theme.ref{
    background-image: url(../assets/images/doc/img-reference.png);
}

#node-theme {
	background: #fbe6cd;
	background-size: cover;
	padding: 0px;
	margin: 0px;
	margin-left: 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	position: relative;
	vertical-align: top;
	width: 46%;
	height: 300px;
	display: inline-block;
	border: 0;
	box-shadow: 0px 0px 10px #aaaaaa;
}
 
#node-theme a {
	font-family: 'din-bold';
	background: #58595b;
	color:#ffffff;
	position: absolute;
	bottom: 0;
	left: 0;
	right:0;
	height: 30px;
	line-height: 35px;
	font-size: 22px;
	text-decoration: none;
	padding: 20px;
	border-radius: 0px 0px 10px 10px;
}

#node-theme a:hover {
	background: #e89f7c;
	color:#ffffff;
}







#header {

	width: 100%;
	height: 40px;
	background: #ffffff;
	box-shadow: 0px 0px 10px #aaaaaa;
	position: fixed;
	top:0;
	left:0;

	font-family: sans-serif;
	line-height: 40px;
	padding-left: 20px;

	z-index: 10;

	font-family: 'din-bold';
	font-size: 18px;
	color: #58595b;

}



#side-menu {

	background: #ffffff;
	box-shadow: 0px 0px 10px #aaaaaa;
	position: fixed;
	top:40px;
	left:0;
	bottom: 0;

	font-family: sans-serif;

	width: 300px;
	line-height: 60px;
    overflow: auto;

}

#side-menu ul {

	padding: 0;
	margin: 0;
	color: #58595b;
}

#side-menu ul li {

	background: #e6e7e8;
	height: 40px;
	line-height: 40px;
	padding-left: 20px;
	margin-bottom: 2px;
	color: #58595b;

	list-style-type: none;
	font-family: 'din-regular';

}
#side-menu ul li:hover {
	background: #e89f7c;
	color: #58595b;
}


#side-menu ul li.sublist{
	background-color: rgb(230, 231, 232, 0.2);
    height: auto;
    line-height: 17px;
    font-size: 12px;
    padding: 9px 20px;
}

#side-menu ul li.sublist span.title{
	cursor: auto;
    font-family: 'din-bold';
    font-style: italic;
    font-size: 14px;
}

#side-menu ul li.sublist a{
	display: inline-block;
	width: 100%;
	font-family: 'din-regular';
}


#side-menu ul li.sublist a:hover, #side-menu ul li.sublist a.selected {
	color: #e89f7c;
	font-family : 'din-bold';
}

#side-menu ul li.reflist{
	height: 25px;
	line-height: 25px;
	padding-left: 20px;
	margin-bottom: 2px;
	color: #939498;
	list-style-type: none;
	font-family: 'din-light';
}



#side-menu ul li#selected {

	background:#58595b;
	color:white;
	font-family: 'din-bold';

}

#side-menu ul li a {
	text-decoration: none;
	color: #58595b;
	display: block;
	/*margin: 10px;*/
}

#side-menu ul li a:hover {
	text-decoration: none;
	color: #ffffff;
	display: block;
	/*margin: 10px;*/
}	


#content {
	position: absolute;
    top: 40px;
    right: 0px;
    left: 300px;
    bottom: 0;
    height: auto;
    width: auto;
}

#content h1 {

	margin-top:0;
	font-family: 'din-bold';
	font-size: 48px;
    margin-left: 6px;
    margin-bottom: 10px;

}

#content h2 {

	margin-top:30px;
	font-family: 'din-bold';
	font-size: 18px;

}

#content h3 {

	margin-top:30px;
	font-family: 'din-bold';
	font-size: 16px;

}

#content a {
	color: #e89f7c;
	text-decoration: none;
	border-bottom: 1px dotted #e89f7c;
}

#content a:hover {
	color:#ffffff;
	background-color: #e89f7c;
}

#content p#note {
	color: #e89f7c;
	font-family: 'din-bold';
}


#params {
	text-align: left;
	padding: 0;
	border-collapse: separate;
    border-spacing: 0em 10px;
    line-height: 42px;
	width: 100%;
}

#params tr {
	height: 40px;
	margin-bottom: 5px;
	background-color: rgba(232,159,124,0.2);
	/*line-height: 18px;*/
}

#params td {
	padding-left: 20px;	
}

#params td#col1{
	width:20%;
}

#params td#col2{
	width:80%;
}

.inspector{
	margin-bottom: 50px;
}

.inspector h2{
	margin-bottom: 0px;
}

.inspector h3{
	margin-top: 20px;
	font-style: italic;
}

#settings {
	text-align: left;
	padding: 0;
	border-collapse: collapse;
    border-spacing: 0em 10px;
    line-height: 14px;
	font-size: 13px;
	width: 100%;
    margin-top: 10px;
}

#settings tr {
	height: 40px;
	margin-bottom: 5px;
	font-family: 'din-regular';
	/*line-height: 18px;*/
}

#settings tr#line1{
	background-color: #ffffff;
}

#settings th {
	font-family: 'din-bold';
}

#settings th#col1, #settings tr.noHeader td#col1{
	border-top-left-radius: 5px;
}
#settings th#colOut, #settings tr.noHeader td#colOut{
	border-top-right-radius: 5px;
}

#settings tr:last-child td#col1{
	border-bottom-left-radius: 5px;
}
#settings tr:last-child td#colOut{
	border-bottom-right-radius: 5px;
}

#settings tr:nth-child(even){
	background-color: rgba(232, 159, 124, 0.1); 
}
#settings tr.pair{
	background-color: rgba(232, 159, 124, 0.1) !important;
}

#settings tr:nth-child(odd){
	background-color: rgba(232, 159, 124, 0.3); 
}
#settings tr.impair {
	background-color: rgba(232, 159, 124, 0.3) !important;
}

#settings th{
	text-transform : uppercase;
}
#settings th#col1,
#settings td#col1 {
	width:20%;
	padding-left: 15px;
}

#settings th#col2,
#settings td#col2 {
	width:25%;
	line-height: 22px;
	text-transform: uppercase;
	font-size: 11px;
}

#settings th#col2{
	font-size: 13px;
}


#settings th#col3,
#settings td#col3 {
	width:45%;
	line-height: 22px;
}

#settings th#colIn,
#settings td#colIn,
#settings th#colOut,
#settings td#colOut {
	width:5%;
	line-height: 22px;
}

#settings ul {
	padding: 0;
	margin: 0;
	line-height: 22px;
	margin-bottom: 10px;
	margin-top: 7px;
}

#settings ul li {
	list-style-type: none;
	font-family: 'din-regular';
	margin-bottom: 10px;
}

#back {
	cursor: pointer;
	background: #e89f7c;
	padding: 10px 20px 10px 14px;
	border-radius: 10px;
	color:#ffffff;
	font-family: 'din-bold';
	font-size: 20px;
	text-align: center;
	text-decoration: none;
	margin-top: 20px;
	margin-bottom: 20px;
	position: fixed ;
	top:40px;
    right: 20px;

}

#back a {
	color:#ffffff;
	text-decoration: none;

}

#download {

	background: #ffffff;
	padding: 10px 20px;
	border-radius: 10px;
	color:#e89f7c;
	font-family: 'din-bold';
	font-size: 20px;
	width:230px;
	text-align: center;
	text-decoration: none!important;
	margin-top: 20px;
	margin-bottom: 20px;
	border: none!important;
	margin-top: 20px;

}


.listing{
    margin-left: 20px;
}
</style>
-->

