var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    {
      staticClass: "line",
      class: [
        { selected: _vm.selected },
        { activated: _vm.link.activated },
        { deprecated: _vm.isDeprecated }
      ],
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.selectPath($event)
        }
      }
    },
    [
      _vm.link && !_vm.impossible
        ? _c(
            "g",
            [
              _vm._l(_vm.path.beziers, function(bezier, b) {
                return _c("g", [
                  _c("path", {
                    attrs: { d: _vm.generatePath(bezier, _vm.looper[b]) }
                  })
                ])
              }),
              _c(
                "v-touch",
                { attrs: { id: "grab", tag: "g" }, on: { pan: _vm.onPan } },
                [
                  _vm.path && _vm.path.beziers && _vm.path.beziers.length >= 2
                    ? _c("line", {
                        staticClass: "grabber-bg",
                        attrs: {
                          x1: _vm.getPointAt(
                            _vm.path.beziers[0],
                            _vm.grabberStart
                          ).x,
                          x2: _vm.getPointAt(
                            _vm.path.beziers[1],
                            _vm.grabberEnd
                          ).x,
                          y1: _vm.getPointAt(
                            _vm.path.beziers[0],
                            _vm.grabberStart
                          ).y,
                          y2: _vm.getPointAt(
                            _vm.path.beziers[1],
                            _vm.grabberEnd
                          ).y
                        }
                      })
                    : _vm._e(),
                  _vm.path && _vm.path.beziers && _vm.path.beziers.length >= 2
                    ? _c("line", {
                        staticClass: "grabber",
                        attrs: {
                          x1: _vm.getPointAt(
                            _vm.path.beziers[0],
                            _vm.grabberStart
                          ).x,
                          x2: _vm.getPointAt(
                            _vm.path.beziers[1],
                            _vm.grabberEnd
                          ).x,
                          y1: _vm.getPointAt(
                            _vm.path.beziers[0],
                            _vm.grabberStart
                          ).y,
                          y2: _vm.getPointAt(
                            _vm.path.beziers[1],
                            _vm.grabberEnd
                          ).y
                        }
                      })
                    : _vm._e(),
                  _c("path", {
                    staticClass: "grabberTextPath",
                    attrs: { id: _vm.linkuuid, d: _vm.grabberTextPath }
                  }),
                  _c(
                    "text",
                    { attrs: { width: "100", "text-anchor": "middle" } },
                    [
                      _vm.link.conversion
                        ? _c(
                            "textPath",
                            {
                              attrs: {
                                "xlink:href": "#" + _vm.linkuuid,
                                startOffset: "50%",
                                "alignment-baseline": "middle",
                                "text-anchor": "middle"
                              }
                            },
                            [_vm._v(_vm._s(_vm.link.conversion))]
                          )
                        : _c(
                            "textPath",
                            {
                              attrs: {
                                "xlink:href": "#" + _vm.linkuuid,
                                startOffset: "50%",
                                "alignment-baseline": "middle",
                                "text-anchor": "middle"
                              }
                            },
                            [_vm._v(">")]
                          )
                    ]
                  )
                ]
              ),
              this.link.priority != 0 && _vm.priorityPos
                ? _c("g", [
                    _c("circle", {
                      attrs: {
                        cx: _vm.priorityPos.x,
                        cy: _vm.priorityPos.y,
                        r: "9",
                        fill: "#F49E75",
                        stroke: "none"
                      }
                    }),
                    _c(
                      "text",
                      {
                        attrs: {
                          width: "6",
                          x: _vm.priorityPos.x,
                          y: _vm.priorityPos.y,
                          "text-anchor": "middle",
                          fill: "white",
                          stroke: "none",
                          "font-size": "9px",
                          "alignment-baseline": "central"
                        }
                      },
                      [_vm._v(_vm._s(_vm.link.priority))]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }