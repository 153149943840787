import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class InitHistory extends ActionAbstract {

    static get ID() {
        return 'actions:main:initHistory';
    }

    run(state) {

        let currentHistory = [];

        if( state.editor && state.editor.targetId )
            currentHistory.push( state.editor.targetId );
        
        return this.composeState( state, {
            breadcrumb : {
                history : currentHistory,
                crtHistory : 0
            }
        })
        
    }

}

export default InitHistory;