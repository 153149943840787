import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class OpenPanel extends ActionAbstract {

    static get ID() {
        return 'actions:main:panel:open';
    }

    run(state) {
        return this.composeState(state, {
            panel: {
                open: true,
                name: this.getParam('name'),
                type: this.getParam('type')
            }
        });

    }

}

export default OpenPanel;