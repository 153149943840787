
/*
	UI Objects Store instance -
	all function to get info of Objects
*/

import { EventEmitter } from 'events';
import * as uiObjectsFormat from '../../nodal-ui/ui-objects';
import * as helper from '../helper';

class UIObjectStore extends EventEmitter {

	constructor(){
		super();
        this.uiObjects = {};
	}

    setMain( main ){
		this.main = main;
	}

    /**
     * Create any UiObject from list.
     * NEVER USED = DEADCODE
     *
     * @param {*} list — The list of all UiObjects to be created.
     *
    createUIObjects(list) {
        list.forEach((obj) => {
            this.createUIObject(Object.assign({}, obj));
        });
    }*/

    /**
     * Create and init a new UiObject depending of the object definition.
     *
     * @param {Object} object - The object from which to build the UiObject.
     * @returns {UiObject} The created UiObject (UiBox, UiParagraph, etc)
     */
    createUIObject(object) {
        object = object.value;

        const ctor = this.getUIObjectCtor(object);

        this.uiObjects[object._id] = new ctor(object._id, object.fields.slice(0));
        this.uiObjects[object._id].setMediaManager(this.main.lib);
        this.uiObjects[object._id].init();

        return this.uiObjects[object._id];
    }

    /**
     * Create and add recursively all UiObjects contained in the given object.
     *
     * @param {Object} parent — The parent object from which to build all UiObject.
     * @param {int} section — The index of the parent section
     */
    createAndAddUIObjects(parent, section) {
        if (parent.custom && parent.custom.objects && parent.custom.objects.length) {
            parent.custom.objects.forEach((obj) => {
                const ui = this.createUIObject(obj);
                this.addChildTo(ui.id, parent.value._id, section);
                this.createAndAddUIObjects(obj, section);
            });
        }
    };

    get(id, ctx){
        let ui = this.uiObjects[id];
        return this.uiObjects[id];
    }

    getUIObjectCtor(obj) {
        let kebabToPascalCase = (str) => {
                let words = str.split('-');
                return words.map(w => w.substring(0, 1).toUpperCase() + w.substring(1).toLowerCase()).join('');
            };

        let format = helper.config.mapConfigConvertion(obj.format, this.main.config.xpConfig.mapLibrary);

        return uiObjectsFormat[kebabToPascalCase(format)];
    }

    getUIObjectConfig(obj) {
        let ctor = this.getUIObjectCtor(obj.value);
        return ctor.getAuthConfig();
    }


    addChildTo( id , parentId, section = null ){
        let uiobj = this.get( id );
        let parentUI = this.get( parentId );
        if( parentUI && uiobj )
            parentUI.addChild( uiobj, section );
    }

    removeFrom( id, parentId ){
        let uiobj = this.get( id );
        let parentUI = this.get( parentId );
        if( parentUI && uiobj )
            parentUI.removeChild( uiobj );
    }

    deleteUIObject( id ){
        if( this.uiObjects[id] )
            delete this.uiObjects[id];

    }

    deleteFrom( id , parentId ){
        let uiobj = this.get( id );
        if (uiobj) {
            let parentUI = this.get( parentId );
            if (parentUI) {
                parentUI.removeChild( uiobj );   
            }
        }
    }

    addTag( id, tag ){
        // @todo
    }

    removeTag( id, tag ){
        // @todo
    }

    listTag( id ){
        return []; // @todo
    }
    

}

export let ui = new UIObjectStore();