import ActionAbstract from '../ActionAbstract';

class DeleteMedia extends ActionAbstract {

    static get ID() {
        return 'actions:library:deleteMedia';
    }

    run(state) {
        // @todo: warn first if media is in use
        /*
        // delete media directly
        this.deps.mainStore.lib.deleteMedia(this.getParam('media')).then(() => {
            this.trigger('library:fetchAssets');
        });
        return false;
        */

        // mark media to be deleted on save (defer deletion)
        let media = this.getParam('media');
        let mediaSet = this.deps.mainStore.lib.findById(media._id);

        if (mediaSet.length) {
            let compositeState = { library : {
                    waiting: true
            } };

            compositeState.library['.mediaList['+mediaSet[0].index+']._deleted'] = true;
            this.trigger('library:fetchAssets');
            
            return this.composeState(state, compositeState);
        }
        return false;
    }
}

export default DeleteMedia;