/**
 * Register/unregister and manage a media asset in template.
 * 
 *      { action: 'register',   media: MEDIA_ID, target: TARGET_BLOC_ID }
 *      { action: 'unregister', media: MEDIA_ID, target: TARGET_BLOC_ID }
 *      { action: 'delete', media: MEDIA_ID }
 */
import ActionAbstract from '../ActionAbstract'
import * as helper from '../../helper'

class Media extends ActionAbstract {

    static get ID() {
        return 'actions:template:media'
    }

    run(state) {

        // action require to work with template
        if (state.workspace.target != 'template')
            return false

        let media  = this.getParam('media')
        let target = this.getParam('target')

        // @todo: register target (i.e bloc)
        // @todo: unregister and mark _deleted if no longer bloc use this media
        // see ticket NS-158 : https://orbe.atlassian.net/jira/software/projects/NS/boards/1?selectedIssue=NS-158
        switch (this.getParam('action')) {

            case 'register' :

                let cstate = { project: {} }
                let [template,path] = helper.block.getTemplateById(state.project, state.workspace.id, true)
                let exists = (template.media.indexOf(media) != -1)

                // include refernce once
                if (!exists) cstate.project[path + '.media'] = this.arrayPush(media)
                return exists ? false : this.composeState(state, cstate)

            // @todo
            case 'unregister':
                return false

            // @todo
            case 'delete':
                return false
        }

    }
}

export default Media