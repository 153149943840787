<!-- LinkMenu vue -->
<template lang="pug">
div#link-menu-ctn
	div.cover(v-bind:class="{'open':open}", v-on:click="closeMenu()")
	div.link-menu-positioner(v-if="open != false",  v-bind:style="{transform: 'translate('+appState.editor.linkmenu.pos.x+'px , '+appState.editor.linkmenu.pos.y+'px )'}")
		div.link-menu(v-bind:class="[{'open':open}, {'freeze':freeze}, {'error':open == 'error'}]")
			div.title 
				span Link options
			div.group
				div.subtitle
					span Action
				ul.list
					li(v-if="activation.isActivable && !activation.forceActivable", v-on:click="toggleActivation()", v-bind:class="{'active':isActivated}") Activation link
					li.auto(v-if="activation.forceActivable") Activation link (auto)
					li(v-on:click="deleteLink") Delete link
				div.subtitle
					span Priority
				ul.list
					li
						input(placeholder="Priority", v-model="priority" v-on:change="updatePriority") 
			div.group(v-if="appState.editor.linkmenu.conversions.length > 0")
				div.subtitle
					span Conversions
				ul.list
					li(v-for="conversion of appState.editor.linkmenu.conversions", v-on:click="updateCast(conversion)", v-bind:class="[{'active':currentConv==conversion},{'auto':appState.editor.linkmenu.conversions.length == 1}]") {{conversion}}

		svg.error-bt(v-bind:class="[{'open':open == 'error'}]")
			g.icon(v-on:click.stop="deleteLink(link)")
				circle(cx="10", cy="10", r="10", fill="#58585B", stroke-width="0")
				path.icon-x(d="M 7,7 L 13,13 M 13,7 L 7,13")


</template>

<script>

// STORE
import { stores } from '../../../stores/MainStore.js';
import actionManager from '../../../actions';
import * as helper from '../../../helper';

export default {

	props: ['appState', 'blocs'],

	data() {
		return {
			freeze: false,
			priority: 0
		}
	},

	created: function(){


	},

	computed: {

		'open': function(){
			if( this.appState.editor && this.appState.editor.linkmenu ) {
				if (this.appState.editor.linkmenu.open && this.appState.editor.linkmenu.link && this.appState.editor.linkmenu.link.priority)
					this.priority = this.appState.editor.linkmenu.link.priority;
				else 
					this.priority = 0;	

				return this.appState.editor.linkmenu.open;
			}
			this.priority = 0;
			return false;
		},

		'activation' : function(){
			if( this.open )	
				return this.appState.editor.linkmenu.activation;
		}, 

		'isActivated' : function(){
			if( this.open )	{
				if( this.appState.editor.linkmenu.forceActivable )
					return true;
				if( this.appState.editor.linkmenu.link.activated )
					return true;
			}
			return false;
		},

		'currentConv' : function(){
			if( this.open && this.appState.editor.linkmenu.link && this.appState.editor.linkmenu.conversions.indexOf(this.appState.editor.linkmenu.link.conversion) !== -1 )	{
				return this.appState.editor.linkmenu.link.conversion;
			}
			return null;
		}

	},

	watch:{

	},

	methods:{

		closeMenu: function( ){

			if( this.appState.editor.linkmenu && Array.isArray(this.appState.editor.linkmenu.conversions) && this.appState.editor.linkmenu.conversions.length > 1 && !this.currentConv ){
				this.freeze = true;
				setTimeout(function(){ this.freeze = false; }.bind(this), 5000);
				return;
			}

			actionManager.trigger('editor:schematic:toggleLinkMenu', {subaction: 'close'});

		},


		deleteLink: function( ){
			if( this.appState.editor.linkmenu ) {
				let link = this.appState.editor.linkmenu.link;
				actionManager.trigger('editor:schematic:deleteLink', {blocId : link.from.blocID, link : link });
			}
			actionManager.trigger('editor:schematic:toggleLinkMenu', {subaction: 'close'});
		},
 

		updateCast: function(conv){
			if( this.appState.editor.linkmenu.conversions.length > 1 ){
				//this.currentConv = conv;
				let link = this.appState.editor.linkmenu.link;
				actionManager.trigger('editor:schematic:updateLinkCast', {blocId : link.from.blocID, link : link, cast : conv });
			}
		},

		updatePriority: function(){
			if( this.appState.editor.linkmenu.link){
				let link = this.appState.editor.linkmenu.link;
				actionManager.trigger('editor:schematic:updateLinkPriority', {blocId : link.from.blocID, link : link, priority : this.priority });
			}
		},

		toggleActivation: function(){

			if( this.activation.isActivable ){

				let link = this.appState.editor.linkmenu.link;

				if( this.activation.forceActivable ){
            		actionManager.trigger('editor:schematic:updateLinkActivation', { blocId : link.from.blocID, link : link, value : true });
					return;
				}

				actionManager.trigger('editor:schematic:updateLinkActivation', { blocId : link.from.blocID, link : link, value : !this.isActivated });

			}
		}

	}

}

</script>

<style lang="stylus">
#link-menu-ctn
	
	.cover
		position absolute
		top 0
		left 0
		right 0
		bottom 0
		display none
		opacity 0
		z-index 100
		
		&.open
			display block
			
	.link-menu-positioner
		position: absolute;
		top: 0
		left: 0
		z-index 101
		
	.link-menu
		position: absolute;
		bottom: 0
		left: 0
		width: 200px
		height: auto
		padding: 0px
		background: var(--bgColor)
		border-radius: 8px
		padding: 5px
		font-family 'DinRegular'
		font-size 13px
		color: var(--textColor)

		box-shadow: 0 0 10px var(--shadowColor)
		
		visibility hidden
		transform-origin 0% 100%
		transform: scale(0.5) translateY(0px) translateX(-45%)
		opacity 0
		transition 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1)
		
		&.error
			display none
			
		&.open
			transform: scale(1)	translateY(-3px) translateX(-45%)
			opacity 1
			visibility visible
			
		&.freeze
			animation freeze 0.3s

		:after
			content: ''
			position: absolute
			border-style: solid
			border-width: 6px 6px 0
			border-color: var(--bgColor) transparent
			display: block
			width: 0
			z-index: 1
			bottom: -6px
			left: 91px
			
		.title
			display: block
			background-color: var(--breadColor)
			border-radius: 8px 8px 0px 0px
			color: #ffffff
			height: 32px
			line-height: 34px
			font-family: 'DinBold'
			padding: 0px 10px
			margin: -5px -5px 0px -5px
			
		.group
			margin-top: 5px
			background: var(--linkMenuColor)
			padding-bottom: .1px
			border-radius: 4px
			
			.subtitle
				display block
				height: 32px
				line-height: 32px
				border-radius: 4px
				background-color: var(--linkMenuTopic)
				padding 0px 10px
				color: var(--inspectorText)
				
			ul
				list-style-type none
				margin 0
				padding 0
				font-size 14px
				
				li 
					display: block
					padding: 0px 5px
					cursor: pointer
					height: 32px
					line-height: 32px
					border-radius: 4px
					background: var(--bgColor)
					color: var(--inputTextColor)
					margin: 5px
					text-transform: capitalize
					transition: all 0.2s ease-in-out

					input
						border: 0
						height: 32px
						line-height: 32px
						width: calc(100% + 0px)
						margin-left: -5px
						padding: 0 5px
						border-radius: 4px
						font-size: 14px
						color: var(--inspectorText)
						background: var(--bgColor)
						font-family: 'DinRegular'
						outline: none
						&:hover
							background: var(--inspectorTargetOverColor)
						
					&:hover, &.active
						background-color rgba(250,212,197,1)
					&.active
						font-family 'DinBold', 'ComfortaaBold'
						
					&.auto
						font-family 'DinRegular', 'ComfortaaLight'
						color #B6B7B8
						cursor auto
						&:hover
							background-color: #FFF
						
.error-bt
	position: absolute;
	top: 5px
	left: 0
	width 20px
	height 20px
	transform scale(1) translate(-50%, -50%)
	transform-origin 50% 50%
		
	.icon 
		cursor: pointer
		transform: scale(0) 
		transition: all 0.3s cubic-bezier(0.770, 0.000, 0.175, 1.000)
		transform-origin 50% 50%
		
	&.open
		.icon
			transform: scale(1) 

.icon-x
	stroke: white
	fill: transparent
	stroke-linecap: round
	stroke-width: 2
	

@keyframes freeze {
	0%, 100% {
		transform scale(1) translateY(-3px) translateX(-45%)
	}
	50% {
		transform scale(1.1) translateY(-10px) translateX(-45%)
	}
}

</style>

