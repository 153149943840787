<!--
    Icon component

    @desc: Icon component
-->

<template lang="pug">
span.icon(v-bind:class="name")
</template>

<script>
//  If the container of the svg is a span the size increase from the baseline. 
//  To center using flex we need to apply a margin of 50% of the height of the span.
//  Need to change to a DIV Container - block !
 
export default {
	props: ['name'],
}

</script>

<!-- CSS WITHOUT autoprefix
------------------------- DO NOT REMOVE SHOUL BE FIXED (SEE BELOW) ---------------------------------
<style lang="stylus">
.icon
	background-size: 20px 20px
	background-position: center center
	background-repeat: no-repeat
	transform: translateY(-50%)

	&.project
		mask: url(../../assets/images/icons/project.svg) no-repeat 100% 100%
		mask-size: cover

	&.experience
		mask: url(../../assets/images/icons/experience.svg) no-repeat 100% 100%
		mask-size: cover

	&.screen
		mask: url(../../assets/images/icons/screen.svg) no-repeat 100% 100%
		mask-size: cover

	&.trigger
		mask: url(../../assets/images/icons/trigger.svg) no-repeat 100% 100%
		mask-size: cover

	&.process
		mask: url(../../assets/images/icons/process.svg) no-repeat 100% 100%
		mask-size: cover

	&.memory
		mask: url(../../assets/images/icons/memory.svg) no-repeat 100% 100%
		mask-size: cover

	&.export
		mask: url(../../assets/images/icons/export.svg) no-repeat 100% 100%
		mask-size: cover

	&.note
		mask: url(../../assets/images/icons/note.svg) no-repeat 100% 100%
		mask-size: cover

	&.quit
		mask: url(../../assets/images/icons/quit.svg) no-repeat 100% 100%
		mask-size: cover

	&.chevron
		mask: url(../../assets/images/icons/chevron.svg) no-repeat 50% 50%
		mask-size: cover

	&.arrow-select
		mask: url(../../assets/images/icons/arrow-select.svg) no-repeat 50% 50%
		mask-size: cover

	&.order-asc
		mask: url(../../assets/images/icons/order-asc.svg) no-repeat 50% 50%
		mask-size: cover

	&.order-desc
		mask: url(../../assets/images/icons/order-desc.svg) no-repeat 50% 50%
		mask-size: cover

	&.audio
		mask: url(../../assets/images/icons/audio.svg) no-repeat 50% 50%
		mask-size: cover

	&.group-asset
		mask: url(../../assets/images/icons/group-asset.svg) no-repeat 50% 50%
		mask-size: cover

	&.fmod
		mask: url(../../assets/images/icons/fmod.svg) no-repeat 50% 50%
		mask-size: cover

	&.debug
		mask: url(../../assets/images/icons/debug.svg) no-repeat 50% 50%
		mask-size: cover

	&.graphics
		mask: url(../../assets/images/icons/graphics.svg) no-repeat 50% 50%
		mask-size: cover

	&.inputs
		mask: url(../../assets/images/icons/inputs.svg) no-repeat 50% 50%
		mask-size: cover

	&.outputs
		mask: url(../../assets/images/icons/outputs.svg) no-repeat 50% 50%
		mask-size: cover

	&.map
		mask: url(../../assets/images/icons/map.svg) no-repeat 50% 50%
		mask-size: cover

	&.modules
		mask: url(../../assets/images/icons/module.svg) no-repeat 50% 50%
		mask-size: cover

	&.network
		mask: url(../../assets/images/icons/network.svg) no-repeat 50% 50%
		mask-size: cover

	&.recent
		mask: url(../../assets/images/icons/recent.svg) no-repeat 50% 50%
		mask-size: cover

	&.time
		mask: url(../../assets/images/icons/time.svg) no-repeat 50% 50%
		mask-size: cover

	&.transform
		mask: url(../../assets/images/icons/transform.svg) no-repeat 50% 50%
		mask-size: cover

	&.uimodule
		mask: url(../../assets/images/icons/uimodule.svg) no-repeat 50% 50%
		mask-size: cover

	&.video
		mask: url(../../assets/images/icons/library/video.svg) no-repeat 50% 50%
		mask-size: cover

	&.sound
		mask: url(../../assets/images/icons/library/sound.svg) no-repeat 50% 50%
		mask-size: cover

	&.file
		mask: url(../../assets/images/icons/library/file.svg) no-repeat 50% 50%
		mask-size: cover

	&.search
		mask: url(../../assets/images/icons/search.svg) no-repeat 50% 50%
		mask-size: cover

	&.search-close
		mask: url(../../assets/images/icons/close.svg) no-repeat 50% 50%
		mask-size: cover

	&.recentlyused
		mask: url(../../assets/images/icons/recentlyused.svg) no-repeat 50% 50%
		mask-size: cover

	&.audio-signal
		mask: url(../../assets/images/icons/audiosignal.svg) no-repeat 50% 50%
		mask-size: cover

	&.objects
		mask: url(../../assets/images/icons/object.svg) no-repeat 50% 50%
		mask-size: cover

	&.space
		mask: url(../../assets/images/icons/space.svg) no-repeat 50% 50%
		mask-size: cover

	&.components
		mask: url(../../assets/images/icons/components.svg) no-repeat 50% 50%
		mask-size: cover

	&.blocks
		mask: url(../../assets/images/icons/blocks.svg) no-repeat 50% 50%
		mask-size: cover

	&.templates
		mask: url(../../assets/images/icons/templates.svg) no-repeat 50% 50%
		mask-size: cover

	&.current
		mask: url(../../assets/images/icons/current.svg) no-repeat 50% 50%
		mask-size: cover

	&.vector2d
		mask: url(../../assets/images/icons/vector2d.svg) no-repeat 50% 50%
		mask-size: cover

	&.template-process
		mask: url(../../assets/images/icons/template/template-process.svg) no-repeat 100% 100%
		mask-size: cover

	&.template-item-process
		mask: url(../../assets/images/icons/template/template-item-process.svg) no-repeat 50% 50%
		mask-size: cover

	&.template-item-screen
		mask: url(../../assets/images/icons/template/template-item-screen.svg) no-repeat 50% 50%
		mask-size: cover

	&.template-item-widget
		mask: url(../../assets/images/icons/template/template-item-widget.svg) no-repeat 50% 50%
		mask-size: cover

	&.template-save
		mask: url(../../assets/images/icons/template/template-save.svg) no-repeat 50% 50%
		mask-size: cover

	&.template-delete
		mask: url(../../assets/images/icons/template/template-delete.svg) no-repeat 50% 50%
		mask-size: cover

	&.assets
		mask: url(../../assets/images/icons/assets.svg) no-repeat 50% 50%
		mask-size: cover

	&.layers
		mask: url(../../assets/images/icons/layers.svg) no-repeat 50% 50%
		mask-size: cover

	&.logic
		mask: url(../../assets/images/icons/logic.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-screen, &.object-ui-widget
		mask: url(../../assets/images/icons/ui/screen.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-button
		mask: url(../../assets/images/icons/ui/button.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-layout-2-col
		mask: url(../../assets/images/icons/ui/2cols.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-box
		mask: url(../../assets/images/icons/ui/box.svg) no-repeat 50% 50%
		mask-size: cover

	&.section
		mask: url(../../assets/images/icons/ui/box.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-image
		mask: url(../../assets/images/icons/ui/image.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-canvas
		mask: url(../../assets/images/icons/ui/canvas.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-canvas
		mask: url(../../assets/images/icons/ui/canvas.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-checkbox
		mask: url(../../assets/images/icons/ui/check.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-radio
		mask: url(../../assets/images/icons/ui/radio.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-paragraph
		mask: url(../../assets/images/icons/ui/paragraph.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-selectfield
		mask: url(../../assets/images/icons/ui/selector.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-slider
		mask: url(../../assets/images/icons/ui/slider.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-textfield
		mask: url(../../assets/images/icons/ui/textfield.svg) no-repeat 50% 50%
		mask-size: cover
	
	&.object-ui-input-text
		mask: url(../../assets/images/icons/ui/textfield.svg) no-repeat 50% 50%
		mask-size: cover

	&.object-ui-toggle
		mask: url(../../assets/images/icons/ui/toggle.svg) no-repeat 50% 50%
		mask-size: cover



	&.HOME
		mask: url(../../assets/images/icons/home.svg) no-repeat 50% 50%
		mask-size: cover

	&.SAVE
		mask: url(../../assets/images/icons/save.svg) no-repeat 50% 50%
		mask-size: cover

	&.DOC
		mask: url(../../assets/images/icons/doc.svg) no-repeat 50% 50%
		mask-size: cover

	&.SESSION
		mask: url(../../assets/images/icons/menuSession.svg) no-repeat 50% 50%
		mask-size: cover

	&.UNDO
		mask: url(../../assets/images/icons/undo.svg) no-repeat 50% 50%
		mask-size: cover

	&.REDO
		mask: url(../../assets/images/icons/redo.svg) no-repeat 50% 50%
		mask-size: cover

	&.LIBRARY
		mask: url(../../assets/images/icons/library.svg) no-repeat 100% 100%
		mask-size: cover

	&.TEMPLATE
		mask: url(../../assets/images/icons/template/template.svg) no-repeat 100% 100%
		mask-size: cover

	&.play-exp
		mask: url(../../assets/images/icons/playexp.svg) no-repeat 100% 100%
		mask-size: cover

	&.widget
		mask: url(../../assets/images/icons/widget.svg) no-repeat 50% 50%
		mask-size: cover

	&.process-instance
		mask: url(../../assets/images/icons/process-instance.svg) no-repeat 50% 50%
		mask-size: cover

	&.grid
		mask: url(../../assets/images/icons/grid.svg) no-repeat 50% 50%
		mask-size: cover

	&.list
		mask: url(../../assets/images/icons/list.svg) no-repeat 50% 50%
		mask-size: cover
	
	&.share
		mask: url(../../assets/images/icons/share.svg) no-repeat 50% 50%
		mask-size: cover
</style>
------------------------ DO NOT REMOVE SHOUL BE FIXED (SEE BELOW) ----------------------------------
-->

<!--
	FIXME: add postcss-loader and autoprefix modules to use non-prefixed CSS rules only.
	npm i -D postcss-loader autoprefixer
	https://cli.vuejs.org/guide/css.html#postcss
-->
<style lang="stylus">
.icon
	background-size: 20px 20px
	background-position: center center
	background-repeat: no-repeat
	transform: translateY(-50%)

	&.project
		-webkit-mask: url(../../assets/images/icons/project.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/project.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.experience
		-webkit-mask: url(../../assets/images/icons/experience.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/experience.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.screen
		-webkit-mask: url(../../assets/images/icons/screen.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/screen.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.trigger
		-webkit-mask: url(../../assets/images/icons/trigger.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/trigger.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.process
		-webkit-mask: url(../../assets/images/icons/process.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/process.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.memory
		-webkit-mask: url(../../assets/images/icons/memory.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/memory.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	/*&.export
		-webkit-mask: url(../../assets/images/icons/export.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/export.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover*/

	&.note
		-webkit-mask: url(../../assets/images/icons/note.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/note.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.others
		-webkit-mask: url(../../assets/images/icons/others.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/others.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.quit
		-webkit-mask: url(../../assets/images/icons/quit.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/quit.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.chevron
		-webkit-mask: url(../../assets/images/icons/chevron.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/chevron.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.arrow-select
		-webkit-mask: url(../../assets/images/icons/arrow-select.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/arrow-select.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.order-asc
		-webkit-mask: url(../../assets/images/icons/order-asc.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/order-asc.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.order-desc
		-webkit-mask: url(../../assets/images/icons/order-desc.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/order-desc.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.audio
		-webkit-mask: url(../../assets/images/icons/audio.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/audio.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.group-asset
		-webkit-mask: url(../../assets/images/icons/group-asset.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/group-asset.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
	
	&.fmod
		-webkit-mask: url(../../assets/images/icons/fmod.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/fmod.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.debug
		-webkit-mask: url(../../assets/images/icons/debug.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/debug.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.graphics
		-webkit-mask: url(../../assets/images/icons/graphics.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/graphics.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.inputs
		-webkit-mask: url(../../assets/images/icons/inputs.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/inputs.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.outputs
		-webkit-mask: url(../../assets/images/icons/outputs.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/outputs.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.map
		-webkit-mask: url(../../assets/images/icons/map.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/map.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.progress
		-webkit-mask: url(../../assets/images/icons/progress.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/progress.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.elements
		-webkit-mask: url(../../assets/images/icons/elements.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/elements.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover


	&.modules
		-webkit-mask: url(../../assets/images/icons/module.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/module.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.network
		-webkit-mask: url(../../assets/images/icons/network.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/network.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.recent
		-webkit-mask: url(../../assets/images/icons/recent.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/recent.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.time
		-webkit-mask: url(../../assets/images/icons/time.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/time.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.transform
		-webkit-mask: url(../../assets/images/icons/transform.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/transform.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.uimodule
		-webkit-mask: url(../../assets/images/icons/uimodule.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/uimodule.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.video
		-webkit-mask: url(../../assets/images/icons/library/video.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/library/video.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.sound
		-webkit-mask: url(../../assets/images/icons/library/sound.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/library/sound.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.file
		-webkit-mask: url(../../assets/images/icons/library/file.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/library/file.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.search
		-webkit-mask: url(../../assets/images/icons/search.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/search.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.search-close
		-webkit-mask: url(../../assets/images/icons/search-close.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/search-close.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.recentlyused
		-webkit-mask: url(../../assets/images/icons/recentlyused.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/recentlyused.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.audio-signal
		-webkit-mask: url(../../assets/images/icons/audiosignal.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/audiosignal.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.output
		-webkit-mask: url(../../assets/images/icons/outputs.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/outputs.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.objects
		-webkit-mask: url(../../assets/images/icons/object.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/object.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.space
		-webkit-mask: url(../../assets/images/icons/space.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/space.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.components
		-webkit-mask: url(../../assets/images/icons/components.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/components.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.blocks
		-webkit-mask: url(../../assets/images/icons/blocks.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/blocks.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.templates
		-webkit-mask: url(../../assets/images/icons/templates.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/templates.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.menuTemplates
		-webkit-mask: url(../../assets/images/icons/menuTemplates.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/menuTemplates.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.current
		-webkit-mask: url(../../assets/images/icons/current.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/current.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.vector2d
		-webkit-mask: url(../../assets/images/icons/vector2d.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/vector2d.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.template-process
		-webkit-mask: url(../../assets/images/icons/template/template-process.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/template/template-process.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.template-item-process
		-webkit-mask: url(../../assets/images/icons/template/template-item-process.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/template/template-item-process.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.template-item-screen
		-webkit-mask: url(../../assets/images/icons/template/template-item-screen.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/template/template-item-screen.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.template-item-widget
		-webkit-mask: url(../../assets/images/icons/template/template-item-widget.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/template/template-item-widget.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.template-save
		-webkit-mask: url(../../assets/images/icons/template/template-save.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/template/template-save.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.template-delete
		-webkit-mask: url(../../assets/images/icons/template/template-delete.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/template/template-delete.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover


	&.assets
		-webkit-mask: url(../../assets/images/icons/assets.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/assets.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.layers
		-webkit-mask: url(../../assets/images/icons/layers.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/layers.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.logic
		-webkit-mask: url(../../assets/images/icons/logic.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/logic.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-screen, &.object-ui-widget
		-webkit-mask: url(../../assets/images/icons/ui/htmlview.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/htmlview.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-button
		-webkit-mask: url(../../assets/images/icons/ui/button.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/button.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-layout-2-col
		-webkit-mask: url(../../assets/images/icons/ui/2cols.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/2cols.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-box
		-webkit-mask: url(../../assets/images/icons/ui/	box.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/box.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.section
		-webkit-mask: url(../../assets/images/icons/ui/box.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/box.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-image
		-webkit-mask: url(../../assets/images/icons/ui/image.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/image.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-canvas
		-webkit-mask: url(../../assets/images/icons/ui/canvas.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/canvas.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-checkbox
		-webkit-mask: url(../../assets/images/icons/ui/check.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/check.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-radio
		-webkit-mask: url(../../assets/images/icons/ui/radio.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/radio.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-paragraph
		-webkit-mask: url(../../assets/images/icons/ui/paragraph.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/paragraph.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-selectfield
		-webkit-mask: url(../../assets/images/icons/ui/selector.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/selector.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-slider
		-webkit-mask: url(../../assets/images/icons/ui/slider.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/slider.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-textfield
		-webkit-mask: url(../../assets/images/icons/ui/textfield.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/textfield.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-input-text
		-webkit-mask: url(../../assets/images/icons/ui/textfield.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/textfield.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-toggle
		-webkit-mask: url(../../assets/images/icons/ui/toggle.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/toggle.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-map
		-webkit-mask: url(../../assets/images/icons/ui/map.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/map.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-sliderfield
		-webkit-mask: url(../../assets/images/icons/ui/sliderfield.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/sliderfield.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-video
		-webkit-mask: url(../../assets/images/icons/ui/videoplayer.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/videoplayer.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-map-layer
		-webkit-mask: url(../../assets/images/icons/ui/maplayer.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/maplayer.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-map-line-path
		-webkit-mask: url(../../assets/images/icons/ui/maplinepath.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/maplinepath.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-line-path
		-webkit-mask: url(../../assets/images/icons/ui/linepath.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/linepath.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-map-marker
		-webkit-mask: url(../../assets/images/icons/ui/mapmarker.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/mapmarker.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-circular-progress-bar
		-webkit-mask: url(../../assets/images/icons/ui/circularprogressbar.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/circularprogressbar.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-linear-progress-bar
		-webkit-mask: url(../../assets/images/icons/ui/linearprogressbar.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/linearprogressbar.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-stream-graph
		-webkit-mask: url(../../assets/images/icons/ui/streamgraph.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/streamgraph.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.object-ui-choose-file
		-webkit-mask: url(../../assets/images/icons/ui/choosefile.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/ui/choosefile.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.HOME
		-webkit-mask: url(../../assets/images/icons/menuHome.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/menuHome.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.SAVE
		-webkit-mask: url(../../assets/images/icons/menuSave.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/menuSave.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.DOC
		-webkit-mask: url(../../assets/images/icons/menuDoc.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/menuDoc.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.SESSION
		-webkit-mask: url(../../assets/images/icons/menuSession.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/menuSession.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.UNDO
		-webkit-mask: url(../../assets/images/icons/menuUndo.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/menuUndo.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.REDO
		-webkit-mask: url(../../assets/images/icons/menuRedo.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/menuRedo.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.LIBRARY
		-webkit-mask: url(../../assets/images/icons/menuLibrary.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/menuLibrary.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.TEMPLATE
		-webkit-mask: url(../../assets/images/icons/menuTemplate.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/menuTemplate.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.play-exp
		-webkit-mask: url(../../assets/images/icons/playexp.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/pla	yexp.svg) no-repeat 100% 100%
		-webkit-mask-size: cover
		mask-size: cover

	&.widget
		-webkit-mask: url(../../assets/images/icons/widget.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/widget.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.process-instance
		-webkit-mask: url(../../assets/images/icons/process-instance.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/process-instance.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.grid
		-webkit-mask: url(../../assets/images/icons/grid.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/grid.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.list
		-webkit-mask: url(../../assets/images/icons/list.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/list.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.delete
		-webkit-mask: url(../../assets/images/icons/modal_delete.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_delete.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		// NavigatorItem , NavigatorUiItem , StateManager , ProjectDeleteCreatePopup

	&.edit
		-webkit-mask: url(../../assets/images/icons/modal_edit.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_edit.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		// TrainingSetList

	&.error
		-webkit-mask: url(../../assets/images/icons/modal_error.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_error.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		// CreateBlock , ImportJson , PasteBlock

	&.exclamation
		-webkit-mask: url(../../assets/images/icons/modal_exclamation.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_exclamation.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		// FieldManager , GeoField

	&.help
		-webkit-mask: url(../../assets/images/icons/modal_help.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_help.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		// Modal


	&.new
		-webkit-mask: url(../../assets/images/icons/modal_new.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_new.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		// AddTrainingSet

	&.notfound
		-webkit-mask: url(../../assets/images/icons/modal_not_found.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_not_found.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		// UpdateFields , AssetField  , ObjectIncluder

	&.secret
		webkit-mask: url(../../assets/images/icon-eye.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icon-eye.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		// getProject for secret memories

	&.save
		-webkit-mask: url(../../assets/images/icons/modal_save.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_save.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		// TopMenu

	&.share
		-webkit-mask: url(../../assets/images/icons/modal_share.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_share.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.shareGrey
		-webkit-mask: url(../../assets/images/icons/modal_share.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_share.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.icon_author
		-webkit-mask: url(../../assets/images/icons/icon_author.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/icon_author.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.icon_owner
		-webkit-mask: url(../../assets/images/icons/icon_owner.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/icon_owner.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.icon_reader
		-webkit-mask: url(../../assets/images/icons/icon_reader.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/icon_reader.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.label_shared
		-webkit-mask: url(../../assets/images/icons/label_shared.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/label_shared.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.cross
		-webkit-mask: url(../../assets/images/icons/cross.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/cross.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
	&.template-popup-screen
		-webkit-mask: url(../../assets/images/icons/template-popup-screen.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/template-popup-screen.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.template-popup-widget
		-webkit-mask: url(../../assets/images/icons/template-popup-widget.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/template-popup-widget.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		
	&.template-popup-process
		-webkit-mask: url(../../assets/images/icons/template-popup-process.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/template-popup-process.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.angle
		-webkit-mask: url(../../assets/images/memory/angle.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/angle.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.array
		-webkit-mask: url(../../assets/images/memory/array.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/array.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.asset
		-webkit-mask: url(../../assets/images/memory/asset.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/asset.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.boolean
		-webkit-mask: url(../../assets/images/memory/boolean.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/boolean.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
	
	&.clock
		-webkit-mask: url(../../assets/images/memory/clock.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/clock.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.color
		-webkit-mask: url(../../assets/images/memory/color.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/color.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
	
	&.float
		-webkit-mask: url(../../assets/images/memory/float.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/float.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.geo
		-webkit-mask: url(../../assets/images/memory/geo.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/geo.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
	
	&.geoshape
		-webkit-mask: url(../../assets/images/memory/geoshape.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/geoshape.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.int
		-webkit-mask: url(../../assets/images/memory/int.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/int.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.jsontype
		-webkit-mask: url(../../assets/images/memory/jsonType.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/jsonType.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.percent
		-webkit-mask: url(../../assets/images/memory/percent.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/percent.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.string
		-webkit-mask: url(../../assets/images/memory/string.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/string.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
	
	&.vector2
		-webkit-mask: url(../../assets/images/memory/vector2.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/vector2.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
	
	&.shape2d
		-webkit-mask: url(../../assets/images/memory/shape2d.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/shape2d.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover
		
	&.vector3
		-webkit-mask: url(../../assets/images/memory/vector3.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/vector3.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.session
		-webkit-mask: url(../../assets/images/memory/session.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/session.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.secret
		-webkit-mask: url(../../assets/images/memory/secret.svg) no-repeat 50% 50%
		mask: url(../../assets/images/memory/secret.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.arrowNext
		-webkit-mask: url(../../assets/images/icons/arrowNext.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/arrowNext.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.duplicate
		-webkit-mask: url(../../assets/images/icons/modal_duplicate.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_duplicate.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.duplicateGrey
		-webkit-mask: url(../../assets/images/icons/modal_duplicate.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/modal_duplicate.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.jsonCompact
		-webkit-mask: url(../../assets/images/icons/jsonCompact.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/jsonCompact.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.jsonFormat
		-webkit-mask: url(../../assets/images/icons/jsonFormat.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/jsonFormat.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.download
		-webkit-mask: url(../../assets/images/icons/download.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/download.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.JSON
		-webkit-mask: url(../../assets/images/icons/json.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/json.svg) no-repeat 50% 50%
		-webkit-mask-size: cover
		mask-size: cover

	&.jsonWatermark
		-webkit-mask: url(../../assets/images/icons/library/watermark_json.svg) no-repeat 50% 50%
		mask: url(../../assets/images/icons/library/watermark_json.svg) no-repeat 50% 50%
		-webkit-mask-size: contain
		mask-size: contain
</style>
	