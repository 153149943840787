import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';
import deepCopy from 'deepcopy';

class DeleteLink extends ActionAbstract {

    /**
     * @param blocId : id of block
     * @param link : data of link (from & to)
    **/

    static get ID() {
        return 'actions:editor:schematic:deleteLink';
    }

    run(state) {
        let path;
        let block = null;
        [block,path] = helper.block.getBlockById( state.project, this.getParam('blocId') , true );
        
        let link = this.getParam('link');
        let linkIndex = helper.links.getIndexOfLink( block, link );
		
		if( linkIndex != -1 ){
            let compositeState = { project: {} };
            
            // mark link has deleted
            compositeState.project[path+'.value.links'] = this.arraySplice( linkIndex );

            return this.composeState(state, compositeState );
        }

        return false;

    
    }


}

export default DeleteLink;