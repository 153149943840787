
<template lang="pug">

div#content
    h1 Nodes

    section.doc-theme.project(v-on:click.stop="displayTab('node-project')")
        span Project

    section.doc-theme.experience(v-on:click.stop="displayTab('node-experience')")
        span Experience

    section.doc-theme.screen(v-on:click.stop="displayTab('node-screen')")
        span Screen

</template>

<script>

	export default {


		props:['displayTab'],

		data() {
			return {
			};
		},

		created: function(){

		},


	    methods:{


	    }

    }

</script>

<!-- FIXME: use pure CSS style in webpack.config.js (see below)-->
<style lang="stylus">
.doc-theme.project
    background-image: url(../../assets/images/doc/nodes/img-project.jpg);

.doc-theme.experience
    background-image: url(../../assets/images/doc/nodes/img-xp.jpg);

.doc-theme.screen
    background-image: url(../../assets/images/doc/nodes/img-screen.jpg);
</style>


<!-- ORIGINAL CSS
<style>
.doc-theme.project{
    background-image: url(../../assets/images/doc/nodes/img-project.jpg);
}

.doc-theme.experience{
    background-image: url(../../assets/images/doc/nodes/img-xp.jpg);
}

.doc-theme.screen{
    background-image: url(../../assets/images/doc/nodes/img-screen.jpg);
}


</style>
-->