<template lang="pug">
div.menuComp
	span.button.active(v-on:click="viewType('grid')")
		icon(:name="'grid'")
	span.button(v-on:click="viewType('list')")
		icon(:name="'list'" )
	span.filterby Filter by
	div.sort
		div.anchor(v-on:click='toggleShow')
			span {{cap(selected)}}
			icon(:name="'arrow-select'")
		div.menu(v-if='showMenu', v-on:mouseleave="toggleShow")
			div.menu-item(v-for='item in sort.options' v-on:click='itemClicked(item)') {{cap(item)}}
		div.order
			span( v-on:click="setDirection('asc')")
				icon(:name="'order-asc'")
			span( v-on:click="setDirection('desc')")
				icon(:name="'order-desc'")
</template>

<script>
import Icon from './Icon.vue';

String.prototype.capitalize = function() {
	return this.charAt(0).toUpperCase() + this.slice(1);
}

export default {

	name: 'sortMenu',

	// projects, an object containing the list of all projects
	props: ['projects'],

	components: {
		'icon': Icon,
	},

	data: function() {
		return {
			showMenu: false,
			selected: 'updated',
			sort: {
				current: 'name',
				options: ["owner", "name", "updated"],
				direction: {
					name: "asc",
					owner: "asc",
					updated : "desc",
				}
			},
		}
	},

	created: function() {
		this.internalItems = this.items;
		this.internalEditedItem = this.editedItem;
	},

	methods: {

		cap(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},

		viewType: function(type){
			this.projects.view = type;
		},

		toggleShow: function() {
			this.showMenu = !this.showMenu;
		},

		itemClicked: function(item) {
			this.toggleShow();
			this.sortProjects(item);
			this.selected = item;
		},

		setDirection: function(direction) {
			this.sort.direction[this.sort.current] = direction;
			this.sortProjects(this.selected);
		},

		sortProjects: function(selection) {
			if(selection) {
				this.projects.list.sort( (a, b) => {
					if (this.sort.direction[selection] == "asc") { // asc
						if(a.value[selection] < b.value[selection]) { return -1; }
						if(a.value[selection] > b.value[selection]) { return 1; }
						return 0;
					} else { //desc
						if(a.value[selection] > b.value[selection]) { return -1; }
						if(a.value[selection] < b.value[selection]) { return 1; }
						return 0;
					}
				});
				this.sort.current = selection;
			}
		},
	}
}

</script>

<style lang="stylus">
 
.menuComp
	width: 100%
	padding-left: 0px
	display: inline-flex
	align-items: center
	.filterby
		margin: 0 10px 0 10px
	.button
		display: flex
		background-color: #9B9A9A
		width: 26px
		height: 26px
		transform: translateY(0)
		border-radius: 4px
		margin: 0 5px 0 5px
		.icon
			display: block
			background-color: #fff
			width: 29px
			box-sizing: border-box
			transform: translateY(0)
	.active
		background-color: #fff
		.icon
			background-color: #585857

	.sort
		padding: 0 .7vw 0 .7w
		display: inline-flex
		align-items: center
	.anchor
		display: flex
		align-items: center
		justify-content: space-between
		border: 0
		font-size: 14px
		border-radius: 8px
		transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
		font-family: dinBold
		color: var(--textColor)
		background-color: white
		height: 26px
		width: 97px
		outline: none
		padding: 0 10px 0 10px
		.icon
			width: 16px
			height: 16px
			background-color: #585857
			transform: translate(0%)
		&:hover
			color: #585857
			cursor: pointer
			outline: none
			&:hover .shareUserInput
				border-bottom-left-radius: 0
	.order
		display: flex
		height: 26px
		margin-left: 7px
		align-items: center
		span
			display: flex
			align-items: center
			.icon
				width: 10px
				height: 16px
				background-color: #9b9a9a
				transform: translateY(0)
				cursor: pointer

.menu
	box-shadow: 0 0 10px var(--shadowColor)
	font-family: dinBold
	background-color: #fff
	background-clip: padding-box
	border-top-left-radius: 8px
	border-top-right-radius: 8px
	border-bottom-right-radius: 8px
	border-bottom-left-radius: 8px
	color: #585857
	cursor: pointer
	display: flex
	flex-direction: column
	font-size: 14px
	position: absolute
	list-style: none
	margin: 148px 0 0;
	padding: 10px 10px
	text-align: left
	z-index: 3
	width: 117px
	box-sizing: border-box
	
	.menu-item
		text-align: start
		padding: 0 10px 0 10px
		transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
		&:hover
			background-color: var(--menuElementColor);
			cursor: pointer
			border-radius: 5px
			padding: 0 10px 0 10px

</style>
