
import ActionAbstract from '../../ActionAbstract'
import * as helper from '../../../helper'

class SelectUi extends ActionAbstract {
    
    static get ID() {
        return 'actions:editor:ui:select'
    }
    
    run(state) {
        let id = this.getParam('id')
        let target = state.editor.targetId
        let block = helper.block.getBlockById(state.project, id)

        if (block) {
            let format = block.value.format.toLowerCase().replace(/ui-/,'')
            if (this.deps.mainStore.config.editorHtmlViewLayout.indexOf(format) != -1) target = id
        }
        
        return this.composeState(state, {
            editor: {
                targetLayoutId: target,
                selection: {
                    items: [id]
                }
            }
        })
    }
}

export default SelectUi