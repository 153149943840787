<!-- Array vue -->

<template lang="pug">

span.textinput-ctn.array-bt
	div.edit-bt(v-on:click.stop="moduleModeCondition !== false ? openArray() : ''", :class="{'disabled':(field.disabled || moduleModeCondition === false)}")
		span.label Edit data

	
</template>

<script>

import actionManager from '../../../actions';
import { stores } from '../../../stores/MainStore.js';

export default {

	components: { },

	props:[ 'field', 'fieldvalue', 'bloc', 'inspectorField' ],

	data() {
		return {
			targetId : null
		}
	},

	created: function() {
		this.targetId = this.bloc.value._id;
	},

	destroyed: function(){

	},

	watch:{

	},

	methods:{

		openArray: function(){
			if ( this.field.disabled ) return;
			actionManager.trigger('inspector:loadArrayInspector', { targetId: this.targetId, field: this.field });
		}

	}, 

	computed:{
		moduleModeCondition: function(){
			if(this.inspectorField){
				const inspector = this.inspectorField;
				const conditions = this.field.conditions;
				if(!conditions || conditions === undefined ) return;

				let operator = conditions[0].operator;
				let valueToCompare = conditions[0].value;
				let modeModuleField = inspector.children[0].children[0];

				let conditionalRefFieldValue = stores.block.getValueOfField(this.bloc, modeModuleField);
				let fieldValue = conditionalRefFieldValue.value;
				switch( operator ){
						case '==':
							if( valueToCompare != fieldValue )
								return false;
							break;

						case '!=':
							if( valueToCompare == fieldValue ){
								return false;
							}
							break;

						case '>':
							if( valueToCompare <= fieldValue )
								return false;
							break;

						case '<':
							if( valueToCompare >= fieldValue )
								return false;
							break;

						case '>=':
							if( valueToCompare < fieldValue )
								return false;
							break;
						case '<=':
								if( valueToCompare > fieldValue )
									return false;
								break;
						}
			}
		}
	}

}

</script>

<style lang="stylus">
</style>