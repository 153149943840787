
import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class UpdateScene extends ActionAbstract {

    /**
     * @param x : new pos x of scene
     * @param y : new pos y of scene
    **/

    static get ID() {
        return 'actions:editor:schematic:updateScene';
    }

    run(state) {
        let target = helper.block.getBlockById( state.project, state.editor.targetId );
        
        if( !target.value.scene )
            target.value.scene = {};
            
        target.value.scene.coordinate = { x : this.getParam('x'), y : this.getParam('y') };

        // state.editor.scene.coordinate = target.value.scene.coordinate;
        // return false;

        this.composeState(state, { 
            // "target.value.scene.coordinate": { x : this.getParam('x'), y : this.getParam('y') },
            "editor.scene": {
                coordinate: target.value.scene.coordinate,
                scale : 1
            }
        });
    }


}

export default UpdateScene;