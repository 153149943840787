var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "textinput-ctn has-unit" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formattedValue,
          expression: "formattedValue"
        }
      ],
      staticClass: "value",
      attrs: {
        type: "text",
        placeholder: _vm.placeholder,
        disabled: _vm.field.disabled
      },
      domProps: { value: _vm.formattedValue },
      on: {
        focusout: function($event) {
          return _vm.focusOutField(_vm.field)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.formattedValue = $event.target.value
        }
      }
    }),
    _c(
      "span",
      { staticClass: "unit", class: { disabled: _vm.field.disabled } },
      [_vm._v("%")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }