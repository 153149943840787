import TextUiObject from '../TextUiObject';

class UiButton extends TextUiObject {
    constructor(id, props) {

        super(id, props, 'Button');


    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-button",
            label: "Button",
            parent : "ui-elements",
            isDisplayed : true,
            isEmbeddable: true
        };
    }

    initParameters(){
        
        super.initParameters();

        this.setParamInvisible( 'addChild' );
        this.setParamInvisible( 'removeChild' );

        // this.setDefaultValue( 'mevents_onClick', true );
        // this.updateParamConnexion( 'mevents_onClick-trigger', 'out' , {  pluggable: true,  default: true });
        
        this.setDefaultValue('innerText', 'Label' );
        this.setDefaultValue( 'backgroundColor', { hex: "#FFFFFF" } );
        this.setDefaultValue( 'color', { hex: "#000000" } );
        this.setDefaultValue( 'fontSize', 14 );
        this.setDefaultValue( 'lineHeight', 36 );

        this.setDefaultValue( 'boxShadowColor', { rgba: {r:0,g:0,b:0, a:0.117647} } );
        this.setDefaultValue( 'boxShadowDirection', { x: 0, y : 1 });
        this.setDefaultValue( 'boxShadowBlur', 5 );

        this.disableParam( 'hasBorder' );
    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }
    
    init(){
        super.init();

    }

}

export default UiButton;