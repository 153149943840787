import ActionAbstract from '../ActionAbstract';

class Tag extends ActionAbstract {

    static get ID() {
        return 'actions:library:tag';
    }

    run(state) {

        let tagMedias = this.getParam('tagMedias');

        // Add tags on multiple Media
        if (tagMedias) {
            // Cleanup vue observer if needed.
            let newDocMedias = []
            tagMedias.medias.forEach( (media) => {
                let docMedia = Object.assign( {}, media)
                newDocMedias.push(docMedia)
            })
            // post modifications to couchdb.
            this.deps.mainStore.lib.updateMedias(newDocMedias);

        } else {
            let newMedia = Object.assign({}, state.library.mediaPreview),
            newTag = this.getParam('tag');

            // on ne fait rien si le tag est vide ou existe deja
            if( newTag == '' || newMedia.tags.indexOf(newTag) != -1 ) return false;

            // sinon on sauv et reset newTag
            newMedia.tags.push( newTag );

            this.deps.mainStore.lib.updateMedia(newMedia);

            return this.composeState(state, {
                library: {
                    mediaPreview: newMedia
                }
            });
        }


    }

    runRemove(state) {

        let newMedia = Object.assign({}, state.library.mediaPreview),
            tagIndex = this.getParam('tagIndex');

        // sinon on sauv et reset newTag
        newMedia.tags.splice( tagIndex, 1 );

        this.deps.mainStore.lib.updateMedia(newMedia);

        return this.composeState(state, {
            library: {
                mediaPreview: newMedia
            }
        });

    }    

}

export default Tag;