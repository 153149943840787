var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion topic" }, [
    _c(
      "div",
      {
        staticClass: "accordion-title",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.topicAccordion = !_vm.topicAccordion
          }
        }
      },
      [
        _c("span", { staticClass: "text" }, [_vm._v("Array Access")]),
        _c("span", {
          staticClass: "arrow",
          class: { close: !_vm.topicAccordion }
        })
      ]
    ),
    _c(
      "div",
      { staticClass: "form-content", class: { open: _vm.topicAccordion } },
      [
        _c("div", { staticClass: "children" }, [
          _c(
            "div",
            {
              staticClass: "form-accordion array-row-fields group isAccordion"
            },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.accordion = !_vm.accordion
                    }
                  }
                },
                [
                  _c("span", { staticClass: "text" }, [_vm._v("Array Access")]),
                  _c("span", {
                    staticClass: "arrow",
                    class: { close: !_vm.accordion }
                  }),
                  _vm._m(0)
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-accordion-content",
                  class: { open: _vm.accordion }
                },
                [
                  _c(
                    "div",
                    { staticClass: "big-bt field-group" },
                    _vm._l(_vm.fields, function(field) {
                      return _c("field", {
                        attrs: {
                          field: field,
                          bloc: _vm.target,
                          groupfield: true,
                          target: _vm.target
                        }
                      })
                    }),
                    1
                  )
                ]
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legend" }, [
      _c("span", [_vm._v("default")]),
      _c("span", [_vm._v("in")]),
      _c("span", [_vm._v("out")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }