/*
	Objects Store instance -
	all function to get info of Objects
*/

import { EventEmitter } from 'events';
import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

class ObjectsStore extends EventEmitter {
	constructor(){
		super();

        this.allObjects = {};
	}

	setMain( main ){
		this.main = main;
	}

    /*
     * get all objects of project and put it on parent's custom.objects list
     * @project : current project arborescence
     */
    getObjectsOfProject( project ){
        return new Promise( function(resolve, reject){

            // get ids of all blocks
            let idBlocks = [];
            this.main.project.getAllIDsOfProject( idBlocks, project );

            // get objects with a ref_block pointing to one of block in the idBlocks list
			let url = this.main.config.baseURL+'_design/settings/_view/list-object';
            let self = this;

			Vue.http({url: url, method: 'GET' }).then(function (response) {

                self.allObjects = [];

                for( var data of response.body.rows ){

                    let objs = [];
                    self.main.project.cleanCouchDBGroupIssue( objs , data.value );

                    // Add all objects to ref_block parent
                    for( let obj of objs ){
                        let tag = obj.format.substr(0, obj.format.indexOf('-'));

                        let blockObj = { value : obj , custom : { tag: tag, children: [], objects:[] } };

                        self.allObjects[obj.ref_block] = self.allObjects[obj.ref_block] || [];

                        self.allObjects[obj.ref_block].push( blockObj );
                    }

                    Object.keys(self.allObjects).forEach((refBlock) => {
                        let objs = self.allObjects[refBlock];
                        for( let obj of objs ){

                            let parent = null;

                            // for ui object, we need to find the parent following the children field into the parent's data (list of children's ids)
                            //if( obj.custom.tag == 'ui' ){
                            parent = self.getParentOfUIBlock( self.allObjects[refBlock], obj );
                            if( parent )
                                obj.custom.uiParent = parent.value._id;
                            //}

                            // otherwise, the parent is the "key" parent - i.e : the block linked with ref_block property
                            if( parent == null )
                                parent = self.main.block.getEntityByIDInProject( project, refBlock );

                            if( !parent.custom.objects ){
                                parent.custom.objects = [];
                            }

                            parent.custom.objects.push( obj );

                        }
                    });

				}

                resolve( project );

			}.bind(this), function (error) {
				//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
				reject( error );
			});

		}.bind(this));
    }

    /*----------------------------------------------------------------*/
    /*------------------------ FOR UI OBJECTS ------------------------*/
    /*----------------------------------------------------------------*/

    // function use to extracts id of children of the field['children'] of an ui object.
    // ui objects with multiple sub sections has a multi-array of ids instead of one dimension array.
    // -> this function fill ids array with all ids contains
    // the correct section parent is correctly setted in arborescence (NavigatorUIItem)
    extractChildrenIDs( ids, children ){
        if( Array.isArray(children) ){
            children.forEach( (child) => {
                this.extractChildrenIDs( ids, child );
            })
        }
        else{
            ids.push( children );
        }
    }

    // search in the allObjects list (list of ui-objects) which one has currentObj as child
    // it search into the children field if the id of the currentObj exists to get the parent
    getParentOfUIBlock( allObjects, currentObj ){

        let parent = null;
        allObjects.forEach( (obj) => {

            let children = this.main.block.getField( obj, 'children' );

            if( children && children.value ){

                let childrenIds = [];
                this.extractChildrenIDs( childrenIds, children.value );

                childrenIds.forEach( (child) => {
                    if( child == currentObj.value._id )
                        parent = obj;
                });
            }
        });

        return parent;
    }


    // generate an UI Block doc as expecteed in couchdb
    generateObjectBlockDoc( UIobjectInspector, parent ){

        var UIobjectBlock = this.main.block.generateNewBlockDoc( UIobjectInspector, parent );

        UIobjectBlock.name = UIobjectInspector.value.name;
        UIobjectBlock.format = UIobjectInspector.value.format;

        // when this block can contains multiple section, create as much as children values as nbSection number
        let values = [];
        if( UIobjectInspector.value.nbSection > 1 ){
            for( let i = 0 ; i < UIobjectInspector.value.nbSection ; i++ ){
                values.push( [] );
            }
        }
        UIobjectBlock.fields.push( { name:'children' , value: values } );

        if( parent.value.ref_block ){
            // ref_block is define in parent ui block, we take the same
            UIobjectBlock["ref_block"] = parent.value.ref_block;
        }
        else{
            // else, we take the ref of the parent
            UIobjectBlock["ref_block"] = UIobjectBlock[ "ref_"+parent.value.type ];
        }
        delete UIobjectBlock[ "ref_"+parent.value.type ]; // don't need ref_screen/ref_process/...

        UIobjectBlock.level = parent.value.type; // ex : ui-screen has level screen

        return UIobjectBlock;
    }

    /*
     * check inside blockData (info of the current block), is any UIObject has to be associated to this block
     * @blockData : format of the block
     * @block : block recently added to the project
     */
    createObjectAssociated( blockData, block ){
		return new Promise( function(resolve, reject){

            if( blockData.value.objectAssociated ){
                // UIobject is associated -> create it and add it to block

                let objectInspector = this.main.authoring.findSettingByType( 'object' , blockData.value.objectAssociated );

                var objectBlock = this.generateObjectBlockDoc( objectInspector, block );

                this.main.block.createNewDoc( objectBlock ).then( function( object ){

                    // add objects list to parent
                    if( !block.custom.objects )
                        block.custom.objects = [];

                    // add objects list to ui child create
                    if( !object.custom.objects )
                        object.custom.objects = [];

                    // add ui tag to ui child create
                    object.custom.tag = object.value.format.substr(0, object.value.format.indexOf('-'));

                    block.custom.objects.push( object );

                    if( object.custom.tag == "ui" )
                        this.main.ui.createUIObject( Object.assign( {} , object ));

                    resolve( block );
                }.bind(this));

            }
            else{
                // no UIobject to create, just return block with no changes
                resolve( null );
            }

		}.bind(this));
    }


    // @children : list (1 or 2 dimension) of id's children
    // @id : id to remove from the children array
    // -> return : children list updated
    deleteIDFromChildrenField( children , id ){

        let newChildren = [];
        let i = 0;

        children.forEach( (child) => {

            if( Array.isArray(child) ){
                newChildren[i] = [];
                child.forEach( (sectionChild) => {
                    if( sectionChild != id )
                        newChildren[i].push( sectionChild );
                })
            }
            else{
                if( child != id )
                    newChildren.push( child );
            }

            i++;
        });


        this.main.ui.deleteUIObject( id );

        return newChildren;
    }


    addObjectToParent( blockData , parent, section ){
		return new Promise( function(resolve, reject){

            let blockDoc = this.generateObjectBlockDoc( blockData , parent );

            this.main.project.dispatchWaitInfo( true );
            let self = this;


            this.main.block.createNewDoc( blockDoc )
                .then( function( newBlock ){
                        return self.newblockAdded( parent, section, newBlock );
                    })
                .then( function( newBlock ){

                        self.main.project.saveProject( self.main.config.openProject );

                        resolve( newBlock );

                    });


		}.bind(this));
    }

    newblockAdded( parent, section, newBlock ){
		return new Promise( function(resolve, reject){

            // add a reference to the parent id in the child
            let tag = newBlock.value.format.substr(0, newBlock.value.format.indexOf('-'));
            newBlock.custom.tag = 'ui';

            if( tag == 'ui' ){
                // add helper to get easilly to ui parent
                newBlock.custom.uiParent = parent.value._id;

                // for ui object, need to add id bloc into parent's 'children' field
                this.currentInspector = this.main.authoring.findSettingByType( 'object' , parent.value.format );

                let targetChildrenList = this.main.block.getField( parent, 'children' );
                let parentNbSection = this.currentInspector.value.nbSection;


                // For ui editor, create ui Object & add it to it's ui parent
                let uiobj = this.main.ui.createUIObject( Object.assign( {} , newBlock.value ));
                let parentUI = this.main.ui.get( parent.value._id );

                // for multiple section parent, need to add this id into the correct sub array of children list
                if( parentNbSection > 1 ){
                    let randSection = Math.floor( Math.random()*parentNbSection );

                    section = ( section !== null ) ? section : randSection;

                    if( !targetChildrenList.value[section] )
                        targetChildrenList.value[section] = [];

                    targetChildrenList.value[section].push( newBlock.value._id );
                    parentUI.addChild( uiobj , section );

                }
                else{
                    // add id of the new ui block in the parent
                    targetChildrenList.value.push( newBlock.value._id );

                    parentUI.addChild( uiobj );
                }


            }

            // add full child object to custom.objects
            if( !parent.custom.objects )
                parent.custom.objects = [];

            parent.custom.objects.push( newBlock );


            resolve( newBlock );
		}.bind(this));
    }



}

export let objects = new ObjectsStore();
