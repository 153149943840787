/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import CommonText from '../CommonText';


/**
 * Simple input text based on HTML input type.
 * Available types:
 *   - Text
 *   - Number
 *   - Password
 *   - Email
 *   - URL
 *   - Long Text (i.e textArea)
 */
export default class InputText extends CommonText {
    constructor() {
        super();

        this.state = { value: '' };

        this.onInvalid = this.onInvalid.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    /**
     * Invoke just before rendering when new props or state are being received.
     *
     * @param {Object} props - The new component props.
     * @param {Object} state - The new component state.
     */
    componentWillUpdate(props, state) {
        super.componentWillUpdate(props, state);
    }

    /**
     * Fires the moment when the value of the element is changed.
     * @param {Object} event - The InputEvent event or null.
     */
    onChange(event) {
        this.setState({ value: event.target.value });
        this.eventHandler('value', event.target.value);
        this.eventHandler('onChange', true);
    }

    /**
     * Fires the moment that the element loses focus.
     * @param {Object} event - The FocusEvent event.
     */
    onBlur(event) {
        event.target.checkValidity();
        this.eventHandler('onBlur', true);
    }

    /**
     *  Fires the moment when the value of the element is invalid.
     * @param {Object} event - The event.
     */
    onInvalid() {
        this.eventHandler('onInvalid', true);
    }

    /**
     * Render the HTML input type.
     */
    render() {
        const attrs = {
            ...this.props.dom,
            ...this.props.input,
            // !IMPORTANT: apply the common styles to the input tag, not the parent div
            style: this.mergedStyle,
        };

        attrs.style.WebkitUserSelect = 'initial';
        attrs.style.KhtmlUserSelect = 'initial';
        attrs.style.msUserSelect = 'initial';
        attrs.style.userSelect = 'initial';
        attrs.style.MozUserSelect = 'initial';

        // set the default or the new value (from module input)
        if ('value' in this.props.input) {
            this.state.value = this.props.input.value || '';
            delete this.props.input.value;
        }

        // apply the current component value
        attrs.value = this.state.value;

        // add events callback
        attrs.onInvalid = this.onInvalid;
        attrs.onChange = this.onChange;
        attrs.onBlur = this.onBlur;

        // set the right syntax for attributes or remove non used
        attrs.autoComplete = this.props.input.autoComplete ? 'on' : 'off';
        attrs.maxLength = attrs.maxLength === 0 ? null : attrs.maxLength;
        attrs.type = attrs.type === 'textarea' ? null : attrs.type;
        attrs.placeholder = this.props.input.placeholder || null;
        attrs.pattern = this.props.input.pattern || null;


        // use textarea
        // or by default the input tag

        // @todo: temporary hack (only for 'danse l'Europe' project)
        // Form should be included first an handle the submit event, see the ticket [NS-989]
        // --- previous code : to be restored ----------------------------------------------
        // return this.props.input.type === 'textarea'
        //     ? this.wrapHelper(<textarea {...attrs} {...this.otherEvents} />)
        //     : this.wrapHelper(<input {...attrs} {...this.otherEvents} />);
        // ---------------------------------------------------------------------------------
        const elem = this.props.input.type === 'textarea'
            // eslint-disable-next-line react/jsx-filename-extension
            ? this.wrapHelper(<textarea {...attrs} {...this.otherEvents} />)
            : this.wrapHelper(<input {...attrs} {...this.otherEvents} />);

        if (!this.props.renderer.auth && window.soundworksConfig.appName !== 'danse-leurope') return elem;

        const fattrs = {
            onSubmit: (event) => {
                document.getElementById(attrs.id).blur();
                event.preventDefault();
                return false;
            },
            style: {
                display: 'inline-block',
            },
        };
        return this.wrapHelper(<form {...fattrs}>{elem}</form>);
    }
}
