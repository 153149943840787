import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class UpdateLinkActivation extends ActionAbstract {

    /**
     * @param blocId : id of block
     * @param link : data of link (from & to)
     * @param value : value of activation (true or false)
    **/

    static get ID() {
        return 'actions:editor:schematic:updateLinkActivation';
    }

    run(state) {
        
        let path;
        let block = null;
        [block,path] = helper.block.getBlockById( state.project, this.getParam('blocId') , true );

        let link = this.getParam('link');
        let linkIndex = helper.links.getIndexOfLink( block, link );

        if( linkIndex != -1 ){

            let compositeState = { 
                project: {},
                'editor.linkmenu.link.activated' : this.getParam('value')
             };
             
            compositeState.project[path+'.value.links['+linkIndex+'].activated'] = this.getParam( 'value' );
           
            return this.composeState(state, compositeState );
        }

        return false;
    }


}

export default UpdateLinkActivation;