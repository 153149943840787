<!-- Field vue -->

<template lang="pug">

div.form-accordion.isAccordion.trigger-field

	div.title 
		span.text {{condition.title}} 
			span(v-if="inc")  {{"#"+inc}}
		div.action-bt.bt-trash(v-on:click.stop="deleteCondition")
		span.arrow(v-on:click.stop="accordion = !accordion ", v-bind:class="{'close':!accordion}")

	div.form-accordion-content(v-bind:class="{'open':accordion}")

		// span.textinput-ctn.trigger-select
		// 	span.select-arrow &gt;
		// 	select(v-model="localCond.status", v-on:change="changeStatus()")
		// 		option(value="local") Local
		// 		option(value="shared") Shared

		span.textinput-ctn.trigger-select
			span.select-arrow &gt;
			select(v-model="localCond.type", v-on:change="changeType(true)")
				option(v-for="conditionType of settings", v-bind:value="conditionType.value.type") {{conditionType.value.name}}

		div(v-show="!displayMemoryPicker")
			span.textinput-ctn.trigger-select
				span.select-arrow &gt;
				select(v-model="localCond.target", v-on:change="changeTarget()")
					option(v-for="target of targets", v-bind:value="target.value" ) {{target.name}}

		div(v-show="displayMemoryPicker")
			div.memory-fields-ctn
				div.field-ctn
					span.text memory owner
					div.input-fields.groupfield
						span.memory.textinput-ctn
							span.select-arrow &gt;
							select(v-on:change="getMemoryOfSelectedParent()", v-model="currentParent")
								option(v-for="(option,i) of parentList", v-bind:value="option.value._id") {{option.value.name}}
					div.clear
				div.clear


				div.field-ctn
					span.text memory
					div.input-fields.groupfield(v-if="memoryList.length > 0")
						span.memory.textinput-ctn
							span.select-arrow &gt;
							select(v-on:change="changeTarget()", v-model="localCond.target")
								option(v-for="(option,i) of memoryList", v-bind:value="option.value") {{option.title}}
					div.input-fields.groupfield(v-else)
						span.noresult No memory
					div.clear
				div.clear

		div(v-show="displayMemoryPicker && condition.type === 'state'")
			div.memory-fields-ctn(v-show="memoryType !== 'boolean'")
				span.textinput-ctn.trigger-select
					span.select-arrow &gt;
					select(v-model="localCond.operator", v-on:change="changeChoice()")
						option(v-for="op of ['==', '!=', '>', '>=', '<', '<=']", v-bind:value="op" ) {{ op }}

				span.textinput-ctn.trigger-select
					input.value(v-model="localCond.choice", v-on:change="changeChoice()")

			div.memory-fields-ctn(v-show="memoryType === 'boolean'")
				span.text Condition is true when memory is
				span.checkbox(v-bind:class="{'selected':localCond.choice }", v-on:click.stop="[toggleValue(), changeChoice() ]")
					input(type="checkbox", v-model="localCond.choice")

		div(v-show="choices && choices.length>0")
			span.textinput-ctn.trigger-select
				span.select-arrow &gt;
				select(v-model="localCond.choice", v-on:change="changeChoice()")
					option(v-for="choice of choices", v-bind:value="choice.type" ) {{choice.value}} {{choice.name}}


		span.textinput-ctn.trigger-select(v-for="subfield of subfields")
			field(:field="subfield", :values="subfield.values",:groupfield="true")

	
	div.clear
</template>

<script>

import { stores } from '../../../stores/MainStore.js';
import actionManager from '../../../actions';
import * as helper from '../../../helper';

import FieldManager from  '../fields/FieldManager.vue';

export default {

	components: { field: FieldManager },

	props:[ 'index' , 'condition' , 'target', 'project'],

	data() {
		return {
			accordion: true,
			// pickerOpen: false,	
			// colorLinked: false,
			// colorDecomposed: null,
			
			inc : null,
			subfields: [],
			targets: [],
			choices: [],
			memoryList: [],
			parentList: [],			

			displayMemoryPicker: false,

			settings: [],
			localCond : {
				status: null,
				type: null,
				target: null,
				choice: null,
				fields: [],
				operator: null,
			},

			currentParent: null,

			// TODO: prefers pass app.state to childrens, $root or $children references could change
			// (migration from v1: this.$root)
			app: this.$root.$children[0]
		}
	},

	computed: {
		memoryType: function() {
			let memory = helper.block.getMemoryById( this.app.state.project, this.localCond.target );
			return memory !== undefined ? memory.value.format : undefined;
		}
	},

	created: function(){

		for( let config of this.$config.authoring ){
			if( config.value.level == "trigger" ){
				this.settings.push(config);
			}
		}

		this.localCond = Object.assign({}, {
			status: null,
			type: null,
			target: null,
			choice: null,
			dataType: null,
			fields: [],
			operator: null
		}, this.condition.params);

		if( this.condition.type == "state" )
			this.inc = (this.index + 1);

		if(!this.localCond.status){
			this.localCond.status = "local";// Premiere valeur de la liste par défaut
		}

		if(!this.localCond.type){
			this.localCond.type = this.settings[0].value.type; // Premiere valeur de la liste par défaut
		}
		this.changeType(false);

		// this.targets = this.getAvailableTargets();
		// this.choices = this.getAvailableChoices();

		this.localCond.target = this.condition.params.target;

		this.getParents( this.target );
		if (this.localCond.type === "memory" && this.localCond.target) {
			this.findCurrentParent();
		}
		this.getMemoryOfSelectedParent();

	},

	watch:{
		'index': function(val, oldVal){
			if( this.condition.type == "state" )
				this.inc = (this.index + 1);
		}
	},

	methods:{

		deleteCondition: function(){
			actionManager.trigger('inspector:trigger:removeCondition', { subaction: this.condition.type, targetId: this.target.value._id, index: this.index })
		},

		changeStatus: function () {
			actionManager.trigger('inspector:trigger:updateCondition', { subaction: this.condition.type, params: this.localCond, targetId: this.target.value._id, index: this.index })
		},

		changeType: function( triggerAction ){
			if (this.localCond.type !== "memory") {
				this.targets = this.getAvailableTargets();
				this.choices = this.getAvailableChoices();

				this.displayMemoryPicker = false;
			}
			else {
				this.displayMemoryPicker = true;
				if (this.condition.type === "event") {
					this.choices = this.getAvailableChoices();
				}
			}

			if (triggerAction)
				actionManager.trigger('inspector:trigger:updateCondition', { subaction: this.condition.type, params: this.localCond, targetId: this.target.value._id, index: this.index });			
		},

		changeTarget: function () {
			if (this.localCond.type !== "memory") {			
				this.choices = this.getAvailableChoices();
			}

			this.changeChoice();
		},

		changeChoice: function( ){
			if (this.condition.type === 'state') {
				if (this.localCond.type === 'memory' && this.localCond.target) {
					let memory = helper.block.getMemoryById( this.app.state.project, this.localCond.target);
					if (memory && memory.value.format === "boolean") {
						this.localCond.operator = "==";
					}
					if (memory && ["int", "float"].indexOf(memory.value.format) !== -1) {
						let parseFn = memory.value.format === "int" ? (v) => parseFloat(parseInt(v)) : parseFloat;
						this.localCond.choice = parseFn(this.localCond.choice);
					}

					this.localCond.dataType = (memory && memory.value.format);
				}
			}

			actionManager.trigger('inspector:trigger:updateCondition', { subaction: this.condition.type, params: this.localCond, targetId: this.target.value._id, index: this.index })
		},

		toggleValue: function() {
			this.localCond.choice = !this.localCond.choice;
		},

		getAvailableTargets: function () {
			let settings = this.getSettingsByType( this.localCond.type );
			
			let targets = []; // empty targets

			if( settings.value.format == "memory" ){
				for(let mem of this.project.memory ){
					if( mem.value.format == settings.value.type ){
						targets.push( { value: mem.value._id, name: mem.value.name });
					}
				}
			}

			if( settings.value.format == "block" ){

				let blocks = [];
				this.findBlockInProject( blocks, this.project, settings.value.type );

				if( blocks && blocks.length > 0){
					for( let block of blocks ){
						targets.push( { value: block.value._id, name: block.value.name });
					}
				}
			}

			// et on reatribue la premier valeur des listes par defaut
			if( targets.length > 0 ) 		
				this.localCond.target = targets[0].value;

			return targets;			
		},

		getAvailableChoices: function () {

			let settings = this.getSettingsByType( this.localCond.type );

			let choices = [];
			
			if( this.condition.type == "event" && settings.value.events)
				choices = settings.value.events.slice(0);

			if( this.condition.type == "state" && settings.value.states)
				choices = settings.value.states.slice(0);

			if( !this.localCond.choice ) {
				if ( choices.length > 0 && this.localCond.type !== "memory" ) {
					this.localCond.choice = choices[0].type;
				}
			}

			return choices;
						
		},

		// getCurrentChoice: function( choices, currentType ){
		// 	if( choices.length > 0 ){
		// 		for(let choice of choices){
		// 			if( choice.type == currentType )
		// 				return choice;
		// 		}
		// 	}
		// },

		findBlockInProject: function( blocks, node, blockType ){

			/*if( node.value.type == blockType )
				blocks.push( node );*/

			let children = node.custom.children;

			if( children && children.length > 0 ){
				for( let subnode of children ){
					if( node.value._id === this.currentParent && subnode.value.type == blockType )
						blocks.push( subnode );
					else
						this.findBlockInProject( blocks, subnode, blockType );
				}
			}
		},

		getSettingsByType: function(type){
			for( let config of this.settings ){
				if( config.value.type == type )
					return config;
			}
			return null;
		},
		
		getParents: function ( node ){

			let self = this;

			let isMemoryOwner = (node) => {
				return node.value.memory.findIndex( (m) => {
					return m.id === self.value
				}) !== -1 ? true : false
			};
			// if( this.value.memoryParent )
			// 	this.currentParent = this.value.memoryParent;
			
			// On parcours récursivement les parents du module à la recherche des memory auquel on a acces
			let parentID = node.value['ref_' + node.value.level ]
			if( parentID ){
				// check if there is another way to access global state (via props ?)
				let parent = helper.block.getBlockById( this.app.state.project, parentID );
				if (isMemoryOwner(parent)) {
					this.currentParent = parent.value._id
				} else {
					if (this.currentParent === null) {
						this.currentParent = parentID;
					} 
				}
				this.parentList.push(parent);
				this.getParents( parent );
			}
		},

		findCurrentParent: function () {
			let notFound = true, i = 0;
			while (notFound && i < this.parentList.length) {
				if (this.parentList[i].value.memory.findIndex(el => el.id === this.localCond.target) != -1)
					notFound = false;
				else
					i++;
			}

			if (!notFound) {
				this.currentParent = this.parentList[i].value._id;
			}
		},

		getMemoryOfSelectedParent: function (){

			this.memoryList = [];

			let noParent = this.currentParent ? false : true;
			let noValue = this.localCond.target ? false : true;

			let parent = helper.block.getBlockById(this.app.state.project, this.currentParent);

			let authorizedType = ['string', 'int', 'float', 'boolean'];

			if( parent && parent.value.memory && parent.value.memory.length > 0 ){
				parent.value.memory.forEach( (mem) => {
					let memory = helper.block.getMemoryById( this.app.state.project, mem.id );
					if( memory ){
						if( authorizedType.indexOf(memory.value.format) !== -1 )
							this.memoryList.push( { title: memory.value.name, value: memory.value._id } );
					}
				});
			}

			// if( noValue && this.memoryList.length > 0 )
			// 	this.value.value = this.memoryList[0].value;
			// if( this.memoryList.length == 0 )
			// 	this.value.value = null;

		}

	}

}

</script>

<style lang="stylus">




.trigger-field

	.memory-fields-ctn
		padding-top: 10px

		.field-ctn
			margin-left: 0px
			margin-top: 0px
			margin-right: 10px
			margin-bottom: 10px
			border-radius: 4px
			background: var(--inputValue)
			.text
				padding: 0 10px
				width: 110px
				text-transform: capitalize

		.checkbox
			float: right;

	div.title
		.bt-trash
			right: 19px
			height: 90%
			opacity: 0
			transition: opacity 0.2s ease-in-out
	
		&:hover
			.bt-trash
				opacity: 0.7

				&:hover
					opacity: 1

.trigger-select
	
	display: block
	margin-bottom: 3px
	width: 100%
	height: 24px

	.select-arrow
		display: inline-block
		position: absolute
		top: 6px
		right: 7px
		font-family: 'DinRegular', 'ComfortaaLight'
		font-size: 12px
		transform: rotate(90deg)

	select
		background-color: transparent
		width: 100%
		color: #58585B !important
		font-family: 'DinRegular', 'ComfortaaLight'
		font-size: 12px
		border: 1px solid white
		border-radius: 2px
		text-align: right
		display: inline-block
		height: 24px
		vertical-align: top
		-webkit-appearance: none
		-moz-appearance: none
		appearance: none
		text-align: right		
		padding-left: 4px
		padding-right: 20px
		position: relative

	.textinput-ctn
		width: 100%

	input.value
		background-color: transparent
		width: 95%
		color: #58585B !important
		font-family: 'DinRegular', 'ComfortaaLight'
		font-size: 12px
		border: 1px solid white
		border-radius: 2px
		text-align: right
		display: block
		height: 20px
		vertical-align: top
		padding-right: 3%

	.field-ctn span.text
		height: 23px
		line-height: 23px


.form-accordion.isAccordion.trigger-field
	.trigger-select
		background: transparent!important

	.trigger-select
		.select-arrow
			margin-top: -1px

	.memory-fields-ctn
		padding-top: 0px!important
		padding-left: 0px
		width: calc(100% - 0px)
		margin-bottom: -5px!important

	.memory-fields-ctn .field-ctn
		margin-left: 5px
		margin-top: 0px
		margin-right: 5px
		margin-bottom: 5px
		border-radius: 4px
		.text
			padding: 0px 0px
			font-size: 14px

	.memory-fields-ctn .field-ctn .input-fields.groupfield .memory.textinput-ctn select
		background: var(--inspectorLevel1)
		
	.trigger-select input.value
		border: none
		width: calc(100% - 27px)!important
		border-radius: 4px!important
		color: var(--inspectorText)!important
		background-color: var( --inputValue)
		margin-top: 0px!important
		margin-left: 0px!important
		height: 38px!important
		margin-bottom: 10px

</style>