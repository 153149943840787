import ServiceAbstract from './ServiceAbstract';

var _disableConsole = true;
var consoleOriginalReference = window.console;

class DebugOutput extends ServiceAbstract {

    beforeCreate() {
        super.beforeCreate();
        /*
        if (window.console && window.console.log) {
            window.console = Object.assign({}, window.console);
            window.console.log = this.print.bind(this);
        }        
        */
    }

    static get ID() {
        return 'services:DebugOutput';
    }

    static set disableConsole(disable = true) {
        _disableConsole = disable;
    }

    static get consoleDisabled() {
        return _disableConsole;
    }

    print (message) {
        if (!_disableConsole)
            consoleOriginalReference.log(message);
    }

    printJSON(json) {
        if (!_disableConsole && json )
            this.print(JSON.parse(json));
    }

    printAction(...args) {
        if (window.logActionsStack) consoleOriginalReference.log(...args);
    }

}


export default DebugOutput;