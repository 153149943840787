<!-- Home vue -->

<template lang="pug">
div
	div.prehome
		a#top
		global-links(:user="(appState && appState.user)")

		div.content
			div.content-vcentered
				div(v-if="tab=='accueil'")
					div.logo
					h1 The web platform for digital creatives
					div.baseline
						p Explore the relationship between bodies, media and spaces through new interfaces and collaborative tools for creativity
					a.big-button(v-on:click="connexion")
						span Start

				div(v-if="tab=='form'")
					div.logo
					h1 Identification
					input(class="home", type='text', name="email", v-model="email", placeholder="E-mail")
					input(class="home", type='password', name="password", v-model="password", placeholder="Password", v-on:keyup.enter="login")
					span.error {{ appState.login ? appState.login.errorMessage : '' }}
					br(v-if="appState.login && appState.login.errorMessage != ''")
					a.big-button(v-on:click="login")
						span Log In
					br

				div(v-if="tab=='subscribe'")
					div.logo
					h1 Create an account
					br
					input(class="home", type='text', name="email", v-model="email", placeholder="E-mail")
					input(class="home", type='password', name="password", v-model="password", placeholder="Password", v-on:keyup.enter="subscribe")
					input(class="home", type='password', name="cpassword", v-model="cpassword", placeholder="Password confirmation", v-on:keyup.enter="subscribe")
					br 
					input(class="home", type='password', name="apassword", v-model="apassword", placeholder="Admin password", v-on:keyup.enter="subscribe")
					br 
					span.error(v-if="appState.signup") {{ appState.signup.errorMessage }}
						br
					a.big-button(v-on:click="subscribe")
						span Subscribe

			div.scroll
				p Scroll down to learn more

		a#tour

		div.tour
			div.title
				h1 Tour
			div.content
				h1 What is Nodal.Studio ?
				br
				div.part-one
					p Nodal.Studio offers an accessible tool to create a new generation of mobile apps : applications located in a place and a moment and adapted to the user. This relevance is achieved by linking multiple web components: sensors, machine learning, IOT, Open AI, Open Data, cloud, BLE beacons, social networks...
					p Nodal.Studio addresses a wide variety of mobile experiences : soundwalks, soundscapes, collective audiovisual performances, collaborative games, augmented reality, alternate reality games... Nodal.studio turns the smartphone in everybody’s pocket into a mean of intense immersion, collaborative production or collective expression.
				div.part-two
					.video 
						iframe(src="https://player.vimeo.com/video/298171079?color=f8a176" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen)
				div.clear

		div.how
			div.content
				h1 How does it work ?
				br
				div.steps
					div.step-one
						div.bg
						h2 Step 1
						p Create your project and your «&nbsp;targets&nbsp;».
					div.step-two
						div.bg
						h2 Step 2
						p Create and edit screens, with a WYSIWYG screen editor.
					div.step-three
						div.bg
						h2 Step 3
						p Compose your map with beacons and an POIs.
					div.step-four
						div.bg
						h2 Step 4
						p Create your interactions with a wide range of features. Link modules and test it on the fly.
					div.step-five
						div.bg
						h2 Step 5
						p Publish your experience for the web.
				div.clear

		a#showcase

		div.showcase
			div.title
				h1 Showcase
				div.content
					h1 They build their ideas with Nodal.Studio
					br
					a(v-on:click="openPopin('vibes')")
						div.showcaseImg.vibes
							div.colorImg
								div.textImage
									div.legend
										h4 Vibes
					a(v-on:click="openPopin('dmacdlo')")
						div.showcaseImg.dmacdlo
							div.colorImg
								div.textImage
									div.legend
										h4 Des mondes au creux de l'oreille
					a(v-on:click="openPopin('cityforu')")
						div.showcaseImg.cityforu
							div.colorImg
								div.textImage
									div.legend
										h4 City4u
					a(v-on:click="openPopin('neighbors')")
						div.showcaseImg.neighbors
							div.colorImg
								div.textImage
									div.legend
										h4 Neighbors
					a(v-on:click="openPopin('walks')")
						div.showcaseImg.walks
							div.colorImg
								div.textImage
									div.legend
										h4 Audio Walks
					a(v-on:click="openPopin('code')")
						div.showcaseImg.code
							div.colorImg
								div.textImage
									div.legend
										h4 Capture
					a(v-on:click="openPopin('postpiper')")
						div.showcaseImg.postpiper
							div.colorImg
								div.textImage
									div.legend
										h4 Post-Piper
					a(v-on:click="openPopin('playground')")
						div.showcaseImg.playground
							div.colorImg
								div.textImage
									div.legend
										h4 Playground
					a(v-on:click="openPopin('saleve')")
						div.showcaseImg.saleve
							div.colorImg
								div.textImage
									div.legend
										h4 Paysage sonore
					a(v-on:click="openPopin('drouot')")
						div.showcaseImg.drouot
							div.colorImg
								div.textImage
									div.legend
										h4 Drouotnautes
					a(v-on:click="openPopin('mucem')")
						div.showcaseImg.mucem
							div.colorImg
								div.textImage
									div.legend
										h4 Parcours Enfants du Mucem
					a(v-on:click="openPopin('mars')")
						div.showcaseImg.mars
							div.colorImg
								div.textImage
									div.legend
										h4 Escape to Mars
					a(v-on:click="openPopin('danser')")
						div.showcaseImg.danser
							div.colorImg
								div.textImage
									div.legend
										h4 Application à danser
					a(v-on:click="openPopin('fabrique')")
						div.showcaseImg.fabrique
							div.colorImg
								div.textImage
									div.legend
										h4 Les contraintes de l'endroit
				div.clear

		a#contact

		div.footer
			div.logo
			br
			a(href='mailto:contact@orbe.mobi')
				h1.mail Contact us
			div.content
				div.col
					h1 Nodal.Studio
					ul
						li
							a(href='#tour')
								p Tour
						li
							a(href='#showcase')
								p Showcase
						li
							a(href='#contact')
								p Participate
				//div.col
					h1 INFORMATION
					ul
						li
							a(href='#' target='_blank')
								p F.A.Q.
						li
							a(href='#' target='_blank')
								p Legal terms
				div.col
					h1 Partners
					ul
						li
							a(href='https://www.orbe.mobi' target='_blank')
								p Orbe
						li
							a(href='https://www.ircam.fr/' target='_blank')
								p Ircam
						li
							a(href='https://cosima.ircam.fr/' target='_blank')
								p CoSiMa
						li
							a(href='https://rapidmix.goldsmithsdigital.com/' target='_blank')
								p Rapid Mix
						li
							a(href='https://www.agence-nationale-recherche.fr/' target='_blank')
								p ANR
				div.col
					h1 Community
					ul
						li
							a(href='https://www.facebook.com/orbemobi' target='_blank')
								p FaceBook
						li
							a(href='https://twitter.com/OrbeMobi' target='_blank')
								p Twitter
						li
							a(href='https://www.linkedin.com/company-beta/460814/' target='_blank')
								p LinkedIn
		div.back-to-top
			a(href='#top')
				div.top-icon
				p Back To Top

	<transition name="popin">
	div.popin(v-if="popinWindow == 'postpiper'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.postpiper
				div.popinContent
					h1 Post-Piper
					ul
						li Conception and general curating : 
							a(href='https://atelier-artel.fr' target='_blank') Artel
						li Developement : 
							a(href='https://www.orbe.mobi' target='_blank') Collectif Orbe
						li Realization and technical support : 
							a(href='https://sylvainderozevire.com' target='_blank') Studio design Sylvain de Rozevire
						li Venues : 
							a(href='https://www.ecolecamondo.fr' target='_blank') École Camondo&nbsp;·&nbsp;
							a(href='https://www.lesartsdecoratifs.fr' target='_blank') Musée des Arts Décoratifs
						li Partners : 
							a(href='https://www.ecolecamondo.fr' target='_blank') École Camondo&nbsp;·&nbsp;
							a(href='https://www.timmpi.com' target='_blank') Timmpi&nbsp;·&nbsp;
							a(href='https://www.movinphone.com' target='_blank') Movin’Phone
						li More information : 
							a(href='https://postpiper.fr' target='_blank') Official website
					p Discotheque, rave party, club, festival and any other form of organization of festive events have participated – through their atmospheres – to the creation of collective imaginary, between entertainment and political demands. The history of these environments, especially since the World War II, reveals the major place that the ambience take in the processes of psychic and collective individualizations. For better and for worse.
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'playground'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.playground
				div.popinContent
					h1 Playground
					ul
						li Sponsor : 
							a(href='https://www.maifsocialclub.fr' target='_blank') MAIF Social Club
						li Conception and developement : 
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li Partners : 
							a(href='https://www.crossedlab.org' target='_blank') CrossedLab&nbsp;·&nbsp;
							a(href='https://www.agence-nationale-recherche.fr' target='_blank') ANR
						li More information : 
							a(href='https://vimeo.com/216900137' target='_blank') Video&nbsp;·&nbsp;
							a(href='https://lieu.maifsocialclub.fr/programmation/sportmania/playground' target='_blank') Event
					p Playground turns a neighborhood into a playground for a session of 40'. About twenty participants are involved in the playground, while the public can follow the game on a real-time 3D map of the neighborhood. The game brings the participants to move constantly, statistics of distance traveled and expenditure of calories are collected in real time and restored on the big screen as well as on mobiles..
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'saleve'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.saleve
				div.popinContent
					h1 Paysage Sonore
					ul
						li Sponsor : 
							a(href='https://www.maisondusaleve.com' target='_blank') Maison du Salève
						li Scenography : 
							a(href='https://atelier-artel.fr' target='_blank') Artel
						li Interation design ad mobile app : 
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li Partner : 
							a(href='https://cosima.ircam.fr/' target='_blank') ANR-CoSiMa
					p The temporary exhibition on the theme of the Paysage Sonore is presented at Maison du Salève for two years. The exhibition shows four biotopes in which the visitor can incarnate different actors of the landscape to better understand the impact of human activities on these ecosystems..
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'drouot'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.drouot
				div.popinContent
					h1 Drouotnautes
					ul
						li Sponsor : 
							a(href='https://www.mulhouse.fr' target='_blank') Ville de Mulhouse
						li Curation : 
							a(href='https://www.facebook.com/Le-BENTO-105743163241185/' target='_blank') Le Bento
						li Artistic Direction : 
							a(href='https://www.orbe.mobi' target='_blank') Collective Orbe
						li Workshop : 
							a(href='https://www.orbe.mobi' target='_blank') Collective Orbe
						li Partner : 
							a(href='https://cosima.ircam.fr/' target='_blank') ANR-CoSiMa
					p During 4 weeks, the team of Orbe has implemented a sound-walk on the theme of xenobiology with schoolchildren and high school students from the Drouot district in Mulhouse. In this walk, the visitor explores ecosystems that are deployed on imaginary planets.
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'mucem'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.mucem
				div.popinContent
					h1 Parcours Enfants du Mucem
					ul
						li Sponsor : 
							a(href='https://www.mucem.org' target='_blank') Mucem
						li Scenography : 
							a(href='https://www.matalicrasset.com' target='_blank') Matali Crasset
						li Tangible Design : 
							a(href='https://studioravages.com' target='_blank') Studio Ravages
						li Scenario : 
							a(href='https://urbanexpe.com' target='_blank') Urban Expé
						li Interactive design : 
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li Partner : 
							a(href='https://cosima.ircam.fr/' target='_blank') ANR-Cosima
					p Orbe is working again with the Mucem teams to design and implement the child tour route. The route takes place at the times of Soliman the Magnificent and Philip II of Spain. Young visitors explore the Mediterranean from port to port, collecting its wealth.
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'mars'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.mars
				div.popinContent
					h1 Escape to Mars
					ul
						li Sponsor : 
							a(href='https://www.keepcool.fr' target='_blank') Keep Cool
						li Scenario : 
							a(href='https://urbanexpe.com' target='_blank') Urban Expé
						li Interactive and Graphic Design : 
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li Technical solution : 
							a(href='https://www.orbe.mobi' target='_blank') Orbe
					p This Escape Game takes place in a Fitness Room. Participants are located in a ship in flight towards the red planet. Many difficulties animate their journey. They must overcome each of them by practicing the different activities proposed by the room. 
					p Photo by Urban Expé
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'danser'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.danser
				div.popinContent
					h1 Application à danser
					iframe(src="https://player.vimeo.com/video/294744935" width="100%" height="460" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen)
					ul
						li Sponsor : 
							a(href='https://www.gymnase-cdc.com' target='_blank') Le Gymnase (Roubaix)
						li Partners : 
							a(href='https://a-cdc.fr/FR' target='_blank') The 12 Choreographic Development Centers&nbsp;·&nbsp;
							a(href='https://www.ircam.fr' target='_blank') IRCAM&nbsp;·&nbsp;
							a(href='https://www.contour-progressif.net' target='_blank') Compagnie Contour Progressif – Mylène Benoit&nbsp;·&nbsp;
							a(href='https://cosima.ircam.fr/' target='_blank') ANR-Cosima&nbsp;·&nbsp;
							a(href='https://rapidmix.goldsmithsdigital.com' target='_blank') RapidMix
						li Technical solution, interactive and graphic design : 
							a(href='https://www.orbe.mobi' target='_blank') Orbe
					p An app to build his dance from everyday gestures, in workshops organized by the CDCNs. The app allows to develop a personal choreographic repertoire, in relation to space, sound, others.
			div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'fabrique'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.fabrique
				div.popinContent
					h1 Les contraintes de l'endroit
					ul
						li Sponsor : 
							a(href='https://www.univ-paris8.fr' target='_blank') Université Paris 8
						li Partners : 
							a(href='https://www.labex-arts-h2h.fr' target='_blank') Labex Arts H2H&nbsp;·&nbsp;
							a(href='https://idefi-creatic.net/fr/' target='_blank') Idefi-CréaTIC&nbsp;·&nbsp;
							a(href='https://www.ircam.fr' target='_blank') IRCAM&nbsp;·&nbsp;
							a(href='https://cosima.ircam.fr/' target='_blank') ANR-Cosima &nbsp;·&nbsp;
							a(href='https://rapidmix.goldsmithsdigital.com' target='_blank') RapidMix
						li Interactive and Graphic Design : 
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li More information : 
							a(href='https://www.labex-arts-h2h.fr/les-contraintes-de-l-endroit.html' target='_blank') Official page
					p The sound arts represent a field of activities with the distinction of being both old and new. Ancient, because its history goes back to at least the beginning of the twentieth century and the plural of its denomination inscribes it in fact in a complex genealogy. Recent, through the specific studies devoted to it. The sonic arts factory intends to analyze the historical and cultural construction of these practices, their conditions of possibility and their historiography, as well as the social and aesthetic stakes at work behind the affects they mobilize or produce.
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'code'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.code
				div.popinContent
					iframe(src="https://player.vimeo.com/video/279939004" width="100%" height="460" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen)
					h1 Capture
					ul
						li Partners : 
							a(href='https://www.facebook.com/codeformusic/' target='_blank') Code&nbsp;·&nbsp;
							a(href='https://www.orbe.mobi' target='_blank') Orbe&nbsp;·&nbsp;
							a(href='https://paulemilieu.com' target='_blank') Paul Emilieu
					p Capture is a project using the Post-Piper <em>dispositif</em> in order to create a collective audiovisual experience. The Code Ensemble improvises on their intruments to activate the audience smartphones displayed on the Post-Piper structure. As a result, the musicians and participants are imerged into a collective muscial and light installation creating a visual score as the instruments play.  
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'walks'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.walks
				div.popinContent
					h1 Audio Walks
					ul
						li Sponsor : 
							a(href='https://www.univ-paris8.fr' target='_blank') ENSCi – Les Ateliers
						li Partner : 
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li More information : 
							a(href='https://audiowalks.orbe.mobi' target='_blank') Projects page
					p A set of sound creations on the move: fictions, life-size games, urban visits... The students of ENSCi's Sound Creation Studio team up with Orbe to create a dozen of experiences. The applications are directly visible in the browser of any smartphone, in order to compose scenarios, play sounds and geolocate multiple interaction modalities. This experimental project helps the platform to extend its use to wider audiences. The walks are available wherever and whenever wanted, as long as the participant explores to the scene with a mobile web browser.
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'vibes'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.vibes
				div.popinContent
					h1 Vibes
					ul
						li This project is co-funded by the Creative Europe Programme of the European Union
						li Partners : 
							a(href='http://www.comune.bassano.vi.it/' target='_blank') Comune di Bassano del Grappa&nbsp;·&nbsp;
							a(href='https://www.coventry.ac.uk/' target='_blank') Coventry University&nbsp;·&nbsp;
							a(href='https://www.holonic.systems/' target='_blank') Holonic Systems&nbsp;·&nbsp;
							a(href='https://www.ickamsterdam.com/en/' target='_blank') ICK Amsterdam&nbsp;·&nbsp;
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li Website : 
							a(href='https://vibes.nodal.mobi' target='_blank') Vibes
					p Vibes is a dance meeting app. After  creating a profile, the user sends an invitation to share an dance moment at certain time and date. The appointment ends as a performance in different places at the same time, where people can create movement according to the sound and share a dance moment audioguided by a choreographer.
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'dmacdlo'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.dmacdlo
				div.popinContent
					h1 Des mondes au creux de l'oreille
					ul
						li Partners : 
							a(href='https://www.osupytheas.fr/' target='_blank') OSU Institut Pytheas&nbsp;·&nbsp;
							a(href='https://www.prism.cnrs.fr/' target='_blank') Prism/CNRS&nbsp;·&nbsp;
							a(href='https://ecole-art-aix.fr/' target='_blank') École supérieure d'art d'Aix-en-Provence&nbsp;·&nbsp;
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li Website : 
							a(href='http://www.desmondesaucreuxdeloreille.net/' target='_blank') Des mondes au creux de l'oreille
					p Des mondes au creux de l'oreille is a soundwalk through the old port of Marseille that connects you, as you walk, to natural sound environments captured live around the world. Explore the old port with your mobile phone and experience these soundscapes, far or near, over time and space.
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'neighbors'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.neighbors
				div.popinContent
					h1 Neighbors
					ul
						li Partners : 
							a(href='https://yannickribeaut.com/wp/' target='_blank') Yannick Ribeaut&nbsp;·&nbsp;
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li Websites : 
							a(href='https://dev2.nodal.mobi/orbe/neighbors/app' target='_blank') Participate to the project&nbsp;·&nbsp;
							a(href='https://dev2.nodal.mobi/orbe/neighbors/site' target='_blank') View the project
					p Neighbors a collective photographic project from your home, through the prism of your windows, on the model of Yannick Ribeaut's “Neighbors Project” and developed by Orbe. This series is our reflection on interdependence, interconnection, otherness.
				div.close
	</transition>
	<transition name="popin">
	div.popin(v-if="popinWindow == 'cityforu'")
		div.popinClose(v-on:click="closePopin")
			div.popinProject.cityforu
				div.popinContent
					h1 City4u
					ul
						li Producer : 
							a(href='https://city4u.com' target='_blank') City4u
						li Creative partners : 
							a(href='https://www.deep-forest.fr' target='_blank') Deep Forest&nbsp;·&nbsp;
							a(href='https://prisme.ae' target='_blank') Prisme&nbsp;·&nbsp;
							a(href='https://www.orbe.mobi' target='_blank') Orbe
						li More info : 
							a(href='https://vimeo.com/467313772/169aa94b3d' target='_blank') Video 
					p City4u offers a discovery experience of the city through the prism of immersive media. The offer is presented as a multi-faceted avant-garde route to discover the secrets of a city.
				div.close
	</transition>
</template>

<script>

	import actionManager from '../actions';

	import GlobalLinks from './common/GlobalLinks.vue';

	export default {

		components: { GlobalLinks },

		props:['appState'],

		data() {
			return {
				email: '',
				password: '',
				cpassword: '',
				apassword: '',
				firstname: '',
				lastname: '',
				popinWindow: false
			};
		},

		created: function(){
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			this.$root.$on('toggleNavigatorEmit', this.onToggleNavigatorEmit)
		},

		beforeDestroy: function(){
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// If limits appear, have a look at https://vuex.vuejs.org
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			this.$root.$off('toggleNavigatorEmit', this.onToggleNavigatorEmit)
		},

		computed: {
			tab: function () {
				if (this.appState && this.appState.prehome) {
					return this.appState.prehome.tab;
				} else {
					return 'accueil';
				}
			}
		},

		methods: {

			connexion(){
				if (!this.appState.user) {
					actionManager.trigger('prehome:changeTab', {tab: 'form'});
				} else {
					actionManager.trigger('prehome:changeTab', {tab: 'loggedin'});
				}
			},

			login(){
				
				var user = {
				  email: this.email,
				  password: this.password
				};

				actionManager.trigger('Login', user);
				
			},

			subscribe(){

				var form = {
				  email: this.email,
				  password: this.password,
				  cpassword: this.cpassword,
				  apassword: this.apassword
				};

				actionManager.trigger('SignUp', form);

				this.email = this.password = this.cpassword = this.apassword = ''

			},

			openPopin( namePopin) {
				this.popinWindow = namePopin;
			},

			closePopin() {
				this.popinWindow = false;
			},

			// ORIGINAL toggleNavigatorEmit EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
			// (migration Vue v1 to v2)
			onToggleNavigatorEmit: function(isOpen){
				this.navigatorOpen = isOpen;
			}
		}
	}

</script>

<style lang="stylus">

html
	//overflow: hidden
	height: 100%
	
body
	//overflow: hidden
	height: 100%
	letter-spacing:0px
	
.prehome-container
	width: 100%
	height: 100%
	min-height: 100%

.prehome
	position: absolute
	width: 100%
	height: 100%
	min-height: 100%
	background-image: url(../assets/images/bg-home.png)
	background-size: cover
	background-position: center center
	color: white
	text-align:center
	overflow: auto

	.content-vcentered
		margin-top: 48vh
		margin-bottom: 30vh
		transform: translateY(-50%)
		position: relative
		z-index: 0
		.baseline
			width: 80%
			margin-left: auto
			margin-right: auto
	
	.scroll
		height: 70px
		width: 200px
		position: absolute
		bottom: 50px
		left: 50%
		transform: translate(-50%,0px)
		background-image: url(../assets/images/scroll-down.png)
		background-size: 40px
		background-repeat: no-repeat
		background-position: center bottom


	.logo
		display: inline-block
		width: 800px
		height: 400px
		background-image: url(../assets/images/logo-nodal.png)
		background-position: center center
		background-size: contain
		background-repeat: no-repeat

	h1
		font-family: 'DinBold'
		font-size: 26px
		text-align: center
		margin: 0
		padding: 0
		margin-top: -145px

	p
		margin: auto
		max-width: 800px
		display: block
		padding: 0
		margin-top: 0px
		font-family: 'DinRegular'
		font-size:14px


	.links ul
		position: absolute
		top: 20px 
		right: 20px
		list-style-type: none
		//text-align: right
		margin: 0
		padding : 0
		li
			display: inline-block
			//margin-left: 10px
			a 
				font-family: 'DinRegular', Helvetica
				color: white
				font-size: 13px
				text-decoration: none
				cursor: pointer
			a:hover
				color: #F39A74
				
	.slide-down
		display: inline-block
		width: 64px
		height: 30px
		background-image: url(../assets/images/icon-slide.png)
		background-size: cover
		background-position: center center
		position: absolute
		bottom:12%;
		left: 50%
		transform: translateX(-50%)

@media (max-width: 800px)
	.tour
		background-color: #fff
		h1
			background-color: #f49e75
			margin-top: -28px
			padding: 20px
			padding-left: 20px
			padding-right: 20px
			font-size:30px
			font-family: 'DinBold', Helvetica
		.content
			padding-left: 80px
			padding-right: 80px
			padding-bottom: 40px
			color: #585857
			max-width: 800px
			margin-left: auto
			margin-right: auto
			h1
				margin-top: 40px
				font-size: 22px
				width: fit-content
				padding: 0
				font-family: 'DinBold', Helvetica
				background: transparent
				box-shadow: #fff6eb 0px -10px 0px 0px inset
				margin-left: auto
				margin-right: auto
			.part-one
				p
					font-size: 16px
					line-height: 24px
					margin-bottom: 24px
			.part-two
				p
					font-size: 16px
					line-height: 24px
				.video
					position: relative
					padding-bottom: 56.25%
					padding-top: 20px
					height: 0
					margin-top: 40px
					margin-bottom: 20px
					border-radius: 10px
					background: #000000
					iframe
						position: absolute
						top: 0
						left: 0
						width: 100%
						height: 100%
			.clear
				padding-bottom:20px


@media (min-width: 801px)
	.tour
		background-color: #fff
		h1
			background-color: #f49e75
			margin-top: -28px
			padding: 20px
			padding-left: 80px
			padding-right: 80px
			font-size:30px
			font-family: 'DinBold', Helvetica
		.content
			padding-left: 80px
			padding-right: 80px
			padding-bottom: 40px
			color: #585857
			max-width: 800px
			margin-left: auto
			margin-right: auto
			h1
				margin-top: 40px
				font-size: 22px
				width: fit-content
				padding: 0
				font-family: 'DinBold', Helvetica
				background: transparent
				box-shadow: #fff6eb 0px -10px 0px 0px inset
				margin-left: auto
				margin-right: auto
			.part-one
				p
					font-size: 16px
					line-height: 24px
					margin-bottom: 24px
			.part-two
				p
					font-size: 16px
					line-height: 24px
				.video
					position: relative
					padding-bottom: 56.25%
					padding-top: 20px
					height: 0
					margin-top: 40px
					margin-bottom: 20px
					border-radius: 10px
					background: #000000
					iframe
						position: absolute
						top: 0
						left: 0
						width: 100%
						height: 100%
			.clear
				padding-bottom:20px
		
.how
	background-color: #fff6eb
	.content
		margin-top: -28px
		padding: 20px
		padding-left: 80px
		padding-right: 80px
		color: #585857
		vertical-align:top
		max-width: 800px
		margin-left: auto
		margin-right: auto
		h1
			margin-top: 20px
			font-size: 22px
			width: fit-content
			padding: 0
			font-family: 'DinBold', Helvetica
			background: transparent
			box-shadow: #ffffff 0px -10px 0px 0px inset
			margin-left: auto
			margin-right: auto
		.steps
			vertical-align:top
			font-family: 'DinBold', Helvetica
			.step-one
				display: inline-block
				vertical-align:top
				width: 150px
				margin: 10px
				.bg
					width: 150px
					height: 150px
					background: url(../assets/images/steps/1.png)
					background-size: 150px 150px
					background-position: center center
				h2
					display: inline-block
					border-radius: 5px
					padding: 5px 10px
					padding-bottom:1px
					margin-bottom:5px
					background: white
					font-size: 16px
					font-family: 'DinBold', Helvetica
				p
					margin-top: 5px
					font-size: 14px
					line-height: 20px
			.step-two
				display: inline-block
				vertical-align:top
				width: 150px
				margin: 10px
				.bg
					width: 150px
					height: 150px
					background-image: url(../assets/images/steps/2.png)
					background-size: 150px 150px
					background-position: center center
				h2
					display: inline-block
					border-radius: 5px
					padding: 5px 10px
					padding-bottom:1px
					background: white
					margin-bottom:5px
					font-size: 16px
					font-family: 'DinBold', Helvetica
				p
					margin-top: 5px
					font-size: 14px
					line-height: 20px
			.step-three
				display: inline-block
				vertical-align:top
				width: 150px
				margin: 10px
				.bg
					width: 150px
					height: 150px
					background-image: url(../assets/images/steps/3.png)
					background-size: 150px 150px
					background-position: center center
				h2
					display: inline-block
					border-radius: 5px
					padding: 5px 10px
					background: white
					padding-bottom:1px
					margin-bottom:5px
					font-size: 16px
					font-family: 'DinBold', Helvetica
				p
					margin-top: 5px
					font-size: 14px
					line-height: 20px
			.step-four
				display: inline-block
				vertical-align:top
				width: 150px
				margin: 10px
				.bg
					width: 150px
					height: 150px
					background-image: url(../assets/images/steps/4.png)
					background-size: 150px 150px
					background-position: center center
				h2
					display: inline-block
					border-radius: 5px
					background: white
					padding: 5px 10px
					padding-bottom:1px
					margin-bottom:5px
					font-size: 16px
					font-family: 'DinBold', Helvetica
				p
					margin-top: 5px
					font-size: 14px
					line-height: 20px
			.step-five
				display: inline-block
				vertical-align:top
				width: 150px
				margin: 10px
				.bg
					width: 150px
					height: 150px
					background-image: url(../assets/images/steps/5.png)
					background-size: 150px 150px
					background-position: center center
				h2
					display: inline-block
					border-radius: 5px
					background: white
					padding: 5px 10px
					padding-bottom:1px
					margin-bottom:5px
					font-size: 16px
					font-family: 'DinBold', Helvetica
				p
					margin-top: 5px
					font-size: 14px
					line-height: 20px
		.clear
			padding-bottom:40px
			width:100%

.showcase
	background-color:#fff
	h1
		background-color: #f49e75
		margin-top: -28px
		padding: 20px
		padding-left: 80px
		padding-right: 80px
		font-size:30px
		font-family: 'DinBold', Helvetica
	.content
		padding-left: 80px
		padding-right: 80px
		padding-bottom: 40px
		color: #585857
		text-align: center
		max-width: 800px
		margin-left: auto
		margin-right: auto
		h1
			margin-top: 40px
			font-size: 22px
			width: fit-content
			padding: 0
			font-family: 'DinBold', Helvetica
			background: transparent
			box-shadow: #fff6eb 0px -10px 0px 0px inset
			margin-left: auto
			margin-right: auto
			margin-bottom: 25px
		.showcaseImg
			position:relative
			text-align: left
			display: inline-block
			width:30%
			height:250px
			background-repeat:no-repeat
			background-position:center center
			background-size: cover
			margin: 0.8% 1%
			border-radius: 10px
			.colorImg
				//position:absolute
				top:0
				left:0
				width:100%
				height:100%
				.textImage
					color:#fff
					.legend
						text-align: left
						bottom: 0
						left: 0
						line-height: 26px
						margin: 0 20px
						opacity: 0
						font-family: 'DinRegular', Helvetica
						text-transform:uppercase
			.colorImg:hover
				//position:absolute
				top:0
				left:0
				background-color:rgba(244, 158, 117,0.75)
				border-radius: 10px
				opacity:1
				width:100%
				height:100%
				.textImage
					color:#fff
					font-size:20px
					font-weight:bold
					.legend
						opacity: 1

		.showcaseImg.playground
			background-image:url(../assets/images/showcase/playground.jpg)

		.showcaseImg.vibes
			background-image:url(../assets/images/showcase/vibes.jpg)

		.showcaseImg.walks
			background-image:url(../assets/images/showcase/walks.jpg)

		.showcaseImg.postpiper
			background-image:url(../assets/images/showcase/postpiper.jpg)

		.showcaseImg.code
			background-image:url(../assets/images/showcase/code.jpg)

		.showcaseImg.saleve
			background-image:url(../assets/images/showcase/saleve.jpg)

		.showcaseImg.drouot
			background-image:url(../assets/images/showcase/drouot.jpg)

		.showcaseImg.mucem
			background-image:url(../assets/images/showcase/mucem.jpg)

		.showcaseImg.mars
			background-image:url(../assets/images/showcase/mars.jpg)

		.showcaseImg.danser
			background-image:url(../assets/images/showcase/danser.jpg)

		.showcaseImg.fabrique
			background-image:url(../assets/images/showcase/fabrique.jpg)

		.showcaseImg.neighbors
			background-image:url(../assets/images/showcase/neighbors.jpg)

		.showcaseImg.dmacdlo
			background-image:url(../assets/images/showcase/dmacdlo.jpg)

		.showcaseImg.cityforu
			background-image:url(../assets/images/showcase/cityforu.jpg)
						
	.clear
		padding-bottom:20px
			
.footer
	color:#fff
	background-color: #575756
	padding-bottom: 30px
	padding-top: 30px
	a
		color:#fff
		h1
		.mail
			background-color: white
			color: #575756
			font-size: 16px
			margin-top: 10px
	.logo
		background-image: url(../assets/images/logo-nodal.png)
		background-size: contain
		background-repeat:no-repeat
		background-position:center center
		width: 180px
		height: 180px
	h1
		display: inline-block
		border-radius: 5px
		border: 1px solid #fff
		padding: 5px 10px
		padding-top: 8px
		margin-bottom:0px
		font-size: 16px
		font-family: 'DinRegular', Helvetica
	h1:hover
		background-color:#f49e75
		border:1px solid #f49e75
	.content
		vertical-align:top
		padding-top:40px
		.col
			display: inline-block
			width:33.33%
			vertical-align:top
			h1
				border:none
				font-family: 'DinBold', Helvetica
				font-size: 16px
			h1:hover
				background-color:transparent
				border:none
				color:#fff
			ul
				list-style-type: none
				padding: 0
				font-size: 14px
				line-height: 20px
				li
					a
						text-decoration:none
						p
							padding:0
							margin:0
							color:#fff
						p:hover
							color:#f49e75
.back-to-top
	background-color: #343333
	padding:50px
	font-size: 10px
	.top-icon
		background-image: url(../assets/images/back-to-top.png)
		background-size: 30px 30px
		background-repeat:no-repeat
		background-position:center center
		width: 30px
		height: 30px
		margin: auto
		margin-bottom: 15px
	.top-icon:hover
		background-image: url(../assets/images/back-to-top-over.png)
		color:#f49e75
		a:hover
			color:#f49e75
	p
		margin-top: 5px
	a
		text-decoration:none
		color:#fff
	a:hover
		color:#f49e75
		
.contact
	background-color: #fffaec
	h1
		background-color: #f49e75
		margin-top: -28px
		padding: 20px
		padding-left: 80px
		padding-right: 80px
		font-size:30px
		font-family: 'DinBold', Helvetica
	.content
		padding-left: 80px
		padding-right: 80px
		padding-bottom: 40px
		color: #585857
		h1
			margin-top: 0
			background-color: #fffaec
			border-bottom: 2px solid #f49e75
			font-size: 24px
			font-family: 'DinRegular', Helvetica
		input.beta-test
			display: block
			margin: auto
			background-color: #fff
			border: none
			border-radius: 5px
			padding: 7px 10px
			margin-top: 10px
			margin-bottom: 5px
			font-family: 'DinRegular', Helvetica
			font-size: 16px
			color: #585857
			width:75%
			text-align:center

a.big-button
	display:inline-block
	background-color: #F39A74
	border-radius: 40px
	color: white
	text-decoration: none
	font-family: 'DinBold', Helvetica
	font-size: 20px
	margin-top: 50px
	margin-bottom: 10px
	min-width: 150px
	padding-left: 20px
	padding-right: 20px
	height: 40px
	line-height: 42px

a.subscribe
	display: inline-block
	width: auto
	margin: auto
	margin-bottom: 20px
	cursor:pointer
a.subscribe:hover
	color: #F39A74

input.home
	display: block
	width: 250px
	margin: auto
	background-color: rgba(255,255,255,.15)
	border: 0
	border-radius: 10px
	padding: 7px 10px
	margin-top: 5px
	margin-bottom: 5px
	font-family: 'DinRegular', Helvetica
	font-size: 17px
	color: white
	position: relative
	z-index: 10
	text-align: center

span.error
	color: #F39A74
	font-family: 'DinRegular', Helvetica
	font-size: 14px

a.xp-bt 
	display: inline-block
	width: 150px
	margin: 10px 
	text-align: center
	vertical-align: top
	text-decoration: none
	span.ico
		display: inline-block
		width: 106px
		height: 106px
		background-size: 106px 106px
		background-position: center center
		background-repeat: no-repeat
		&.generic
			background-image: url(../assets/images/generic-bt.png)
	span.title
		display: inline-block
		font-family: 'DinBold'	
		font-size: 17px
		color: white
		margin-top: 4px	

.popin
	z-index:1000
	position:fixed
	top:0
	left:0
	right:0
	bottom:0
	background-color:rgba(0, 0, 0, .85)

	.popinClose
		z-index:1000
		position:fixed
		top:20px
		right:20px
		background-size: 30px 30px
		background-repeat:no-repeat
		background-position:center center
		width: 30px
		height: 30px
	.popinProject
		z-index:1001
		position:fixed
		top:10%
		left:20%
		right:20%
		bottom:10%
		background-color:#fff
		padding:20px
		color: #585857
		overflow: scroll
		border-radius: 10px
		.previewImg
			width:100%
			height:auto
		h1
			margin-top: 0
			font-size: 30px
			border-bottom: 1px solid #ffffff
			font-family: 'DinBold', Helvetica
			padding-bottom: 10px
		p
			text-align:left
			font-family: 'DinRegular', Helvetica
			font-size:16px
			line-height:22px
			width:100%
			max-width:100%
			text-align:justify
		ul
			list-style-type: none
			font-size: 12px
			line-height: 20px
			padding-left: 0
			color: #ffffff
			font-family: 'DinRegular', Helvetica
			text-transform: uppercase
			a
				text-decoration: none
				color: #f49e75
		.popinContent
			position: absolute
			bottom: 0
			left: 0
			padding: 40px
			background-color: rgba(0,0,0,.6)
			color: #ffffff
			background-position:center center
		.close
			z-index:1000
			position:fixed
			top:20px
			right:20px
			background-image: url(../assets/images/close-button.png)
			background-size: 30px 30px
			background-repeat:no-repeat
			background-position:center center
			width: 30px
			height: 30px

	.popinProject.vibes
		background-image:url(../assets/images/showcase/vibes.jpg)
		background-size:cover
		background-position:center

	.popinProject.playground
		background-image:url(../assets/images/showcase/playground.jpg)
		background-size:cover
		background-position:center

	.popinProject.walks
		background-image:url(../assets/images/showcase/walks.jpg)
		background-size:cover
		background-position:center

	.popinProject.postpiper
		background-image:url(../assets/images/showcase/postpiper.jpg)
		background-size:cover
		background-position:center

	.popinProject.code
		background-image:url(../assets/images/showcase/code.jpg)
		background-size:cover
		background-position:center

	.popinProject.saleve
		background-image:url(../assets/images/showcase/saleve.jpg)
		background-size:cover
		background-position:center

	.popinProject.drouot
		background-image:url(../assets/images/showcase/drouot.jpg)
		background-size:cover
		background-position:center

	.popinProject.mucem
		background-image:url(../assets/images/showcase/mucem.jpg)
		background-size:cover
		background-position:center

	.popinProject.mars
		background-image:url(../assets/images/showcase/mars.jpg)
		background-size:cover
		background-position:center

	.popinProject.danser
		background-image:url(../assets/images/showcase/danser.jpg)
		background-size:cover
		background-position:center

	.popinProject.fabrique
		background-image:url(../assets/images/showcase/fabrique.jpg)
		background-size:cover
		background-position:center

	.popinProject.neighbors
		background-image:url(../assets/images/showcase/neighborsWindow.jpg)
		background-size:cover
		background-position:center

	.popinProject.dmacdlo
		background-image:url(../assets/images/showcase/dmacdlo.jpg)
		background-size:cover
		background-position:center

	.popinProject.cityforu
		background-image:url(../assets/images/showcase/cityforu.jpg)
		background-size:cover
		background-position:center

.popin
	opacity: 1
	transition: all .3s ease
	.popinProject
		transition: all .3s ease
		transform: scale(1)
	&.popin-enter
		opacity: 0
		.popinProject
			transform: scale(1.05)
	&.popin-leave-active 
		opacity: 0
		.popinProject
			transform: scale(1.05)
			
b
	font-family: 'DinBold'
	
strong
	font-family: 'DinBold'
		

@media (max-width: 800px)

	.categories
		visibility: hidden
	.logging
		visibility: hidden
	.prehome
		.logo
			width: 75%
		h1
			margin-top: -176px
			font-size: 18px
		.p
			font-size: 14px
			width: 80%

	.tour
		.content
			padding-left: 40px
			padding-right: 40px

	.how
		padding-bottom: 150px
		.content
			h1
			.steps
				vertical-align:top
				font-family: 'DinBold', Helvetica
				.step-one
					width: 150px
					margin: 10px
					.bg
						width: 150px
						height: 150px
						background-size: 150px 150px
				.step-two
					width: 150px
					margin: 10px
					.bg
						width: 150px
						height: 150px
						background-size: 150px 150px
				.step-three
					width: 150px
					margin: 10px
					.bg
						width: 150px
						height: 150px
						background-size: 150px 150px
				.step-four
					width: 150px
					margin: 10px
					.bg
						width: 150px
						height: 150px
						background-size: 150px 150px
				.step-five
					width: 150px
					margin: 10px
					.bg
						width: 150px
						height: 150px
						background-size: 150px 150px

	.showcase
		.content
			padding-left: 40px
			padding-right: 40px
			.showcaseImg
				width:100%
				height:100px

	.popin
		.popinProject
			top: 0
			left: 0
			right: 0
			bottom: 0
			border-radius: 0

@media (min-width: 501px)

	.categories
		visibility: visible
	.logging
		visibility: visible
	.prehome
		.logo
			width: 800px
		h1
			margin-top: -145px
			font-size: 26px
		.p
			max-width: 65%
			font-size: 14px

	.tour
		.content
			padding-left: 80px
			padding-right: 80px

	.how
		padding-bottom: 120px
		.content
			h1
			.steps
				vertical-align:top
				font-family: 'DinBold', Helvetica
				.step-one
					width: 120px
					margin: 10px
					.bg
						width: 120px
						height: 120px
						background-size: 120px 120px
				.step-two
					width: 120px
					margin: 10px
					.bg
						width: 120px
						height: 120px
						background-size: 120px 120px
				.step-three
					width: 120px
					margin: 10px
					.bg
						width: 120px
						height: 120px
						background-size: 120px 120px
				.step-four
					width: 120px
					margin: 10px
					.bg
						width: 120px
						height: 120px
						background-size: 120px 120px
				.step-five
					width: 120px
					margin: 10px
					.bg
						width: 120px
						height: 120px
						background-size: 120px 120px
	
	.showcase
		.content
			.showcaseImg
				width:30%
				height:250px

	.popin
		.popinProject
			top: 10%
			left: 20%
			right: 20%
			bottom: 10%
			border-radius: 10px


</style>


