var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.project.custom && _vm.project.custom.display
    ? _c(
        "div",
        {
          staticClass: "project-item",
          style: [
            _vm.cover
              ? {
                  "background-image": "url(" + _vm.cover + ")",
                  "background-size": "cover"
                }
              : ""
          ],
          on: {
            click: function($event) {
              return _vm.projectClickHandler()
            }
          }
        },
        [
          _vm.project.value.users.length > 0
            ? _c("div", { staticClass: "shared-icon" }, [
                _vm.project.value.owner.includes(_vm.currentUser)
                  ? _c("span", { staticClass: "projUsrType icon icon_owner" })
                  : _vm._e(),
                _vm.project.value.viewers !== undefined &&
                _vm.project.value.viewers.includes(
                  _vm.currentUser.replace("org.couchdb.user:", "")
                )
                  ? _c("span", { staticClass: "projUsrType icon icon_reader" })
                  : _vm._e(),
                _vm.project.value.editors !== undefined &&
                _vm.project.value.editors.includes(
                  _vm.currentUser.replace("org.couchdb.user:", "")
                )
                  ? _c("span", { staticClass: "projUsrType icon icon_author" })
                  : _vm._e()
              ])
            : _vm._e(),
          _c("div", { staticClass: "desc-ctn" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.project.value.name))
            ]),
            _c("div", { staticClass: "author" }, [
              _vm._v(
                _vm._s(_vm.project.value.owner.replace("org.couchdb.user:", ""))
              )
            ]),
            _vm.createdDate
              ? _c("div", { staticClass: "date created" }, [
                  _vm._v(_vm._s(_vm.createdDate))
                ])
              : _vm._e(),
            _vm.updatedDate
              ? _c("div", { staticClass: "date updated" }, [
                  _vm._v(_vm._s(_vm.updatedDate))
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "actions-bts" }, [
            _vm.project.value.owner == _vm.currentUser
              ? _c("div", {
                  staticClass: "action-bt bt-trash",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.removeProject($event)
                    }
                  }
                })
              : _vm._e(),
            _vm.project.value.owner == _vm.currentUser
              ? _c("div", {
                  staticClass: "action-bt bt-share",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.shareProject($event)
                    }
                  }
                })
              : _vm._e(),
            _vm.project.value.owner == _vm.currentUser
              ? _c("div", {
                  staticClass: "action-bt bt-duplicate",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.duplicateProject($event)
                    }
                  }
                })
              : _vm._e()
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }