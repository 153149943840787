<!-- Spinner vue -->

<template lang="pug">
<transition name="spinner">
div.waiting
	div.spinner-ctn
		div.dot.dot1
		div.dot.dot2
		div.dot.dot3
</transition>
</template>


<script>

import { stores } from '../../stores/MainStore.js';
import Item from  './NavigatorItem.vue';

export default {

	data() {
		return {

		}
	},

	created: function(){

	},

	methods:{


		
	}

}

</script>

<style lang="stylus">

.waiting
	display: block
	width: 100%
	height: 100%
	background-color: var(--modalTransparentBgColor)
	z-index: 1000
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	
	
	.spinner-ctn
		position: absolute
		top: 50%
		left: 50%
		
		.dot
			width: 10px
			height: 10px
			position: absolute
			top: -5px
			left: -5px
			background-color: var(--bgColor)
			border-radius: 10px
			opacity: 0
			animation: fade 0.7s 0.05s infinite
			
			&.dot1
				transform: translate(-20px)
				animation: fade 0.7s 0.0s infinite
				
			&.dot3
				transform: translate(20px)
				animation: fade 0.7s 0.1s infinite
		
	@keyframes fade
		0% { opacity: 0 }
		100% { opacity: 1 }


.spinner
	transition: all 0.4s ease-in-out
	visibility: visible
	opacity: 1

	&.spinner-enter
		visibility: visible
		opacity: 1
	&.spinner-leave-active 
		visibility: hidden
		opacity: 0
		

</style>




