var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion memory-bloc" }, [
    _c(
      "div",
      {
        staticClass: "accordion-title",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.accordion = !_vm.accordion
          }
        }
      },
      [
        _c("span", { staticClass: "text" }, [_vm._v("Sessions")]),
        _c("span", { staticClass: "arrow", class: { close: !_vm.accordion } })
      ]
    ),
    _c("div", { staticClass: "form-content", class: { open: _vm.accordion } }, [
      _c("div", { staticClass: "form-accordion isAccordion" }, [
        _vm._m(0),
        _c("div", { staticClass: "form-accordion-content open" }, [
          _c(
            "div",
            { staticClass: "list-memory list-session" },
            _vm._l(_vm.sessionsList, function(session, i) {
              return _c(
                "div",
                {
                  staticClass: "memory-line",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.openSessionConfigurator(session.value._id)
                    }
                  }
                },
                [
                  _vm._m(1, true),
                  _c("span", { staticClass: "col col2" }, [
                    _vm._v(_vm._s(session.value.name))
                  ]),
                  _c("span", { staticClass: "col col3" }),
                  _c(
                    "span",
                    {
                      staticClass: "col col4",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteSession(session)
                        }
                      }
                    },
                    [_c("span", { staticClass: "bt-trash" })]
                  ),
                  _c("div", { staticClass: "clear" })
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "big-bt memory-bt", on: { click: _vm.addSession } },
            [
              _c("span", { staticClass: "text" }, [
                _vm._v("Add new session template")
              ]),
              _c("span", { staticClass: "bt-plus" }, [_vm._v("+")])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title tab-title" }, [
      _c("span", { staticClass: "col col1" }, [_vm._v("Name")]),
      _c("span", { staticClass: "col col2" }),
      _c("span", { staticClass: "col col3" }),
      _c("span", { staticClass: "col col4" }),
      _c("div", { staticClass: "clear" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "col col1" }, [
      _c("span", { staticClass: "icon session" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }