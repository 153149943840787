import { EventEmitter } from 'events';

/* Abstract */
class ServiceAbstract extends EventEmitter {

    constructor(deps = []) {
        super();
        
        this.deps = {};
        this._initDeps(deps);

        this.beforeCreate();
    }

    static get ID() {
        // to be implemented in child class
    }

    _initDeps(deps) {

        let makePropName = (dep) => {
            let name = dep.constructor.ID.substring(dep.constructor.ID.indexOf(':') + 1);
            name = name.substring(0, 1).toLowerCase() + name.substring(1);
            return name;
        }

        deps.forEach(dep => this.deps[makePropName(dep)] = dep);
    }

    beforeCreate() {
        // to be implemented in child class
    }
}

export default ServiceAbstract;