import ActionAbstract from '../ActionAbstract'
import * as helper from '../../helper'

class Link extends ActionAbstract {

    static get ID() {
        return 'actions:template:link'
    }

    run(state) {

        let id = this.getParam('id')
        switch (this.getParam('action')) {
            case "success":
                this.trigger('editor:menu:filter', { type: state.editor.menu.type, id: state.editor.targetId }) // refresh menu
                return this.composeState(state, {
                    isLoading: false,
                    project: {
                        templates: this.arrayPush(this.getParam('doc')),
                        value: {
                            template: this.arrayPush(id),
                            accordion: {
                                template: true
                            }
                        }
                    }
                })

            case "error":
                console.error('Fail to load template', id)
                return this.composeState(state, {
                    isLoading: false,
                    templates: {
                        error: {
                            code: this.getParam('code'),
                            message: this.getParam('message'),
                            action: 'link'
                        }
                    }
                })
        
            default:

                // check if exists
                if (state.project.value.template && state.project.value.template.indexOf(id) != -1) return false;

                // link the template to the current project
                this.deps.mainStore.template.getTemplate(id).then(
                    response => {
                        this.trigger('template:link', { action: 'success', id: id, doc: response })
                    },
                    error => {
                        this.trigger('template:link', { action: 'error', id: id, code: error.code, message: error.message  })
                    }
                )
                return this.composeState(state, {
                    isLoading: true
                })
        }
    }

}

export default Link