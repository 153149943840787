<!-- Modal vue -->

<template lang="pug">
div#sharingTab(v-if="projectInfo !== undefined")
	div.input.shareUserInput
		dropdown(v-on:click='activeType' :items='UserType', :defaultItem='UserType[1]', @update-type="changeType")
		input.value.inputModal(placeholder="Enter a user identifier", v-model="usermail", v-on:input="trimMail", v-on:keyup.enter="addUser", type="text")
		div.addsharedUser(v-on:click.stop="addUser", :disabled="!(usermail && usermail.trim())", value="Add", type="button") Add

	div.editors.sharing

		div.inlinelist(v-if="projectInfo.owner")
			span.icon.icon_owner
			span.shareGroupName Owner
			span.user {{ projectInfo.owner.replace('org.couchdb.user:', '') }}

		div.inlinelist(v-if="projectInfo.editors")
			span.icon.icon_author
			span.shareGroupName Editor
			span.user(v-for="editor in projectInfo.editors", v-on:click.once="removeUser(editor, true)") {{editor}}
					icon(:name="cross")

		div.inlinelist(v-if="projectInfo.viewers")
			span.icon.icon_reader
			span.shareGroupName Viewer
			span.user(v-for="viewer in projectInfo.viewers", v-on:click="removeUser(viewer, false)") {{viewer}}
				icon(:name="cross")

		p.error(v-if="sharingError") {{ sharingError}}
		p.error(v-if="responseMessage") {{responseMessage}}
</template>


<script>
import Icon from '../common/Icon.vue'
import actionManager from '../../actions';
import dropdown from '../common/dropdown.vue';

export default {

	// settings	the modal configuration (ie. app.state.modal)
	props: {
		method: { type: Function },
		index: {type: Number},
		projectInfo: { type: Object },
		name: { type: String },
		// sharingError: {type: String},
	},

	components: {
		Icon, dropdown
	},

    data() {
        return {
					value: '',
					default: [],
					error: '',
					icon_owner: 'icon_owner',
					icon_author: 'icon_author',
					icon_reader: 'icon_reader',
					cross: 'cross',
					usermail: '',
					activeType: 'Viewer',
					UserType: ['Editor', 'Viewer'],
					showMenu: false,
					sharingError: "",
					responseMessage: ""
		}
    },

		created: function() {

    },

		// watch: {
		// 		sharingError: function(newVal, oldVal) { // watch it
		// 			this.error = newVal
		// 			this.$emit('send-index', this.index)
		// 			this.waiting = false
		// 		}
		// },

	methods: {

		trimMail: function(){
			this.usermail = this.usermail.trim();
		},
		changeType: function(type) {
			this.activeType = type;
		},

		isAlready: function(type) {
			if (this.projectInfo[type] == undefined ) return false
			return this.projectInfo[type].includes(this.usermail) ? true : false
		},

		removeUser: function(user, type){
			let usrType = type ? "editor" : "viewer"
			this.waiting = true

			this.requestShare("removeRole", this.projectInfo, usrType, user)

		},

		addUser: function() {
			let mailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			let validEmail = this.usermail.match(mailPattern) ? true : false
			let ownerNorm = this.projectInfo.owner.replace('org.couchdb.user:', '')

			let checkUsers = this.isAlready("viewers") || this.isAlready("editors") ? true : false // ajouter check si deja present

			if(checkUsers) {
				this.error = `Project is already shared with ${this.usermail}`
			} else {

				if (this.usermail !== ownerNorm && this.usermail.match(mailPattern) && !checkUsers){
					this.requestShare("addRole", this.projectInfo, this.activeType.toLowerCase(), this.usermail)
					this.usermail = ""
				} else {
					this.error = `${this.usermail} is not a valid user identifier`
				}

			}

			setTimeout(() => {
				this.error = ''
				this.usermail = "";
			}, 4000)
		},

		requestShare: function(method, project, type, user){
			let self = this
			actionManager.trigger('projects:share', {
				method: method,
				project: project,
				type: type,
				user: user,
				callback: (res, error) => {
					if (error) {
						self.sharingError = error
						return
					} else self.responseMessage = res 

					let typeList = `${type}s`
					if (method == "removeRole") {
						self.projectInfo[typeList].splice(self.projectInfo[typeList].indexOf(user),1)
						self.projectInfo[typeList].splice(self.projectInfo.users.indexOf(user),1)
					} else {
						self.projectInfo[typeList].push(user)
						self.projectInfo.users.push(user)
					} 
				}
			})
			// this.$emit('send-index', this.index)
		},

		listShared: function() {

		}
    }
}
</script>

<style lang="stylus">
.shareWraper
	width: 100%

#dropdown
	height: 40px

#sharingTab
	width: 100%
	.typeSelector
		-webkit-appearance: none
		-moz-appearance: none
		appearance: none
		height: auto
		border: 0;
		margin: 0
		padding: .75em
		border-radius: 0
		overflow: hidden
		text-overflow: ellipsis

	.shareUserInput
		width: 100%
		display: inline-flex
		flex-flow: row nowrap
		background-color: var(--iconPuzzleColor)
		border-radius: 8px
		height: 40px
		input
			border: none
		.inputModal
			width: 85%
			text-align: left
			font-size: 16px
			color: black
			padding-left: 15px
			font-family: dinBold
			padding-top: 7px
			outline: none
			background: var(--linkMenuColor)
		.addsharedUser
			color: white
			display: flex
			font-size: 14px
			text-align: center
			cursor: pointer
			width: 15%
			align-self: center
			align-items: center
			justify-content: center
			background-color: var(--selectedColor)
			height: 40px
			border-bottom-right-radius: 8px
			border-top-right-radius: 8px
			padding-top: 5px
			box-sizing: border-box
			font-family: 'DinBold'
	.sharing
		display: flex
		flex-flow: column nowrap
		width: auto
		background-color: var(--inspectorLevel1)
		border-radius: 8px
		padding: 10px
		margin-top: 5px

		.inlinelist
			display: inline-flex
			flex-flow: row wrap
			justify-content: start
			align-items: center
			width: 100%
			font-size: 12px
			color: #999
			margin-top: 5px
			z-index: 2
		.user
			display: flex
			margin-left: 5px
			background-color: var(--bgColor)
			color: var(--inputTextColor)
			padding: 4px 10px 4px 10px
			border-radius: 20px
			box-sizing: border-box
			height: 24px
			font-size: 14px
			align-items: center
			font-family: 'DinBold'
			span.cross
				display: none
				cursor: pointer
				margin-left: 4px
				height: 14px
				width: 14px
				margin-top: -1px
			&:hover
				border: solid 1px #F49E75
				span.cross
					display: block
		.shareGroupName
			color: #F49E75
			font-size: 12px
			margin-left: 4px
			margin-right: 5%
			font-family: DinBold
		span.icon
			width: 18px
			height: 18px
			background-color: #F49E75
			transform: translateY(0);
			span
				margin-left: 2px
</style>
