<template lang="pug">

div.preview-media
	div.cover-back(v-on:click.stop="closeModal")

	div.modal
		div.bt-close(v-on:click.stop="closeModal")
		div.content
			div.preview
				image-visu(v-if="state.mediaPreview.assetType=='image'", :media="state.mediaPreview")
				sound-visu(v-if="state.mediaPreview.assetType=='sound'", :media="state.mediaPreview")
				video-visu(v-if="state.mediaPreview.assetType=='video'", :media="state.mediaPreview")
				file-visu(v-if="state.mediaPreview.assetType=='file'", :media="state.mediaPreview")
				//div.img-ctn
					img(v-bind:src="state.mediaPreview.url")

			Tagging(:library="state", :tagModal="false")

</template>

<script>
import actionManager from  '../../actions';

import Tagging from  './tagging.vue';
import Icon from  '../common/Icon.vue';

// VISUS
import ImageVisu from "./ImageVisu.vue";
import SoundVisu from "./SoundVisu.vue";
import VideoVisu from "./VideoVisu.vue";
import FileVisu from "./FileVisu.vue";

export default {

	props: ['state'],

	components: { Icon, ImageVisu, SoundVisu, VideoVisu, FileVisu, Tagging },

	data() {
		return {
			newTag: '',
			error: ''
		}
	},

	created: function(){

	},

	methods:{


		closeModal: function(){
			actionManager.trigger('main:toggleMediaPreview', { open : false });
		},


	},

	filters: {
		// @todo: to be removed (dead code)
		// formatURL: function(url) {
		// 	if( url){
		// 		url = url.replace("https://assets.nodal.studio/", '');
		// 		url = url.replace(this.$config.assetURL, '');
		// 		url = url.replace("upload/", '');
		// 		return url;
		// 	}
		// 	return '';
		// }
	}

}
</script>

<style lang="stylus">
.preview-media
	display: block
	position: fixed
	width: 100%
	height: 100%
	top: 0
	right: 0
	z-index: 105

	.cover-back
		display: block
		width: 100%
		height: 100%
		position: fixed
		top: 0
		left: 0
		background-color: var(--modalBgColor)
		opacity: 0.8

	.modal
		display: block
		width: 70%
		height: 70%
		position: fixed
		top: 13%
		left: 15%
		background-color: var(--modalMainColor)
		box-shadow 0px 0px 10px var(--shadowColor)
		border-radius: 16px

		.content
			height: 100%

			.preview
				width: calc(100% - 269px)
				height: 100%
				float:left
				.img-ctn
					width: 100%
					height: 100%
					display: flex
					justify-content: center
					align-items: center
					.img
						width: 90%
						height: 90%
						object-fit: contain

			

input#urlToCopy
	border: 0
	margin: 5px
	background: var(--inspectorTargetColor)
	font-family: 'DinBold'
	color: var(--inputTextColor)
	font-size: 14px
	padding: 10px
	border-radius: 4px
	margin-top: 0px
	width: calc(100% - 30px)
	&:focus
		outline: none!important

.clickableField
	position: relative

.clickableMask
	position: absolute
	top: 0
	left: 5px
	height: calc(100% - 5px)
	width: calc(100% - 10px)
	border-radius: 4px
	background: var(--selectedColor)
	opacity: 0
	transition: linear all 0.2s !important
	text-align: center
	line-height: 40px
	font-family: 'DinBold'
	color: white

 .clickableMask:hover
	opacity: 1

.replaceFile
	label.input-file-trigger
		background: var(--saveColor)
		transition: linear all .1s !important
		border-radius: 40px
		&:hover
			background: var(--selectedSaveColor)

.vc-chrome-color-wrap .vc-checkerboard
	width: 28px !important
	height: 28px !important

.vc-chrome-active-color
	width: 26px !important
	height: 26px !important
	border-radius: 35px !important
	border: 1px solid var(--inputTextColor) !important

.vc-chrome-body
	background: var(--bgColor) !important

.vc-chrome-hue-wrap .vc-hue
	border-radius: 35px !important

.vc-alpha-checkboard-wrap
	border-radius: 35px !important

.vc-chrome-fields-wrap
	padding-top: 0px !important

.vc-chrome-toggle-btn
	width: 15px !important

.vc-input__input
	line-height: 29px !important
	color: var(--inputTextColor) !important
	font-family: 'DinBold' !important
	box-shadow: none !important
	background-color: var(--menuElementColor) !important
	border-radius: 40px  !important

.vc-chrome-toggle-icon
	margin-top: 8px !important

.vc-chrome-toggle-icon-highlight
	background: none !important

.vc-chrome-fields .vc-input__label
	color: var(--inputTextColor) !important
	font-family: 'DinBold' !important
	margin-top: 0px !important

</style>
