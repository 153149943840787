import ActionAbstract from '../ActionAbstract';

class ToggleObjInspector extends ActionAbstract {

    static get ID() {
        return 'actions:main:toggleObjInspector';
    }

    run(state) {
        return this.composeState(state, {
            main: {
                objInspectorOpen: this.toggle
            }
        });
    }

    toggle(state) {
        let forcedState = this.getParam('open');
        return forcedState !== undefined ? forcedState : !!!state;
    }

}

export default ToggleObjInspector;