var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion topic stateManager" }, [
    _c(
      "div",
      {
        staticClass: "accordion-title",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.topicAccordion = !_vm.topicAccordion
          }
        }
      },
      [
        _c("span", { staticClass: "text" }, [_vm._v("Event Manager")]),
        _c("span", {
          staticClass: "arrow",
          class: { close: !_vm.topicAccordion }
        })
      ]
    ),
    _c(
      "div",
      { staticClass: "form-content", class: { open: _vm.topicAccordion } },
      [
        _c("div", { staticClass: "children" }, [
          _c(
            "div",
            {
              staticClass: "form-accordion array-row-fields group isAccordion"
            },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.accordion = !_vm.accordion
                    }
                  }
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v("Event " + _vm._s(_vm.bloc.side))
                  ]),
                  _c("span", {
                    staticClass: "arrow",
                    class: { close: !_vm.accordion }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-accordion-content",
                  class: { open: _vm.accordion }
                },
                [
                  _c(
                    "div",
                    { staticClass: "big-bt field-group" },
                    [
                      _vm._l(_vm.states, function(state) {
                        return _c("div", { staticClass: "field-ctn state" }, [
                          _c("div", { staticClass: "field-wrapper" }, [
                            _c("span", { staticClass: "text" }, [
                              _vm._v(_vm._s(state))
                            ]),
                            _c("span", { staticClass: "trash" }, [
                              _c("div", {
                                staticClass: "action-bt bt-trash",
                                on: {
                                  click: function($event) {
                                    return _vm.removeState(state)
                                  }
                                }
                              })
                            ])
                          ]),
                          _c("div", { staticClass: "clear" })
                        ])
                      }),
                      _c("div", { staticClass: "clear" })
                    ],
                    2
                  ),
                  _c("div", { staticClass: "big-bt field-group adder" }, [
                    _c("div", { staticClass: "field-ctn" }, [
                      _c("div", { staticClass: "field-wrapper" }, [
                        _c(
                          "span",
                          { staticClass: "textinput-ctn newStateInput" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newState,
                                  expression: "newState"
                                }
                              ],
                              staticClass: "value newState",
                              attrs: {
                                type: "text",
                                placeholder: "New event name"
                              },
                              domProps: { value: _vm.newState },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.newState = $event.target.value
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "clear" })
                    ]),
                    _c("div", { staticClass: "clear" }),
                    _c(
                      "div",
                      {
                        staticClass: "big-bt bt-repeat-form",
                        class: { disabled: _vm.disabled },
                        on: { click: _vm.addState }
                      },
                      [
                        _c("span", { staticClass: "text" }, [_vm._v("Add")]),
                        _c("span", { staticClass: "bt-plus" }, [_vm._v("+")])
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }