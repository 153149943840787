/*
 * OpenLayers map: core component of OpenLayers engine.
 * https://openlayers.org/en/latest/apidoc/module-ol_Map-Map.html
 */

import React from 'react';
import PropTypes from 'prop-types';
import NodalUi from '../../NodalUi';

// @todo: Use the ol package from npm and remove this dist, retrieve the version from the package
// and review all the code below with ol modules.
import ol from '../../../../vendor/ol';

export default class MapOL extends NodalUi {
    constructor() {
        super();

        this.layers = []; // the map layers list
        this.icons = []; // the list containing all markers style
        this.mapMoving = false;

        this.state = {
            map: null,
            viewportUpdated: false,
            zoom: null,
            angle: null,
            latitude: null,
            longitude: null,
        };

        this.mapClickHandler = this.mapClickHandler.bind(this);
        this.mapDoubleClickHandler = this.mapDoubleClickHandler.bind(this);
        this.mapMovedHandler = this.mapMovedHandler.bind(this);


        // increase this counter on tileloadstart event, decrease in tileloadend
        // and tileloaderror event. When this countes is 0, the map is loaded.
        this.tilesCount = 0;

        this.onTileLoadStart = this.onTileLoadStart.bind(this);
        this.onTileLoadEnd = this.onTileLoadEnd.bind(this);
        this.onTileLoadError = this.onTileLoadError.bind(this);
        this.onImageLoadEnd = this.onImageLoadEnd.bind(this);
        this.onImageLoadError = this.onImageLoadError.bind(this);
    }


    createTexture(name, url) {
        if (this.icons[name]) return;

        // format URLs for cordova local assets
        if (url && window.cordovaConfig) {
            if (window.cordovaConfig.offline === true || window.cordovaConfig.local === true) {
                url = url.replace(/^.*\/upload\//gi, './media/');
            }
        }

        // sprite
        // deprecated: use 2D Sprite object instead
        // name_sprite-fps-col-row-w-h[-in-out]
        const match = /.*_sprite-([\d|\.]+-\d+-\d+-\d+-\d+.*)/.exec(name);
        if (match && match[1]) {
            const gif = new Image();
            const cvs = document.createElement('canvas');
            const ctx = cvs.getContext('2d');

            let [fps, col, row, w, h, i, o] = match[1].split('-');
            if (!i) i = 0;
            if (!o) o = col * row;

            console.warn(`Deprecated: use sprite protocol in map texture for '${name}' [fps:${fps}, col:${col}, row:${row}, width:${w}, height:${h}, in:${i}, out:${o}),\nuse 2D Sprite object instead\n`);

            cvs.width = w;
            cvs.height = h;

            const me = this;
            gif.onload = function () {
                let index = i;
                const sprite = () => {
                    const x = index % col;
                    const y = Math.floor(index / col);

                    if (++index >= o) index = i;

                    ctx.clearRect(0, 0, w, h);
                    ctx.drawImage(this, x * w, y * h, w, h, 0, 0, w, h);

                    me.state.map.render();
                };

                sprite();
                setInterval(sprite, fps);
            };

            // important: set the image cross origin for all user interactions on the marker
            // @todo: for all images not served by CORS, create a php script on the asset server to
            // redirect the request and allow 'Access-Control-Allow-Origin' in headers.
            gif.crossOrigin = 'anonymous';

            // @todo on map created or loaded
            setTimeout(() => { gif.src = url; }, 1000);

            this.icons[name] = new ol.style.Style({
                image: new ol.style.Icon({
                    anchor: [0.5, 0.5],
                    crossOrigin: 'anonymous',
                    imgSize: [cvs.width, cvs.height],
                    img: cvs,
                }),
            });

            return;
        }

        // default
        this.icons[name] = new ol.style.Style({
            image: new ol.style.Icon({
                anchor: [0.5, 0.5],
                src: url,
            }),
        });
    }

    loadTextures() {
        if (this.props.textures && Array.isArray(this.props.textures) && this.props.textures.length > 0) {
            this.props.textures.forEach((texture) => {
                if (texture[1].value) this.createTexture(texture[0].value, texture[1].value);
                else console.warn(`Missing asset reference for the texture '${texture[0].value}'`);
            });
        }
    }


    componentDidMount() {
        this.loadTextures();

        let projection = null;
        let center = null;

        // default
        if (this.props.mapBackground === 'osm') {
            let styleURL = null;
            if (this.props.map.mapStyle) {
                // auto format mapbox URL
                if (this.props.map.mapStyle.search('api.mapbox.com') !== -1 && this.props.map.mapStyle.search('.html?') !== -1) {
                    styleURL = this.props.map.mapStyle.replace('.html?fresh=true&title=true&', '/tiles/256/{z}/{x}/{y}?').replace(/#.*/, '');
                } else styleURL = this.props.map.mapStyle;
                // @todo: to be set in verbose mode [ticket NS-113]
                // console.log('using map style URL:', styleURL);
            }

            // apply user style or use the default OSM style
            const tilesrc = styleURL ? new ol.source.XYZ({ url: styleURL }) : new ol.source.OSM();
            this.layers.splice(0, 0, new ol.layer.Tile({
                source: tilesrc,
                visible: this.props.tile.tileVisibility !== 'undefined' ? this.props.tile.tileVisibility : true,
                opacity: this.props.tile.tileOpacity || 1,
            }));

            // handle the tile load events to trigger map loaded
            tilesrc.on('tileloadstart', this.onTileLoadStart);
            tilesrc.on('tileloadend', this.onTileLoadEnd);
            tilesrc.on('tileloaderror', this.onTileLoadError);

            center = ol.proj.fromLonLat([
                this.props.viewport.longitude,
                this.props.viewport.latitude,
            ]);
        }

        // static image
        if (this.props.mapBackground === 'image') {
            if (!this.props.projection.projectionStartPoint)
                throw new Error('Failed to create OpenLayers map: missing the projection starting point.');
            if (!this.props.projection.projectionEndPoint)
                throw new Error('Failed to create OpenLayers map: missing the projection ending point.');

            const extent = [
                this.props.projection.projectionStartPoint.x,
                this.props.projection.projectionStartPoint.y,
                this.props.projection.projectionEndPoint.x,
                this.props.projection.projectionEndPoint.y,
            ];

            projection = new ol.proj.Projection({
                units: this.props.projection.projectionUnit,
                extent,
            });

            const imagesrc = new ol.source.ImageStatic({
                url: this.props.mapBackgroundImage__url,
                imageExtent: extent,
                projection,
            });

            this.layers.splice(0, 0, new ol.layer.Image({
                source: imagesrc
            }));

            // handle static image load events
            // imagesrc.on('imageloadstart', this.onImageLoadStart); // ?
            imagesrc.on('imageloadend', this.onImageLoadEnd);
            imagesrc.on('imageloaderror', this.onImageLoadError);

            center = ol.extent.getCenter(extent);
        }

        // create map object with feature layer
        const extendsInterations = [];
        const map = new ol.Map({
            pixelRatio: this.props.map.pixelRatio || 1,
            controls: null,
            target: this.refs.mapContainer,
            layers: this.layers,
            view: new ol.View({
                projection,
                center,
                zoom: this.props.viewport.zoom,
                rotation: this.props.viewport.angle * (Math.PI / 180), // to radian
                maxZoom: this.props.viewport.maxZoom,
                minZoom: this.props.viewport.minZoom,
            }),
            interactions: ol.interaction.defaults({
                doubleClickZoom: this.props.mapcontrol.doubleClickZoom,
                dragPan: this.props.mapcontrol.dragPan,
                mouseWheelZoom: this.props.mapcontrol.scrollZoom,
                altShiftDragRotate: this.props.mapcontrol.allowRotation,
                pinchRotate: this.props.mapcontrol.allowRotation,
            }).extend(extendsInterations),
        });

        if (!map) throw new Error('Failed to create OpenLayers map.');

        map.on('singleclick', this.mapClickHandler);
        map.on('dblclick', this.mapDoubleClickHandler);
        map.on('moveend', this.mapMovedHandler);

        // save map and layer references to local state
        this.props.mapRefCallback(map);
        this.setState({ map, zoom: this.props.viewport.zoom });
    }

    componentWillUnmount() {
        if (!this.state.map) return;
        this.state.map.removeEventListener('singleclick');
        this.state.map.removeEventListener('dblclick');
        this.state.map.removeEventListener('moveend');
    }

    componentWillUpdate(nextProps, nextState) {
        super.componentWillUpdate(nextProps, nextState);

        if (nextState.map) {
            const isOSM = nextProps.mapBackground === 'osm';

            // update the background tiles layer if needed
            // (i.e. the first layers)
            // @todo: should be called backgroundVisibility and backgroundOpacity to be used with 
            // any background layer
            if (nextProps.tile && isOSM) {
                const tileLayer = this.layers[0];
                const visible = nextProps.tile.tileVisibility;
                const opacity = nextProps.tile.tileOpacity;

                if (tileLayer.getVisible() !== visible) tileLayer.setVisible(visible);
                if (tileLayer.getOpacity() !== opacity) tileLayer.setOpacity(opacity);
            }

            // update navigation
            // @todo: add positon and margin options see ticket[NS_]
            const { displayNavigation } = nextProps.map;
            const controls = nextState.map.getControls().getArray();
            const zcontrol = controls.find((ctl) => ctl instanceof ol.control.Zoom);
            if (zcontrol && !displayNavigation) nextState.map.removeControl(zcontrol);
            if (!zcontrol && displayNavigation) nextState.map.addControl(new ol.control.Zoom());

            // update viewport
            if (nextProps.mergedViewport.viewportUpdated) {
                this.mapMoving = true;

                // convert angle to radian
                const angle = ('angle' in nextProps.mergedViewport) ? nextProps.mergedViewport.angle * (Math.PI / 180) : 0;

                // animate view
                if (nextProps.map.transitionDuration > 0) {
                    // @todo: to be set in verbose mode [ticket NS-113]
                    console.log('animate map')
                    nextState.map.getView().animate({
                        center: !isOSM ? null : ol.proj.fromLonLat([
                            nextProps.mergedViewport.longitude,
                            nextProps.mergedViewport.latitude,
                        ]),
                        zoom: nextProps.mergedViewport.zoom,
                        rotation: angle,
                        duration: nextProps.map.transitionDuration,
                        easing: ol.easing[nextProps.map.transitionEasing],
                    });
                    this.props.viewportAnimationStartedCallback();
                } else {
                    if (nextProps.mergedViewport.updated) {
                        const fields = nextProps.mergedViewport.updated;
                        // @todo: to be set in verbose mode [ticket NS-113]
                        console.log('update map', [...fields])
                        if (isOSM && fields.has('latitude') && fields.has('longitude')) {
                            nextState.map.getView().setCenter(ol.proj.fromLonLat([
                                nextProps.mergedViewport.longitude,
                                nextProps.mergedViewport.latitude,
                            ]));
                        }
                        if (fields.has('zoom')) nextState.map.getView().setZoom(nextProps.mergedViewport.zoom);
                        if (fields.has('angle')) nextState.map.getView().setRotation(angle);
                    }
                    // old method, should never happen (for compatibilities)
                    else {
                        if (isOSM) nextState.map.getView().setCenter(ol.proj.fromLonLat([
                            nextProps.mergedViewport.longitude,
                            nextProps.mergedViewport.latitude,
                        ]));
                        nextState.map.getView().setZoom(nextProps.mergedViewport.zoom);
                        nextState.map.getView().setRotation(angle);
                    }
                }
            }
        }
    }


    setDefaultStyles(nextProps) {
        const styles = {
            visibility: nextProps.visibility === true ? 'visible' : 'hidden',
            display: nextProps.visibility === true ? 'block' : 'none',
            pointerEvents: nextProps.lock === true ? 'none' : 'auto',
            width: nextProps.map.fullWidth ? '100%' : nextProps.viewport.width,
            height: nextProps.map.fullHeight ? '100%' : nextProps.viewport.height,
        };

        this.defaultStyle = { ...this.defaultStyle, ...styles };
    }


    /**
     * Triggered when user click on the map, with no dragging.
     *
     * @param {Object} e - The ol/MapBrowserEvent.
     */
    mapClickHandler(e) {
        const point = ol.proj.transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');
        this.props.positionClickedCallback({ lat: point[1], lng: point[0] });
    }

    /**
     * Triggered when user double click on the map, with no dragging.
     *
     * @param {Object} e - The ol/MapBrowserEvent.
     */
    mapDoubleClickHandler(e) {
        const point = ol.proj.transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');
        this.props.positionDoubleClickedCallback({ lat: point[1], lng: point[0] });
    }

    /**
     * Triggered when a pointer is moved.
     * Note that on touch devices this is triggered when the map is panned,
     * so is not the same as mousemove.
     *
     * @param {Object} e - The ol/MapBrowserEvent.
     */
    mapMovedHandler(e) {
        if (!this.state.map) return;
        let currentPos = this.state.map.getView().getCenter();
        currentPos = ol.proj.transform(currentPos, 'EPSG:3857', 'EPSG:4326');
        const viewportState = {
            zoom: this.state.map.getView().getZoom(),
            angle: this.state.map.getView().getRotation(),
            latitude: currentPos[1],
            longitude: currentPos[0],
        };

        if (this.mapMoving) {
            this.setState(viewportState);
            this.props.viewportUpdateCallback(viewportState);
            this.mapMoving = false;
        } else {
            this.props.viewportUpdateCallback(viewportState);
            this.mapMoving = false;
        }
    }

    /**
     * Triggered when a tile starts loading.
     *
     * @param {Object} e - The ol/source/Tile.TileSourceEvent.
     */
    onTileLoadStart() {
        this.tilesCount++;
    }

    /**
     * Triggered when a tile finishes loading, either when its data is loaded,
     * or when loading was aborted because the tile is no longer needed.
     *
     * @param {Object} e - The ol/source/Tile.TileSourceEvent.
     */
    onTileLoadEnd(e) {
        this.tilesCount--;
        if (this.tilesCount === 0) this.props.mapTileLoadedCallback(e); // map loaded
    }

    /**
     * Triggered if tile loading results in an error.
     *
     * @param {Object} e - The ol/source/Tile.TileSourceEvent.
     */
    onTileLoadError(e) {
        this.tilesCount--;
        if (this.tilesCount === 0) this.props.mapTileLoadedCallback(e); // map loaded
    }

    /**
     * Triggered when an image finishes loading.
     *
     * @param {Object} e - The ol/source/Image.ImageSourceEvent.
     */
    onImageLoadEnd(e) {
        this.props.mapImageLoadedCallback(e);
    }

    /**
     * Triggered if image loading results in an error.
     *
     * @param {Object} e - The ol/source/Image.ImageSourceEvent.
     */
    onImageLoadError(e) {
        this.props.mapImageErrorCallback(e);
    }


    render() {
        const controlClass = this.props.map.displayNavigation ? `control-${this.props.map.positionNavigation}` : 'control-none';

        const layers = this.state.map ? this.props.children : [];

        return this.wrapIntoAuthoring(
            <div id="map-ol" className={controlClass} ref="mapContainer" style={this.defaultStyle}>
                {layers}
            </div>,
            0,
        );
    }

    getChildContext() {
        return {
            map: this.state.map,
            mapComp: this,
        };
    }
}

MapOL.childContextTypes = {
    map: PropTypes.instanceOf(ol.Map),
    mapComp: PropTypes.instanceOf(MapOL),
};


// Story Trip marker
// @todo: think about a method to add custom marker texture
/* export const generateMarkerTexture = ( name, colors , size, opacity, star  ) => {

    let pixelRatio = window.devicePixelRatio;
    let width = size*2*pixelRatio;// 64;//(size*2)+2;
    let radius = (width-4)*.5;
    let center = width*.5;

    var canvas = document.createElement("canvas");
    canvas.width = canvas.height = width;
    let ctx = canvas.getContext('2d');

    ctx.globalAlpha = opacity;

    let arcsRadius = (2*Math.PI)/colors.length;
    let start = 0;

    let drawArcCircle = ( c, r, start, end, nbPoints ) => {
        let incr = (end-start)/nbPoints;
        let angle = start;
        for( let d = 0 ; d < nbPoints+1 ; d++ ){
            let x = c+r*Math.cos(angle);
            let y = c+r*Math.sin(angle);
            ctx.lineTo(x, y);
            angle += incr;
        }
    }

    let drawStar = (cx, cy, spikes, outerRadius, innerRadius, color) => {
        var rot = Math.PI / 2 * 3;
        var x = cx;
        var y = cy;
        var step = Math.PI / spikes;

        ctx.beginPath();
        ctx.moveTo(cx, cy - outerRadius)
        for ( let i = 0; i < spikes; i++) {
            x = cx + Math.cos(rot) * outerRadius;
            y = cy + Math.sin(rot) * outerRadius;
            ctx.lineTo(x, y)
            rot += step

            x = cx + Math.cos(rot) * innerRadius;
            y = cy + Math.sin(rot) * innerRadius;
            ctx.lineTo(x, y)
            rot += step
        }
        ctx.lineTo(cx, cy - outerRadius)
        ctx.closePath();
        ctx.fillStyle=color;
        ctx.fill();

    }


    for( let i = 0 ; i < colors.length ; i++ ){
        ctx.fillStyle = colors[i];
        ctx.beginPath();
        ctx.moveTo(center, center);
        //ctx.arc(center,center,radius,start,start+arcsRadius, true); // Outer circle
        drawArcCircle( center, radius, start, (start+arcsRadius) , 20 );

        ctx.lineTo(center, center);
        ctx.closePath();
        ctx.fill();
        start += arcsRadius;
    }

    ctx.globalAlpha = 1;
    ctx.fillStyle = '#FFFFFF';
    ctx.beginPath();
    drawArcCircle( center, radius-(6*pixelRatio), 0, 2*Math.PI , 100 );
    ctx.closePath();
    ctx.fill();

    if( star !== null && star !== undefined ){
        let inner = Math.min( star.radius.x, star.radius.y )*pixelRatio;
        let outer = Math.max( star.radius.x, star.radius.y )*pixelRatio;
        drawStar( center, center, star.pikes, outer, inner, star.color);
    }

    // ctx.getImageData(0,0,width,width).data;
    return { name: name, canvas: canvas , width: width, height: width };
} */
