import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class ConfirmModal extends ActionAbstract {

    static get ID() {
        return 'actions:main:modal:confirmModal';
    }

    run(state) {
    
        if( state.modal.nextAction )
            this.trigger( state.modal.nextAction, Object.assign( {} , state.modal.nextActionParam, this.params ) );
            
        this.trigger( 'main:modal:closeModal' );
        
        return false;
    }

}

export default ConfirmModal;