import { default as helperBlock } from './block';
import { default as helperUser } from './user';


const project = {
    getPlayerUrl(state, xpId, screenId = null) {
        let screen = null;
        if (screenId !== null) {
            screen = helperBlock.getBlockById( state.project, screenId);
            if ( screen.value.type !== 'screen' ){
                throw new Error("invalid screen given");
            }
        }

        let xp = helperBlock.getBlockById( state.project, xpId);
        // FIXME: 'note' type
        if (xp.value.type === 'note') {
            console.warn("Post-It type actually not supported");
            return;
        }
        if(xp.value.type){
            if(xp.value.type !== 'experience'){
                throw new Error(`invalid given experience: xp type is '${xp.value.type}' instead of 'experience'`);
            }
        }

        let owner = this.getOwner( state , state.project.value._id );
        let ownerFormated = helperUser.formatUserName( owner );
    
        let url= ownerFormated +'/'+ state.project.value.slug+'/'+xp.value.slug;
        if( screen ){
            url += '/' + screen.value.slug;
        }

        return url;
    },

    getOwner( state, projectId ){
        let owner = null;
        if( state.projects && state.projects.list ){
            state.projects.list.forEach( project => {
                if( project && project.value && project.value.project == projectId ){
                    owner = project.value.owner;
                }
            });
        } 
        return owner;
    }    
}

export default project;