<!-- Number vue -->

<template lang="pug">

span.textinput-ctn.has-unit
	input.value(type="text", v-model="value", v-bind:placeholder="placeholder", v-on:focusout="focusOutField(field)", :disabled="field.disabled")
	span.unit(v-bind:class="{'disabled': field.disabled }") °
	
</template>

<script>

export default {


	components: { },

	props:[ 'field' , 'fieldvalue' , 'placeholder', 'dynlabel' ],

	data() {
		return {
			value: null
		}
	},

	created: function(){
		this.value = this.fieldvalue ? this.fieldvalue.value : 0;
	},

	watch: {
		fieldvalue: function (obj) {
			this.value = obj ? obj.value : 0;
		}
	},

	methods:{

		focusOutField: function(fieldUpdated){
			let floatVal = parseFloat(this.value);
			if( this.value === "" || floatVal === NaN || floatVal === 'NaN' || this.value === null )
				this.value = null;
			else
				this.value = floatVal;

			if (this.fieldvalue.value !== this.value) {
				this.valueUpdated(fieldUpdated);
			}

		},

		valueUpdated: function( fieldUpdated ){
			this.$parent.valueUpdated( fieldUpdated, this.value );
		}


	}

}

</script>

<style lang="stylus">
</style>