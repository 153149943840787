import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class DeselectAll extends ActionAbstract {

    static get ID() {
        return 'actions:main:deselectAll';
    }

    run(state) {
        
        this.trigger('editor:menu:toggleMenu', {status: false});
        this.trigger('main:toggleInspector', {open: false});
        this.trigger('editor:schematic:drawLink', {subaction: 'cancel' });


        return this.composeState(state, {
            editor: {
                selection: {
                    items: [],
                }
            }
        });
    }


}

export default DeselectAll;