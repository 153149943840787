import React from 'react';
import Hammer from 'react-hammerjs';

export default class AuthoringOverlay extends React.Component {

    constructor(props){
        super();

        const positionning = { position : "absolute", top : 0,  left : 0, right: 0, bottom : 0};

        this.ctnStyle = Object.assign({}, positionning);

        const { isDeprecated } = props;

        this.overlayStyle = Object.assign({
            pointerEvents: 'none',
            borderColor: '#f39a74',
            backgroundColor: !isDeprecated ? 'rgba(243,154,116,0.5)' : '',
            backgroundImage: isDeprecated ? 'url(../../../assets/textureDeprecated.png)' : '',
            backgroundSize: isDeprecated ? '50px 30px' : '',
            borderWidth: 2,
            borderStyle: 'solid',
        }, positionning);


        this.styleOut = Object.assign({
            opacity: isDeprecated ? 1 : 0,
        }, this.overlayStyle);

        this.styleOver = Object.assign({
            opacity: 1,
        }, this.overlayStyle);


        this.state = {
            currentStyle : this.styleOut,
            mouseDown : false,
            mouseMoved : false
        };

        this._mouseOverHandler = this.mouseOverHandler.bind(this);
        this._mouseOutHandler = this.mouseOutHandler.bind(this);
        this._onMouseDown = this.onMouseDown.bind(this);
        this._onMouseUp = this.onMouseUp.bind(this);
        this._onMouseMove = this.onMouseMove.bind(this);
        this._tapHandler = this.tapHandler.bind(this);
    }

    mouseOverHandler(){
        //this.state.currentStyle =  this.styleOver;
        this.setState( { currentStyle : this.styleOver} );

        if( this.props.nbSection > 0 )
            this.props.callback( { state: 'over', id : this.props._id, section : this.props.idSection || 0 } );
    }

    mouseOutHandler(){
        //this.state.currentStyle =  this.styleOut;
        this.setState( { currentStyle : this.styleOut} );
    }

    tapHandler(){
        this.props.callback( { state: 'select', id : this.props._id, section : this.props.idSection || 0 } );
    }

    onMouseDown( evt ){
        evt.preventDefault();
        evt.stopPropagation();
        this.setState( { mouseDown : true, mouseMoved: false } );
        this.props.callback( { state: 'stop-drag', id : this.props._id, section : this.props.idSection || 0 } );
    }

    onMouseUp(evt){
        evt.preventDefault();
        this.props.callback( { state: 'stop-drag', id : this.props._id, section : this.props.idSection || 0 } );
        this.setState( { mouseDown : false, mouseMoved: false } );
    }

    onMouseMove( evt ){
        evt.preventDefault();
        evt.persist();

        if( this.state.mouseDown === true && this.state.mouseMoved === true ){
            this.props.callback( { state: 'start-drag', id : this.props._id, section : this.props.idSection || 0 , position : { x: evt.clientX , y: evt.clientY } } );
        }
        this.props.callback( { state: 'move-drag',  id : this.props._id, position : { x: evt.clientX , y: evt.clientY } } );


        this.setState( { mouseMoved: true } );
    }

    render() {

        let otherProps = {
            className : "auth-overlay"
        };

        let rollOver = {
            onMouseOver: this._mouseOverHandler,
            onMouseOut : this._mouseOutHandler
        };

        let dragEvents = {
            onMouseDown : this._onMouseDown,
            onMouseUp : this._onMouseUp
        }

        if( this.state.mouseDown || this.props.dragging )
            dragEvents.onMouseMove = this._onMouseMove ;

        if( this.props.nbSection == 0 && this.props.dragging )
            rollOver = null;

        let style = this.state.currentStyle;
        if( this.props.selected && this.props.selected == this.props._id ){
            style = this.styleOver;
        }

        this.ctnStyle = Object.assign( { zIndex: this.props.nestedLevel+1 }, this.ctnStyle );

        return (
            <Hammer onTap={this._tapHandler} >
                <div {...otherProps} {...rollOver} {...dragEvents} style={this.ctnStyle}>
                    <div style={style}></div>
                </div>
            </Hammer>
        );
    }

}
