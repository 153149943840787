import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class InspectorActionAbstract extends ActionAbstract {

    beforeCreate() {
        super.beforeCreate();

        this._target = null;
        this._path = null;
    }

    /**
     * @param targetId
     * @param type
     */
    preRun(state) {

        [this._target, this._path] = helper.block.getEntityByIDInWholeProject(state.project, this.getParam('targetId'), true);

        if( this._target.value.type === 'object' ) {
            // @todoUI
            this._ui = this.deps.mainStore.ui.get( this.getParam('targetId') );
        }

    }


}

export default InspectorActionAbstract;