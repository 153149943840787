import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class OpenModal extends ActionAbstract {

    static get ID() {
        return 'actions:main:modal:openModal';
    }

    run(state) {

        let checkButton = (butt, text) => {
            let button = this.getParam(`${butt}`)

            if (!button) return text
            else if (button == "none") return false
            else return button
        }

        return this.composeState( state, {
            modal : {
                open: true,
                icon: this.getParam('icon') !== undefined ? this.getParam('icon') : "DOC",
                title: this.getParam('title'),
                blocIsMemory: this.getParam('blocIsMemory'),
                type: this.getParam('type'),
                isUsedBy: this.getParam('isUsedBy') ? this.usageInfo(this.getParam('isUsedBy'), state) : null,
                text: this.getParam('text'),
                isBuildOnly: this.getParam('isBuildOnly'),
                share: this.getParam('share') !== undefined ? this.getParam('share') : false,
                download: this.getParam('download'),
                sharingError: '',
                duplicate: this.getParam('duplicate') !== undefined ? this.getParam('duplicate') : false,
                duplicateError: '',

                projectInfo: this.getParam('projectInfo') !== undefined ? this.getParam('projectInfo') : false,
                hasInput: this.getParam('hasInput'),
                inputName: this.getParam('inputName'),

                nextAction: this.getParam('nextAction'),
                nextActionParam: this.getParam('nextActionParam'),

                cancelButton: checkButton("cancelButton","Cancel"),//this.getParam('cancelButton') !== undefined ? this.getParam('cancelButton') : "Cancel",
                continueButton: checkButton("continueButton","Continue"),//this.getParam('continueButton') !== undefined  ? this.getParam('continueButton') : "Continue"
            }
        });

    }

    usageInfo(isUsedBy) {  
        let message = [];
        for (let i = 0; i < isUsedBy.length; i++) {
            let parentId = isUsedBy[i].parentID
            let parentName;
            let parentType;
            let parentBloc = helper.block.getEntityByIDInWholeProject(this.getParam('project'), parentId);
            let blocName = helper.config.getConfigByType(this.getParam('config'), isUsedBy[i].blocType).value.name;
            if(parentBloc) {
                parentName = parentBloc.value.name
                parentType = parentBloc.value.type
            }
            let t = `${blocName} - ${isUsedBy[i].blocName} in ${parentType} ${parentName}`
            message.push(t)
        }
        return message
    }
}

export default OpenModal;
