var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion targets-bloc" }, [
    _c(
      "div",
      {
        staticClass: "accordion-title",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.accordion = !_vm.accordion
          }
        }
      },
      [
        _c("span", { staticClass: "text" }, [_vm._v("Training Set")]),
        _c("span", { staticClass: "arrow", class: { close: !_vm.accordion } })
      ]
    ),
    _c("div", { staticClass: "form-content", class: { open: _vm.accordion } }, [
      _c("div", { staticClass: "form-accordion selectSet isAccordion" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "text" }, [_vm._v("Training Set list")]),
          _c(
            "span",
            {
              staticClass: "description-bull",
              on: { click: _vm.displayDescription }
            },
            [_vm._v("?")]
          )
        ]),
        _c("div", { staticClass: "big-bt field-group" }, [
          _c("span", { staticClass: "textinput-ctn fullsize" }, [
            _c("span", { staticClass: "select-arrow" }, [_vm._v(">")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedTrainSet,
                    expression: "selectedTrainSet"
                  }
                ],
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedTrainSet = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.valueUpdated
                  ]
                }
              },
              [
                _c("option", { domProps: { value: 0 } }, [
                  _vm._v("Select a training set")
                ]),
                _vm._l(_vm.trainingSets, function(train, i) {
                  return _c("option", { domProps: { value: train._id } }, [
                    _vm._v(_vm._s(train.name))
                  ])
                })
              ],
              2
            )
          ]),
          _c("div", { staticClass: "clear" }),
          _vm.target &&
          _vm.target.value &&
          _vm.target.value.type !== "gesture-recognizer"
            ? _c(
                "div",
                {
                  staticClass: "big-bt bt-repeat-form",
                  on: { click: _vm.addTrain }
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v("Create a new Training set")
                  ]),
                  _c("span", { staticClass: "bt-plus" }, [_vm._v("+")])
                ]
              )
            : _vm._e()
        ])
      ])
    ]),
    _c("div", { staticClass: "clear", staticStyle: { height: "10px" } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }