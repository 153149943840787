<!-- Trigger vue -->

<template lang="pug">
div
	div.accordion.memory-bloc
		div.accordion-title(v-on:click.stop="accordionEvent = !accordionEvent ")
			span.text Event
			span.arrow(v-bind:class="{'close':!accordionEvent}")

		div.form-content(v-bind:class="{'open':accordionEvent}")

			trigger-field(v-for="(event,i) of target.value.events", :index="i", :condition="event", :target="target", :project="project")

			div.big-bt.memory-bt(v-on:click="addEvent", v-if="target.value.events.length == 0")
				span.text Add an event
				span.bt-plus +



	div.accordion.memory-bloc
		div.accordion-title(v-on:click.stop="accordionStates = !accordionStates ")
			span.text States
			span.arrow(v-bind:class="{'close':!accordionStates}")

		div.form-content(v-bind:class="{'open':accordionStates}")

			trigger-field(v-for="(state,i) of target.value.trigger_states", :index="i", :condition="state", :target="target", :project="project")

			div.big-bt.memory-bt(v-on:click="addState")
				span.text Add new state
				span.bt-plus +

			div.clear(style="height:10px")
</template>

<script>

import actionManager from '../../../actions';
import TriggerField from './TriggerField.vue';

export default {

	components: { TriggerField  },

	props:[ 'target', 'project' ],

	data() {
		return {
			accordionEvent: true,
			accordionStates: true,
		}
	},

	created: function(){

		// if( !this.target.value.fields ){
		// 	this.target.value.fields = [];
		// }

	},


	methods:{

		addEvent: function(){
			if( this.target.value.events.length > 0 )
				return;
						
			// this.target.value.events.push({ type:"event", title:"Event", params: { }, fields: [] });
			actionManager.trigger('inspector:trigger:addCondition', { subaction: 'event', targetId: this.target.value._id })
		},

		addState: function(){
			actionManager.trigger('inspector:trigger:addCondition', { subaction: 'state', targetId: this.target.value._id })
		}

	}

}

</script>

