import AdvancedUiObject from '../AdvancedUiObject';

class UiVideo extends AdvancedUiObject {
    constructor(id, props) {
        super(id, props, 'Video');
    }

    static getAuthConfig() {
        return {
            exportFormat: 'ui-video',
            label: 'Video Player',
            parent: 'ui-elements',
            isDisplayed: true,
            isEmbeddable: true,
        };
    }

    initParameters() {
        super.initParameters();

        const videoParams = this.createInspectorNode('group', 'video-params', 'Parameters');
        videoParams.children.push(this.createInspectorNode('group-field', 'group-field-params'));

        const actionsGroup = this.createInspectorNode('group', 'control-actions', 'Actions');
        actionsGroup.children.push(this.createInspectorNode('group-field', 'group-field-actions'));

        const dataGroup = this.createInspectorNode('group', 'video-data', 'Data');
        dataGroup.children.push(this.createInspectorNode('group-field', 'group-field-data'));

        const mediaGroup = this.createInspectorNode('group', 'video-properties', 'Media properties');
        mediaGroup.children.push(this.createInspectorNode('group-field', 'group-field-props'));

        const eventsGroup = this.createInspectorNode('group', 'video-events', 'Events');
        eventsGroup.children.push(this.createInspectorNode('group-field', 'group-field-events'));

        const videoTopic = this.createInspectorNode('topic', 'settings', 'Video Settings');
        videoTopic.children = [
            videoParams, actionsGroup, dataGroup, mediaGroup, eventsGroup,
        ];

        this.inspector.unshift(videoTopic);

        const otherParameters = {
            // settings
            mode: {
                type: 'String',
                connection: null,
                default: 'file',
                auth: {
                    label: 'Mode',
                    container: 'settings',
                    widget: 'select',
                    options: [
                        { label: 'File', value: 'file' },
                        { label: 'Stream', value: 'stream' },
                    ],
                    description: 'Selection of the mode for the video player: ‘File’ to use a media file as source, or ‘Stream’ to use the camera input as source.',
                },
            },
            autoplay: {
                type: 'Boolean',
                default: false,
                connection: null,
                auth: {
                    container: 'settings',
                    label: 'Autoplay',
                    description: 'Specifies whether the video may be played automatically or not.',
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                },
            },
            displayControls: {
                type: 'Boolean',
                default: false,
                connection: null,
                auth: {
                    container: 'settings',
                    label: 'Display Controls',
                    description: 'Specifies whether the video controls must be displayed or not.',
                },
            },

            // parameters
            source: {
                type: 'Asset',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false, disabled: false },
                },
                auth: {
                    container: 'group-field-params',
                    label: 'Video File',
                    widget: 'asset',
                    assetType: 'video',
                    description: 'Sets the source of the video.',
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                },
            },
            streamSource: {
                type: 'Mixed',
                connection: {
                    in: { pluggable: true, default: true },
                    out: { pluggable: true, default: false, disabled: false },
                },
                auth: {
                    container: 'group-field-params',
                    label: 'Video Stream',
                    widget: 'calculated',
                    description: 'Sets the stream of the video.',
                    conditions: [{ field: 'mode', operator: '==', value: 'stream' }],
                },
            },
            poster: {
                type: 'Asset',
                default: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false, disabled: false },
                },
                auth: {
                    container: 'group-field-params',
                    label: 'Poster',
                    assetType: 'image',
                    description: 'Sets the poster of the video.',
                },
            },
            loop: {
                type: 'Boolean',
                default: false,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false, disabled: false },
                },
                auth: {
                    container: 'group-field-params',
                    label: 'Loop',
                    description: 'Specifies whether the video may be looped or not.',
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                },
            },
            volume: {
                type: 'Float',
                default: 1.0,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    container: 'group-field-params',
                    label: 'Volume',
                    description: 'Sets the volume at which the media will be played (between 0 and 1), where 0 is effectively muted and 1 is the loudest possible value.',
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                },
            },

            // actions
            play: {
                type: 'Mixed',
                callable: true,
                deprecated: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false, disabled: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    container: 'group-field-actions',
                    label: 'Play',
                    description: 'When activated, plays the video.',
                },
            },
            start: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: true },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    container: 'group-field-actions',
                    label: 'Start',
                    description: 'When activated, go back to the beginning and starts playing the video.',
                },
            },
            stop: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: true },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    container: 'group-field-actions',
                    label: 'Stop',
                    description: 'When activated, stops the video.',
                },
            },
            pause: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    container: 'group-field-actions',
                    label: 'Pause',
                    description: 'When activated, pauses the video.',
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                },
            },
            resume: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    container: 'group-field-actions',
                    label: 'Resume',
                    description: 'When activated, resumes the video at the position it was paused.',
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                },
            },
            capture: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    container: 'group-field-actions',
                    label: 'Capture',
                    description: 'When activated, captures the video.',
                },
            },

            // IO
            captureToDataURL: {
                type: 'String',
                default: false,
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    deprecated: true,
                    container: 'group-field-data',
                    label: 'Capture To URL',
                    widget: 'calculated',
                    description: 'Outputs the URL of the video data when a capture is done.',
                },
            },
            captureToBlob: {
                type: 'Blob',
                default: false,
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    container: 'group-field-data',
                    label: 'Capture To File',
                    widget: 'calculated',
                    description: 'Outputs the file of the video data when a capture is done.',
                },
            },

            // medi properties
            mediaWidth: {
                type: 'Int',
                unit: 'px',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    container: 'group-field-props',
                    label: 'Media Width',
                    widget: 'calculated',
                    description: 'Outputs the width of the media in pixels.',
                },
            },
            mediaHeight: {
                type: 'Int',
                unit: 'px',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    container: 'group-field-props',
                    label: 'Media Height',
                    widget: 'calculated',
                    description: 'Outputs the height of the media in pixels.',
                },
            },
            duration: {
                type: 'Int',
                unit: 's',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    container: 'group-field-props',
                    label: 'Media Duration',
                    widget: 'calculated',
                    description: 'Outputs the length of the media in seconds.',
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                },
            },

            // events
            loaded: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    container: 'group-field-events',
                    label: 'Loaded',
                    widget: 'calculated',
                    description: 'Outputs when the frame at the current playback position of the media has finished loading.',
                    conditions: [{ field: 'mode', operator: '==', value: 'file' }],
                },
            },
            played: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    widget: 'calculated',
                    container: 'group-field-events',
                    label: 'Played',
                    description: 'Outputs when the video has started to play.',
                },
            },
            paused: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    widget: 'calculated',
                    container: 'group-field-events',
                    label: 'Paused',
                    description: 'Outputs when the video playback has been paused.',
                },
            },
            ended: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    container: 'group-field-events',
                    label: 'Ended',
                    widget: 'calculated',
                    description: 'Outputs when the video is ended.',
                },
            },
            error: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    container: 'group-field-events',
                    label: 'Error',
                    widget: 'calculated',
                    description: 'Outputs a message when an error occurred.',
                },
            },
        };

        this.addToParameters(otherParameters);

        this.setParamInvisible('addChild');
        this.setParamInvisible('removeChild');

        this.setDefaultValue('width', '100%');
    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(),
    // or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }

    init() {
        super.init();

        this.videoNode = null;
        this.canvasNode = null;
        this.canvas = document.createElement('canvas');

        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.pause = this.pause.bind(this);
        this.resume = this.resume.bind(this);
        this.capture = this.capture.bind(this);

        this.set('setVideoNode', this.setVideoNode.bind(this));
        this.set('setCanvasNode', this.setCanvasNode.bind(this));
        this.set('onControls', this.onControls.bind(this));
        this.set('onError', this.onError.bind(this));

        this.mode = this.get('mode');
        this.previousAction = null;

        // using Cordova
        this.isCordova = !!window.cordovaConfig && window.plugin && window.plugin.CanvasCamera;

        // play if needed
        if (this.get('autoplay')) this.start();
    }

    /**
     * Attach all trigger field listener to this object.
     *
     * @param {Object} module - The parent module
     * @override
     */
    registerTriggerListeners(module) {
        module.addInputListener('start', this.start);
        module.addInputListener('stop', this.stop);
        module.addInputListener('pause', this.pause);
        module.addInputListener('resume', this.resume);
        module.addInputListener('capture', this.capture);
    }

    /**
     * Detach all trigger field listener from this object.
     *
     * @param {Object} module - The parent module
     * @override
     */
    unregisterTriggerListeners(module) {
        module.removeInputListener('start', this.start);
        module.removeInputListener('stop', this.stop);
        module.removeInputListener('pause', this.pause);
        module.removeInputListener('resume', this.resume);
        module.removeInputListener('capture', this.capture);
    }

    /**
     * Register the video element.
     * @param {Object} node - The video DOM node
     */
    setVideoNode(node) {
        this.videoNode = node;
    }

    /**
     * Register the canvas element.
     * @param {Object} node - The canvas DOM node
     */
    setCanvasNode(node) {
        this.canvasNode = node;
        if (this.isCordova) {
            if (this.canvasNode) window.plugin.CanvasCamera.initialize(this.canvasNode);
            else console.warn('Cannot initialise CanvasCamera plugin without a proper canvas');
        }
    }

    /**
     * @deprecated
     */
    play() {
        this.videoNode.play();
    }

    start() {
        const source = this.get(this.mode === 'file' ? 'source' : 'streamSource');
        if (!source) {
            this.onError('Source is missing.');
            return;
        }
        this.updatePlayback('start');
    }

    stop() {
        this.updatePlayback('stop');
    }

    pause() {
        this.updatePlayback('pause');
    }

    resume() {
        this.updatePlayback('resume');
    }

    /**
     * Movie file or stream playback manager.
     * @param {String} action - the user action
     */
    updatePlayback(action) {
        if (action !== 'start' && this.previousAction === action) return;
        if (this.videoNode == null) {
            // avoid missing element on Cordova reload
            if (!(this.isCordova && this.mode === 'stream')) {
                this.onError('Playback error: missing the video element.');
                return;
            }
        }

        let executed = false;

        // playback management for file
        //  - start, always to enable start and restart
        //  - stop, always if already started and previous action is not  'stop'
        //   -pause, if already started and previous action is not  'pause'
        //  - resume, if already started and previous action is 'pause'
        switch (action) {
        case 'start':
            // eslint-disable-next-line no-case-declarations
            const nuance = this.previousAction && this.previousAction !== 'stop' ? 'restarted' : 'started';
            if (this.mode === 'file') this.videoNode.currentTime = 0;
            if (this.mode === 'stream' && this.isCordova) {
                const stream = this.get('streamSource');
                const source = stream.source === 'user' ? 'front' : 'back';
                // @todo: to be set in verbose mode [ticket NS-113]
                console.log(`Video player: init stream with:
                    \tcamera source ... ${source}
                    \twidth ........... ${stream.width}
                    \theight .......... ${stream.height}
                    \tframe rate ...... ${stream.fps}`
                );

                window.plugin.CanvasCamera.start(
                    {
                        use: 'data',
                        cameraFacing: source,
                        width: stream.height,
                        height: stream.width,
                        fps: stream.fps,
                    },
                    (err) => this.onError(err),
                );
            }
            else {
                this.videoNode.play()
                    .then(() => this.fireOutput('start', `Playback or streaming has been successfully ${nuance}.`))
                    .catch((error) => this.onError(`Playback or streaming error: ${error}`));
            }
            executed = true;
            break;
        case 'pause':
            if (this.previousAction && this.previousAction !== 'stop') {
                this.videoNode.pause();
                this.fireOutput('pause', 'Playback has been successfully paused.');
                executed = true;
            }
            break;
        case 'resume':
            if (this.previousAction && this.previousAction === 'pause') {
                this.videoNode.play()
                    .then(() => this.fireOutput('resume', 'Playback has been successfully resumed.'))
                    .catch((error) => this.onError(`Playback error: ${error}`));
                executed = true;
            }
            break;
        case 'stop':
            if (this.previousAction) {
                if (this.mode === 'file') {
                    this.videoNode.pause();
                    this.videoNode.currentTime = 0;
                }
                this.fireOutput('stop', 'Playback or streaming has been successfully stopped.');
                executed = true;
            }
            break;
        }

        // register action and send to componant
        if (executed) {
            this.previousAction = action;
            // @todo: to be set in verbose mode [ticket NS-113]
            //console.log('Video player:', this.previousAction);
        }
    }

    /**
     * Capture the current frame and send the file and data to the corresponding outputs.
     */
    capture() {
        const source = this.get(this.mode === 'file' ? 'source' : 'streamSource');
        if (!source) {
            this.onError('Source is missing');
            return;
        }

        if (this.videoNode !== null && this.canvasNode) {
            const stream = this.get('streamSource');
            // const isCordova = stream && stream.camera === 'cordovaMode';
            // const node = isCordova ? this.canvasNode : this.videoNode;

            // this.canvas.width = isCordova ? stream.width : this.videoNode.videoWidth;
            // this.canvas.height = isCordova ? stream.height : this.videoNode.videoHeight;
            // console.log('--- source size', this.canvas.width, this.canvas.height);
            // this.canvas.getContext('2d').drawImage(node, 0, 0);

            // this.canvas.toBlob((blob) => {
            //     this.eventCallback('captureToDataURL', this.canvas.toDataURL('image/jpeg', 1.0));
            //     this.eventCallback('captureToBlob', blob);
            // }, 'image/jpeg', 1.0);

            // Cordova
            if (stream && stream.camera === 'cordovaMode') {
                // console.log('--- cordova source size', this.canvasNode.width, this.canvasNode.height);
                try {
                    this.canvasNode.toBlob((blob) => {
                        this.eventCallback('captureToDataURL', this.canvasNode.toDataURL('image/jpeg', 1.0));
                        this.eventCallback('captureToBlob', blob);
                    }, 'image/jpeg', 1.0);
                }
                catch (err) { this.eventCallback('error', err.message); }
            }
            else {
                this.canvas.width = this.videoNode.videoWidth;
                this.canvas.height = this.videoNode.videoHeight;
                // console.log('--- browser source size', this.canvas.width, this.canvas.height);
                try {
                    this.canvas.getContext('2d').drawImage(this.videoNode, 0, 0);
                    this.canvas.toBlob((blob) => {
                        this.eventCallback('captureToDataURL', this.canvas.toDataURL('image/jpeg', 1.0));
                        this.eventCallback('captureToBlob', blob);
                    }, 'image/jpeg', 1.0);
                }
                catch (err) { this.eventCallback('error', err.message); }
            }
        }
    }

    /**
     * Controls handler, manage user interaction on controls.
     * @param {String} ctl - The control name
     * @param {Boolean} isHover - Whether the mouse is over the element or not.
     */
    onControls(ctl, isHover = false) {
        if (!this.previousAction) {
            switch (ctl) {
            case 'play' : this.previousAction = 'start'; break;
            case 'pause': this.previousAction = 'pause'; break;
            }
        }
        else if (ctl === 'loaded') this.previousAction = 'start'; // handle autoplay after stop
    }

    /**
     * Error handler.
     * @param {String} err - The error message
     */
    onError(err) {
        console.error('Video player error:', err);
        this.fireOutput('error', err);
    }

    /**
     * Call all listeners attached to the specified output.
     *
     * @param {string} name - The name of the output event to fire.
     * @param {...*} values - The arguments passed to the output listeners.
     */
    fireOutput(name, value) {
        this.eventCallback(name, value);
    }
}

export default UiVideo;
