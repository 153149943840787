var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "content" } }, [
    _c("h1", [_vm._v("Node Experience")]),
    _c("h2", [_vm._v("Presentation")]),
    _vm._m(0),
    _c("h2", [_vm._v("Parameters")]),
    _vm._m(1),
    _c("h2", [_vm._v("Actions")]),
    _vm._m(2),
    _c("h2", [_vm._v("Settings")]),
    _c("h3", [_vm._v("Geo Beacon Settings")]),
    _c("p", [_vm._v("Not documented yet.")]),
    _c("h3", [_vm._v("BLE Beacon Settings")]),
    _c("p", [_vm._v("Not documented yet.")]),
    _c("h3", [_vm._v("Targets")]),
    _vm._m(3),
    _c("h3", [_vm._v("Home Screen")]),
    _c("p", [
      _vm._v(
        "A screen has to be selected as the default screen to be opened when the experience is launched."
      )
    ]),
    _c("h3", [_vm._v("Memory")]),
    _vm._m(4),
    _c("p", [
      _vm._v("A persistent memory is kept from one session to another.")
    ]),
    _c("div", { attrs: { id: "back" } }, [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.displayTab("nodes")
            }
          }
        },
        [_vm._v("← Back")]
      )
    ]),
    _vm._m(5)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "An experience is an app that organizes the user experience. It contains a series of screens linked by conditions and settings shared between screens."
      ),
      _c("br"),
      _vm._v(
        " An experience has a target that defines on what it is displayed (Mobile, Tablet, Desktop, ...)."
      ),
      _c("br"),
      _vm._v(
        " The authoring interface adapts according to the target (available modules)."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { attrs: { id: "params" } }, [
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Name")])]),
        _c("td", { attrs: { id: "col2" } }, [_vm._v("String")])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Owner")])]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v("User who create the project")
        ])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [
          _c("strong", [_vm._v("User(s)")])
        ]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v(
            "List of users who can access the experience with specific rights (view only, edit, admin)"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { attrs: { id: "params" } }, [
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Delete")])]),
        _c("td", { attrs: { id: "col2" } }, [_vm._v("Delete the experience")])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [
          _c("strong", [_vm._v("Duplicate")])
        ]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v("Create a copy of the experience")
        ])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Share")])]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v(
            "Share the experience with specific rights (view only, edit, admin)"
          )
        ])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Export")])]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v("Export the experience in JSON")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("An experience has to be deployed for at least one target."),
      _c("br"),
      _vm._v(
        " The authoring interface adapts according to the target (available modules)."
      ),
      _c("br"),
      _vm._v(" Different targets are available :"),
      _c("ul", { staticClass: "listing" }, [
        _c("li", [_vm._v("Mobile")]),
        _c("li", [_vm._v("Tablet ")]),
        _c("li", [_vm._v("Desktop")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("A shared memory is accessible to all the users of the project."),
      _c("br"),
      _vm._v(" It has different types :"),
      _c("ul", { staticClass: "listing" }, [
        _c("li", [_vm._v("Angle")]),
        _c("li", [
          _vm._v("Array (A specific interface allows to parameter the array")
        ]),
        _c("li", [_vm._v("Asset (It can be chosen via the Library)")]),
        _c("li", [_vm._v("Boolean")]),
        _c("li", [_vm._v("Color")]),
        _c("li", [_vm._v("Float")]),
        _c("li", [_vm._v("Integer")]),
        _c("li", [_vm._v("Percent")]),
        _c("li", [_vm._v("String")]),
        _c("li", [_vm._v("Vector")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("br"), _c("br"), _c("br")])
  }
]
render._withStripped = true

export { render, staticRenderFns }