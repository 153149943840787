import React from 'react';
import AdvancedUi from '../AdvancedUi';

export default class Image extends AdvancedUi {

    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            position: 'relative'
        });

        this.specificStyle = {
            
        };

        


        // merge style order :  
        // mergedStyle <== defaultStyle(parameters) < specificStyle < field props < forcedStyle(auto calculated)
    }

    setDefaultStyles( nextProps, nextState ){

        super.setDefaultStyles( nextProps, nextState );
        
    }

    render() {
        let value = this.props.image + this.props['image__url'];
        let url = this.props['image__url'];


        if( url !== null && url != '' ){
             return this.wrapIntoHammer( this.wrapIntoAuthoring( 
                <img {...this.otherEvents} {...this.props.dom} style={this.mergedStyle} src={url} />
            , 0  )) ;
        }
        return this.wrapIntoHammer( this.wrapIntoAuthoring( 
            <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>Oups il n'y a pas assez de connexion pour charger l'image.</div>
         , 0  )) ;
       

    }

}