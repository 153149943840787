
<template lang="pug">

	div.container#app
		router-view(:app-state="state")

</template>


<script>
	import { stores } from '../stores/MainStore.js'

	export default {

		created: function() {
			var self = this;

			stores.config.$router = self.$router;
			this.state = stores.getInitialState();

			self.$router.beforeEach((to, from, next) => {
				if ( to.name!='home' && to.name.search("doc") === -1 && ( self.state === null || (!self.state.user && !self.state.isLoading)) ) {
					console.warn("invalid state -> redirect home");
					self.$router.push('/home');
				}
				next();
			});

			// check for user session or redirect to login page
			if ( !self.state.user && self.$route.name!='home' && self.$route.name.search("doc")===-1 ) {
				console.warn("session out -> redirect home")
				self.$router.push('/home');
			}

		},

		data() {
			return {
				state: {}
				// isPreHome: true
			}

		}

	}

</script>

<style lang="stylus">

@font-face
    font-family: 'bembobold';
    src: url('../assets/fonts/bembo-bold.eot');
    src: url('../assets/fonts/bembo-bold.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/bembo-bold.woff2') format('woff2'),
            url('../assets/fonts/bembo-bold.woff') format('woff'),
            url('../assets/fonts/bembo-bold.ttf') format('truetype'),
            url('../assets/fonts/bembo-bold.svg#bembobold') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'bemboregular';
    src: url('../assets/fonts/bembo.eot');
    src: url('../assets/fonts/bembo.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/bembo.woff2') format('woff2'),
            url('../assets/fonts/bembo.ttf') format('truetype'),
            url('../assets/fonts/bembo.svg#bemboregular') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'bemboitalic';
    src: url('../assets/fonts/bembo-italic.eot');
    src: url('../assets/fonts/bembo-italic.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/bembo-italic.woff2') format('woff2'),
            url('../assets/fonts/bembo-italic.woff') format('woff'),
            url('../assets/fonts/bembo-italic.ttf') format('truetype'),
            url('../assets/fonts/bembo-italic.svg#bemboitalic') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'bembobold_italic';
    src: url('../assets/fonts/bembo-bolditalic.eot');
    src: url('../assets/fonts/bembo-bolditalic.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/bembo-bolditalic.woff2') format('woff2'),
            url('../assets/fonts/bembo-bolditalic.woff') format('woff'),
            url('../assets/fonts/bembo-bolditalic.ttf') format('truetype'),
            url('../assets/fonts/bembo-bolditalic.svg#bembobold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'ComfortaaLight'
    src: url('../assets/fonts/comfortaa-light.eot')
    src: url('../assets/fonts/comfortaa-light.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/comfortaa-light.woff')  format('woff'), url('../assets/fonts/comfortaa-light.ttf') format('truetype'), url('../assets/fonts/comfortaa-light.svg#comfortaalight') format('svg')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'ComfortaaBold'
    src: url('../assets/fonts/comfortaa-bold.eot')
    src: url('../assets/fonts/comfortaa-bold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/comfortaa-bold.woff')  format('woff'), url('../assets/fonts/comfortaa-bold.ttf') format('truetype'), url('../assets/fonts/comfortaa-bold.svg#comfortaalight') format('svg')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'DinRegular';
    src: url('../assets/fonts/DINNextLTPro-Regular.eot');
    src: url('../assets/fonts/DINNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/DINNextLTPro-Regular.woff') format('woff'),
            url('../assets/fonts/DINNextLTPro-Regular.ttf') format('truetype'),
            url('../assets/fonts/DINNextLTPro-Regular.svg#dinregular') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'DinBold';
    src: url('../assets/fonts/DINNextLTPro-Bold.eot');
    src: url('../assets/fonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/DINNextLTPro-Bold.woff') format('woff'),
            url('../assets/fonts/DINNextLTPro-Bold.ttf') format('truetype'),
            url('../assets/fonts/DINNextLTPro-Bold.svg#dinbold') format('svg');
    font-weight: normal;
    font-style: normal;


@font-face
    font-family: 'DinCondensed';
    src: url('../assets/fonts/DINNextLTPro-Condensed.eot');
    src: url('../assets/fonts/DINNextLTPro-Condensed.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/DINNextLTPro-Condensed.woff') format('woff'),
            url('../assets/fonts/DINNextLTPro-Condensed.ttf') format('truetype'),
            url('../assets/fonts/DINNextLTPro-Condensed.svg#DINNextLTPro-Condensed') format('svg');
    font-weight: normal;
    font-style: normal;


@font-face
    font-family: 'DinBoldCondensed';
    src: url('../assets/fonts/DINNextLTPro-BoldCondensed.eot');
    src: url('../assets/fonts/DINNextLTPro-BoldCondensed.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/DINNextLTPro-BoldCondensed.woff') format('woff'),
            url('../assets/fonts/DINNextLTPro-BoldCondensed.ttf') format('truetype'),
            url('../assets/fonts/DINNextLTPro-BoldCondensed.svg#DINNextLTPro-BoldCondensed') format('svg');
    font-weight: normal;
    font-style: normal;


@font-face
    font-family: 'DINNextLTPro-Regular';
    src: url('../assets/fonts/DINNextLTPro-Regular.eot');
    src: url('../assets/fonts/DINNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/DINNextLTPro-Regular.woff') format('woff'),
            url('../assets/fonts/DINNextLTPro-Regular.ttf') format('truetype'),
            url('../assets/fonts/DINNextLTPro-Regular.svg#DINNextLTPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'DINNextLTPro-Bold';
    src: url('../assets/fonts/DINNextLTPro-Bold.eot');
    src: url('../assets/fonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/DINNextLTPro-Bold.woff') format('woff'),
            url('../assets/fonts/DINNextLTPro-Bold.ttf') format('truetype'),
            url('../assets/fonts/DINNextLTPro-Bold.svg#DINNextLTPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;

@font-face
    font-family: 'PoppinsRegular';
    src: url('../assets/fonts/poppins-regular.eot');
    src: url('../assets/fonts/poppins-regular.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/poppins-regular.woff') format('woff'),
            url('../assets/fonts/poppins-regular.ttf') format('truetype'),
            url('../assets/fonts/poppins-regular.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'PoppinsBold';
    src: url('../assets/fonts/poppins-bold.eot');
    src: url('../assets/fonts/poppins-bold.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/poppins-bold.woff') format('woff'),
            url('../assets/fonts/poppins-bold.ttf') format('truetype'),
            url('../assets/fonts/poppins-bold.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'GalanoRegular';
    src: url('../assets/fonts/galano-regular.eot');
    src: url('../assets/fonts/galano-regular.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/galano-regular.woff') format('woff'),
            url('../assets/fonts/galano-regular.ttf') format('truetype'),
            url('../assets/fonts/galano-regular.svg#GalanoClassicDEMOAlt-Regular') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'GalanoBold';
    src: url('../assets/fonts/galano-bold.eot');
    src: url('../assets/fonts/galano-bold.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/galano-bold.woff') format('woff'),
            url('../assets/fonts/galano-bold.ttf') format('truetype'),
            url('../assets/fonts/galano-bold.svg#galano_grotesque_demobold') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Regular-webfont.eot');
	src: url('../assets/fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
			url('../assets/fonts/Roboto-Regular-webfont.woff') format('woff'),
			url('../assets/fonts/Roboto-Regular-webfont.ttf') format('truetype'),
			url('../assets/fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
	font-weight: normal;
	font-style: normal;

@font-face {
    font-family: 'Typold-Black';
    src: url('../assets/fonts/Typold-Black.eot');
    src: url('../assets/fonts/Typold-Black.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/Typold-Black.svg#Typold-Black') format('svg'),
            url('../assets/fonts/Typold-Black.ttf') format('truetype'),
            url('../assets/fonts/Typold-Black.woff') format('woff'),
            url('../assets/fonts/Typold-Black.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Typold-Regular';
    src: url('../assets/fonts/Typold-Regular.eot');
    src: url('../assets/fonts/Typold-Regular.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/Typold-Regular.svg#Typold-Regular') format('svg'),
            url('../assets/fonts/Typold-Regular.ttf') format('truetype'),
            url('../assets/fonts/Typold-Regular.woff') format('woff'),
            url('../assets/fonts/Typold-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face
	font-family: 'YaahowuLight';
	src: url('../assets/fonts/yaahowu_light.eot');
	src: url('../assets/fonts/yaahowu_light.eot?#iefix') format('embedded-opentype'),
			url('../assets/fonts/yaahowu_light.woff') format('woff'),
			url('../assets/fonts/yaahowu_light.ttf') format('truetype'),
			url('../assets/fonts/yaahowu_light.svg#yaahowu_lightregular') format('svg');
	font-weight: normal;
	font-style: normal;

@font-face
    font-family: 'YaahowuBold';
    src: url('../assets/fonts/yaahowu_bold.eot');
    src: url('../assets/fonts/yaahowu_bold.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/yaahowu_bold.woff') format('woff'),
            url('../assets/fonts/yaahowu_bold.ttf') format('truetype'),
            url('../assets/fonts/yaahowu_bold.svg#yaahowu_boldregular') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'EarthOrbiterTitle';
    src: url('../assets/fonts/earthorbitertitle.eot');
    src: url('../assets/fonts/earthorbitertitle.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/earthorbitertitle.ttf') format('truetype'),
            url('../assets/fonts/earthorbitertitle.woff') format('woff'),
            url('../assets/fonts/earthorbitertitle.woff2') format('woff2'),
            url('../assets/fonts/earthorbitertitle.svg#EarthOrbiterTitle') format('svg');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'Outfit-Bold';
    src: url('../assets/fonts/Outfit-Bold.eot');
    src: url('../assets/fonts/Outfit-Bold.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/Outfit-Bold.svg#Outfit-Bold') format('svg'),
            url('../assets/fonts/Outfit-Bold.ttf') format('truetype'),
            url('../assets/fonts/Outfit-Bold.woff') format('woff'),
            url('../assets/fonts/Outfit-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

@font-face
    font-family: 'Outfit-Regular';
    src: url('../assets/fonts/Outfit-Regular.eot');
    src: url('../assets/fonts/Outfit-Regular.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/Outfit-Regular.svg#Outfit-Regular') format('svg'),
            url('../assets/fonts/Outfit-Regular.ttf') format('truetype'),
            url('../assets/fonts/Outfit-Regular.woff') format('woff'),
            url('../assets/fonts/Outfit-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

html
	margin 0
	padding 0
	height: 100%

body
	margin 0
	padding 0
	height 100%
	position relative
	font-family 'DinRegular', 'ComfortaaLight', Helvetica
	//overflow: hidden

.container
	background-color #EFEFEF

.draw-zone
	position: fixed
	top: 0
	left: 0
	bottom: 0
	right: 0
	z-index: 50
	background-color: var(--drawZoneColor)
	overflow: hidden
	transition: all 0.3s ease-in-out

	&.navigatorOpen
		left: 420px


</style>
