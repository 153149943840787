import ActionAbstract from '../ActionAbstract';
import Vue from 'vue';

/**
 * @param experience: the experience (slug) to be downloaded
 */
class DownloadProject extends ActionAbstract {

    
    static get ID() {
        return 'actions:project:download';
    }

    runError(state) {
        // @todo: implement notification
        //this.trigger('notification', { message: this.getParam('message'), type: 'error' });
        console.error('notification', { message: this.getParam('message'), type: 'error' });
        return this.composeState(state, {
            isLoading: false,
            project: {
                error: {
                    code: this.getParam('code'),
                    message: this.getParam('message'),
                    action: 'project:download'
                }
            }
        })
    }

    runSuccess(state) {
        return this.composeState(state, {
            isLoading: false,
        });
    }

    run(state) {

        const owner = state.project.owner.replace('org.couchdb.user:', '');
        const slug = state.project.value.slug;
        const xp = this.getParam('experience');
        const options = this.getParam('options');

        const url = `https://${env.domain_app}/authoring/downloadproject/${owner}/${slug}/${xp}`;

        let reqObj = { responseType: 'arraybuffer' }
        if (options) {
            reqObj.params = {qParam: options}
        }

        /** DATABASE WORKSHOP NS-324
         * ! this call should have an authentification token
         * ! nothing is secure and every user could do this call
         * ! We should use the token/cookie used with couchDB login.
        */
        const download = async (url) => {
            await Vue.http.get(url, reqObj).then(
                (response) => {
                    // @todo: use HTTP status code and status message instead of passing error 
                    // code/message in body as JSON. The body must only content binary data.
                    const contentType = response.headers.get('content-type');
                    if (contentType && contentType.startsWith('application/json')) {

                        this.trigger('project:download', {
                            subaction: 'error',
                            code: response.data.code, // response.status
                            message: 'You must first run the project once' // response.statusText
                        });

                    }
                    else {
                        const blob = new Blob([response.data], { type: 'application/gzip' });
                        const link = document.createElement('a');
                        const date = new Date().toISOString().slice(0, 10);
                        link.href = URL.createObjectURL(blob);
                        link.download = `${slug}-${xp}-${date}.tar.gz`;
                        link.click();
                        URL.revokeObjectURL(link.href);
                        this.trigger('project:download', { subaction: 'success' })
                        this.trigger('main:modal:closeModal')
                    }
                },
                (error) => {
                    const message = error.statusText || `Failed to download experience '${xp}'`;
                    this.trigger('project:download', { subaction: 'error', code: error.status, message });
                }
            );
        }

        download(url);

        return this.composeState(state, {
            isLoading: true,
        });

    }
}

export default DownloadProject;