import UiObject, { EVENT_CALLBACK } from '../UiObject';

/**
 * Simple input text based on HTML input type.
 * Available types:
 *   - Text
 *   - Number
 *   - Password
 *   - Email
 *   - URL
 *   - Long Text (i.e textArea)
 */
class UiInputText extends UiObject {
    static getAuthConfig() {
        return {
            exportFormat: 'ui-input-text',
            label: 'Text Field',
            parent: 'ui-inputs',
            isDisplayed: true,
            isEmbeddable: true,
        };
    }

    /**
     * InputText constructor.
     *
     * @param {string} id - The UI object identifier.
     * @param {Array} props - All UI object parameters.
     */
    constructor(id, props) {
        super(id, props, 'InputText');
    }

    /**
     * Define the InputText parameters.
     */
    initParameters() {
        super.initParameters();

        // prepend Input topic
        this.inspector.unshift({
            type: 'topic',
            name: 'settings',
            title: 'Text Field',
            isAccordion: true,
            children: [
                {
                    type: 'group',
                    name: 'group-input-settings',
                    title: 'Settings',
                    isAccordion: true,
                    children: [
                        {
                            type: 'group-field',
                            name: 'group-field-input-settings',
                        },
                    ],
                },
                {
                    type: 'group',
                    name: 'group-input-font',
                    title: 'Font Styles',
                    isAccordion: true,
                    children: [
                        {
                            type: 'group-field',
                            name: 'group-field-input-font',
                        },
                    ],
                },
            ],
        });
        // append input events to Events topic
        this.inspector[2].children.push(
            {
                type: 'group',
                name: 'group-input-events',
                title: 'Input Events List',
                isAccordion: true,
                children: [
                    {
                        type: 'group-field',
                        name: 'group-field-input-events',
                    },
                ],
            },
        );


        // define input parameters
        this.addToParameters({
            // settings
            value: {
                type: 'String',
                default: '',
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: true },
                },
                auth: {
                    label: 'Value',
                    container: 'group-field-input-settings',
                    description: 'The default value of the input field.',
                },
            },
            type: {
                type: 'String',
                default: 'text',
                partial: 'input',
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Type',
                    container: 'group-field-input-settings',
                    widget: 'select',
                    options: [
                        { value: 'text', label: 'Text' },
                        { value: 'textarea', label: 'Long Text' },
                        { value: 'number', label: 'Number' },
                        { value: 'password', label: 'Password' },
                        { value: 'email', label: 'Email' },
                        { value: 'url', label: 'URL' },
                    ],
                    description: 'The type of the input field.',
                },
            },
            min: {
                type: 'Int',
                default: null,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Minimum Value',
                    container: 'group-field-input-settings',
                    description: 'The minimum value allowed in the numeric input field.',
                    conditions: [{ field: 'type', operator: '==', value: 'number' }],
                },
            },
            max: {
                type: 'Int',
                default: null,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Maximum Value',
                    container: 'group-field-input-settings',
                    description: 'The maximum value allowed in the numeric input field.',
                    conditions: [{ field: 'type', operator: '==', value: 'number' }],
                },
            },
            step: {
                type: 'Float',
                default: 1,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Step Value',
                    container: 'group-field-input-settings',
                    description: 'The step value allowed in the numeric input field.',
                    conditions: [{ field: 'type', operator: '==', value: 'number' }],
                },
            },
            minLength: {
                type: 'Int',
                default: null,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Minimum Length',
                    container: 'group-field-input-settings',
                    description: 'The minimum number of characters allowed in the input field.',
                },
            },
            maxLength: {
                type: 'Int',
                default: null,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Maximum Length',
                    container: 'group-field-input-settings',
                    description: 'The maximum number of characters allowed in the input field.',
                },
            },
            autoComplete: {
                type: 'Boolean',
                default: false,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Autocomplete',
                    container: 'group-field-input-settings',
                    description: 'Specifies whether the input field should have autocomplete enabled.',
                },
            },
            pattern: {
                type: 'String',
                default: '',
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Pattern',
                    container: 'group-field-input-settings',
                    description: 'Specifies a regular expression that the input field value is checked against.',
                },
            },
            placeholder: {
                type: 'String',
                default: '',
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Placeholder',
                    container: 'group-field-input-settings',
                    description: 'Specifies a short hint that describes the expected value of the input field.',
                },
            },
            disabled: {
                type: 'Boolean',
                default: false,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Disabled',
                    container: 'group-field-input-settings',
                    description: 'Specifies whether the input field should be disabled.',
                },
            },
            readOnly: {
                type: 'Boolean',
                default: false,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Read Only',
                    container: 'group-field-input-settings',
                    description: 'Specifies whether the input field can be modified or not.',
                },
            },
            required: {
                type: 'Boolean',
                default: false,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Required',
                    container: 'group-field-input-settings',
                    description: 'Specifies whether the input field must be filled out.',
                },
            },

            // events
            onChange: {
                type: 'Boolean',
                default: false,
                partial: 'input',
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Change',
                    container: 'group-field-input-events',
                    description: 'Triggers whether the pattern value is verified or not, when the value of the input field is change.',
                },
            },
            onBlur: {
                type: 'Boolean',
                default: false,
                partial: 'input',
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Blur',
                    container: 'group-field-input-events',
                    description: 'Triggers whether the pattern value is verified or not, when the input field loses focus.',
                },
            },
            onInvalid: {
                type: 'Boolean',
                default: false,
                partial: 'input',
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Invalid',
                    container: 'group-field-input-events',
                    description: 'Triggers whether the input value does not satisfy its constraints or pattern.',
                },
            },

            // font styles
            // @todo: this class should extend something like 'FontUiObject'
            // see ticket NS-
            fontSize: {
                type: 'Int',
                default: 13,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Font Size',
                    container: 'group-field-input-font',
                    unit: 'px',
                    description: 'The font size of the text to display.',
                },
            },
            color: {
                type: 'Color',
                default: { hex: '#000000' },
                partial: 'styles',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Color',
                    container: 'group-field-input-font',
                    description: 'The color of the text to display.',
                },
            },
            textAlign: {
                type: 'String',
                default: 'left',
                partial: 'styles',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Text Align',
                    container: 'group-field-input-font',
                    widget: 'select',
                    options: [
                        { value: 'left', label: 'left' },
                        { value: 'center', label: 'center' },
                        { value: 'right', label: 'right' },
                    ],
                    description: 'The alignment of the text.',
                },
            },
            lineHeight: {
                type: 'Int',
                default: 16,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Line Height',
                    container: 'group-field-input-font',
                    unit: 'px',
                    description: 'The height of each line of the text to display.',
                },
            },
            letterSpacing: {
                type: 'Int',
                default: 0,
                partial: 'styles',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Letter Spacing',
                    container: 'group-field-input-font',
                    unit: 'px',
                    description: 'The Space between each character.',
                },
            },
            underline: {
                type: 'Boolean',
                default: false,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Underline',
                    container: 'group-field-input-font',
                    description: 'Specifies whether the text should be underlined or not.',
                },
            },
            italic: {
                type: 'Boolean',
                default: false,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Italic',
                    container: 'group-field-input-font',
                    description: 'Specifies whether the text should be displayed in italic or not.',
                },
            },
            bold: {
                type: 'Boolean',
                default: false,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Bold',
                    container: 'group-field-input-font',
                    description: 'Specifies whether the text should be displayed in bold or not.',
                },
            },
            uppercase: {
                type: 'Boolean',
                default: false,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Uppercase',
                    container: 'group-field-input-font',
                    description: 'Specifies whether the text should be displayed in uppercase letters or not.',
                },
            },
        });
    }

    /**
     * Update a parameter.
     *
     * @param {string} prop - The parameter name.
     * @param {string} value - The parameter value.
     */
    set(prop, value) {
        super.set(prop, value);
        if (prop === 'value') {
            this.emit(EVENT_CALLBACK, { name: 'value', value });
            this.emit(EVENT_CALLBACK, { name: 'onChange', value: true });
        }
    }
}

export default UiInputText;
