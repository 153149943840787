import InspectorActionAbstract from './InspectorActionAbstract';
import * as helper from '../../helper' ;
import Vue from 'vue';

class UpdateApplyFieldQueryMode extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateApplyFieldQueryMode';
    }

    /**
     * @param targetId
     * @param apply : boolean
     * @param fieldName 
     */

    run (state) {

        let field, index;
        [field, index] = helper.block.getField(this._target, this.getParam('fieldName'), true);

        /*let compose = composite || { project: {} };
        compose.project[this._path + '.value.fields[' + index + '].apply'] = this.getParam('apply');
        */
        Vue.set( field, 'apply' , this.getParam('apply')  );
        
        return true; // this.composeState(state, compose);     

    }



}

export default UpdateApplyFieldQueryMode;