import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import deepCopy from 'deepcopy';

class DeleteMemory extends ActionAbstract {

    /**
     * @param targetId - the id of the parent block
     * @param memId - id of the memory to delete
     */

    static get ID() {
        return 'actions:project:deleteMemory';
    }

    run (state) {

        let parent;

        // this.closeMemory();
        let projectCopy = deepCopy(state.project);

        let memory = helper.block.getMemoryById(projectCopy, this.getParam('memId')),
            target = helper.block.getBlockById(projectCopy, this.getParam('targetId')) || helper.block.getMemoryOwner(projectCopy, this.getParam('memId'));
            
        let index = target.value.memory.findIndex((el) => {
                return el.id === this.getParam('memId');
            });

        memory.value._deleted = true;
        target.value.memory.splice(index, 1);
        // stores.project.saveProject( stores.config.openProject );

        return this.composeState(state, {
            project: projectCopy
        });

    }

}

export default DeleteMemory;