import AdvancedUiObject from '../AdvancedUiObject';

class UiScreen extends AdvancedUiObject {
    constructor(id, props) {
        super(id, props, 'Screen');

    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-screen",
            label: "HTML View",
            parent : "ui-layouts",
            isDisplayed : true,
            isEmbeddable: false,
            nbSection: 1,
            childrenType: 'ui'
        };
    }


    
    initParameters(){
        super.initParameters();
    
        let otherParameters = {
            // Add param to positionning common settings
            textAlign: {
                type: 'String', 
                default: "center", 
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Content Alignment",
                    container: "pos-fields",
                    widget: 'select',
                    options: [ {value:'left', label:'left'}, {value:'center', label:'center'}, {value:'right', label:'right'} ],
                    description: "Sets the horizontal alignment of the UI: Inherit: same alignment as the parent / Left: aligned to the left / Center: aligned to the center / Right: aligned to the right."
                }
            }
        };

        this.addToParameters( otherParameters );

        this.setParamInvisible( 'parent' );
        //this.setParamInvisible( 'parent_select' );

        this.setDefaultValue( 'fontFamily' , 'ComfortaaLight' );
        this.setDefaultValue( 'width', '100%' );
        this.setDefaultValue( 'height', '100%' );

        this.setDefaultValue( 'position', 'absolute' );
        this.setDefaultValue( 'top', '0px' );
        this.setDefaultValue( 'left', '0px' );
        this.setDefaultValue( 'bottom', '0px' );
        this.setDefaultValue( 'right', '0px' );
        this.setDefaultValue( 'screenfixed', true );
        this.setDefaultValue( 'backgroundColor', { hex: '#000000' } );

        this.disableParam( 'width' );
        this.disableParam( 'height' );
        this.disableParam( 'position' );
        this.disableParam( 'top' );
        this.disableParam( 'left' );
        this.disableParam( 'bottom' );
        this.disableParam( 'right' );
        this.disableParam( 'screenfixed' );

        this.disableParam( 'marginTop' );
        this.disableParam( 'marginRight' );
        this.disableParam( 'marginBottom' );
        this.disableParam( 'marginLeft' );

        this.disableParam( 'paddingTop' );
        this.disableParam( 'paddingRight' );
        this.disableParam( 'paddingBottom' );
        this.disableParam( 'paddingLeft' );
    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }
    
    init(){
        super.init();

        //this.set('styles', {height: "100%"}, true );
        
    }
}

export default UiScreen;