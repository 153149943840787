var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchbar" },
    [
      _c("icon", { staticClass: "search", attrs: { name: "search" } }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        attrs: { id: "searchIN", type: "search", placeholder: _vm.placeholder },
        domProps: { value: _vm.search },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          }
        }
      }),
      _vm.tag
        ? _c("icon", {
            staticClass: "close",
            attrs: { name: "search-close" },
            nativeOn: {
              click: function($event) {
                return _vm.clear($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }