import InspectorActionAbstract from './InspectorActionAbstract';
import * as helper from '../../helper' ;

class RepeatedForm extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:repeatedForm';
    }

    /**
     * @param targetId
     * @param bloc
     */
    runAdd (state) {

        let bloc = this.getParam('bloc'),
            forms = bloc.form,
            fields = [],
            uid = this.uniqueid();

        Object.keys(forms).forEach((formName) => {
            let formDef = forms[formName];

            fields.push( {
                formName: formName,
                in: formDef.connection.in.pluggable && formDef.connection.in.default,
                out: formDef.connection.out.pluggable && formDef.connection.out.default,
                name: [bloc.name, uid, formName].join('_'),
                parentField: bloc.name,
                pluggable:{
                    in:formDef.connection.in.pluggable,
                    out:formDef.connection.out.pluggable
                },
                repeatedField:true,
                value: null,
            } );
        });

        let composite = { project: {} };
        composite.project[this._path + '.value.fields'] = this.arrayPush(fields);

        return this.composeState( state, composite );
    }

    /**
     * @param targetId
     * @param bloc
     * @param uid
     */
    runRemove (state) {
        let indexes = [];
        this._target.value.fields.forEach((f, idx) => {
            if (f.name.indexOf(this.getParam('uid')) !== -1) {
                indexes.push(idx);
            }
        });

        let composite = { project: {} };
        composite.project[this._path + '.value.fields'] = this.arraySplice(indexes);
        
        return this.composeState( state, composite );
    }

    /**
     * @param targetId
     * @param bloc
     * @param nbRepeatedField
     */
    runSetNbRepeatedField( state ){
        // Get all current field, filtred by uid saved in bdd
        let target = helper.block.getBlockById( state.project, this.getParam('targetId') ) ;
        let bloc = this.getParam('bloc');
        
        let uuids = [];
        if( target && target.value ){

            target.value.fields.forEach( (field) =>{
                let isRepeatedValue = -1;
                if( field.name )
                    isRepeatedValue = field.name.search( bloc.name+"_" );
                if( isRepeatedValue != -1 ){
                    let fieldname  = field.name.replace(bloc.name+"_", '')
                    let uid = fieldname.substring(0, fieldname.indexOf("_")) ;
                    
					if( uuids.indexOf(uid) == -1 )
                        uuids.push( uid );
                }
            });
    
            let nbRepeatedField = this.getParam('nbRepeatedField');
            if( uuids.length < nbRepeatedField ){
                for( let i = 0 ; i < nbRepeatedField - uuids.length ; i++ ){
			        this.trigger('inspector:repeatedForm', { subaction: 'add', targetId: this.getParam('targetId'), bloc: this.getParam('bloc') });
                }
            }
            else if( uuids.length > nbRepeatedField ){
                let uuidsToRemove = uuids.splice( nbRepeatedField, uuids.length );
                uuidsToRemove.forEach( ( uuidToRemove ) => {
			        this.trigger('inspector:repeatedForm', { subaction: 'remove', targetId: this.getParam('targetId'), bloc: this.getParam('bloc'), uid : uuidToRemove });
                })
            }
        }

        return false;
    }

    postRun() {
        this.trigger('block:updateFields', { id: this.getParam('targetId') });
    }

    uniqueid () {
        var idstr=String.fromCharCode(Math.floor((Math.random()*25)+65));
        do {                
            var ascicode=Math.floor((Math.random()*42)+48);
            if (ascicode<58 || ascicode>64){
                idstr+=String.fromCharCode(ascicode);    
            }                
        }while (idstr.length<32);

        return (idstr);
    }

}

export default RepeatedForm;