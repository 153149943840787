import ActionManager from '../ActionManager';

import ListAll from './ListAll';
ActionManager.register(ListAll, ['stores:MainStore']);

import SetFilter from './SetFilter';
ActionManager.register(SetFilter, ['stores:MainStore']);

import Share from './Share';
ActionManager.register(Share, ['stores:MainStore']);
