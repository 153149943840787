import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

// FIXME for node.js 10.5.3 or remove this plugin : 
// Verify if we need this plugin for the browsers we need to support.
// react-tap-event-plugin@3.0.3 requires a peer of react@^16.0.0-0 < 16.4.0 but none is installed. You must install peer dependencies yourself.
// see https://www.npmjs.com/package/react-tap-event-plugin
// import injectTapEventPlugin from 'react-tap-event-plugin';
// injectTapEventPlugin();

import * as components from './';

export default class Main extends React.Component {
    constructor() {
        super();
        this.authcallback = null;

        this.state = {
            obj: null,
            displayMode : "desktop",
            // dnd: {
            //     draggedItem : null,
            //     dropped : false,
            //     flyOverBox : null,
            //     success : false
            // }
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.dragHandler = this.dragHandler.bind(this);
        this.dragStartHandler = this.dragStartHandler.bind(this);
        this.dropHandler = this.dropHandler.bind(this);
        this.registerDropZone = this.registerDropZone.bind(this);

        this.dropZones = {}
        this.prevDragOver = null;
        // merge style order :  
        // mergedStyle <== defaultStyle(parameters) < specificStyle < field props < forcedStyle(auto calculated)
    }

    componentWillMount() {
        this.updateWindowDimensions();
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        if( this.state.obj && this.state.obj.renderer && this.state.obj.renderer.auth ){
            window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
            return;
        }

        if( window.innerWidth < 600 && this.state.displayMode != 'mobile' )  
            this.setState( { displayMode : "mobile" } );
        else if( window.innerWidth >= 600 && window.innerWidth <= 1024  && this.state.displayMode != 'tablet' )  
            this.setState( { displayMode : "tablet" } );
        else if( window.innerWidth > 1024 && this.state.displayMode != 'desktop' )  
            this.setState( { displayMode : "desktop" } );
    }

    registerDropZone(dz, register = true) {
        if (register)
            this.dropZones[dz.props._id] = dz
        else
            delete this.dropZones[dz.props._id]
    }

    dragStartHandler(evt){
        if( this.isAuthMode() ) return;
    }

    _getFlyOverZone (evt) {
        const targetEl = document.elementFromPoint(evt.nativeEvent.center.x, evt.nativeEvent.center.y);

        if( !targetEl ) return;

        const dropZoneCmp = this.dropZones[targetEl.id];

        if (dropZoneCmp) {
            return dropZoneCmp;
        } else {
            return null
        }        
    }

    dragHandler( evt ) {
        if( this.isAuthMode() ) return; 
        
        const dropZoneCmp = this._getFlyOverZone(evt)

        if (dropZoneCmp && this.prevDragOver != dropZoneCmp ) {
            dropZoneCmp.dragOver(evt.nodalItemConfig);
            this.prevDragOver = dropZoneCmp;
        }

        if( this.prevDragOver && this.prevDragOver != dropZoneCmp ){
            this.prevDragOver.dragOut(evt.nodalItemConfig);
            this.prevDragOver = null;
        }


    }

    dropHandler( evt ){  
        if( this.isAuthMode() ) return; 

        const dropZoneCmp = this._getFlyOverZone(evt)
        
        if (dropZoneCmp) {
            return dropZoneCmp.dropItemInside(evt.nodalTargetId, evt.nodalItemConfig)
        }
        return false;
    }

    isAuthMode( ){
        return this.state.obj.renderer &&  this.state.obj.renderer.auth != undefined ? true : false;
    }

    render() {
        if (this.state.obj !== null) {
            
            let rendprops = { 
                renderer :      this.state.obj.renderer,
                onStartDrag :   this.dragStartHandler,
                onDrag:         this.dragHandler,
                onDragEnd :     this.dropHandler,
                registerDropZone: this.registerDropZone,
                // clearDnd :      this.clearDnd.bind(this),
                // dnd :           this.state.dnd
             };

            let forcedDisplayMode = this.state.obj.renderer.forcedDisplayMode;
            rendprops.renderer.displayMode = forcedDisplayMode ? forcedDisplayMode : this.state.displayMode;
            
            let auth =  this.isAuthMode();

            function rehydrateJSON(obj, key) {
                if (!obj) return '';
                
                var Type = components[obj.type];
                if (typeof Type !== 'function') {
                    throw new Error(`Unknown ${obj.type} ui component`);
                } else {
                    let cache = obj.cache,
                        nodesAsJson = null;
                        
                    if (cache && !auth ) {
                        nodesAsJson = cache;
                    } else {
                        var children = obj.children ? obj.children.map(rehydrateJSON) : [];
                        let props = Object.assign( {} , rendprops , obj.props );
                        
                        nodesAsJson = <Type key={props._id} {...props}>{children}</Type>

                        obj.cache = nodesAsJson;
                    }
                    return nodesAsJson;
                }
            }

            let content = rehydrateJSON( this.state.obj );
            return ( 
                <MuiThemeProvider> 
                    { content }
                </MuiThemeProvider>
            );
        } else {
            return false;
        }
    }
}