import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';
import deepCopy from 'deepcopy';

class Filter extends ActionAbstract {

    /**
     * @param type : type of target
     * @param search : string to seach in components list
     * @param ignoreIsEmbeddable : ignore param isEmbeddable of objects
    **/

    static get ID() {
        return 'actions:editor:menu:filter';
    }

    run(state) {

        return this.composeState(state, {
            editor: {
                menu :{
                    type : this.getParam('type'),
                    list : this.filter(state)
                }
            },
            inspector: {
              schematic: {
                level: this.getParam('type'),
              }
            }
        });
    }


    addComponentToTree( node, parentName, component ){
        if( node && node.name == parentName ){
            node.hasComponents = true;
            if( !node.components )
                node.components = [];
            node.components.push( component );
        }

        if( Array.isArray(node.children) && node.children.length > 0 ){
            for (var i = 0; i < node.children.length; i++) {
                this.addComponentToTree( node.children[i], parentName, component );
            }
        }

    }

    getInspector( type ){
        let allInspectors = this.deps.mainStore.config.authoring ;
        for( let inspector of allInspectors ){
            if( inspector.value.type == type )
                return inspector;
        }
        return null;
    }

    hasComponents( node ){

        if( node && Array.isArray(node.components) && node.components.length > 0 ){
            return true;
        }

        if( Array.isArray(node.children) && node.children.length > 0 ){
            let result = null;
            for (var i = 0; i < node.children.length; i++) {
                if( this.hasComponents( node.children[i] ) === true )
                    result = true;
            }
            if( result === true )
                return true;
        }

        return false;
    }

    clearTreeEmpty( tree ){
        let cleanTree = [];
        if (tree && Array.isArray(tree)) {
            for( let i = 0 ; i < tree.length ; i++ ){
                if( this.hasComponents( tree[i] ) )
                    cleanTree.push( tree[i]  );
            }
        }

        return cleanTree;
    }

    isSeachCompatible( name ){
        let result = true;
        if( this.getParam('search') ){
            let search = name.search( new RegExp(this.getParam('search'), 'i') );
            if( search == -1 ){
                result = false;
            }
        }
        return result;
    }

    filter(state) {
        let allInspectors = this.deps.mainStore.config.authoring ;
        let targetType = this.getParam('type');
        let templates = state.project ? state.project.templates : null;

        if (!targetType) return [];

        let tree = { children : deepCopy( this.deps.mainStore.config.authoringTree ) };
        // add templates
        if (templates && (targetType != 'project' && targetType != 'object')) {
            tree.children.unshift({
                name: "templates", title: "Templates", icon: "menuTemplates", children: [
                    { name: "template-process", title: "Process", icon: "template-item-process" },
                    { name: "template-screen",  title: "Screen",  icon: "template-item-screen" },
                    { name: "template-widget",  title: "Widget",  icon: "template-item-widget" },
                ]
            });
            for (let template of templates) {
                if (state.editor && state.editor.targetId == template.value._id) continue;      // cannot include template to itself
                if (targetType != 'experience' && template.value.type != 'process') continue;   // exclude widget and screen
                if (this.isSeachCompatible(template.value.name))
                    this.addComponentToTree(tree, 'template-'+template.value.type, template);
            }
        }

        for( let inspector of allInspectors ) {
            //check if the modules' level is array
            const isArray = () => {
              if(Array.isArray(inspector.value.level)){
                return inspector.value.level.includes(targetType);
              }
            }

            if( targetType == 'object' && inspector.value.type == targetType && ( this.getParam('ignoreIsEmbeddable') === true || inspector.value.isEmbeddable ) && inspector.value.canBeCreated !== false ){
                if( this.isSeachCompatible( inspector.value.name ) )
                    this.addComponentToTree( tree, inspector.value.parent , inspector );
            }
            //to avoid the Current experience to be rendered at the project level.
            else if ( targetType === 'project' && inspector.value.type === "space-experience" && inspector.value.level === '*') {  }
            // if experience level
            else if( inspector.value.level === targetType || inspector.value.level === '*' && !isArray()) {
                if( inspector.value.type === "experience" && inspector.value.level != '*') {
                    // For the experience, they are as experiences as targets.
                    for (var i = 0; i < inspector.value.targets.length; i++) {
                        let block = deepCopy( inspector );  // Dupplicate without references
                        block.value.name = inspector.value.targets[i].title;
                        block.value.targets = [ inspector.value.targets[i] ];
                        block.value.parent = inspector.value.targets[i].parent ;

                        if( this.isSeachCompatible( block.value.name ) )
                            this.addComponentToTree( tree, block.value.parent , block );
                    }
                }
                // For the Current experience at the experience level. If at experience level and if the modules should be render at all levels.
                else if ( targetType === 'experience' && inspector.value.level === '*'){
                         tree.children.unshift({
                           name : 'current', title: 'Nodes', icon: 'current'
                         });
                         if( this.isSeachCompatible( inspector.value.name ) )
                             this.addComponentToTree( tree, 'current', inspector);
                 }
                 //For the Current experience at the screen level.
                 else if (targetType === 'screen' && inspector.value.type === "space-experience" && inspector.value.level === '*'){
                   tree.children.unshift({
                     name : 'current', title: 'Nodes', icon: 'current'
                   });
                   if( this.isSeachCompatible( inspector.value.name ) )
                       this.addComponentToTree( tree, 'current', inspector);
                 }
                  else {
                      if( this.isSeachCompatible( inspector.value.name ) )
                          this.addComponentToTree( tree, inspector.value.parent, inspector );
                  }
            }
            // For the Current Screen who need to appear at the screen level and at the process level the level is equal to ['process', 'level'].
            else if (isArray()){
              if(targetType === 'screen'){
                if( this.isSeachCompatible( inspector.value.name ) )
                    this.addComponentToTree( tree, 'current', inspector );
              } else { // render of the modules in the node block if in process.
                if( this.isSeachCompatible( inspector.value.name ) )
                    this.addComponentToTree( tree, inspector.value.parent, inspector );
              }
            }
        }

        // Add note block
        if (targetType === 'process') {
            let noteBlock = this.getInspector('note');
            if (noteBlock && this.isSeachCompatible(noteBlock.value.name)) this.addComponentToTree(tree, noteBlock.value.parent, noteBlock);
        }

        tree = this.clearTreeEmpty( tree.children );

        // filter after the clearTree if process has experience, screen or widget as parent to remove Current XP/Screen/Widget accordingly
        // @todo: this should be done before
        if (targetType === 'process' && tree) {
            const id = this.getParam('id')
            const insideTemplate = state.workspace.target === 'template'
            const process = id ? helper.block.getEntityByIDInWholeProject(state.project, id).value : null
            if (process) {
                tree.forEach((group) => {
                    if(group.children) {
                        group.children.forEach((children) => {
                            if (children.name === "node" && children.components) {
                                const clone = JSON.parse(JSON.stringify(children.components));
                                for (let j = children.components.length-1; j>=0; j--) {
                                    let hide = false;
                                    const btype = children.components[j]._id
                                    switch (btype) {
                                        case "BLOCK_MODULE_SPACE-EXPERIENCE":
                                            hide = insideTemplate || !(process.ref_experience || process.ref_screen || process.ref_widget)
                                            break;
                                        case "BLOCK_MODULE_SPACE-SCREEN":
                                            hide = insideTemplate || !process.ref_screen
                                            break;
                                        case "BLOCK_MODULE_SPACE-WIDGET":
                                            hide = insideTemplate || !(process.ref_screen || process.ref_widget)
                                            break;
                                    }
                                    if (hide) children.components.splice(j, 1)
                                }
                            }
                        });
                    }
                });
            }
        }

        return tree;
    }

}

export default Filter;
