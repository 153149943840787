import React from 'react';
import NodalUi from './NodalUi';

export default class ChartsUi extends NodalUi {

    constructor(){
        super();

        this.chartProps = {};
    }


    setDefaultStyles( nextProps, nextState ){

        super.setDefaultStyles( nextProps, nextState );

        let domain = null;

        if( nextProps.domainX && nextProps.domainX.x != undefined && nextProps.domainX.y != undefined  ){
            domain = { x : [nextProps.domainX.x, nextProps.domainX.y] , y :null };
        }
            
        if( nextProps.domainY && nextProps.domainY.x != undefined && nextProps.domainY.y != undefined  ){
            if( domain === null )
                domain = { x : null, y : null };

            domain.y = [nextProps.domainX.x, nextProps.domainX.y];
        }

        
        let otherChartProps = {
            animate : { duration : this.props.animate },
            //domain : domain
        };

        this.chartProps = Object.assign( {}, this.props.chart, otherChartProps );

    }

}