<!-- Puzzle vue -->

<template>
<svg version="1.1" class="puzzle" viewBox="0 0 192.9 141.9">
	<g id="puzzleSVG" fill="#FFFFFF" stroke="#707070" stroke-width="0" >
		<path class="st0" d="M157.9,0v57.4c0,1.6,0.4,3.1,0.8,4.4l0,0.2c0.6,2.3,1.9,3.9,3.8,4.6c1.8,0.7,3.9,0.4,5.8-0.9
			c2.1-1.4,3.6-2.7,4.7-4.2c2.5-3.2,5.9-4.7,9.3-4.2c3.7,0.5,6.9,3.2,8.7,7.3c3.1,7.3,2.5,13.6-1.9,19.5c-2.1,2.7-4.7,4.3-7.5,4.4
			c-3,0.1-5.9-1.4-8.4-4.2c-1.5-1.7-3.1-3.1-4.7-4.3c-2-1.4-4.1-1.8-6-1.1c-1.9,0.7-3.2,2.4-3.8,4.9l-0.1,0.2
			c-0.3,1.2-0.6,2.6-0.7,4.1v47.8c0,3.3-2.7,6-6,6H6c-3.3,0-6-2.7-6-6V88.1c0-1.4,0.4-2.8,0.7-4.1l0.1-0.2c0.6-2.4,2-4.2,3.8-4.9
			c1.8-0.7,4-0.3,6,1.1c1.7,1.2,3.3,2.6,4.7,4.3c2.4,2.8,5.4,4.3,8.4,4.2c2.8-0.1,5.5-1.7,7.5-4.4c4.4-5.9,5-12.3,1.9-19.5
			c-1.8-4.1-5-6.8-8.7-7.3c-3.4-0.5-6.8,1.1-9.3,4.2c-1.1,1.4-2.7,2.8-4.7,4.2c-1.9,1.3-4,1.6-5.8,0.9c-1.8-0.7-3.2-2.3-3.8-4.6
			l0-0.2C0.4,60.4,0,58.9,0,57.4V0H157.9z"/>
	</g>
</svg>
</template>

<script>

	export default {
		

	}

</script>

<style lang="stylus">

g#puzzleSVG
	fill: var(--moduleBoxColor)

.puzzle-bloc:hover .title
	background-color: var(--selectedColor)

.puzzle-bloc:hover .puzzle-part:after
	background-color: var(--modalColor) !important

.puzzle-part:after
	transition: linear all .1s !important	

</style>



