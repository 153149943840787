import InspectorActionAbstract from './InspectorActionAbstract';
import * as helper from '../../helper' ;

class UpdateMemoryLink extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateMemoryLink';
    }

    /**
     * @param targetId
     * @param newValue
     * @param fieldName 
     */
    run (state) {
        return this.updateFieldValue(state);
    }

    updateFieldValue(state, composite = null) {
        let field, index;
        [field, index] = helper.block.getField(this._target, this.getParam('fieldName'), true);

        let compose = composite || { project: {} };
        compose.project[this._path + '.value.fields[' + index + '].memoryLinked'] = this.getParam('newValue');

        return this.composeState(state, compose);     
    }

        
}

export default UpdateMemoryLink;