import deepCopy from 'deepcopy';

import * as helper from '../../helper';
import ActionAbstrat from '../ActionAbstract';

class ChangeXpTarget extends ActionAbstrat {

    /**
     * @param xpid - the id of the current xp
     * @param target - object representing the client type (aka target) to toggle
     */

    static get ID() {
        return 'actions:project:changeXpTarget';
    }

    run(state) {

        let projectCopy = deepCopy(state.project),
            xp = helper.block.getBlockById(projectCopy, this.getParam('xpid')),
            index;

        index = xp.value.targets.findIndex(t => t.name === this.getParam('target').name);

        if (index !== -1) {
            xp.value.targets.splice(index, 1);
        } else {
            xp.value.targets.push(this.getParam('target'));
        }

        return this.composeState(state, {
            project: projectCopy
        });
    }


}

export default ChangeXpTarget;