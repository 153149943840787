<!-- Curve2DField vue -->

<template lang="pug">

div.curve-fields-ctn
	div.field-ctn
		canvas.curvedraw(v-bind:id="'curve-editor-'+bloc.value._id+'-'+rand", width="150", height="150", v-on:mousedown="onMouseDown", v-on:mousemove="onMouseMove", v-on:mouseup="onMouseUp")
		div.clear
		select(v-if="gotPreset", v-model="preset", v-on:change="presetChange")
			option(v-for="(opt,name) in presets", v-bind:value="name" ) {{ opt.label }}
			option(value="custom") Custom
	div.clear



</template>

<script>

import actionManager from '../../../actions';
import * as helper from '../../../helper';

import * as BezierCurve from 'bezier-curve';

export default {

	components: { },

	props:[ 'field' , 'fieldvalue' , 'bloc' ],

	data() {
		return {
			currentParent : null,
			memoryList: [],
			parentList: [],
			value: null,

			canvas: null,
			width: null,
			height: 100,
			ctx: null,
			points: [ ],
			curve: null,
			rand : Math.random()*Math.random()*10,

			presets: {
				linear : { label:"Linear", controls : [{ x:0.250, y:0.250 }, { x:0.750, y:0.750 } ] },

				quadIn : { label:"Quad. in", controls : [{ x:0.550, y:0.085 }, { x:0.680, y:0.530 } ] },
				cubicIn : { label:"Cubic in", controls : [{ x:0.550, y:0.055 }, { x:0.675, y:0.190 } ] },
				quartIn : { label:"Quart. in", controls : [{ x:0.895, y:0.030 }, { x:0.685, y:0.220 } ] },
				quintIn : { label:"Quint. in", controls : [{ x:0.755, y:0.050 }, { x:0.855, y:0.060 } ] },
				sineIn : { label:"Sine in", controls : [{ x:0.470, y:0.000 }, { x:0.745, y:0.715 } ] },
				expoIn : { label:"Expo. in", controls : [{ x:0.950, y:0.050 }, { x:0.795, y:0.035 } ] },
				circIn : { label:"Circ. in", controls : [{ x:0.600, y:0.040 }, { x:0.980, y:0.335 } ] },

				quadOut : { label:"Quad. out", controls : [{ x:0.250, y:0.460 }, { x:0.450, y:0.940 } ] },
				cubicOut : { label:"Cubic out", controls : [{ x:0.215, y:0.610 }, { x:0.355, y:1.000 } ] },
				quartOut : { label:"Quart. out", controls : [{ x:0.165, y:0.840 }, { x:0.440, y:1.0000 } ] },
				quintOut : { label:"Quint. out", controls : [{ x:0.230, y:1.000 }, { x:0.320, y:1.000 } ] },
				sineOut : { label:"Sine out", controls : [{ x:0.390, y:0.575 }, { x:0.565, y:1.000 } ] },
				expoOut : { label:"Expo. out", controls : [{ x:0.190, y:1.000 }, { x:0.220, y:1.000 } ] },
				circOut : { label:"Circ. in", controls : [{ x:0.075, y:0.820 }, { x:0.165, y:1.000 } ] },

				quadInOut : { label:"Quad. in out", controls : [{ x:0.455, y:0.030 }, { x:0.515, y:0.955 } ] },
				cubicInOut : { label:"Cubic in out", controls : [{ x:0.645, y:0.045 }, { x:0.355, y:1.000 } ] },
				quartInOut : { label:"Quart in out", controls : [{ x:0.770, y:0.000 }, { x:0.175, y:1.000 } ] },
				quintInOut : { label:"Quint in out", controls : [{ x:0.860, y:0.000 }, { x:0.070, y:1.000 } ] },
				SineInOut : { label:"Sine in out", controls : [{ x:0.445, y:0.050 }, { x:0.550, y:0.950 } ] },
				ExpoInOut : { label:"Expo in out", controls : [{ x:1.000, y:0.000 }, { x:0.000, y:1.000 } ] },
				CircInOut : { label:"Circ. in out", controls : [{ x:0.785, y:0.135 }, { x:0.150, y:0.860 } ] }

			},
			gotPreset: false,
			preset: 'custom',

			SqrHitRadius : 0.05, //Math.pow(9, 2),
            draggingPoint : -1,
            draggingType : ''

		}
	},

	created: function(){
		this.value = this.fieldvalue.value ? this.fieldvalue.value : { points : this.points , preset: this.preset };
		if( this.value && this.value.points && this.value.points.length > 0 ){
			this.points = this.value.points;
		}
		if( this.value && this.value.preset ){
			this.preset = this.value.preset;
			this.gotPreset = true;
		}
	},

	mounted: function(){

		this.canvas = document.getElementById("curve-editor-"+this.bloc.value._id+'-'+this.rand);

		if( this.field.curve && this.canvas ){

			this.ctx = this.canvas.getContext("2d");

			let self = this;

			if( this.points.length == 0 ){
				for( let i = 0 ; i < this.field.curve.points.length ; i++ ){
					let initPt = this.field.curve.points[i];
					let pt = { pos : BezierCurve.v2( initPt.x , initPt.y ), in: null, out: null , xFixed: initPt.xFixed, yFixed: initPt.yFixed };
					this.points.push( pt );
				};

				if( this.points.length == 2 && this.field.curve.presets ){
					// preset list activé
					this.preset = self.field.curve.default;
					this.gotPreset = true;
					this.presetChange();
				}
			}

			this.curve = new BezierCurve.Curve(this.points);

			window.addEventListener('resize', this.windowResize);

			this.windowResize();
		}
	},


	destroyed: function(){
		window.removeEventListener('resize', this.windowResize);
	},

	watch:{

		'points': function( newVal, oldVal ){
			this.value.points = this.points;
		}

	},

	methods:{

		windowResize: function(){
			this.width = this.height = this.canvas.offsetWidth;

			this.drawCanvas();
		},



		presetChange: function(){
			if( this.preset && this.presets[this.preset] ){
				let controls = this.presets[this.preset].controls;
				this.points[0].pos = { x : 0 , y : 0 };
				this.points[1].pos = { x : 1 , y : 1 };
				this.points[0].out = BezierCurve.v2( controls[0].x, controls[0].y );
				this.points[1].in = BezierCurve.v2( controls[1].x, controls[1].y );

				this.value.preset = this.preset;
			}
			this.valueUpdated();

			this.curve = new BezierCurve.Curve(this.points);
			this.drawCanvas();
		},

		valueUpdated: function( ){
			this.value.preset = this.preset;
			this.$parent.valueUpdated( this.field, this.value );
		},

		drawCanvas: function(){

			this.ctx.clearRect(0, 0, this.width, this.height);
			let CurvePointStrokeColor = "#000";
			let ControlPointStrokeColor = "#090";
			let PointFillColor = "rgba(200,200,200,0.5)";
			let CurveColor = "#44F";
			// curve points
			let i;
			for (i = 0; i < this.curve.points.length; i++) {
				let point = this.curve.points[i];
				this.drawPoint(point.pos, CurvePointStrokeColor, PointFillColor);
			}
			for (i = 0; i < this.curve.beziers.length; i++) {
				let bezier = this.curve.beziers[i];
				// control points
				this.drawPoint(bezier.startCtrlPoint, ControlPointStrokeColor, PointFillColor);
				this.drawPoint(bezier.endCtrlPoint, ControlPointStrokeColor, PointFillColor);
				this.drawLine(bezier.start, bezier.startCtrlPoint);
				this.drawLine(bezier.end, bezier.endCtrlPoint);
			}

			let speed = 0.05;
			let bezierProgress = 0;
			let bezierIndex = 0;
			let prev = null;
			while (bezierProgress < 1) {
				bezierProgress += speed;
				if (bezierProgress > this.curve.progresses[bezierIndex]) {
					bezierIndex++;
					bezierIndex %= this.curve.beziers.length;
				}
				var realProgress = (bezierProgress - (bezierIndex > 0 ? this.curve.progresses[bezierIndex - 1] : 0) ) / this.curve.ratios[bezierIndex];
				var pos = this.curve.beziers[bezierIndex].getPointAt(realProgress);

				if( realProgress < 1 && prev )
					this.drawLine( prev , pos );

				prev = pos;
			}

		},

		getRefX( x ){
			return (x)*this.width;
		},

		getRefY( y ){
			return (1-y)*this.height;
		},

		drawPoint: function(pos, strokeColor, fillColor, radius) {
			radius = radius || 5;
			this.ctx.lineWidth = 1;
			this.ctx.strokeStyle = strokeColor;
			this.ctx.fillStyle = fillColor;
			this.ctx.beginPath();
			this.ctx.arc( this.getRefX(pos.x), this.getRefY(pos.y), radius, 0, Math.PI * 2, true);
			this.ctx.fill();
			this.ctx.stroke();
		},

		drawLine : function(from, to) {
			this.ctx.lineWidth = 1;
			this.ctx.strokeStyle = "#000";
			this.ctx.beginPath();
			this.ctx.moveTo( this.getRefX(from.x) , this.getRefY(from.y) );
			this.ctx.lineTo( this.getRefX(to.x) , this.getRefY(to.y) );
			this.ctx.stroke();
		},

		getMousePos: function( evt ) {
			return BezierCurve.v2( (evt.offsetX/this.width), 1-(evt.offsetY/this.height) );
		},

		onMouseDown: function( evt ){
			let mousePos = this.getMousePos( evt );

			for (let i = 0; i < this.curve.points.length; i++) {
				let point = this.curve.points[i];

				let dis = BezierCurve.v2.sqrDistance(point.pos, mousePos);
				if (dis < this.SqrHitRadius) {
					this.draggingPoint = i;
					this.draggingType = 'pos';
					return;
				}
				if (point.in) {
					dis = BezierCurve.v2.sqrDistance(point.in, mousePos);
					if (dis < this.SqrHitRadius) {
						this.draggingPoint = i;
						this.draggingType = 'in';
						return;
					}
				}
				if (point.out) {
					dis = BezierCurve.v2.sqrDistance(point.out, mousePos);
					if (dis < this.SqrHitRadius) {
						this.draggingPoint = i;
						this.draggingType = 'out';
						return;
					}
				}
			}
		},

		onMouseMove: function( evt ){

			if (this.draggingType) {
				this.preset = "custom";

				let mousePos = this.getMousePos( evt );

				let point = this.curve.points[this.draggingPoint];
				let oldPos = point[this.draggingType];

				if( this.draggingType == "pos" && this.points[this.draggingPoint].xFixed === true ){
					mousePos.x = oldPos.x;
				}

				if( this.draggingType == "pos" && this.points[this.draggingPoint].yFixed === true ){
					mousePos.y = oldPos.y;
				}

				point[this.draggingType] = mousePos;

				this.keepSmooth(point, mousePos, oldPos);

				this.curve.computeBeziers();
				this.drawCanvas();
			}
		},

		onMouseUp: function( evt ){

			if (this.draggingType) {
				this.valueUpdated();
				this.draggingType = '';
				this.draggingPoint = -1;
				evt.stopImmediatePropagation();
			}
		},


		keepSmooth : function(point, mousePos, oldPos) {
			if (this.draggingType === 'pos') {
				let delta = BezierCurve.v2.sub(mousePos, oldPos);
				if (point.in) {
					point.in = BezierCurve.v2.add(point.in, delta);
				}
				if (point.out) {
					point.out = BezierCurve.v2.add(point.out, delta);
				}
			}
			else {
				let another;
				let anotherType;
				if (this.draggingType === 'in') {
					anotherType = 'out';
				}
				else if (this.draggingType === 'out') {
					anotherType = 'in';
				}
				another = point[anotherType];
				if (another) {
					let dir = BezierCurve.v2.sub(point.pos, mousePos);
					let len = BezierCurve.v2.distance(mousePos, point.pos);
					if (len > 0.01) {
						dir = BezierCurve.v2.div(dir, len);
						let anotherLen = BezierCurve.v2.distance(another, point.pos);
						dir = BezierCurve.v2.mul(dir, anotherLen);
						point[anotherType] = BezierCurve.v2.add(dir, point.pos);
					}
				}
			}
		}


	}



}

</script>

<style lang="stylus">

.curve-fields-ctn
	border-top: 1px solid rgba(0,0,0,0.3)
	padding-top: 8px
	margin-top: 2px
	padding-bottom: 5px

	.field-ctn
		position : relative

		span.text
			line-height : 20px

		.input-fields.groupfield
			line-height : 25px

	.noresult
		font-size: 14px
		line-height: 40px

	.curvedraw
		border 1px solid black


</style>
