import UiInputFields from '../UiInputFields';
import { STATE_CHANGE } from '../UiObject';

// /**
//  * @@deprecated since version 3.2.0, use the InputText instead.
//  */
export const DEPRECATED = true;

class UiTextfield extends UiInputFields {
    constructor(id, props) {
        super(id, props, 'InputTextField');
    }

    static getAuthConfig() {
        return {
            exportFormat: 'ui-textfield',
            label: 'TextField',
            parent: 'ui-inputs',
            isDisplayed: true,
            deprecated: true,
            isEmbeddable: true,
        };
    }

    initParameters() {
        super.initParameters();

        const otherParameters = {
            // Add param to positionning common settings
            value: {
                type: 'String',
                default: '',
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: true },
                },
                auth: {
                    label: 'Value',
                    container: 'input-global-fields',
                    description: "Specifies the value of the Input Field."
                },
            },

            type: {
                type: 'String',
                default: 'text',
                partial: 'input',
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Type',
                    container: 'input-global-fields',
                    widget: 'select',
                    options: [{ value: 'text', label: 'text' }, { value: 'password', label: 'password' }, { value: 'number', label: 'number' }],
                },
            },

            defaultValue: {
                type: 'String',
                default: '',
                partial: 'input',
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Default value',
                    container: 'input-global-fields',
                },
            },

            maxLength: {
                type: 'Int',
                default: null,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                deprecated: true,
                auth: {
                    label: 'Max length',
                    container: 'input-global-fields',
                    description: "Maximum length of the text.",
                },
            },

            rows: {
                type: 'Int',
                default: 1,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Rows',
                    container: 'input-style-fields',
                },
            },
            rowsMax: {
                type: 'Int',
                default: null,
                partial: 'input',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Max rows',
                    container: 'input-style-fields',
                },
            },

            fontSize: {
                type: 'Int',
                default: 13,
                partial: 'commonStyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Font size',
                    container: 'general-fields',
                    unit: 'px',
                },
            },
            textAlign: {
                type: 'String',
                default: 'left',
                partial: 'commonStyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Text align',
                    container: 'general-fields',
                    widget: 'select',
                    options: [{ value: 'left', label: 'left' }, { value: 'center', label: 'center' }, { value: 'right', label: 'right' }],
                },
            },
            lineHeight: {
                type: 'Int',
                default: null,
                partial: 'commonStyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Line height',
                    container: 'general-fields',
                    unit: 'px',
                },
            },
            letterSpacing: {
                type: 'Int',
                default: 0,
                partial: 'commonStyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Letter spacing',
                    container: 'general-fields',
                    unit: 'px',
                },
            },
        };

        this.addToParameters(otherParameters);

        this.disableParam('height');
    }

    // HACK:TODO: check nodal-app requirements and prefers
    // replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init(); }

    init() {
        super.init();
    }

    set(prop, value, partial = false) {
        super.set(prop, value, partial);

        if (prop === 'value') {
            this.set('newValue', value);
            // this.emit(STATE_CHANGE, this.render());
            setTimeout(() => {
                this.set('newValue', null);
                this.emit(STATE_CHANGE, this.render());
            }, 10);
        }
    }
}

export default UiTextfield;
