<!-- Memory vue -->

<template lang="pug">
div.accordion.targets-bloc.widget-manager
	div.accordion-title(v-on:click.stop="accordion = !accordion ")
		span.text Widget Visibility Manager
		span.arrow(v-bind:class="{'close':!accordion}")

	div.form-content(v-bind:class="{'open':accordion}")

		div.form-accordion.isAccordion()

			div.title 
				span.text Widgets list

			div.form-accordion-content.open.widget-visibility.widgetSelection

				span.textinput-ctn.trigger-select(v-for="(w,index) in widgetslist")
					span.text {{w.value.name}}
					span.bt-order.bt-top(v-on:click.stop="goUp(w.value._id)", v-bind:class="{'disabled':index==0}") &nbsp;
					span.bt-order.bt-bottom(v-on:click.stop="goDown(w.value._id)", v-bind:class="{'disabled':index==widgetslist.length-1}") &nbsp;
					span.checkbox(v-if="w.value.type == 'widget'", v-bind:class="{'selected': activated.indexOf(w.value._id) != -1 }", v-on:click.stop="toggleValue(w.value._id)")
						input(type="checkbox")

	div.clear(style="height:10px")
								
</template>

<script>

import actionManager from '../../../actions';
import * as helper from '../../../helper';

export default {

	props:[ 'project', 'target' ],

	data() {
		return {
			accordion: true
		}
	},

	computed: {

		'screenParent': function(){
			if( this.target.value && this.target.value.ref_block )
				return helper.block.getBlockById( this.project, this.target.value.ref_block );
			return null;
		},

		'xpParent': function(){
			if( this.screenParent && this.screenParent.value && this.screenParent.value.ref_experience )
				return helper.block.getBlockById( this.project, this.screenParent.value.ref_experience );
			return null;
		},

		'widgetslist': function(){
			let list = [ ];
			if( this.xpParent && this.xpParent.custom.children ){
				list = this.xpParent.custom.children.filter( ( c ) => { if ( c.value.type === "widget" ) return c ; return null } );
			}
			if( this.screenParent && this.screenParent.value.widgetsOrder ){

				list.push( this.screenParent );

				let funcOrder = ( w1 , w2 ) => { 
					let index1 = this.screenParent.value.widgetsOrder.indexOf( w1.value._id );
					let index2 = this.screenParent.value.widgetsOrder.indexOf( w2.value._id );
					if( index1 < index2 ) return -1;
					if( index1 > index2 ) return 1;
					return 0;
				};
				list = list.sort( funcOrder );
			}
			
			return list;
		},

		'activated': function(){
			if( this.screenParent && this.screenParent.value.widgetsActivated ){
				return this.screenParent.value.widgetsActivated;
			}
			return [];
		}



	},

	created: function(){

		// order unknown widgets
		actionManager.trigger('inspector:widgets:updateWidgetOrder', { subaction: "init", screenId: this.screenParent.value._id });

	},

	methods:{

		toggleValue: function( widgetId ) {
			let current = this.activated.indexOf(widgetId) ;
			let newStatus = current === -1 ? true : false;
			actionManager.trigger('inspector:widgets:updateWidgetStatus', { screenId: this.screenParent.value._id, widgetId: widgetId, activated : newStatus });
		},

		goUp: function( widgetId ){
			actionManager.trigger('inspector:widgets:updateWidgetOrder', { subaction: "up", screenId: this.screenParent.value._id, widgetId: widgetId });
		},

		goDown: function( widgetId ){
			actionManager.trigger('inspector:widgets:updateWidgetOrder', { subaction: "down", screenId: this.screenParent.value._id, widgetId: widgetId });
		}

	}

}

</script>

<style lang="stylus">
.textinput-ctn
	.checkbox
		float: right
		margin-top : 2px
		margin-right: 2px

.accordion .form-content .form-accordion .form-accordion-content.widget-visibility
	margin : 5px
	background: var(--inputValue)
	border-radius: 4px
	color: var(--inputTextColor)
	font-size: 14px

.bt-order
	width: 20px;
	height: 21px;
	display: inline-block;
	float: right;
	text-align: center;
	cursor: pointer;

	&.disabled 
		opacity : 0.3

span.textinput-ctn.trigger-select 
	background: var(--inspectorTargetColor)
	border-radius: 4px
	padding-left: 5px
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis
	width: calc(100% - 5px)!important
	height: auto
	margin-left: 0px

span.textinput-ctn.trigger-select.parentScreen
	background: var(--inspectorTargetColor)
	margin: 5px
	width: calc(100% - 15px)!important
	border-radius: 4px
	height: 40px
	line-height: 40px
	color: var(--inputTextColor)
	font-size: 14px
	font-family: 'DinBold'
	.checkbox
		margin-right: 5px

.widget-manager
	margin-bottom: -10px !important
	.accordion
		margin-bottom: -10px
		
	.widget-visibility
		background: transparent !important

</style>



