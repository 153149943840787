import UiObject from '../UiObject';

class UiLayout2Col extends UiObject {

    constructor(id, props) {
        super(id, props, 'MultiColumns');

    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-layout-2-col",
            label: "Layout 2 Cols",
            parent : "ui-layouts",
            isDisplayed : true,
            isEmbeddable: true,
            deprecated: true,
            nbSection: 2,
            childrenType: 'ui'
        };
    }


    initParameters(){
        super.initParameters();

        let otherParameters = {
            // Add param to positionning common settings
            textAlign: {
                type: 'String',
                default: "center",
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth : {
                    label: "Content alignment",
                    container: "pos-fields",
                    widget: 'select',
                    options: [ {value:'left', label:'left'}, {value:'center', label:'center'}, {value:'right', label:'right'} ],
                    description: "Sets the horizontal alignment of the UI. Inherit: same alignment as the parent / Left: aligned to the left / Center: aligned to the center / Right: aligned to the right."
                }
            }
        };

        this.addToParameters( otherParameters );

        this.setDefaultValue( 'width', '100%' );
    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }

    init(){
        super.init();

        this.set( 'cols', [ [ ] , [ ] ] );
    }


    _initChild() {
        super._initChild();
        // to do : repartir les child id dans les cols
        this.set( 'cols', this.childrenIdByCols );
    }


    addChild(child, section=null, pos = null) {
        this.disableStateEvent();
        super.addChild(child);

        let cols = this.get('cols');

        cols[section].push(child.id);

        this.enableStateEvent();
        this.set('cols', cols);

    }

}

export default UiLayout2Col;
