import ActionAbstract from '../ActionAbstract'

class Delete extends ActionAbstract {
    
    static get ID() {
        return 'actions:template:delete'
    }
    
    run(state) {
        let doc, url
        
        switch (this.getParam('action')) {

            case "success":
                this.trigger('template:listAll')
                return false

            case "error":
                return this.composeState(state, {
                    isLoading: false,
                    templates: {
                        error: {
                            code: this.getParam('code'),
                            message: this.getParam('message'),
                            action: 'delete'
                        }
                    }
                })

            case "destroydb":
                this.deps.mainStore.template.deleteDB(this.getParam('id')).then(
                    response => this.trigger('template:delete', { action: 'success' }),
                    error => {
                        error.action = "error"
                        this.trigger('template:delete', error)
                    }
                )
                return false
            
            default:
                doc = 'ref_' + this.getParam('id')
                url = this.deps.mainStore.config.baseURLAuth
                this.deps.mainStore.block.deleteDocument(doc, url).then(
                    response => this.trigger('template:delete', { action: 'destroydb', id: this.getParam('id') }),
                    error => this.trigger('template:delete', { action: 'error', code: error.status, message: error.statusText })
                ) 
                
                return this.composeState(state, {
                    templates: {
                        waiting: true
                    }
                })
        }
    }
}

export default Delete