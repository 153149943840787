<!-- ArrayRow Fields vue -->

<template lang="pug">
div.accordion.topic
	div.accordion-title(v-on:click.stop="topicAccordion = !topicAccordion")
		span.text Array Access
		span.arrow(v-bind:class="{'close':!topicAccordion}")

	div.form-content(v-bind:class="{'open':topicAccordion}")
		div.children
			div.form-accordion.array-row-fields.group.isAccordion
				div.title(v-on:click.stop="accordion = !accordion")
					span.text Array Access
					span.arrow(v-bind:class="{'close':!accordion}")

					div.legend
						span default
						span in
						span out


				div.form-accordion-content(v-bind:class="{'open':accordion}")
					div.big-bt.field-group

						field(v-for="field of fields", :field="field", :bloc="target", :groupfield="true", :target="target")

</template>

<script>
import { stores } from '../../../stores/MainStore.js';
import * as helper from '../../../helper';
import FieldManager from  '../fields/FieldManager.vue';

export default {

	components: { field: FieldManager },

	props:[ 'target', 'bloc'/*, 'values'*/ ],

	data() {
		return {
			topicAccordion: true,
			accordion: true,
			memoryTarget : null,
			arrowValues: [],
			currentMemoryID : null
		}
	},

	computed: {
		fields: function() {

			let memId = helper.block.getField(this.target, this.bloc.target).value;
			let fields = this.target.value.fields; 
			let result = [];

			Object.keys(this.target.custom.fields).filter( (fName) => {
				return fName.indexOf(memId) == 0
			}).forEach( (fName, i) => {
				result.push(Object.assign({}, this.target.custom.fields[fName]));
			});

			return result;
		}
	},

	watch: {
		// 'values[bloc.target].value': function( newID, oldID ){
		// 	this.removeOldValues( oldID );
		// 	this.getTarget();
		// }
	},

	created: function(){
		// this.getTarget();

		// stores.memory.on('ArrayColUpdated', this.colUpdatedHandler );
	},

	destroyed: function(){
		// stores.memory.removeListener('ArrayColUpdated', this.colUpdatedHandler );
	},

	methods:{

		// getTarget: function( ){
		// 	if( this.bloc.target && this.values && this.values[this.bloc.target] && this.values[this.bloc.target].value ){
		// 		this.getCols( this.values[this.bloc.target].value );
		// 	}
		// },

		// getCols: function( arrayID ){
		// 	let array = this.getArrayRowCols( arrayID );

		// 	this.fields = [];
		// 	this.arrowValues = [];
		// 	this.currentMemoryID = this.values[this.bloc.target].value;

		// 	if( array && array.header && Array.isArray( array.header )){
		// 		for (var i = 0; i < array.header.length; i++) {

		// 			// On cherche les valeur déja défini pour ces colonnes
		// 			let field = stores.block.getField( this.target, this.currentMemoryID+"_"+i);

		// 			if( field ){ // On les reactive si elles existent et qu'on a pas forcer la remise à 0
		// 				this.arrowValues[this.currentMemoryID+"_"+i] = field; 
		// 			}
		// 			else{
		// 				// on initialise la valeur a true pour in et out si les valeurs existent pas ou si on force un refresh
		// 				this.target.value.fields.push(  this.createColValue( this.currentMemoryID+"_"+i ) );
		// 				let field = stores.block.getField( this.target, this.currentMemoryID+"_"+i);
		// 				this.arrowValues[this.currentMemoryID+"_"+i] = field;
		// 			}

		// 			// On ajoute le champ à l'inspector
		// 			this.fields.push( this.createArrayRowColField( this.currentMemoryID+"_"+i , array.header[i].name ) );
		// 		}

		// 		stores.block.triggerConnectionChange( {blocID: this.target.value._id} );
		// 	}	


		// },

		// removeOldValues: function( arrayID ){

		// 	this.fields = [];
		// 	this.arrowValues = [];

		// 	// Delete all link to previous columns's array
		// 	stores.block.deleteLinkToBloc( this.target );

		// 	let array = this.getArrayRowCols( arrayID );
		// 	let memoryID = this.values[this.bloc.target].value;

		// 	if( array && array.header && Array.isArray( array.header )){
		// 		for (var i = 0; i < array.header.length; i++) {
		// 			stores.block.removeField( this.target, this.currentMemoryID+"_"+i);
		// 		}
		// 	}

		// 	this.currentMemoryID = null;

		// },

		// getFieldIndex: function( name ){
		// 	let cpt = 0;
		// 	if( this.target.value.fields && this.target.value.fields.length > 0 ){
		// 		for( let field of this.target.value.fields ){
		// 			if( field.name == name )
		// 				return cpt;
		// 			cpt++;
		// 		}
		// 	}
		// 	return -1;
		// },

		// createColValue: function( name ){
		// 	return {
		// 		name: name,
		// 		value: null,
		// 		pluggable : { in: true, out: true },
		// 		in: true, 
		// 		out: true
		// 	}
		// },


		// colUpdatedHandler: function( memoryId ){
		// 	if( memoryId == this.values[this.bloc.target].value )
		// 		this.getCols( this.values[this.bloc.target].value );
		// }
		
	}

}

</script>

<style lang="stylus">

.array-row-fields
	//padding: 8px 4px 8px 8px

</style>



