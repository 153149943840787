var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.display
    ? _c(
        "div",
        {
          staticClass: "field-ctn",
          class: [
            _vm.field.type,
            { isDeprecated: _vm.field.deprecated },
            { "invisible-setting": _vm.field.invisible },
            {
              notApply:
                (_vm.isTemplate || _vm.includedQueryField) && !_vm.applyField
            }
          ]
        },
        [
          _c(
            "p",
            {
              staticClass: "text-hover",
              class: { isTooLong: _vm.dynlabel && _vm.dynlabel.length > 18 }
            },
            [_vm._v(_vm._s(_vm.dynlabel))]
          ),
          _vm.field.description
            ? _c(
                "span",
                {
                  staticClass: "description-bull",
                  class: { buildOnly: _vm.field.buildOnly },
                  on: { click: _vm.displayDescription }
                },
                [_vm._v(_vm._s(_vm.field.buildOnly ? "!" : "?") + " ")]
              )
            : _vm._e(),
          _vm.field.resume
            ? _c(
                "span",
                {
                  staticClass: "description-bull2",
                  on: { click: _vm.displayResume }
                },
                [_vm._v("?")]
              )
            : _vm._e(),
          _vm.includedQueryField
            ? _c("div", {
                staticClass: "btApply",
                class: { notApply: !_vm.applyField },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.toggleApplyField()
                  }
                }
              })
            : _vm._e(),
          _vm.widget == "memory"
            ? _c(
                "div",
                { staticClass: "field-wrapper" },
                [
                  _c("memory-field", {
                    attrs: {
                      field: _vm.field,
                      fieldvalue: _vm.value,
                      bloc: _vm.bloc
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widget == "curve2d"
            ? _c(
                "div",
                { staticClass: "field-wrapper" },
                [
                  _c("curve", {
                    attrs: {
                      field: _vm.field,
                      fieldvalue: _vm.value,
                      bloc: _vm.bloc
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widget == "object"
            ? _c(
                "div",
                { staticClass: "field-wrapper" },
                [
                  _c("object-field", {
                    attrs: {
                      field: _vm.field,
                      fieldvalue: _vm.value,
                      bloc: _vm.bloc
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.widget == "geo"
            ? _c(
                "div",
                { staticClass: "field-wrapper" },
                [
                  _c("geo-field", {
                    attrs: {
                      field: _vm.field,
                      fieldvalue: _vm.value,
                      bloc: _vm.bloc,
                      dynlabel: _vm.dynlabel,
                      "connection-target": _vm.connectionTarget,
                      "connection-change-handler": _vm.connectionChangeHandler,
                      geomodalState: _vm.state.main.geomodal
                    }
                  })
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "field-wrapper" },
                [
                  _vm.dynlabel && _vm.widget !== "memory"
                    ? _c(
                        "span",
                        {
                          staticClass: "text",
                          class: { disabled: _vm.field.disabled }
                        },
                        [_vm._v(_vm._s(_vm.dynlabel))]
                      )
                    : _vm._e(),
                  _vm.widget == "textarea"
                    ? _c("textarea-field", {
                        attrs: {
                          field: _vm.field,
                          fieldvalue: _vm.value,
                          placeholder: _vm.placeholder
                        }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class: {
                        "param-ctn":
                          _vm.field.type == "param" && !_vm.groupfield,
                        "input-fields":
                          _vm.field.type == "field" || _vm.groupfield,
                        groupfield: _vm.groupfield,
                        "memory-link-field": _vm.hasMemoryLink,
                        "textarea-outputs": _vm.widget == "textarea",
                        disabled: _vm.field.disabled
                      }
                    },
                    [
                      _vm.widget == "boolean"
                        ? _c("boolean-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "string"
                        ? _c("string-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "jsontype"
                        ? _c("json-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              bloc: _vm.bloc,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "geoshape"
                        ? _c("string-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: "{GeoJSON}"
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "shape2d"
                        ? _c("string-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: "[[x,y],[x,y], … [x,y]]"
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "number" || _vm.widget == "float"
                        ? _c("float-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "int"
                        ? _c("int-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "displayunit"
                        ? _c("display-unit-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "percent"
                        ? _c("percent-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "angle"
                        ? _c("angle-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "clock"
                        ? _c("clock-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              timemetric: _vm.inspectorOption
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "color"
                        ? _c("color-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              bloc: _vm.bloc,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "select"
                        ? _c("select-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "vector2"
                        ? _c("vector2d", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "vector3"
                        ? _c("vector3d", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "euler"
                        ? _c("euler-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "array"
                        ? _c("array-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              bloc: _vm.bloc,
                              placeholder: _vm.placeholder,
                              inspectorField: _vm.inspectorField,
                              target: _vm.target
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "asset"
                        ? _c("asset-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              bloc: _vm.bloc,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "secret"
                        ? _c("secret-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              bloc: _vm.bloc,
                              placeholder: _vm.placeholder,
                              user: _vm.state.user
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "select-node"
                        ? _c("select-node-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              bloc: _vm.bloc
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "select-template-id"
                        ? _c("select-template-field", {
                            attrs: {
                              field: _vm.field,
                              referent: _vm.value,
                              bloc: _vm.bloc
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "select-template-name"
                        ? _c("select-template-field", {
                            attrs: {
                              field: _vm.field,
                              referent: _vm.value,
                              bloc: _vm.bloc
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "calculated"
                        ? _c("calculated-field")
                        : _vm._e(),
                      _vm.widget == "timestamp"
                        ? _c("date-time-picker-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.widget == "session-pattern-list"
                        ? _c("session-pattern-list-field", {
                            attrs: {
                              field: _vm.field,
                              fieldvalue: _vm.value,
                              placeholder: _vm.placeholder
                            }
                          })
                        : _vm._e(),
                      _vm.field.type == "field" && !_vm.ui
                        ? _c("span", [
                            _c(
                              "span",
                              {
                                staticClass: "plug checkbox",
                                class: {
                                  disabled:
                                    !_vm.field.connection ||
                                    !_vm.field.connection.in.pluggable ||
                                    _vm.field.connection.in.disabled,
                                  selected: _vm.connectionTarget.in
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.connectionChangeHandler(
                                      _vm.field,
                                      "in"
                                    )
                                  }
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.connectionTarget.in,
                                      expression: "connectionTarget.in"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.connectionTarget.in
                                    )
                                      ? _vm._i(_vm.connectionTarget.in, null) >
                                        -1
                                      : _vm.connectionTarget.in
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.connectionTarget.in,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.connectionTarget,
                                              "in",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.connectionTarget,
                                              "in",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.connectionTarget,
                                          "in",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                })
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "plug checkbox",
                                class: {
                                  disabled:
                                    !_vm.field.connection ||
                                    !_vm.field.connection.out.pluggable ||
                                    _vm.field.connection.out.disabled,
                                  selected: _vm.connectionTarget.out
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.connectionChangeHandler(
                                      _vm.field,
                                      "out"
                                    )
                                  }
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.connectionTarget.out,
                                      expression: "connectionTarget.out"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.connectionTarget.out
                                    )
                                      ? _vm._i(_vm.connectionTarget.out, null) >
                                        -1
                                      : _vm.connectionTarget.out
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.connectionTarget.out,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.connectionTarget,
                                              "out",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.connectionTarget,
                                              "out",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.connectionTarget,
                                          "out",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                })
                              ]
                            ),
                            _c("div", { staticClass: "clear" })
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "clear" }),
                      _vm.hasMemoryLink
                        ? _c(
                            "div",
                            {
                              staticClass: "rond-bts-ctn memory-link",
                              class: { activated: _vm.memLinkActivated }
                            },
                            [
                              _c("div", { staticClass: "action-bts" }, [
                                _c("div", {
                                  staticClass: "action-bt bt-link",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.toggleMemoryLink()
                                    }
                                  }
                                })
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("div", { staticClass: "clear" })
                ],
                1
              ),
          _c("div", { staticClass: "clear" }),
          _vm.hasMemoryLink && _vm.memLinkActivated
            ? _c(
                "div",
                { staticClass: "field-wrapper" },
                [
                  _c("memory-field", {
                    attrs: {
                      field: _vm.field,
                      fieldvalue: _vm.value,
                      bloc: _vm.bloc,
                      "mem-link": _vm.field.memoryLink
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }