<!-- UI Editor vue -->
<template lang="pug">

div.ui-editor

	div.cover(v-on:click.stop="clickDrop()")

	div.ratio-btns
		div.btn(v-bind:class="{'active' : ratio == 'desktop'}", v-on:click="ratio='desktop'") desktop
		div.btn(v-bind:class="{'active' : ratio == 'tablet'}", v-on:click="ratio='tablet'") tablet
		div.btn(v-bind:class="{'active' : ratio == 'mobile'}", v-on:click="ratio='mobile'") mobile
		div.btn(v-bind:class="{'active' : ratio == 'custom'}", v-on:click="ratio='custom'") custom

	div.ratio-btns(style="top:130px", v-if="ratio == 'custom'")
		div.btnSize
			div.btn
				span width
				input(v-model="screens.custom.width" )
			div.btn
				span height
				input(v-model="screens.custom.height" )

	div.ui-ctn(v-bind:style="{'transform': 'translate(-50%, -50%) scale('+scale.x+','+scale.y+')', 'width' : screens[ratio].width+'px' , 'height' : screens[ratio].height+'px' }", v-bind:id="'react-renderer-'+screenId")


</template>
<script>

// STORE
import { stores } from '../../../stores/MainStore.js';
import serviceManager from '../../../services';
import actionManager from '../../../actions';
import * as helper from '../../../helper';


import { factory } from '../../../../nodal-ui/reactRenderer';
import UiObject, { STATE_CHANGE } from '../../../../nodal-ui/ui-objects/UiObject';

export default {

	props: [ 'target', 'appState' ],

	data() {
		return {
			dragging: false,
			onDropZone: false,
			currentDropZone: null,
			currentSection: -1,
			screenId: null,
			currentState: null,
			ratio: null,
			prevTime : 0,
			pollFreq : 30,
			screens: {
				desktop : 	{ width : 1680, height : 1024 },
				tablet : 	{ width : 768, height : 1024 },
				mobile : 	{ width : 320, height : 568 },
				custom : 	{ width : 1680, height : 1024 }
			}
		}
	},

	created: function(){


		this.reactRenderer = factory();

		this.screenId = this.target.value._id;

		let custom = this.appState.editor.customPreview ? this.appState.editor.customPreview[this.screenId] : null ;

		if( custom && custom.width && custom.height ){
			this.screens.custom.width = custom.width;
			this.screens.custom.height = custom.height;
			this.ratio = "custom";
		}
		else{
			this.ratio = "mobile";
		}

		this.reactRenderer.setProp( 'auth', { callback : this.rendererCallback.bind(this) }, true );
		this.reactRenderer.setProp( 'auth', { dragging : this.dragging }, true );
		this.reactRenderer.setProp( "forcedDisplayMode" , this.ratio );

		this.stores = serviceManager.get('stores:MainStore');
		this.reactRenderer.setProp('deprecated', this.stores.config.deprecated)

		this.updateSelected();
	},

	mounted: function(){

		this.initRenderer();
		actionManager.trigger('editor:ui:initUiState', { targetId : this.target.value._id });

		this.screen = this.stores.ui.get( this.screenId );
		if( this.screen ){
			this.screen.addListener(STATE_CHANGE, this.stateChanged );
		}
	},


	destroyed: function(){
		this.reactRenderer.unmount();
		if( this.screen ){
			this.screen.removeListener(STATE_CHANGE, this.stateChanged );
		}
	},

	computed: {

		'uiState' : function(){
			if( this.screenId && this.appState.editor && this.appState.editor.uiState && this.currentState != this.appState.editor.uiState ){
				this.currentState = this.appState.editor.uiState;
				//this.reactRenderer.render( this.appState.editor.uiState );
				return this.appState.editor.uiState;
			}
			return null;
		},

		'currentEditor': function(){
			let val = 0;
			if( this.appState.main.inspectorOpen && this.appState.inspector && this.appState.inspector.targetId ){
				val = this.appState.inspector.targetId;
			}

			return val;
		},

		'scale': function(){

			let windowW = ( window.innerWidth - this.appState.editor.origin.x ) * 0.8;
			let windowH = ( window.innerHeight - this.appState.editor.origin.y ) * 0.8;
			let size = this.screens[this.ratio];

			if( this.ratio == "custom" )
				actionManager.trigger("editor:ui:update-custom-preview", { id : this.screenId , customPreview : { width : this.screens.custom.width, height : this.screens.custom.height } } );
			else
				actionManager.trigger("editor:ui:update-custom-preview", { id : this.screenId , customPreview : null } );

			let scaleH = 1;
			let scaleW = 1;
			if( size.width >= size.height && size.width > windowW ){
				scaleW = windowW / size.width;
			}
			if( size.width < size.height && size.height > windowH ){
				scaleH = windowH / size.height;
			}

			let scale = Math.min(scaleH, scaleW);

			this.reactRenderer.setProp( "forcedRatio" , this.screens[this.ratio] );
			return { x : scale , y : scale };
		}
	},

	watch: {

		// DEADCODE ?
		// 'appState.editor.menu.dragging' : function( newVal, oldVal ){
		// 	this.reactRenderer.setProp( 'auth', { dragging : newVal }, true );
		// },

		'currentEditor': function( newVal, oldVal ){
			this.updateSelected();
		},

		'uiState': function( newVal, oldVal ){
			if( oldVal && newVal == oldVal ){ // && newVal.id == this.screenId ){
				this.reactRenderer.render( newVal );
			}
		},

		'ratio': function( newVal, oldVal ){
			this.reactRenderer.setProp( "forcedDisplayMode" , newVal );
			this.reactRenderer.render( this.uiState );
		}

	},


	methods:{

		initRenderer() {
			this.reactRenderer.setContainer('#react-renderer-'+this.screenId);
			//this.reactRenderer.render(  this.appState.editor.uiState );
		},

		clickDrop: function(){
			actionManager.trigger('main:deselectAll');
		},

		stateChanged: function(){
			actionManager.trigger('editor:ui:updateUiState');
		},

		updateSelected: function(){

			let val = false;
			if( this.appState.main.inspectorOpen && this.appState.inspector && this.appState.inspector.targetId ){
				val = this.appState.inspector.targetId;
			}

			this.reactRenderer.setProp( 'auth', { selected : val }, true );
			if (this.screen) {
				actionManager.trigger('editor:ui:updateUiState');
			}
		},

		getTimestamp: function(){
			var d = new Date();
			var n = d.getTime();
			return n;
		},

		rendererCallback: function( feedback ){
			// feedback is an object with a state ('select', 'over') and the id of the target + a section number eventually
			if( feedback && feedback.id && feedback.state ){
				// MOUSE OVER EVENT
				if( feedback.state === 'over' && this.appState.editor.menu.dragging ){
					actionManager.trigger('editor:menu:dragDropBlock', { subaction:'updateDropZone', dropZone: feedback.id, dropSection: feedback.section });
				}

				// MOUSE CLICK / SELECT EVENT
				if( feedback.state === 'select' ){
					actionManager.trigger('inspector:load', { targetId : feedback.id });
					actionManager.trigger('editor:ui:select',  { id : feedback.id });
				}

				// DRAG & DROP EVENT
				/* @todo: fixme: [ticket NS-883] does no longer work + graphic bug
				if( feedback.state === 'start-drag' ){
					actionManager.trigger( 'editor:menu:dragDropBlock' , { subaction: 'start', pattern : null , position : feedback.position } );
					//actionManager.trigger( 'editor:ui:pick-ui', { id : feedback.id });
				}
				if( feedback.state === 'move-drag' ){

					let time = this.getTimestamp();
					let diff = time - this.prevTime;

					if( diff > this.pollFreq ){
						actionManager.trigger('editor:menu:dragDropBlock', { subaction:'refreshPos', position : feedback.position } );
						this.prevTime = time;
					}

				}
				if( feedback.state === 'stop-drag' && this.appState.editor.ui && this.appState.editor.ui.internalDragging === true ){
					actionManager.trigger( 'editor:menu:dragDropBlock' , { subaction: 'drop' } );
					//actionManager.trigger('editor:ui:drop-ui', { id : feedback.id, section : feedback.section });
				}
				*/
			}

		}

	}

}

</script>

<style lang="stylus">
.ui-editor
	display block
	position: absolute
	top 0
	left 0
	right 0
	bottom 0
	height 100%
	overflow: auto;

	.cover
		display: block
		z-index: 5

	.ratio-btns
		position absolute
		width: 100%
		top 100px
		left 0px
		display: inline-block
		text-align : center
		z-index: 50
		text-transform: capitalize
		.btn
			display: inline-block
			text-align: center
			margin: 0px 5px
			font-size: 14px
			cursor: pointer
			font-family: 'DinBold'
			color: var(--inspectorText)
			background: var(--bgColor)
			line-height: 40px
			height: 40px
			width: 100px
			border-radius: 40px
			&.active
				background-color:#f39a74 !important
				color: #ffffff !important
		.btnSize
			margin-top:20px
			.btn
				background: #f8c5a6!important
				color: #ffffff!important
				width: 210px!important
				input
					background: white
					border: 0
					margin-left: 10px
					border-radius: 4px
					height: 30px
					color: #707070
					font-family: 'DinBold'
					font-size: 14px
					width: 50px
					padding: 0 10px
					text-align: center

	.ui-ctn
		width 80%
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		z-index: 10

		overflow: auto;
		background-color white


</style>
