import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import uuid from 'uuid/v4';
import Vue from 'vue';

class AddTrainingSet extends ActionAbstract {

    /**
     * @param name - name of the new training set
     */

    static get ID() {
        return 'actions:project:addTrainingSet';
    }

    run (state) {

        let newTrainingSet = {
            _id: uuid(),
            type: "training-set",
            name: this.getParam('name'),
            slug: this.deps.mainStore.project.slugify(this.getParam('name'))
        }

        let trains = [];

        if (state.project.value.trainingSets) {
            trains = [].concat( state.project.value.trainingSets );
        }

        if( trains.indexOf( newTrainingSet ) === -1 ){
            trains.push( newTrainingSet );
            Vue.set(state.project.value, 'trainingSets' , trains );
        }
        else{
			this.trigger('main:modal:openModal', {title : "New Training Set name - Error", text:'This training set already exists. Please enter an other name.', hasInput : true, inputName : "name", nextAction : 'project:addTrainingSet', icon: 'new' })
        }

        return state;

    }

}

export default AddTrainingSet;
