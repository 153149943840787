var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "textinput-ctn" }, [
    _c(
      "div",
      {
        staticClass: "edit-bt",
        attrs: { disabled: _vm.field.disabled },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.openJSONEditor()
          }
        }
      },
      [_c("span", { staticClass: "label" }, [_vm._v("Edit JSON")])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }