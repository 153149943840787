import ActionAbstract from '../ActionAbstract';

class ToggleInspector extends ActionAbstract {

    static get ID() {
        return 'actions:main:toggleInspector';
    }

    run(state) {
        return this.composeState(state, {
            main: {
                inspectorOpen: this.toggle,
                geomodal: {
                  open: false,
                }
            }
        });
    }

    toggle(state, completeState) {
        let forcedState = this.getParam('open');
        let newState = forcedState !== undefined ? forcedState : !!!state;
        if (!newState || completeState.main.objInspectorOpen) {
            this.trigger('main:toggleObjInspector', { open: false });
        }
        return newState;
    }

}

export default ToggleInspector;
