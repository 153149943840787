<!-- SchematicItem vue -->
<template lang="pug">

v-touch.container-bloc(v-on:pan="onPan",v-bind:style="{top: coordinates.y+'px', left: coordinates.x+'px'}")
    bloc(:app-state="appState", :bloc="bloc", :dragging="dragging", :family="family", :key="family.length", :workspace="workspace")

</template>

<script>

// STORE
import { stores } from '../../../stores/MainStore.js';
import actionManager from '../../../actions';
import * as helper from '../../../helper';

import BlocManager from  './blocs/BlocManager.vue';

export default {

	props: ['appState', 'bloc', 'family', 'workspace'],

	components: { bloc : BlocManager },

	data() {
		return {
			dragging: false,
			coordinates: { x: 0, y: 0 },
			lastCoordinates: { x: 0, y: 0 },
			pollFreq : 30,
			prevTime : 0
		}
	},

	created: function(){
		this.initCoordinates();
	},

	computed: {
		'deleted' : function(){
			let computedBloc = helper.block.getBlockById( this.appState.project, this.bloc.value._id );
			return computedBloc.value._deleted;
		}

	},

	methods: {

		initCoordinates: function(){
			this.coordinates = [];

			let sceneDOM = document.getElementById('scene-'+this.appState.editor.targetId);
			let middleScene = { x:0, y:0 };
			if( sceneDOM ){
				middleScene.x = sceneDOM.offsetWidth*.5 - 115;
				middleScene.y = sceneDOM.offsetHeight*.4;
			}

			if( !this.bloc.value.coordinate ){
				this.coordinates = {
					x: -this.appState.editor.scene.coordinate.x + middleScene.x ,
					y: -this.appState.editor.scene.coordinate.y + middleScene.y
				};
				actionManager.trigger('editor:schematic:moveBlock', { subaction:'drop', id : this.bloc.value._id, x: this.coordinates.x , y : this.coordinates.y });
			}
			else{
				this.coordinates = {x: this.bloc.value.coordinate.x, y:this.bloc.value.coordinate.y };
			}

			this.lastCoordinates = Object.assign( {}, this.coordinates );
			//this.bloc.custom.currentPos = this.coordinates;

		},

		onPan: function( e ){

			if( !this.dragging ){
				// HACK: Instead of installing Vuex, and another dependencies,
				// use the root instance as an event hub to communicate between components.
				// (since migration Vue v1 to v2)
				// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
				this.$root.$emit( 'dragging-bloc', true );
			}

			this.dragging = true;

			let delta = { x : e.deltaX , y: e.deltaY };

			this.coordinates.x = this.lastCoordinates.x + delta.x + ( delta.x * (1-this.appState.editor.scene.scale) );
			this.coordinates.y = this.lastCoordinates.y + delta.y + ( delta.y * (1-this.appState.editor.scene.scale) );

			let time = this.getTimestamp();
			let diff = time - this.prevTime;

			if( diff > this.pollFreq ){
				actionManager.trigger('editor:schematic:moveBlock', { subaction:'refresh', id : this.bloc.value._id, x: this.coordinates.x , y : this.coordinates.y });
				this.prevTime = time;
			}

			if( e.isFinal ){

				this.lastCoordinates = Object.assign( {}, this.coordinates );

				setTimeout( function(){
					this.dragging = false;
					// HACK: Instead of installing Vuex, and another dependencies,
					// use the root instance as an event hub to communicate between components.
					// (since migration Vue v1 to v2)
					// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
					this.$root.$emit( 'dragging-bloc', false );
					// HACK: Instead of installing Vuex, and another dependencies,
					// use the root instance as an event hub to communicate between components.
					// (since migration Vue v1 to v2)
					// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
					this.$root.$emit( 'first-pan', true );
					actionManager.trigger('editor:schematic:moveBlock', { subaction:'drop', id : this.bloc.value._id, x: this.coordinates.x , y : this.coordinates.y });
				}.bind(this), 0);
			}

			e.preventDefault();
		},

		getTimestamp(){
			var d = new Date();
			var n = d.getTime();
			return n;
		}

	}


}

</script>

<style lang="stylus">

.container-bloc
	position: absolute
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

</style>
