import { stores } from './MainStore';
const xpConfig = {
    mapLibrary : 'ui-map-ol'
};

const ConfigPlugin = {
    install: function (Vue, options) {

        Vue.prototype.$config = stores.config;
        Vue.prototype.$xpConfig = xpConfig;

    },

    
}

export default ConfigPlugin;