import LibraryActionAbstract from './LibraryActionAbstract';

import * as helper from '../../helper';

class Open extends LibraryActionAbstract {

    static get ID() {
        return 'actions:library:open';
    }

    run(state) {

        this.trigger('library:fetchAssets');

        this.trigger('main:toggleLibrary', { open: true });

        if( this.getParam('importJSON') === true ){

            return this.composeState(state, {
                library: {
                    status: this.deps.mainStore.lib.LIBRARY_STATE_SELECT,
                    target: null,
                    import : true,
                    fieldDef: { assetType : "file" }
                }
            });

        }


        let target = null;
        let bloc = this.getParam('bloc');
        if (!bloc || bloc.value.type !== 'memory') {
            if (bloc && this.getParam('field')) {
                let fieldName = this.getParam('field').name;
                target = helper.block.getField(bloc, fieldName);
                if (fieldName.includes("array")) {
                    fieldName = this.getParam('field').target || this.getParam('field').label;
                    target = helper.block.getFieldFromArray(bloc, fieldName);
                    if (target) {
                        target["fieldToChange"] = this.getParam('field').name;
                        target["valueIsArray"] = true;
                    }
                    else { // fatal error, must be reported
                        // @todo: implement notification
                        //this.trigger('notification', { message: 'The media library must have a valid target to run properly with arrays', type: 'error' });
                        console.error('The media library must have a valid target to run properly with arrays');
                        return false;
                    }
                }
            }
        } else {
            target = this.getParam('field');
        }

        // assigns all default values to set a proper Library panel's setting (e.g. remve previous filter)
        const def = Object.assign({ assetType: null }, this.getParam('field'));

        return this.composeState(state, {
            library: {
                status: !this.getParam('field') ? this.deps.mainStore.lib.LIBRARY_STATE_CONSULT : this.deps.mainStore.lib.LIBRARY_STATE_SELECT,
                target: {field: target, bloc: bloc},
                fieldDef: def,
                import : false,
                assetSelected: false,
                mediaId: this.getParam('mediaId')
            }
        }); 
    }

}

export default Open;
