<!-- BlockGroup vue -->
<template lang="pug">
div.block-group(v-bind:class="[{'open':open}, 'level'+level]", v-if="displayGroup" )
	ul
		li.title
			icon(:name="group.icon")
			span.text {{group.title}}

		group-bloc(v-for="subgroup of group.children", :group="subgroup", :level="level+1")

		bloc-item(v-for="component of group.components", :component="component", :workspace="workspace")
		
</template>

<script>

import BlocItem from  './BlocItem.vue';
import Group from  './BlocGroup.vue';
import Icon from  '../../common/Icon.vue';

export default {

	name: 'group-bloc',

	props: ['group', 'level', 'workspace'],

	components: { group : Group , BlocItem, Icon },

	data() {
		return {
			open: true,
			currentComponents: null,
		}
	},

	computed: {	
		'displayGroup' : function(){
			if( this.group.hasComponents )
				return true;

			let result = false;
			if( this.group.children ){
				this.group.children.forEach( (child) => {
					if( child.hasComponents )
						result = true;
				});
			}

			return result;
		}
	}

}

</script>

<style lang="stylus">


</style>

