<!-- Percent vue -->
<template lang="pug">
span.textinput-ctn.has-unit
	input.value(type="text", v-model="formattedValue", v-bind:placeholder="placeholder", v-on:focusout="focusOutField(field)", :disabled="field.disabled")
	span.unit(v-bind:class="{'disabled': field.disabled }") %
</template>

<script>
export default {
	components: {},

	props:[ 'field' , 'fieldvalue' , 'placeholder' ],

	data() {
		return {
			value: null
		}
	},

	created: function() {
		let percentValue = this.fieldvalue.value * 100
		this.formattedValue = parseFloat(percentValue.toFixed(2));
	},

	watch: {
		// TODO: to be verified if this solution is ok
		// while working with the FieldManager.vue
		// (see above the created method)
		 // since migration Vue v1 to v2
		fieldvalue: function (obj) {
			// Is this ever called? Everything works with nothing inside this.
		}
	},

	methods:{
		focusOutField: function(fieldUpdated){
			let floatVal = parseFloat(this.formattedValue);
			if( this.formattedValue === "" || floatVal === NaN || floatVal === 'NaN' || floatVal === null) {
				this.value = null;
			} else {
				this.value = floatVal / 100;
			}

			if (!this.fieldvalue || this.fieldvalue.value !== this.value) {
				this.valueUpdated(fieldUpdated);
			}
		},

		valueUpdated: function(fieldUpdated){
			this.$parent.valueUpdated(fieldUpdated, this.value);
		}
	}
}
</script>

<style lang="stylus">
</style>
