<template lang="pug">

div.sound-ctn
	audio(:src="media.url", controls)

</template>

<script>

export default {
	
	props: ['media'],

	data() {
		return {

		}
	},

	created: function(){

	},

	methods:{


	},

	filters: {

	}	

}
</script>

<style lang="stylus">

.sound-ctn
	width: 100%
	height: 100%
	display: flex
	justify-content: center
	align-items: center

	

	
			
</style>