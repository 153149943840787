import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import deepCopy from 'deepcopy';

class AddMemory extends ActionAbstract {

    // @todo: reformat or delete (outdated)
    // /**
    //  * @param targetId - the id of the parent block
    //  * @param format - the format of the new block
    //  * @param name - the name of the new block
    //  */

    static get ID() {
        return 'actions:project:addMemory';
    }

    run (state) {

        let parent;
        let targetId = this.getParam('targetId');

        switch(this.getParam('subaction')) {
            case 'success':
                let newDoc = this.getParam('newDoc');

                if( !newDoc.value.fields ){
                    newDoc.value.fields = [];
                }

                let path;
                [, path] = helper.block.getBlockById(state.project, targetId, true);
                
                if(newDoc.value.format === "secret"){
                    newDoc.value._id = `secret-${newDoc.value._id}`
                }

                this.trigger('inspector:loadObj', {targetId: newDoc.value._id});

                let compositeState = {
                    isLoading: false,
                    project: {}
                };
               
                compositeState.project[path + ".value.memory"] = this.arrayPush({ id : newDoc.value._id });
                if (state.workspace.target == 'project') compositeState.project["memory"] = this.arrayPush(newDoc);
                else { //if (state.workspace.target == 'template') {
                    [, path] = helper.block.getTemplateById(state.project, state.workspace.id,true);
                    compositeState.project[path+".memory"] = this.arrayPush(newDoc);
                }
                
                this.composeState(state, compositeState);
                break;
            case 'fail':
            default:
                let choice = this.getParam('choice');
                parent = helper.block.getBlockById(state.project, targetId);

                var block = this.deps.mainStore.block.generateNewBlockDoc( choice, parent );
                block.name = choice.value.name;
                block.format = choice.value.format;
                this.deps.mainStore.block.createNewDoc( block ).then( (newDoc) => {
                    this.trigger('project:addMemory', { subaction: 'success', newDoc, targetId: targetId });
                });

                return this.composeState(state, {
                    isLoading: true
                });
        }

    }

}

export default AddMemory;