import React from 'react';
import AdvancedUi from '../AdvancedUi';
import PinchZoomPan from '../helpers/PinchZoomPan';
import Hammer from 'react-hammerjs';

export default class Box extends AdvancedUi {

    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            position: 'relative'
        });

        this.state = this.getInitState();

        this.handleDropZoneRegistration = this.handleDropZoneRegistration.bind(this);

    }
    

    getInitState(){
        return {
            dragOver: false,
            itemDropped: 0,
            //filled: false,

            dragging : false,
            delta : { x : null, y : null },
            oldPos :  { x : 0, y : 0 },
            hide: false,
            reseted : false,
            forcedTranslateX : false,
            forcedTranslateY : false
        }
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return super.shouldComponentUpdate(nextProps, nextState) || (nextProps.dnd !== undefined && nextProps.dnd.draggedItem)
    // }

    componentDidMount() {
        this.handleDropZoneRegistration()
    }

    componentWillUpdate( nextProps , nextState ){
        super.componentWillUpdate( nextProps , nextState );
        
        //console.log( nextProps.resetState );
        if( nextProps.resetState === true && nextState.reseted === false )
            this.setState( Object.assign( {}, this.getInitState(), { reseted : true } ) ); // resetUis() has been called -> need to reset the state and fill list

        if( nextProps.resetState !== true && nextState.reseted === true )
            this.setState( { reseted : false } );


        let newDeltaState = { delta : nextState.delta , oldPos : nextState.oldPos };
        let updateDelta = false;


        if( this.notEmpty( nextProps.force_translateX ) && nextProps.force_translateX !== undefined && nextState.forcedTranslateX === false ){
            newDeltaState.delta.x = nextProps.force_translateX === "auto" ? 0 : parseInt(nextProps.force_translateX);
            newDeltaState.oldPos.x = newDeltaState.delta.x;
            newDeltaState.forcedTranslateX = true;
            updateDelta = true;
        }
        if( nextProps.force_translateX === null  && nextState.forcedTranslateX === true )
            this.setState( { forcedTranslateX : false } );


        if( this.notEmpty( nextProps.force_translateY ) && nextProps.force_translateY !== undefined && nextState.forcedTranslateY === false ){
            newDeltaState.delta.y = nextProps.force_translateY === "auto" ? 0 : parseInt(nextProps.force_translateY);
            newDeltaState.oldPos.y = newDeltaState.delta.y;
            newDeltaState.forcedTranslateY = true;
            updateDelta = true;
        }
        if( nextProps.force_translateY === null && nextState.forcedTranslateY === true )
            this.setState( { forcedTranslateY : false } );



        if( updateDelta ){
            this.setState( newDeltaState );
            if( this.props.feedbackDeltaDnD )
                this.props.feedbackDeltaDnD( newDeltaState.delta );
        }

    }

    componentDidUpdate( prevProps, prevState ) {
        this.handleDropZoneRegistration();
    }

    componentWillUnmount() {
        this.handleDropZoneRegistration(false)        
    }

    handleDropZoneRegistration(register) {
        this.props.dragdrop && this.props.registerDropZone && this.props.registerDropZone(this, register !== undefined ? register : (this.props.dragdrop.dragAndDropMode === 'dropzone') )
    }



    onDrag_handler( evt ){
        evt.preventDefault();

        let position = { x : evt.center.x, y : evt.center.y };

        const customEvent = { nodalTargetId : this.props._id , position, nodalItemConfig : this.props.dragdrop, nativeEvent: evt};

        let newState = { dragging : false };
        

        if ( !evt.isFinal ) {
            if( !this.state.dragging) {
                this.props.onStartDrag && this.props.onStartDrag(customEvent);
                this.eventHandler( 'ddevents_startDrag-trigger', this.props._id );
                this.eventHandler( 'ddevents_startDrag-position', position );                  
            } else {
                this.props.onDrag && this.props.onDrag(customEvent);

                this.eventHandler( 'ddevents_onDrag-trigger', this.props._id );
                this.eventHandler( 'ddevents_onDrag-position', position );
            }

            newState.dragging = true;
            let deltaX = this.props.dragdrop.dragX == true ? evt.deltaX : 0;
            let deltaY = this.props.dragdrop.dragY == true ? evt.deltaY : 0;
            newState.delta = { x : ( this.state.oldPos.x + deltaX ) , y :( this.state.oldPos.y +  deltaY ) } 
        } else {
            
            let hasScroll = this.props.dragdrop.dragX === true && this.props.dragdrop.dragY === true ? false : true;
            if( hasScroll && position.x === 0 && position.y === 0 )
                return; // ignore, we are scrolling

            const success = this.props.onDragEnd && this.props.onDragEnd(customEvent);

            this.eventHandler( 'ddevents_stopDrag-trigger', this.props._id );
            this.eventHandler( 'ddevents_stopDrag-position', position );
            this.eventHandler( 'ddevents_stopDrag-success', success );

            if( !success ){
                // on Bad move
                if( this.props.dragdrop.onBadMove === "none" ){
                    Object.assign(newState, { oldPos : this.state.delta } );
                }
                if( this.props.dragdrop.onBadMove === "backToStartPos" ){
                    Object.assign(newState, { delta :  { x : this.state.oldPos.x, y : this.state.oldPos.y } } );
                    //this.setState( { oldPos :  { x : 0, y : 0 } } );
                }
                if( this.props.dragdrop.onBadMove === "hide" ){
                    Object.assign(newState, { hide :  true } );
                }
            }
            else{
                Object.assign(newState, { oldPos : this.state.delta } );
            }

        }

        if( this.props.feedbackDeltaDnD && newState.delta ){
            this.props.feedbackDeltaDnD( newState.delta );
        }

        this.setState( newState );        

    }

    

    setDefaultStyles( nextProps, nextState ){
        
        super.setDefaultStyles( nextProps, nextState );
        
        let styles = {};

        if( nextProps.transform ){
            styles.transform = "";
            
            let translateX = nextState.delta.x != null ? nextState.delta.x+"px" : nextProps.transform.translateX;
            if( this.notEmpty( translateX ) && translateX !== "auto" )
                styles.transform += 'translateX('+translateX+') ';

            let translateY = nextState.delta.y != null ? nextState.delta.y+"px" : nextProps.transform.translateY;
            if( this.notEmpty( translateY ) && translateY !== "auto" )
                styles.transform += 'translateY('+translateY+') ';

            if( this.notEmpty( nextProps.transform.scaleX ) )
                styles.transform += 'scaleX('+(nextProps.transform.scaleX/100)+') ';

            if( this.notEmpty( nextProps.transform.scaleY ) )
                styles.transform += 'scaleY('+(nextProps.transform.scaleY/100)+') ';

            if( this.notEmpty( nextProps.transform.rotate ) )
                styles.transform += 'rotate('+(nextProps.transform.rotate)+'deg) ';


        }

        if( nextState.dragging )
            styles.transitionProperty = "none";
        else
            styles.transitionProperty = this.defaultStyle.transitionProperty;

        if( nextState.hide === true )
            styles.display = "none";

        styles.pointerEvents = nextState.dragging ? 'none' : this.defaultStyle.pointerEvents;
        styles.touchAction = nextState.dragging ? 'none' : this.defaultStyle.pointerEvents;
        styles.pointerType = "touch";

        this.defaultStyle = Object.assign( {}, this.defaultStyle, styles );
    }

    
    // componentWillUpdate( nextProps , nextState ){
    //     super.componentWillUpdate( nextProps , nextState );
    //     this.checkDropzoneSuccess( nextProps );
    // }


    // used only for droppable items
    dragOver(draggedItem) {         
        this.setState({ dragOver: true });
        this.eventHandler( 'ddevents_onDragOver-trigger', this.props._id );
        this.eventHandler( 'ddevents_onDragOver-correctObject', this._isCorrectDropZone(draggedItem) );         
    }

    dragOut(draggedItem) {         
        this.setState({ dragOver: false });
        this.eventHandler( 'ddevents_onDragOut-trigger', this.props._id );
        this.eventHandler( 'ddevents_onDragOut-correctObject', this._isCorrectDropZone(draggedItem) );         
    }

    dropItemInside( itemId, draggedItem ){
        this.setState({ dragOver: false });        
        if (this._isCorrectDropZone(draggedItem)) {
            if( !this.props.dragdrop.fillCapacity || this.state.itemDropped < this.props.dragdrop.fillCapacity ){
                let newNbItem = this.state.itemDropped + 1,
                    newState = {
                        itemDropped: newNbItem
                    };
                this.eventHandler( 'ddevents_onDrop-trigger', this.props._id );
                this.eventHandler( 'ddevents_onDrop-droppedItem', itemId );
                this.eventHandler( 'ddevents_onDrop-correctObject', this.props._id );
                /*if( this.props.dragdrop.fillCapacity && newNbItem >= this.props.dragdrop.fillCapacity ){
                    this.eventHandler( 'ddevents_onFill-trigger', this.props._id );
                    newState.filled = true;
                }*/
                if( this.isFilled( newNbItem ) )
                    this.eventHandler( 'ddevents_onFill-trigger', this.props._id );

                this.setState(newState)
                return true;
            }
        }
        return false;
    }    

    isFilled( newNbItem ){
        if( this.props.dragdrop.fillCapacity && newNbItem >= this.props.dragdrop.fillCapacity ){
            return true;
        }
        return false;
    }

    _isCorrectDropZone( draggedItem ){

        function _splitTags ( tagString ) {
            let tags = tagString.replace(' ', ''); 
            return tags.split(',');
        }

        if( this.isFilled( this.state.itemDropped ) ){
            return false;
        }

        if( this.props._id == draggedItem.dropZoneUi )
            return true;

        let tagFound = false;
        if( draggedItem.dropZoneTags != undefined && draggedItem.dropZoneTags != "" ){

            let itemTags = _splitTags( draggedItem.dropZoneTags );

            let dzTags = _splitTags( this.props.customTags );
            dzTags.push( this.props._id );
            dzTags.push( "ui-box-dropzone" );

            let i = 0;
            while (!tagFound && i < itemTags.length ) {
                tagFound = dzTags.indexOf(itemTags[i]) !== -1;
                i++;
            }
        }
        
        return tagFound;
    }




    render() {

        let dragEventCallbackProps = null;
        if( this.props.dragdrop && this.props.dragdrop.dragAndDropMode === 'draggable' ){ //&& !this.state.dropped ){
            let touchAction = this.props.dragdrop.dragX === true && this.props.dragdrop.dragY === true ? "none" : "auto";
            dragEventCallbackProps = {
                className : "dragitem",
                onPan : this.onDrag_handler.bind(this),
                options: { touchAction: touchAction }
            };

        }else{
            //dragEventCallbackProps = { onPan : function(evt){} } ;
        }

        let otherDoms = {};
        if( this.props.layout.overflowY === false && this.el ){
            this.el.scrollTop = 0;
        }
        
        let content;
        
        if( this.props.svgContainer === true ){
            content = (
                <svg {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                    {this.props.children}
                </svg>
            );
        }
        else if( this.props.pinchToZoomEnabled === true ){
            content = (
                <PinchZoomPan ref={elem => (this.el = elem)} triggerResetPinch={this.props.triggerResetPinch} {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                    {this.props.children}
                </PinchZoomPan>
            );
        }
        else{
            content = (
                <div ref={elem => (this.el = elem)} {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                    {this.props.children}
                </div>
            );
        }

        content = this.wrapIntoAuthoring( content , 0  );

        return <Hammer {...this.touchEvents} {...this.tevents} {...otherDoms} {...dragEventCallbackProps}  options={{ recognizers: { swipe: { velocity: 0.8  } } }} >
            {content}
        </Hammer>;

    }

}