import ActionAbstract from '../ActionAbstract';

class ToggleLibrary extends ActionAbstract {

    static get ID() {
        return 'actions:main:toggleLibrary';
    }

    run(state) {
        return this.composeState(state, {
            main: {
                libraryOpen: this.getParam('open')
            }
        });
    }
}

export default ToggleLibrary;