import ActionAbstract from '../ActionAbstract';

class GotoRoute extends ActionAbstract {
    static get ID() {
        return 'actions:main:gotoRoute';
    }

    run(state) {
        this.deps.mainStore.config.$router.push(this.getParam('routeParam'));
        return this.composeState(state, {});
    }

}

export default GotoRoute;