<!--
	Project component

	FIXME: fix transition with appear (see SliderPanel)
-->
<template lang="pug">

div.main-ctn(v-doc-keyup="triggerKeyUp")

	div.main-ctn(v-if="appState.main && appState.project")
		top-menu(:app-state="appState" )
		breadcrumb(:app-state="appState",v-if="appState.main && appState.main.breadcrumbOpen")
		navigator(:app-state="appState")

		div.draw-zone(v-bind:class="{ 'navigatorOpen': appState.main.navigatorOpen}")
			//editor(:target="draw.subject")
			editor(v-if="appState.editor" , :app-state="appState")

		slider-panel(v-if="appState.main && appState.main.libraryOpen", :state="appState.library", :user="appState.user", :panel="appState.panel", :workspace="appState.workspace", title="Asset Library", name="LIBRARY")
		<transition name="modal-transition">
			media-preview.modal-transition(v-if="appState.main && appState.main.mediaPreviewOpen", :state="appState.library")
		</transition>

		<transition name="modal-transition">
			tagModal.modal-transition(v-if="appState.main && appState.library.tagModalOpen", :state="appState.library")
		</transition>

		slider-panel(v-if="appState.main && appState.main.templateOpen", :state="appState.templates", :user="appState.user", :panel="appState.panel",:project="appState.project.value", title="Templates", name="TEMPLATE")
		slider-panel(v-if="appState.main && appState.main.jsonEditorOpen", :state="appState.jsonEditor", :panel="appState.panel", title="JSON Editor", name="JSON")

		div.inspector-container(v-bind:class="{'open':inspectorIsOpen,'second-panel-open':objInspectorIsOpen}")
			div.bt-nav(v-bind:class="{'open':inspectorIsOpen,'geo':geoIsOpen}", v-on:click.prevent="toggleInspector()")
			<transition name="panel-transition">
				inspector.panel-transition(v-if="inspectorIsOpen", :class="{'geo':geoIsOpen}", :app-config="appState.config", :project="appState.project", :inspector="appState.inspector", :workspace="appState.workspace.target", :state="appState")
			</transition>
			<transition name="panel-transition">
				inspector.obj.panel-transition(v-if="objInspectorIsOpen", :app-config="appState.config", :project="appState.project", :inspector="appState.objinspector", :workspace="appState.workspace.target", :state="appState")
			</transition>

		<transition name="arraypan-transition">
		array-inspector.arraypan-transition(v-if="appState.main && appState.main.arrayInspectorOpen", :project="appState.project", :inspector="appState.arrayInspector", :library="appState.library", :geomodal="appState.main.geomodal")
		</transition>

		//<transition name="arraypan-transition">
		//wysiwyg-inspector.arraypan-transition(v-if="wysiwygInspector.open")
		//</transition>

	spinner(v-if="appState.isLoading")

	// TODO: move to the caller component like the popup window ?
	modal(v-if="appState.modal && appState.modal.open", :settings="appState.modal")
	//geomodal(v-if="appState.main.geomodal && appState.main.geomodal.open", :settings="appState.main.geomodal")

</template>

<script>

	// STORE
	import { stores } from '../stores/MainStore.js';

	// COMMON COMPONENTS
	import TopMenu from  './common/TopMenu.vue';
	import Breadcrumb from  './common/Breadcrumb.vue';
	import Navigator from  './common/Navigator.vue';
	import Spinner from  './common/Spinner.vue';
	import Modal from  './common/Modal.vue';
	import GeoModal from './common/GeoModal.vue';
	import SliderPanel from  './common/SliderPanel.vue';
	import MediaPreview from  './library/MediaPreview.vue';
	import tagModal from  './library/tagModal.vue';

	// COMPONENTS FOR INSPECTORS
	import Inspector from  './inspector/Inspector.vue';
	import ArrayInspector from  './inspector/ArrayInspector.vue';
	import WysiwygInspector from  './inspector/WysiwygInspector.vue';

	import actionManager from '../actions';

	// COMPONENTS FOR DRAWS
	import Editor from './editor/Editor.vue';

	export default {

		props:['appState'],

		components: { Navigator , TopMenu, Breadcrumb, Inspector, Editor, ArrayInspector, WysiwygInspector, MediaPreview, tagModal, Spinner, Modal, geomodal: GeoModal, SliderPanel },

		data() {
			return {
				navigatorOpen:true,
				project: null,
				library: false,
				draw:{
					subject: null,
					transition: 'go-inside'
				},
				wysiwygInspector:{
					open: false
				},
				currentMedia: null,
				libStatus: stores.lib.LIBRARY_STATE_CONSULT,
				//defaultRefs:
				libField: null,
				waiting: true,
				currentKeyCode: -1
			}
		},

		created: function(){
			// --- --- --- Keyboard listener - Need to be moved in a helper.
			// action sto be performed on CMD/CTRL + SHIFT
			const me = this;
			const performOnShift = (e) => {
				if ( !e.shiftKey ) return;

				e.preventDefault();
				switch(e.keyCode) {
					// (C) Copy the selected element(s).
					// trigger action once
					case 67:
						if ( me.currentKeyCode != 67 ) {
							actionManager.trigger("block:copy");
						}
						break;
					// (V) Paste the selected element(s).
					// trigger action once
					case 86:
						if ( me.currentKeyCode != 86 ) {
							actionManager.trigger("block:paste");
						}
						break;
					// (D) Duplicate the selected element(s).
					// trigger action once
					case 68:
						if ( me.currentKeyCode != 68 ) {
							actionManager.trigger("block:copy", {duplicate: true});
						}
						break;
					// (I) Inspect the selected element.
					// trigger action once
					case 73:
						if ( me.currentKeyCode != 73 ) {
							// @todo
						}
						break;
					// (SPACE) Toggle Menu.
					// Open and close the Add Button.
					case 32:
						actionManager.trigger('editor:menu:toggleMenu');
						break;
					// (F) Toggle Full Screen Mode.
					// Open and close the full screen mode for an optimized visibility
					case 70:
						actionManager.trigger('main:toggleNavigator', {'open':false} );
						actionManager.trigger('main:toggleInspector', {'open':false} );
						actionManager.trigger('main:toggleBreadcrumb' );
						break;
					// (LEFT) Previous Level.
					// Go to the previous level on the workspace
					case 37:
						actionManager.trigger('main:historyBack');
						break;
					// (RIGHT) Next Level.
					// Go to the next level on the workspace
					case 39:
						actionManager.trigger('main:historyNext');
						break;
					// (R) Run the selected experience.
					case 82:
						 // trigger action once
						if ( me.currentKeyCode != 67 && this.appState.editor.targetXP) {
							actionManager.trigger('main:openPreview', {'blocId':this.appState.editor.targetXP});
						}
						break;
				}
				me.currentKeyCode = e.keyCode;
			}

			document.addEventListener('keydown', e => {

				// CMD or CTRL
				if ( navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey ) {
					switch(e.keyCode) {
						// (S) Opens the Save Modal.
						case 83:
							e.preventDefault();
							// trigger action once
							if ( me.currentKeyCode != 83 ) {
								actionManager.trigger('main:modal:openModal', {
									title: 'Are you sure?',
									text: 'This save will update every node in your project. Please make sure no one is currently working on it.',
									nextAction: 'project:save',
									icon: 'save',
								});
							}
							break;
						// (R) Prevent Reload.
						// Block the Reload Ctrl+R action to prevent from closing project
						case 82:
							e.preventDefault();
						// +SHIFT
						default:
							performOnShift(e);
							break;
					}
					me.currentKeyCode = e.keyCode;
				}
			}, false);

			document.addEventListener('keyup', e => {
				me.currentKeyCode = -1;
			}, false);
			// --- --- ---

			if( this.appState.project === undefined ) {
				actionManager.trigger('project:openProject', { projectId: this.$route.params.projectId });
			}

			// ---- DEAD CODE --------------------------------------------------------------
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			/* this.$root.$on('openLibraryFromField', this.onOpenLibraryFromField)
			this.$root.$on('toggleLibrary', this.onToggleLibrary)
			this.$root.$on('displayMedia', this.onDisplayMedia)
			this.$root.$on('closeMediaModal', this.onCloseMediaModal)
			this.$root.$on('closeAllLibrary', this.onCloseAllLibrary) */
			// ---- DEAD CODE --------------------------------------------------------------
		},

		beforeDestroy: function(){
			// ---- DEAD CODE --------------------------------------------------------------
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// If limits appear, have a look at https://vuex.vuejs.org
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			/* this.$root.$off('openLibraryFromField', this.onOpenLibraryFromField)
			this.$root.$off('toggleLibrary', this.onToggleLibrary)
			this.$root.$off('displayMedia', this.onDisplayMedia)
			this.$root.$off('closeMediaModal', this.onCloseMediaModal)
			this.$root.$off('closeAllLibrary', this.onCloseAllLibrary) */
			// ---- DEAD CODE --------------------------------------------------------------
		},

		computed: {
			inspectorIsOpen: function() {
				return this.appState.main && this.appState.main.inspectorOpen;
			},
			objInspectorIsOpen: function() {
				return this.appState.main && this.appState.main.objInspectorOpen;
			},
			geoIsOpen: function() {
				return this.appState.main && this.appState.main.geomodal && this.appState.main.geomodal.open;
			}
		},

		methods: {

			newExperienceAdded: function( response ){
				this.project.custom.children.push( response );

				if( this.project.custom.children.length == 1 )
					this.initFirstExperience();
			},

			initFirstExperience: function(){
				this.draw.subject = this.project.custom.children[0];

				if( this.$children )
					// HACK: Instead of installing Vuex, and another dependencies,
					// use the root instance as an event hub to communicate between components.
					// (since migration Vue v1 to v2)
					// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
					this.$root.$emit('update-arborescence', { project:this.project, subject:this.draw.subject} ) // <---- ORIGINAL updateArborescence LISTENER NOT FOUND
			},

			getTransition: function( oldLevel, newLevel ){
				let order = ['project', 'experience', 'screen', 'object', 'process', 'module'];
				let indexOld = order.indexOf(oldLevel);
				let indexNew = order.indexOf(newLevel);

				if( indexOld != -1 && indexNew != -1 && indexNew < indexOld ){
					return 'go-outside';
				}
				return 'go-inside';
			},

			triggerKeyUp : function( e ){
				if( e.keyCode === 27 ){
					actionManager.trigger('main:modal:closeModal');
					actionManager.trigger('main:toggleInspector', {'open':false} );
					actionManager.trigger('main:toggleLibrary', {'open':false} );
					actionManager.trigger('main:toggleTemplate', {'open':false} );
					actionManager.trigger('main:panel:close');
				}

			},

			toggleInspector: function(){
				actionManager.trigger('main:toggleInspector');
			},


			// ---- DEAD CODE --------------------------------------------------------------
			/* // ORIGINAL openLibraryFromField EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
			// (migration Vue v1 to v2)
			onOpenLibraryFromField: function( data ){
				this.libField = data;
				this.libStatus = stores.lib.LIBRARY_STATE_SELECT;
				this.library = true;
			},

			// ORIGINAL toggleLibrary EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
			// (migration Vue v1 to v2)
			onToggleLibrary : function( ){
				this.library = !this.library;

				if( !this.library ){
					this.libField = null;
					this.libStatus = stores.lib.LIBRARY_STATE_CONSULT;
				}
			},

			// ORIGINAL displayMedia EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
			// (migration Vue v1 to v2)
			onDisplayMedia: function( info ){
				this.currentMedia = info.target;
			},

			// ORIGINAL closeMediaModal EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
			// (migration Vue v1 to v2)
			onCloseMediaModal: function(){
				this.currentMedia = null;
			},

			// ORIGINAL closeAllLibrary EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
			// (migration Vue v1 to v2)
			onCloseAllLibrary: function(){
				this.currentMedia = null;
				this.library = false;
				this.libField = null;
				this.libStatus = stores.lib.LIBRARY_STATE_CONSULT;
			}, */
			// ---- DEAD CODE --------------------------------------------------------------
		}
	}

</script>



<style lang="stylus">

:root
	--bgColor: #ffffff
	--drawZoneColor: #fff6ec
	--menuColor: #ffffff
	--textColor: #707070
	--menuElementColor: #e7e7e8
	--mainColor: #707070
	--breadColor: #707070
	--topSelectColor: #ebebeb
	--inspectorColor: rgba(247,185,149,0.8)
	--shadowColor: rgba(0,0,0,0.1)
	--addElementColor: #f7f7f5
	--mainTitleColor: #bfbdbd
	--currentStepColor: #ebebeb
	--defaultStepColor: #acacac

	--saveColor: #707070

	--modalBgColor: #F49E75
	--modalColor: #fde6d4
	--modalMainColor: #fff6ec
	--modalSearchbar: #707070
	--modalCriteriaBarColor: #ededed
	--modalIconColor: #ebebeb
	--modalTransparentBgColor: rgba(244,158,117,.7)
	--modalFilterColor: rgba(255,255,255,.2)

	--arrayColumnOddColor: rgba(255,255,255,.5)
	--arrayColumnEvenColor: rgba(255,255,255,.7)

	--moduleTitleColor: #707070
	--moduleBoxColor: #ffffff
	--moduleInputColor: #f7f7f7
	--moduleValue: #ffffff
	--moduleTextColor: #ffffff

	--linkMenuColor: #f7f7f5
	--linMenuTopic: #e6e7e8

	--level1Color: #707070
	--level2Color: #bbbdc0

	--puzzleColor: #fffffff
	--iconPuzzleColor: #E6E7E8

	--inspectorLevel1: #fcf2e9
	--subtitleColor: rgba(255,255,255,.8)
	--inspectorTargetColor: #FDE9DD
	--inspectorTargetOverColor: #ffddc8
	--inspectorText: #707070
	--inputValue: #f8c5a6
	--inputTextColor: #707070
	--checkedValue: #ffffff
	--selectedSaveColor: #f39a74
	--selectedSaveTextColor: #ffffff

	--descriptionTextColor: #ffffff

	--descriptionTextColor: #ffffff
	--descriptionBgColor: #707070

	--selectedColor: #f39a74

	--docElementColor: #ffffff

@media (prefers-color-scheme: dark)
	:root
		--bgColor: #131313
		--drawZoneColor: #4A3023
		--menuColor: #131313
		--textColor: #707070
		--menuElementColor: rgba(255,255,255,.2)
		--mainColor: #ebebeb
		--breadColor: #222222
		--topSelectColor: #222222
		--inspectorColor: rgba(0,0,0,.6)
		--shadowColor: rgba(0,0,0,.5)
		--addElementColor: #222222
		--mainTitleColor: #2f2f2f
		--currentStepColor: #666666
		--defaultStepColor: #333333

		--saveColor: #F49E75

		--modalBgColor: #131313
		--modalColor: #261912
		--modalMainColor: #4a3023
		--modalSearchbar: #131212
		--modalCriteriaBarColor: #1e1e1e
		--modalIconColor: #222222
		--modalTransparentBgColor: rgba(0,0,0,.7)
		--modalFilterColor: rgba(0,0,0,.2)

		--arrayColumnOddColor: rgba(0,0,0,.2)
		--arrayColumnEvenColor: rgba(0,0,0,.8)

		--moduleTitleColor: #131313
		--moduleBoxColor: #222222
		--moduleInputColor: #101010
		--moduleValue: #222222
		--moduleTextColor: #e4e4e4

		--linkMenuColor: #222222
		--linkMenuTopic: #636363

		--level1Color: #515151
		--level2Color: #3c3c3c

		--puzzleColor: #131313
		--iconPuzzleColor: #282828

		--inspectorLevel1: #4A3023
		--subtitleColor: rgba(0,0,0,.3)
		--inspectorTargetColor: #623F2F
		--inspectorTargetOverColor: #b8795c
		--inspectorText: #e4e4e4
		--inputValue: #925F46
		--inputTextColor: #e4e4e4
		--checkedValue: #f8c5a6
		--selectedSaveColor: #fde6d4
		--selectedSaveTextColor: #707070

		--descriptionTextColor: #f39a74
		--descriptionBgColor: #e4e4e4

		--selectedColor: #f39a74

		--docElementColor: #ffddc8

body
	background: var(--bgColor)!important
	overscroll-behavior-x: none
	-webkit-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	-o-user-select: none
	user-select: none

input:focus, textarea:focus, select:focus
	outline: none!important

.main-ctn
	position: absolute
	width: 100%
	height: 100%
	min-height: 100%

.inspector-container
	display: flex
	position: fixed
	top: 40px
	bottom: 0
	right: 0
	z-index: 80

	.bt-nav
		left: auto
		transform: translate(-7px, 0px)

	.bt-nav.open
		margin-left: 20px

	.bt-nav.geo
		margin-left: 460px

.inspector-transition
	transform: translateX(0%)
	transition: all .1s .1s ease;

	&.inspector-transition-enter-active
		transform: translateX(100%)
		z-index: 200
	&.inspector-transition-leave-active
		transform: translateX(100%)
		z-index: 199
		transition: all .5s ease;
	&.inspector-transition-leave-active.second-panel-open
		transform: translateX(100%)

.panel-transition
	transition: width .1s .1s ease

	&.panel-transition-enter-active
	&.panel-transition-leave-active
		width: 420px
	&.panel-transition-enter
	&.panel-transition-leave-to
		width: 0


.inspector-panel
	padding-left: 20px
	overflow: auto
	.inspector-content
		background-color: var(--inspectorColor)
		box-shadow: 0 0 10px var(--shadowColor)
		padding-bottom: 0.1px
		min-height: 100%
		width: 420px
	&.geo
		padding-left: 460px

	&.obj
		padding-left: 0
		box-shadow: 0 0 10px var(--shadowColor)

		.textinput-ctn.has-unit
			input
				width: 60px !important

		.unit
			margin-top: -2px
			margin-right: -70px!important
			padding-right: 8px
			line-height: 30px!important
			width: 47px

		.textinput-ctn.color-field
			input
				width: 86px !important
				margin-left: 5px

		.color-square
			margin-top: 0px
			width: 64px

		.edit-bt
			background: var(--bgColor)
			color: var(--inspectorText)
			width: 165px
			border-radius: 4px
			.label
				color: var(--inspectorText)

		.value.vector2D.x
			width: 72px !important
			margin-left: 5px
			float: left

		.value.vector2D.y
			width: 68px !important

		.value.vector3D.x
			width: 41px !important
			margin-left: 5px
			float: left

		.value.vector3D.y
			width: 42px !important
			margin-left: 5px
			float: left

		.value.vector3D.z
			width: 42px !important
			margin-left: 5px
			float: left

		.mapboxgl-map
			margin-left: -10px

.arraypan-transition
	transform: translateY(0%)
	transition: all .5s .3s ease;

	&.arraypan-transition-enter-active
		transform: translateY(-100%)
	&.arraypan-transition-leave-active
		transform: translateY(-100%)
		transition: all .5s ease;

.modal-transition
	transform: scale(1)
	opacity: 1
	transition: all .3s ease;

	&.modal-transition-enter-active
		transform: scale(1.3)
		opacity: 0
	&.modal-transition-leave-active
		transform: scale(1.3)
		opacity: 0

</style>
