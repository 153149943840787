
<template lang="pug">

div#content
    h1 Memories 

    p#chapo The memories in Nodal.Studio concern the data used by the triggers or shared between experiences, screens or modules. It can be compared to variables used all over the project.

    p The memories have to be given different properties.
    p Each time a user add a new memory, he has to set a type first :
        ul.listing
            li Angle
            li Array (a specific interface allows to parameter the array)
            li Asset (chosen via the library)
            li Boolean
            li Color
            li Float
            li Integer
            li Percent
            li String
            li Vector
    
    h2 Global settings
    h3 Name
    p The name given to a memory is a String that will be used to reference the memory everywhere in the project.

    h3 Shared
    p When the Share option of the memory is activated, it will be shared between all the client connected to the same project. If a client changed the value, all the others will be updated with this new value.

    h3 Persistent
    p When the Persistent option of the memory is activated, it stores the last updated value in the database, even if a default value was set.

    h2 Value
    p When a memory is created, a default value can be given.<br /> If the Persistent option of the memory is activated, it will overwrite this value every time it is updated.


    p <br /><br /><br />

</template>

<script>

	export default {


		props:[],

		data() {
			return {
			};
		},

		created: function(){

		},


	    methods:{


	    }

    }

</script>

<style>


</style>