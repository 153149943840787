var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c(
      "div",
      { attrs: { id: "popup-window" } },
      [
        _c("div", {
          staticClass: "cover-back",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.abord($event)
            }
          }
        }),
        _c(_vm.component, {
          tag: "component",
          staticClass: "popup-content",
          attrs: { settings: _vm.settings }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }