import ActionAbstract from "../ActionAbstract";
import * as helper from '../../helper';
import Vue from 'vue';

class ConnectionChange extends ActionAbstract {

    static get ID() {
        return 'actions:inspector:connectionChange';
    }

    preRun(state) {

        this._bloc = null;
        this._path = null;

        this._project = state.project;

        [this._bloc, this._path] = helper.block.getBlockById(state.project, this.getParam('bloc_id'), true);

        this._composite = {project: {}};
        this._compositeValue = {};

        let fieldIndex = this._bloc.value.fields.findIndex((f) => {
            return f.name === this.getParam('fieldName');
        })
        let fieldPath = 'fields[' + fieldIndex + ']';

        this._composite.project[this._path] = {
            value: {
            }
        }

        this._composite.project[this._path].value[fieldPath] = this._compositeValue;
    }

    postRun() {
        this.trigger('block:updateFields', { id: this._bloc.value._id } );
        Vue.nextTick( function(){
            this.trigger('editor:schematic:checkLinks', { bloc_id: this._bloc.value['ref_' + this._bloc.value.level] });         
            this.trigger('block:updateAnchorPositions', { id: this._bloc.value._id } );      
        }.bind(this));        
    }

    runAddIn (state) {
        this._compositeValue.in = true;

        return this.composeState(state, this._composite);
    }

    runRemoveIn(state) {
        this._compositeValue.in = false;

        return this.composeState(state, this._composite);
    }

    runAddOut(state) {
        this._compositeValue.out = true;

        return this.composeState(state, this._composite);
    }

    runRemoveOut(state) {
        this._compositeValue.out = false;

        return this.composeState(state, this._composite);
    }

}

export default ConnectionChange;