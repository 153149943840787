import deepCopy from 'deepcopy';
import Vue from 'vue';

import ServiceAbstract from '../services/ServiceAbstract';

const REMOVE_KEY_FROM_STATE = "_____REMOVE_KEY_FROM_STATE_____";
const ARRAY_ORDERS = [
    '_arrayPush',
    '_arraySplice',
    '_arrayUnshift'
]

class ActionAbstract extends ServiceAbstract {

    static get ID() {
        // to be implemented in child class
    }

    beforeCreate() {
        this.params = {};
    }

    setParams(params = {}) {
        this.params = params;
    }

    getParams() {
        return this.params;
    }

    getParam(param) {
        return this.params[param];
    }

    trigger(action, payload) {
        this.deps.actionManager.trigger(action, payload);
    }

    removeKey() {
        return REMOVE_KEY_FROM_STATE;
    }

    arrayUnshift(values) {
        return [
            ARRAY_ORDERS[2],
            values
        ];
    }

    _arrayUnshift(src, param, srcParent, key) {
        let index = src.length;
        let data = Array.isArray(param[1]) ? param[1] : [param[1]];
        let arrCopy = src.slice();
        arrCopy.reverse();
        arrCopy.push(...param[1]);
        arrCopy.reverse();
        srcParent[key] = arrCopy;
    }

    arrayPush(valueToPush) {
        return [
            ARRAY_ORDERS[0],
            valueToPush
        ];
    }

    _arrayPush(src, param) {
        let index = src.length;
        let data = Array.isArray(param[1]) ? param[1] : [param[1]];
        for (let i=0; i<data.length; i++) {
            Vue.set(src, i + index, data[i])
        }
    }

    arraySplice(indexToRemove, lengthToRemove = 1) {
        return [
            ARRAY_ORDERS[1],
            indexToRemove,
            lengthToRemove
        ];
    }

    _arraySplice(src, param) {
        //console.log('// ---> splice', deepCopy(src), param)
        if (param[2] > 1) {
            for (let i=0; i<param[2]; i++) {
                let key = src[(param[1] + i)]
                let idx = src.indexOf(key)
                // TODO: remove this warning
                // previous method splice the arrray only if element found: https://v1.vuejs.org/api/#array-remove-reference
                // since migration Vue v1 to v2
                if (idx==-1) console.warn("Element not found: ", key)
                else src.splice(idx, 1)
            }
        } else {
            let data = Array.isArray(param[1]) ? param[1] : [param[1]];
            if(typeof data[0] === 'number'){
              data.reverse();
            } else {
              data.sort().reverse();
            }
            data.forEach( (val) => {
                let key = src[val]
                let idx = src.indexOf(key)
                // TODO: remove this warning
                // previous method splice the arrray only if element found: https://v1.vuejs.org/api/#array-remove-reference
                // since migration Vue v1 to v2
                if (idx == -1) console.warn("Element not found: ", key)
                else src.splice(idx, 1)
            });
        }
    }

    composeState(state, obj, fullState = null) {

        let stateCopy = state;
        if (!stateCopy) {
            stateCopy = {}
        }
        let newKey = (base, key, value) => {
                Vue.set(base, key, value);
            },
            setKey = (base, key, value) => {
                base[key] = value
            },
            processKey = (key) => {
                let setterFn = null,
                    nested = false,
                    tabIndex = null;

                if (key.indexOf('.') !== -1 || key.indexOf('[') !== -1) {

                    let keyTab = key.replace(/^\.|\.$/gm,'').split('.'),
                        newKey = keyTab.shift();

                    let tabIndexMatch = newKey.match(/([a-zA-Z0-9_]*)\[([0-9]*)\]/);
                    if (tabIndexMatch !== null) {
                        newKey = tabIndexMatch[1];
                        tabIndex = tabIndexMatch[2];
                    }

                    obj[newKey] = {};
                    if(keyTab.length) {
                        obj[newKey][keyTab.join('.')] = obj[key];
                    } else {
                        obj[newKey] = obj[key];
                    }
                    obj[key] = null
                    delete obj[key];

                    key = newKey;
                }

                if (stateCopy[key] !== undefined && stateCopy[key] !== null) {
                    setterFn = setKey;
                } else {
                    setterFn = newKey;
                }

                if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                    let base = tabIndex !== null ? stateCopy[key][tabIndex] : stateCopy[key];
                    if (setterFn === newKey)
                        setterFn(stateCopy, key, this.composeState(base, obj[key], stateCopy));
                    else
                        this.composeState(base, obj[key], stateCopy)
                } else {
                    if ( Array.isArray(obj[key]) && ARRAY_ORDERS.indexOf(obj[key][0]) != -1 && Array.isArray(stateCopy[key]) ) {
                        let base = tabIndex !== null ? stateCopy[key][tabIndex] : stateCopy[key];
                        this[obj[key][0]].call(this, base, obj[key], stateCopy, key);
                    } else if (obj[key] === REMOVE_KEY_FROM_STATE) {
                        if (stateCopy && stateCopy[key] !== undefined && stateCopy[key] !== null) {
                            Vue.delete(stateCopy, key);
                        }
                    } else {
                        if (typeof obj[key] === 'function') {
                            setterFn(stateCopy, key, obj[key].call(this, stateCopy && stateCopy[key] !== undefined && stateCopy[key] !== null ? stateCopy[key] : {}, fullState));
                        } else {
                            setterFn(stateCopy, key, obj[key]);
                        }
                    }
                }
            };

        let newKeys = Object.keys(obj);

        newKeys.forEach(processKey);

        return stateCopy;
    }

    preRun() {

    }

    run(state) {

    }

    postRun() {

    }
}

export default ActionAbstract;
