var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _c("div", { staticClass: "nav", class: { open: _vm.navigatorOpen } }, [
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              class: { active: _vm.currentFilter == "all" },
              on: {
                click: function($event) {
                  return _vm.filterButtons("all")
                }
              }
            },
            [_c("span", [_vm._v("All projects")])]
          )
        ]),
        _c("li", [
          _c(
            "a",
            {
              class: { active: _vm.currentFilter == "owner" },
              on: {
                click: function($event) {
                  return _vm.filterButtons("owner")
                }
              }
            },
            [_c("span", [_vm._v("Created by me")])]
          )
        ]),
        _c("li", [
          _c(
            "a",
            {
              class: { active: _vm.currentFilter == "user" },
              on: {
                click: function($event) {
                  return _vm.filterButtons("user")
                }
              }
            },
            [_c("span", [_vm._v("Shared with me")])]
          )
        ])
      ])
    ]),
    _c("div", {
      staticClass: "bt-nav",
      class: { open: _vm.navigatorOpen },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.toggleNavigator($event)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }