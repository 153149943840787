var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion targets-bloc", class: { hide: _vm.isTemplate } },
    [
      _c(
        "div",
        {
          staticClass: "accordion-title",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.accordion = !_vm.accordion
            }
          }
        },
        [
          _c("span", { staticClass: "text" }, [_vm._v("Process triggering")]),
          _c("span", { staticClass: "arrow", class: { close: !_vm.accordion } })
        ]
      ),
      _c(
        "div",
        { staticClass: "form-content", class: { open: _vm.accordion } },
        [
          _c("div", { staticClass: "form-accordion isAccordion" }, [
            _vm._m(0),
            _c("div", { staticClass: "form-accordion-content open" }, [
              _c(
                "span",
                { staticClass: "textinput-ctn trigger-select parentScreen" },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v("Starts With Parent Screen \t\t\t\t")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "checkbox",
                      class: { selected: _vm.choice },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.toggleValue()
                        }
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.choice,
                            expression: "choice"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.choice)
                            ? _vm._i(_vm.choice, null) > -1
                            : _vm.choice
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.choice,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.choice = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.choice = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.choice = $$c
                            }
                          }
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "clear", staticStyle: { height: "10px" } })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "text" }, [_vm._v("Process Auto Start")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }