import ActionAbstract from '../ActionAbstract';
import { FILTERS } from './SetFilter';

class ListAll extends ActionAbstract {

    static get ID() {
        return 'actions:projects:listAll';
    }

    run(state) {
        var self = this;

        switch(this.getParam('subaction')) {
            case 'listprojects':
                return this.composeState(state, {
                    isLoading: false,
                    projects: {
                        list: this.getParam('projects')
                    },
                    project: this.removeKey()                                        
                });
                break;
            default:
                this.deps.mainStore.projects.getProjects(this.getParam('forceRefresh') ).then( ( response ) => {

                    for( var project of response ){

                        let display = true;
                        let isOwner = project.value.owner === state.user ? true : false;

                        let currentFilterEqual = (filterType) => {
                            if (state.projects.currentFilter === filterType) return true
                            else return false
                        }
                        
                        let matchSearch = () => {
                            let searchName = state.projects.currentFilter.replace('search_', '')
                            return project.value.name.toLowerCase().includes(searchName) ? true : false
                        }

                        if (state.projects && state.projects.currentFilter && !currentFilterEqual(FILTERS.ALL)) {

                            if (currentFilterEqual(FILTERS.OWNER) && !isOwner || currentFilterEqual(FILTERS.USER) && isOwner) {
                                display = false;
                            }
                            
                            if(state.projects.currentFilter.includes('search_') && !matchSearch()) display = false
                        }

                        project.custom.display = display;
                    }

                    this.trigger('projects:listAll', {subaction: 'listprojects', projects: response});

                });
                return this.composeState(state, {
                    isLoading: true,
                    projects: {
                        list: []
                    }
                });
                break;
        }

    }

}

export default ListAll;