
import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import Vue from 'vue';

class UpdateAnchorPositions extends ActionAbstract {

    /**
     * @param id : block id
    **/

    static get ID() {
        return 'actions:block:updateAnchorPositions';
    }

    run(state) {

        let ids = Array.isArray(this.getParam('id')) ? this.getParam('id') : [this.getParam('id')];

        ids.forEach( (id) => {

            //this.compositeState = { project : { } };
            [this.block, this.path] = helper.block.getBlockById( state.project, id, true );

            let orderIn = 0;
            let orderOut = 0;

            if( this.block && this.block.custom.states ){
                let states = Object.keys(this.block.custom.states);
                states.forEach( ( stateName ) => {
                    let state = this.block.custom.states[stateName]
                    let anchor = this.getAnchorPosition( id, stateName );

                    let order = { input : null, output : null };
                    if( state.in ){
                        order.input = orderIn;
                        orderIn++;
                    }
                    if( state.out ){
                        order.output = orderOut;
                        orderOut++;
                    }
                    
                    Vue.set( state, 'anchorPos' , anchor );
                    Vue.set( state, 'order' , order );
                    //this.compositeState.project[this.path+'.custom.states['+stateName+'].order'] = order;
                    //this.compositeState.project[this.path+'.custom.states['+stateName+'].anchorPos'] = anchor;
                });
            }

            if( this.block && this.block.custom.fields ){
                let fields = Object.keys(this.block.custom.fields);
                fields.forEach( ( fieldname ) => {
                    let field = this.block.custom.fields[fieldname]
                    let anchor = this.getAnchorPosition( id, fieldname );

                    let order = { input : null, output : null };
                    if( field.in ){
                        order.input = orderIn;
                        orderIn++;
                    }
                    if( field.out ){
                        order.output = orderOut;
                        orderOut++;
                    }

                    Vue.set( field, 'anchorPos' , anchor );
                    Vue.set( field, 'order' , order );
                    
                    //this.compositeState.project[this.path+'.custom.fields['+fieldname+'].order'] = order;
                    //this.compositeState.project[this.path+'.custom.fields['+fieldname+'].anchorPos'] = anchor;

                });
            }


            //this.composeState(state, this.compositeState);
        });
        
        return false;
    }

    getAnchorPosition(id, fieldname ){
        // get anchor position
        let input = helper.links.getAnchorCoordinates( id, 'input', fieldname );
        let output = helper.links.getAnchorCoordinates( id, 'output', fieldname );
        return {
            input : input ? input : { x: 0 , y : 0 },
            output : output ? output : { x: 0 , y : 0 }, 
        };

    }


}

export default UpdateAnchorPositions;