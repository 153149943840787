import ActionManager from '../ActionManager';

import CreateBlock from './CreateBlock';
ActionManager.register(CreateBlock, ['stores:MainStore']);

import CreateAssociatedObject from './CreateAssociatedObject';
ActionManager.register(CreateAssociatedObject, ['stores:MainStore']);

import DeleteBlock from './DeleteBlock';
ActionManager.register(DeleteBlock, ['stores:MainStore']);

import UpdateFields from './UpdateFields';
ActionManager.register(UpdateFields, ['stores:MainStore']);

import UpdateAnchorPositions from './UpdateAnchorPositions';
ActionManager.register(UpdateAnchorPositions, ['stores:MainStore']);

import UpdateIncludedBlocks from './UpdateIncludedBlocks';
ActionManager.register(UpdateIncludedBlocks, ['stores:MainStore']);

import CopyBlock from './CopyBlock';
ActionManager.register(CopyBlock, ['stores:MainStore']);

import PasteBlock from './PasteBlock';
ActionManager.register(PasteBlock, ['stores:MainStore']);

import ImportJson from './ImportJson';
ActionManager.register(ImportJson, ['stores:MainStore']);

import UpdateCompositeName from './UpdateCompositeName';
ActionManager.register(UpdateCompositeName, ['stores:MainStore']);

