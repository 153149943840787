var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-touch",
    {
      staticClass: "container-bloc",
      style: { top: _vm.coordinates.y + "px", left: _vm.coordinates.x + "px" },
      on: { pan: _vm.onPan }
    },
    [
      _c("bloc", {
        key: _vm.family.length,
        attrs: {
          "app-state": _vm.appState,
          bloc: _vm.bloc,
          dragging: _vm.dragging,
          family: _vm.family,
          workspace: _vm.workspace
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }