
<template lang="pug">

div.accordion.targets-bloc
	div.accordion-title(v-on:click.stop="accordion = !accordion ")
		span.text Training Set
		span.arrow(v-bind:class="{'close':!accordion}")

	div.form-content(v-bind:class="{'open':accordion}")

		div.form-accordion.selectSet.isAccordion()

			div.title
				span.text Training Set list
				span.description-bull(v-on:click="displayDescription") ?


			div.big-bt.field-group

				span.textinput-ctn.fullsize
					span.select-arrow &gt;
					select(v-on:change="valueUpdated", v-model="selectedTrainSet" )
						option(v-bind:value="0") Select a training set
						option(v-for="(train,i) of trainingSets", v-bind:value="train._id") {{train.name}}

				div.clear

				div.big-bt.bt-repeat-form(v-on:click="addTrain", v-if="target && target.value && target.value.type !== 'gesture-recognizer'")
					span.text Create a new Training set
					span.bt-plus +


	div.clear(style="height:10px")

</template>

<script>

import * as helper from '../../../helper';
import actionManager from '../../../actions';

export default {

	props:['project', 'target', 'bloc'],

	data() {
		return {
			selectedTrainSet: 0,
			accordion: true,
			widgetDoc: "A Training Set is a collection of gestures. You can create as many Training Set as you want. The Training Set selected will be the one in which the gestures to be created and recorded will be added."
		}
	},

	computed: {

		'trainingSets' : function(){
			if( this.project.value && this.project.value.trainingSets )
				return this.project.value.trainingSets;
			return [];
		},


	},

	watch: {

	},

	created: function(){
		let trainingSetIdField = helper.block.getField(this.target, "trainingSetId");
		if( trainingSetIdField && trainingSetIdField.value ){
			this.selectedTrainSet = trainingSetIdField.value;
		}

	},

	destroyed: function(){

	},

	methods:{

		addTrain : function(){
			actionManager.trigger('main:modal:openModal', {title : "New Training Set name", hasInput : true, inputName : "name", nextAction : 'project:addTrainingSet', icon:'new' })
		},

		valueUpdated: function(){
			actionManager.trigger('inspector:updateValue', { subaction: this.target.value.type, targetId: this.target.value._id, fieldName: 'trainingSetId', newValue: this.selectedTrainSet } )
		},

		displayDescription: function(){
			actionManager.trigger('main:modal:openModal', {title:"Traingin Set", text:this.widgetDoc, continueButton: 'OK', cancelButton: '', icon: 'help' })
		},
	},

}

</script>

<style lang="stylus">

.textinput-ctn.fullsize
	width: 97%
	margin: 1%
	height: 30px

	select
		height: 30px
		font-size: 13px


.accordion .big-bt.field-group, .accordion .input-ctn.field-group
	/*padding: 4px;*/

</style>
