import ActionAbstract from '../../ActionAbstract';

class CloseGeoModal extends ActionAbstract {

    static get ID() {
        return 'actions:main:geomodal:close';
    }

    run(state) {
        return this.composeState(state, {
            main: {
                geomodal: {
                  open: false,
                  fieldvalue: null,
                  modalId: null,
                }
            }
        });
    }

}

export default CloseGeoModal;
