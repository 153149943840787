<!-- ParentNodeField vue -->

<template lang="pug">

span.textinput-ctn
	input.value(type="text", v-model="displayedValue", disabled="true", v-if="modeList === false")
	
	span(v-else)
		span.select-arrow &gt;
		select(v-on:change="valueUpdated(field)", v-model="value", :disabled="field.disabled")
			option(v-for="(w,i) of nodeList", v-bind:value="w.value") {{w.label}}
</template>

<script>

import * as helper from '../../../helper';

export default {

	components: { },

	props:[ 'field' , 'fieldvalue' , 'bloc' ],

	data() {
		return {
			value: null,
			displayedValue : null,
            nodeList: [],
			modeList: false,
			widgetList: [],
			inScreen : false,
			// TODO: prefers pass app.state to childrens, $root or $children references could change
			// (migration from v1: this.$root)
			app: this.$root.$children[0]
		}
	},

	created: function(){

		if( this.field.selectNodeType === "process-instance" ){
			this.getProcessInstance( );
			this.modeList = true;
		}
		else{
	        this.getParents( this.bloc );
		}

        this.value = this.fieldvalue.value ? this.fieldvalue.value : null ;

        if( this.value === null ){

			if( this.field.defaultNodeLevel ){
				this.nodeList.forEach( (parent) => {
					if( parent.value.type == this.field.defaultNodeLevel ){
						this.value = parent.value._id;
						this.displayedValue = parent.value.name;
						this.valueUpdated();
					}
				});
			}
	
			if( this.field.defaultNodeLevel === "widget" && this.value === null ){
				
				if( this.widgetList && this.widgetList.length > 0 ){
					this.value = this.widgetList[0].value; // by default, select first widget of list
				}

				this.nodeList = this.widgetList;
			}	
        }
		else{
			let parent = helper.block.getBlockById( this.app.state.project, this.value );
			this.displayedValue = parent.value.name;
		}

		if( this.field.defaultNodeLevel === "widget" && this.inScreen === true ){
			// -> display a list
			this.modeList = true;
		}

	},

	destroyed: function(){

	},

	watch:{

	},

	methods:{

		valueUpdated: function( ){
			this.$parent.valueUpdated( this.field , this.value );
		},

        getParents( node ){

			let self = this;
			
			// On parcours récursivement les parents du module à la recherche des object auquel on a acces
			let parentID = node.value['ref_' + node.value.level ]
			if( parentID ){
				let parent = helper.block.getBlockById( this.app.state.project, parentID );

				if( parent.value.type === "screen" )
					this.inScreen = true;

				if( parent.value.type === "experience" && parent.custom.children ){
					let widgets = parent.custom.children.filter( ( c ) => { if ( c.value.type === "widget" ) return c; return null; } );
					this.widgetList = widgets.map( ( c ) => { return { label : c.value.name, value : c.value._id } ; } );
				}

				this.nodeList.push(parent);
				this.getParents( parent );
			}
		},

		getProcessInstance() {
			
			// get parent (screen or xp)
			let processId = this.bloc.value['ref_' + this.bloc.value.level ]
			let processParent = helper.block.getBlockById( this.app.state.project, processId );

			let parentId = processParent.value['ref_' + processParent.value.level ];
			let parent = helper.block.getBlockById( this.app.state.project, parentId );

			if( parent ){

				let processes = parent.custom.children.filter( ( c ) => { if ( c.value.type === "process" ) return c; return null; } );

				processes.forEach( (p) => {
					// The bad named 'template' name value is defined in blocks.json (BLOCK_PROCESS), should be 'instance' instead.
					// @todo:fixme: find a way to renamed this property without breaking projets, or leave as this.
					let templateField = p.value.fields.find( (f) => { return f.name === "template" });
            		if( templateField && templateField.value === true )
                		this.nodeList.push(  { label : p.value.name, value : p.value._id } );
				});


			}
		}

	}

}

</script>

<style lang="stylus">
</style>