var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "block-menu noselect" }, [
    _vm.menu.list
      ? _c(
          "div",
          { staticClass: "blockList", class: { open: _vm.menu.open } },
          [
            _vm.menu.open
              ? _c("bloc-group", {
                  attrs: { group: _vm.recentlyUsed, level: 0 }
                })
              : _vm._e(),
            _vm._l(_vm.menu.list, function(group) {
              return _vm.menu.open
                ? _c("bloc-group", { attrs: { group: group, level: 0 } })
                : _vm._e()
            }),
            _vm.allowImportBlock
              ? _c("div", { staticClass: "block-group" }, [
                  _c("ul", [
                    _c(
                      "li",
                      { staticClass: "title" },
                      [
                        _c("icon", { attrs: { name: "others" } }),
                        _c("span", { staticClass: "text" }, [_vm._v("Others")])
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "block noselect import",
                        on: {
                          click: function($event) {
                            return _vm.importToScene()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "text" }, [
                          _vm._v("Import Block")
                        ])
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      : _vm._e(),
    _c("div", { staticClass: "searchBar", class: { open: _vm.menu.open } }, [
      _c("span", { staticClass: "ico-loupe" }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        attrs: { id: "itemBlocSearch", autofocus: "", autocomplete: "off" },
        domProps: { value: _vm.search },
        on: {
          keyup: function($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 27) {
              return null
            }
            return _vm.toggleListBlock($event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          }
        }
      })
    ]),
    _c("div", {
      staticClass: "btAddBlock",
      class: { open: _vm.menu.open },
      on: { click: _vm.toggleListBlock }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }