// import Immutable from 'immutable';
// import patch from 'immutable-diff';

import ServiceAbstract from '../services/ServiceAbstract';

var isPlayingStack = false;

class ActionHandler extends ServiceAbstract {

    beforeCreate() {
        this._stack = [];
        this._count = 0;
    }

    static get ID () {
        return 'actions:ActionHandler';
    }

    pushStack(action) {
        this._stack.push({ number: this._count, action});
        this._count++;
        this.handleStack();     // treat all actions asynchronously
        //this.flush();         // treat the stack list one by one
    }

    // async (old method)
    // @todo: to be removed if the sync method is validated
    handleStack() {
        if (isPlayingStack)
            return;

        isPlayingStack = true;
        let newState = this.deps.mainStore.state;
        let shouldSave = false;

        while(this._stack.length) {

            let stack  = this._stack.shift();
            let number = stack.number;
            let action = stack.action;

            this.deps.eventLogger.log('playing action : ' + action.constructor.ID + ' with parameters : ' + JSON.stringify(action.getParams()))

            action.preRun(newState);
            this.deps.eventLogger.logAction(action.constructor.ID, number, 'preRun');

            let methodName = 'run';
            if (action.getParam('subaction') !== undefined) {
                let subaction = action.getParam('subaction');
                let searchedMethodName = 'run' + subaction.substr(0, 1).toUpperCase() + subaction.substr(1);
                if (typeof action[searchedMethodName] === 'function') {
                    methodName = searchedMethodName;
                }
            }

            if (typeof action[methodName] === 'function') {
                shouldSave = action[methodName](newState) || shouldSave;
                this.deps.eventLogger.logAction(action.constructor.ID, number, methodName);
            } else {
                throw new Error(`can't find method ${searchedMethodName} on class ${action.constructor.name}`);
            }

            action.postRun();
            this.deps.eventLogger.logAction(action.constructor.ID, number, 'postRun');
        }

        // 
        if( shouldSave )
            this.deps.mainStore.saveCurrentState();

        this.deps.eventLogger.log('new state : ');
        this.deps.eventLogger.log(this.deps.mainStore.stateHistory[this.deps.mainStore.stateHistory.length - 1]);

        isPlayingStack = false;
    }

    flush() {
        if (!this._stack.length || isPlayingStack) return;
        else isPlayingStack = true;


        const logger = this.deps.eventLogger;
        const state  = this.deps.mainStore.state;
        const stack  = this._stack.shift();
        const number = stack.number;
        const action = stack.action;

        let shouldSave = false;


        logger.logAction(action.constructor.ID, number, 'start');
        logger.log('playing action : ' + action.constructor.ID + ' with parameters : ' + JSON.stringify(action.getParams()))

        // define action
        let methodName = 'run';
        if (action.getParam('subaction') !== undefined) {
            let subaction = action.getParam('subaction');
            let searchedMethodName = 'run' + subaction.substr(0, 1).toUpperCase() + subaction.substr(1);
            if (typeof action[searchedMethodName] === 'function') {
                methodName = searchedMethodName;
            }
        }
        
        // -handle error
        if (typeof action[methodName] !== 'function') {
            isPlayingStack = false;
            throw new Error(
                `can't find method ${searchedMethodName} on class ${action.constructor.name}`
            );
        }


        // preRun method call
        const doPre = () => {
            return new Promise(resolve => {
                action.preRun(state);
                resolve();
            });
        };
        // run and/or sub action methods call
        const doRun = () => {
            return new Promise(resolve => {
                const result = action[methodName](state);
                resolve(result);
            });
        };
        // postRun method call
        const doPost = () => {
            return new Promise(resolve => {
                action.postRun();
                resolve();
            });
        };



        // start process and wait for each promises to be finalized
        // before start the next one
        logger.logAction(action.constructor.ID, number, 'preRun');
        doPre().then( result => {
            logger.logAction(action.constructor.ID, number, methodName);
            doRun().then( result => {
                shouldSave = result || shouldSave;
                logger.logAction(action.constructor.ID, number, 'postRun');
                doPost().then( result => {
                    logger.logAction(action.constructor.ID, number, 'done');

                    isPlayingStack = false;

                    // treat the next one if needed
                    // or save the current state if no actions are requested
                    if (this._stack.length) this.flush();
                    else {
                        this.deps.mainStore.saveCurrentState();
                        logger.log('new state : ');
                        logger.log(this.deps.mainStore.stateHistory[this.deps.mainStore.stateHistory.length - 1]);
                    }
                });
            })
        });

    }
}

export default ActionHandler;
