/* 
 * OpenLayer layer: data that is rendered client-side.
 * https://openlayers.org/en/latest/apidoc/module-ol_layer_Vector-VectorLayer.html
 */

import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import NodalUi from '../../NodalUi';

// @todo: Use the ol package from npm and remove this dist, retrieve the version from the package
// and review all the code below with ol modules.
import ol from '../../../../vendor/ol';
import MapOL, { generateMarkerTexture } from './MapOL';
import { olVersion } from '../../../contexts';

const geoJSON = (new ol.format.GeoJSON());

export default class MapOLLayer extends NodalUi {

    constructor() {

        super();

        this.currentGroupName = null;
        this.layer = null;              // layer data that is rendered client-side
        this.source = null;             // source of features for this layers
        this.cluster = null;            // layer source to cluster marker data
        this.clusterStyles = [];        // the list of all clusters style as a cache for each cluster size
        this.features = new Map();      // list of key (i.e module UID) => ol/Feature~Feature
        this.featureGroups = {};
        this.currentGroupName = null;
        this.autoQueryRendered = false;
        this._requestAnimationFrame = null;
        this.tmpSource = new ol.source.Vector({ features: new ol.Collection() });

        // list of executed animations
        this.featureAnims = {
            transform: new Map(),
            followpath: new Map(),
        };

        this.clickListening = false;
        this.moveListening = false;
        this.moveEndListening = false;

        this._updateMarkerRenderedList = this._updateMarkerRenderedList.bind(this);
        this._mapClickHandler = this._mapClickHandler.bind(this);
        this._mapDoubleClickHandler = this._mapDoubleClickHandler.bind(this);
        this._mapMoveEndHandler = this._mapMoveEndHandler.bind(this);
        this._mapMoveHandler = this._mapMoveHandler.bind(this);
    }


    // Story Trip marker
    // TODO: think about a method to add custom marker texture
    // (see MapOL.js)
    /*generateTexture( name, colors, size, opacity, star ){
        
        let texture = generateMarkerTexture.call( this, name, colors, size, opacity, star );

        return new ol.style.Style({
            image: new ol.style.Icon(({
                anchor: [0.5, 0.5],
                crossOrigin: 'anonymous',
                img: texture.canvas,
                scale : window.devicePixelRatio ? 1/window.devicePixelRatio : 1,
                imgSize: texture ? [texture.width, texture.height] : undefined
            }))
        });
    }*/

    componentDidMount() {

        // source of features (original data)
        this.source = new ol.source.Vector({
            features: this.getUserFeatures(this.props)
        });

        // the clustered source
        if (this.props.cluster.enable) {
            this.cluster = new ol.source.Cluster({
                distance: this.props.cluster.distance,
                source: this.source
            });
        }

        // layer data that is rendered client-side
        this.layer = new ol.layer.Vector({
            source: this.cluster ? this.cluster : this.source,
            style:  this.getStyle.bind(this),
            zIndex: this.props.options.zIndex
        });

        this.context.mapComp.layers.push(this.layer);

        if (this.context.map) {
            this.context.map.addLayer(this.layer);
        }

        this.updateMinMaxResolution(this.props);
        this.props.mapLayerCreated(this.context.map, this.layer);
    }

    componentWillUnmount() {

        if (this.context.map) {
            this.clickListening = false;
            this.moveListening  = false;
            this.moveEndListening = false;
            this.context.map.removeLayer(this.layer);
            this.context.map.removeEventListener('singleclick');
            this.context.map.removeEventListener('click');
            this.context.map.removeEventListener('moveend');
            this.context.map.removeEventListener('dblclick');
            this.context.map.removeEventListener('pointermove');
        }

        if (this._requestAnimationFrame !== null) {
            clearInterval(this._requestAnimationFrame);
        }
    }

    componentWillUpdate(nextProps, nextState) {

        // detect update of layer visibility
        if (this.props.visibility != nextProps.visibility) {
            this.layer.setVisible(nextProps.visibility);
        }

        // detect update of min-max zoom
        if (this.context.map && (nextProps.options.maxZoom != this.props.options.maxZoom || nextProps.options.minZoom != this.props.options.minZoom)) {
            this.updateMinMaxResolution(nextProps);
        }

        // detect update of layer zIndex
        if (this.context.map && nextProps.options.zIndex != this.props.options.zIndex) {
            this.layer.setZIndex(nextProps.options.zIndex);
        }

        // detect update of list of features
        if (nextProps.triggerFeatures === true || this.geoJSONMarkers.features.length != nextProps.features.length) {
            this.upateFeaturesWithUserProperties(nextProps);
        }

        if (nextProps.culling === true && nextProps.createCullingZones === true)
            this.createCullingZones();

        // request automatic output of markers rendered list
        if (nextProps.autoOutputMarkersRendered === true && this.autoQueryRendered === false) {
            this.startAutoQueryRendered();
        }

        // stop automatic output of markers rendered list
        if (nextProps.autoOutputMarkersRendered === false && this.autoQueryRendered === true)
            this.stopAutoQueryRendered();

        // request output of markers rendered list - once
        if (nextProps.queryMarkersRendered === true)
            this._updateMarkerRenderedList();

        // listen click is not done
        if (!this.clickListening && this.context.map) {
            // remove the default map (or previous) listeners to stop event propagation
            this.context.map.removeEventListener('singleclick');
            this.context.map.removeEventListener('dblclick');
            // attach listeners to this layer
            this.context.map.on("singleclick", this._mapClickHandler);
            this.context.map.on('dblclick', this._mapDoubleClickHandler);
            this.clickListening = true;
        }

        // listen move is not done
        if (!this.moveListening && this.context.map) {
            this.context.map.on('pointermove', this._mapMoveHandler);
            this.moveListening = true;
        }

        if (!this.moveEndListening && this.props.culling === true) {
            this.context.map.on('moveend', this._mapMoveEndHandler);
            this.moveEndListening = true;
        }

        // this code is a non-sens: the layer have already this source
        // WARNING BREAK CLUSTERIZATION
        //if (nextProps.culling === false) {
        //    this.layer.setSource(this.source);
        //}


    }

    render() {
        return this.wrapIntoAuthoring(<div id="map-ol-layer" ref="mapLayer" ></div>, 0);
    }



    updateMinMaxResolution(props) {
        if (!this.context.map) return;
        let view   = this.context.map.getView();
        let minRes = view.getResolutionForZoom(Math.max(props.options.minZoom || 0, props.options.maxZoom || 30));
        let maxRes = view.getResolutionForZoom(Math.min(props.options.minZoom || 0, props.options.maxZoom || 30));
        this.layer.setMinResolution(minRes);
        this.layer.setMaxResolution(maxRes);
    }

    createCullingZones() {

        // clear culling zones
        Object.keys(this.featureGroups).forEach((groupName) => {
            this.featureGroups[groupName].source = null;
            this.featureGroups[groupName].extent = null;
            this.featureGroups[groupName].centerUnproj = null;
            this.featureGroups[groupName].center = null;
            delete this.featureGroups[groupName];
        });
        this.featureGroups = {};

        // recreate zones
        this.props.features.forEach((f) => {

            if (f && f.properties && f.properties.groupTag !== undefined) {
                let groupTags = f.properties.groupTag.replace(/\s/g, '').split(',');

                groupTags.forEach((groupTag) => {
                    if (!this.featureGroups[groupTag]) {
                        this.featureGroups[groupTag] = {
                            source: new ol.source.Vector({ features: new ol.Collection() })
                        };
                    }

                    this.featureGroups[groupTag].source.addFeature(geoJSON.readFeature(f, { featureProjection: 'EPSG:3857' }));
                });
            }

        });

        Object.keys(this.featureGroups).forEach((groupName) => {
            this.featureGroups[groupName].source.refresh();
            this.featureGroups[groupName].extent = this.featureGroups[groupName].source.getExtent();
            this.featureGroups[groupName].centerUnproj = ol.extent.getCenter(this.featureGroups[groupName].extent);
            this.featureGroups[groupName].center = this.mapToGeoCoordinate(his.featureGroups[groupName].centerUnproj);
        });

        this.updateSourceWithNearestExtent();
    }

    updateSourceWithNearestExtent() {

        /* -- ONLY NEAREST CENTER VISIBLE -- */

        if (this.props.cullingMethod === "nearest-center") {

            let viewCenter = this.mapToGeoCoordinate(this.context.map.getView().getCenter());

            let currentExtent = null;
            let currentDistance = null;
            Object.keys(this.featureGroups).forEach((groupName) => {
                let distance = ol.sphere.getDistance(this.featureGroups[groupName].center, viewCenter);
                if (currentExtent === null || currentDistance > distance) {
                    currentDistance = distance;
                    currentExtent = groupName;
                }
            });

            if (this.featureGroups[currentExtent] && this.featureGroups[currentExtent].source && this.currentGroupName != currentExtent) {
                this.currentGroupName = currentExtent;

                this.featureGroups[currentExtent].source.refresh();
                this.layer.setSource(this.featureGroups[currentExtent].source);

                this.props.currentCullingTagCallback(currentExtent);
            }
        }
        else {

            /* -- ALL NEAREST CENTERS VISIBLE -- */

            let viewExtent = this.context.map.getView().calculateExtent();

            let currentExtents = [];
            Object.keys(this.featureGroups).forEach((groupName) => {
                if (this.props.cullingMethod === "visible-centers") {
                    let containsCoordinate = ol.extent.containsCoordinate(viewExtent, this.featureGroups[groupName].centerUnproj);
                    if (containsCoordinate)
                        currentExtents.push(groupName);
                }
                else if (this.props.cullingMethod === "visible-boundingbox") {
                    let extentsIntersects = ol.extent.intersects(viewExtent, this.featureGroups[groupName].extent);
                    if (extentsIntersects)
                        currentExtents.push(groupName);
                }
            });

            this.tmpSource.clear();
            if (currentExtents.length > 0) {
                currentExtents.forEach((currentExtent) => {
                    this.tmpSource.addFeatures(this.featureGroups[currentExtent].source.getFeatures());
                })
            }
            this.tmpSource.refresh();
            this.layer.setSource(this.tmpSource);

            this.currentGroupName = currentExtents && currentExtents.length > 0 ? currentExtents.join(',') : '';
            this.props.currentCullingTagCallback(this.currentGroupName);
        }

    }




    /**
     * Apply opacity to the given color.
     * @param {string} color - The RGBA color definition.
     * @param {int} opacity - The feature opacity.
     * @returns {string} RGBA color definition.
     */
    getColor(color, opacity=1) {

        let rgba = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?([\d|.]+)[\s+]?/i);

        // apply opacity
        if (rgba && rgba.length === 5) {
            color = 'rgba('
                + parseInt(rgba[1]).toString() + ','
                + parseInt(rgba[2]).toString() + ','
                + parseInt(rgba[3]).toString() + ','
                + (parseFloat(rgba[4]) * opacity).toString()
                + ')';
        }

        return color;
    }

    /**
     * Create a new fill style with the given color and opacity.
     * @param {string} color - The RGBA color definition.
     * @param {int} opacity - The feature opacity.
     * @returns {Object} The ol/style/Fill style.
     */
    getFillStyle(color, opacity=1) {
        return new ol.style.Fill({ color: this.getColor(color, opacity) });
    }

    /**
     * Create a new Openlayer style for a marker feature.
     * @param {Object} feature - The marker feature.
     * @returns {Object} An ol/style/Style.
     */
    getMarkerStyle(feature) {

        // create the default icon if not exists in texture
        if (!this.context.mapComp.icons[feature.get('symbol')]) {
            // Story Trip marker (see above)
            // let iconMarker = this.generateTexture(feature.get('symbol'), feature.get('iconColor'), feature.get('iconSize'), feature.get('opacity'), null);                
            // this.context.mapComp.icons[ feature.get('symbol') ] = iconMarker;
            this.context.mapComp.icons[feature.get('symbol')] = new ol.style.Style({
                image: new ol.style.Circle({
                    radius: feature.get('iconSize') / 2,
                    fill: new ol.style.Fill({
                        color: this.getColor(feature.get('iconColor'), feature.get('opacity'))
                    })
                })
            });
            // @todo: to be set in verbose mode [ticket NS-113]
            //console.log("create the default marker icon", feature.get('symbol'));
        }

        // apply user properties
        let style = this.context.mapComp.icons[feature.get('symbol')];
        
        // position
        style.setZIndex(feature.get('zIndex'));

        // apply tranform
        style.getImage().setScale(feature.get('scale'));
        style.getImage().setOpacity(feature.get('opacity'));
        style.getImage().setRotation(feature.get('rotation'));

        // using image texture
        if (feature.get('hasTexture') === true) {

            if (feature.get('textureSize') !== null)
                style.getImage().setScale(feature.get('textureSize') * feature.get('scale'));

            if (feature.get('textureAnchor') !== null) {
                if (typeof style.getImage().setAnchor != "function")
                    console.warn("Could not set anchor for texture reference", feature.get('symbol'))
                else switch (feature.get('textureAnchor')) {
                    case "center":
                        style.getImage().setAnchor([0.5, 0.5]);
                        break;
                    case "left":
                        style.getImage().setAnchor([0, 0.5]);
                        break;
                    case "right":
                        style.getImage().setAnchor([1, 0.5]);
                        break;
                    case "top":
                        style.getImage().setAnchor([0.5, 0]);
                        break;
                    case "bottom":
                        style.getImage().setAnchor([0.5, 1]);
                        break;
                    case "top-left":
                        style.getImage().setAnchor([0, 0]);
                        break;
                    case "top-right":
                        style.getImage().setAnchor([1, 0]);
                        break;
                    case "bottom-left":
                        style.getImage().setAnchor([0, 1]);
                        break;
                    case "bottom-right":
                        style.getImage().setAnchor([1, 1]);
                        break;
                }
            }
        }

        // text
        let text = feature.get('text');
        if (text !== null && text != '') {

            let offset = feature.get('textOffset');
            let color = feature.get('textColor');

            style.setText(
                new ol.style.Text({
                    font: feature.get('fontSize') + 'px ' + feature.get('fontFamily'),
                    fill: this.getFillStyle(color, feature.get('opacity')),
                    offsetX: offset.x,
                    offsetY: offset.y,
                    scale: feature.get('scale'),
                    rotation: feature.get('rotation'),
                    text: text
                })
            )
        }
        else style.setText(null); // reset text of style

        return style;
    }
    
    /**
     * Create a new Openlayer style for a LinePath feature.
     * @param {Object} feature - The LinePath feature.
     * @returns {Object} An ol/style/Style.
     */
    getLineStyle(feature) {

        let style = new ol.style.Style({
            stroke: new ol.style.Stroke({
                color: '#000000',
                width: 2
            })
        });

        // apply user properties
        style.getStroke().setColor(this.getColor(feature.get('fill'), feature.get('opacity')));
        style.getStroke().setWidth(feature.get('width') * feature.get('scale'));
        style.getStroke().setLineCap(feature.get('cap'));
        style.getStroke().setLineJoin(feature.get('join'));
        style.getStroke().setLineDash(feature.get('dashPattern'));
        style.getStroke().setLineDashOffset(feature.get('dashOffset'));

        return style;
    }

    /**
     * Create a new Openlayer style for a cluster feature.
     * @param {Object} styles - The cluster options.
     * @param {int} size - The number of features in cluster.
     * @returns {Object} An ol/style/Style.
     */
    getClusterStyle(styles, size) {

        let texture = styles.useTexture && styles.texture.trim() ? styles.texture.trim() : null;
        let name  = texture || ['-cluster',styles.iconSize,size].join('-');
        let delta = Math.max(1, size * styles.growFactor);

        // create the default icon if not exists in texture
        if (!this.context.mapComp.icons[name]) {
             this.context.mapComp.icons[name] = new ol.style.Style({
                image: new ol.style.Circle({
                    radius: styles.iconSize / 2,
                    fill: new ol.style.Fill({
                        color: this.getColor(styles.iconColor)
                    })
                })
            });
            // @todo: to be set in verbose mode [ticket NS-113]
            //console.log("create the default cluster icon", name);
        }

        // apply user options 
        let style = this.context.mapComp.icons[name];
        
        // using image texture
        if (styles.useTexture) {

            if (styles.textureSize !== null)
                style.getImage().setScale(styles.textureSize*delta);

            if (styles.textureAnchor !== null) {
                if (typeof style.getImage().setAnchor != "function")
                    console.warn("Could not set anchor for cluster.")
                else switch (styles.textureAnchor) {
                    case "center":
                        style.getImage().setAnchor([0.5, 0.5]);
                        break;
                    case "left":
                        style.getImage().setAnchor([0, 0.5]);
                        break;
                    case "right":
                        style.getImage().setAnchor([1, 0.5]);
                        break;
                    case "top":
                        style.getImage().setAnchor([0.5, 0]);
                        break;
                    case "bottom":
                        style.getImage().setAnchor([0.5, 1]);
                        break;
                    case "top-left":
                        style.getImage().setAnchor([0, 0]);
                        break;
                    case "top-right":
                        style.getImage().setAnchor([1, 0]);
                        break;
                    case "bottom-left":
                        style.getImage().setAnchor([0, 1]);
                        break;
                    case "bottom-right":
                        style.getImage().setAnchor([1, 1]);
                        break;
                }
            }
        }
        else style.getImage().setScale(delta);

        // display the number of features
        if (styles.useNumber) {
            style.setText(
                new ol.style.Text({
                    text: size.toString(),
                    font: styles.fontSize + 'px ' + styles.fontFamily,
                    fill: this.getFillStyle(styles.numberColor),
                    offsetX: styles.numberOffset.x,
                    offsetY: styles.numberOffset.y
                })
            )
        }

        // above all
        style.setZIndex(1000);

        return style;
    }

    /**
     * Returns the feature style corresponding to the given feature.
     * @param {Object} feature - The ol/Feature.
     * @returns {Object} The ol/style/Style.
     */
    getStyle(feature) {

        let isCluster = feature.get('features') ? feature.get('features').length > 1 : false;
        let type = isCluster ? 'cluster' : feature.getGeometry().getType();
        let style = null;

        // retrieve single marker feature
        if (!isCluster && feature.get('features')) feature = feature.get('features')[0];


        // @todo: to optimize process for linepath or marker
        // create the style once and update the feature style
        switch(type) {

            // cluster
            case 'cluster' :
                let size = feature.get('features').length;
                style = this.getClusterStyle(this.props.cluster, size);
                // let size = feature.get('features').length;
                // style = this.clusterStyles[size];                           // get in cache first
                // if (!style) {
                //     style = this.getClusterStyle(this.props.cluster, size); // or create
                //     this.clusterStyles[size] = style;                       // and store
                // }
                break;

            // linepath
            case 'LineString':
                style = this.getLineStyle(feature);
                break;

            case 'Polygon':
                break;
                
            // marker
            default:
                style = this.getMarkerStyle(feature);
                break;
        }

        return style;
    }

    /**
     * Returns the current feature for the specified ID.
     * 
     * @param {int} id - The feature identifier.
     * @returns {Object} The ol/Feature.
     */
    getFeature(id) {
        return this.features.has(id) ? this.features.get(id) : null;
    }

    /**
     * Read all users features and returns ol/feature list.
     * @param {Object} props - The user properties containing the list of features
     * @returns {Array} The ol/Features list.
     */
    getUserFeatures(props) {
        this.geoJSONMarkers = {
            type: 'FeatureCollection',
            features: props && props.features ? props.features : []
        };
        return geoJSON.readFeatures(this.geoJSONMarkers, { featureProjection: 'EPSG:3857' });
    }



    /**
     * Add a new feature in layer source.
     * @param {int} id - The feature identifier.
     * @param {Object} feature - The feature object.
     */
    addFeature(id, feature) {

        // exclude linepath feature to avoid assertion error
        // https://openlayers.org/en/v5.3.0/doc/errors/#10
        if (this.cluster && feature.getGeometry().getType() != 'Point') {
            if (feature.get('type') == 'linepath')
                console.warn("MapOL LinePath cannot be included in a cluster layer, abort insertion.")
            else console.warn("feature cannot be included in a cluster layer, abort insertion.")
            return;
        }

        // @todo: to be set in verbose mode [ticket NS-113]
        //console.log("add map feature:", (feature.get('symbol') || "linepath"), ", [ UID:", id, "]");

        this.features.set(id, feature);
        this.source.addFeature(feature);
    }
    
    /**
     * Update the specified feature.
     * @param {int} id - The feature identifier.
     * @param {Object} feature - The feature object.
     */
    updateFeature(id, feature) {
        let current = this.getFeature(id);
        if (current) {

            let transform  = feature.get('transform');
            let animations = feature.get('animations');

            // start animatons (e.g. follow path)
            if ( animations ) {
                this.updateFollowPathAnimation(id, animations.followpath);
            }

            // transform styles
            if (transform && transform.animate) {
                this.startTransform(id, transform);
            }
            // or update with new styles
            else {
                // @todo: if transform exists, use setProperties to update the other properties without definting the whole style
                // @todo: to be set in verbose mode [ticket NS-113]
                //console.log("update map feature:", (feature.get('symbol') || "linepath"), ", [ UID:", id, "]");
                let style = this.getStyle(feature);
                current.setStyle(style);
            }

            // update position in both case
            current.setGeometry(feature.getGeometry());
        }
    }

    /**
     * Delete the specified feature.
     * @param {int} id - The feature identifier.
     */
    removeFeature(id) {
        let feature = this.getFeature(id);
        if (feature) {
            this.stopTransform(id);
            this.source.removeFeature(feature);
            this.features.delete(id);
        }
    }

    /**
     * Create all new feature, update existing and remove unused.
     * @param {Object} props - The user properties containing the list of features
     */
    upateFeaturesWithUserProperties(props) {

        let features = this.getUserFeatures(props);    // the required features list
        let unusedkeys = new Map(this.features);       // all actual features
        let dorefresh = false;

        // for all user fueatures
        //  add, update or do nothing
        for (let i = 0; i < features.length; i++) {

            let feature = features[i];
            let key = feature.get('key');

            // create
            if (!this.features.has(key)) {
                this.addFeature(key, feature);
                dorefresh = true;
            }
            // update
            else if (feature.get('hasChanged'))
                this.updateFeature(key, feature)

            // remove key from the deletion list
            unusedkeys.delete(key);

            // mark updated
            this.props.onFeatureUpdated(key);
        }

        // delete no longer used features
        unusedkeys.forEach((feature, id) => {
            this.removeFeature(id);
            dorefresh = true;
        });

        // force refresh
        if (dorefresh) {
            // do not use the 'refresh' method with ol version >=6.x.x
            if (typeof (olVersion) === 'undefined' || parseFloat(olVersion) < 6.0) {
                this.source.refresh();
            }
            else this.context.map.render();
        }
    }




    startAutoQueryRendered() {
        this.stopAutoQueryRendered();
        this.throttleRendered = this.props.autoOutputMarkersRenderedThrottle ? this.props.autoOutputMarkersRenderedThrottle : 500;
        this._requestAnimationFrame = setInterval(this._updateMarkerRenderedList, this.throttleRendered);
        this.autoQueryRendered = true;
    }

    stopAutoQueryRendered() {
        if (this._requestAnimationFrame !== null) {
            clearInterval(this._requestAnimationFrame);
        }
    }



    _updateMarkerRenderedList() {
        if (!this.context.map) return;

        let extent = this.context.map.getView().calculateExtent(this.context.map.getSize());
        let markersDrawn = [];

        this.source.forEachFeatureInExtent(extent,
            (f) => {
                markersDrawn.push(f.get("key"));
            });

        if (this.props.markerRenderedUpdated) {
            this.props.markerRenderedUpdated(markersDrawn);
        }
    }

    /**
     * Triggered when user click on the map, with no dragging.
     * @param {Object} e - The ol/MapBrowserEvent.
     */
    _mapClickHandler(e) {
        let trigged  = false;
        try {
            let features = this.context.map.getFeaturesAtPixel(e.pixel);
            if (features && features.length > 0) {
                trigged = features.some((feature) => {
                    if (feature && feature.get('captureClick') === true) {
                        let lonlat = this.mapToGeoCoordinate(e.coordinate,);
                        this.props.markerClickedFromLayerCallback(feature.get('key'), lonlat);
                        e.stopPropagation();
                        return true;
                    }
                })
            }
        }
        catch(err) { console.warn('Error while clicking on map feature:', err.message) }

        // call the default map listener
        if (!trigged) this.context.mapComp.mapClickHandler(e);
    }

    /**
     * Triggered when user double click on the map, with no dragging.
     * @param {Object} e - The ol/MapBrowserEvent.
     */
    _mapDoubleClickHandler(e) {
        let trigged = false;
        try {
            let features = this.context.map.getFeaturesAtPixel(e.pixel);
            if (features && features.length > 0) {
                trigged = features.some((feature) => {
                    if (feature && feature.get('captureDbClick') === true) {
                        let lonlat = this.mapToGeoCoordinate(e.coordinate);
                        this.props.markerDoubleClickedFromLayerCallback(feature.get('key'), lonlat);
                        e.stopPropagation();
                        return true;
                    }
                })
            }
        }
        catch (err) {  console.warn('Error while double clicking on map feature:', err.message) }

        // call the default map listener
        if (!trigged) this.context.mapComp.mapDoubleClickHandler(e);
    }

    /**
     * Triggered when a pointer is moved.
     * Note that on touch devices this is triggered when the map is panned, so is not the same as mousemove.
     * @param {Object} e - The ol/MapBrowserEvent.
     */
    _mapMoveHandler(e) {
        // do nothing while draggging
        if (!e.dragging && this.props.haveMouseListeners) {
            try {
                let features = this.context.map.getFeaturesAtPixel(e.pixel);
                this.props.onMouseMoved(features, this.mapToGeoCoordinate(e.coordinate));
            }
            catch (err) { console.warn('Error while moving on map feature:', err.message) }
        }
    }

    /**
     * Triggered after the map is moved.
     * @param {Object} e - The ol/MapBrowserEvent.
     */
    _mapMoveEndHandler(e) {
        if (this.props.culling === true)
            this.updateSourceWithNearestExtent();
    }


    /**
     * Stop transform animation for the given feature ID
     * @param {int} id - The feature identifier.
     */
    stopTransform(id) {
        if (this.featureAnims.transform.has(id)) {
            ol.Observable.unByKey(this.featureAnims.transform.get(id).listener);
            this.featureAnims.transform.delete(id);
            this.props.onTransformEnded(id);
        }
    }

    /**
     * Start transform animation for the given feature ID
     * @param {int} id - The feature identifier.
     * @param {Object} options - The animation options.
     */
    startTransform(id, options) {

        // stop previous transform animation
        this.stopTransform(id);


        // retrieve feature
        let feature = this.getFeature(id);
        if (!feature) {
            console.warn("Invalid feature.\nAbording")
            return;
        }

        // warn invalid properties
        // and set to default
        if (!options.duration) {
            console.warn("Invalid transform duration: ", options.duration, "\nAborting");
            return;
        }
        if (!options.easing) {
            console.warn("Invalid transform easing: using 'linear'");
            options.easing = "linear";
        }
        if (!options.property) {
            console.warn("Invalid transform property: using 'all'");
            options.property = "all";
        }


        // delete unused target property
        if (options.values.to.opacity && options.property != 'all' && options.property.indexOf('opacity') == -1) {
            delete options.values.to.opacity;
        }
        if (options.values.to.scale && options.property != 'all' && options.property.indexOf('scale') == -1) {
            delete options.values.to.scale;
        }
        if (options.values.to.rotation && options.property != 'all' && options.property.indexOf('rotation') == -1) {
            delete options.values.to.rotation;
        }
        if (options.values.to.translate && options.property != 'all' && options.property.indexOf('translate') == -1) {
            delete options.values.to.translate;
        }

        // bad casting: warn and remove property for this transformation
        if ('opacity' in options.values.to && isNaN(options.values.to.opacity)) {
            console.warn("Invalid opacity target: ", options.values.to.opacity);
            delete options.values.to.opacity;
        }
        if ('scale' in options.values.to && isNaN(options.values.to.scale)) {
            console.warn("Invalid scale target: ", options.values.to.scale);
            delete options.values.to.scale;
        }
        if ('rotation' in options.values.to && isNaN(options.values.to.rotation)) {
            console.warn("Invalid rotation target: ", options.values.to.rotation);
            delete options.values.to.rotation;
        }
        if ('translate' in options.values.to && (isNaN(options.values.to.translate.lat) || isNaN(options.values.to.translate.lng))) {
            console.warn("Invalid translate target: ", options.values.to.translate);
            delete options.values.to.translate;
        }

        // register requested user properties
        // warn and remove invlaid
        if ('opacity' in options.values.to) {
            let opacity = feature.get('opacity');
            if (!isNaN(opacity)) options.values.from.opacity = opacity;
            else {
                console.warn("Invalid opacity property: ", opacity);
                delete options.values.to.opacity;
            }
        }
        if ('scale' in options.values.to) {
            let scale = feature.get('scale');
            if (!isNaN(scale)) options.values.from.scale = scale;
            else {
                console.warn("Invalid scale property: ", scale);
                delete options.values.to.scale;
            }
        }
        if ('rotation' in options.values.to) {
            let rotation = feature.get('rotation');
            if (!isNaN(rotation)) options.values.from.rotation = rotation;
            else {
                console.warn("Invalid rotation property: ", rotation);
                delete options.values.to.rotation;
            }
        }

        // register requested translation
        if ('translate' in options.values.to) {

            let coords = feature.getGeometry().getCoordinates();
            let lonlat = this.mapToGeoCoordinate(coords);

            options.values.to.latitude    = options.values.to.translate.lat;
            options.values.to.longitude   = options.values.to.translate.lng;
            options.values.from.latitude  = lonlat[1];
            options.values.from.longitude = lonlat[0];

            options.translate = {
                from: feature.getGeometry().getCoordinates(),
                to: this.geoToMapCoordinate([options.values.to.longitude, options.values.to.latitude]),
            }

            delete options.values.to.translate;
        }

        // nothing to transform, abort
        if (Object.keys(options.values.to).length == 0) {
            console.warn("Aborting, no properties found for transform");
            return;
        }
        
        const tO = ('opacity' in options.values.to);
        const tS = ('scale' in options.values.to);
        const tR = ('rotation' in options.values.to);

        // add animation to OpenLayer and force postcompose
        let time = new Date().getTime();
        options.listener = this.context.map.on('postcompose', animate);
        this.featureAnims.transform.set(id, options);
        this.context.map.render();

        // @todo: to be set in verbose mode [ticket NS-113]
        // console.log("transform", (feature.get('symbol') || "linepath"), `[${id}]`,
        //     `\n\tproperty:  ${options.property}`,
        //     `\n\teasing:    ${options.easing}`,
        //     `\n\tduration:  ${options.duration/1000}s`,
        //     "\n\topacity:  ", (tO ? `${options.values.from.opacity} -> ${options.values.to.opacity}` : "X"),
        //     "\n\tscale:    ", (tS ? `${options.values.from.scale} -> ${options.values.to.scale}` : "X"),
        //     "\n\trotation: ", (tR ? `${options.values.from.rotation} -> ${options.values.to.rotation}` : "X"),
        //     "\n\ttranslate:", (options.values.to.latitude ? `[${options.values.from.latitude},${options.values.from.longitude}] -> [${options.values.to.latitude},${options.values.to.longitude}]` : "X")
        // )

        let me = this;
        function animate(event) {

            let elapsed = event.frameState.time - time;
            let elapsedRatio = elapsed / options.duration;
            let easing = 0;
            let values = {};  // will contain affected style properties

            switch (options.easing) {
                case "easeIn": easing = ol.easing.easeIn(elapsedRatio); break;
                case "easeOut": easing = ol.easing.easeOut(elapsedRatio); break;
                case "inAndOut": easing = ol.easing.inAndOut(elapsedRatio); break;
                case "upAndDown": easing = ol.easing.upAndDown(elapsedRatio); break;
                default: easing = ol.easing.linear(elapsedRatio); break;
            }


            // compute style properties
            if (tS) values.scale = mapValue(easing, 0, 1, options.values.from.scale, options.values.to.scale);
            if (tO) values.opacity = mapValue(easing, 0, 1, options.values.from.opacity, options.values.to.opacity);
            if (tR) values.rotation = mapValue(easing, 0, 1, options.values.from.rotation, options.values.to.rotation);

            // changes any existing properties
            // and update without triggering an event ! IMPORTANT
            feature.setProperties(values, true);
            feature.setStyle(me.getStyle(feature));


            // translate if needed
            if (options.translate) {
                let lat = easing * (options.translate.to[1] - options.translate.from[1]) + options.translate.from[1];
                let lon = easing * (options.translate.to[0] - options.translate.from[0]) + options.translate.from[0];
                let geo = me.mapToGeoCoordinate([lon, lat]);

                values.latitude  = geo[1];
                values.longitude = geo[0];

                feature.setGeometry(new ol.geom.Point([lon, lat]));
            }


            // stop or tell OpenLayers to continue postcompose animation
            if (elapsed > options.duration) {
                me.props.onFeatureStylesUpdated(id, options.values.to);
                me.stopTransform(id);
            }
            else {
                me.props.onFeatureStylesUpdated(id, values);
                me.context.map.render();
            }

        }

        function mapValue(value, inmin, inmax, outmin, outmax) {

            if (Math.abs(inmin - inmax) < Number.EPSILON) return outmin;

            value = ((value - inmin) / (inmax - inmin) * (outmax - outmin) + outmin);
            if (outmax < outmin) {
                if (value < outmax) value = outmax;
                else if (value > outmin) value = outmin;
            }
            else {
                if (value > outmax) value = outmax;
                else if (value < outmin) value = outmin;
            }

            return value;
        }
    }

    /**
     * Create a new follow path animation for the specify feature ID.
     *
     * @param {int} id - The feature identifier.
     * @param {Object} options - The animation options.
     */
    startFollowPathAnimation(id, options) {

        // retrieve feature
        const feature = this.getFeature(id);
        if (!feature) {
            console.warn("Invalid feature.\nAbording")
            return;
        }

        const me    = this;
        const time  = new Date().getTime();
        let index   = options.offset;
        let target  = -1;
        

        // @todo: to be set in verbose mode [ticket NS-113]
        // console.log("FollowPath", (feature.get('symbol') || "linepath"), `[${id}]`,
        //     `\n\tpoints: ${options.points.length}`,
        //     `\n\toffset: ${options.offset}`,
        //     `\n\tloop:   ${options.speed}`,
        //     `\n\tspeed:  ${options.speed}s`,
        // )

        // based on Marker Animation
        // https://openlayers.org/en/latest/examples/feature-move-animation.html
        let animate = event => {

            const elapsed = event.frameState.time - time;
            const current = Math.round(options.speed * elapsed / 1000); // current target index

            // on change
            if (target !== current) {
                target = current;

                // stop animation if no longer points
                if (!options.points.length) {
                    me.stopFollowPathAnimation(id);
                    return;
                }

                // convert and move to the new coordinate (if needed)
                // and call the progression callback function
                if (options.status === 'playing') {

                    let point = me.geoToMapCoordinate(options.points[index]);
                    feature.setGeometry(new ol.geom.Point(point));
                    this.props.onFollowPathProgression(id, index);

                    index++;
                    if (index >= options.points.length) {
                        this.props.onFollowPathCycleEnded(id);
                        if (options.loop) index = 0;
                        else {
                            me.stopFollowPathAnimation(id);
                            return;
                        }
                    }

                }

            }

            // tell OpenLayers to continue the postrender animation
            me.context.map.render();
        }

        // add animation to OpenLayer and force postcompose
        //options.listener = this.layer.on('postrender', animate);
        options.listener = this.context.map.on('postcompose', animate);
        this.featureAnims.followpath.set(id, options);
        this.context.map.render();
    }

    /**
     * Update the follow path animation for the specify feature ID.
     *
     * @param {int} id - The feature identifier.
     * @param {Object} options - The animation options.
     */
    updateFollowPathAnimation(id, options) {
        if (!options.status) return;

        // start animation if not exits
        if (!this.featureAnims.followpath.has(id)) {
            if (options.status === 'playing') {
                this.startFollowPathAnimation(id, options);
            }
            return;
        }

        // restart on change
        if (options.restart) {
            this.stopFollowPathAnimation(id,true);
            this.startFollowPathAnimation(id, options);
            delete options.restart;
        }

        // or stop
        if (options.status === 'stopped' || !options.points.length) {
            this.stopFollowPathAnimation(id);
        }
    }
    
    /**
     * Stop the follow path animation for the given feature ID.
     * 
     * @param {int} id - The feature identifier.
     * @param {boolean} quiet - Whether this action must sent to the user or not (true by default)
     */
    stopFollowPathAnimation(id, quiet = false) {
        if (this.featureAnims.followpath.has(id)) {
            ol.Observable.unByKey(this.featureAnims.followpath.get(id).listener);
            this.featureAnims.followpath.delete(id);
            if (!quiet) this.props.onFollowPathEnded(id);
        }
    }

    /**
     * Converts a geographic coordinate ([longitude,latitude])to a map point coordinate.
     *
     * @param {Array} lonlat - Coordinate as longitude and latitude in array
     * @returns {Array} The map point coordinate
     */
    geoToMapCoordinate(lonlat) {
        return ol.proj.transform(lonlat, 'EPSG:4326', 'EPSG:3857');
    }

    /**
     * Converts a map point coordinate to a geographic coordinate (i.e. [longitude,latitude]).
     *
     * @param {ol/coordinate} coords - the coordinate of the point
     * @returns {Array} The longitude and latitude in array
     */
    mapToGeoCoordinate(point) {
        return ol.proj.transform(point, 'EPSG:3857', 'EPSG:4326');
    }
}

MapOLLayer['contextTypes'] = {
    mapComp: PropTypes.instanceOf(MapOL),
    map: PropTypes.instanceOf(ol.Map)
};
