<!-- JSON Editor vue -->
<template lang="pug">
	div.json-panel
		div.criteria-bar
			div.containers.compact(@click.stop="format()")
				icon.icons(name="jsonFormat")
			div.containers.format(@click.stop="compact()")
				icon.icons(name="jsonCompact")
			div.containers.download(@click.stop="download()") Download
				div.circle
					icon.icons(name="download")
		div.json-editor-container(@focusout="focusOut()")
			json-editor-vue.editor-body(v-model="value" mode="text" indentation="\t" tabSize=4 :mainMenuBar="false" :statusBar="false")
		div.footer(v-if="state.from === 'LIBRARY'")
			div.button.enabled(@click.stop="save()") Save Changes
</template>

<script>
	import actionManager from '../../actions';
	import JsonEditorVue from 'json-editor-vue';
	import Icon from '../common/Icon.vue';

	export default {
		props: ['state'],
		components: { JsonEditorVue, Icon },
		data() {
			return {
				value: '{}',
			}
		},
		created: function() {
			if (this.state && this.state.content) {
				this.value = this.state.content;
			}
		},
		methods: {
			save: function() {
				if (this.state && this.state.callback) {
					this.state.callback(this.value);

					if (this.state.from !== 'FIELD') {
						actionManager.trigger('main:toggleJson', { open: false });
					}
				}
			},
			download: function() {
				const a = document.createElement('a');

				a.href = URL.createObjectURL(new Blob([this.value], { type: 'application/json' }));
				a.download = `export-${Date.now()}.json`;
				a.click();
			},
			format: function() {
				try {
					this.value = JSON.stringify(JSON.parse(this.value), null, 4);
				} catch(err) {
					console.warn(err);
				}
			},
			compact: function() {
				try {
					this.value = JSON.stringify(JSON.parse(this.value));
				} catch(err) {
					console.warn(err);
				}
			},
			focusOut: function() {
				if (this.state && this.state.from === 'FIELD') {
					this.save();
				}
			},
		},
	}
</script>

<style lang="stylus" scoped>
	.json-panel
		overflow: hidden
		flex: auto
		.criteria-bar
			gap: 6px
			.containers
				height: 24px
				background-color: white
				border-radius: 4px
				transition: linear all .1s
				&:hover
					cursor: pointer
			.icons
				display: block
				background-color: var(--textColor)
				width: 12px
				height: 12px
				margin-top: 12px
				margin-left: 6px
			.format, .compact
				width: 24px
				&:hover
					background-color: var(--textColor)
					.icons
						background-color: white
			.download
				margin-left: auto
				display: flex
				gap: 6px
				flex-direction: row-reverse
				align-items: center
				padding: 0px 8px 0px 6px
				font-family: 'DinBold'
				.circle
					display: flex
					background-color: var(--textColor)
					width: 15px
					height: 15px
					border-radius: 15px
					.icons
						margin: auto
						transform: translateY(0)
						background-color: white
						height: 8px
						mask-size: contain
						-webkit-mask-size: contain
				&:hover
					background-color: var(--textColor)
					color: white
					.circle
						background-color: white
					.icons
						background-color: var(--textColor)
		.json-editor-container
			display: flex
			flex-direction: column
			height: 100%
			overflow: auto
			margin-right: -20px
			--jse-theme-color: var(--inspectorTargetOverColor)
			--jse-text-color: var(--textColor)
			--jse-main-border: 0
			--jse-panel-background: var(--inspectorTargetOverColor)
			--jse-panel-border: 0
			--jse-panel-color-readonly: var(--textColor)
			--jse-hover-background-color: rgba(247, 185, 149, 0.2)
			--jse-selection-background-color: rgba(247, 185, 149, 0.1)
			--jse-message-error-background: #f77963
			--jse-key-color: #767676
			--jse-value-color: #f7b995
			--jse-value-color-number: #f7b995
			--jse-value-color-boolean: #c27d5d
			--jse-value-color-null: #cccccc
			--jse-value-color-string: #f49f77
			--jse-value-color-url: #f49f77
			--jse-delimiter-color: #a0a0a0
			--jse-font-size: 18px
			--jse-font-size-mono: 18px
			--jse-font-family: 'DinRegular'
			--jse-font-family-mono: Monospace
			--jse-search-match-color: #f8c5a6
			--jse-search-match-outline: 1px solid #f39a74
			--jse-search-match-active-color: #f39a74
			--jse-search-match-active-outline: 1px solid #f39a74
			::v-deep .jse-main
				width: calc(100% - 5px)
			::v-deep .jse-message
				position: sticky
				bottom: 0%
				width: calc(100% - 35px)
			::v-deep .cm-gutters
				font-size: small
				line-height: 2em
				font-family: 'DinRegular'
			::v-deep .cm-lineWrapping
				font-weight: normal
			::v-deep .cm-matchingBracket, ::v-deep .cm-selectionMatch
				background-color: #cccccc
				color: var(--addElementColor)
			::v-deep .cm-gutters
				font-size: small
				line-height: 2em
				font-family: 'DinRegular'
			.editor-body
				flex: auto
</style>