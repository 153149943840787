var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plug-bt",
      class: [
        _vm.connexion.type,
        _vm.side,
        _vm.fieldname,
        { selected: _vm.selected == _vm.fieldname + "_" + _vm.side },
        { deprecatedField: _vm.isDeprecated }
      ],
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.linkPlug(_vm.connexion.type)
        }
      }
    },
    [
      _vm.side == "input" &&
      _vm.connexion.type != "state" &&
      _vm.formatedVal !== null
        ? _c("span", { staticClass: "value" }, [
            _c("span", [_vm._v(_vm._s(_vm.formatedVal))])
          ])
        : _vm._e(),
      _c("span", { staticClass: "name" }, [
        _vm._v(_vm._s(_vm._f("truncate")(_vm.label, 10)))
      ]),
      _vm.side == "output" &&
      _vm.connexion.type != "state" &&
      _vm.formatedVal !== null
        ? _c("span", { staticClass: "value" }, [
            _c("span", [_vm._v(_vm._s(_vm.formatedVal))])
          ])
        : _vm._e(),
      _c("div", { staticClass: "anchor" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }