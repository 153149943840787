var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "doc" } },
    [
      _c("div", { attrs: { id: "header" } }, [
        _c("div", { attrs: { id: "title" } }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.displayTab("home")
                }
              }
            },
            [_vm._v("Documentation")]
          )
        ]),
        _c("div", { attrs: { id: "backAuth" } }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.backToStudio()
                }
              }
            },
            [_vm._v("Back To Studio")]
          )
        ])
      ]),
      _c("nav", { attrs: { id: "side-menu" } }, [
        _c(
          "ul",
          { staticClass: "nodes" },
          [
            _c("li", { class: { selected: _vm.currentTab == "env" } }, [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.displayTab("env")
                    }
                  }
                },
                [_vm._v("Environment")]
              )
            ]),
            _c("li", { class: { selected: _vm.currentTab == "nodes" } }, [
              _c(
                "a",
                {
                  class: { selected: _vm.currentTab == "nodes" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.displayTab("nodes")
                    }
                  }
                },
                [_vm._v("Nodes")]
              )
            ]),
            _c("li", { class: { selected: _vm.currentTab == "mem" } }, [
              _c(
                "a",
                {
                  class: { selected: _vm.currentTab == "mem" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.displayTab("mem")
                    }
                  }
                },
                [_vm._v("Memories")]
              )
            ]),
            _c(
              "li",
              {
                class: {
                  selected:
                    _vm.currentTab == "ref" || _vm.currentTab == "module"
                }
              },
              [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.displayTab("ref")
                      }
                    }
                  },
                  [_vm._v("Modules")]
                )
              ]
            ),
            _vm._l(_vm.modules, function(li) {
              return _c(
                "li",
                { staticClass: "sublist" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.displayTab("module", li.name)
                        }
                      }
                    },
                    [_vm._v(_vm._s(li.title))]
                  ),
                  _vm._l(li.components, function(cp) {
                    return _c(
                      "a",
                      {
                        class: { selected: _vm.subType == cp.value.type },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.displayTab("module", cp.value.type)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(cp.value.name))]
                    )
                  })
                ],
                2
              )
            }),
            _c(
              "li",
              {
                class: {
                  selected:
                    _vm.currentTab == "obj" || _vm.currentTab == "object"
                }
              },
              [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.displayTab("obj")
                      }
                    }
                  },
                  [_vm._v("Objects")]
                )
              ]
            ),
            _vm._l(_vm.objects, function(li) {
              return _c(
                "li",
                { staticClass: "sublist" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "title",
                      class: { selected: _vm.subType == li.name },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.displayTab("object", li.name)
                        }
                      }
                    },
                    [_vm._v(_vm._s(li.title))]
                  ),
                  _vm._l(li.components, function(cp) {
                    return _c(
                      "a",
                      {
                        class: { selected: _vm.subType == cp.value.format },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.displayTab("object", cp.value.format)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(cp.value.name))]
                    )
                  })
                ],
                2
              )
            }),
            _c("li", { class: { selected: _vm.currentTab == "examples" } }, [
              _c(
                "a",
                {
                  class: { selected: _vm.currentTab == "examples" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.displayTab("examples")
                    }
                  }
                },
                [_vm._v("Examples")]
              )
            ])
          ],
          2
        )
      ]),
      _vm.currentTab === "home"
        ? _c("div", { attrs: { id: "content" } }, [
            _c(
              "section",
              {
                staticClass: "doc-theme env",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.displayTab("env")
                  }
                }
              },
              [_c("span", [_vm._v("Environment")])]
            ),
            _c(
              "section",
              {
                staticClass: "doc-theme nodes",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.displayTab("nodes")
                  }
                }
              },
              [_c("span", [_vm._v("Nodes")])]
            ),
            _c(
              "section",
              {
                staticClass: "doc-theme mem",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.displayTab("mem")
                  }
                }
              },
              [_c("span", [_vm._v("Memories")])]
            ),
            _c(
              "section",
              {
                staticClass: "doc-theme ref",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.displayTab("ref")
                  }
                }
              },
              [_c("span", [_vm._v("Reference")])]
            ),
            _c(
              "section",
              {
                staticClass: "doc-theme ref",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.displayTab("obj")
                  }
                }
              },
              [_c("span", [_vm._v("Objects")])]
            ),
            _c(
              "section",
              {
                staticClass: "doc-theme ref",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.displayTab("examples")
                  }
                }
              },
              [_c("span", [_vm._v("Examples")])]
            )
          ])
        : _vm._e(),
      _vm.currentTab === "env" ? _c("environment") : _vm._e(),
      _vm.currentTab === "nodes"
        ? _c("nodes", { attrs: { "display-tab": _vm.displayTabCallback } })
        : _vm._e(),
      _vm.currentTab === "node-project"
        ? _c("node-project", {
            attrs: { "display-tab": _vm.displayTabCallback }
          })
        : _vm._e(),
      _vm.currentTab === "node-experience" ? _c("node-experience") : _vm._e(),
      _vm.currentTab === "node-screen" ? _c("node-screen") : _vm._e(),
      _vm.currentTab === "mem" ? _c("memories") : _vm._e(),
      _vm.currentTab === "ref"
        ? _c("references", {
            attrs: {
              "display-tab": _vm.displayTabCallback,
              "app-state": _vm.appState
            }
          })
        : _vm._e(),
      (_vm.currentTab == "module" || _vm.currentTab === "object") &&
      _vm.subType != null
        ? _c("reference", {
            attrs: {
              "display-tab": _vm.displayTabCallback,
              "app-state": _vm.appState,
              type: _vm.currentTab,
              "sub-type": _vm.subType
            }
          })
        : _vm._e(),
      _vm.currentTab === "obj"
        ? _c("objects", {
            attrs: {
              "display-tab": _vm.displayTabCallback,
              "app-state": _vm.appState
            }
          })
        : _vm._e(),
      _vm.currentTab === "examples"
        ? _c("examples", { attrs: { "display-tab": _vm.displayTabCallback } })
        : _vm._e(),
      _c("div", { attrs: { id: "bottom" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }