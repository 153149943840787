import ActionAbstract from '../ActionAbstract';

class ToggleTagModal extends ActionAbstract {

    static get ID() {
        return 'actions:main:ToggleTagModal';
    }

    run(state) {
        return this.composeState(state, {
            library: {
                tagModalOpen: this.getParam('open'),
                mediasUploaded: this.getParam('mediasUploaded') ? this.getParam('mediasUploaded') : null
            }
        });
    }

    toggle(state) {
        let forcedState = this.getParam('open');
        let newState = forcedState !== undefined ? forcedState : !!!state;
        return newState;
    }

}

export default ToggleTagModal;