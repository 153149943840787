var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "postit",
      class: { open: this.isOpen, close: !this.isOpen }
    },
    [
      _c(
        "div",
        { staticClass: "clip", on: { mouseup: _vm.togglePostIt } },
        [_c("icon", { attrs: { name: "note" } })],
        1
      ),
      _c("div", { staticClass: "postit-value" }, [
        _c("div", { staticClass: "ghost-txt" }, [_vm._v(_vm._s(_vm.value))]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value"
            }
          ],
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.value = $event.target.value
            }
          }
        })
      ]),
      _c("div", { staticClass: "bottom-menu-postit" }, [
        _c("div", { staticClass: "action-bts-postit" }, [
          _c("div", {
            staticClass: "action-bt-postit bt-delete-postit",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.deleteBloc($event)
              }
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }