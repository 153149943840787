import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class CreateProject extends ActionAbstract {
    static get ID() {
        return 'actions:project:createProject';
    }

    run(state) {
        
        switch(this.getParam('subaction')) {
            case "success":

                let newProjectId = this.getParam('project').value._id;

                this.trigger('project:openProject', {projectId : newProjectId });

                return this.composeState(state, {
                    isLoading: false,
                    projects: {
                        list: this.arrayPush(this.getParam('ref_project'))
                    }
                });

            break;

            case "saveProject" :  
                let projectData = this.getParam('project');

                this.deps.mainStore.block.saveDocument( projectData , this.deps.mainStore.config.bdd+'nodal-project-'+projectData.value._id+'/' ).then( ( response ) => {
                    this.trigger('project:createProject', { subaction:'success', project: projectData, ref_project: this.getParam('ref_project')  });
                });

                return false;

            break;

            case "dbcreated" :  
                let data = this.getParam('project');
                let ref_project = { value : this.deps.mainStore.projects.generateNewProjectRef( data.value._id, state.user  ) };
                this.deps.mainStore.block.saveDocument( ref_project , this.deps.mainStore.config.baseURLAuth ).then( ( response ) => {
                    this.trigger('project:createProject', { subaction:'saveProject', project: data, ref_project: ref_project });
                });

                return false;

            break;

            case "createdb" :  

                let projectId = this.getParam('project').value._id ;
                this.deps.mainStore.project.createDBProject( projectId ).then( ( response ) => {
                    this.trigger('project:createProject', { subaction:'dbcreated', project: this.getParam('project') });
                });

                return false;

            break;

            default: 

				let project = this.deps.mainStore.projects.generateNewProjectDoc( );

                this.deps.mainStore.block.createNewDoc( project ).then( ( newProject ) => {
                    this.trigger('project:createProject', { subaction:'createdb', project: newProject });
                });

                return this.composeState(state, {
                    isLoading: true
                });
                
            break;
        }

    }
}

export default CreateProject;