<!-- Field vue -->

<template lang="pug">
div.wysiwyg-inspector
	div.wysiwyg-inspector-cover(v-on:click.stop="close()")
	div.wysiwyg-window
		div.wysiwyg-centerer
			div.wysiwyg-ctn
				div#tinymce

</template>

<script>

import { stores } from '../../stores/MainStore.js';

import Vue from 'vue'
// import Editor from 'tinymce'; // not used

export default {

	props:[],

	components: {},

	data() {
		return {
			text: "Hello world :) "
		}
	},

	created: function(){
		Editor.init({
			selector: '#tinymce'
		});
	},

	watch:{
	
	},

	methods:{


		close: function(){
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			this.$root.$emit('close-array-panel', {selected: id }) // <---- ORIGINAL closeArrayPanel LISTENER NOT FOUND
		},
	}

}

</script>

<style lang="stylus">

.wysiwyg-inspector, .wysiwyg-inspector-cover
	display: block
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: 90
	
.wysiwyg-window
	display: block
	width: 70%
	height: 70%
	position: fixed
	top: 35px
	left: 50%
	transform: translateX(-50%)
	background-color: #feddb8
	z-index: 91
	box-shadow 1px 1px 3px rgba(0,0,0,0.2)
	overflow: auto

.wysiwyg-centerer
	display: table
	vertical-align: middle
	text-align: center
	width: 100%
	height: 100%
	position relative



</style>
