var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "template-create" } }, [
    _c("div", { staticClass: "section" }, [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "icon" },
          [_c("icon", { attrs: { name: "template-popup-" + _vm.type } })],
          1
        ),
        _c("h3", { staticClass: "title" }, [
          _c("span", { staticClass: "before-type" }, [_vm._v("Duplicate a ")]),
          _c("span", { staticClass: "type" }, [_vm._v(_vm._s(_vm.type) + " ")]),
          _c("span", { staticClass: "after-type" }, [_vm._v("Template")])
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          staticClass: "name",
          attrs: { placeholder: "template name" },
          domProps: { value: _vm.name },
          on: {
            keyup: _vm.keyup,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            }
          }
        }),
        _c("p", { staticClass: "message" }, [
          _c("span", { staticClass: "before-name" }, [
            _vm._v("You are about to duplicate the ")
          ]),
          _c("span", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.original) + " ")
          ]),
          _c("span", { staticClass: "after-name" }, [_vm._v("template")])
        ]),
        _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      ])
    ]),
    _c("div", { staticClass: "section" }, [
      _c("div", { staticClass: "action" }, [
        _c("input", {
          attrs: {
            disabled: !(_vm.name && _vm.name.trim()),
            value: "Continue",
            type: "button"
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.submit($event)
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }