import ActionAbstract from '../ActionAbstract';

class OpenSessionInspector extends ActionAbstract {
    /**
     * @param id - the project id
     * @param slug - the project slug
     * 
    **/
    static get ID() {
        return 'actions:project:openSessionInspector';
    }

    run(state) {
        const pid = this.getParam('id');
        const slug = this.getParam('slug');
        const url = `sessions-inspector/${slug}/${pid}`;
        const windox = window.open();
        
        windox.opener = null;
        windox.document.location = this.deps.mainStore.config.serverURL + url;

        return false;
    }
}

export default OpenSessionInspector;