<!-- Asset vue -->

<template lang="pug">

span.textinput-ctn(v-bind:class="{'setted':value, 'disabled':field.disabled }")
	div.edit-bt(v-on:click.stop="openLibrary(field)", v-if="!value", :class="{'disabled':isDisabled()}")
		span.label Choose
	input.value.fileinfo(type="text", v-bind:value="currentFileInfo.filename", v-on:click.stop="openLibrary(field)", v-if="value && currentFileInfo", align="right")
	input.value.hide(type="text", v-model="value")
	div.file-action-bts(v-if="value && currentFileInfo")
		div.action-bt.bt-view(v-on:click.stop="viewMedia()")
		div.action-bt.bt-delete(v-on:click.stop="resetFile()")
</template>

<script>

import actionManager from '../../../actions';

export default {

	components: { },

	props:[ 'field' , 'fieldvalue', 'bloc', 'placeholder', 'parentField', 'library'],

	data() {
		return {
			actionOnChange: false,
			loadedMedia : null,
			mediaDeleted : false,
			count : 0
		}
	},

	created: function() {
		//this.loadMediaDoc();
		if (this.field.action !== undefined) {
			this.field.action.forEach((action) => {
				if (action.on === 'change') this.actionOnChange = action;
				if (action.on === 'load') this.$parent.dynAction(action, this.getMedia());
			});
		}
	},
	watch: {
		value: function(newval, oldval) {
			if (this.actionOnChange) this.$parent.dynAction(this.actionOnChange, this.getMedia());
		},
		selectedAsset: function(newval, oldval) {
			//this.getValue()//
			this.count ++
		}
	},

	computed: {

		selectedAsset: function(){
			if(this.library){
				return this.library.assetSelected
			}	
		},
		currentFileInfo: function () {
			let res = null;

			if( this.value ){
				// TODO: prefers pass app.state to childrens, $root or $children references could change
				// (migration from v1: this.$root)
				this.$root.$children[0].state.library.mediaList.forEach( (media) => {
					if(this.value){
						if( ((media._id === this.value.value || media._id == this.value || media.url === this.value) && !media._deleted)){
							res = media;
						}	
					}
				})
			}

			if( res === null )
				this.triggerMediaDeleted();

			return res;

			/*this.loadMediaDoc();

			if (this.value && this.bloc.custom.media){

				if( this.bloc.custom.media[this.value] === null ){
					this.triggerMediaDeleted();
					return null;
				}

				this.mediaDeleted = false;

				return this.bloc.custom.media[this.value];
			}*/

		},
		value: function(){
			const count = this.count;
			const value = this.getValue(count);
			return value
		},
	},


	methods: {

		isDisabled: function() {
			return !this.bloc || !this.bloc.value;
		},

		getValue : function(){
			let res = null;

			if( this.fieldvalue && !this.fieldvalue.value && this.placeholder ){
				res = this.placeholder;
			} else {
				res = this.fieldvalue ? this.fieldvalue.value : null;
			}

			if( this.mediaDeleted !== false && this.mediaDeleted === res )
				res = null;
				
			return res;
		},

		/**
		 * Returns the associated asset.
		 * @returns {Object}
		 */
		getMedia: function() {
			let media = null;

			const value = this.getValue();
			if (value) {
				// TODO: prefers pass app.state to childrens, $root or $children references could change
				// (migration from v1: this.$root)
				const medias = this.$root.$children[0].state.library.mediaList;
				for( let i=0; i<medias.length; i++) {
					if( ((medias[i]._id === value || medias[i]._id == value.value || medias[i].url === value) && !medias[i]._deleted)){
						media = medias[i];
						break;
					}
				}
			}

			return media;
		},

		/**
		 * Returns the linked asset identifier.
		 * @returns {string}
		 */
		getMediaId: function() {
			if (typeof this.value === 'string') return this.value;
			return this.value ? this.value.value : '';
		},

		loadMediaDoc: function () {

			this.loading = true;
			let subMediaId = null;

			subMediaId = this.field.name;

			if( this.loadedMedia == this.value && this.bloc.custom.media && this.bloc.custom.media[this.value] !== undefined ){
				// media already loaded
				return;
			}

			this.loadedMedia = this.value;

			/*if( this.value )
				actionManager.trigger('library:assetFieldLoad', { mediaId: this.value, bloc: this.bloc , subMediaId: this.value });
				*/
		},

		updateValue : function( ){
			this.value = this.fieldvalue ? this.fieldvalue.value : null;
		},

		openLibrary: function( field ){
			if (!this.isDisabled()) actionManager.trigger('library:open', { field: this.field, bloc: this.bloc, mediaId: this.getMediaId()});
			else console.warn(`Invalid bloc type for asset field '${this.field.label}'`)
		},

		viewMedia: function( media ){
			if( this.currentFileInfo )
				actionManager.trigger('library:mediaPreview', { media: this.currentFileInfo });
		},

		resetFile: function(){
			this.loadedMedia = null;
			this.$parent.valueUpdated( this.field, null);
			if(this.field.name.includes('array') && this.bloc.value.type !== 'memory' && this.parentField){
				this.parentField["valueIsArray"] = true;
				this.parentField["fieldToChange"] = this.field.name;
				actionManager.trigger('library:selectAssetForField', { mediaId: null, target: { bloc : this.bloc, field: this.parentField } });
			} else {
				actionManager.trigger('library:selectAssetForField', { mediaId: null, target: { bloc : this.bloc, field: this.field } });
			}
		},

		triggerMediaDeleted : function(){
			this.mediaDeleted = this.value;

			let label = this.field.label ? this.field.label : this.field.name;
			actionManager.trigger('main:modal:openModal', {title:'Media not found', text:'Media selected for '+label+' input does not exists. Continue to replace it.', continueButton: 'Replace', nextAction:'library:open', nextActionParam: { field: this.field, bloc: this.bloc }, icon: 'notfound' })

			this.resetFile();
		}

	}

}

</script>

<style lang="stylus">
</style>
