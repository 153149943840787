import UiObject from './UiObject';

/**
 * @@deprecated since version 3.2.0, use the InputText or the DropDownList instead.
 */
export const DEPRECATED = true;

class UiInputFields extends UiObject {

    constructor(id, props = [], type = 'InputField') {
        super(id, props, type);
        
    }


    initParameters(){
        super.initParameters();

        let globalSettings = this.createInspectorNode('group', 'input-global', 'Input settings' );
        globalSettings.children.push( this.createInspectorNode('group-field', 'input-global-fields') );

        let inputStyle = this.createInspectorNode('group', 'input-style', 'Input Style' );
        inputStyle.children.push( this.createInspectorNode('group-field', 'input-style-fields') );

        let inputTopic = this.createInspectorNode( 'topic', 'input-topic', 'Input Field' );
        inputTopic.children = [ globalSettings, inputStyle ];

        this.inspector.unshift( inputTopic );


        let inputParameters = {

            disabled : {
                type: 'Boolean', 
                default: false, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Disabled",
                    container: "input-global-fields",
                    description: "Specifies whether text can be inserted in the field or not"
                }
            },
            floatingLabelText : {
                type: 'String', 
                default: 'Input label', 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Label",
                    container: "input-global-fields",
                    description: "the label to display in the input field (as soon as the input field gets the focus the label will move above the field)"
                }
            },
            hintText : {
                type: 'String', 
                default: '', 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Placeholder",
                    container: "input-global-fields",
                    description: "a placeholder suggestion for the input field's value"
                }
            },
            errorText : {
                type: 'String', 
                default: '', 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Error message",
                    container: "input-global-fields",
                    description: "the error message to display under the input field when needed"
                }
            },


            // STYLE
            valueColor : {
                type: 'Color', 
                default: { hex : "#000000" }, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Value color",
                    container: "input-style-fields",
                    description: "color of the text entered by the user"
                }
            },
            labelColor : {
                type: 'Color', 
                default: { hex : "#b7b7b7" }, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Label color",
                    container: "input-style-fields",
                    description: "color of the label text"
                }
            },
            focusLabelColor : {
                type: 'Color', 
                default: { hex : "#00bcd4 " }, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Focus label color",
                    container: "input-style-fields",
                    description: "color of the label text when the input field gets the focus"
                }
            },

            floatingLabelFixed : {
                type: 'Boolean', 
                default: false, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Fixed label",
                    container: "input-style-fields",
                    description: "specifies whether the label should be always displayed above the input field or not"
                }
            },

            hintColor : {
                type: 'Color', 
                default: { hex : "#b7b7b7" }, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Placeholder color",
                    container: "input-style-fields",
                    description: "color of the placeholder text"
                }
            },

            errorColor : {
                type: 'Color', 
                default: { hex : "#f44336" }, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Error color",
                    container: "input-style-fields",
                    description: "color of the error text"
                }
            },
            
            underlineShow : {
                type: 'Boolean', 
                default: true, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Underlined",
                    container: "input-style-fields",
                    description: "specified whether a line should be displayed under the input zone or not"
                }
            },
            underlineColor : {
                type: 'Color', 
                default: { hex : "#b7b7b7" }, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Underline color",
                    container: "input-style-fields",
                    description: "color of the underline"
                }
            },
            underlineFocusColor : {
                type: 'Color', 
                default: { hex : "#00bcd4" }, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Underline Focus color",
                    container: "input-style-fields",
                    description: "color of the underline when the input field gets the focus"
                }
            },
                    
        };

        this.addToParameters( inputParameters );
        
    }

}

export default UiInputFields;