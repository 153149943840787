import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import uuid from 'uuid/v4';

class DuplicateProject extends ActionAbstract {
    static get ID() {
        return 'actions:project:DuplicateProject';
    }

    run(state) {

        let self = this
        let currentProject = this.getParam('project')
        let neoProjectName = this.getParam('neoProjectName')

        let duplicationInfo = {
            oldDBinfo: {
                uuid: currentProject.project,
                name: currentProject.name,
                slug: currentProject.slug
            }, 
            newDBInfo: {
                uuid: uuid(),
                name: neoProjectName,
                slug: neoProjectName,
                owner: state.user
            }
        }

        this.composeState(state, {isLoading: true});

        // request duplication of the project to nodal-app backend
        this.deps.mainStore.project.duplicateProject(duplicationInfo).then( (res) => {
            this.deps.mainStore.state.modal.duplicateError = "ok"
        })
        .catch( error => {
            this.deps.mainStore.state.modal.duplicateError = error
        })
        .finally( ()=>{
            this.trigger('projects:listAll', { forceRefresh : true });
            this.composeState(state, {isLoading: false});
        })
    }
}

export default DuplicateProject;
