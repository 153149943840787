var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "content" } }, [
    _c("h1", [_vm._v("Node Screen")]),
    _c("h2", [_vm._v("Presentation")]),
    _c("p", [
      _vm._v(
        "A Screen is one step of the experience. It contains an inspector with various parameters."
      )
    ]),
    _vm._m(0),
    _vm._m(1),
    _c("h2", [_vm._v("Parameters")]),
    _vm._m(2),
    _c("h2", [_vm._v("Actions")]),
    _vm._m(3),
    _c("h2", [_vm._v("Settings")]),
    _c("h3", [_vm._v("Process triggering")]),
    _c("p", [
      _vm._v(
        "A process can be triggered automatically when its parent screen is played, or with a specific trigger added in the authoring"
      )
    ]),
    _c("h3", [_vm._v("Memory")]),
    _vm._m(4),
    _c("p", [
      _vm._v("A persistent memory is kept from one session to another.")
    ]),
    _c("div", { attrs: { id: "back" } }, [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.displayTab("nodes")
            }
          }
        },
        [_vm._v("← Back")]
      )
    ]),
    _vm._m(5)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Each screen contains two layers :"),
      _c("ul", { staticClass: "listing" }, [
        _c("li", [
          _c("strong", [_vm._v("A HTML View")]),
          _vm._v(
            " which opens the screen editor to design the graphics of the screen."
          )
        ]),
        _c("li", [
          _c("strong", [_vm._v("A Logic Layer")]),
          _vm._v(
            " that contains Processes and Triggers to design the interactivity and behavior."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "The user can build the experience by associating and setting different screens."
      ),
      _c("br"),
      _vm._v(
        " Different types of screens and modules are available at the screen level :"
      ),
      _c("ul", { staticClass: "listing" }, [
        _c("li", [_vm._v("Screen : Regular screen that can be designed.")]),
        _c("li", [
          _vm._v(
            "Widget : Graphic elements that can be reused in different screens."
          )
        ]),
        _c("li", [
          _vm._v(
            "Process : Logical modules that create a common behaviors to several screens."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { attrs: { id: "params" } }, [
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Name")])]),
        _c("td", { attrs: { id: "col2" } }, [_vm._v("String")])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Owner")])]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v("User who create the project")
        ])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [
          _c("strong", [_vm._v("User(s)")])
        ]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v(
            "List of users who can access the project with specific rights (view only, edit, admin)"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { attrs: { id: "params" } }, [
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Delete")])]),
        _c("td", { attrs: { id: "col2" } }, [_vm._v("Delete the process")])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [
          _c("strong", [_vm._v("Duplicate")])
        ]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v("Create a copy of the process")
        ])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Share")])]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v(
            "Share the process with specific rights (view only, edit, admin)"
          )
        ])
      ]),
      _c("tr", [
        _c("td", { attrs: { id: "col1" } }, [_c("strong", [_vm._v("Export")])]),
        _c("td", { attrs: { id: "col2" } }, [
          _vm._v("Export the process in JSON")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("A shared memory is accessible to all the users of the project."),
      _c("ul", { staticClass: "listing" }, [
        _c("li", [_vm._v("Angle")]),
        _c("li", [
          _vm._v("Array (A specific interface allows to parameter the array")
        ]),
        _c("li", [_vm._v("Asset (It can be chosen via the Library)")]),
        _c("li", [_vm._v("Boolean")]),
        _c("li", [_vm._v("Color")]),
        _c("li", [_vm._v("Float")]),
        _c("li", [_vm._v("Integer")]),
        _c("li", [_vm._v("Percent")]),
        _c("li", [_vm._v("String")]),
        _c("li", [_vm._v("Vector")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("br"), _c("br"), _c("br")])
  }
]
render._withStripped = true

export { render, staticRenderFns }