
<template lang="pug">
div#content.refs-ctn
	h1 Objects
	div.columns-ctn
		div.column(v-for="ul of list")
			div.module-theme(v-for="li of ul")
				h1
					span.icon(v-bind:class="li.name")
					span {{li.title}}
				ul.nodes
					li(v-for="cp of li.components", v-on:click.stop="displayTab('object', cp.value.format)") 
						span {{cp.value.name}}
</template>

<script>

	import actionManager from '../../actions';

	export default {


		props:['appState', 'displayTab'],

		data() {
			return {

			};
		},
		computed:{

			'list': function(){
				if( this.appState.editor && this.appState.editor.menu && this.appState.editor.menu.list && this.appState.editor.menu.list.length > 0 ){
					let list = this.appState.editor.menu.list[0].children.filter( (c) => { if( c.components && c.components.length > 0 ) return c; return null; }) ;
					let listSplited = this.chunkArray( list , 2 );
					return listSplited;
				}
				return [];
			},

		},

		created: function(){
			actionManager.trigger('doc:openDoc', {type: 'object'});
		},

		methods:{

			chunkArray: function(arr,n){
				var chunkLength = Math.max(arr.length/n ,1);
				var chunks = [];
				for (var i = 0; i < n; i++) {
					if(chunkLength*(i+1)<=arr.length)chunks.push(arr.slice(chunkLength*i, chunkLength*(i+1)));
				}
				return chunks; 
			}
		}

	}

</script>

<style>

</style>