import ActionManager from '../ActionManager'

import Create from './Create'
ActionManager.register(Create, ['stores:MainStore'])

import Duplicate from './Duplicate'
ActionManager.register(Duplicate, ['stores:MainStore'])

import Open from './Open'
ActionManager.register(Open, ['stores:MainStore'])

import Save from './Save'
ActionManager.register(Save, ['stores:MainStore'])

import Link from './Link'
ActionManager.register(Link, ['stores:MainStore'])

import Unlink from './Unlink'
ActionManager.register(Unlink, ['stores:MainStore'])

import Delete from './Delete'
ActionManager.register(Delete, ['stores:MainStore'])

import ListAll from './ListAll'
ActionManager.register(ListAll, ['stores:MainStore'])

import FetchAll from './FetchAll'
ActionManager.register(FetchAll, ['stores:MainStore'])

import UpdateFields from './UpdateFields'
ActionManager.register(UpdateFields, ['stores:MainStore'])

import Media from './Media'
ActionManager.register(Media, ['stores:MainStore'])
