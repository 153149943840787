<!-- GEO MODAL vue -->

<template lang="pug">
	div.modal-map-container
		div.map(v-bind:id="mapId")
		div.clear
		div.clear
</template>


<script>
import { stores } from '../../stores/MainStore.js';
import * as helper from '../../helper';
import Spinner from  '../common/Spinner.vue';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import actionManager from '../../actions';



export default {

		name: 'geomodal',

    // settings	the modal configuration (ie. app.state.modal)
		props: [ 'fieldvalue', 'field', 'valueFromField'],

		data() {
        return {
					mapId : Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10),
					accessToken:"pk.eyJ1Ijoib3JiZSIsImEiOiJjamh2cnVnN3owNDhuM3ZzMnhpb3NuZXh6In0.oJk70gjwutMnLwYuVEJBLA",
					options : {
						style : 'mapbox://styles/mapbox/light-v9',
						center: [ 2.350901, 48.873674 ],
						zoom: 7
					},
					markerAdded : false,
					geoJSON : {
						"type": "Point",
						"coordinates":  [ 2.350901, 48.873674 ]
					},
        }
    },

		computed: {
			value: function(){
				return {lat: this.valueFromField.lat, lng: this.valueFromField.lng}
			},
		},

		mounted: function(){
			this.createMap();
		},

		watch: {
				'value': function(newVal, oldVal){
					if( oldVal && newVal == oldVal )
						return;
					this.valueUpdated(newVal);
				}
		},

    created: function() {
    },

    methods: {

			createMap: function(){
				let center = this.options.center;

				// Old methods stored their value as {x:*, y:*} rather than {lat:*, lng:*},
				// making issues for some manipulation. In that case we should convert those values
				// to satisfy the new requirements. [see the ticket NS-218]
				if (this.fieldvalue && this.fieldvalue.value && (this.fieldvalue.value.x || this.fieldvalue.value.y)) {
					let newvalue = {
						lat: this.fieldvalue.value.lat ? this.fieldvalue.value.lat : (this.fieldvalue.value.x || 0),
						lng: this.fieldvalue.value.lng ? this.fieldvalue.value.lng : (this.fieldvalue.value.y || 0)
					};
					// @todo: to be set in verbose mode [ticket NS-113]
					console.warn('construct a Geo type with old method. Converting to', newvalue);
					this.fieldvalue.value = newvalue;
				}

				if( this.fieldvalue && this.fieldvalue.value != undefined && this.checkNumberValue(this.fieldvalue.value.lat) && this.checkNumberValue(this.fieldvalue.value.lng) )
					center = [this.fieldvalue.value.lng, this.fieldvalue.value.lat ];

				mapboxgl.accessToken = this.accessToken;
				this.map = new mapboxgl.Map({ container: this.mapId, center: center , zoom: this.options.zoom , style: 'mapbox://styles/orbe/cjk2es6pa1yk22rs5r83g2381' });

				this.nav = new mapboxgl.NavigationControl();
				this.map.addControl( this.nav , 'top-right');
				this.map.addControl( new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl }) , 'top-left' );

				this.map.on( 'click', this.clickMapHandler );
				this.map.on( 'load', this.loadedMapHandler );

			},

			checkNumberValue: function(val){
				let floatVal = parseFloat(val);
				if( val == "" || floatVal == NaN || floatVal == 'NaN' )
					val = null;
				else
					val = floatVal;
				return val;
			},

			clickMapHandler: function( evt ){
				this.valueUpdated( { lat : evt.lngLat.lat , lng : evt.lngLat.lng } );
			},

			loadedMapHandler: function() {
				if( this.fieldvalue && this.fieldvalue.value != undefined ){
					this.valueUpdated( { lat : this.fieldvalue.value.lat , lng : this.fieldvalue.value.lng } );
				}
				else {
					console.warn('Do we really need to setup a default value ? This seamns to cause the \'Computed property "value" was assigned to but it has no setter.\' error')
					this.value =  {lat:0, lng:0};
				}
			},

			valueUpdated: function( pos ){
				if( !this.map ) return;

				this.geoJSON.coordinates = [ pos.lng, pos.lat ];
				if( !this.markerAdded ){
					this.addLayer();
					this.markerAdded = true;
				}
				else{
					this.map.getSource('marker').setData( this.geoJSON );
				}

				this.$parent.valueUpdated(pos);

			},

			addLayer: function( ){

				this.map.addSource('marker', {type: 'geojson', data: this.geoJSON });

				this.map.addLayer({
					id : "marker-layer",
					source : "marker",
					type: "symbol",
					layout: {
						"icon-image": 'marker-15',
						"icon-size" : 1
					},
					paint: {
						"icon-color" : '#555555'
					}
				});
			},

    }

}
</script>

<style lang="stylus">


.modal-map-container
	height: 100%
	width: calc(100% - 20px)
	padding: 6px
	border-radius: 6px
	background-color: white
	z-index: 2
	box-shadow: 0 0 10px var(--shadowColor)

	.mapboxgl-map
		border-radius: 6px
		margin: 0
		top:0
		right:0
	.mapboxgl-canvas
		position: absolute
		width: 550px
		height: 360px
		z-index: 1

.mapboxgpl-crtl-top-rigth
	top: 270px !important
	right: 0px
	position: relative !important
	background-color: red

.mapboxgl-ctrl-top-left
	top: 11px
	left: 4px
	right: 70px
	.mapboxgl-ctrl-geocoder
		&.mapboxgl-ctrl
			width: 440px !important
			border-radius: 6px
			input.mapboxgl-ctrl-geocoder--input
				margin-left: 28px

.mapboxgl-ctrl-bottom-left
	display: none
.mapboxgl-ctrl-bottom-right
	display: none

.mapboxgl-ctrl-geocoder > .mapboxgl-ctrl-geocoder--icon-search
	top: 5px;
	left: 5px;
	position: absolute;

.mapboxgl-ctrl-icon.mapboxgl-ctrl-compass > .mapboxgl-ctrl-compass-arrow
	width: 14px
	height: 14px
	margin: 8px 0px;

.mapboxgl-ctrl-group > button
	width: 20px
	height: 20px

.mapboxgl-ctrl-geocoder input[type='text']
	height: 30px

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon-search
	top: 5px
	right: 5px
	position: absolute

.mapboxgl-ctrl-group > button.mapboxgl-ctrl-compass
	height: 30px

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right
	top: 5px
	left: 5px
	position: absolute

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--button
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon-loading
	display: none
	position: absolute
	background: white

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon-loading
	animation: spin 4s linear infinite

</style>
