var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "refs-ctn", attrs: { id: "content" } }, [
    _c("h1", [_vm._v("References")]),
    _c(
      "div",
      { staticClass: "columns-ctn" },
      _vm._l(_vm.list, function(ul) {
        return _c(
          "div",
          { staticClass: "column" },
          _vm._l(ul, function(li) {
            return _c("div", { staticClass: "module-theme" }, [
              _c("h1", [
                _c("span", { staticClass: "icon", class: li.name }),
                _c("span", [_vm._v(_vm._s(li.title))])
              ]),
              _c(
                "ul",
                { staticClass: "nodes" },
                _vm._l(li.components, function(cp) {
                  return cp.value.visibility
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.displayTab("module", cp.value.type)
                            }
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(cp.value.name))])]
                      )
                    : _vm._e()
                }),
                0
              )
            ])
          }),
          0
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }