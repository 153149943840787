var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.blocsToDisplay ? _vm.blocsToDisplay.length : "",
      staticClass: "schematic"
    },
    [
      _vm.target
        ? _c(
            "v-touch",
            {
              staticClass: "draw-scene",
              class: _vm.target.value.type,
              style: {
                "background-position":
                  _vm.scene.coordinate.x + "px " + _vm.scene.coordinate.y + "px"
              },
              attrs: { id: "scene-" + _vm.target.value._id },
              on: { pan: _vm.onPanScene },
              nativeOn: {
                mousemove: function($event) {
                  return _vm.onMouseMove($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "scene",
                  style: {
                    transform:
                      "translate(" +
                      _vm.scene.coordinate.x +
                      "px , " +
                      _vm.scene.coordinate.y +
                      "px ) scale(" +
                      _vm.scene.scale +
                      ")"
                  }
                },
                _vm._l(_vm.blocsToDisplay, function(bloc) {
                  return _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.blocEventHandler($event, bloc)
                        }
                      }
                    },
                    [
                      !bloc.value._deleted
                        ? _c("item", {
                            attrs: {
                              bloc: bloc,
                              "app-state": _vm.appState,
                              family: _vm.blocsToDisplay,
                              workspace: _vm.workspace
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.linksEventHandler($event)
                    }
                  }
                },
                [
                  _c("bloc-links", {
                    attrs: {
                      "app-state": _vm.appState,
                      target: _vm.target,
                      scene: _vm.scene
                    }
                  })
                ],
                1
              ),
              _c("link-menu", {
                attrs: {
                  "app-state": _vm.appState,
                  blocs: _vm.target.custom.children
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }