var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.component.value.visibility !== false
        ? _c(
            "v-touch",
            {
              staticClass: "block noselect",
              attrs: { tag: "li", workspace: _vm.workspace },
              on: { tap: _vm.addBlockToScene, pan: _vm.dragItem }
            },
            [
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.component.value.name) + " ")
              ])
            ]
          )
        : _vm._e(),
      _vm.concatDocLink
        ? _c("span", { staticClass: "docLink" }, [
            _c("a", { attrs: { href: _vm.concatDocLink, target: "_blank" } }, [
              _vm._v("?\t\t\t ")
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }