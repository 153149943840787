import ActionAbstract from '../ActionAbstract'
import * as helper from '../../helper'

class Save extends ActionAbstract {

    static get ID() {
        return 'actions:template:save'
    }

    run(state) {

        let global = this.getParam('global') || false   // the save process is called recursivly (i.e. global save) 

        switch (this.getParam('action')) {

            case "success":
                return global ? false : this.composeState(state, {
                    isLoading: false,
                })

            case "error":
                /// @todo: implement notification
                //this.trigger('notification', { message: this.getParam('message'), type: 'error' });
                console.error('notification', { message: this.getParam('message'), type: 'error' });
                return this.composeState(state, {
                    isLoading: false,
                    templates: {
                        error: {
                            code: this.getParam('code'),
                            message: this.getParam('message'),
                            action: 'save'
                        }
                    }
                })

            default:

                // the parent template, i.e. the target database
                this.template = helper.block.getTemplateByEntity(state.project, this.getParam('target'))

                if(!this.template) {
                    console.warn(`Invalid template database for target '${target}'.\nAborting save action.`)
                    return false
                }

                let target  = this.getParam('target')
                let node    = this.template             // node to save, by default the entire template
                let partial = node.value._id != target  // save the entire template or only some of those documents

                if (partial) {
                    node = helper.block.getEntityByIDInWholeProject(state.project, target)
                    node.memory = this.searchForMemories(node)
                }

                this.deps.mainStore.template.saveTemplate(this.template, node, partial).then(
                    response => this.trigger('template:save', { action: 'success', global: global }),
                    error => this.trigger('template:save', { action: 'error', code: error.code, message: error.message, global: global })
                )

                return this.composeState(state, {
                    isLoading: true,
                })
            }
    }

    /**
     * Search recursively for memories in the given node.
     * @param {Object} node - The node tree
     * @param {[Array]} memories - The known memories list
     * @retruns {Array} A list containing all memories founded
     */
    searchForMemories(node, memories = []) {

        if (node.value && node.value.memory) {
            node.value.memory.forEach((obj) => {
                let mem = helper.block.getMemoryById(this.template, obj.id)
                if (mem) memories.push(mem)
            })
        }

        if (node.custom && node.custom.children && node.custom.children.length > 0) {
            for (let subnode of node.custom.children) {
                memories = this.searchForMemories(subnode, memories)
            }
        }

        return memories
    }
}

export default Save
