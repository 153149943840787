import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class Save extends ActionAbstract {

    /*
    @param target : (optionnal) block id to recursivly save. Project id by default
    */

    static get ID() {
        return 'actions:project:save';
    }

    run(state) {

        this.project = state.project;
        this.memories = [];

        let nodeToSave = state.project;
        let isProject = true;
        let callback = (error) => {
            if (error) this.trigger('project:save', { subaction: 'error', code: error.code, message: error.message })
            else this.trigger('project:save', { subaction: 'success' })
        }

        // Local save from the inspector
        if( this.getParam('target') != undefined ){
            let nodeToSaveTemp = helper.block.getEntityByIDInWholeProject( state.project, this.getParam('target') );

            if (nodeToSaveTemp) {
                if (nodeToSaveTemp.value.type == "memory") { // handle local save upper references.
                    // Save the parent to include the references of the modified block
                    // If you save a memory you need the screen that include it the first time
                    // to save the reference on a local save.
    
                    let targetKey = Object.keys(nodeToSaveTemp.value)
                    .filter((key) => key.includes('ref_'))
                    .reduce((cur, key) => { return key}); // get the proper parent
    
                    let targetParent = nodeToSaveTemp.value[targetKey]
                    if (targetParent) {
                        nodeToSave = helper.block.getEntityByIDInWholeProject( state.project, targetParent)
                    }
                    
                } else nodeToSave = nodeToSaveTemp
            } else nodeToSave = nodeToSaveTemp

            this.searchForMemories( nodeToSave );
            nodeToSave.memory = [].concat( this.memories );
            isProject = false;
        }

        // save all templates
        if (nodeToSave.templates && nodeToSave.templates.length>0) {
            nodeToSave.templates.forEach((template) => {
                this.trigger('template:save', { target: template.value._id, global:true });
            })
        }
        const user = state.user.replace('org.couchdb.user:', '');
        this.deps.mainStore.project.saveProject(nodeToSave, callback, isProject, user);

        return this.composeState(state, {
            isLoading: true,
        });

    }

    runError(state) {
        // @todo: implement notification
        //this.trigger('notification', { message: this.getParam('message'), type: 'error' });
        console.error('notification', { message: this.getParam('message'), type: 'error' });
        return this.composeState(state, {
            isLoading: false,
            project: {
                error: {
                    code: this.getParam('code'),
                    message: this.getParam('message'),
                    action: 'save'
                }
            }
        })
    }

    runSuccess(state) {
        
        this.deps.mainStore.project.generateProjectJSON(
            error => this.trigger('project:save', { subaction: 'error', code: error.code, message: error.message })
        ).then( () => {

            if(state.breadcrumb.history) {
                this.trigger('editor:openEditor', { id : state.breadcrumb.history[0] });
            }

        }).catch( (error) => {

            this.trigger('project:save', { 
                subaction: 'error', 
                code: error.code, 
                message: error.message 
            })

        }).finally( () => {
            return this.composeState(state, {
                isLoading: false,
            });
        })

    }

    searchForMemories( node ){

        if( node.value && node.value.memory ){
            node.value.memory.forEach( ( memData ) => {
                let mem = helper.block.getMemoryById( this.project, memData.id );
                if (mem) this.memories.push( mem );
            });
        }

        if( node.custom && node.custom.children && node.custom.children.length > 0 ){
			for( let subnode of node.custom.children ){
				this.searchForMemories( subnode );
			}
        }
        
    }
}

export default Save;