<template lang="pug">
div
	div(v-if="nodeMemoriesInspector")
		sub-topic(:project="project" , :target="target", :bloc="nodeMemoriesInspector", :state="state")

</template>

<script>

import * as helper from '../../../helper';
import actionManager from '../../../actions';

import SubTopic from "../blocs/Topic.vue";

export default {

	name: 'node-memories',

	props:[ 'project', 'target', 'bloc', 'schematicLevel', 'state'],

	components : { SubTopic },


	data() {
		return {
			screenId : null,
			topicAccordion: true,
			accordion: true,
			prevNodeTargetId: null,
			states: null
		}
	},

	computed: {

		'nodeTargetId': function() {
			let f = helper.block.getField(this.target, this.bloc.target);
			return f ? f.value : null;
		},

		'nodeTarget': function(){

			let block = null;

			if( this.nodeTargetId != null )
				block = helper.block.getBlockById( this.project , this.nodeTargetId );

			return block;
		},

		'memories' : function(){
			let target = this.nodeTarget;
			if( target && target.value.memory && target.value.memory.length > 0 )
				return target.value.memory;
			return [];
		},

		'nodeMemoriesInspector': function(){

			let setField  = helper.block.getField( this.target, 'memories-set' );
			let getField  = helper.block.getField( this.target, 'memories-get' );
			let modeField = helper.block.getField( this.target, 'memories-mode' );

			let watchOption = this.bloc.watchOption === false ? false : true;
			let fields = {
				actions:  [],
				memories: []
			};

			if(this.schematicLevel === 'process' && watchOption === true)
				fields.actions = this.getAndSetFields( setField, getField,  modeField );

			if( this.memories ){
				let isInstance = this.target.value.type.startsWith('template-');
				this.memories.forEach( (memData) => {

					let memId = memData.id;
					let mem = helper.block.getMemoryById( this.project , memId );
					let suffix = "mem-";
					//voir ici pour ajouter secret au prefix.
					let memValueField = helper.block.getField( mem, 'value' );
					let valueField = helper.block.getField( this.target, suffix+memId );
					let isArray = mem.value.format.toLowerCase() === "array";


					// // @todo:to be removed safely.
					// // temporary hack to work with the proper value, see UpdateFields.getDefaultValue() method
					// // force to copy the right target memory values and associated headers from source
					// // since v3.2.0, [ticket NS-453] enable memory type Array in node's inspector
					// if( isInstance && isArray ) {
					// 	// once at the instance creation
					// 	// @todo: move this (or logic) where fields are created, this method is only
					// 	// avaiblable if the inspector is open after the module creation.
					// 	if( valueField && !Array.isArray(valueField.value) ) {
					// 		const source  = mem.value.fields.filter(field => field.name === 'value')[0];
					// 		memValueField = this.target.value.fields.filter(field => field.name === suffix+memId)[0];
					// 		Object.assign(memValueField, source);
					// 		memValueField.name = suffix+memId;
					// 	}
					// }

					// define default parameters
					let params = {
						"type": "field",
						"name": suffix+memId,
						"widget": mem.value.format,
						"coreFormat": mem.value.format,
						"default": memValueField.value,
						"disabled": !isInstance,
						"label": mem.value.name,
						"description": `The memory ${mem.value.name} of the current element.`,
						"connection": {
							"in": {
								"pluggable": !isArray,
								"default": valueField ? valueField.in : false
							},
							"out": {
								"pluggable": !isArray,
								"default": valueField ? valueField.out : false
							}
						}
					};

					// since v3.2.0, [ticket NS-453] enable memory type Array in node's inspector
					// define array pattern to disable column editing
					if( isInstance && isArray && memValueField.header ) {
						let capitalize = (s) => { return s.charAt(0).toUpperCase() + s.slice(1); }
						params.pattern = [];
						memValueField.header.forEach( col => params.pattern.push(
							{ 
								name: col.name,
								type: capitalize(col.widget),
								widget: col.widget
							}
						));
					}
					
					fields.memories.push(params);
				});
			}

			let inspector = this.includeInTopic( this.bloc.title, fields );

			if( this.prevNodeTargetId != this.nodeTargetId ){
				actionManager.trigger('block:updateIncludedBlocks', { id : this.target.value._id, includedBlocks : null, includedInspector : [ inspector ] });
				this.prevNodeTargetId = this.nodeTargetId;
			}

			return inspector;
		}

	},

	watch: {

	},

	created: function(){
		this.prevNodeTargetId = null;
	},

	destroyed: function(){

	},

	methods:{
		getAndSetFields: function( setField, getField, modeField ){
			return [
				{
					"type": "field",
					"name" : "memories-mode",
					"widget": "select",
					"container": 'group-field-actions',
					"coreFormat": "String",
					"default": modeField ? modeField.value : 'get',
					"label": "Mode",
					"description": "Select the mode to get the actual values of the memories. The 'get' mode needs activation to get the values of the memories. The 'watch' mode gets the values of the memories as they are actualized.",
					"connection": null,
					"options": [ {'label':'Get', 'value':'get'}, {'label':'Watch', 'value':'watch'}  ]
				},
				{
					"type": "field",
					"name" : "memories-get",
					"widget": "calculated",
					"container": 'group-field-actions',
					"coreFormat": "Mixed",
					"default": null,
					"label": "Get memories",
					"description": "Outputs all connected memories.",
					"connection": {
						"in": {
							"pluggable": true,
							"default": getField ? getField.in : false
						},
						"out": {
							"pluggable": true,
							"default": getField ? getField.out : false
						},
						"activate": {
							"pluggable": true,
							"default": true,
							"force" : true
						}
					}
				},
				{
					"type": "field",
					"name" : "memories-set",
					"widget": "calculated",
					"container": 'group-field-actions',
					"coreFormat": "Mixed",
					"default": null,
					"label": "Set memories",
					"description": "Update all memories with their input values.",
					"connection": {
						"in": {
							"pluggable": true,
							"default": setField ? setField.in : false
						},
						"out": {
							"pluggable": true,
							"default": setField ? setField.out : false
						},
						"activate": {
							"pluggable": true,
							"default": true,
							"force" : true
						}
					}
				}
			];
		},

		includeInTopic: function( topicName, fields ){
			return {
				"type": "topic",
				"name": "topic-node-memories",
				"title": topicName,
				"isAccordion": true,
				"children": [
					fields.actions.length === 0 ? {} :
					{
						"type": 'group',
						"name": 'group-actions',
						"title": 'Actions',
						"isAccordion": true,
						"children": [
							{
								"type": 'group-field',
								"name": 'group-field-actions',
								"children": fields.actions
							}
						]
					},
					fields.memories.length === 0 ? {} :{
						"type": "group",
						"name": "group-node-memories",
						"title": "I/O",
						"isAccordion": true,
						"children": [
							{
								"type": "group-field",
								"name": "group-fields-io",
								"children": fields.memories
							}
						]
					}
				]
			};
		}



	},

}

</script>

<style lang="stylus">


</style>
