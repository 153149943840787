import React from 'react';
import CommonText from '../CommonText';
import DOMPurify from 'dompurify';

export default class Paragraph extends CommonText {

    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            display : 'block',
        });
    }

    render() {

        let content = DOMPurify.sanitize(
            this.props.innerText,
            {
                ALLOWED_TAGS: ['a', 'iframe', 'br', 'div', 'p', 'span', 'pre'],
                ALLOWED_ATTR: ['target', 'style', 'href', 'src', 'width', 'height', 'frameborder', 'title', 'allow', 'allowfullscreen']
            }
        );
        content = content.replace(/\\n/g, '').replace(/\\r/g, '');

        if( window.cordovaConfig ){
            var patt = /href="(.*?)"/g;
            var array1;

            while ((array1 = patt.exec(content)) !== null) {
              let link = array1[0].replace("href=\"", "");
              link = link.slice(0, link.length-1);
              let replacement = `style="text-decoration:underline;" onclick="window.openLink('${link}')"`;
              content = content.replace(array1[0], replacement);
            }
        }

        return this.wrapHelper(
            <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle} dangerouslySetInnerHTML={{__html: content}}>
            </div>
        );
    }
}
