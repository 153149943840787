/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import UiObject from '../../UiObject';
import { OpenLayersContext as Context } from '../../../contexts';
import { GeoShape, Shape2D } from '../../../../data-types';

const ol = new Context();

/**
 * A Map Polygon UI is an element that is contained by a Map Layer UI. It displays a polygon using 
 * a predefined list of points or traced/edited directly on the map by the user.
 */
class UiMapOlPolygon extends UiObject {
    constructor(id, props) {
        super(id, props, 'MapOLPolygon');

        //console.log('---> POLYGON CREATE', id);

        this.label = 'MapOlPolygon';
        this.initialized = false;

        // uid: int or string
        this.map = -1; // the parent ol/map uid
        this.layer = -1; // the parent ol/layer uid
        this.feature = -1; // corresponding ol/feature uid

        // store polygon geometry
        this.geometry = {
            type: 'cartesian', // geo ar cartesien
            data: [], // GeoJSON or array containing coordinates
            update: false, // whether the polygon geometry must be updated
        };


        this.tracing = false;
        this.editing = false;
        this.mouseover = false;
    }

    static getAuthConfig() {
        return {
            exportFormat: 'ui-map-ol-polygon',
            label: 'MapOL Polygon',
            parent: 'ui-maps-ol',
            isDisplayed: true,
            canBeCreated: false,
            isEmbeddable: true,
            requireUiParentTypes: ['MapOLLayer'],
            description: 'A Map Polygon UI is an element that is contained by a Map Layer UI. It displays a polygon  using a predefined list of points or traced/edited directly on the map by the user.',
        };
    }

    initParameters() {
        // topic and groups
        this.inspector = [
            {
                type: 'topic',
                name: 'settings',
                title: 'Polygon Settings',
                isAccordion: true,
                children: [
                    {
                        type: 'group',
                        name: 'container',
                        title: 'Container',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-container',
                            },
                        ],
                    },
                    {
                        type: 'group',
                        name: 'tags',
                        title: 'Tags',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-tags',
                            },
                        ],
                    },
                    {
                        type: 'group',
                        name: 'parameters',
                        title: 'Parameters',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-parameters',
                            },
                        ],
                    },
                    {
                        type: 'group',
                        name: 'styles',
                        title: 'Styles',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-styles',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'topic',
                name: 'i/O',
                title: 'I/O',
                isAccordion: true,
                children: [
                    {
                        type: 'group',
                        name: 'data',
                        title: 'Data',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-data',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'topic',
                name: 'methods',
                title: 'Methods',
                isAccordion: true,
                children: [
                    {
                        type: 'group',
                        name: 'trace',
                        title: 'Trace',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-trace',
                            },
                        ],
                    },
                    {
                        type: 'group',
                        name: 'edit',
                        title: 'Edit',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-edit',
                            },
                        ],
                    },
                    {
                        type: 'group',
                        name: 'get data',
                        title: 'Get Data',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-get-data',
                            },
                        ],
                    },
                    {
                        type: 'group',
                        name: 'clear data',
                        title: 'Clear Data',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-clear-data',
                            },
                        ],
                    },
                    {
                        type: 'group',
                        name: 'get bounding box',
                        title: 'Get Bounding Box',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-get-bounding-box',
                            },
                        ],
                    },
                    {
                        type: 'group',
                        name: 'transform',
                        title: 'Transform',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-transform',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'topic',
                name: 'feedback',
                title: 'Feedback',
                isAccordion: true,
                children: [
                    {
                        type: 'group',
                        name: 'events',
                        title: 'Events',
                        isAccordion: true,
                        children: [
                            {
                                type: 'group-field',
                                name: 'group-field-events',
                            },
                        ],
                    },
                ],
            },
        ];

        this.parameters = {
            // Settings
            // container
            parent: {
                type: 'String',
                callable: true,
                connection: {
                    in: { pluggable: true, default: true },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Map Layer',
                    description: 'Specified the Reference ID of the parent Map Layer UI.',
                    container: 'group-field-container',
                    widget: 'calculated',
                },
            },
            // tags
            addTags: {
                type: 'String',
                partial: null,
                method: 'setCustomTags',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Add tags',
                    description: 'List of tags that are used to easily retrieve objects via queries (separated by a coma).',
                    container: 'group-field-tags',
                },
            },
            removeTags: {
                type: 'String',
                partial: null,
                method: 'removeTags',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Remove tags',
                    description: 'List of tags that are removed from the object (separated by a coma).',
                    container: 'group-field-tags',
                },
            },
            // parameters
            snap: {
                type: 'Boolean',
                default: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Snap',
                    description: 'If set to TRUE, handles snapping of the mouse on the polygon while editing or tracing the polygon.',
                    container: 'group-field-parameters',
                },
            },
            draggable: {
                type: 'Boolean',
                default: false,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Draggable',
                    description: 'If set to TRUE, enables the manual drag of the polygon on the map.',
                    container: 'group-field-parameters',
                },
            },
            // styles
            visibility: {
                type: 'Boolean',
                default: true,
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Visibility',
                    description: 'Specifies whether the polygon is visible or not.',
                    container: 'group-field-styles',
                },
            },
            opacity: {
                type: 'Float',
                default: 1.0,
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Opacity',
                    description: 'Sets the opacity of the polygon.',
                    container: 'group-field-styles',
                },
            },
            fill: {
                type: 'Boolean',
                default: true,
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Fill',
                    description: 'Specifies whether the polygon is filled or not.',
                    container: 'group-field-styles',
                },
            },
            fillColor: {
                type: 'Color',
                default: { hex: '#FFFFFF' },
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Fill Color',
                    description: 'Sets the fill color of the polygon.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'fill', operator: '==', value: true }],
                },
            },
            border: {
                type: 'Boolean',
                default: true,
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Border',
                    description: 'Specifies whether the polygon has border or not.',
                    container: 'group-field-styles',
                },
            },
            borderColor: {
                type: 'Color',
                default: { hex: '#000000' },
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Border Color',
                    description: 'Sets the border color of the polygon.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'border', operator: '==', value: true }],
                },
            },
            borderWidth: {
                type: 'Float',
                default: 1.0,
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Border Width',
                    description: 'Sets the border width of the polygon.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'border', operator: '==', value: true }],
                },
            },
            borderLineJoin: {
                type: 'String',
                default: 'miter',
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Border Line Join',
                    description: 'Sets the line join type of the border of the polygon: miter, round or bevel.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'border', operator: '==', value: true }],
                    widget: 'select',
                    options: [
                        { value: 'miter', label: 'Miter' },
                        { value: 'round', label: 'Round' },
                        { value: 'bevel', label: 'Bevel' },
                    ],
                },
            },
            borderLineCap: {
                type: 'String',
                default: 'round',
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Border Line Cap',
                    description: 'Sets the line cap type of the border of the polygon: butt, round, square.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'border', operator: '==', value: true }],
                    widget: 'select',
                    options: [
                        { value: 'butt', label: 'Butt' },
                        { value: 'round', label: 'Round' },
                        { value: 'square', label: 'Square' },
                    ],
                },
            },
            dashedLine: {
                type: 'Boolean',
                default: false,
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Dashed Line',
                    description: 'Specifies whether dashes and gaps are used to draw the border of the polygon.',
                    container: 'group-field-styles',
                },
            },
            dashPattern: {
                type: 'String',
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Dash Pattern',
                    description: 'Set a list of numbers defining the line dash pattern (e.g. \'0, 20\' for dotted line). Separate all values with a comma or space.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'dashedLine', operator: '==', value: true }],
                },
            },
            dashOffset: {
                type: 'Float',
                partial: 'mapstyle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Dash Offset',
                    description: 'Specified the line dash offset.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'dashedLine', operator: '==', value: true }],
                },
            },
            cursorType: {
                type: 'String',
                default: 'circle',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Cursor Type',
                    description: 'Sets the primitive shape for the cursor of the polygon: circle, square.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'border', operator: '==', value: true }],
                    widget: 'select',
                    options: [
                        { value: 'circle', label: 'Circle' },
                        { value: 'square', label: 'Square' },
                    ],
                },
            },
            cursorColor: {
                type: 'Color',
                default: { hex: '#FFFFFF' },
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Cursor Color',
                    description: 'Sets the color of the cursor of the polygon: circle, square.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'border', operator: '==', value: true }],
                },
            },
            cursorSize: {
                type: 'Float',
                default: 3.0,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Cursor Size',
                    description: 'Sets the size of the cursor of the polygon.',
                    container: 'group-field-styles',
                    conditions: [{ field: 'border', operator: '==', value: true }],
                },
            },

            // I/O
            // data
            fromGeoShape: {
                type: 'GeoShape',
                method: 'setGeoShape',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false, disabled: true },
                },
                auth: {
                    label: 'From Geo Shape',
                    description: 'Applies the given list of geo coordinates to the polygon.',
                    container: 'group-field-data',
                },
            },
            from2DShape: {
                type: 'Shape2D',
                method: 'set2DShape',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false, disabled: true },
                },
                auth: {
                    label: 'From 2D Shape',
                    description: 'Applies the given list of cartesian 2D positions to the polygon.',
                    container: 'group-field-data',
                },
            },
            addGeoPoint: {
                type: 'Geo',
                method: 'addGeoPoint',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false, disabled: true },
                },
                auth: {
                    label: 'Add Geo Point',
                    description: 'Add a new point to the polygon using a geo coordinates.',
                    container: 'group-field-data',
                },
            },
            add2DVector: {
                type: 'Vector2',
                method: 'add2DVector',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false, disabled: true },
                },
                auth: {
                    label: 'Add 2D Vector',
                    description: 'Add a new point to the polygon using a cartesian 2D position.',
                    container: 'group-field-data',
                },
            },

            // methods
            // trace
            startTrace: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Trace',
                    description: 'Starts drawing the polygon manually and outputs a message on sucess.',
                    container: 'group-field-trace',
                },
            },
            canvelTracing: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Cancel Tracing',
                    description: 'Aborts the interaction on the map to draw the polygon and outputs a message on success.',
                    container: 'group-field-trace',
                },
            },
            onTraceStart: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Trace Start',
                    description: 'Outputs a message when the drawing has started.',
                    container: 'group-field-trace',
                    widget: 'calculated',
                },
            },
            onTraceEnd: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Trace End',
                    description: 'Outputs a message when the drawing has finished.',
                    container: 'group-field-trace',
                    widget: 'calculated',
                },
            },
            onTraceAbort: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Trace Abort',
                    description: 'Outputs a message when the drawing is aborted.',
                    container: 'group-field-trace',
                    widget: 'calculated',
                },
            },
            // edit
            startEditing: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Start Editing',
                    description: 'Adds an interaction on the map to modify the polygon manually, outputs a message on success.',
                    container: 'group-field-edit',
                },
            },
            stopEditing: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Stop Editing',
                    description: 'Removes the interaction on the map to modify the polygon manually, outputs a message on success.',
                    container: 'group-field-edit',
                },
            },
            onEditStart: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Edit Start',
                    description: 'Outputs a message when the modification has started.',
                    container: 'group-field-edit',
                    widget: 'calculated',
                },
            },
            onEditEnd: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Edit End',
                    description: 'Outputs a message when the modification has finished.',
                    container: 'group-field-edit',
                    widget: 'calculated',
                },
            },
            // get data
            getGeoShape: {
                type: 'GeoShape',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Get Geo Shape',
                    description: 'Returns the polygon data as a list of geo coordinates.',
                    container: 'group-field-get-data',
                },
            },
            get2DShape: {
                type: '2DShape',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Get 2D Shape',
                    description: 'Returns the polygon data as a list of cartesian 2D positions.',
                    container: 'group-field-get-data',
                },
            },
            // clear data
            clearData: {
                type: 'String',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Clear Data',
                    description: 'Triggers the clearing of the polygon.',
                    container: 'group-field-clear-data',
                },
            },
            // get bounding box
            getGeoBoundingBox: {
                type: 'GeoShape',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Get Geo Bounding Box',
                    description: 'Returns the bounding rectangle containing the polygon as a list of geo coordinates.',
                    container: 'group-field-get-bounding-box',
                },
            },
            get2DBoundingBox: {
                type: '2DShape',
                trigger: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                    activate: { pluggable: true, default: true, force: true },
                },
                auth: {
                    label: 'Get 2D Bounding Box',
                    description: 'Returns the bounding rectangle containing the polygon as a list of cartesian positions.',
                    container: 'group-field-get-bounding-box',
                },
            },
            // transform
            // startTransform: {
            //     type: 'String',
            //     trigger: true,
            //     connection: {
            //         in: { pluggable: true, default: false },
            //         out: { pluggable: true, default: false },
            //         activate: { pluggable: true, default: true, force: true },
            //     },
            //     auth: {
            //         label: 'Start Transform',
            //         description: 'Triggers the transformation on all elements of the polygon and output a message when started.',
            //         container: 'group-field-transform',
            //     },
            // },
            // transformScale: {
            //     type: 'Float',
            //     default: 1.0,
            //     connection: {
            //         in: { pluggable: true, default: false },
            //         out: { pluggable: true, default: false },
            //     },
            //     auth: {
            //         label: 'Scale',
            //         description: 'Specifies the scale to apply on all elements of the polygon.',
            //         container: 'group-field-transform',
            //     },
            // },
            // transformRotate: {
            //     type: 'Angle',
            //     default: 0,
            //     connection: {
            //         in: { pluggable: true, default: false },
            //         out: { pluggable: true, default: false },
            //     },
            //     auth: {
            //         label: 'Rotate',
            //         description: 'Sets the rotation to apply on all elements of this polygon.',
            //         container: 'group-field-transform',
            //     },
            // },
            // transformTranslate: {
            //     type: 'Geo',
            //     connection: {
            //         in: { pluggable: true, default: false },
            //         out: { pluggable: true, default: false },
            //     },
            //     auth: {
            //         label: 'Translate',
            //         description: 'Sets the translation to apply on all elements of this polygon.',
            //         container: 'group-field-transform',
            //     },
            // },
            // transformEasing: {
            //     type: 'String',
            //     default: 'linear',
            //     connection: {
            //         in: { pluggable: true, default: false },
            //         out: { pluggable: true, default: false },
            //     },
            //     auth: {
            //         label: 'Easing',
            //         description: 'Sets the type of transform to apply on the polygon: linear, ease-in, ease-out, in-and-out or up-and-down.',
            //         container: 'group-field-transform',
            //         widget: 'select',
            //         options: [
            //             { value: 'linear', label: 'Linear' },
            //             { value: 'ease-in', label: 'Ease-In' },
            //             { value: 'ease-out', label: 'Ease-Out' },
            //             { value: 'in-and-out', label: 'In And Out' },
            //             { value: 'up-and-down', label: 'Up And Down' },
            //         ],
            //     },
            // },
            // transformDuration: {
            //     type: 'Float',
            //     connection: {
            //         in: { pluggable: true, default: false },
            //         out: { pluggable: true, default: false },
            //     },
            //     auth: {
            //         label: 'Duration',
            //         description: 'Sets the duration in seconds on which to apply transform changes. This value must be greater than 0 to apply easing.',
            //         container: 'group-field-transform',
            //     },
            // },
            // onTransformationEnd: {
            //     type: 'String',
            //     event: true,
            //     connection: {
            //         in: { pluggable: false, default: false },
            //         out: { pluggable: true, default: false },
            //     },
            //     auth: {
            //         label: 'On Transformation End',
            //         description: 'Outputs a message when the transformation has ended.',
            //         container: 'group-field-transform',
            //         widget: 'calculated',
            //     },
            // },

            // feedback
            // events
            onClick: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Click',
                    description: 'Outputs a message when the polygon is clicked.',
                    container: 'group-field-events',
                    widget: 'calculated',
                },
            },
            onDbClick: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Double Click',
                    description: 'Outputs a message when the polygon is double clicked.',
                    container: 'group-field-events',
                    widget: 'calculated',
                },
            },
            onMouseEnter: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Mouse Enter',
                    description: 'Outputs a message when a mouse enter is detected on the polygon.',
                    container: 'group-field-events',
                    widget: 'calculated',
                },
            },
            onMouseLeave: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Mouse Leave',
                    description: 'Outputs a message when a mouse leave is detected on the polygon.',
                    container: 'group-field-events',
                    widget: 'calculated',
                },
            },
            onDragEnd: {
                type: 'String',
                event: true,
                connection: {
                    in: { pluggable: false, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'On Drag End',
                    description: 'Outputs a message when the drag ended.',
                    container: 'group-field-events',
                    widget: 'calculated',
                },
            },
        };
    }


    // HACK:TODO: check nodal-app requirements and prefers
    // replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init(); }

    init() {
        super.init();
        this.initialized = true;

        this.getGeoShape = this.getGeoShape.bind(this);
        this.get2DShape = this.get2DShape.bind(this);
        this.getGeoBoundingBox = this.getGeoBoundingBox.bind(this);
        this.get2DBoundingBox = this.get2DBoundingBox.bind(this);
        this.clearData = this.clearData.bind(this);
        this.startTrace = this.startTrace.bind(this);
        this.cancelTrace = this.cancelTrace.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.stopEditing = this.stopEditing.bind(this);

        this.setGeoShape = this.setGeoShape.bind(this);
        this.set2DShape = this.set2DShape.bind(this);
        this.addGeoPoint = this.addGeoPoint.bind(this);
        this.add2DVector = this.add2DVector.bind(this);

        // console.log('---> POLYGON INITED');

        // IMPORTANT: the parent property is always required while creating an UI object. Depending
        // of the current schematic, the user could thing that this porperty is stored (but it's not),
        // so we force to display the error message to inform the user about the right strategy.
        if (!this.get('parent')) this.addToParent(null);
    }

    // @todo: never be called
    dispose() {
        super.dispose();
    }

    update() {
        // update geometry
        if (this.geometry.update) {
            // console.log('---> POLYGON UDPDATE GEOMETRY', this.geometry);
            const done = this.geometry.type === 'geo'
                ? this.createFromGeoJSON(this.geometry.data)
                : this.createFromCoordinates(this.geometry.data);
            if (done) this.geometry.update = false;
        }
        // or styles
        else if (this.isValidFeature()) {
            ol.setStyle(this.layer, this.feature, this.getStyle());
        }
    }

    /**
     * Is called when the object is created and the module parameters setted.
     */
    onCreate() {
        //console.log('---> POLYGON CREATED', this.id, this.feature);
        this.update();
    }

    /**
     * Is called when the object is updated and the module parameters setted.
     */
    onUpdate() {
        //console.log('---> POLYGON UPDATED', this.id, this.feature);
        this.update();
    }

    /**
    * Is called when the object is deleted.
    */
    onDelete() {
        //console.log('---> POLYGON DELETED', this.id, this.feature);
        // remove map feature
        if (this.isValidFeature()) {
            if (ol.removeFeature(this.feature, this.layer)) this.feature = -1;
            else console.warn(`Failed to delete the UiMapOlPolygon '${this.label}' [${this.id}]`);
        }

        // remove all listeners
        Object.values(this.triggerModules).forEach((module) => this.unregisterTriggerListeners(module));
    }

    /**
     * Attach all trigger field listener to this object.
     *
     * @param {Object} module - The parent module
     * @override
     */
    registerTriggerListeners(module) {
        super.registerTriggerListeners(module);

        // triggers
        module.addInputListener('startTrace', this.startTrace);
        module.addInputListener('canvelTracing', this.cancelTrace);
        module.addInputListener('startEditing', this.startEditing);
        module.addInputListener('stopEditing', this.stopEditing);
        module.addInputListener('getGeoShape', this.getGeoShape);
        module.addInputListener('get2DShape', this.get2DShape);
        module.addInputListener('clearData', this.clearData);
        module.addInputListener('getGeoBoundingBox', this.getGeoBoundingBox);
        module.addInputListener('get2DBoundingBox', this.get2DBoundingBox);
        // module.addInputListener('startTransform', this.startTransform);
        // recursive params
        module.addInputListener('fromGeoShape', this.setGeoShape);
        module.addInputListener('from2DShape', this.set2DShape);
        module.addInputListener('addGeoPoint', this.addGeoPoint);
        module.addInputListener('add2DVector', this.add2DVector);
    }

    /**
     * Detach all trigger field listener from this object.
     *
     * @param {Object} module - The parent module
     * @override
     */
    unregisterTriggerListeners(module) {
        super.unregisterTriggerListeners(module);

        // triggers
        module.removeInputListener('startTrace', this.startTrace);
        module.removeInputListener('canvelTracing', this.cancelTrace);
        module.removeInputListener('startEditing', this.startEditing);
        module.removeInputListener('stopEditing', this.stopEditing);
        module.removeInputListener('getGeoShape', this.getGeoShape);
        module.removeInputListener('get2DShape', this.get2DShape);
        module.removeInputListener('clearData', this.clearData);
        module.removeInputListener('getGeoBoundingBox', this.getGeoBoundingBox);
        module.removeInputListener('get2DBoundingBox', this.get2DBoundingBox);
        // module.removeInputListener('startTransform', this.startTransform);
        // recursive params
        module.removeInputListener('fromGeoShape', this.setGeoShape);
        module.removeInputListener('from2DShape', this.set2DShape);
        module.removeInputListener('addGeoPoint', this.addGeoPoint);
        module.removeInputListener('add2DVector', this.add2DVector);
    }

    /**
      * Returns whether the parent map is valid or not.
      * @returns {Boolean}
      */
    isValidMap() {
        if (!this.map || this.map === -1) {
            console.warn(`The map parent is missing for the UiMapOlPolygon '${this.label}' [${this.id}]`);
            return false;
        }
        return true;
    }

    /**
     * Returns whether the parent layer is valid or not.
     * @returns {Boolean}
     */
    isValidContainer() {
        if (!this.layer || this.layer === -1) {
            console.warn(`The map layer container is missing for the UiMapOlPolygon '${this.label}' [${this.id}]`);
            return false;
        }
        return true;
    }

    /**
     * Returns if the current map feature is valid or not.
     * @returns {Boolean}
     */
    isValidFeature() {
        return this.feature && this.feature !== -1;
    }

    /**
     * Set the context of this polygon.
     *
     * @param {ol.Map} map — The parent map object.
     * @param {ol.layer} layer — The parent layer obejct.
     */
    setContext(map, layer) {
        this.map = ol.registerMap(map);
        this.layer = ol.registerLayer(layer);
        //console.warn('---> POLYGON CONTEXT', 'map:', this.map, 'layer:', this.layer);
        // @todo: To be removed, the call should be restricted for user action
        // here to satisfy UiObject / REACT lifecycle
        this.update();
    }

    /**
     * Applies the given list of geo coordinates to the polygon.
     * Data must be a GeoJSON type polygon.
     *
     * @param {String} value — The GeoJSON content.
     * @param {Object} options — The link options.
     */
    setGeoShape(value, options) {
        // @todo: extending nodal-ui, do not use options, the update should not call this method
        // if (!value || (this.initialized && typeof options !== 'object')) return;
        if (!value) return;
        if (value.dataTypeName) value = value._value; // working this incoming data (not param value)

        this.geometry.type = 'geo';
        this.geometry.data = value;
        this.geometry.update = true;
    }

    /**
     * Returns the polygon data as a list of geo coordinates.
     */
    getGeoShape() {
        const json = ol.getFeatureGeoJSON(this.feature, this.layer);
        this.fireOutput('getGeoShape', new GeoShape(json));
    }

    /**
     * Returns the bounding rectangle containing the polygon geometry
     * as a list of geo coordinates.
     */
    getGeoBoundingBox() {
        const json = ol.getExtentGeoJSON(this.feature, this.layer);
        this.fireOutput('getGeoBoundingBox', new GeoShape(json));
    }

    /**
     * Applies the given list of cartesian 2D positions to the polygon.
     * Data must be stored in array of array: [[x,y],[x,y], … [x,y]]
     *
     * @param {Array} value — The list of coordinates defining the polygon geometry.
     * @param {Object} options — The link options.
     */
    set2DShape(value, options) {
        // @todo: extending nodal-ui, do not use options, the update should not call this method
        // if (!value || (this.initialized && typeof options !== 'object')) return;
        if (!value) return;
        if (value.dataTypeName) value = value._value; // working this incoming data (not param value)

        this.geometry.type = 'cartesian';
        this.geometry.data = value;
        this.geometry.update = true;
    }

    /**
     * Returns the polygon data as a list of cartesian 2D positions.
     */
    get2DShape() {
        const coords = ol.getFeatureCoordinates(this.feature, this.layer);
        this.fireOutput('get2DShape', new Shape2D(coords));
    }

    /**
     * Returns the bounding rectangle containing the polygon geometry
     * as a list of cartesian positions.
     */
    get2DBoundingBox() {
        const coords = ol.getExtentCoordinates(this.feature, this.layer);
        this.fireOutput('get2DBoundingBox', new Shape2D(coords));
    }

    /**
     * Add a new point to the polygon geometry using a geo coordinates.
     * @param {*} value — The Geo point to be added.
     */
    addGeoPoint(value) {
        if (!value) return;
        if (value.dataTypeName) value = value._value; // working this incoming data (not param value)
        value = Context.geoToMapCoordinate(value);
        this.add2DVector(value);
    }

    /**
     * Add a new point to the polygon geometry using a cartesian 2D position.
     * @param {*} value — The 2D Vector to be added.
     */
    add2DVector(value) {
        if (!value) return;
        if (value.dataTypeName) value = value._value; // working this incoming data (not param value)
        // switch to array (if needed)
        if (!Array.isArray(this.geometry.data)) this.geometry.data = [];
        if (!Array.isArray(value)) value = [value.x, value.y];

        // exclude duplicate
        if (this.geometry.data.length) {
            const point = this.geometry.data[this.geometry.data.length - 1];
            if (point[0] === value[0] && point[1] === value[1]) return;
        }

        this.geometry.type = 'cartesian';
        this.geometry.data.push(value);
        this.geometry.update = true;
    }

    /**
     * Clear the polygon geometry.
     */
    clearData() {
        this.geometry.data = [];
        if (ol.removeFeature(this.feature, this.layer)) {
            this.fireOutput('clearData', 'Clear map polygon data done.');
            this.feature = -1;
        }
    }

    /**
     * Create a new style from user parameters.
     * @returns {ol.style.Style} - The polygon style.
     */
    getStyle() {
        const styles = this.getPartial('mapstyle');
        // @todo: extending nodal-ui, do we really need to setup default values ?
        if ('visibility' in styles === false) styles.visibility = true;
        if ('opacity' in styles === false) styles.opacity = 1;
        if ('fill' in styles === false) styles.fill = true;
        if ('fillColor' in styles === false) styles.fillColor = 'rgba(255, 255, 255, 1)';
        if ('border' in styles === false) styles.border = true;
        if ('borderColor' in styles === false || !styles.borderColor) styles.borderColor = 'rgba(0, 0, 0, 1)';
        if ('borderWidth' in styles === false) styles.borderWidth = 1;
        // if ('borderLineJoin' in styles === false) styles.borderLineJoin = 'miter';
        // if ('borderLineCap' in styles === false) styles.borderLineCap = 'round';
        if ('dashedLine' in styles === false) styles.dashedLine = false;
        if ('dashPattern' in styles === false) styles.dashPattern = '';
        if ('dashOffset' in styles === false) styles.dashOffset = 0;

        // get select item
        styles.borderLineJoin = this.getSelectOptionValue('borderLineJoin', styles.borderLineJoin);
        styles.borderLineCap = this.getSelectOptionValue('borderLineCap', styles.borderLineCap);

        // hide feature
        if (!styles.visibility) styles.fill = styles.border = false;
        else {
            // apply opacity to colors
            if (styles.opacity < 1) {
                styles.fillColor = this.applyOpacity(styles.fillColor, styles.opacity);
                styles.borderColor = this.applyOpacity(styles.borderColor, styles.opacity)
            }
            // format dash pattern
            if (styles.dashedLine && styles.dashPattern) styles.dashPattern = styles.dashPattern.match(/\d+/g);
        }
        return ol.createStyle(styles);
    }

    /**
     *  Create a new polygon from GeoJSON.
     * @param {String} json — The polygon GeoJSON definition.
     * @returns {Boolean} - True on success, false otherwise.
     */
    createFromGeoJSON(json) {
        if (!this.isValidContainer()) return false;

        // clear previous data first
        if (this.isValidFeature()) ol.removeFeature(this.feature, this.layer);
        this.feature = ol.addFeatureFromGeoJSON(this.layer, json, this.getStyle());
        this.addEventListeners();

        return true;
    }

    /**
     * Create a new polygon from coordinates.
     * @param {Array} data — The coordinate array of the polygon geometry.
     * @returns {Boolean} - True on success, false otherwise.
     */
    createFromCoordinates(data) {
        if (!this.isValidContainer()) return false;

        // clear previous data first
        if (this.isValidFeature()) ol.removeFeature(this.feature, this.layer);

        // create a new feature
        const source = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: data.length === 1 ? data : [data],
            },
        }
        this.feature = ol.addFeature(this.layer, source, this.getStyle());
        this.addEventListeners();

        return true;
    }

    /**
     * Register event callbacks for this polygon.
     */
    addEventListeners() {
        if (!this.isValidFeature()) return;

        const events = {
            click: () => this.fireOutput('onClick', 'The polygon has been clicked.'),
            dbclick: () => this.fireOutput('onDbClick', 'The polygon has been double clicked.'),
            move: (over) => {
                if (over && !this.mouseover) this.fireOutput('onMouseEnter', 'Mouse enter is detected on the polygon.');
                if (!over && this.mouseover) this.fireOutput('onMouseLeave', 'Mouse leave is detected on the polygon.');
                this.mouseover = over;
            },
        }
        ol.addListeners(this.feature, events);
    }

    /**
     * Starts drawing the polygon manually.
     */
    startTrace() {
        // do not start another trace without finishing or cancelling the current drawing
        if (this.tracing) return;
        if (!this.isValidMap()) {
            this.fireOutput('onTraceAbort', 'Failed to start drawing polygon: map reference is invalid.');
            console.warn(`Failed to start drawing the polygon '${this.label}' [${this.id}]: the map reference is invalid.`);
            return;
        }

        const stopTrace = () => {
            this.tracing = !ol.removeInteraction('draw');
            if (this.tracing) console.warn(`Failed to remove draw interaction to the polygon '${this.label}' [${this.id}].`);
        }

        const err = ol.addInteraction('draw', {
            layer: this.layer,
            snap: this.get('snap'),
            type: 'Polygon',
            style: this.getStyle(),
            drawstart: () => {
                this.clearData();
                this.fireOutput('onTraceStart', 'Drawing polygon has started.');
            },
            drawabort: (message) => {
                stopTrace();
                this.fireOutput('onTraceAbort', typeof message === 'string' ? message : 'Drawing polygon is aborted.');
            },
            drawend: (feature) => {
                stopTrace();
                this.feature = feature;
                this.addEventListeners();
                this.fireOutput('onTraceEnd', 'Drawing polygon has finished.');
            },
        });

        // output message or an error on fail
        if (err) this.fireOutput('onTraceAbort', `Failed to start drawing polygon: ${err}`);
        else {
            this.fireOutput('startTrace', 'Draw interaction has been fully started.');
            this.tracing = true;
        }
    }

    /**
     * Aborts the interaction on the map to draw the polygon.
     */
    cancelTrace() {
        const done = ol.abortInteraction('draw');
        if (done) {
            this.fireOutput('canvelTracing', 'Draw interaction has been successfully cancelled.');
            this.tracing = !ol.removeInteraction('draw');
        }
    }

    /**
     * Adds an interaction on the map to modify the polygon manually.
     */
    startEditing() {
        if (this.editing) return;
        if (!this.isValidMap()) {
            console.warn(`Failed to modify the polygon '${this.label}' [${this.id}]: the map reference is invalid.`);
            return;
        }

        const err = ol.addInteraction('modify', {
            layer: this.layer,
            feature: this.feature,
            snap: this.get('snap'),
            style: this.getStyle(),
            modifystart: () => this.fireOutput('onEditStart', 'The modification of the polygon has started.'),
            modifyend: (feature) => {
                // compare uid
                if (feature === this.feature) {
                    this.fireOutput('onEditEnd', 'The modification of the polygon has finished.');
                }
            },
        });

        // output message or an error on fail
        if (err) console.warn(`Failed to modify the polygon '${this.label}' [${this.id}]: ${err}`);
        else {
            this.fireOutput('startEditing', 'Modify interaction has been fully started.');
            this.editing = true;
        }
    }

    /**
     * Removes the interaction on the map to modify the polygon manually.
     */
    stopEditing() {
        const done = ol.removeInteraction('modify');
        if (done) {
            this.fireOutput('stopEditing', 'Modify interaction has been successfully stopped.');
            this.editing = false;
        }
    }

    /**
     * Call all listeners attached to the specified output.
     *
     * @param {string} name - The name of the output event to fire.
     * @param {...*} values - The arguments passed to the output listeners.
     */
    fireOutput(name, value) {
        this.eventCallback(name, value);
    }

    /**
     * Gets all current user values registered under a specific 'partial' parameter.
     *
     * @param {string} label - The partial name.
     * @returns {Object} - Returns an object organised by parameter name (as key).
     */
    getPartial(label) {
        return this.state.props[label];
    }

    /**
     * Apply opacity to the given color.
     *
     * @param {string} color - The RGBA color definition.
     * @param {int} opacity - The feature opacity.
     * @returns {string} - RGBA color definition.
     */
    applyOpacity(color, opacity = 1) {
        const rgba = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?([\d|.]+)[\s+]?/i);
        if (rgba && rgba.length === 5) color = `rgba(${rgba[1]}, ${rgba[2]}, ${rgba[3]}, ${parseFloat(rgba[4]) * opacity})`;
        return color;
    }

    /**
     * Returns the correct value for the given select field value. The value can be the index, the
     * label or the value itself. If the given value is not specified this method will return the
     * default value for this field.
     *
     * @param {String} prop - The corresponding field name
     * @param {String|int} - The current value
     */
    getSelectOptionValue(prop, value = null) {
        const param = this.getParameter(prop);
        const index = parseInt(value);

        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(index)) {
            const option = param.options[parseInt(value)];
            return option === undefined ? param.default : option.value;
        }
        if (!value) return param.default;
        const option = param.options.find((opt) => {
            const search = value.toLowerCase();
            return search === opt.label.toLowerCase() || search === opt.value.toLowerCase();
        })
        return option === undefined ? param.default : option.value;
    }
}

export default UiMapOlPolygon;
