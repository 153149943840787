var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion targets-bloc" }, [
    _c(
      "div",
      {
        staticClass: "accordion-title",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.accordion = !_vm.accordion
          }
        }
      },
      [
        _c("span", { staticClass: "text" }, [_vm._v("Targets")]),
        _c("span", { staticClass: "arrow", class: { close: !_vm.accordion } })
      ]
    ),
    _c("div", { staticClass: "form-content", class: { open: _vm.accordion } }, [
      _c("div", { staticClass: "form-accordion isAccordion" }, [
        _vm._m(0),
        _c("div", { staticClass: "form-accordion-content open" }, [
          _c(
            "div",
            { staticClass: "big-bt field-group" },
            [
              _vm._l(_vm.availableTargets.value.targets, function(
                clientTarget
              ) {
                return _c("div", { staticClass: "field-ctn target-line" }, [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(clientTarget.title))
                  ]),
                  _c("div", { staticClass: "param-ctn" }, [
                    _c(
                      "span",
                      {
                        staticClass: "checkbox plug",
                        class: {
                          selected:
                            _vm.getIndexOfClient(clientTarget.name) != -1
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.toggleTarget(clientTarget)
                          }
                        }
                      },
                      [_c("input", { attrs: { type: "checkbox" } })]
                    ),
                    _c("div", { staticClass: "clear" })
                  ]),
                  _c("div", { staticClass: "clear" })
                ])
              }),
              _c("div", { staticClass: "clear" })
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title tab-title" }, [
      _c("span", { staticClass: "col col1" }, [_vm._v("Type")]),
      _c("span", { staticClass: "col col2" }),
      _c("div", { staticClass: "clear" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }