import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class UpdateUiFields extends ActionAbstract {

     /**
     * @param parentUi : ui parent, object
     * @param newOrderChilren : reordered list of the ui chidldren, array
     *
    **/

    static get ID() {
        return 'actions:editor:ui:UpdateUiFields';
    }

    run(state) {

        const parentUi = this.getParam('parentUi');
        const newOrderChilren = this.getParam('newOrderChilren');
        
        let node = node
        let path;
        [node, path] = helper.block.getBlockById( state.project, parentUi.value._id, true);

        let newOrderChildrenFields = [];
        let newOrderFieldValue = [];
        newOrderChilren.forEach(child => {
            let childId = child.value._id;
            newOrderChildrenFields.push(childId)
            newOrderFieldValue.push(childId)
        })


        let parentNode;
        let parrentPath
        [parentNode, parrentPath ] = this.getChildrenById(state.editor.uiState, parentUi.value._id)

        let oldOrder;
        oldOrder = parentNode.children
        let sortResult = [];

        sortResult = oldOrder.map(function(item) {
            var n = newOrderChildrenFields.indexOf(item.id);
            newOrderChildrenFields[n] = '';
            return [n, item]
        }).sort().map(function(j) { return j[1] })

        let compositeState = {
            project: {},
            editor: {
                uiState: {}
            }
        };

        let childrenFieldIndex;
        let parentFields = node.value.fields;

        parentFields.forEach((field, i) => {
            if(field["name"] === 'children'){
                childrenFieldIndex = i
            }
        })

        compositeState.project[path + '.value.fields[' + childrenFieldIndex + '].value'] = newOrderFieldValue
        compositeState.editor.uiState[parrentPath+'.children'] = sortResult

        return this.composeState( state, compositeState);
    }

    getChildrenById(node, id) {

        let returnValue = [
            null,           // searched object's parent
            ''              // path
        ];

        if (!returnValue[0]) {
            if( node.id && node.id == id ) {
                returnValue[0] = node;
            } else {
                // go through block children
                if( node.children && node.children.length > 0 ){
                    for( let i = 0; i < node.children.length; i++ ){
                        let subnode = node.children[i];
                        returnValue = this.getChildrenById( subnode , id, returnValue[1] );
                        if( returnValue[0] ) {
                            returnValue[1] =  "children[" + i + "]" + (returnValue[1] === '' ? '' :  "." + returnValue[1]);
                            break;
                        }
                    }
                }
            }
        }
       return returnValue;
    }
}

export default UpdateUiFields;