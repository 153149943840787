var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.projectInfo !== undefined
    ? _c("div", { attrs: { id: "sharingTab" } }, [
        _c(
          "div",
          { staticClass: "input shareUserInput" },
          [
            _c("dropdown", {
              attrs: { items: _vm.UserType, defaultItem: _vm.UserType[1] },
              on: { click: _vm.activeType, "update-type": _vm.changeType }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.usermail,
                  expression: "usermail"
                }
              ],
              staticClass: "value inputModal",
              attrs: { placeholder: "Enter a user identifier", type: "text" },
              domProps: { value: _vm.usermail },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.usermail = $event.target.value
                  },
                  _vm.trimMail
                ],
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.addUser($event)
                }
              }
            }),
            _c(
              "div",
              {
                staticClass: "addsharedUser",
                attrs: {
                  disabled: !(_vm.usermail && _vm.usermail.trim()),
                  value: "Add",
                  type: "button"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.addUser($event)
                  }
                }
              },
              [_vm._v("Add")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "editors sharing" }, [
          _vm.projectInfo.owner
            ? _c("div", { staticClass: "inlinelist" }, [
                _c("span", { staticClass: "icon icon_owner" }),
                _c("span", { staticClass: "shareGroupName" }, [
                  _vm._v("Owner")
                ]),
                _c("span", { staticClass: "user" }, [
                  _vm._v(
                    _vm._s(
                      _vm.projectInfo.owner.replace("org.couchdb.user:", "")
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.projectInfo.editors
            ? _c(
                "div",
                { staticClass: "inlinelist" },
                [
                  _c("span", { staticClass: "icon icon_author" }),
                  _c("span", { staticClass: "shareGroupName" }, [
                    _vm._v("Editor")
                  ]),
                  _vm._l(_vm.projectInfo.editors, function(editor) {
                    return _c(
                      "span",
                      {
                        staticClass: "user",
                        on: {
                          "~click": function($event) {
                            return _vm.removeUser(editor, true)
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(editor)),
                        _c("icon", { attrs: { name: _vm.cross } })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.projectInfo.viewers
            ? _c(
                "div",
                { staticClass: "inlinelist" },
                [
                  _c("span", { staticClass: "icon icon_reader" }),
                  _c("span", { staticClass: "shareGroupName" }, [
                    _vm._v("Viewer")
                  ]),
                  _vm._l(_vm.projectInfo.viewers, function(viewer) {
                    return _c(
                      "span",
                      {
                        staticClass: "user",
                        on: {
                          click: function($event) {
                            return _vm.removeUser(viewer, false)
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(viewer)),
                        _c("icon", { attrs: { name: _vm.cross } })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.sharingError
            ? _c("p", { staticClass: "error" }, [
                _vm._v(_vm._s(_vm.sharingError))
              ])
            : _vm._e(),
          _vm.responseMessage
            ? _c("p", { staticClass: "error" }, [
                _vm._v(_vm._s(_vm.responseMessage))
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }