var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "link-menu-ctn" } }, [
    _c("div", {
      staticClass: "cover",
      class: { open: _vm.open },
      on: {
        click: function($event) {
          return _vm.closeMenu()
        }
      }
    }),
    _vm.open != false
      ? _c(
          "div",
          {
            staticClass: "link-menu-positioner",
            style: {
              transform:
                "translate(" +
                _vm.appState.editor.linkmenu.pos.x +
                "px , " +
                _vm.appState.editor.linkmenu.pos.y +
                "px )"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "link-menu",
                class: [
                  { open: _vm.open },
                  { freeze: _vm.freeze },
                  { error: _vm.open == "error" }
                ]
              },
              [
                _vm._m(0),
                _c("div", { staticClass: "group" }, [
                  _vm._m(1),
                  _c("ul", { staticClass: "list" }, [
                    _vm.activation.isActivable && !_vm.activation.forceActivable
                      ? _c(
                          "li",
                          {
                            class: { active: _vm.isActivated },
                            on: {
                              click: function($event) {
                                return _vm.toggleActivation()
                              }
                            }
                          },
                          [_vm._v("Activation link")]
                        )
                      : _vm._e(),
                    _vm.activation.forceActivable
                      ? _c("li", { staticClass: "auto" }, [
                          _vm._v("Activation link (auto)")
                        ])
                      : _vm._e(),
                    _c("li", { on: { click: _vm.deleteLink } }, [
                      _vm._v("Delete link")
                    ])
                  ]),
                  _vm._m(2),
                  _c("ul", { staticClass: "list" }, [
                    _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.priority,
                            expression: "priority"
                          }
                        ],
                        attrs: { placeholder: "Priority" },
                        domProps: { value: _vm.priority },
                        on: {
                          change: _vm.updatePriority,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.priority = $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm.appState.editor.linkmenu.conversions.length > 0
                  ? _c("div", { staticClass: "group" }, [
                      _vm._m(3),
                      _c(
                        "ul",
                        { staticClass: "list" },
                        _vm._l(
                          _vm.appState.editor.linkmenu.conversions,
                          function(conversion) {
                            return _c(
                              "li",
                              {
                                class: [
                                  { active: _vm.currentConv == conversion },
                                  {
                                    auto:
                                      _vm.appState.editor.linkmenu.conversions
                                        .length == 1
                                  }
                                ],
                                on: {
                                  click: function($event) {
                                    return _vm.updateCast(conversion)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(conversion))]
                            )
                          }
                        ),
                        0
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c(
              "svg",
              {
                staticClass: "error-bt",
                class: [{ open: _vm.open == "error" }]
              },
              [
                _c(
                  "g",
                  {
                    staticClass: "icon",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.deleteLink(_vm.link)
                      }
                    }
                  },
                  [
                    _c("circle", {
                      attrs: {
                        cx: "10",
                        cy: "10",
                        r: "10",
                        fill: "#58585B",
                        "stroke-width": "0"
                      }
                    }),
                    _c("path", {
                      staticClass: "icon-x",
                      attrs: { d: "M 7,7 L 13,13 M 13,7 L 7,13" }
                    })
                  ]
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("Link options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _c("span", [_vm._v("Action")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _c("span", [_vm._v("Priority")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _c("span", [_vm._v("Conversions")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }