import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class SelectXP extends ActionAbstract {

    /**
     * @param id : id of block
     **/
    static get ID() {
        return 'actions:editor:selectXP';
    }

    run(state) {
        return this.composeState(state, {
            editor: {
                targetXP: this.getParam('id'),
            }
        });
    }

}

export default SelectXP;