var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "content" } }, [
      _c("h1", [_vm._v("Nodal.Studio")]),
      _c("p", { attrs: { id: "chapo" } }, [
        _vm._v(
          "Nodal.Studio is a visual programming environment developed by Orbe. "
        ),
        _c("br"),
        _vm._v(
          " It was designed to create interactive multimedia works with audio and visual generation. "
        ),
        _c("br"),
        _vm._v(
          ' The main environment is for creating project is called "Authoring".'
        )
      ]),
      _c("p", [
        _vm._v(
          "The authoring is dedicated to create the projects, which are organized with specific elements called "
        ),
        _c("strong", [_vm._v("Nodes")]),
        _vm._v(":"),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _c("ul", { attrs: { id: "hierarchy" } }, [
          _c("li", [
            _c("strong", [_vm._v("Project : ")]),
            _vm._v("The application workspace.")
          ]),
          _c("li", [
            _c("strong", [_vm._v("Experience : ")]),
            _vm._v(
              "The sub-application(s) of the project, with specific targets."
            )
          ]),
          _c("li", [
            _c("strong", [_vm._v("Screen : ")]),
            _vm._v("The visual states of the experience.")
          ]),
          _c("li", [
            _c("strong", [_vm._v("Process : ")]),
            _vm._v(
              "Logical elements used to enhance the experience(s) and the screen(s)."
            )
          ])
        ])
      ]),
      _c("p", [
        _vm._v("These nodes are organized in a specific hierarchy: "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _c("ul", { attrs: { id: "hierarchy" } }, [
          _c("li", [_vm._v("The Project is the root level.")]),
          _c("li", [
            _vm._v("Each Project contains one or several Experience(s).")
          ]),
          _c("li", [
            _vm._v(
              "Each Experience contains one or several Screen(s) and a Logic level."
            )
          ]),
          _c("li", [
            _vm._v(
              "Each Screen contains one HTML View to edit the visual elements and a Logic level."
            )
          ]),
          _c("li", [
            _vm._v(
              "Each Logic contains one or several Process(es) and Trigger(s)."
            )
          ])
        ])
      ]),
      _c("img", {
        staticClass: "illustration",
        attrs: { src: require("../../assets/images/doc/env/level.jpg") }
      }),
      _c("h2", [_vm._v("Main actions menu")]),
      _c("img", {
        staticClass: "illustration",
        attrs: { src: require("../../assets/images/doc/env/top.png") }
      }),
      _c("p", [
        _vm._v(
          "The main actions menu gives access to options available at any moment during the designing in Nodal.Studio :"
        ),
        _c("ul", { attrs: { id: "hierarchy" } }, [
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/images/doc/env/home.png"),
                height: "20px",
                width: "20px"
              }
            }),
            _c("span", [
              _vm._v("The home button to get back to all the projects.")
            ])
          ]),
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/images/doc/env/save.png"),
                height: "20px",
                width: "20px"
              }
            }),
            _c("span", [
              _vm._v(
                "The save button to save all the changes made on the project."
              )
            ])
          ]),
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/images/doc/env/lib.png"),
                height: "20px",
                width: "20px"
              }
            }),
            _c("span", [
              _vm._v(
                "The library button to access to all the media added to the project."
              )
            ])
          ]),
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/images/doc/env/play.png"),
                height: "20px",
                width: "20px"
              }
            }),
            _c("span", [
              _vm._v(
                "The play button to preview the experience or a specific screen."
              )
            ])
          ])
        ])
      ]),
      _c("h2", [_vm._v("Hierarchy")]),
      _c("img", {
        staticClass: "illustration",
        attrs: { src: require("../../assets/images/doc/env/hierarchy.png") }
      }),
      _c("p", [
        _vm._v(
          "The hierarchy is the place where the entire list of components of the project is displayed."
        ),
        _c("br"),
        _vm._v(
          " It contains all the levels of the project and the different elements that has been added to it."
        ),
        _c("br"),
        _vm._v(
          " When an element is clicked, the schematic interface will automatically jump to its level to display the associated content."
        )
      ]),
      _c("p", [
        _vm._v(
          "When positioning the cursor over an element in the hierarchy, contextual actions are available :"
        ),
        _c("ul", { attrs: { id: "hierarchy" } }, [
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/images/doc/env/delete.png"),
                height: "15px",
                width: "15px"
              }
            }),
            _c("span", [_vm._v("Deleting the element")])
          ]),
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/images/doc/env/inspect.png"),
                height: "15px",
                width: "15px"
              }
            }),
            _c("span", [_vm._v("Opening the inspector of the element")])
          ]),
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/images/doc/env/view.png"),
                height: "15px",
                width: "15px"
              }
            }),
            _c("span", [_vm._v("Viewing the screen on a new window")])
          ])
        ])
      ]),
      _c("h2", [_vm._v("Schematic interface")]),
      _c("img", {
        staticClass: "illustration",
        attrs: { src: require("../../assets/images/doc/env/schematic.png") }
      }),
      _c("p", [
        _vm._v(
          "The schematic interface is the place where are the modules are connected and arranged in order to design the project. Each  modules have different inputs and outputs to be connected one to another in order to pass a value, transmit a state, change a color, etc. These inputs and outputs can be set or changed in the inspector (view below)."
        )
      ]),
      _c("p", [
        _vm._v("At every level of the authoring, a "),
        _c(
          "span",
          {
            staticStyle: {
              background: "#58595b",
              color: "#ffffff",
              display: "inline-block",
              width: "20px",
              height: "20px",
              "border-radius": "20px",
              "font-weight": "bold",
              "text-align": "center",
              "line-height": "20px"
            }
          },
          [_vm._v("+")]
        ),
        _vm._v(
          " button is available at the left bottom corner of the schematic interface. It opens a menu that gives access to all the modules that can be added at this specific level :"
        ),
        _c("ul", { attrs: { id: "hierarchy" } }, [
          _c("li", [
            _c("strong", [_vm._v("Project :")]),
            _c("span", [_vm._v("Types of targets that compose the project.")])
          ]),
          _c("li", [
            _c("strong", [_vm._v("Experience :")]),
            _c("span", [
              _vm._v("Types of screens that compose the experience.")
            ])
          ]),
          _c("li", [
            _c("strong", [_vm._v("Screen :")]),
            _c("span", [_vm._v("Types of processing modules.")])
          ]),
          _c("li", [
            _c("strong", [_vm._v("Screen Editor :")]),
            _c("span", [
              _vm._v("Graphical modules to be displayed on the screen.")
            ])
          ]),
          _c("li", [
            _c("strong", [_vm._v("Process :")]),
            _c("span", [
              _vm._v("Logical modules providing interactions to the project")
            ])
          ])
        ])
      ]),
      _c("h2", [_vm._v("Inspector")]),
      _c("img", {
        staticClass: "illustration",
        attrs: { src: require("../../assets/images/doc/env/inspector.png") }
      }),
      _c("p", [
        _vm._v(
          "The inspector is displayed every time an element is selected in the hierarchy or in the schematic interface. It gives all the informations and parameters available for the selected element."
        )
      ]),
      _c("p", [
        _vm._v(
          "From this inspector, an module can be renamed and different parameters can be set. But the main feature of the inspector is to give access to the the inputs and outputs of a module : each one can be displayed or hidden to connected the modules."
        )
      ]),
      _c("p", [
        _vm._v(
          "At the bottom of the inspector, a save button allow to save the changes made on a module."
        )
      ]),
      _c("h2", [_vm._v("Screen editor")]),
      _c("img", {
        staticClass: "illustration",
        attrs: { src: require("../../assets/images/doc/env/editor.png") }
      }),
      _c("p", [
        _vm._v(
          "The screen editor is the main interface to design all the visual element of the screen. It is available for any screen when its HTML View is selected in the hierarchy."
        )
      ]),
      _c("p", [
        _vm._v(
          "The different modules (Paragraphs, Buttons, Boxes, Images, etc.) are available directly via the "
        ),
        _c(
          "span",
          {
            staticStyle: {
              background: "#58595b",
              color: "#ffffff",
              display: "inline-block",
              width: "20px",
              height: "20px",
              "border-radius": "20px",
              "font-weight": "bold",
              "text-align": "center",
              "line-height": "20px"
            }
          },
          [_vm._v("+")]
        ),
        _vm._v(
          " button by a simple clic on the selected element, or by dragging the element directly on the screen preview, on one of its container."
        )
      ]),
      _c("h2", [_vm._v("Library")]),
      _c("img", {
        staticClass: "illustration",
        attrs: { src: require("../../assets/images/doc/env/library.png") }
      }),
      _c("p", [
        _vm._v("The library (accessible from the icon "),
        _vm._v(
          ' "height=20px" width="20px" /> in the Main action menu) is the place where all the media used in the project are stored. There are different types of files that are supported :'
        ),
        _c("ul", { attrs: { id: "hierarchy" } }, [
          _c("li", [_vm._v("Images (JPG, PNG, GIF)")]),
          _c("li", [_vm._v("Sounds (MP3, WAV)")]),
          _c("li", [_vm._v("Videos (MP4, WEBM, OGG)")]),
          _c("li", [_vm._v("Files (Imported JSON)")])
        ])
      ]),
      _c("p", [
        _vm._v(
          "It is a place to find all the media easily after they have been added."
        ),
        _c("br"),
        _c("br")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }