import ChartsUiObject from '../ChartsUiObject';

class UiStreamGraphObject extends ChartsUiObject {
    constructor(id, props) {
        super(id, props, 'StreamGraph');
        this.streamData = [];
        this.streamColors = [];
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-stream-graph",
            label: "Stream Graph",
            parent : "ui-charts",
            isDisplayed : true,
            isEmbeddable: true
        };
    }
    

    initParameters(){
        super.initParameters();

        let dataGroup = this.createInspectorNode('group', 'data-group', 'Data' );
        dataGroup.children.push( this.createInspectorNode('group-field', 'data-fields') );

        let chartTopic = this.createInspectorNode( 'topic', 'chartSetting', 'Chart settings' );
        chartTopic.children = [ dataGroup ];

        this.inspector.unshift( chartTopic );
        
        let otherParameters = {

            // DATA
            addValues: {
                type: 'ArrayType', 
                default: null, 
                partial: 'stream',
                method: 'addValues',
                connection: { in: {pluggable: true, default: true}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Add values",
                    container: "data-fields",
                    widget: "calculated",
                    description: "values to inject into the chart's colors"
                }
            },
            clearValues: {
                type: 'Mixed', 
                default: null, 
                partial: 'stream',
                method: 'clearValues',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Clear values",
                    container: "data-fields",
                    widget: "calculated",
                    description: "used to trigger a cleaning of the chart's values"
                }
            },
            colors: {
                type: 'ArrayType', 
                default: null, 
                partial: 'stream',
                method: 'newColorSet',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Colors",
                    container: "data-fields",
                    widget: "array",
                    pattern: [
                        {name:'color',type:'Color', widget:'color'}
                    ],
                    description: "the different colors that compose the chart"
                }
            },
            yCentered: {
                type: 'Boolean', 
                default: true, 
                partial: 'stream',
                connection: { in: {pluggable: false, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Y Centered",
                    container: "data-fields",
                    description: "specifies whether the chart's values are displayed from center or from bottom along the Y axis"
                }
            },
            domain: {
                type: 'Vector2', 
                default: null, 
                partial: 'stream',
                connection: { in: {pluggable: false, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Domain",
                    container: "data-fields",
                    description: "the domain (or bounds) in which the chart's values are expected to be"
                }
            },
            interpolation: {
                type: 'String', 
                default: "basis", 
                partial: 'stream',
                connection: { in: {pluggable: false, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Interpolation",
                    container: "data-fields",
                    widget: "select",
                    options: [  {value:"basis", label:"Basis"},
                                {value:"bundle", label:"Bundle"},
                                {value:"cardinal", label:"Cardinal"},
                                {value:"catmullRom", label:"CatmullRom"},
                                {value:"linear", label:"Linear"},
                                {value:"monotoneX", label:"MonotoneX"},
                                {value:"monotoneY”", label:"MonotoneY”"},
                                {value:"natural", label:"Natural"},
                                {value:"step", label:"Step"},
                                {value:"stepAfter", label:"Step After"},
                                {value:"stepBefore", label:"Step Before"} ],
                    description: "the kind of interpolation to apply to the chart's values"
                }
            },

        };

        this.addToParameters( otherParameters );

        this.setParamInvisible( 'addChild' );
        this.setParamInvisible( 'removeChild' );

        this.setDefaultValue( 'width', '300px' );
        this.setDefaultValue( 'height', '200px' );

        this.setParamInvisible( 'backgroundColor' );
        this.setParamInvisible( 'backgroundImage' );
        this.setParamInvisible( 'backgroundRepeat' );
        this.setParamInvisible( 'backgroundSize' );
        this.setParamInvisible( 'backgroundWidth' );
        this.setParamInvisible( 'backgroundHeight' );
        this.setParamInvisible( 'backgroundPositionX' );
        this.setParamInvisible( 'backgroundPositionY' );
        this.setParamInvisible( 'fontFamily' );

        this.setParamInvisible( 'domainX' );
        this.setParamInvisible( 'domainY' );
        this.setParamInvisible( 'domainPaddingX' );
        this.setParamInvisible( 'domainPaddingY' );


    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }

    init(){
        super.init();

        this.set( 'streamData', [] );

    }


    addValues( values ){
        if(!values || !values.addValues ) return;

        //this.streamData.push( values.addValues[0] );
        
        let middle = 0;
        let dx = values.addValues[0];
        if( this._state.props.stream.yCentered === true ){
            dx.forEach( ( dy , y ) => { middle += dy.value; });
            middle = middle * 0.5;
        }

        dx.forEach( ( dy , curveId ) => {
            if( !this.streamData[curveId] ) this.streamData[curveId] = []; // [{x:0, y:0, y0: 0}];

            let x = this.streamData[curveId].length;

            let y0 = 0;
            if( curveId > 0 ){
                y0 =  this.streamData[curveId-1][x].y ; // dx[y-1].value;
            }
            if( curveId == 0 )
                y0 -= middle;

            this.streamData[curveId].push( { x : x , y : y0+dy.value , y0 : y0 } );
            
        });


        this.set( 'streamData', this.streamData.filter( (a) => { return true; } ) );

    
    }

    clearValues( values ){
        if( values === null) return;

        this.streamData = [];
        this.set( 'streamData', this.streamData.filter( (a) => { return true; } ) );

    }

    newColorSet( values ){


        this.streamColors = [];

        if( values.colors && values.colors.length > 0 ){
            values.colors.forEach( ( c ) => {
                if( c && Array.isArray(c) && c[0] ){
                    let color = this.castColor( c[0].value );
                    this.streamColors.push( color );
                }
            });
        }   
        this.set( 'streamColors', this.streamColors.filter( (a) => { return true; } ) );
    }

}

export default UiStreamGraphObject;