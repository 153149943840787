var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "object-fields-ctn geo-field-ctn" }, [
    _c("div", { staticClass: "field-wrapper" }, [
      _vm.dynlabel
        ? _c("span", { staticClass: "text geo" }, [
            _vm._v(_vm._s(_vm.dynlabel)),
            _vm.field.description
              ? _c(
                  "span",
                  {
                    staticClass: "description-bull",
                    on: { click: _vm.displayDescription }
                  },
                  [_vm._v("?")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c("span", {
        staticClass: "map-modal-icon",
        class: { disabled: _vm.field.disabled },
        on: { click: _vm.openMapModal }
      }),
      _vm.geomodalState &&
      _vm.geomodalState.open &&
      this.fieldId === _vm.geomodalState.modalId
        ? _c(
            "div",
            { staticClass: "geomodal" },
            [
              _c("span", {
                staticClass: "close-geo-modal",
                on: { click: _vm.openMapModal }
              }),
              _c("geomodal", {
                attrs: {
                  fieldvalue: _vm.fieldvalue,
                  field: _vm.field,
                  valueFromField: this.value
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "input-fields groupfield" }, [
        _c("span", { staticClass: "textinput-ctn geo" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value.lat,
                expression: "value.lat"
              }
            ],
            staticClass: "value geo lat",
            attrs: { type: "text", disabled: _vm.field.disabled },
            domProps: { value: _vm.value.lat },
            on: {
              focusout: function($event) {
                return _vm.updateLatitude()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.value, "lat", $event.target.value)
              }
            }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value.lng,
                expression: "value.lng"
              }
            ],
            staticClass: "value geo lng",
            attrs: { type: "text", disabled: _vm.field.disabled },
            domProps: { value: _vm.value.lng },
            on: {
              focusout: function($event) {
                return _vm.updateLongitude()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.value, "lng", $event.target.value)
              }
            }
          })
        ]),
        _vm.field.type == "field"
          ? _c("span", { staticClass: "coord" }, [
              _c(
                "span",
                {
                  staticClass: "plug checkbox",
                  class: {
                    disabled:
                      !_vm.field.connection ||
                      !_vm.field.connection.in.pluggable ||
                      _vm.field.connection.in.disabled,
                    selected: _vm.connectionTarget.in
                  },
                  on: {
                    click: function($event) {
                      return _vm.connectionChangeHandler(_vm.field, "in")
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.connectionTarget.in,
                        expression: "connectionTarget.in"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.connectionTarget.in)
                        ? _vm._i(_vm.connectionTarget.in, null) > -1
                        : _vm.connectionTarget.in
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.connectionTarget.in,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.connectionTarget,
                                "in",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.connectionTarget,
                                "in",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.connectionTarget, "in", $$c)
                        }
                      }
                    }
                  })
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "plug checkbox",
                  class: {
                    disabled:
                      !_vm.field.connection ||
                      !_vm.field.connection.out.pluggable ||
                      _vm.field.connection.out.disabled,
                    selected: _vm.connectionTarget.out
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.connectionChangeHandler(_vm.field, "out")
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.connectionTarget.out,
                        expression: "connectionTarget.out"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.connectionTarget.out)
                        ? _vm._i(_vm.connectionTarget.out, null) > -1
                        : _vm.connectionTarget.out
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.connectionTarget.out,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.connectionTarget,
                                "out",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.connectionTarget,
                                "out",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.connectionTarget, "out", $$c)
                        }
                      }
                    }
                  })
                ]
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "clear" })
      ]),
      _c("div", { staticClass: "clear" })
    ]),
    _c("div", { staticClass: "clear" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }