import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class UpdateBreadcrumb extends ActionAbstract {

    /**
     * @param currentId : id of screen block
     * 
    **/

    static get ID() {
        return 'actions:main:updateBreadcrumb';
    }

    run(state) {
        let breadcrumb = {
            arborescence : []
        };

        let current = helper.block.getBlockById( state.project, this.getParam('currentId') );
        
       /* if( data.openFromHistory !== true )
            this.addHistory( subject ); */
 
        if( current != null ){

            if( current.value._id != state.project.value._id )	
                breadcrumb.arborescence.push( helper.block.getBlockById( state.project , current.value._id ) );

            let currentNode = current;
            
            let currentParentID = null;

            if( currentNode.value.type == 'object' && currentNode.value.ref_block  )
                currentParentID = currentNode.value.ref_block; // block associated
            else
                currentParentID = currentNode.value['ref_'+currentNode.value.level];

            while( currentParentID && currentParentID != state.project.value._id ){
                let parent = helper.block.getBlockById( state.project , currentParentID );

                breadcrumb.arborescence.push( parent );

                currentParentID = parent.value['ref_'+parent.value.level];

            }

            breadcrumb.arborescence.reverse();
        }

        return this.composeState( state, {
            breadcrumb : breadcrumb
        });
    }

}

export default UpdateBreadcrumb;