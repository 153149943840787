var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "content" } }, [
      _c("h1", [_vm._v("Examples")]),
      _c("section", { staticClass: "module-theme" }, [
        _c("h1", [_vm._v("Audio")]),
        _c("ul", { staticClass: "nodes" }, [
          _c("li", [_c("span", [_vm._v("Toggle a sound player")])]),
          _c("li", [
            _c("span", [_vm._v("Launch a sound with a GPS position")])
          ]),
          _c("li", [
            _c("span", [_vm._v("Oscillator with device orientation")])
          ]),
          _c("li", [
            _c("span", [_vm._v("Scrub player with device orientation")])
          ]),
          _c("li", [_c("span", [_vm._v("Play a sound according to compass")])]),
          _c("li", [_c("span", [_vm._v("FFT with a sound player")])])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }