<!-- ObjectField vue -->

<template lang="pug">

div.object-fields-ctn.geo-field-ctn

	div.field-wrapper
		span.text.geo(v-if="dynlabel") {{dynlabel}}
			span.description-bull(v-if="field.description", v-on:click="displayDescription") ?
		span.map-modal-icon(v-on:click="openMapModal", v-bind:class="{'disabled': field.disabled}")
		div.geomodal(v-if="geomodalState && geomodalState.open && (this.fieldId === geomodalState.modalId)")
			span.close-geo-modal(v-on:click="openMapModal")
			geomodal(:fieldvalue="fieldvalue", :field="field", :valueFromField="this.value")

		div.input-fields.groupfield
			span.textinput-ctn.geo
				input.value.geo.lat(type="text", v-model="value.lat", v-on:focusout="updateLatitude()", :disabled="field.disabled")
				input.value.geo.lng(type="text", v-model="value.lng", v-on:focusout="updateLongitude()", :disabled="field.disabled")

			span.coord(v-if="field.type=='field'")

				// INPUT
				span.plug.checkbox(v-bind:class="{'disabled':!field.connection || !field.connection.in.pluggable || field.connection.in.disabled,'selected':connectionTarget.in }", v-on:click="connectionChangeHandler(field, 'in')")
					input(type="checkbox", v-model="connectionTarget.in")

				// OUTPUT
				span.plug.checkbox(v-bind:class="{'disabled':!field.connection || !field.connection.out.pluggable || field.connection.out.disabled,'selected':connectionTarget.out}", v-on:click.stop="connectionChangeHandler(field, 'out')")
					input(type="checkbox", v-model="connectionTarget.out")

			div.clear
		div.clear
	div.clear

</template>

<script>
import uuid from 'uuid/v4';
import { stores } from '../../../stores/MainStore.js';
import * as helper from '../../../helper';
import mapboxgl from 'mapbox-gl';
import actionManager from '../../../actions';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import GeoModal from '../../common/GeoModal.vue';
import Modal from '../../common/Modal.vue';

export default {

	components: { geomodal: GeoModal, Modal },

	props:[ 'field' , 'fieldvalue' , 'bloc', 'dynlabel', 'connectionTarget', 'connectionChangeHandler', 'geomodalState' ],

	data() {
		return {
			fieldId: null,
		}
	},
	mounted: function(){
		this.fieldId = uuid();
	},
	computed: {
		'value': function(){
			if (!this.fieldvalue.value) this.fieldvalue.value = {lat: 0, lng: 0};
			return this.fieldvalue.value;
		}
	},

	methods:{

		addLayer: function(){
			this.map.addSource('marker', {type: 'geojson', data: this.geoJSON });
			this.map.addLayer({
				id : "marker-layer",
				source : "marker",
				type: "symbol",
				layout: {
					"icon-image": 'marker-15',
					"icon-size" : 1
				},
				paint: {
					"icon-color" : '#555555'
				}
			});
		},

		valueUpdated: function( pos ){
			this.value.lat = pos.lat;
			this.value.lng = pos.lng;
			this.$parent.valueUpdated( this.field, this.value );
		},

		checkNumberValue: function(val){
			let floatVal = parseFloat(val);
			if( val === "" || floatVal === NaN || floatVal === 'NaN' || val === null )
				val = null;
			else
				val = floatVal;
			return val;
		},

		updateLatitude: function(){
			this.value.lat = this.checkNumberValue( this.value.lat );
			if (this.fieldvalue.value.lat !== this.value.lat )
				this.valueUpdated( { lat : this.value.lat , lng : this.value.lng } );
		},

		updateLongitude: function(){
			this.value.lng = this.checkNumberValue( this.value.lng );
			if (this.fieldvalue.lng !== this.value.lng)
				this.valueUpdated( { lat : this.value.lat , lng : this.value.lng } );
		},

		displayDescription: function(){
			actionManager.trigger('main:modal:openModal', {title:this.dynlabel, text:this.field.description, continueButton: 'OK', cancelButton: '', icon: 'exclamation' })
		},

		openMapModal: function() {
			if(!this.geomodalState.open){
				//- pour ouvrir la modal que si modalId est null, aucune modale n'existe.
				actionManager.trigger('main:geomodal:open', {fieldvalue: this.fieldvalue, fieldId: this.fieldId})
			} else if(this.geomodalState.open && this.fieldId !== this.geomodalState.modalId){
				//- pour fermer puis ouvrir la bonne modale
				actionManager.trigger('main:geomodal:close', {open: false, fieldvalue: ""})
				actionManager.trigger('main:geomodal:open', {fieldvalue: this.fieldvalue, fieldId: this.fieldId })
			} else {
				if(this.fieldId === this.geomodalState.modalId){
					//- pour fermer uniquement la bonne modal
					actionManager.trigger('main:geomodal:close', {open: false, fieldvalue: ""})
				}
			}
		}

	}

}

</script>

<style lang="stylus">

.geomodal
	width: 550px
	height: 360px
	position: absolute
	margin-left: -449px
	margin-top: -176px
	z-index: 1
	padding-right: 4px
	background-image: url('../../../assets/images/triangle-blanc.png')
	background-repeat: no-repeat
	background-size: 20px 20px
	background-position: right 0px bottom 152px

	span.close-geo-modal
		width: 25px
		height: 25px
		border-radius: 15px
		background-color: white
		box-shadow: 0 0 10px var(--shadowColor)
		z-index: 2
		background-repeat: no-repeat
		background-position: center
		background-image: url('../../../'assets/images/deleteIcon.png)
		background-size: 13px
		position: absolute
		right: 5px
		top: -8px
		&:hover
			cursor: pointer

.object-fields-ctn
	padding-top: 5px
	margin-top: 0px
	padding-bottom: 5px

	.field-wrapper
		position : relative
		span.text
			&.geo
				width: 98px

		.input-fields.groupfield
			line-height : 25px

	.map-modal-icon
			height: 24px
			width: 24px
			background-image: url('../../../assets/images/geo-target.png')
			background-size: contain
			float: left
			position: absolute
			right: 245px
			top: 8px
			&:hover
				cursor: pointer
			&.disabled
				pointer-events: none

	.map-modal
			position: absolue
			width: 30px
			heigth: 30px
			z-index: 11

	.noresult
		font-size: 14px
		line-height: 40px

.map
	width: 100%
	height: 100%
	&.mapboxgl-map
		border-radius: 4px

input.value.geo
	width: 30% !important
	&.lat
		padding: 0
		margin: 0 5px 0 0
		width: 72px!important
		padding: 0 5px
		border-radius: 4px
		outline: none!important
	&.lng
		width: 67px!important
		padding: 0 5px
		margin: 0
		border-radius: 4px
		outline: none!important

.coord .plug.checkbox
	margin-top: 0px!important


.object-fields-ctn.geo-field-ctn
	padding-top: 0px !important
	margin-top: 0px !important
	padding-bottom: 0px !important
	line-height: 40px
	
	.textinput-ctn
		margin-top: 5px

.object-fields-ctn.geo-field-ctn
	.checkbox
		margin-top: 5px!important

@keyframes spin
	from { transform: rotate(0deg) }
	to   { transform: rotate(360deg) }

</style>
