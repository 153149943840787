<!-- ObjectField vue -->

<template lang="pug">

div.object-fields-ctn

	div.uiSelector

		div.field-ctn
			span.text Object Owner
			div.input-fields.groupfield
				span.object.textinput-ctn
					span.select-arrow &gt;
					select(v-on:change="getObjectsOfSelectedParent()", v-model="currentParent",  :disabled="field.parentDisabled")
						option(v-for="(option,i) of parentList", v-bind:value="option.value._id") {{option.value.name}}
			div.clear
		div.clear


		div.field-ctn
			span.text Object
			div.input-fields.groupfield(v-if="objectList.length > 0")
				span.object.textinput-ctn
					span.select-arrow &gt;
					select(v-on:change="valueUpdated(field)", v-model="value")
						option(v-for="(option,i) of objectList", v-bind:value="option.value") {{option.title}}
			div.input-fields.groupfield(v-else)
				span.noresult no object
			div.clear
		div.clear

		//div.field-ctn(style="margin-top:10px")
			div.edit-bt(v-on:click.stop="openObject(fieldvalue.value)")
				span.label Edit Object

</template>

<script>

import { stores } from '../../../stores/MainStore.js';
import * as helper from '../../../helper';

export default {

	components: { },

	props:[ 'field' , 'fieldvalue' , 'bloc' ],

	data() {
		return {
			currentParent : null,
			objectList: [],
			parentList: [],
			newValue : null,
			// TODO: prefers pass app.state to childrens, $root or $children references could change
			// (migration from v1: this.$root)
			app: this.$root.$children[0]
		}
	},

	created: function(){

		this.value = this.fieldvalue && this.fieldvalue.value ? this.fieldvalue.value : null ;

		if( this.value ){
			let obj = helper.block.getBlockById( this.app.state.project, this.value );
			this.currentParent = obj ? obj.value.ref_block : null;
		}

		this.getParents( this.bloc );
		this.getObjectsOfSelectedParent();

	},

	watch: {
		fieldvalue: function (obj) {
			this.value = obj && obj.value ? obj.value : null;
		}
	},

	methods:{

		getParents( node ){

			let self = this;
			
			// On parcours récursivement les parents du module à la recherche des object auquel on a acces
			let parentID = node.value.type !== 'object' ? node.value['ref_' + node.value.level ] : node.value['ref_block'] ;
			if( parentID ){
				let parent = helper.block.getBlockById( this.app.state.project, parentID );

				if( parent.value.type === "experience" ){
					// list widgets of experience
					if( parent.custom.children && parent.custom.children.length > 0 ){
						parent.custom.children.forEach( ( c ) => {
							if( c.value.type === "widget" && this.parentList.indexOf( c ) == -1 )
								this.parentList.push(c);
						});
					}
				}

				this.parentList.push(parent);
				this.getParents( parent );
			}
		},

		getCurrentParent(){

			if( !this.currentParent ){

				let res = this.parentList[0]; // 0 by default;

				if( this.field.parentLevel ){
					// a level parent is specified, search for it in parent list
					this.parentList.forEach( (parent) => {
						if( parent.value.type == this.field.parentLevel )
							res = parent;
					});
					
				}
				

				this.currentParent = res ? res.value._id : null;
				
				return res;
			}
				
			let res = null;
			this.parentList.forEach( (parent)=>{
				if( parent.value._id == this.currentParent ){
					res = parent;
				}
			});
			return res;
		},

        recursiveParseObjects( node, tab, filter, level ){
			let arrayTags = [];
			
			if (Array.isArray(filter)) {
				arrayTags = filter;
			} else {
				if(typeof filter === 'string') {
					arrayTags = [filter];
				} else if (typeof filter === 'object' && filter.ref) {

					arrayTags = [helper.block.getField(this.bloc, filter.ref).value]

				}
			}

			let prefix = '';
			for(let i = 0 ; i<level*2 ; i++){
				prefix += '-';
			}

            if( node && node.custom.objects && node.custom.objects.length > 0 ){
                node.custom.objects.forEach( (obj) => {
					if( !obj.value._deleted ){
						if( !arrayTags.length || (arrayTags.indexOf(obj.custom.tag) != -1) ){
							let title = prefix !== '' ? prefix+' '+obj.value.name : obj.value.name ;
							tab.push( { title: title, value: obj.value._id } );
						}
						this.recursiveParseObjects( obj, tab, arrayTags, level+1 );
					}
                });
            }
        },

		getObjectsOfSelectedParent(){

			this.objectList = [];

			let noParent = this.currentParent ? false : true;
			let noValue = this.value ? false : true;

			let parent = this.getCurrentParent();

			if( parent && this.currentParent && this.currentParent != parent.value._id ){
				noValue = true;
			}
            
            if( parent ){
                this.recursiveParseObjects( parent , this.objectList , this.field.tag, 0 );
            }

			if( noValue && this.objectList.length > 0 )
				this.$parent.valueUpdated( this.field, this.objectList[0].value );
			if( this.objectList.length == 0 )
				this.$parent.valueUpdated( this.field, null );

		},

		valueUpdated: function( fieldUpdated ){
			this.$parent.valueUpdated( fieldUpdated, this.value );
		},


		openMemory: function( id ){
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			this.$root.$emit('open-obj-inspector', {selected: id }) // <---- ORIGINAL openObjInspector LISTENER NOT FOUND
		},


	}

}

</script>

<style lang="stylus">

.object-fields-ctn
	padding-top: 8px
	margin-top: 2px
	padding-bottom: 5px
	
	.field-ctn
		position : relative

		span.text
			line-height : 20px

		.input-fields.groupfield
			line-height : 25px
			
	.noresult
		font-size: 14px
		line-height: 40px
		font-family: 'DinBold'
		text-transform: capitalize
		opacity: .5
		position: absolute
		top: 1px
		left: 119px
		width: 100px
</style>