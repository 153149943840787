var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "textinput-ctn" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value.x,
          expression: "value.x"
        }
      ],
      staticClass: "value vector3D x",
      attrs: {
        type: "text",
        placeholder: _vm.placeholderX,
        disabled: _vm.field.disabled
      },
      domProps: { value: _vm.value.x },
      on: {
        focusout: function($event) {
          return _vm.updateX()
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.value, "x", $event.target.value)
        }
      }
    }),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value.y,
          expression: "value.y"
        }
      ],
      staticClass: "value vector3D y",
      attrs: {
        type: "text",
        placeholder: _vm.placeholderY,
        disabled: _vm.field.disabled
      },
      domProps: { value: _vm.value.y },
      on: {
        focusout: function($event) {
          return _vm.updateY()
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.value, "y", $event.target.value)
        }
      }
    }),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value.z,
          expression: "value.z"
        }
      ],
      staticClass: "value vector3D z",
      attrs: {
        type: "text",
        placeholder: _vm.placeholderZ,
        disabled: _vm.field.disabled
      },
      domProps: { value: _vm.value.z },
      on: {
        focusout: function($event) {
          return _vm.updateZ()
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.value, "z", $event.target.value)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }