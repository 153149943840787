import ActionAbstract from '../ActionAbstract';

class ToggleNavigator extends ActionAbstract {

    static get ID() {
        return 'actions:main:toggleNavigator';
    }

    run(state) {
        
        return this.composeState(state, {
            main: {
                navigatorOpen: this.toggle
            }
        });
    }

    toggle(state) {
        return this.getParam('force')|| !!!state;
    }

}

export default ToggleNavigator;