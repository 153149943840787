import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class UpdateXpConfig extends ActionAbstract {

    static get ID() {
        return 'actions:main:updateXpConfig';
    }

    run(state) {

        let xpConfig = { mapLibrary : 'ui-map-ol' };
        
        if( this.getParam('name') )
            xpConfig[this.getParam('name')] = this.getParam('value');

        this.deps.mainStore.config.xpConfig = xpConfig;

        return this.composeState( state, {
            xpConfig : xpConfig
        });
        
    }

}

export default UpdateXpConfig;