<!-- DisplayUnit vue -->

<template lang="pug">

span.textinput-ctn.displayunit
    input.value(type="text", v-model="displayedValue", v-bind:placeholder="placeholderVal", v-on:focusout="focusOutField(field)", :disabled="field.disabled")
    span.textinput-ctn.unit
        span.select-arrow &gt;
        select(v-on:change="valueUpdated(field)", v-model="unit", :disabled="field.disabled")
            option(value="%", v-if="placeholderUnit != '%'") %
            option(value="%", v-if="placeholderUnit == '%'") % (default)
            option(value="px", v-if="placeholderUnit != 'px'") px
            option(value="px", v-if="placeholderUnit == 'px'") px (default)
            option(value="calc", v-if="placeholderUnit != 'calc'") calc
            option(value="calc", v-if="placeholderUnit == 'calc'") calc (default)
</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue' , 'placeholder' ],

	data() {
		return {
            value: null,
            displayedValue: null,
            unit: "%",
            placeholderVal: null,
            placeholderUnit: null
		}
	},

	created: function() {
        this.value = this.fieldvalue ? this.fieldvalue.value : null;

        if (this.placeholder) {
            if (this.placeholder.search('calc') !== -1) {
                this.placeholderVal = this.getCalcExpression(this.placeholder);
                this.placeholderUnit = 'calc';
            } else {
                this.placeholderVal = this.getFloatValue(this.placeholder);
                this.placeholderUnit = '%';

                if (this.placeholder.search('px') !== -1) {
                    this.placeholderUnit = 'px';
                }
            }
        }

        if (this.value) {
            if (this.value && this.value.search('calc') !== -1) {
                this.displayedValue = this.getCalcExpression(this.value);
                this.unit = 'calc';
            } else {
                this.displayedValue = this.getFloatValue(this.value);

                if (this.value.search('px') !== -1) {
                    this.unit = 'px';
                }
            }
        } else {
            if (this.placeholderUnit) {
                this.unit = this.placeholderUnit;
            }
        }
	},

	destroyed: function() {

	},

	watch: {
        'unit': function(oldValue, newValue) {
            if( oldValue && newValue === oldValue ) return;
            this.$parent.unitUpdated(oldValue)
        }
	},

	methods: {
        getFloatValue: function (val) {
            let floatVal = parseFloat(val);
            
			if( val === null || val === undefined || val === "" || val == "auto" || floatVal == NaN || floatVal == 'NaN' ){
				val = null;
            }
			else
				val = floatVal;
            
            return val;
        },

        getCalcExpression: function (string) {
            if (typeof string !== 'string' && string.search('calc') === -1) {
                return null;
            }

            // Extracting what's inside calc(...)
            return string.substring(5, string.length - 1);
        },

		focusOutField: function (fieldUpdated) {
            if (this.displayedValue !== null) {
                if (this.unit === 'calc') {
                    this.value = `calc(${this.displayedValue})`;
                } else {
                    this.displayedValue = this.getFloatValue(this.displayedValue);
                    this.value = `${this.displayedValue}${this.unit}`;
                }
            } else {
                this.value = null;
            }

            if(this.fieldvalue.value !== this.value) {
                this.valueUpdated(fieldUpdated);
            }
		},

		valueUpdated: function (fieldUpdated) {
            if (this.displayedValue !== null) {
                if (this.unit === 'calc') {
                    this.value = `calc(${this.displayedValue})`;
                } else {
                    this.value = `${this.displayedValue}${this.unit}`;
                }
            } else {
                this.value = 'auto';
            }
            
            this.$parent.valueUpdated(fieldUpdated, this.value);
		}
	}
}

</script>

<style lang="stylus">
.displayunit
    input.value
        width 50% !important
    .unit
        width 35% !important


span.textinput-ctn.displayunit input.value
    width: 72px!important
    //////////////////
    background: var(--inputValue)
    border-radius: 4px

span.textinput-ctn.unit
    width: 63px !important

span.textinput-ctn.unit
    .select-arrow
        top: 0px
        margin-right: 0px !important

span.textinput-ctn.unit
    select
        margin-top: 0px
        margin-left: -10px
        width: 61px

</style>