var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "library-panel" }, [
    _c(
      "div",
      { staticClass: "criteria-bar" },
      [
        _c("div", { staticClass: "arrange-by" }, [
          _c(
            "span",
            { staticClass: "button active" },
            [_c("icon", { attrs: { name: "grid" } })],
            1
          ),
          _c(
            "span",
            { staticClass: "button" },
            [_c("icon", { attrs: { name: "list" } })],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "sort", class: { disabled: _vm.forceFilter } },
          [
            _vm._v("Filter by"),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentFilter,
                    expression: "currentFilter"
                  }
                ],
                attrs: { disabled: _vm.forceFilter },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.currentFilter = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _vm._l(_vm.state.types, function(values, type) {
                  return _c("option", { domProps: { value: type } }, [
                    _vm._v(_vm._s(type))
                  ])
                }),
                _c("option", { attrs: { value: "all" } }, [_vm._v("All types")])
              ],
              2
            ),
            _c("icon", {
              staticClass: "arrow",
              attrs: { name: "arrow-select" }
            })
          ],
          1
        ),
        _c("search-bar", { on: { change: _vm.searchResult } })
      ],
      1
    ),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "list" },
      [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "add rounded" }, [
              _c("input", {
                staticClass: "file input-file",
                attrs: { type: "file", id: "asset-new-file", multiple: "" },
                on: { change: _vm.uploadFile }
              }),
              _c("label", {
                staticClass: "input-file-trigger",
                attrs: { for: "asset-new-file" }
              }),
              _c("span", { staticClass: "ico" })
            ]),
            _vm._l(_vm.mediaList, function(media, i) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.currentFilter == "all" ||
                        (_vm.currentFilter == media.assetType &&
                          media._deleted !== true),
                      expression:
                        "currentFilter == 'all' || currentFilter == media.assetType && media._deleted !== true"
                    }
                  ],
                  staticClass: "item rounded",
                  class: {
                    selected: _vm.selected == media._id,
                    hide: !_vm.isInSearchResult(_vm.searchTag, media)
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectMedia(i)
                    }
                  }
                },
                [
                  media.assetType == "image"
                    ? _c("div", {
                        staticClass: "visu rounded",
                        style: { "background-image": "url(" + media.url + ")" }
                      })
                    : _c("icon", {
                        staticClass: "visu rounded",
                        attrs: { name: media.assetType }
                      }),
                  _c("div", { staticClass: "name rounded" }, [
                    !media.filename
                      ? _c("span", { staticClass: "url" }, [
                          _vm._v(_vm._s(_vm.cleanURL(media.url)))
                        ])
                      : _c("span", { staticClass: "url" }, [
                          _vm._v(_vm._s(media.filename))
                        ])
                  ]),
                  _c("div", { staticClass: "actions" }, [
                    _c("div", {
                      staticClass: "button view",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.viewMedia(media)
                        }
                      }
                    }),
                    !_vm.isShared(media)
                      ? _c("div", {
                          staticClass: "button delete",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.deleteMedia(media)
                            }
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm.isShared(media)
                    ? _c("div", { staticClass: "shared" })
                    : _vm._e()
                ],
                1
              )
            })
          ],
          2
        ),
        _vm.waiting ? _c("spinner") : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "footer" }, [
      _vm.state.status == _vm.state.statusList.LIBRARY_STATE_SELECT
        ? _c(
            "div",
            {
              staticClass: "button",
              class: { enabled: _vm.selected },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.chooseFile()
                }
              }
            },
            [_vm._v("Choose media")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "filter" }, [
      _c("div", { staticClass: "tab active" }, [
        _c("span", [_vm._v("Ressources")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }