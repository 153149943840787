import ActionAbstract from '../ActionAbstract';

class RenameMedia extends ActionAbstract {

    static get ID() {
        return 'actions:library:renameMedia';
    }

    run(state) {
        // mark media to be deleted on save (defer deletion)
        const id = this.getParam('id');
        const filename = this.getParam('filename');
        const mediaSet = this.deps.mainStore.lib.findById(id);

        if (mediaSet.length) {
            const media = mediaSet[0].doc;
            media.filename = filename;
            // post modifications to couchdb.
            this.deps.mainStore.lib.updateMedia(media);
            return this.composeState(state, {
                library: {
                    mediaPreview: media
                }
            });
        }
        return false;
    }
}

export default RenameMedia;