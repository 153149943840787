// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icons/template/template-action-link.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/icons/template/template-action-unlink.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/icons/template/template-action-duplicate.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/icons/template/template-action-delete.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".template-panel .list .content .add {\n  position: relative;\n}\n.template-panel .list .content .add:hover .select {\n  opacity: 1;\n}\n.template-panel .list .content .add .select {\n  box-shadow: 0px 0px 10px var(--shadowColor);\n  background-color: var(--bgColor);\n  border-radius: 10px;\n  position: absolute;\n  z-index: 20;\n  width: 110%;\n  padding: 5px;\n  top: calc(50% + 25px);\n  left: calc(-5% - 5px);\n  opacity: 0;\n}\n.template-panel .list .content .add .select:before {\n  content: '';\n  display: block;\n  width: 20px;\n  height: 20px;\n  background-color: var(--bgColor);\n  position: absolute;\n  transform: rotate(45deg);\n  left: calc(50% - 10px);\n  top: -10px;\n  z-index: 0;\n}\n.template-panel .list .content .add .select span.option {\n  text-transform: capitalize;\n  background-color: var(--menuElementColor);\n  display: inline-block;\n  text-align: center;\n  margin: 5px;\n  width: calc(100% - 10px);\n  position: relative;\n  z-index: 1;\n  height: 30px;\n  line-height: 30px;\n  border-radius: 5px;\n  color: var(--inputTextColor);\n}\n.template-panel .list .content .add .select span.option:hover {\n  background-color: #f49e75;\n  color: #fff;\n}\n.template-panel .list .content .item .flags .linked {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.template-panel .list .content .item .actions .link {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.template-panel .list .content .item .actions .unlink {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.template-panel .list .content .item .actions .duplicate {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.template-panel .list .content .item .actions .delete {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
