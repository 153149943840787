import ChartsUiObject from '../ChartsUiObject';

class UiCircularProgressBar extends ChartsUiObject {
    constructor(id, props) {
        super(id, props, 'CircularProgressBar');
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-circular-progress-bar",
            label: "Circular Progress Bar",
            parent : "ui-progress",
            isDisplayed : true,
            isEmbeddable: true
        };
    }


    initParameters(){
        super.initParameters();

        let dataGroup = this.createInspectorNode('group', 'data-group', 'Data' );
        dataGroup.children.push( this.createInspectorNode('group-field', 'data-fields') );

        let sizeGroup = this.createInspectorNode('group', 'csize-group', 'Size' );
        sizeGroup.children.push( this.createInspectorNode('group-field', 'csize-fields') );

        let styleGroup = this.createInspectorNode('group', 'style-group', 'Style' );
        styleGroup.children.push( this.createInspectorNode('group-field', 'style-fields') );

        let chartTopic = this.createInspectorNode( 'topic', 'chartSetting', 'Chart settings' );
        chartTopic.children = [ dataGroup, sizeGroup, styleGroup ];

        this.inspector.unshift( chartTopic );

        let otherParameters = {

            // DATA
            percent: {
                type: 'Percent',
                default: 0.2,
                partial: null,
                connection: { in: {pluggable: true, default: true}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Percent",
                    container: "data-fields",
                    widget: "percent",
                }
            },

            // SIZE
            radius: {
                type: 'Int',
                default: 100,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Radius",
                    container: "csize-fields",
                    unit: 'px'
                }
            },
            thickness: {
                type: 'Int',
                default: 20,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Thickness",
                    container: "csize-fields",
                    unit: 'px'
                }
            },
            startAngle: {
                type: 'Int',
                default: 0,
                partial: 'chart',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Start angle",
                    container: "csize-fields",
                    unit: 'deg'
                }
            },
            endAngle: {
                type: 'Int',
                default: 360,
                partial: 'chart',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "End angle",
                    container: "csize-fields",
                    unit: 'deg'
                }
            },
            startSide: {
                type: 'String',
                default: 'bottom',
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Start side",
                    container: "style-fields",
                    widget: 'select',
                    options: [ {value: 'left', label:'Left'} , {value: 'top', label:'Top'} , {value: 'right', label:'Right'} , {value: 'bottom', label:'Bottom'} ]
                }
            },

            // STYLE
            strokeStyle: {
                type: 'String',
                default: 'filled',
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Stroke style",
                    container: "style-fields",
                    widget: 'select',
                    options: [ {value: 'filled', label:'Filled'} , {value: 'dotted', label:'Dotted'} ]
                }
            },
            cornerRadius: {
                type: 'Int',
                default: 5,
                partial: 'chart',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Corner radius",
                    container: "style-fields",
                    unit: 'px',
                    conditions : [{field:'strokeStyle', value:'dotted', operator:'!='}]
                }
            },

            nbDots: {
                type: 'Int',
                default: 20,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Number of dots",
                    container: "style-fields",
                    conditions : [{field:'strokeStyle', value:'dotted', operator:'=='}],
                    min: 4
                }
            },

            strokeColor: {
                type: 'Color',
                default: { hex : "#FFFFFF" },
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Stroke color",
                    container: "style-fields"
                }
            },
            strokeBackgroundColor: {
                type: 'Color',
                default: null,
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Stroke background color",
                    container: "style-fields"
                }
            }
        };

        this.addToParameters( otherParameters );

        this.setParamInvisible( 'addChild' );
        this.setParamInvisible( 'removeChild' );

        this.setParamInvisible( 'width' ); // replaced by radius
        this.setParamInvisible( 'height' ); // replaced by radius

        this.setParamInvisible( 'backgroundColor' );
        this.setParamInvisible( 'backgroundImage' );
        this.setParamInvisible( 'backgroundRepeat' );
        this.setParamInvisible( 'backgroundSize' );
        this.setParamInvisible( 'backgroundWidth' );
        this.setParamInvisible( 'backgroundHeight' );
        this.setParamInvisible( 'backgroundPositionX' );
        this.setParamInvisible( 'backgroundPositionY' );
        this.setParamInvisible( 'fontFamily' );

        this.setParamInvisible( 'domainX' );
        this.setParamInvisible( 'domainY' );
        this.setParamInvisible( 'domainPaddingX' );
        this.setParamInvisible( 'domainPaddingY' );


    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }

    init(){
        super.init();

    }

}

export default UiCircularProgressBar;
