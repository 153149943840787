
import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class CheckLinks extends ActionAbstract {

    /**
     * @param bloc_id : id of parent block (loop into its children) 
     * @param child_id : check link only if the link is connected to this block id
     */

    static get ID() {
        return 'actions:editor:schematic:checkLinks';
    }

    run(state) {

        this.state = state;

        let block = helper.block.getBlockById(state.project, this.getParam('bloc_id'));

        this.checkLinksInBlock(block);

    }

    checkLinksInBlock(block) {
        // let self = this;
        block.custom.children.forEach((b) => {
            if (b.value.links)
                b.value.links.forEach(this.checkLink.bind(this, b));
            if (b.custom.children && this.getParam('recursive'))
                b.custom.children.forEach(this.checkLinksInBlock.bind(this));
        });
    }

    checkLink(blocParent, link) {
        let bFrom, bTo;

        bFrom = helper.block.getBlockById(this.state.project, link.from.blocID);
        bTo = helper.block.getBlockById(this.state.project, link.to.blocID);

        let childId =  this.getParam('child_id');
        if( childId && link.from.blocID != childId && link.to.blocID != childId )
            return;

        if (bFrom === null || bTo === null || !helper.links.checkLink(link.from, bFrom, link.to, bTo, link, false, true, this.state)) {
            this.trigger('editor:schematic:deleteLink', { blocId: blocParent.value._id, link})
        }
    }

}

export default CheckLinks;