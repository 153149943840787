import ServiceManager from './ServiceManager';

export { default as ServiceAbstract } from './ServiceAbstract';

import DebugOutput from './DebugOutput';
import EventLogger from './EventLogger';

// VERBOSE MODE
DebugOutput.disableConsole = true;  // enable optional logging of informations: action and state
window.disableConsole = (bool) => DebugOutput.disableConsole = bool
window.logActionsStack = false;     // print actions or sub actions stack order

ServiceManager.register(DebugOutput);
ServiceManager.register(EventLogger, ['services:DebugOutput']);

const serviceManager = new ServiceManager();
export default serviceManager;
