<!-- Boolean vue -->

<template lang="pug">

span.checkbox(v-bind:class="{'selected':value, 'plug':field.type=='field', 'disabled': field.disabled }", v-on:click.stop="[toggleValue(), valueUpdated(field) ]")
	input(type="checkbox", v-model="value", :disabled="field.disabled")

</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue' , 'placeholder' ],

	data() {
		return {
			value: null
		}
	},

	created: function(){

		this.value = this.fieldvalue ? this.fieldvalue.value : false;

		if( this.placeholder && this.fieldvalue && this.fieldvalue.value == null ){
			this.value = this.placeholder;
		}
			
	},

	watch:{
		fieldvalue: function (obj) {
			this.value = obj ? obj.value : false;
		}
	},

	methods:{

		valueUpdated: function( fieldUpdated ){
			this.$parent.valueUpdated( fieldUpdated, this.value );
		},


		toggleValue: function(){
			if( this.field.disabled ) return;
			this.value = !this.value
		}


	}

}

</script>

<style lang="stylus">
</style>
