import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import deepCopy from 'deepcopy';

class UpdateIncludedBlocks extends ActionAbstract {

    static get ID() {
        return 'actions:block:updateIncludedBlocks';
    }

    /**
     * @param id
     * @param includedBlocks
     * (@param includedInpector) -- deprecated
     * @param includedBlockType
     * @param defaultVal 
     */
    run (state) {

        [this._target, this._path] = helper.block.getEntityByIDInWholeProject(state.project, this.getParam('id'), true);

        let composite = {project: {}};
        if (this.getParam('includedBlocks'))
            composite.project[this._path + '.value.includedBlocks'] = this.getParam('includedBlocks');
        else 
            composite.project[this._path + '.value.includedBlocks'] = this.removeKey();
        

        let includedBlockType = this.getParam('includedBlockType');

        
        //includedBlockType = helper.config.mapConfigConvertion( includedBlockType , state.xpConfig.mapLibrary );

        if( includedBlockType )
            composite.project[this._path + '.value.includedBlockType'] = includedBlockType;
        else 
            composite.project[this._path + '.value.includedBlockType'] = this.removeKey();
            
        
        //!\ includedInspector deprecated : use included includedBlockType instead /!\\
        let includedInspector = this.getParam('includedInspector');
        
        if( includedInspector && this.getParam('defaultVal') === false ){
            
            let includedInspectorCopy = [];

            includedInspector.forEach( ( inspector ) => {
                let copy = deepCopy( inspector );
                this.removeDefaultValues( copy );
                includedInspectorCopy.push( copy );
            });

            includedInspector = includedInspectorCopy;
            
        }

        if( includedInspector && (this.getParam('defaultVal') === false  || !this.getParam('defaultVal')) && this._target.value.type.includes('template')){
            let includedInspectorCopy = [];
            includedInspector.forEach( ( inspector ) => {
                let copy = deepCopy( inspector );
                includedInspectorCopy.push( copy );
            });
            includedInspector = includedInspectorCopy;
        }

        if ( includedInspector )
            composite.project[this._path + '.value.includedInspector'] = this._target.value.includedInspector ? this._target.value.includedInspector.concat(includedInspector) : includedInspector ;
        else 
            composite.project[this._path + '.value.includedInspector'] = this.removeKey();
        //!\ ----------------------------------------------------------- deprecated /!\\


        this.trigger('block:updateFields', { id : this.getParam('id') });//, refresh: true } );
        this.trigger('editor:schematic:checkLinks', { bloc_id: this.getParam('id') });

        return this.composeState(state, composite);
    }

    runClear( state ){
        
        [this._target, this._path] = helper.block.getEntityByIDInWholeProject(state.project, this.getParam('id'), true);
        let composite = {project: {}};
        composite.project[this._path + '.value.includedInspector'] = this.removeKey();
        
        return this.composeState(state, composite);
    }

    removeDefaultValues( inspector ){

        if(!inspector)
            return;
        
        if( inspector.children ){
            inspector.children.forEach( (child) => {

                if( ( child.type == "field" || child.type == "param" ) ){
                    delete child.default;
                }

                if( child.children && child.children.length > 0 ){
                    this.removeDefaultValues( child  );
                }

            });
        }
        

    }

}

export default UpdateIncludedBlocks;