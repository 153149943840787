var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "widget" },
    [
      _vm.bloc.type == "array-row-field"
        ? _c("array-row-fields", {
            attrs: { target: _vm.target, bloc: _vm.bloc }
          })
        : _vm._e(),
      _vm.bloc.type == "object-includer"
        ? _c("object-includer", {
            attrs: {
              project: _vm.project,
              target: _vm.target,
              bloc: _vm.bloc,
              state: _vm.state,
              inspectorField: _vm.inspectorField
            }
          })
        : _vm._e(),
      _vm.bloc.type == "state-manager"
        ? _c("state-manager", {
            attrs: { project: _vm.project, target: _vm.target, bloc: _vm.bloc }
          })
        : _vm._e(),
      _vm.bloc.type == "state-trigger"
        ? _c("state-trigger", {
            attrs: { project: _vm.project, target: _vm.target, bloc: _vm.bloc }
          })
        : _vm._e(),
      _vm.bloc.type == "node-memories"
        ? _c("node-memories", {
            attrs: {
              project: _vm.project,
              target: _vm.target,
              bloc: _vm.bloc,
              schematicLevel: _vm.schematicLevel,
              state: _vm.state
            }
          })
        : _vm._e(),
      _vm.bloc.type == "training-set"
        ? _c("training-set-list", {
            attrs: { project: _vm.project, target: _vm.target, bloc: _vm.bloc }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }