var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "big-bt field-group",
      class: { hide: _vm.isObj && _vm.bloc.hideForObjInspector === true }
    },
    _vm._l(_vm.bloc.children, function(subbloc) {
      return _c(
        "div",
        { staticClass: "children" },
        [
          subbloc.type == "field" ||
          (subbloc.type == "param" && subbloc.hideForObjInspector !== true)
            ? _c("field", {
                attrs: {
                  field: subbloc,
                  bloc: _vm.target,
                  groupfield: true,
                  state: _vm.state,
                  inspectorField: _vm.inspectorField
                }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }