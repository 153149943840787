import ActionAbstract from '../ActionAbstract';

class LibraryActionAbstract extends ActionAbstract {

    preRun(state) {
        this.composeState(state, {
            library: {
                types: this.deps.mainStore.lib.types,
                statusList: {
                    LIBRARY_STATE_CONSULT: this.deps.mainStore.lib.LIBRARY_STATE_CONSULT,
                    LIBRARY_STATE_SELECT: this.deps.mainStore.lib.LIBRARY_STATE_SELECT
                }
            }
        });
    }

}

export default LibraryActionAbstract;