var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "doc-keyup",
          rawName: "v-doc-keyup",
          value: _vm.triggerKeyUp,
          expression: "triggerKeyUp"
        }
      ],
      staticClass: "main-ctn"
    },
    [
      _vm.appState.main && _vm.appState.project
        ? _c(
            "div",
            { staticClass: "main-ctn" },
            [
              _c("top-menu", { attrs: { "app-state": _vm.appState } }),
              _vm.appState.main && _vm.appState.main.breadcrumbOpen
                ? _c("breadcrumb", { attrs: { "app-state": _vm.appState } })
                : _vm._e(),
              _c("navigator", { attrs: { "app-state": _vm.appState } }),
              _c(
                "div",
                {
                  staticClass: "draw-zone",
                  class: { navigatorOpen: _vm.appState.main.navigatorOpen }
                },
                [
                  _vm.appState.editor
                    ? _c("editor", { attrs: { "app-state": _vm.appState } })
                    : _vm._e()
                ],
                1
              ),
              _vm.appState.main && _vm.appState.main.libraryOpen
                ? _c("slider-panel", {
                    attrs: {
                      state: _vm.appState.library,
                      user: _vm.appState.user,
                      panel: _vm.appState.panel,
                      workspace: _vm.appState.workspace,
                      title: "Asset Library",
                      name: "LIBRARY"
                    }
                  })
                : _vm._e(),
              _c(
                "transition",
                { attrs: { name: "modal-transition" } },
                [
                  _vm.appState.main && _vm.appState.main.mediaPreviewOpen
                    ? _c("media-preview", {
                        staticClass: "modal-transition",
                        attrs: { state: _vm.appState.library }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "modal-transition" } },
                [
                  _vm.appState.main && _vm.appState.library.tagModalOpen
                    ? _c("tagModal", {
                        staticClass: "modal-transition",
                        attrs: { state: _vm.appState.library }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.appState.main && _vm.appState.main.templateOpen
                ? _c("slider-panel", {
                    attrs: {
                      state: _vm.appState.templates,
                      user: _vm.appState.user,
                      panel: _vm.appState.panel,
                      project: _vm.appState.project.value,
                      title: "Templates",
                      name: "TEMPLATE"
                    }
                  })
                : _vm._e(),
              _vm.appState.main && _vm.appState.main.jsonEditorOpen
                ? _c("slider-panel", {
                    attrs: {
                      state: _vm.appState.jsonEditor,
                      panel: _vm.appState.panel,
                      title: "JSON Editor",
                      name: "JSON"
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "inspector-container",
                  class: {
                    open: _vm.inspectorIsOpen,
                    "second-panel-open": _vm.objInspectorIsOpen
                  }
                },
                [
                  _c("div", {
                    staticClass: "bt-nav",
                    class: { open: _vm.inspectorIsOpen, geo: _vm.geoIsOpen },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.toggleInspector()
                      }
                    }
                  }),
                  _c(
                    "transition",
                    { attrs: { name: "panel-transition" } },
                    [
                      _vm.inspectorIsOpen
                        ? _c("inspector", {
                            staticClass: "panel-transition",
                            class: { geo: _vm.geoIsOpen },
                            attrs: {
                              "app-config": _vm.appState.config,
                              project: _vm.appState.project,
                              inspector: _vm.appState.inspector,
                              workspace: _vm.appState.workspace.target,
                              state: _vm.appState
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "transition",
                    { attrs: { name: "panel-transition" } },
                    [
                      _vm.objInspectorIsOpen
                        ? _c("inspector", {
                            staticClass: "obj panel-transition",
                            attrs: {
                              "app-config": _vm.appState.config,
                              project: _vm.appState.project,
                              inspector: _vm.appState.objinspector,
                              workspace: _vm.appState.workspace.target,
                              state: _vm.appState
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "arraypan-transition" } },
                [
                  _vm.appState.main && _vm.appState.main.arrayInspectorOpen
                    ? _c("array-inspector", {
                        staticClass: "arraypan-transition",
                        attrs: {
                          project: _vm.appState.project,
                          inspector: _vm.appState.arrayInspector,
                          library: _vm.appState.library,
                          geomodal: _vm.appState.main.geomodal
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.appState.isLoading ? _c("spinner") : _vm._e(),
      _vm.appState.modal && _vm.appState.modal.open
        ? _c("modal", { attrs: { settings: _vm.appState.modal } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }