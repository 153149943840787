import ActionAbstract from '../ActionAbstract';
import Vue from 'vue';

class FetchAssets extends ActionAbstract {

    static get ID() {
        return 'actions:library:fetchAssets';
    }

    run(state) {

        return this.composeState(state, {
            library: {
                mediaList: this.deps.mainStore.lib.listMedia,
                waiting: false
            }
        });

    }

}

export default FetchAssets;


