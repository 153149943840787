var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "prehome" },
        [
          _c("a", { attrs: { id: "top" } }),
          _c("global-links", {
            attrs: { user: _vm.appState && _vm.appState.user }
          }),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "content-vcentered" }, [
              _vm.tab == "accueil"
                ? _c("div", [
                    _c("div", { staticClass: "logo" }),
                    _c("h1", [
                      _vm._v("The web platform for digital creatives")
                    ]),
                    _vm._m(0),
                    _c(
                      "a",
                      {
                        staticClass: "big-button",
                        on: { click: _vm.connexion }
                      },
                      [_c("span", [_vm._v("Start")])]
                    )
                  ])
                : _vm._e(),
              _vm.tab == "form"
                ? _c("div", [
                    _c("div", { staticClass: "logo" }),
                    _c("h1", [_vm._v("Identification")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email"
                        }
                      ],
                      staticClass: "home",
                      attrs: {
                        type: "text",
                        name: "email",
                        placeholder: "E-mail"
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "home",
                      attrs: {
                        type: "password",
                        name: "password",
                        placeholder: "Password"
                      },
                      domProps: { value: _vm.password },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.login($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    }),
                    _c("span", { staticClass: "error" }, [
                      _vm._v(
                        _vm._s(
                          _vm.appState.login
                            ? _vm.appState.login.errorMessage
                            : ""
                        )
                      )
                    ]),
                    _vm.appState.login && _vm.appState.login.errorMessage != ""
                      ? _c("br")
                      : _vm._e(),
                    _c(
                      "a",
                      { staticClass: "big-button", on: { click: _vm.login } },
                      [_c("span", [_vm._v("Log In")])]
                    ),
                    _c("br")
                  ])
                : _vm._e(),
              _vm.tab == "subscribe"
                ? _c("div", [
                    _c("div", { staticClass: "logo" }),
                    _c("h1", [_vm._v("Create an account")]),
                    _c("br"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email"
                        }
                      ],
                      staticClass: "home",
                      attrs: {
                        type: "text",
                        name: "email",
                        placeholder: "E-mail"
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "home",
                      attrs: {
                        type: "password",
                        name: "password",
                        placeholder: "Password"
                      },
                      domProps: { value: _vm.password },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.subscribe($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cpassword,
                          expression: "cpassword"
                        }
                      ],
                      staticClass: "home",
                      attrs: {
                        type: "password",
                        name: "cpassword",
                        placeholder: "Password confirmation"
                      },
                      domProps: { value: _vm.cpassword },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.subscribe($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.cpassword = $event.target.value
                        }
                      }
                    }),
                    _c("br"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.apassword,
                          expression: "apassword"
                        }
                      ],
                      staticClass: "home",
                      attrs: {
                        type: "password",
                        name: "apassword",
                        placeholder: "Admin password"
                      },
                      domProps: { value: _vm.apassword },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.subscribe($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.apassword = $event.target.value
                        }
                      }
                    }),
                    _c("br"),
                    _vm.appState.signup
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.appState.signup.errorMessage)),
                          _c("br")
                        ])
                      : _vm._e(),
                    _c(
                      "a",
                      {
                        staticClass: "big-button",
                        on: { click: _vm.subscribe }
                      },
                      [_c("span", [_vm._v("Subscribe")])]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._m(1)
          ]),
          _c("a", { attrs: { id: "tour" } }),
          _vm._m(2),
          _vm._m(3),
          _c("a", { attrs: { id: "showcase" } }),
          _c("div", { staticClass: "showcase" }, [
            _c("div", { staticClass: "title" }, [
              _c("h1", [_vm._v("Showcase")]),
              _c("div", { staticClass: "content" }, [
                _c("h1", [_vm._v("They build their ideas with Nodal.Studio")]),
                _c("br"),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("vibes")
                      }
                    }
                  },
                  [_vm._m(4)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("dmacdlo")
                      }
                    }
                  },
                  [_vm._m(5)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("cityforu")
                      }
                    }
                  },
                  [_vm._m(6)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("neighbors")
                      }
                    }
                  },
                  [_vm._m(7)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("walks")
                      }
                    }
                  },
                  [_vm._m(8)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("code")
                      }
                    }
                  },
                  [_vm._m(9)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("postpiper")
                      }
                    }
                  },
                  [_vm._m(10)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("playground")
                      }
                    }
                  },
                  [_vm._m(11)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("saleve")
                      }
                    }
                  },
                  [_vm._m(12)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("drouot")
                      }
                    }
                  },
                  [_vm._m(13)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("mucem")
                      }
                    }
                  },
                  [_vm._m(14)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("mars")
                      }
                    }
                  },
                  [_vm._m(15)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("danser")
                      }
                    }
                  },
                  [_vm._m(16)]
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openPopin("fabrique")
                      }
                    }
                  },
                  [_vm._m(17)]
                )
              ]),
              _c("div", { staticClass: "clear" })
            ])
          ]),
          _c("a", { attrs: { id: "contact" } }),
          _vm._m(18),
          _vm._m(19)
        ],
        1
      ),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "postpiper"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject postpiper" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Post-Piper")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Conception and general curating : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://atelier-artel.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Artel")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Developement : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Collectif Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Realization and technical support : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://sylvainderozevire.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Studio design Sylvain de Rozevire")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Venues : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.ecolecamondo.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("École Camondo · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.lesartsdecoratifs.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Musée des Arts Décoratifs")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Partners : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.ecolecamondo.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("École Camondo · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.timmpi.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Timmpi · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.movinphone.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Movin’Phone")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("More information : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://postpiper.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Official website")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "Discotheque, rave party, club, festival and any other form of organization of festive events have participated – through their atmospheres – to the creation of collective imaginary, between entertainment and political demands. The history of these environments, especially since the World War II, reveals the major place that the ambience take in the processes of psychic and collective individualizations. For better and for worse."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "playground"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject playground" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Playground")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Sponsor : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.maifsocialclub.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("MAIF Social Club")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Conception and developement : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Partners : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.crossedlab.org",
                                target: "_blank"
                              }
                            },
                            [_vm._v("CrossedLab · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://www.agence-nationale-recherche.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("ANR")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("More information : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://vimeo.com/216900137",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Video · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://lieu.maifsocialclub.fr/programmation/sportmania/playground",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Event")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "Playground turns a neighborhood into a playground for a session of 40'. About twenty participants are involved in the playground, while the public can follow the game on a real-time 3D map of the neighborhood. The game brings the participants to move constantly, statistics of distance traveled and expenditure of calories are collected in real time and restored on the big screen as well as on mobiles.."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "saleve"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject saleve" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Paysage Sonore")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Sponsor : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.maisondusaleve.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Maison du Salève")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Scenography : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://atelier-artel.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Artel")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Interation design ad mobile app : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Partner : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://cosima.ircam.fr/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("ANR-CoSiMa")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "The temporary exhibition on the theme of the Paysage Sonore is presented at Maison du Salève for two years. The exhibition shows four biotopes in which the visitor can incarnate different actors of the landscape to better understand the impact of human activities on these ecosystems.."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "drouot"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject drouot" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Drouotnautes")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Sponsor : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.mulhouse.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Ville de Mulhouse")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Curation : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://www.facebook.com/Le-BENTO-105743163241185/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Le Bento")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Artistic Direction : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Collective Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Workshop : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Collective Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Partner : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://cosima.ircam.fr/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("ANR-CoSiMa")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "During 4 weeks, the team of Orbe has implemented a sound-walk on the theme of xenobiology with schoolchildren and high school students from the Drouot district in Mulhouse. In this walk, the visitor explores ecosystems that are deployed on imaginary planets."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "mucem"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject mucem" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Parcours Enfants du Mucem")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Sponsor : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.mucem.org",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Mucem")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Scenography : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.matalicrasset.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Matali Crasset")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Tangible Design : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://studioravages.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Studio Ravages")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Scenario : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://urbanexpe.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Urban Expé")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Interactive design : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Partner : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://cosima.ircam.fr/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("ANR-Cosima")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "Orbe is working again with the Mucem teams to design and implement the child tour route. The route takes place at the times of Soliman the Magnificent and Philip II of Spain. Young visitors explore the Mediterranean from port to port, collecting its wealth."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "mars"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject mars" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Escape to Mars")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Sponsor : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.keepcool.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Keep Cool")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Scenario : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://urbanexpe.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Urban Expé")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Interactive and Graphic Design : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Technical solution : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "This Escape Game takes place in a Fitness Room. Participants are located in a ship in flight towards the red planet. Many difficulties animate their journey. They must overcome each of them by practicing the different activities proposed by the room. "
                        )
                      ]),
                      _c("p", [_vm._v("Photo by Urban Expé")])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "danser"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject danser" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Application à danser")]),
                      _c("iframe", {
                        attrs: {
                          src: "https://player.vimeo.com/video/294744935",
                          width: "100%",
                          height: "460",
                          frameborder: "0",
                          webkitallowfullscreen: "",
                          mozallowfullscreen: "",
                          allowfullscreen: ""
                        }
                      }),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Sponsor : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.gymnase-cdc.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Le Gymnase (Roubaix)")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Partners : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://a-cdc.fr/FR",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "The 12 Choreographic Development Centers · "
                              )
                            ]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.ircam.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("IRCAM · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.contour-progressif.net",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "Compagnie Contour Progressif – Mylène Benoit · "
                              )
                            ]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://cosima.ircam.fr/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("ANR-Cosima · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://rapidmix.goldsmithsdigital.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("RapidMix")]
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Technical solution, interactive and graphic design : "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "An app to build his dance from everyday gestures, in workshops organized by the CDCNs. The app allows to develop a personal choreographic repertoire, in relation to space, sound, others."
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "close" })
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "fabrique"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject fabrique" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Les contraintes de l'endroit")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Sponsor : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.univ-paris8.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Université Paris 8")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Partners : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.labex-arts-h2h.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Labex Arts H2H · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://idefi-creatic.net/fr/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Idefi-CréaTIC · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.ircam.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("IRCAM · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://cosima.ircam.fr/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("ANR-Cosima  · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://rapidmix.goldsmithsdigital.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("RapidMix")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Interactive and Graphic Design : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("More information : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://www.labex-arts-h2h.fr/les-contraintes-de-l-endroit.html",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Official page")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "The sound arts represent a field of activities with the distinction of being both old and new. Ancient, because its history goes back to at least the beginning of the twentieth century and the plural of its denomination inscribes it in fact in a complex genealogy. Recent, through the specific studies devoted to it. The sonic arts factory intends to analyze the historical and cultural construction of these practices, their conditions of possibility and their historiography, as well as the social and aesthetic stakes at work behind the affects they mobilize or produce."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "code"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject code" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("iframe", {
                        attrs: {
                          src: "https://player.vimeo.com/video/279939004",
                          width: "100%",
                          height: "460",
                          frameborder: "0",
                          webkitallowfullscreen: "",
                          mozallowfullscreen: "",
                          allowfullscreen: ""
                        }
                      }),
                      _c("h1", [_vm._v("Capture")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Partners : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.facebook.com/codeformusic/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Code · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://paulemilieu.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Paul Emilieu")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v("Capture is a project using the Post-Piper "),
                        _c("em", [_vm._v("dispositif")]),
                        _vm._v(
                          " in order to create a collective audiovisual experience. The Code Ensemble improvises on their intruments to activate the audience smartphones displayed on the Post-Piper structure. As a result, the musicians and participants are imerged into a collective muscial and light installation creating a visual score as the instruments play.  "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "walks"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject walks" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Audio Walks")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Sponsor : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.univ-paris8.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("ENSCi – Les Ateliers")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Partner : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("More information : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://audiowalks.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Projects page")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "A set of sound creations on the move: fictions, life-size games, urban visits... The students of ENSCi's Sound Creation Studio team up with Orbe to create a dozen of experiences. The applications are directly visible in the browser of any smartphone, in order to compose scenarios, play sounds and geolocate multiple interaction modalities. This experimental project helps the platform to extend its use to wider audiences. The walks are available wherever and whenever wanted, as long as the participant explores to the scene with a mobile web browser."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "vibes"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject vibes" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Vibes")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "This project is co-funded by the Creative Europe Programme of the European Union"
                          )
                        ]),
                        _c("li", [
                          _vm._v("Partners : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "http://www.comune.bassano.vi.it/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Comune di Bassano del Grappa · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.coventry.ac.uk/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Coventry University · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.holonic.systems/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Holonic Systems · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.ickamsterdam.com/en/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("ICK Amsterdam · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Website : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://vibes.nodal.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Vibes")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "Vibes is a dance meeting app. After  creating a profile, the user sends an invitation to share an dance moment at certain time and date. The appointment ends as a performance in different places at the same time, where people can create movement according to the sound and share a dance moment audioguided by a choreographer."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "dmacdlo"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject dmacdlo" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Des mondes au creux de l'oreille")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Partners : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.osupytheas.fr/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("OSU Institut Pytheas · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.prism.cnrs.fr/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Prism/CNRS · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://ecole-art-aix.fr/",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "École supérieure d'art d'Aix-en-Provence · "
                              )
                            ]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Website : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "http://www.desmondesaucreuxdeloreille.net/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Des mondes au creux de l'oreille")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "Des mondes au creux de l'oreille is a soundwalk through the old port of Marseille that connects you, as you walk, to natural sound environments captured live around the world. Explore the old port with your mobile phone and experience these soundscapes, far or near, over time and space."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "neighbors"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject neighbors" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("Neighbors")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Partners : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://yannickribeaut.com/wp/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Yannick Ribeaut · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Websites : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://dev2.nodal.mobi/orbe/neighbors/app",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Participate to the project · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://dev2.nodal.mobi/orbe/neighbors/site",
                                target: "_blank"
                              }
                            },
                            [_vm._v("View the project")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "Neighbors a collective photographic project from your home, through the prism of your windows, on the model of Yannick Ribeaut's “Neighbors Project” and developed by Orbe. This series is our reflection on interdependence, interconnection, otherness."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "popin" } }, [
        _vm.popinWindow == "cityforu"
          ? _c("div", { staticClass: "popin" }, [
              _c(
                "div",
                { staticClass: "popinClose", on: { click: _vm.closePopin } },
                [
                  _c("div", { staticClass: "popinProject cityforu" }, [
                    _c("div", { staticClass: "popinContent" }, [
                      _c("h1", [_vm._v("City4u")]),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Producer : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://city4u.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("City4u")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("Creative partners : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.deep-forest.fr",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Deep Forest · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://prisme.ae",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Prisme · ")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.orbe.mobi",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Orbe")]
                          )
                        ]),
                        _c("li", [
                          _vm._v("More info : "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://vimeo.com/467313772/169aa94b3d",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Video ")]
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          "City4u offers a discovery experience of the city through the prism of immersive media. The offer is presented as a multi-faceted avant-garde route to discover the secrets of a city."
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "close" })
                  ])
                ]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "baseline" }, [
      _c("p", [
        _vm._v(
          "Explore the relationship between bodies, media and spaces through new interfaces and collaborative tools for creativity"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scroll" }, [
      _c("p", [_vm._v("Scroll down to learn more")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tour" }, [
      _c("div", { staticClass: "title" }, [_c("h1", [_vm._v("Tour")])]),
      _c("div", { staticClass: "content" }, [
        _c("h1", [_vm._v("What is Nodal.Studio ?")]),
        _c("br"),
        _c("div", { staticClass: "part-one" }, [
          _c("p", [
            _vm._v(
              "Nodal.Studio offers an accessible tool to create a new generation of mobile apps : applications located in a place and a moment and adapted to the user. This relevance is achieved by linking multiple web components: sensors, machine learning, IOT, Open AI, Open Data, cloud, BLE beacons, social networks..."
            )
          ]),
          _c("p", [
            _vm._v(
              "Nodal.Studio addresses a wide variety of mobile experiences : soundwalks, soundscapes, collective audiovisual performances, collaborative games, augmented reality, alternate reality games... Nodal.studio turns the smartphone in everybody’s pocket into a mean of intense immersion, collaborative production or collective expression."
            )
          ])
        ]),
        _c("div", { staticClass: "part-two" }, [
          _c("div", { staticClass: "video" }, [
            _c("iframe", {
              attrs: {
                src: "https://player.vimeo.com/video/298171079?color=f8a176",
                frameborder: "0",
                webkitallowfullscreen: "",
                mozallowfullscreen: "",
                allowfullscreen: ""
              }
            })
          ])
        ]),
        _c("div", { staticClass: "clear" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "how" }, [
      _c("div", { staticClass: "content" }, [
        _c("h1", [_vm._v("How does it work ?")]),
        _c("br"),
        _c("div", { staticClass: "steps" }, [
          _c("div", { staticClass: "step-one" }, [
            _c("div", { staticClass: "bg" }),
            _c("h2", [_vm._v("Step 1")]),
            _c("p", [_vm._v("Create your project and your « targets ».")])
          ]),
          _c("div", { staticClass: "step-two" }, [
            _c("div", { staticClass: "bg" }),
            _c("h2", [_vm._v("Step 2")]),
            _c("p", [
              _vm._v("Create and edit screens, with a WYSIWYG screen editor.")
            ])
          ]),
          _c("div", { staticClass: "step-three" }, [
            _c("div", { staticClass: "bg" }),
            _c("h2", [_vm._v("Step 3")]),
            _c("p", [_vm._v("Compose your map with beacons and an POIs.")])
          ]),
          _c("div", { staticClass: "step-four" }, [
            _c("div", { staticClass: "bg" }),
            _c("h2", [_vm._v("Step 4")]),
            _c("p", [
              _vm._v(
                "Create your interactions with a wide range of features. Link modules and test it on the fly."
              )
            ])
          ]),
          _c("div", { staticClass: "step-five" }, [
            _c("div", { staticClass: "bg" }),
            _c("h2", [_vm._v("Step 5")]),
            _c("p", [_vm._v("Publish your experience for the web.")])
          ])
        ]),
        _c("div", { staticClass: "clear" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg vibes" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [_c("h4", [_vm._v("Vibes")])])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg dmacdlo" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Des mondes au creux de l'oreille")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg cityforu" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [_c("h4", [_vm._v("City4u")])])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg neighbors" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Neighbors")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg walks" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Audio Walks")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg code" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [_c("h4", [_vm._v("Capture")])])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg postpiper" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Post-Piper")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg playground" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Playground")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg saleve" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Paysage sonore")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg drouot" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Drouotnautes")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg mucem" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Parcours Enfants du Mucem")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg mars" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Escape to Mars")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg danser" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Application à danser")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "showcaseImg fabrique" }, [
      _c("div", { staticClass: "colorImg" }, [
        _c("div", { staticClass: "textImage" }, [
          _c("div", { staticClass: "legend" }, [
            _c("h4", [_vm._v("Les contraintes de l'endroit")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "logo" }),
      _c("br"),
      _c("a", { attrs: { href: "mailto:contact@orbe.mobi" } }, [
        _c("h1", { staticClass: "mail" }, [_vm._v("Contact us")])
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "col" }, [
          _c("h1", [_vm._v("Nodal.Studio")]),
          _c("ul", [
            _c("li", [
              _c("a", { attrs: { href: "#tour" } }, [_c("p", [_vm._v("Tour")])])
            ]),
            _c("li", [
              _c("a", { attrs: { href: "#showcase" } }, [
                _c("p", [_vm._v("Showcase")])
              ])
            ]),
            _c("li", [
              _c("a", { attrs: { href: "#contact" } }, [
                _c("p", [_vm._v("Participate")])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col" }, [
          _c("h1", [_vm._v("Partners")]),
          _c("ul", [
            _c("li", [
              _c(
                "a",
                { attrs: { href: "https://www.orbe.mobi", target: "_blank" } },
                [_c("p", [_vm._v("Orbe")])]
              )
            ]),
            _c("li", [
              _c(
                "a",
                { attrs: { href: "https://www.ircam.fr/", target: "_blank" } },
                [_c("p", [_vm._v("Ircam")])]
              )
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "https://cosima.ircam.fr/", target: "_blank" }
                },
                [_c("p", [_vm._v("CoSiMa")])]
              )
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://rapidmix.goldsmithsdigital.com/",
                    target: "_blank"
                  }
                },
                [_c("p", [_vm._v("Rapid Mix")])]
              )
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.agence-nationale-recherche.fr/",
                    target: "_blank"
                  }
                },
                [_c("p", [_vm._v("ANR")])]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "col" }, [
          _c("h1", [_vm._v("Community")]),
          _c("ul", [
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.facebook.com/orbemobi",
                    target: "_blank"
                  }
                },
                [_c("p", [_vm._v("FaceBook")])]
              )
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://twitter.com/OrbeMobi",
                    target: "_blank"
                  }
                },
                [_c("p", [_vm._v("Twitter")])]
              )
            ]),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.linkedin.com/company-beta/460814/",
                    target: "_blank"
                  }
                },
                [_c("p", [_vm._v("LinkedIn")])]
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "back-to-top" }, [
      _c("a", { attrs: { href: "#top" } }, [
        _c("div", { staticClass: "top-icon" }),
        _c("p", [_vm._v("Back To Top")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }