var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-panel" },
    [
      _c(
        "div",
        { staticClass: "criteria-bar" },
        [
          _c("div", { staticClass: "arrange-by" }, [
            _c(
              "span",
              { staticClass: "button active" },
              [_c("icon", { attrs: { name: "grid" } })],
              1
            ),
            _c(
              "span",
              { staticClass: "button" },
              [_c("icon", { attrs: { name: "list" } })],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "sort" },
            [
              _vm._v("Sorted by"),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sortBy,
                      expression: "sortBy"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.sortBy = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "date" } }, [_vm._v("Date")]),
                  _c("option", { attrs: { value: "name" } }, [_vm._v("Name")]),
                  _c("option", { attrs: { value: "owner" } }, [_vm._v("Owner")])
                ]
              ),
              _c("icon", {
                staticClass: "arrow",
                attrs: { name: "arrow-select" }
              })
            ],
            1
          ),
          _c("search-bar", { on: { change: _vm.searchResult } })
        ],
        1
      ),
      _c("div", { staticClass: "filter" }, [
        _c(
          "div",
          {
            staticClass: "tab",
            class: { active: _vm.currentFilter == "all" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.filterBy("all")
              }
            }
          },
          [_c("span", [_vm._v("All")])]
        ),
        _c(
          "div",
          {
            staticClass: "tab",
            class: { active: _vm.currentFilter == "screen" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.filterBy("screen")
              }
            }
          },
          [_c("span", [_vm._v("Screens")])]
        ),
        _c(
          "div",
          {
            staticClass: "tab",
            class: { active: _vm.currentFilter == "widget" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.filterBy("widget")
              }
            }
          },
          [_c("span", [_vm._v("Widgets")])]
        ),
        _c(
          "div",
          {
            staticClass: "tab",
            class: { active: _vm.currentFilter == "process" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.filterBy("process")
              }
            }
          },
          [_c("span", [_vm._v("Processes")])]
        )
      ]),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "add rounded" }, [
                _c("span", { staticClass: "ico" }),
                _c(
                  "div",
                  { staticClass: "select" },
                  _vm._l(_vm.state.types, function(type, i) {
                    return _c(
                      "span",
                      {
                        staticClass: "option",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.createTemplate(i)
                          }
                        }
                      },
                      [_vm._v(_vm._s(type))]
                    )
                  }),
                  0
                )
              ]),
              _vm._l(_vm.templates, function(template, i) {
                return _c(
                  "div",
                  {
                    staticClass: "item rounded",
                    class: {
                      selected: _vm.selected == template.template,
                      hide: _vm.isHidden(template)
                    },
                    attrs: { id: template.template },
                    on: {
                      click: function($event) {
                        return _vm.selectTemplate(i)
                      }
                    }
                  },
                  [
                    _c("icon", {
                      staticClass: "rounded",
                      attrs: {
                        name: "template-item-" + template.type.toLowerCase()
                      }
                    }),
                    _c("div", { staticClass: "name rounded" }, [
                      _c("span", [_vm._v(_vm._s(template.name))])
                    ]),
                    _c("div", { staticClass: "flags" }, [
                      _vm.isLinked(template.template)
                        ? _c("div", { staticClass: "button linked" })
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "actions" }, [
                      !_vm.isLinked(template.template)
                        ? _c("div", {
                            staticClass: "button link",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.linkTemplate(template)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.isLinked(template.template)
                        ? _c("div", {
                            staticClass: "button unlink",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.unlinkTemplate(template)
                              }
                            }
                          })
                        : _vm._e(),
                      _c("div", {
                        staticClass: "button duplicate",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.duplicateTemplate(template)
                          }
                        }
                      }),
                      _vm.canBeDeleted(template)
                        ? _c("div", {
                            staticClass: "button delete",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deleteTemplate(template)
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ],
                  1
                )
              })
            ],
            2
          ),
          _vm.waiting ? _c("spinner") : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "footer" }),
      _vm.popup.open
        ? _c("popup-window", {
            attrs: {
              component: _vm.popup.component,
              settings: _vm.popup.settings
            },
            on: { "popup-close": _vm.popup.callback }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }