var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checkRights()
    ? _c("span", { staticClass: "textinput-ctn" }, [
        _c("span", {
          staticClass: "secret-eye-icon",
          on: {
            click: function($event) {
              return _vm.switchVisibility()
            }
          }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value"
            }
          ],
          ref: "inputPassword",
          staticClass: "value",
          attrs: {
            type: "password",
            placeholder: _vm.placeholder,
            disabled: _vm.field.disabled
          },
          domProps: { value: _vm.value },
          on: {
            focusout: function($event) {
              return _vm.valueUpdated(_vm.field)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.value = $event.target.value
            }
          }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }