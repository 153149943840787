import React from 'react';
import ChartsUi from '../ChartsUi';
import { VictoryChart } from 'victory';
import { VictoryArea } from 'victory';
import { VictoryAxis } from 'victory';

export default class StreamGraph extends ChartsUi {
    
    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            display : 'block',
        });
    }

    
    
    render() {


        let width = parseInt(this.props.styles.width);
        let height = parseInt(this.props.styles.height);

        // PROPS FOR CHART
        let otherChartProps = {
            width : width,
            height: height,
            standalone: false,
            padding: 0,
            //labels : ( (d) => "" ),
            //interpolation : this.props.stream.interpolation,
            //animate : this.chartProps.animate, // doesn't work ...
        };

        let domainY = this.props.stream.domain;
        if( domainY && domainY.x && domainY.y ){
            otherChartProps.domain = { y : [ domainY.x , domainY.y ] };
        }

       // this.chartProps = Object.assign( {}, this.chartProps, this.props.chart, otherChartProps );
        

        let domStyle  = { 
            paddingTop: "3px"
         };

        this.mergedStyle = Object.assign( {}, this.mergedStyle, domStyle );

        let noAxis = { axis: {stroke: "none"}, ticks: {stroke: "none"}, tickLabels: {fill: "none"},  grid: {stroke: "none"}  }
        
        

        let streamData =this.props.streamData ? this.props.streamData:  [ ];
        let colors = this.props.streamColors ? this.props.streamColors : [ "#006064", "#00796B", "#8BC34A", "#DCE775",  "#FFF59D", "#F4511E", "#c33409" ];
        

        return this.wrapHelper(  
            <svg width={width} height={height} {...this.otherEvents} {...this.props.dom} style={this.mergedStyle} >
                <VictoryChart {...otherChartProps} >
                    <VictoryAxis style={noAxis} crossAxis={false} />
                    { streamData.map( (d, i )=> {
                        let key = "stream_"+i;
                        return (
                            <VictoryArea key={key} data={d} interpolation={this.props.stream.interpolation}  style={{data: {fill: colors[(colors.length-1)-i] }}}  />
                        );
                    }) }
                </VictoryChart>
            </svg>
        );

    }

}