import ActionAbstract from '../ActionAbstract'
import * as helper from '../../helper'

class Unlink extends ActionAbstract {

    static get ID() {
        return 'actions:template:unlink'
    }

    run(state) {

        let id    = this.getParam('id')
        let index = state.project.value.template.indexOf(id) // the template index in project list
        let doc   = -1                                       // the document index in project templates list


        // do nothing if used in schematic
        if ( this.isUsed(id, state.project) ) {
            let name  = this.getParam('name')
            this.trigger('main:modal:openModal', {
                title: 'Unlink template refused',
                text: `The template '${name}' is currently used in project.`,
                continueButton: 'OK',
                cancelButton: ''
            })
            return false
        }


        // if linked in project
        // get the template document index (should be the same)
        if (index != -1 && state.project.templates) {
            state.project.templates.some((template,key) => {
                if (template.value._id == id) {
                    doc = key
                    return true
                }
            })
        }

        // execute the next action if required
        // next: {action:'*', param:{}}
        if (this.getParam('next'))
            this.trigger(this.getParam('next').action, this.getParam('next').param)

        // refresh editor interface
        if (doc != -1) {
            this.trigger('editor:menu:filter', { type: state.editor.menu.type, id: state.editor.targetId })
            if (state.workspace.id == id) this.trigger('main:deselectAll')
        }

        // unlink the template from the current project
        return doc == -1 ? false : this.composeState(state, {
            project: {
                templates: this.arraySplice(doc),
                value: {
                    template: this.arraySplice(index)
                }
            }
        })
    }

    /**
     * Returns whether the specified template is used somewhere in project or node schematic or not.
     * @param {boolean} id - The template ID.
     * @param {Object} project - The current project.
     * @returns {boolean}
     */
    isUsed(id, project) {
        let blocks = helper.block.getBlocksByTemplateId(project,id)
        return blocks && blocks.length > 0
    }

}

export default Unlink