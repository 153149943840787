<!-- Bloc vue -->
<template lang="pug">
div.classic-bloc(v-bind:class="{'instance':isInstance, 'template':isTemplate, 'current':isCurrent, 'deprecated': isDeprecated, 'buildOnly': isBuildOnly }", :key="family.length")
	div.bloc
		span.builOnlyBull(v-if="isBuildOnly") !
			span.buildOnlyText The module is only available for built applications.
		div.title
			span
				span.icon-ctn
					icon(:name="iconName")
				span.type {{displayTypeName}}
				span.name {{bloc.custom.compositeName}} {{bloc.value.name}}
		div.content
			div.plugs(v-bind:class="{'empty': isEmpty }")

				div.plug-list.output-list
					div(v-for="(connexion,name) of connexions")
						field(v-if="connexion.out", :app-state="appState", :connexion="connexion", :fieldname="name", side="output", :initial-bloc="bloc" )
						//div.plug-bt(v-if="connexion.out", v-bind:class="[connexion.type, 'output', name, {'selected':selected==name+'_out'}]", v-on:click.stop="linkPlug(connexion.type, 'output', name)")
							span.name(v-if="connexion.label") {{connexion.label | truncate ('12') }}
							span.name(v-else) {{name | truncate('12') }}
							span.value(v-if="connexion.type!='state' && connexion.value !== null")
								span {{connexion.value}}
							div.anchor

				div.plug-list.input-list
					div(v-for="(connexion,name) of connexions")
						field(v-if="connexion.in", :app-state="appState", :connexion="connexion", :fieldname="name", side="input", :initial-bloc="bloc" )
						//div.plug-bt(v-if="connexion.in", v-bind:class="[connexion.type, 'input', name, {'selected':selected==name+'_in'}]", v-on:click.stop="linkPlug(connexion.type, 'input', name)")
							span.value(v-if="connexion.type!='state' && connexion.value !== null")
								span {{connexion.value}}
							span.name(v-if="connexion.label") {{connexion.label | truncate('12') }}
							span.name(v-else) {{name | truncate('12') }}
							div.anchor

				div.clear

	div.bottom-menu
		div.action-bts
			div.action-bt.bt-inspect(v-on:click.stop.prevent="inspectBloc")
		div.action-bts
			div.action-bt.bt-duplicate-white(v-on:click.self="duplicateBloc")
		div.action-bts
			div.action-bt.bt-export-white(v-on:click.stop.prevent="exportBloc")
		div.action-bts
			div.action-bt.bt-trash-white(v-on:click.stop.prevent="deleteBloc")

</template>

<script>

// STORE
import actionManager from '../../../../actions';
import * as helper from '../../../../helper';
import Icon from  '../../../common/Icon.vue';

import ClassicBlocField from  './ClassicBlocField.vue';

export default {

	props: ['bloc', 'appState', 'blocTypeName', 'blocTypeIcon', 'family', 'workspace'],

	components: { field : ClassicBlocField, Icon },

	data() {
		return {
		}
	},
	mounted: function(){
		actionManager.trigger('block:updateAnchorPositions', { id : this.bloc.value._id } );
	},

	destroyed : function(){

	},

	computed: {
		'isEmpty': function(){
			if( !this.connexions )
				return true;

			let ckeys = Object.keys(this.connexions);
			if( ckeys.length == 0 ) return true;

			let inLenght = 0;
			let outLenght = 0;
			ckeys.forEach( (ckey)=> {
				let c = this.connexions[ckey];
				if( c.in === true ) inLenght++;
				if( c.out === true ) outLenght++;
			} );

			if( inLenght == 0 && outLenght == 0 )
				return true;

			return false;
		},

		'isInstance': function(){
			if( this.bloc.value.type === "process"){
				// The bad named 'template' name value is defined in blocks.json (BLOCK_PROCESS), should be 'instance' instead.
				// @todo:fixme: find a way to renamed this property without breaking projets, or leave as this.
				let templateField = this.bloc.value.fields.find((f) => { return f.name === "template" } );
				if( templateField && templateField.value === true )
					return true;
			}
			return false;
		},

		'isTemplate': function(){
			return this.bloc.value.type === "template-process";
		},

		'isCurrent': function(){
			return this.blocTypeName.includes('Current') && this.bloc.value.level !== "process";
		},

		'isDeprecated': function(){
			return this.bloc.value.deprecated ? this.bloc.value.deprecated : null;
		},

		'isBuildOnly': function(){
			return this.bloc.value.buildOnly ? this.bloc.value.buildOnly : null;
		},

		'displayTypeName': function() {
			if(this.bloc.value.deprecated){
				return `${this.blocTypeName} (depre.)`
			} else {
				return this.blocTypeName;
			}
		},

		'iconName': function(){
			if( this.bloc.value.type === "process" && this.isTemplate === true ){
				return "process-instance";
			}
			if( this.bloc.value.level === "process" && this.blocTypeIcon != null ){
				return this.blocTypeIcon;
			}
			if(this.bloc.value.level !== "process" && this.bloc.value.type.includes('space-')){
				return 'current';
			}
			return this.bloc.value.type.toLowerCase()
		},

		'connexions' : function(){
			let valueFields = {}, self = this;
			this.bloc.value.fields.forEach((f) => {

				let val = f.value; // @todo : to update this computed function, we need to watch the field value

				if (self.bloc.custom.fields[f.name] && self.bloc.custom.fields[f.name].display) {
					valueFields[f.name] = {
						"id": this.bloc.value._id,
						"in": f.in,
						"out": f.out,
						"type": 'field'
					};
				}
			});

			if( this.bloc.custom.states ){
				let states = Object.keys( this.bloc.custom.states );
				states.forEach( (stateName) => {
					let state =  this.bloc.custom.states[stateName];
					valueFields[stateName] = {
						"id": this.bloc.value._id,
						"in": state.in,
						"out": state.out,
						"type": 'state'
					};

				});
			}
			return valueFields;
		},

	},

	methods:{

		inspectBloc: function() {
			actionManager.trigger("inspector:load", {targetId: this.bloc.value._id });
			actionManager.trigger('workspace:update', { id: this.workspace.id, target: this.workspace.workspace });
		},

		duplicateBloc: function() {
			actionManager.trigger('block:copy', {blocks : [this.bloc.value._id], duplicate: true});
		},

        deleteBloc: function(){
			actionManager.trigger('block:deleteBlock', {id : this.bloc.value._id} );
		},

		exportBloc: function(){
			actionManager.trigger('block:copy', {blocks : [this.bloc.value._id], saveToFile: true, fileName: "nodal-export-"+this.appState.project.value.name+"-"+this.bloc.value.name.replace(' ', '-')+".json" } );
		}

	}

}

</script>

<style lang="stylus">

.classic-bloc
	display: flex
	flex-flow: column
	align-items: flex-end
	width: 230px
	position: absolute
	z-index: 1

	&.dragging
		z-index: 1000

	.bloc
		background-color: var(--moduleBoxColor)
		box-shadow: 0 0 10px var(--shadowColor)
		font-family: 'DinRegular', 'ComfortaaLight'
		border-radius: 8px
		color: #707070
		width: 100%
		min-height: 78px !important

	.title
		display: block
		background-color: var(--moduleTitleColor)
		font-family: 'DinRegular', 'ComfortaaLight'
		font-size: 14px
		color: var(--moduleTextColor)
		padding: 0px 10px
		border-radius: 8px 8px 0px 0px
		z-index: 1
		position: relative
		height: 36px
		line-height: 38px
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		transition: linear all .1s !important			

		.type
			font-family: 'DinBold', 'ComfortaaBold'
			font-size: 14px
			text-transform: capitalize
			margin-right 3px
			margin-left: 20px;

		.name
			font-family: 'DinRegular', 'ComfortaaLight'
			font-size: 14px
			color: var(--moduleTextColor)

	.ico-drag
		display: block
		position: absolute
		right: 0
		top: 0
		width: 20px
		height: 20px
		background-color: #333

	.content
		display: block

		&.fake
			position: relative
			width:123%
			padding-bottom: 77%
			opacity: 0

	&.experience
		border: 0px
		background-color: transparent
		.title
			border: 1px solid #58585B

	&.selected
		.title
			background-color: #F49E75
			.name
				color: white
		&.buildOnly
			.title
				background: linear-gradient(to right, #F49E75, #FDECE3);

	&.instance
		border: 1px solid #A5A5A5;

		.title
			background-color: #A5A5A5

	&.template
		//border: 1px solid #575756
		.title
			margin-top: 0px
			background-color: #EDEDED
			.type
			.name
				color: #575756
		.icon
			background-color: #575756 !important

		&.selected
			//border: 1px solid #F49E75
			.title
				background-color: #FCF2E9
				.type
				.name
					color: #F49E75
			.icon
				background-color: #F49E75 !important

	&.current

		.title
			/*background-color: #f8f8f8
			border-top: 1px dashed #58585b
			border-bottom: 1px dashed #58585b*/
			background-image: url(../../../../../assets/textureCurrent.png)
			background-color: var(--currentStepColor)
			background-size: cover
			.type
			.name
				color: #58585b
		.icon
			background-color: #58585b !important

		&.selected
			.title
				background-color: #fefbf6
				border: 1px dashed #F49E75
				.type
				.name
					color: #F49E75
			.icon
				background-color: #F49E75 !important

	&.experience.selected
		border: 0px
		.title
			border: 1px solid #FCF2E9
	
	&.buildOnly
		.title
			background: linear-gradient(to right, #707070, #E2E2E2);

		.builOnlyBull
			right: 9px
			top: -12px
			z-index: 2
			background: #f77963!important
			color: white
			position: absolute
			width: 22px
			height: 22px
			line-height: 28px
			border-radius: 20px
			text-align: center
			cursor: pointer
			font-family: 'DinBold'
			font-size: 14px
			transition: linear opacity .1s
			box-shadow: 0 0 5px rgba(0,0,0,.1)
		
			&:hover
				.buildOnlyText
					display: flex
					color: var(--moduleTitleColor)

			.buildOnlyText
				display: none
				position: absolute
				top: -96px
				right: -123px
				font-family: 'DinRegular'
				color: black
				line-height: 20px
				font-size: 14px
				justify-content: center
				height: 56px
				width: 123px
				background: white
				padding: 15px
				box-shadow: 0 0 39px rgba(0, 0, 0, 0.05)
				border-radius: 9px

				&:after
					content: ""
					position: absolute
					right: 124px
					bottom: -7px
					border-top: 9px solid white
					border-right: 9px solid transparent
					border-left: 9px solid transparent
					border-bottom: none

	&.deprecated
		.title
			background-image: url(../../../../../assets/textureDeprecated.png) !important
			background-size: contain !important

		.content
			.plugs
				.plug-list
					.plug-bt
						background-image: url(../../../../../assets/textureDeprecated.png) !important
						background-size: contain !important
						/* color: white */
						&:hover, &.selected
							background-image: url(../../../../../assets/textureDeprecated.png) !important
							background-size: contain !important

						.anchor
							background-color: #F77963 !important
					&.input-list
						float: left
						text-align: left

						.plug-bt
							border-radius: 0px 4px 4px 0px;

							.value
								float: right
								text-align: right

							.anchor
								left: -6px
								border-radius: 4px 0px 0px 4px;
					&.output-list
						float: right
						text-align: right

						.plug-bt
							border-radius: 4px 0px 0px 4px;

							.value
								float: left

							.anchor
								right: -6px
								border-radius: 0px 6px 6px 0px;

	
	
	
	.icon-ctn
		.icon
			width: 16px
			height: 16px
			position: absolute
			left: 8px
			top: 18px
			background-color: white

	.plugs
		margin-top: 8px
		margin-bottom: 4px

		&.empty
			margin:0px

		.plug-list
			display: inline-block
			width: 48%
			font-family: 'DinRegular'
			font-size: 10px
			color: #58585B

			.plug-bt
				display: block
				padding: 6px 8px
				background-color: var(--moduleInputColor)
				margin-bottom: 5px
				position: relative
				cursor: pointer
				transition: linear all .1s !important

				&:before
					content: ''
					display: block
					width: 100%
					height: 100%
					position: absolute
					top: 0
					left: 0
					right: 0
					bottom: 0
					background-color: rgba(0,0,0,0)

				.value
					display: inline-block
					width: 35px
					padding: 1px 3px
					background-color: var(--moduleValue)
					color: var(--inputTextColor)
					font-size: 9px
					height: 11px
					line-height: 11px
					text-align: left
					overflow: hidden
					white-space: nowrap
					border-radius: 4px

				.name
					width: 79%
					overflow: hidden
					text-transform: capitalize
					color: #707070
					font-size: 11px

				.anchor
					content:''
					display: block
					background-color: var(--moduleTitleColor)
					width: 5px
					height: 16px
					position: absolute
					top: 50%
					transform: translate(0, -50%)

				&:hover, &.selected
					background-color: #fce9dd

					.anchor
						background-color: #f59a7a
				&.deprecatedField
						background-color: rgba(255, 0, 0, 0.4)
						color: white
						&:hover, &.selected
							background-color: rgba(255, 0, 0, 0.7)

						.anchor
							background-color: red
			&.input-list
				float: left
				text-align: left

				.plug-bt
					border-radius: 0px 20px 20px 0px;

					.value
						float: right
						text-align: right

					.anchor
						left: -6px
						border-radius: 4px 0px 0px 4px;
				&.deprecatedField
					background-color: rgba(255, 0, 0, 0.4)
					color: white
					.anchor
						background-color: rgba(255, 0, 0, 0.4)
			&.output-list
				float: right
				text-align: right

				.plug-bt
					border-radius: 20px 0px 0px 20px;

					.value
						float: left

					.anchor
						right: -6px
						border-radius: 0px 3px 3px 0px;

	.bottom-menu
		display: none
		background-color: var(--bgColor)
		border-radius: 20px
		box-shadow: 0px 0px 10px var(--shadowColor)
		margin-top: 10px

		.action-bts
			display:inline-block
			vertical-align: middle;
			background-color: var(--breadColor)
			border-radius: 20px
			width: 20px
			height: 20px
			margin: 3px
			transition: linear all .1s !important
			.action-bt
				width: 100%
				height: 100%
				background-size: auto 50%
				background-repeat: no-repeat
				background-position: center center
				cursor: pointer

	.puzzle-part
		position: absolute
		width: 100%
		padding: 2px
		padding-bottom: 90%
		top: 0px
		left: -2px
		z-index: 0
		.puzzle
			position: absolute
			width: 120%
			height: 100%

			fill: #FFFFFF

		&:after
			content: ""
			display: block
			width: 99%
			height: 65%
			position: absolute
			top: 30%
			left: 0px


		&.mobile
			&:after
				background-color: #E6E7E8
				-webkit-mask: url(../../../../assets/images/icons/mobile.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/mobile.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.tablet
			&:after
				background-color: #E6E7E8
				-webkit-mask: url(../../../../assets/images/icons/tablet.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/tablet.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.desktop
			&:after
				background-color: #E6E7E8
				-webkit-mask: url(../../../../assets/images/icons/desktop.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/desktop.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.light
			&:after
				background-color: #E6E7E8
				-webkit-mask: url(../../../../assets/images/icons/light.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/light.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.iot
			&:after
				background-color: #E6E7E8
				-webkit-mask: url(../../../../assets/images/icons/iot.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/iot.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain
		&.multiplatform
			&:after
				background-color: #E6E7E8
				-webkit-mask: url(../../../../assets/images/icons/desktop.svg) no-repeat 100% 100%
				mask: url(../../../../assets/images/icons/desktop.svg) no-repeat 100% 100%
				-webkit-mask-size: contain
				mask-size: contain


.classic-bloc
	&:hover
		z-index: 2
		.title
			background-color: var(--selectedColor)
		.bottom-menu
			display: inline-block

.classic-bloc:hover .plugs .plug-list .plug-bt
	background: var(--modalColor)

.trigger
	.classic-bloc
		border-radius: 16px 8px 8px 16px

		.title
			border-radius: 16px 8px 0px 0px

.widget.draggable-bloc
	.classic-bloc
		border-radius: 8px
		.bloc
			min-height: 0px !important
		.title
			border-radius: 8px

.trigger
	.classic-bloc
		.bloc
			width: 180px
			border-radius: 8px8px 8px 56px

		.title
			border-radius: 8px 8px 0px 0px;
</style>
 