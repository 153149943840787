var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.subInspector
      ? _c(
          "div",
          [
            _c("sub-topic", {
              attrs: {
                project: _vm.project,
                target: _vm.target,
                bloc: _vm.subInspector
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }