import ActionManager from '../ActionManager';

import Load from './Load';
ActionManager.register(Load, ['stores:MainStore']);

import LoadObjInspector from './LoadObjInspector';
ActionManager.register(LoadObjInspector, ['stores:MainStore']);

import LoadArrayInspector from './LoadArrayInspector';
ActionManager.register(LoadArrayInspector, ['stores:MainStore']);

import UpdateName from './UpdateName';
ActionManager.register(UpdateName, ['stores:MainStore']);

import UpdateSlug from './UpdateSlug';
ActionManager.register(UpdateSlug, ['stores:MainStore']);

import UpdateValue from './UpdateValue';
ActionManager.register(UpdateValue, ['stores:MainStore']);

import UpdateArray from './UpdateArray';
ActionManager.register(UpdateArray, ['stores:MainStore']);

import ConnectionChange from './ConnectionChange';
ActionManager.register(ConnectionChange, ['stores:MainStore']);

import RepeatedForm from './RepeatedForm';
ActionManager.register(RepeatedForm, ['stores:MainStore']);

import UpdateState from './UpdateState';
ActionManager.register(UpdateState, ['stores:MainStore']);

import UpdateHomeScreen from './UpdateHomeScreen';
ActionManager.register(UpdateHomeScreen, ['stores:MainStore']);

import UpdateProcessAutoStart from './UpdateProcessAutoStart';
ActionManager.register(UpdateProcessAutoStart, ['stores:MainStore']);

import UpdateAccordion from './UpdateAccordion';
ActionManager.register(UpdateAccordion, ['stores:MainStore']);

import UpdateQueryMode from './UpdateQueryMode';
ActionManager.register(UpdateQueryMode, ['stores:MainStore']);

import UpdateApplyFieldQueryMode from './UpdateApplyFieldQueryMode';
ActionManager.register(UpdateApplyFieldQueryMode, ['stores:MainStore']);

import UpdateMemoryLink from './UpdateMemoryLink';
ActionManager.register(UpdateMemoryLink, ['stores:MainStore']);


import './trigger';
import './widgets';