<template lang="pug">
	div.project-item(
		v-if="project.custom && project.custom.display"
		@click="projectClickHandler()"
		:style="[ cover ? { 'background-image': `url(${cover})`, 'background-size': 'cover' } : '' ]"
	)
		div.shared-icon(v-if="project.value.users.length > 0")
			span.projUsrType.icon.icon_owner(v-if="project.value.owner.includes(currentUser)")
			span.projUsrType.icon.icon_reader(v-if="project.value.viewers !== undefined && project.value.viewers.includes(currentUser.replace('org.couchdb.user:', ''))")
			span.projUsrType.icon.icon_author(v-if="project.value.editors !== undefined && project.value.editors.includes(currentUser.replace('org.couchdb.user:', ''))")
		div.desc-ctn
			div.title {{project.value.name}}
			div.author {{project.value.owner.replace('org.couchdb.user:', '')}}
			div.date.created(v-if="createdDate") {{ createdDate }}
			div.date.updated(v-if="updatedDate") {{ updatedDate }}
		div.actions-bts
			div.action-bt.bt-trash(v-if="project.value.owner == currentUser", v-on:click.stop.prevent="removeProject")
			div.action-bt.bt-share(v-if="project.value.owner == currentUser", v-on:click.stop.prevent="shareProject")
			div.action-bt.bt-duplicate(v-if="project.value.owner == currentUser", v-on:click.stop.prevent="duplicateProject")
</template>

<script>
	import actionManager from '../../actions';
	import Icon from '../common/Icon.vue';

	export default {
		props: ['project', 'projectIndex', 'currentUser'],
		components: { Icon },
		computed: {
			cover() {
				if (this.project && this.project.value && this.project.value.cover) {
					return this.project.value.cover;
				}

				return null;
			},
			createdDate() {
				if (this.project && this.project.value) {
					return this.formatDate(this.project.value.created);
				}

				return null;
			},
			updatedDate() {
				if (this.project && this.project.value) {
					return this.formatDate(this.project.value.updated);
				}

				return null;
			},
		},
		methods: {
			removeProject() {
				actionManager.trigger('main:modal:openModal', {
					title:`Delete ${this.project.value.slug}`,
					icon: 'delete',
					share: false,
					text:"Do you want to delete this project ?",
					cancelButton: 'Cancel',
					continueButton: 'Yes',
					nextAction:'project:deleteProject',
					nextActionParam:{ projectId: this.project.value.project },
				});
			},
			shareProject() {
				actionManager.trigger('main:modal:openModal', {
					title:`Share ${this.project.value.name}`,
					icon: 'share',
					share: true,
					text: '',
					cancelButton: '',
					continueButton: 'none',
				});

				this.$emit('send-indexToProjectItems', this.projectIndex);
			},
			duplicateProject() {
				actionManager.trigger('main:modal:openModal', {
					title:`Duplicate ${this.project.value.name}`,
					icon: 'duplicate',
					duplicate: true,
					share: false,
					text: '',
					cancelButton: 'Cancel',
					continueButton: 'ok',
				});

				this.$emit('send-indexToProjectItems', this.projectIndex);
			},
			projectClickHandler() {
				let userType;
				const user = this.currentUser.replace('org.couchdb.user:', '');

				if (this.project.value.viewers) {
					userType = this.project.value.viewers.includes(user) ? 'viewer' : 'editor';
				} else {
					// Retrocompatibility for projects shared directly from couchDB.
					userType = 'editor';
				} 

				if (this.project.value.owner.includes(this.currentUser)) {
					userType = 'owner';
				}

				actionManager.trigger('project:openProject', {
					projectId : this.project.value.project,
					currentUserType: userType,
					currentUser: this.currentUser,
					owner: this.project.value.owner,
				});
			},
			formatDate(timestamp) {
				const date = new Date(timestamp);

				if (!timestamp) {
					return null;
				}

				return ('000' + date.getFullYear()).slice(-4)
					+ '/' + ('0' + (date.getMonth() + 1)).slice(-2)
					+ '/' + ('0' + date.getDate()).slice(-2);
			},
		},
	}
</script>

<style lang="stylus">
	.project-item
		display: inline-block
		font-family: 'DinBold'
		width: 250px
		height: 250px
		position: relative
		margin: 10px
		box-shadow: 0 0 10px var(--shadowColor)
		cursor: pointer
		transition: all 0.1s ease-in-out
		background-image: url(../../assets/images/bg_noCover.png)
		background-position: center center
		background-size: 120%
		border-radius: 10px
		overflow: hidden
		&:hover
			background-color: rgba(243, 154, 116, 0.5)
			background-blend-mode: multiply
			.desc-ctn
				background-color: var(--selectedColor)
				color: var(--bgColor)
				.date
					background-color: var(--bgColor)
					color: var(--textColor)
		.desc-ctn
			box-sizing: border-box
			position: absolute
			bottom: 0
			left: 0
			width: 100%
			height: 93px
			background-color: var(--bgColor)
			color: var(--textColor)
			padding: 10px
			.title
				font-size: 20px
				line-height: 20px
				text-overflow: ellipsis
				white-space: nowrap
				overflow: hidden
			.author
				font-family: 'DinRegular'
				font-size: 14px
				padding-bottom: 6px
				&:before
					content: 'by '
			.date
				display: inline-flex
				flex-direction: column
				margin-right: 4px
				padding: 5px 12px 3px 5px
				background-color: #ebebeb
				border-radius: 4px
				font-size: 13px
				justify-content: center
				line-height: 9px
		.shared-icon
			display: inline-flex
			width: 40px
			height: auto
			background-image: url(../../assets/images/icons/label_shared.svg)
			background-size: contain
			background-repeat: no-repeat
			background-position: center center
			position: absolute
			top: 0px
			right: 10px
			justify-content: center
			box-sizing: border-box
			&:before
				content: ''
				position: relative
				display: flex
				position: relative
				width: 100%
				padding-top: 100%
			.projUsrType
				position: absolute
				display: flex
				height: 20px
				width: 20px
				background-color: white
				top: 14px
		div.actions-bts
			position: absolute
			top: 10px
			left: 10px
			z-index: 10
			visibility:hidden
			opacity: 0
			transition: all 0.2s ease-in-out;
			.action-bt
				position: relative
				display: block
				width: 25px
				height: 25px
				background-color: white
				border-radius: 25px
				float:right
				margin-right: 5px
				box-shadow 1px 1px 3px rgba(0,0,0,0.1)
		&:hover
			div.actions-bts
				visibility:visible
				opacity: 1
		.bt-share
			background: #ffffff !important
			background-image: url(../../assets/images/btnShare.png) !important
			background-size: 25px !important
			background-position: center !important 
			background-repeat: no-repeat !important
			transition: linear all .1s !important
			&:hover
				background-color: var(--inspectorTargetOverColor) !important
	.updated
		&:before
			content: 'UPDATED:'
			font-size: 10px
			padding-bottom: 3px
	.created
		&:before
			content: 'CREATED:'
			font-size: 10px
			padding-bottom: 3px
</style>
