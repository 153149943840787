<template lang="pug">
div.topmenu
	div.logo(v-if="!appState.project")
		p Nodal.Studio authoring platfom
	div.buttons(v-if="appState.project")
		div.button(v-on:click="backHome")
			span.icon
				icon(name="HOME")
				span.title Home
		div.button(v-on:click="clickSaveHandler")
			span.icon
				icon(name="SAVE")
				span.title Save
		//
			div.button(v-on:click="clickUndo")
				span.icon
					icon(name="UNDO")
					span.title Undo
		//
			div.button(v-on:click="clickRedo")
				span.icon
					icon(name="REDO")
					span.title Redo

		div.button(v-on:click="clickLibrary")
			span.icon
				icon(name="LIBRARY")
				span.title Library

		div.button(v-on:click="clickTemplate")
			span.icon
				icon(name="TEMPLATE")
				span.title Template

		a.button(href="/#/doc", target="_blank")
			span.icon
				icon(name="DOC")
				span.title Documentation

		div.button(v-on:click="openSessionExperience", v-bind:class="{'unclickable': !projectHasExperience}")
			span.icon
				icon(name="SESSION")
				span.title Session Inspector

		div.exp-play
			div.select
				span.icon-play(v-on:click="clickPlayExperience", v-bind:class="{'unclickable': !projectHasExperience}")
					icon(name="play-exp")
				select#exp(v-if="appState.project != undefined && appState.project.custom != undefined && appState.project.custom.children != undefined", v-model="value")
					option(v-for="exp of appState.project.custom.children", v-bind:value="exp.value._id") {{exp.value.name}}
				span#icon-arrow-down
</template>

<script>
// STORE
import * as helper from '../../helper';
import actionManager from '../../actions';
import Icon from  './Icon.vue';

export default {

	props: ['appState'],

	components: {Icon },

	data() {
		return {
			value: null,
		}
	},

	created: function(){
		this.value = this.appState.project ? this.appState.project.custom ? this.appState.project.custom.children[0] ? this.appState.project.custom.children[0].value._id : null : null : null;
	},

	computed: {
		updateValue: function(){
			if(this.appState.breadcrumb && this.appState.breadcrumb.arborescence){
				let value = null;
				this.appState.breadcrumb.arborescence.forEach( (val) => {
                	if (val.value.type=='experience') {
                		value=val.value._id;
                	}
            	});
				if (value==null) {
					value = this.appState.project ? this.appState.project.custom ? this.appState.project.custom.children[0] ? this.appState.project.custom.children[0].value._id : null : null : null;
				}
				actionManager.trigger('editor:selectXP', {id:this.value});
				return value;
			}
			return null;
		},
		projectHasExperience: function(){
			if(this.appState.project.custom && this.appState.project.custom.children.length !== 0){
				let experiences = this.appState.project.custom.children.filter(child => child.value.type === "experience")
				if(experiences.length > 0){
					return true
				} else {
					return false
				}
			}

		}
	},

	watch:{
		'updateValue' : function( newVal, oldVal ){
			this.value = newVal;
		}
	},

	methods:{
		clickSaveHandler: function(){

			if (this.appState.project.currentUserType !== "viewer"){
				actionManager.trigger('main:modal:openModal', {
					title: 'Are you sure?',
					text: 'This save will update every node in your project. Please make sure no one is currently working on it.',
					nextAction: 'project:save',
					icon: 'save',
				});
			} else {
				actionManager.trigger('main:modal:openModal', {
					title: 'Viewer',
					text: 'You are currently a viewer of this project, which means you cannot save changes. Please contact the project owner if you would like to be added to the editors.',
					icon: 'icon_reader',
					cancelButton: '',
				});
			}
			//actionManager.trigger('project:save');
			// stores.project.saveProject( this.project );
		},

		/*clickUndo: function(){							// @Todo
			actionManager.trigger('project:save');
		},

		clickRedo: function(){							// @Todo
			actionManager.trigger('project:save');
		},*/

		clickLibrary: function( ){
			actionManager.trigger('library:open');
		},

		clickTemplate: function( ){
			actionManager.trigger('template:open');
		},

		backHome : function(){
			actionManager.trigger('project:quitProject');
		},

		clickPlayExperience : function(){		// @Todo
			if(this.projectHasExperience){
				actionManager.trigger('main:openPreview', {blocId : this.value});
			}
		},

		openSessionExperience : function() {
			if(this.projectHasExperience && this.appState.project !== undefined && this.appState.project.value !== undefined) {
				actionManager.trigger('project:openSessionInspector', {id : this.appState.project.value._id, slug: this.appState.project.value.slug});
			}
		}
	}

}
</script>

<style lang="stylus">
.logo
	display: inline-flex
	justify-content: space-between
	width: 100vw

select#exp
	line-height: 40px
	height: 40px
	margin-top: 0

span#sortProjects
	.list
		display: inline-block
		width: 40px
		height: 40px
		background-size: 40px 40px
		background-position: center center
		background-repeat: no-repeat
		transform: translateY(0%)
		background-color: white
	select
		line-height: 40px
		height: 40px
		margin-top: 0
		width: auto
		text-align: center

.topmenu
	width: 100%
	height: 40px
	position: fixed
	top: 0
	left: 0
	background-color: var(--menuColor)
	box-shadow: 0 0 10px var(--shadowColor)
	color: var(--textColor)
	font-size: 16px
	z-index: 91

	p
		margin: 0
		padding: 0
		line-height: 40px
		vertical-align: middle
		margin-left: 10px
		font-family: 'DinBold'

	.buttons
		line-height: 33px
		vertical-align: middle

		a.button
			color: #58585b

		.button
			display: inline-block
			margin-left: 10px
			margin-right: 25px

			.icon
				width: 30px
				height: 30px
				position: absolute
				border-style: solid
				border-width: 0px
				top: 50%
				border-radius: 20px
				background-color: var(--breadColor)
				transition: all 0.1s ease-in-out

				span
					width: 24px
					height: 24px
					margin-top: 0px
					margin-left: 3px
					position: absolute
					border-style: solid
					border-width: 0px
					border-color: #ffffff
					background-color: #ffffff
					transition: all 0.3s ease-in-out

				.title
					white-space: nowrap
					line-height: 15px
					vertical-align: middle
					visibility: hidden
					background-color: #FFF
					text-align: center
					border-width: 0px
					border-style: solid
					border-radius: 4px
					position:absolute
					padding: 4px
					min-width: 40px
					width: auto
					height: 15px
					margin-top: 30px
					margin-left: -4px
					font-size: 14px
					z-index: 1
					opacity: 1
					transition: all 0s ease-in-out
					box-shadow: 0 0 20px rgba(0,0,0,.3)

			.icon:hover
				cursor:pointer
				background-color: var(--selectedColor)

				.title
					visibility: visible
					transition: all 0.3s ease-in-out

				span
					background-color: #fff
					transition: all 0.3s ease-in-out

		.exp-play
			display: inline-block
			right: 20px

			.select
				height: 30px
				width: 200px
				position: absolute;
				top: 50%;
				right: 10px
				background-color: var(--topSelectColor)
				border-radius: 20px
				border-style: solid
				border-width: 0px
				border-color: var(--textColor)
				line-height: 0px
				transform: translateY(-50%)
				font-family: 'DinBold'

				.icon-play
					width: 30px
					height: 30px
					position: absolute
					background-color: var(--breadColor)
					border-radius: 30px
					transition: linear all .1s !important
					&:hover
						background-color: var(--selectedColor)

					.icon
						width: 30px
						height: 30px
						position: absolute
						top: 50%
						background-color: #fff

					.icon:hover
						cursor:pointer

					&.unclickable:hover
						background-color: var(--saveColor)
						.icon:hover
							cursor: default

				#exp
					display: inline-block
					position: absolute
					height: 100%
					width: 85%
					margin-left: 35px
					line-height: 33px
					font-size: 16px
					border-style: none
					background-color: var(--topSelectColor)
					border-radius: 40px;

				#exp:hover
					cursor:pointer

				#exp:selected
					border-style: none

				#icon-arrow-down
					display: inline-block
					position: absolute
					z-index: 10
					background: var(--inspectorText)
					right: 5px
					width: 16px
					height: 30px
					-webkit-mask: url(../../assets/images/arrowSelector.svg) no-repeat 50% 50%
					mask: url(../../assets/images/arrowSelector.svg) no-repeat 50% 50%
					transition: linear all .1s !important
					background-size: contain
					background-repeat: no-repeat
					background-position: center

</style>
