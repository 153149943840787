import ActionAbstract from '../ActionAbstract';

class ToggleBreadcrumb extends ActionAbstract {

    static get ID() {
        return 'actions:main:toggleBreadcrumb';
    }

    run(state) {
        let status = (state.main && state.main.breadcrumbOpen) ;
        status = this.getParam('force') !== undefined ? this.getParam('force') : !status ;
        return this.composeState(state, {
            main: {
                breadcrumbOpen: status
            }
        });
    }

}

export default ToggleBreadcrumb;
