var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "clear" }),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value"
        }
      ],
      staticClass: "value",
      attrs: { placeholder: _vm.placeholder, disabled: _vm.field.disabled },
      domProps: { value: _vm.value },
      on: {
        focusout: function($event) {
          return _vm.valueUpdated(_vm.field)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.value = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-ctn field-area" }, [
      _c("div", { staticClass: "clear" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }