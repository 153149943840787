import React from 'react';
import AdvancedUi from '../AdvancedUi';

export default class Screen extends AdvancedUi {

    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            display : 'block',
            overflow: 'auto'
        });

        this.specificStyle = {
            
        };


        // merge style order :  
        // mergedStyle <== defaultStyle(parameters) < specificStyle < field props < forcedStyle(auto calculated)
    }


    render() {

        let content = this.props.children;

        return this.wrapIntoHammer( this.wrapIntoAuthoring( 
            <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                {content}
            </div>
        , 0  )) ;

    }

}