import ActionAbstract from './ActionAbstract';

class DummyEnd extends ActionAbstract {

    static get ID() {
        return 'actions:DummyEnd';
    }

    run(state) {

        let newState = this.composeState(state, {
            inspector: {
                montab: this.montab,
                text: this.removeKey(),
            },
            text: this.text
        });


        return newState;

    }

    text() {
        return "done";
    }

    montab(state) {
        state[this.getParam('index')] = this.getParam('newValue');
        return state;
    }

}

export default DummyEnd;
