import InspectorActionAbstract from './InspectorActionAbstract';
import * as helper from '../../helper';

class UpdateSlug extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateSlug';
    }

    /**
     * @param targetId
     * @param type
     * @param newSlug
     */


    run (state) {
        
        if( this._target.value.type != 'project') {
            let parent = helper.block.getBlockById(state.project, this._target.value['ref_' + this._target.value.level]);
            if (this.deps.mainStore.project.isUsedSlug( this.getParam('newSlug') , parent ) ) {
                alert('url déjà utilisée.');
                return false;
            }
        }
        else if( this._target.value.type == 'session-set' ){
            if (this.deps.mainStore.project.isUsedSlug( this.getParam('newSlug') , state.project.sessions ) ) {
                alert('url déjà utilisée.');
                return false;
            }
        }
        else if( this._target.value.type == 'project' ){

            this.deps.mainStore.project.isUsedProjectSlug( this.getParam('newSlug') , state.project, state.user  ).then( ( isUsed ) => {
                if( !isUsed ){
                    
                }
                else{
                    alert('url project déjà utilisée.');
                    return false;
                }
            });
        }
        else{
            this._target.value.slug = this.getParam('newSlug');
        }

        let newState = {},
            inspectorkey = this.getParam('inspector').second ? 'objInspector' : 'inspector';
            
        /*newState[inspectorkey] =  {
            currentSlug: this.getParam('newSlug')
        }*/

        newState.project = {};
        newState.project[this._path + '.value.slug'] = this.getParam('newSlug');

        return this.composeState(state, newState);
    }
}

export default UpdateSlug;