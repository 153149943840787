<!-- Navigator vue -->

<template lang="pug">
	div
		div.nav(v-bind:class="{ 'open': appState.main.navigatorOpen }", v-if="appState.project")
			div.arbo

				item(:node="appState.project", :app-state="appState")
				div.spacer

				div.clear

			div.clear


		div.bt-nav(v-bind:class="{'open': appState.main.navigatorOpen}", v-on:click.prevent="toggleNavigator()")
</template>

<script>

import actionManager from '../../actions';
import Item from  './NavigatorItem.vue';

export default {

	components: { Item },

	props: ['appState'],

	data() {
		return {
			currInspector: null
		}
	},

	created: function(){

	},

	methods:{
		customInit: function(project){

			this.project = null;
			this.project = project;

		},

		toggleNavigator: function(){
        	actionManager.trigger('main:toggleNavigator');

			setTimeout( () => {
        		actionManager.trigger('editor:schematic:updateOrigin');
			}, 350 );
		}

	}

}

</script>


<style lang="stylus">
.bt-nav
	display block
	width 12px
	height 40px
	background-color: var(--breadColor)
	cursor: pointer
	position: fixed
	top: 50%
	left: -3px
	margin-left: 0
	z-index: 90
	transition: all 0.3s ease-in-out
	background-image: url(../../assets/images/drawer.png)
	background-size: 75%
	background-position: center
	background-repeat: no-repeat
	border-radius: 30px
	&.open
		left: 420px
		margin-left: -6px

	&:hover
		width: 18px;
		height: 50px;
		transform: translate(-3px, -5px);

.nav
	display: block
	position: fixed
	width: 420px
	height: 100%
	top: 0px
	left: 0px
	background-color: var(--bgColor)
	box-shadow: 0 0 10px var(--shadowColor)
	z-index: 90
	transform:translateX(-100%)
	transition: all 0.3s ease-in-out

	&.open
		transform:translateX(0)

	.arbo
		list-style-type: none
		margin: 0
		padding: 0
		color: #58585B
		width: 100%
		position: absolute
		top: 40px
		left: 0
		right: 0
		bottom: 0
		overflow: auto
		padding-bottom : 10px

		input
			color: var(--inputTextColor)
			font-size: 14px
			line-height: 40px


		.action-bts, .inspector-open-bts
			position: absolute
			right: 36px !important
			top: 5px
			height: 80%
			visible: hidden
			opacity: 0
			transition: all 0.2s ease-in-out

			&.arrow-space
				right: 27px

			.action-bt
				float: right
				display: block
				width: 24px
				height: 24px
				margin-top: 3px
				margin-right: 3px
				border-radius: 24px
				position: relative
				opacity: 1
				transition: all 0.2s ease-in-out

				&:hover
					opacity: 1

				&.bt-inspector.active
					opacity: 1

				.icon
					left: 0px

		.inspector-open-bts
			visible: hidden
			opacity: 0
			display: none

			.action-bt
				opacity: 1

			&.active
				visible: visible
				opacity: 1
				display: block

		.spacer
			height: 2px

		.accordion-bloc
			display: block
			cursor: pointer
			padding 0px 15px
			line-height: 40px
			height: 40px
			padding-left: 35px
			background-color: #939597
			text-decoration: none
			position: relative
			margin-bottom: 2px
			color: var(--inputTextColor)
			font-size: 14px

			.icon
				width: 20px
				height: 20px
				position: absolute
				left: 6px
				top: 50%
				background-color: var(--inputTextColor)

			&:hover
				.action-bts
					visible: visible
					opacity: 1

				.inspector-open-bts
					visible: hidden
					opacity: 0
					display: none


			.ico-arrow
				display: block
				position: absolute
				width: 10px
				height: 100%
				background-color: var(--inputTextColor)
				-webkit-mask: url(../../assets/images/arrowNavigator.svg) no-repeat 50% 50%
				mask: url(../../assets/images/arrowNavigator.svg) no-repeat 50% 50%
				background-repeat: none
				background-position: center center
				background-repeat: no-repeat
				top: 0%
				right: 10px
				transition: linear all .1s !important

				&.close
					transform: rotate(90deg)


		.accordion-ctn
			margin-left: 10px
			position: relative
			margin-bottom: 2px
			margin-top: 2px

			&:before
				content:''
				position: absolute
				height: 100%
				top: -2px
				left: -10px
				width: 1px
				background-color: black

		.memory  , .object
			.accordion-bloc
				input
					opacity: 1

		.project
			.accordion-bloc
				background-color: rgba(244,158,117,.5)

			.accordion-ctn
				&:before
					background-color: #F3BFA5

		.experience
			.accordion-bloc
				background-color: rgba(244,158,117,.4)
				transition: linear all .1s !important
				&:hover
					background-color: rgba(244,158,117,.6)

			.accordion-ctn
				&:before
					background-color: rgba(244,158,117,0.4)

			.object
				.accordion-bloc
					background-color: rgba(244,158,117,.3)



		.screen, .widget
			.accordion-bloc
				background-color: rgba(244,158,117,.3)

			.accordion-ctn
				&:before
					background-color: rgba(244,158,117,.3)

			.object
				.accordion-bloc
					background-color: #FEF3EE


		.process, .trigger, .memory
			.accordion-bloc
				background-color: rgba(244,158,117,.1)

			.object
				.accordion-bloc
					background-color: #FEF8F5


		.accordion-bloc.active
		.accordion-bloc.selected
			/*background-color: #F39A74*/
			input
				color: white

			.icon
				background-color: white

			.navigatorType
				color: #ffffff

		.accordion-bloc.selected
			background-color: #F39A7480

		.accordion-bloc.subgroup
			background: rgba(244,158,117,.2)

			.title
				font-family: 'DinBold'

		.accordion-ctn.active
			&:before
				background-color: #F39A74




	div.bottom-buttons
		position: fixed
		bottom: 55px
		width: 100%
		text-align: center

		.big-bt
			border:1px solid white
			border-radius: 2px
			margin: auto
			padding: 4px 4px 6px 6px
			position: relative
			cursor: pointer
			display: inline-block
			text-align: left
			width: 85%

			span.text
				font-family: 'DinRegular', 'ComfortaaLight'
				font-size: 12px
				line-height: 10px
				text-align: left
				color: white

			span.bt-plus
				font-family: 'DinRegular', 'ComfortaaLight'
				font-size: 16px
				color: white
				display:inline-block
				position: absolute
				right: 8px
				top: 50%
				transform: translateY(-50%)

			&.add-xp
				border-color: #f59b75
				span.text
					color: #f59b75
				span.bt-plus
					color: #f59b75

.bt-trash
	background: #ffffff !important
	background-image: url(../../assets/images/btnTrash.png) !important
	background-size: 12px !important
	background-position: center !important
	background-repeat: no-repeat !important
	transition: linear all .1s !important
	&:hover
		background-color: var(--inspectorTargetOverColor) !important

.bt-trash-white
	background: var(--breadColor)!important
	background-image: url(../../assets/images/btnTrashOver.png) !important
	background-size: 8px !important
	background-position: center !important
	background-repeat: no-repeat !important
	border-radius: 40px
	transition: linear all .1s !important 
	&:hover
		background: var(--selectedColor)!important
		background-image: url(../../assets/images/btnTrashOver.png) !important
		background-size: 12px !important
		background-position: center !important
		background-repeat: no-repeat !important
		border-radius: 40px
		transition: linear all .1s !important

.bt-export-white
	background: var(--breadColor)!important
	background-image: url(../../assets/images/btnExportOver.png) !important
	background-size: 10px !important
	background-position: center !important
	background-repeat: no-repeat !important
	border-radius: 40px
	transition: linear all .1s !important
	&:hover
		background: var(--selectedColor)!important
		background-image: url(../../assets/images/btnExportOver.png) !important
		background-size: 16px !important
		background-position: center !important
		background-repeat: no-repeat !important
		border-radius: 40px
		transition: linear all .1s !important

.bt-duplicate
	background: #ffffff !important
	background-image: url(../../assets/images/btnDuplicate.png) !important
	background-size: 12px !important
	background-position: center !important 
	background-repeat: no-repeat !important
	transition: linear all .1s !important
	&:hover
		background-color: var(--inspectorTargetOverColor) !important

.bt-duplicate-white
	background: var(--breadColor)!important
	background-image: url(../../assets/images/bt-duplicate.png) !important
	background-size: 10px !important
	background-position: center !important
	background-repeat: no-repeat !important
	border-radius: 40px
	transition: linear all .1s !important
	&:hover
		background: var(--selectedColor)!important
		background-image: url(../../assets/images/bt-duplicate.png) !important
		background-size: 16px !important
		background-position: center !important
		background-repeat: no-repeat !important
		border-radius: 40px
		transition: linear all .1s !important

.bt-download
	background: #ffffff !important
	background-image: url(../../assets/images/btnDownload.png) !important
	background-size: 12px !important
	background-position: center !important
	background-repeat: no-repeat !important
	transition: linear all .1s !important
	&:hover
		background-color: var(--inspectorTargetOverColor) !important

.bt-download-white
	background: var(--breadColor)!important
	background-image: url(../../assets/images/bt-download.png) !important
	background-size: 8px !important
	background-position: center !important
	background-repeat: no-repeat !important
	border-radius: 40px
	transition: linear all .1s !important 
	&:hover
		background: var(--selectedColor)!important
		background-image: url(../../assets/images/bt-download.png) !important
		background-size: 12px !important
		background-position: center !important
		background-repeat: no-repeat !important
		border-radius: 40px
		transition: linear all .1s !important

.bt-inspect
	background: var(--breadColor)!important
	background-image: url(../../assets/images/bt-settings-white.png) !important
	background-size: 10px !important
	background-position: center !important
	background-repeat: no-repeat !important
	border-radius: 40px
	transition: linear all .1s !important
	&:hover
		background: var(--selectedColor)!important
		background-image: url(../../assets/images/bt-settings-white.png) !important
		background-size: 16px !important
		background-position: center !important
		background-repeat: no-repeat !important
		border-radius: 40px
		transition: linear all .1s !important

.bt-play
	background: #ffffff!important
	background-image: url(../../assets/images/bt-play.png) !important
	background-size: 13px 12px !important
	background-position: 6px 6px !important
	background-repeat: no-repeat !important
	&:hover
		background-color: var(--inspectorTargetOverColor) !important

.bt-inspector
	width: 10%
	height: 100%
	background: #ffffff
	background-image: url(../../assets/images/bt-settings.png)
	background-repeat: no-repeat
	background-size: auto 50%
	background-position: center center
	position: absolute
	top: 0
	right: 0
	cursor: pointer
	transition: linear all .1s !important
	&:hover
		background-color: var(--inspectorTargetOverColor)

	&.bt-inspector-white
		background-image: url(../../assets/images/bt-settings-white.png)


input.invisible
	background: transparent
	border: none
	border-radius: 0
	font-family: 'DinRegular', 'ComfortaaLight', Helvetica
	text-transform: capitalize
	-webkit-appearance: none
	line-height: normal
	-webkit-touch-callout: none
	-webkit-user-select: text
	-khtml-user-select: text
	-moz-user-select: text
	-ms-user-select: text
	user-select: none
	font-size: 14px
	color: var(--inputTextColor)

	&:focus
		outline: none

input.invisibleSimple
	background: transparent
	border: none
	border-radius: 0
	font-family: 'DinBold'
	text-transform: capitalize
	-webkit-appearance: none
	line-height: normal
	-webkit-touch-callout: none
	-webkit-user-select: text
	-khtml-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	user-select: text
	font-size: 14px

	&:focus
		outline: none




</style>
