var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "textinput-ctn array-bt" }, [
    _c(
      "div",
      {
        staticClass: "edit-bt",
        class: {
          disabled: _vm.field.disabled || _vm.moduleModeCondition === false
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.moduleModeCondition !== false ? _vm.openArray() : ""
          }
        }
      },
      [_c("span", { staticClass: "label" }, [_vm._v("Edit data")])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }