<!-- Color vue -->

<template lang="pug">

span.textinput-ctn.color-field
	input.value.uint(v-model="hex", v-on:keyup="valueUpdatedFromInput()", :disabled="field.disabled")
	div.color-square(v-on:click.stop="togglePicker(field)")
		div.color(v-bind:style="{'background-color':visuHex, 'opacity': currentAlpha }")
	div.color-picker(v-bind:class="{'open':pickerOpen}", v-if="pickerOpen")
		chrome-picker(v-model="value")
	
</template>

<script>

import VueColor from 'vue-color';

export default {

	components: { 'chrome-picker': VueColor.Chrome  },

	props:[ 'field' , 'fieldvalue', 'bloc'  , 'placeholder' ],

	data() {
		return {
			pickerOpen: false,
			colorLinked: false,
			colorDecomposed: null,
			hex: null,
			visuHex: null,
			value: null,
			init: true,
			currentAlpha: 1
		}
	},

	created: function(){
		this.value = this.fieldvalue ? this.initValue(this.fieldvalue.value) : null;

		if( !this.value && this.placeholder )
			this.value = this.initValue(this.placeholder);

		this.updateHexVisu();

		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$on('clickCoverHandler', this.onClickCoverHandler)
	},

	beforeDestroy: function(){
		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// If limits appear, have a look at https://vuex.vuejs.org
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$off('clickCoverHandler', this.onClickCoverHandler)
	},

	watch:{

		fieldvalue: function (obj) {
			if (obj) this.value = obj.value;
			//this.value = obj.value;
		},

		value : function( newVal, oldVal ){
			this.updateHexVisu();
			if( !this.init ) this.valueUpdated();
			this.init = false;

			if( this.value && this.value.rgba && this.value.a != this.value.rgba.a ){
				this.value.rgba.a = this.value.a;
				if( this.value.hsv ) this.value.hsv.a = this.value.a;
				if( this.value.hsl ) this.value.hsl.a = this.value.a;
				this.valueUpdated();
			}
		}
	},

	computed: {
	
	},


	methods:{

		updateHexVisu: function(){
			if( this.value && this.value.hex ){
				this.visuHex = this.value.hex;
				this.hex = this.value.hex;
				this.currentAlpha = this.value.a;
				return;
			}
			if( this.value && this.value.rgba ){
				let hex = this.rgbToHex( this.value.rgba );
				this.visuHex = hex;
				this.hex = hex;
				this.currentAlpha = this.value.a;
				return;
			}
			this.visuHex = null;
		},

		rgbToHex: function( rgb ) {
			let valueCopy = {
				r: rgb.r,
				g: rgb.g,
				b: rgb.b
			}
			const hexString = "#" + ((1 << 24) + (valueCopy.r << 16) + (valueCopy.g << 8) + valueCopy.b).toString(16).slice(1).toUpperCase();

			return hexString;
		},

		hexToRgb: function(hex) {
			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			return result ? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
				a: this.currentAlpha
			} : null;
		},

		// one of these two methods break the update of the colour picker [tocket NS-941]
		// bad conversion or anything else, they are purely optional (from a quick look)
		/*
		rgbToHsl: function( rawRgb, a ){
			let rgb = {
				a: rawRgb.a,
				r: rawRgb.r,
				g: rawRgb.g,
				b: rawRgb.b
			}
			rgb.r /= 255, rgb.g /= 255, rgb.b /= 255;
			var max = Math.max(rgb.r, rgb.g, rgb.b), min = Math.min(rgb.r, rgb.g, rgb.b);
			var h, s, l = (max + min) / 2;

			if(max == min){
				h = s = 0; // achromatic
			}else{
				var d = max - min;
				s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
				switch(max){
					case rgb.r: h = (rgb.g - rgb.b) / d + (rgb.g < rgb.b ? 6 : 0); break;
					case rgb.g: h = (rgb.b - rgb.r) / d + 2; break;
					case rgb.b: h = (rgb.r - rgb.g) / d + 4; break;
				}
				h /= 6;
			}

			return {h: h, s: s, l: l, a: a};
		},

		rgbToHsv: function ( rawRgb , a ) {
			let rgb = {
				a: rawRgb.a,
				r: rawRgb.r,
				g: rawRgb.g,
				b: rawRgb.b
			}			
			var max = Math.max(rgb.r, rgb.g, rgb.b), min = Math.min(rgb.r, rgb.g, rgb.b),
				d = max - min,
				h,
				s = (max === 0 ? 0 : d / max),
				v = max / 255;

			switch (max) {
				case min: h = 0; break;
				case rgb.r: h = (rgb.g - rgb.b) + d * (rgb.g < rgb.b ? 6: 0); h /= 6 * d; break;
				case rgb.g: h = (rgb.b - rgb.r) + d * 2; h /= 6 * d; break;
				case rgb.b: h = (rgb.r - rgb.g) + d * 4; h /= 6 * d; break;
			}

			return { h: h, s: s, v: v, a:a };
		},
		*/


		fillColorValFromRGB: function( rgb , a ){
			return {
				hex: this.rgbToHex( rgb ),
				// hsl: this.rgbToHsl( rgb, a ),
				// hsv: this.rgbToHsv( rgb, a ),
				rgba: {
					r: rgb.r,
					g: rgb.g,
					b: rgb.b,
					a: a
				},
				a: a,
				source: "rgba"
			};
		},

		fillColorValFromHex: function( hex , a){
			let rgb = this.hexToRgb( hex );
			if( rgb == null ) return null;
			return this.fillColorValFromRGB( rgb , a );
		},
		

		valueUpdated: function( ){
			this.currentAlpha = this.value ? this.value.a : 1 ;
		 	this.$parent.valueUpdated( this.field, this.value );
		},

		valueUpdatedFromInput: function(){
			let correctValue = this.fillColorValFromHex( this.hex, this.currentAlpha );
			if( correctValue )
				this.value = correctValue;
			
			if( this.hex === null || this.hex === "" )
				this.value = null; 

			if( !this.value && this.placeholder )
				this.value = this.initValue( this.placeholder );
		},


		togglePicker: function( field ){

			if( this.field.disabled )
				return;

			if( !this.pickerOpen && this.value == null )
				this.setDefaultValue();
				
			this.pickerOpen = !this.pickerOpen;
			// HACK: Instead of installing Vuex, and another dependencies,
			// use the root instance as an event hub to communicate between components.
			// (since migration Vue v1 to v2)
			// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
			this.$root.$emit('picker-cover-toggle', this.pickerOpen) // <---- ORIGINAL pickerCoverToggle LISTENER NOT FOUND
		},

		setDefaultValue( ){
			this.value = {};
			this.value = this.fillColorValFromHex( "#FEFFFF", 1 );
		},

		initValue( value ){
			if( typeof value === "string" )
				 return this.fillColorValFromHex(  value, this.currentAlpha );
			return value;
		},


		// ORIGINAL clickCoverHandler EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
		// (migration Vue v1 to v2)
		onClickCoverHandler: function(){
			if( this.pickerOpen )
				this.togglePicker();
		}
	}

}

</script>

<style lang="stylus">

.param-ctn 
	.textinput-ctn.color-field
		input.value
			width: 50%
			float: left

</style>