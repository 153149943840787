import AdvancedUiObject from '../AdvancedUiObject';
import { STATE_CHANGE } from '../UiObject';

class UiBox extends AdvancedUiObject {
    constructor(id, props) {
        super(id, props, 'Box');

        this.prevDndTag = null;
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-box",
            label: "Box",
            parent : "ui-layouts",
            isDisplayed : true,
            isEmbeddable: true,
            nbSection: 1,
            childrenType: 'ui'
        };
    }


    
    initParameters(){
        super.initParameters();

        this.setDefaultValue( 'width', '100%' );
        this.setDefaultValue( 'height', '200px' );


        let dragTopic = this.createInspectorNode( 'topic', 'dragdrop', 'Drag & Drop' );

        let dragParams = this.createInspectorNode('group', 'dragdrop-params', 'Drag & Drop Parameters' );
        let dragParamsFields = this.createInspectorNode('group-field', 'dragdrop-params-fields' ); 
        dragParams.children.push( dragParamsFields );

        let dragEvents = this.createInspectorNode('group', 'dragdrop-events', 'Drag & Drop Events' );
        dragEvents.hideForObjInspector = true;

        dragTopic.children = [ dragParams, dragEvents ];

        //this.inspector.push( dragTopic );
        this.inspector.splice( this.inspector.length-1, 0 , dragTopic );

        let dragdropFields = {
            
            svgContainer : {
                type: 'Boolean', 
                default: false, 
                connection: null,
                auth :  {
                    label: "SVG Container",
                    container: "general-fields",
                    description: "Specifies whether the box may contain SVG elements or not."
                }
            }, 
            
            pinchToZoomEnabled : {
                type: 'Boolean', 
                default: false, 
                connection: null,
                auth :  {
                    label: "Pinch to Zoom Enabled",
                    container: "general-fields",
                    description : "On mobile only, enable pinch to zoom feature.",
                    conditions: [{field:'svgContainer', value:false, operator:'=='}]
                }
            }, 

            resetPinch : {
                type: 'Mixed', 
                default: false, 
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                callable: true,
                auth :  {
                    label: "Reset pinch",
                    container: "general-fields",
                    description : "On mobile only, reset pinch scale and positionning.",
                    conditions: [{field:'pinchToZoomEnabled', value:true, operator:'=='}]
                }
            }, 
            
            dragAndDropMode : {
                type: 'String', 
                default: "none", 
                partial: 'dragdrop',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Drag&Drop mode",
                    container: "dragdrop-params-fields",
                    widget: 'select',
                    options: [ {value:'none', label:'None'}, {value:'draggable', label:'Draggable'}, {value:'dropzone', label:'DropZone'} ],
                    description: "specifies the drag and drop mode : 'Draggable' elements may be dragged and dropped in 'Drop zone' elements (default value is 'None')"
                }
            },

            // draggable params
            dragX : {
                type: 'Boolean', 
                default: true, 
                connection: null,
                partial: 'dragdrop',
                auth :  {
                    label: "Drag X",
                    container: "dragdrop-params-fields",
                    description: "Specifies whether the box can be dragged on the X axis"
                }
            }, 
            dragY : {
                type: 'Boolean', 
                default: true, 
                connection: null,
                partial: 'dragdrop',
                auth :  {
                    label: "Drag Y",
                    container: "dragdrop-params-fields",
                    description: "Specifies whether the box can be dragged on the Y axis"
                }
            }, 

            dropZoneUi: {
                type: 'String',
                default: null,
                partial: 'dragdrop',
                connection: {
                    in: {pluggable: true, default: false}, out: {pluggable: true, default: false}
                },
                auth:{ 
                    container: "dragdrop-params-fields",
                    label: 'DropZone',
                    widget: "object",
                    tag: ['ui'],
                    parentLevel : 'screen',
                    conditions: [{field:'dragAndDropMode', value:'draggable', operator:'=='}]
                }
            },
            dropZoneTags: {
                type: 'String',
                default: null,
                partial: 'dragdrop',
                connection: {
                    in: {pluggable: true, default: false}, out: {pluggable: true, default: false}
                },
                auth:{ 
                    container: "dragdrop-params-fields",
                    label: 'DropZone Tags',
                    conditions: [{field:'dragAndDropMode', value:'draggable', operator:'=='}],
                    description: "Specifies the tag(s) of the drop zone(s) that will accept the draggable element"
                }
            },
            onBadMove : {
                type: 'String',
                default: 'none',
                partial: 'dragdrop',
                connection: {
                    in: {pluggable: true, default: false}, out: {pluggable: true, default: false}
                },
                auth:{ 
                    container: "dragdrop-params-fields",
                    label: 'On bad move',
                    widget : 'select',
                    options: [ {value:'none', label:'None'}, {value:'backToStartPos', label:'Back to start pos'}, {value:'hide', label:'Hide'} ],
                    conditions: [{field:'dragAndDropMode', value:'draggable', operator:'=='}],
                    description: "When the element is dropped outside of a correct Drop zone, specifies whether nothing happens (None) or the element get back to its starting position (BackToStartPos) or disappears (Hide)"
                }
            },
            
            // dropzone params
            fillCapacity  : {
                type: 'Int',
                default: null,
                partial: 'dragdrop',
                connection: {
                    in: {pluggable: true, default: false}, out: {pluggable: true, default: false}
                },
                auth:{ 
                    container: "dragdrop-params-fields",
                    label: 'Fill capacity',
                    conditions: [{field:'dragAndDropMode', value:'dropzone', operator:'=='}],
                    description: ""
                }
            },

            textAlign: {
                type: 'String', 
                default: "inherit", 
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Content Alignment",
                    container: "pos-fields",
                    widget: 'select',
                    options: [ {value:'inherit', label:'inherit'}, {value:'left', label:'left'}, {value:'center', label:'center'}, {value:'right', label:'right'} ],
                    description: "Sets the horizontal alignment of the UI. Inherit: same alignment as the parent / Left: aligned to the left / Center: aligned to the center / Right: aligned to the right."
                }
            },

            verticalAlign: {
                type: 'String', 
                default: "inherit", 
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Vertical Align",
                    container: "pos-fields",
                    widget: 'select',
                    options: [ {value:'inherit', label:'inherit'}, {value:'top', label:'top'}, {value:'middle', label:'middle'}, {value:'bottom', label:'bottom'} ],
                    description: "Sets the vertical alignment of the UI. Inherit: same alignment as the parent / Top: aligned to the top / Middle: aligned to the middle / Bottom: aligned to the bottom."
                }
            }
        };
        
        this.addToParameters( dragdropFields );
        
        let ddEventsDraggable = [
            {
                name : 'startDrag',
                subParams : [
                    { name : 'trigger' , label : 'Trigger', type : 'String', default: null },
                    { name : 'position' , label : 'Position', type : 'Vector2', default : {x:0,y:0} }
                ]
            },
            {
                name : 'onDrag',
                subParams : [
                    { name : 'trigger' , label : 'Trigger', type : 'String', default: null },
                    { name : 'position' , label : 'Position', type : 'Vector2', default : {x:0,y:0} }
                ]
            },
            {
                name : 'stopDrag',
                subParams : [
                    { name : 'trigger' , label : 'Trigger', type : 'String', default: null },
                    { name : 'success' , label : 'Success', type : 'Boolean', default: false },
                    { name : 'position' , label : 'Position', type : 'Vector2', default : {x:0,y:0} }
                ]
            }
        ];

        let ddEventsDropZone = [
            {
                name : 'onDrop',
                subParams : [
                    { name : 'trigger' , label : 'Trigger', type : 'String', default: null },
                    { name : 'correctObject' , label : 'correct Object', type : 'Boolean', default: false },
                    { name : 'droppedItem' , label : 'dropped item', type : 'String', default: null }
                ]
            },
            {
                name : 'onFill',
                subParams : [
                    { name : 'trigger' , label : 'Trigger', type : 'String', default: null }
                ]
            },
            {
                name : 'onDragOver',
                subParams : [
                    { name : 'trigger' , label : 'Trigger', type : 'String', default: null },
                    { name : 'correctObject' , label : 'correct Object', type : 'Boolean', default: false }
                ]
            },
            {
                name : 'onDragOut',
                subParams : [
                    { name : 'trigger' , label : 'Trigger', type : 'String', default: null },
                    { name : 'correctObject' , label : 'correct Object', type : 'Boolean', default: false }
                ]
            },
        ];
            
        
        
        ddEventsDraggable.forEach( (event) => {
            this.addDDEvent( dragEvents, event.name, event.subParams, 'draggable' );
        });

        ddEventsDropZone.forEach( (event) => {
            this.addDDEvent( dragEvents, event.name, event.subParams, 'dropzone' );
        });


        // disable translate X & Y for drag&drop Items
        //this.parameters.translateX.auth.conditions =  [{field:'dragAndDropMode', value:'none', operator:'=='}];
        //this.parameters.translateY.auth.conditions =  [{field:'dragAndDropMode', value:'none', operator:'=='}];
        this.parameters.translateX.callable = true;
        this.parameters.translateY.callable = true;


    }

    addDDEvent( ctn , event, subParams, condition ){

        this.addGroupFieldForEvent( ctn, 'ddevents', event );
        let param = this.createEventParameters( 'ddevents', event , subParams, [{field:'dragAndDropMode', value: condition , operator:'=='}] );
        this.addToParameters( param );

    }


    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }
    
    init(){
        super.init();

        this.set( 'triggerResetPinch', false );
        this.set('feedbackDeltaDnD', this.feedbackDeltaDnD.bind(this) );
    }

    reset( recursivly = false ){
        
        this.set( 'resetState', true );

        super.reset( recursivly );

        setTimeout( () => {
            this.set( 'resetState', false );
            this.emit(STATE_CHANGE, this.render());
        }, 100);

        return undefined;
    }

    
    feedbackDeltaDnD( newPosition ){
        this.set( "translateX_value", newPosition.x+"px" );
        this.set( "translateY_value", newPosition.y+"px" );
    }

    translateX( value ){

        this.set( 'force_translateX', value );
        
        setTimeout( () => {
            this.set( 'force_translateX', null );
            this.emit(STATE_CHANGE, this.render());
        }, 100);
        
        this.emit(STATE_CHANGE, this.render());

        return this.get('translateX_value');
    }

    translateY( value ){

        this.set( 'force_translateY', value );
        
        setTimeout( () => {
            this.set( 'force_translateY', null );
            this.emit(STATE_CHANGE, this.render());
        }, 100);
        
        this.emit(STATE_CHANGE, this.render());

        return this.get('translateY_value');
    }

    resetPinch( value ){

        this.set( 'triggerResetPinch', true );
        
        setTimeout( () => {
            this.set( 'triggerResetPinch', false );
            this.emit(STATE_CHANGE, this.render());
        }, 100);
        
        this.emit(STATE_CHANGE, this.render());

        return this.get('triggerResetPinch');
    }

    
    set(prop, value, partial = false) {

        super.set( prop, value, partial );

        if( prop == "dragAndDropMode" && this.assetManager ){

            if( this.prevDndTag !== null && this.assetManager.removeTag )
                this.assetManager.removeTag( this.id, this.prevDndTag );

            if( value !== "none" && this.assetManager.addTag ){
                this.assetManager.addTag( this.id, "ui-box-"+value );
                this.prevDndTag = "ui-box-"+value;
            }
            
        }
    }
}

export default UiBox;