<!-- BlocLinks vue -->
<template lang="pug">
svg.lines
	g.line-styler(v-bind:style="{transform: 'translate('+scene.coordinate.x+'px , '+scene.coordinate.y+'px ) scale('+scene.scale+')'}")
		bloc-link(v-for="link of links", :app-state="appState", :link="link")
		path.line(v-bind:d="linkingPath")
</template>

<script>

// STORE
import BlocLink from  './BlocLink.vue';

export default {

	components: { BlocLink },

	props: ['appState', 'target', 'scene'],

	data() {
		return {
			linking: false,
			currentLinkBloc: null,
			linkingPath: 'M0 0'
		}
	},

	created: function(){
		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$on('mouse-move', this.onMouseMove)
	},

	beforeDestroy: function(){
		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// If limits appear, have a look at https://vuex.vuejs.org
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$off('mouse-move', this.onMouseMove)
	},

	computed:{
		links: function(){
			let blocs = this.target.custom.children;
			let links = [];
			blocs.forEach( (bloc) => {
				bloc.value.links.forEach( (link) => {
					links.push( link );
				})
			});
			return links;
		}
	},

	methods: {

		onMouseMove: function(e){
			let pathSuffix = "";


			if( this.appState.editor.linking && this.appState.editor.linking.status ){

				let to = {
					x: e.x-this.scene.coordinate.x-this.appState.editor.origin.x,
					y: e.y-this.scene.coordinate.y-this.appState.editor.origin.y
				};

				pathSuffix = ' L'+to.x+' '+to.y;

			}

			if (this.appState.editor.linking)
				this.linkingPath = this.appState.editor.linking.path + pathSuffix;

		}
	}

}

</script>

<style lang="stylus">
.lines
	position: absolute
	width: 100%
	height: 100%
	top: 0
	left: 0
	z-index: 10
	fill: transparent

	.line-styler
		stroke var(--moduleTitleColor)
		stroke-width 1
		fill transparent
		stroke-opacity 1

	.invisible
		stroke-width: 10px
		stroke transparent

	.line

		.select
			stroke-width: 1px
			stroke: white
			stroke-opacity 0
			fill: transparent

		&:hover
			stroke-width: 1px
			stroke #f39a74
			fill: transparent
			stroke-opacity 1
			order: 100

		&.selected
			stroke-width: 2px
			stroke #f39a74
			fill: transparent
			stroke-opacity 1
			order: 200

		&.activated
			stroke-width: 3px
			stroke var(--moduleTitleColor)
			fill: transparent
			stroke-opacity 1
			order: 200
			stroke-opacity 1

		&.activated:hover
			stroke-width: 3px
			stroke #f39a74
			stroke-opacity 1

		&.activated.selected
			stroke-width: 2px
			stroke #333
			stroke-opacity 1

		&.deprecated
			stroke-width: 3px
			stroke #F77963
			fill: transparent
			stroke-opacity 1
			stroke-dasharray 2
			order: 200
			stroke-opacity 0.6
			.grab
				fill: #F77963 !important
				order: 400

			.grabber-bg
				stroke : #F77963 !important
				stroke-width: 14
				stroke-linecap: round
				stroke-opacity 17

			.grabber
				stroke : #F77963 !important
				stroke-width: 12
				stroke-linecap: round
				stroke-opacity 1

		&.deprecated:hover
			stroke-width: 2px
			stroke #F77963 !important
			stroke-opacity 1
			stroke-dasharray 2

		&.deprecated.selected
			stroke-width: 2px
			stroke #F77963 !important
			stroke-opacity 1
			stroke-dasharray 2

		.grab
			fill: rgba(255,255,255,0.6)
			order: 400

		.grabber-bg
			stroke : var(--drawZoneColor)
			stroke-width: 14
			stroke-linecap: round
			stroke-opacity 17

		.grabber
			stroke : var(--moduleTitleColor)
			stroke-width: 10.5
			stroke-linecap: round
			stroke-opacity 1
			&:hover
				stroke: var(--selectedColor)
			&:selected
				stroke: var(--selectedColor)

		.grabberTextPath
			stroke-width: 0

		textPath
			stroke: none
			fill: #ffffff
			font-family: 'DinBold'
			font-size: 10px
			text-transform: uppercase

		&.activated
			.grabber-bg
				stroke-width: 15

			.grabber
				stroke : var(--moduleTitleColor)

			textPath
				fill: white

</style>
