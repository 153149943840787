var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "puzzle-bloc" }, [
    _c("div", { staticClass: "bloc" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "type" }, [_vm._v("Experience")]),
        _c("span", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.bloc.value.name))
        ])
      ]),
      _c(
        "div",
        { staticClass: "puzzle-part", class: _vm.getTargetNames() },
        [_c("puzzle")],
        1
      ),
      _c("div", { staticClass: "content fake" })
    ]),
    _c("div", { staticClass: "bottom-menu" }, [
      _c("div", { staticClass: "action-bts" }, [
        _c("div", {
          staticClass: "action-bt bt-inspect",
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.inspectBloc($event)
            }
          }
        })
      ]),
      _c("div", { staticClass: "action-bts" }, [
        _c("div", {
          staticClass: "action-bt bt-duplicate-white",
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.duplicateBloc($event)
            }
          }
        })
      ]),
      _c("div", { staticClass: "action-bts" }, [
        _c("div", {
          staticClass: "action-bt bt-export-white",
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.exportBloc($event)
            }
          }
        })
      ]),
      _c("div", { staticClass: "action-bts" }, [
        _c("div", {
          staticClass: "action-bt bt-download-white",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.downloadBloc($event)
            }
          }
        })
      ]),
      _c("div", { staticClass: "action-bts" }, [
        _c("div", {
          staticClass: "action-bt bt-trash-white",
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.deleteBloc($event)
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }