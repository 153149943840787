
const ui = {

    setUIProp(uiObject, prop, value, partial) {
        uiObject.disableStateEvent();
        uiObject.set(prop, value, partial);
        uiObject.enableStateEvent(false);
    },

    listParentTypes(parents) {
        for (let i = 0; i < parents.length; i++) {
            parents[i] = parents[i].replace(/OL/, " ").trim();
        }
        return parents.toString();
    }

}

export default ui;