import ActionAbstract from '../ActionAbstract';

class DeleteProject extends ActionAbstract {
    static get ID() {
        return 'actions:project:deleteProject';
    }

    run(state) {

        this.deps.mainStore.block.deleteDocument( 'ref_'+this.getParam('projectId'), this.deps.mainStore.config.baseURLAuth ).then( () => {

            this.deps.mainStore.project.deleteDBProject( this.getParam('projectId') );
            this.trigger('projects:listAll', { forceRefresh : true });
            
        });

        return this.composeState(state, {
            isLoading: true,
        });

    }
}

export default DeleteProject;