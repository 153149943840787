<!-- Group Inspector Bloc vue -->

<template lang="pug">
div.form-accordion(v-bind:class="[{'group':bloc.isAccordion}, {'isAccordion':bloc.isAccordion}, {'invisible-setting':bloc.invisible}, {'hide': isObj && bloc.hideForObjInspector === true }]")
	div.title(v-on:click.stop="accordion = !accordion")
		//- span.text(v-if="bloc.label") {{bloc.label}}
		span.text(v-if="bloc.title") {{bloc.title}}
			//span.description-bull(v-if="bloc.description") ?
		span.arrow(v-if="bloc.isAccordion", v-bind:class="{'close':!accordion}")

		div.legend(v-if="bloc.type=='group' && bloc.isAccordion")
			span.legend-default default
			span.legend-in in
			span.legend-out out

		field(v-if="bloc.type=='param' && bloc.hideForObjInspector !== true", :field="bloc", :bloc="target", :state="state", :inspectorOption="inspectorOption")

	div.form-accordion-content(v-bind:class="{'open':accordion}", v-if="subforms && subforms.length > 0 || bloc.isRepeatedForm", :key="currentNBRepeatedField")
		div.children(v-for="(subbloc,index) of subforms")
			div.chapo-repeatedform(v-if="bloc.isRepeatedForm")
				span {{bloc.label}} \#{{index+1}}
				div.action-bts
					div.action-bt.bt-trash(v-on:click.stop="deleteRepeatedForm(subbloc)")
			group-field(:project="project", :target="target", :bloc="subbloc", v-bind:class="{'repeated-group-field':bloc.isRepeatedForm}", :state="state", :inspectorField="inspectorField")

		div.button-repeated-form
			span.description-bull-repeated-form(v-if="bloc.isRepeatedForm && this.repeatedForm && this.repeatedForm.description", v-on:click="displayDescription") ?
			div.big-bt.bt-repeat-form(v-if="bloc.isRepeatedForm && nbRepeatedField === null && this.repeatedForm", v-on:click="addRepeatedForm" )
				span.text Add {{this.repeatedForm.label}}
				span.bt-plus +

		// TODO: to be removed if unused ?
		// (migration v1>v3)
		//nbRepeatedField

</template>

<script>

import GroupField from  './GroupField.vue';
import FieldManager from  '../fields/FieldManager.vue';
import actionManager from '../../../actions'
import * as helper from '../../../helper';

export default {

	props:[ 'project', 'target', 'bloc', 'values', 'state', 'inspectorField', 'inspectorOption' ],

	components: { GroupField , field: FieldManager },

	data() {
		return {
			accordion: true,
			nbRepeatedFieldTarget : null,
			currentNBRepeatedField : -1,
			// inspector: null,
			// repeatedForm: null,
			// subforms: new Array()
		}
	},

	computed: {

		isObj : function(){
			if( this.target && this.target.value.type == "object" )
				return true;
			return false;
		},

		nbRepeatedField : function(){
			if( this.nbRepeatedFieldTarget !== null ){

				let targetId = this.bloc.includedField ? this.bloc.includedField : this.target.value._id;
				let newVal = this.nbRepeatedFieldTarget.value;

				if( this.currentNBRepeatedField != -1 && newVal != this.currentNBRepeatedField ){
					actionManager.trigger('inspector:repeatedForm', { subaction: 'setNbRepeatedField', targetId: targetId, bloc: this.bloc, nbRepeatedField: this.nbRepeatedFieldTarget.value });
				}

				return newVal;
			}
			return null;
		},

		repeatedForm: function () {

			let field = null;
			if( this.bloc.isRepeatedForm ){

				let fakeState = { project : this.project , config : this.$config.authoring };
				let inspector = helper.config.getMergedInspector( fakeState , this.target,  this.$xpConfig["mapLibrary"] );

				if( inspector ){
					field = helper.config.findFieldInInspector(inspector , this.bloc.name);
				}

				if( this.bloc.nbRepeatedField !== undefined ){
					this.nbRepeatedFieldTarget = helper.block.getField(  this.target ,  this.bloc.nbRepeatedField );
				}

			}
			return field;
		},

		subforms: function () {
			if( this.bloc && this.bloc.isRepeatedForm && this.repeatedForm ){

				let subforms = [],
					createGroupField = (function (uid) {

						let groupfield =  { type: 'group-field' , uid: uid, children:[] };

						for (var name of Object.keys(this.repeatedForm.form)) {

							let subfield = Object.assign( {}, this.repeatedForm.form[name]);
							subfield.type = "field";
							subfield.formName = ""+subfield.name;
							subfield.suffix = this.bloc.name+"_"+uid+"_";
							subfield.name = this.bloc.name+"_"+uid+"_"+subfield.name;
							subfield.uid = uid;

							groupfield.children.push( subfield );
						}

						return groupfield;
					}).bind(this),
					currentFieldsIds = [];

				// Get all current field, filtred by uid saved in bdd
				this.target.value.fields.forEach( (field) =>{

					let isRepeatedValue = -1;

					if( field.name )
						isRepeatedValue = field.name.search( this.bloc.name+"_" );

					if( isRepeatedValue != -1 ){

						let fieldname = field.name.replace(this.bloc.name+"_", '');
						let under = fieldname.indexOf("_");
						let uid = fieldname.substring(0, under);

						if( currentFieldsIds.indexOf(uid) == -1 ){
							// uid not added, we add the current form
							subforms.push(createGroupField( uid ));
							currentFieldsIds.push( uid );
						}
					}
				});

				this.currentNBRepeatedField = currentFieldsIds.length;

				return subforms;
			} else {
				return this.bloc.children
			}
		}
	},

	methods: {
		selectHasChanged: function(newVal) {
			this.$parent.selectHasChanged(newVal);
		},

		displayGroup: function(bloc){
			if(bloc.conditions){
				let fieldReference = bloc.conditions.field;
				let operator = bloc.conditions.operator;
				let value1 = bloc.conditions.value;

				this.target.value.fields.forEach((field) => {
					if(field.name === fieldReference){
						let value2 = field.value;
						if( value1 !== null && value2 !== null){
							switch( operator ){
								case '==':
									if( value1 != value2 )
										return false;
									break;

								case '!=':
									if( value1 == value2 )
										return false;
									break;

								case '>':
									if( value1 <= value2 )
										return false;
									break;

								case '<':
									if( value1 >= value2 )
										return false;
									break;

								case '>=':
									if( value1 < value2 )
										return false;
									break;

								case '<=':
									if( value1 > value2 )
										return false;
									break;
							}
						}
					}
				})
			} else {
				return true
			}
		},

		toggleAccordion: function(){
			this.accordion = !this.accordion;
		},

		addRepeatedForm: function(){

			let targetId = this.bloc.includedField ? this.bloc.includedField : this.target.value._id;
			actionManager.trigger('inspector:repeatedForm', { subaction: 'add', targetId: targetId, bloc: this.bloc });

			if( this.bloc.includedField )
				actionManager.trigger('block:updateFields', { id : this.target.value._id });
			// this.addGroupField( this.uniqueid() );
			// stores.block.triggerConnectionChange( {blocID: this.target.value._id} );

			// stores.project.saveProject( stores.config.openProject );
		},

		deleteRepeatedForm: function( groupfield ){

			let targetId = this.bloc.includedField ? this.bloc.includedField : this.target.value._id;
			actionManager.trigger('inspector:repeatedForm', { subaction: 'remove', targetId:targetId, uid: groupfield.uid });

			if( this.bloc.includedField )
				actionManager.trigger('block:updateFields', { id : this.target.value._id });

			// // Delete values & field data for this groupfield
			// let index = this.subforms.indexOf( groupfield );
			// if( index != -1 ){
			// 	groupfield.children.forEach( (subfield) => {
			// 		// stores.block.deleteLinkToField( stores.config.openProject, this.target, subfield.name );
			// 		// stores.block.removeField( this.target , subfield.name );
			// 		// delete this.values[subfield.name];
			// 	});
			// 	this.subforms.splice(index, 1);
			// }

			// // Update connection for bloc display
			// stores.project.saveProject( stores.config.openProject );
			// stores.block.triggerConnectionChange( {blocID: this.target.value._id} );
		},

		displayDescription: function(){
			actionManager.trigger('main:modal:openModal', {title:"Add", text:this.repeatedForm.description, continueButton: 'OK', cancelButton: '', icon: 'exclamation' })
		}

	}

}

</script>

<style lang="stylus">
.button-repeated-form
	span.description-bull-repeated-form
		background: var(--bgColor)!important
		position: absolute
		margin-top: 6px
		margin-left: -16px
		width: 24px
		height: 24px
		line-height: 26px
		border-radius: 20px
		text-align: center
		color: var(--selectedColor)
		cursor: pointer
		opacity: 0
		font-family: 'DinBold'
		font-size: 18px
		box-shadow: 0 0 5px rgba(0,0,0,.1)
		transition: linear opacity 0.1s
.button-repeated-form:hover > .description-bull-repeated-form
	opacity: 1 !important

.accordion .big-bt.bt-repeat-form
	cursor: pointer
	border-radius: 4px
	margin: 0 5px
	background: var(--bgColor)

	span.text
		font-family: 'DinBold'

.chapo-repeatedform
	background-color: var(--bgColor)
	font-size: 14px
	height: 40px
	line-height: 40px
	color: var(--inspectorText)
	margin: 0px
	margin-bottom: 0px 10px
	padding: 0px 10px
	border-radius : 4px 4px 0px 0px
	//margin-top: 5px
	margin-top: 0px
	position: relative
	font-family: 'DinBold'

	.action-bts
		position: absolute
		right: 3px
		top: 10%
		height: 80%
		visibility: hidden
		opacity: 0
		transition: all 0.2s ease-in-out;

		.action-bt
			float: right
			display: block
			width: 15px
			height: 100%
			margin-right: 3px
			position: relative
			opacity: 1
			transition: all 0.2s ease-in-out;

	&:hover
		.action-bts
			opacity: 1
			visibility: visible

.accordion .big-bt.repeated-group-field
	margin-top: 0px
	border-radius: 0px 0px 2px 2px

.accordion.topic
	.form-content.open
		.children .field-ctn.param .description-bull
			background: var(--bgColor)!important
			position: absolute
			margin-top: 8px
			margin-left: -28px
			width: 24px
			height: 24px
			line-height: 28px
			border-radius: 20px
			text-align: center
			color: var(--selectedColor)
			cursor: pointer
			opacity: 0
			font-family: 'DinBold'
			font-size: 18px
			transition: linear opacity .1s
			box-shadow: 0 0 5px rgba(0,0,0,.1)
		.children .field-ctn.param .description-bull2
			background: var(--bgColor) !important
			position: absolute
			margin-top: -33px
			margin-left: -19px
			width: 24px
			height: 24px
			line-height: 28px
			border-radius: 20px
			text-align: center
			color: var(--selectedColor)
			cursor: pointer
			opacity: 0
			font-family: 'DinBold'
			font-size: 18px
			transition: linear opacity .1s
			box-shadow: 0 0 5px rgba(0,0,0,.1)

.form-accordion:hover > .description-bull
	opacity: 1 !important

.form-accordion:hover > .description-bull2
	opacity: 1 !important

</style>
