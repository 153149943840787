import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class OpenProject extends ActionAbstract {

    /**
     * @param projectId
     * @param currentUserType
     * @param step : success | fail
     */

    static get ID() {
        return 'actions:project:openProject';
    }

    run(state) {

        switch (this.getParam('step')) {
            case 'success':
                let currentUserType = this.getParam('currentUserType');
                let project = this.getParam('data');

                this.trigger('library:fetchAssets');
                this.trigger('template:fetchAll', { templates: project.value.template });
                this.trigger('editor:openEditor', { id: project.value._id });
                this.trigger('main:toggleBreadcrumb', { force: true });
                this.trigger('main:toggleNavigator', { force: true });
                this.trigger('main:gotoRoute', { routeParam: {name: 'project', params: { projectId: project.value._id }} });
                this.trigger('workspace:update', { id: project.value._id, target: 'project' });

                // get map library of the first xp found
                let xpConfigParams = {};
                if( project.custom.children.length > 0 ){
                    let fieldMapLibrary = helper.block.getField( project.custom.children[0] , 'mapLibrary' );
                    if( fieldMapLibrary ){
                        xpConfigParams.name = 'mapLibrary';
                        xpConfigParams.value = fieldMapLibrary.value;
                    }
                }

                this.trigger('main:updateXpConfig', xpConfigParams);

                // Add currentUserType to check user permission on save
                project.currentUserType = currentUserType;

                if(project.secretMemoriesNotFound){
                    let text = project.secretMemoriesNotFound.toString().replace(',', ', ');
                    this.trigger(
                        'main:modal:openModal',
                        {
                            title:'Secret Memories',
                            text:`The project contains secret memories and you don\'t have access to their values. Check the memories : ${text}.`,
                            continueButton: 'OK',
                            icon:'secret'
                        },
                    );
                }

                this.deps.mainStore.config.openProject = project;

                return this.composeState(state, {
                    project: project,
                    isLoading: false,
                    config: this.deps.mainStore.config.authoring,
                    dataTypes: this.deps.mainStore.config.datatypes,
                    defaultRef: this.deps.mainStore.config.defaultRef
                });
                break;
            case 'fail' :
                console.warn('fail', this.getParam('data'));
                break;
            default:

                this.deps.mainStore.setProjectURL( this.getParam('projectId') );

                this.deps.mainStore.project.getProject(this.getParam('projectId'), this.getParam('currentUser')).then( (project) => {
                    project.owner = this.getParam('owner') || state.user;
                    this.trigger('project:openProject', { projectId: this.getParam('projectId'), step: 'success', data: project, currentUserType: this.getParam('currentUserType')});
                }, () => {
                    this.trigger('project:openProject', { projectId: this.getParam('projectId'), step: 'fail' });
                });


                return this.composeState(state, {
                    project: {},
                    isLoading: true
                });
            break;
        }



    }



}

export default OpenProject;
