let vueDocKeyup = {

  // must be overridden
  listener: function()
  {

  },

  bind(el, binding) {

    if (typeof binding.value !== 'function') {
      throw new Error(`The given argument of v-doc-keyup: "${binding.value}", is not a function`)
    }

    vueDocKeyup.listener = binding.value
    document.addEventListener('keyup', vueDocKeyup.listener, false)
  },

  unbind () {
    document.removeEventListener('keyup', vueDocKeyup.listener, false)
  }
};


export default (Vue) => {
  Vue.directive('doc-keyup', vueDocKeyup)
}
