import UiObject from '../UiObject';

// will be override by an UiMapboxLinePath or UiMapOlLinePath object depending on experience MapLibrary parameter

class UiMapLinePath extends UiObject {

    constructor(id, props) {
        super(id, props, props);

        this.initParameters();
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-map-line-path",
            label: "Map LinePath",
            parent : "ui-maps",
            isDisplayed : true,
            isEmbeddable: true,
            nbSection: 1,
            childrenType: 'ui'
        };
    }

    
    initParameters(){

    }
}

export default UiMapLinePath;