var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion targets-bloc" }, [
    _c(
      "div",
      {
        staticClass: "accordion-title",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.accordion = !_vm.accordion
          }
        }
      },
      [
        _c("span", { staticClass: "text" }, [_vm._v("Home screen")]),
        _c("span", { staticClass: "arrow", class: { close: !_vm.accordion } })
      ]
    ),
    _c("div", { staticClass: "form-content", class: { open: _vm.accordion } }, [
      _c("div", { staticClass: "form-accordion isAccordion" }, [
        _vm._m(0),
        _c("div", { staticClass: "form-accordion-content open homeScreen" }, [
          _c("span", { staticClass: "textinput-ctn trigger-select" }, [
            _c("span", { staticClass: "select-arrow" }, [_vm._v(">")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedScreenId,
                    expression: "selectedScreenId"
                  }
                ],
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedScreenId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      return _vm.changeScreen()
                    }
                  ]
                }
              },
              _vm._l(_vm.screensList, function(screen, id) {
                return _c("option", { domProps: { value: screen.id } }, [
                  _vm._v(_vm._s(screen.name))
                ])
              }),
              0
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "clear", staticStyle: { height: "10px" } })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "text" }, [_vm._v("Screen Selection")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }