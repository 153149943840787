<!-- GlobalLinks vue -->

<template lang="pug">

div.links(v-if='userLogged!=null')

	div.categories
		ul
			li
				a(href='#tour') Tour
			li
				a(href='#showcase') Showcase
			li
				a(href='#contact') Participate

	div.logging
		ul
			//li 
				a(v-on:click.stop="goToDoc") DOC
			li 
				a(v-on:click.stop="goToHome") Home
			//li 
				router-link(to="/infos") INFORMATION
			//li(v-if="!store.config.session")
				a(v-on:click="goToLogin") LOG IN
			//li(v-if="store.config.session")
				router-link(to="/account") MY INFO
			li(v-if="!userLogged")
				a(v-on:click="goToConnexion") Log In
			li(v-if="userLogged")
				a(v-on:click="logout") Log Out
			li(v-if="!userLogged")
				a(v-on:click="goToSubscribe") Create An Account

</template>

<script>

	import actionManager from '../../actions'

	export default {

		props: ['user'],

		computed: {
			userLogged: function () {
				return !!this.user;
			}
		},

		methods: {

			'goToConnexion': function(){
				actionManager.trigger('prehome:changeTab', {tab: 'form'});
			},

			'goToSubscribe': function(){
				actionManager.trigger('prehome:changeTab', {tab: 'subscribe'});
				
			},

			'goToHome': function(){
				actionManager.trigger('prehome:changeTab', {tab: 'accueil'});
			},

			'goToDoc': function(){
                actionManager.trigger('main:gotoRoute', { routeParam: {name: 'doc', params: { page: null, subpage: null }} })
			},

			'logout': function(){
				actionManager.trigger('Logout');				
			}
		}
	}

</script>

<style lang="stylus">

.links
	//position: absolute
	//top: 10px 
	margin: 0
	padding : 0
	//li
		//display: inline-block
		//margin-left: 10px
		a 
			font-family: 'DinRegular', 'ComfortaaLight', Helvetica
			color: white
			font-size: 13px
			text-decoration: none
			cursor: pointer
		a:hover
			color: #F39A74
	.categories ul
		position: absolute
		top: 20px 
		left: 20px
		text-align: left
		li
			display: inline-block
			margin-right: 10px
			list-style-type: none
	.logging ul
		li
			right: 20px
			text-align: right
			display: inline-block
			margin-left: 10px
			list-style-type: none

</style>



