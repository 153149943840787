<template lang="pug">
    div.project-items
        project-item(v-for="(project, index) in projectsList",
        :projectIndex="index",
        :project="project" ,
        :currentUser="currentUser",
        @send-indexToProjectItems="ProjectToModal")
</template>

<script>

	import ProjectItem from  './ProjectItem.vue';

    export default {

        components: { ProjectItem },

        props: ['projectsList', 'currentUser'],

        methods: {
            ProjectToModal: function(event){
                //emit project data to modal scope (Projects.vue)
                this.$emit('send-index', event);
            }
        }
    }

</script>

<style lang="stylus">
.project-items
	display inline
</style>
