import InspectorActionAbstract from './InspectorActionAbstract';
import * as helper from '../../helper' ;

class UpdateValue extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateValue';
    }

    /**
     * @param targetId
     * @param newValue
     * @param fieldName
     * @param disableFieldUpdate [optional]
     */
    run (state) {

        this.checkField();

        return this.updateFieldValue(state);
    }


    runMemory(state) {
        if (this.getParam('fieldName') === 'name') {
            this.trigger('inspector:updateName', { targetId: this._target.value._id, newName: this.getParam('newValue') });
        }
        if (this.getParam('fieldName') === 'slug') {
            this.trigger('inspector:updateSlug', { targetId: this._target.value._id, newSlug: this.getParam('newValue') });
        }

        this.checkField();

        return this.updateFieldValue(state);
    }

    runObject(state) {
        this.checkField();

        this.updateUIValue();

        return this.updateFieldValue(state);
    }

    checkField() {
        if (!this.getParam('disableFieldUpdate')){
            this.trigger('block:updateFields', { id : this._target.value._id });
        }
    }

    updateFieldValue(state, composite = null) {
        let field, index;

        [field, index] = helper.block.getField(this._target, this.getParam('fieldName'), true);

        let compose = composite || { project: {} };
        compose.project[this._path + '.value.fields[' + index + '].value'] = this.getParam('newValue');

        this.trigger('block:updateCompositeName', { blocId : this.getParam('targetId') } );

        return this.composeState(state, compose);
    }

    updateUIValue(){
        if( this._ui ){
            helper.ui.setUIProp( this._ui, this.getParam('fieldName') , this.getParam('newValue') );
            this.trigger('editor:ui:updateUiState' );
        }
    }

}

export default UpdateValue;
