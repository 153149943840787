import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class UpdateLinkPriority extends ActionAbstract {

    /**
     * @param blocId : id of block
     * @param link : data of link (from & to)
     * @param priority : value
    **/

    static get ID() {
        return 'actions:editor:schematic:updateLinkPriority';
    }

    run(state) {
        
        let path;
        let block = null;
        [block,path] = helper.block.getBlockById( state.project, this.getParam('blocId') , true );

        let link = this.getParam('link');
        let linkIndex = helper.links.getIndexOfLink( block, link );

        if( linkIndex != -1 ){

            let compositeState = { 
                project: {},
                'editor.linkmenu.link.priority' : this.getParam('priority')
            };
             
            compositeState.project[path+'.value.links['+linkIndex+'].priority'] = this.getParam( 'priority' );
           
            return this.composeState(state, compositeState );
        }

        return false;
    }


}

export default UpdateLinkPriority;