/*
	Authoring Store instance -
	all function to get info of Authoring
*/

import { EventEmitter } from 'events';
import Vue from 'vue';
import "babel-polyfill";

class AuthoringStore extends EventEmitter {
	constructor(){
		super();

	}

	setMain( main ){
		this.main = main;
	}

	getJSONAuthoring( name ){
		return new Promise( function(resolve, reject){
			let self = this;

			fetch(this.main.config.previewURL+'authoring/'+name+'.json').then(function (response) {
				response.json().then(resolve);
			}, function (error) {
				console.warn(error);
				reject(error);
			});

		}.bind(this));
	}

	addJSONAuthoring(list){

		list.forEach( (block)=>{
			this.main.config.authoring.push( { _id : block._id, value: block } );
		});
		this.main.config.authoring.sort((el1, el2) => {
			return el1._id < el2._id ? -1 : 1;
		})
	}

	// Get all authoring default References for filter on save / load of a project.
	getDefaultRefs( forceRefresh ){

			return new Promise( function(resolve, reject){

				if( this.main.defaultRef && !forceRefresh ){
					resolve( this.main.defaultRef );
					return;
				}

				this.main.defaultRef = {};
				let self = this

				this.getJSONAuthoring('objectFieldsRefs').then( (objectFieldsRefs)=>{
					this.main.defaultRef.object = objectFieldsRefs;
				})
				.then(this.getJSONAuthoring('modulesFieldsRefs').then( (objectFieldsRefs)=>{
					this.main.defaultRef.module = objectFieldsRefs;
					
					resolve( self.main.defaultRef );
				}))
			}.bind(this))

	}

	// Get all authoring configs to create inspectors
	// /!\ this should be asynchronous when the user load a projetc/exp/screen/module
	// The json should be in the database
	
	getAuthoringConfig( forceRefresh ){
		return new Promise( function(resolve, reject){

			if( this.main.config.authoring && !forceRefresh ){
				resolve( this.main.config.authoring );
				return;
			}

			//let url = this.main.config.baseURLAuth+"_design/all/_view/list-all";
			let self = this;
			self.main.config.authoring = [];

			this.getJSONAuthoring('data-types').then( (datatypes)=>{
				this.main.config.datatypes = datatypes;
			})
			//store the depecrated modules from the app in a main.config
			this.getJSONAuthoring('deprecated').then( (deprecated)=>{
				this.main.config.deprecated = deprecated;
			})
			//store the buid only modules from the app in a main.config
			this.getJSONAuthoring('buildOnlyModules').then((buildOnlyModules)=>{
				this.main.config.buildOnlyModules = buildOnlyModules;
			})
			this.getJSONAuthoring('blocks').then( (blocks)=>{
				this.addJSONAuthoring(blocks);
			})
			.then(this.getJSONAuthoring('memories').then( (memories)=>{
				this.addJSONAuthoring(memories);
			}))
			.then(this.getJSONAuthoring('triggers').then( (triggers)=>{
				this.addJSONAuthoring(triggers);
			}))
			.then(this.getJSONAuthoring('objects').then( (objects)=>{
				this.addJSONAuthoring(objects);
			}))
			.then(this.getJSONAuthoring('modules').then( (modules)=>{ // POur chaque module ajoute le tree de l'inspector a l'authoring tree.
				this.addJSONAuthoring(modules);

				for( let inspector of self.main.config.authoring ){
					inspector.searchResult = true;
					if( inspector.value.type == 'memory' ){
						self.main.memory.addDefaultMemoryConfig( inspector );
					}

					if( inspector.value.type == "tree" ){
						this.main.config.authoringTree = inspector.value.tree;
					}
				}


				resolve( self.main.config.authoring );
			}));


		}.bind(this));
	}

	// Get inspector configuration by type
	findSettingByType( type , format ){

		for( let inspector of this.main.config.authoring ){
			if( format == undefined && inspector.value.type == type )
				return inspector;
			else if( format != undefined && inspector.value.format == format && inspector.value.type == type )
				return inspector;
		}

		return null;
	}



	findFieldInInspector( inspector, fieldName, subRepatedField = null ){
		if( inspector.name && inspector.name == fieldName ){
			if( inspector.coreFormat == 'Repeated' && inspector.form != null && subRepatedField != null ){
				return inspector.form[subRepatedField];
			}
			return inspector;
		}
		if( inspector.children && inspector.children.length > 0 ){
			let result = null;
			for (var i = 0; i < inspector.children.length; i++) {
				result = this.findFieldInInspector( inspector.children[i], fieldName, subRepatedField );

				if( result )
					return result;
			}
			return null;
		}
		return null;
	}


	findTypeInInspector( inspector, type ){
		if( inspector.type && inspector.type == type )
			return inspector;
		if( inspector.children && inspector.children.length > 0 ){
			let result = null;
			for (var i = 0; i < inspector.children.length; i++) {
				if( inspector.children[i].type && inspector.children[i].type == type  )
					return inspector.children[i];
				else
					result = this.findTypeInInspector( inspector.children[i], type );
				if( result )
					return result;
			}
			return null;
		}
		return null;
	}

}

export let authoring = new AuthoringStore();
