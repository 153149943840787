
import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class MoveBlock extends ActionAbstract {

    /**
     * @param id : id of block
     * @param x : new pos x of scene
     * @param y : new pos y of scene
    **/

    static get ID() {
        return 'actions:editor:schematic:moveBlock';
    }

    runDrag(state) {
        return this.composeState(state, { 
            editor : {
                draggingBlock : true
            }
        });
    }

    runRefresh(state){
        let path;
        [,path] = helper.block.getBlockById( state.project, this.getParam('id'), true );

        let compositeState = {
            project: {}
        };

        compositeState.project[path + ".value.coordinate"] = { x : this.getParam('x'), y : this.getParam('y') };
        this.composeState(state, compositeState);

        return false; 
    }

    runDrop(state){
        let path;
        [,path] = helper.block.getBlockById( state.project, this.getParam('id'), true );

        let compositeState = {
            project: {},
            editor : {
                draggingBlock : false
            }
        };

        compositeState.project[path + ".value.coordinate"] = { x : this.getParam('x'), y : this.getParam('y') };

        this.trigger('editor:schematic:calculateBoudingBox');
        
        return this.composeState(state, compositeState);


    }


}

export default MoveBlock;