import ActionManager from '../ActionManager';


import OpenEditor from './OpenEditor';
ActionManager.register(OpenEditor, ['stores:MainStore']);

import OpenParent from './OpenParent';
ActionManager.register(OpenParent, ['stores:MainStore']);


// SCHEMATIC ACTIONS

import SelectXP from './schematic/SelectXP';
ActionManager.register(SelectXP, ['stores:MainStore']);

import UpdateScene from './schematic/UpdateScene';
ActionManager.register(UpdateScene, ['stores:MainStore']);

import CalculateBoundingBox from './schematic/CalculateBoundingBox';
ActionManager.register(CalculateBoundingBox, ['stores:MainStore']);

import MoveBlock from './schematic/MoveBlock';
ActionManager.register(MoveBlock, ['stores:MainStore']);

import UpdateOrigin from './schematic/UpdateOrigin';
ActionManager.register(UpdateOrigin, ['stores:MainStore']);

import DrawLink from './schematic/DrawLink';
ActionManager.register(DrawLink, ['stores:MainStore']);

import ToggleLinkMenu from './schematic/ToggleLinkMenu';
ActionManager.register(ToggleLinkMenu, ['stores:MainStore']);

import DeleteLink from './schematic/DeleteLink';
ActionManager.register(DeleteLink, ['stores:MainStore']);

import UpdateLinkCast from './schematic/UpdateLinkCast';
ActionManager.register(UpdateLinkCast, ['stores:MainStore']);

import UpdateLinkPriority from './schematic/UpdateLinkPriority';
ActionManager.register(UpdateLinkPriority, ['stores:MainStore']);

import UpdateLinkActivation from './schematic/UpdateLinkActivation';
ActionManager.register(UpdateLinkActivation, ['stores:MainStore']);

import CheckLinks from './schematic/CheckLinks';
ActionManager.register(CheckLinks, ['stores:MainStore']);

import MoveLinkGrabber from './schematic/MoveLinkGrabber';
ActionManager.register(MoveLinkGrabber, ['stores:MainStore']);

// MENU ACTIONS

import Filter from './menu/Filter';
ActionManager.register(Filter, ['stores:MainStore']);

import DragDropBlock from './menu/DragDropBlock';
ActionManager.register(DragDropBlock, ['stores:MainStore']);

import ToggleMenu from './menu/ToggleMenu';
ActionManager.register(ToggleMenu);



// UI ACTIONS

import UpdateUiState from './ui/UpdateUiState';
ActionManager.register(UpdateUiState, ['stores:MainStore']);

import UpdateUiFields from './ui/UpdateUiFields';
ActionManager.register(UpdateUiFields, ['stores:MainStore']);

import InitUiState from './ui/InitUiState';
ActionManager.register(InitUiState, ['stores:MainStore']);


import SelectUi from './ui/SelectUi';
ActionManager.register(SelectUi, ['stores:MainStore']);


import UpdateCustomPreview from './ui/UpdateCustomPreview';
ActionManager.register(UpdateCustomPreview, ['stores:MainStore']);

// NO lONGER USED: replaced by menu/DragDropBlock

// import PickUi from './ui/PickUi';
// ActionManager.register(PickUi, ['stores:MainStore']);

// import DropUi from './ui/DropUi';
// ActionManager.register(DropUi, ['stores:MainStore']);