import AdvancedUiObject from '../AdvancedUiObject';

class UiCanvas extends AdvancedUiObject {
    constructor(id, props) {
        super(id, props, 'Canvas');

    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-canvas",
            label: "Canvas",
            parent : "ui-layouts",
            isDisplayed : true,
            isEmbeddable: true
        };
    }

    
    initParameters(){
        super.initParameters();

        this.setParamInvisible( 'addChild' );
        this.setParamInvisible( 'removeChild' );
        
        this.setDefaultValue( 'width', '100%' );
        this.setDefaultValue( 'height', '100%' );
        

    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }
    
    init(){
        super.init();
        this.set('dom', { id : 'canvas-'+this.id }, true );

    }
    
}

export default UiCanvas;