import ActionAbstract from '../ActionAbstract'

class ListAll extends ActionAbstract {

    static get ID() {
        return 'actions:template:listAll'
    }

    run(state) {

        switch (this.getParam('action')) {

            case "success":
                return this.composeState(state, {
                    templates: {
                        waiting: false,
                        list: this.getParam('list')
                    }
                })

            case "error":
                return this.composeState(state, {
                    isLoading: false,
                    templates: {
                        error: {
                            code: this.getParam('code'),
                            message: this.getParam('message'),
                            action: 'list'
                        }
                    }
                })

            default:

                this.deps.mainStore.template.listTemplates().then(
                    response => this.trigger('template:listAll', { action: 'success', list: response }),
                    error => this.trigger('template:listAll', { action: 'error', code: error.status, message: error.statusText })
                )
                return this.composeState(state, {
                    templates: {
                        waiting: true,
                        list: []
                    }
                })
        }
    }
}

export default ListAll
