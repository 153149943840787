
import ActionAbstract from '../../ActionAbstract';

class UpdateOrigin extends ActionAbstract {

    /**
    **/

    static get ID() {
        return 'actions:editor:schematic:updateOrigin';
    }

    run(state) {

        let ctn = document.getElementsByClassName('main-editor');

        let origin = { x : 0, y : 0} ;

		if( ctn && ctn.length > 0 ){
			this.mainContainer = ctn[0].offsetParent;
            origin = { x : this.mainContainer.offsetLeft, y : this.mainContainer.offsetTop } ;
		}

        
        return this.composeState(state, { 
            editor :{
                origin : { 
                    x : origin.x,
                    y : origin.y
                }
            }
        });
    }


}

export default UpdateOrigin;