/*
	User Store instance -
	all function to get info of Users
*/


import { EventEmitter } from 'events';
import * as helper from '../helper';
import PouchDBAuthentication from 'pouchdb-authentication';
import PouchDB from 'pouchdb';
import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

PouchDB.plugin(PouchDBAuthentication);

class UserStore extends EventEmitter {
	constructor(){
		super();
	}

	setMain( main ){
		this.main = main;
	}

	beforeCreate(){
		this.db = new PouchDB( this.main.config.baseURL, this.main.config.pouchOpts );
	}


	// Check if the user is logged in, fill his properties and emit a status change
	isLogged(){
		return new Promise( function(resolve, reject){
			let self = this;

			this.db.getSession(function (err, response) {

				if (err) {
					self.main.config.session = false;
					reject(Error('ErrorConnexion'));
				}
				else if (!response.userCtx.name) {
					self.main.config.session = false;
					reject(Error('ErrorUser'));
				}
				else {

					if( !self.main.config.user || self.main.config.user.name != response.userCtx.name ){
						// User non défini ou nouveau logged

						self.main.config.user = response.userCtx;

						self.getUser( response.userCtx.name ).then(function(){

							self.main.config.user.formatedName = self.formateUserName( self.main.config.user.name);
							//self.emit('sessionStatus', {status:true});

							self.main.config.session = true;

							// Toutes les infos du user sont récupérées -> Ok
							resolve();

						});
					}
					else{
						// User déja connu, -> Ok
						resolve();
					}



				}
			});

		}.bind(this));
	}

	// Get User metadata
	getUser(name){
		return new Promise( function(resolve, reject){
			let self = this;

			let userPrefix = 'org.couchdb.user:';
			if (name.indexOf(userPrefix) !== -1) {
				name = name.substring(userPrefix.length);
			}
			this.db.getUser(name, function(err, response){
				if(err){
					if (err.name === 'not_found') {
						// typo, or you don't have the privileges to see self user
						// @todo: to be set in verbose mode [ticket NS-113]
						// console.log("User not found");
					} else {
						// some other error
						// @todo: to be set in verbose mode [ticket NS-113]
						//console.log('Error user loggin');
					}
					reject(err);
				} else {
					// response is the user object
					self.main.config.metaUser = response;
					//LibraryStore.getMedia();
					resolve(response);

				}
			});

		}.bind(this));
	}

	// deprecated consider using helper
	formateUserName( name ){
		return helper.user.formatUserName(name)
	}


	// Sign in a new user
	// User has to be an object with a value email and password
	login( user ){
		return new Promise( function(resolve, reject){

			let self = this;

			let ajaxOpts = {
			  ajax: {
			    headers: {
			      Authorization: 'Basic ' + window.btoa(user.email + ':' + user.password)
			    }
			  }
			};

			this.db.login( user.email, user.password, ajaxOpts).then( function (response) {


				// Get all user data and trigger resolve
				self.isLogged().then(function(){
					// LOGGED IN
					self.main.config.session = true;

					resolve();
				});
				//self.emit('userLoggedIn', {});

			}, function(error){
				reject(error);
			});

		}.bind(this));
	}


	// Logout the current User
	logout(){
		let self = this;

		this.db.logout(function (err, response) {
		  	if (err) {
				//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
				//reject(error);
		  	}
		  	else{
		  		self.main.resetConfig();
		  	}
		});
	}



	// USER SESSION ON PROJECT
	isUseProject( project ){
		return new Promise( function(resolve, reject){
			var url = (this.main.config.baseURL+project.value._id);

			Vue.http({url: url, method: 'GET'}).then(function (response) {

				project.value._rev = response.body._rev; // Update with potential new _rev

				let isUsed = true;
				let currentDate = new Date();

				if( response.date == null && response.owner == null ){
					// NOBODY ON THIS PROJECT
					// @todo: to be set in verbose mode [ticket NS-113]
					//console.log("NOBODY ON THIS PROJECT");
					isUsed = false;
				}
				else if( stores.config.user.name == response.owner ){
					// OWN SESSION
					// @todo: to be set in verbose mode [ticket NS-113]
					//console.log("OWN SESSION");
					isUsed = false;
				}
				else if( response.date != null && stores.config.user.name != response.owner ){
					let dateSession = new Date(response.date);

					let dayRelativeDifference =   currentDate.getHours()*60 + currentDate.getMinutes() - dateSession.getHours()*60 - dateSession.getMinutes();

					if( dayRelativeDifference > stores.config.SESSION_DURATION ){
						// @todo: to be set in verbose mode [ticket NS-113]
						//console.log("SESSION ENDED");
						isUsed = false;
					}
				}


				resolve( isUsed );

			}, function (error) {
				//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
				reject();
			});


		}.bind(this));
	}


	useProject( project ){
		this.main.config.openProject = project;
		this.updateSessionTime();
		this.main.config.sessionTimer = setInterval( this.updateSessionTime.bind(this) , (this.main.config.SESSION_DURATION-0.5)*60*1000 );

	}

	updateSessionTime(){

		if( this.main.config.openProject == null ){
			this.freeProject();
			return;
		}

		var proj = Object.assign( {}, this.main.config.openProject );
		var url = this.main.config.baseURL+proj.value._id;

		proj.value.session = {
			date : new Date().getTime(),
			owner : this.main.config.user.name
		};

		let self = this;

		Vue.http.put(url, proj.value).then(function (response) {
			self.main.config.openProject.value._rev = response.body.rev;

		}, function (error) {
			//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
			//reject(error);
		});

	}



	freeProject( ){

		clearTimeout(this.main.config.sessionTimer);
		this.main.config.sessionTimer = null;

		let project = Object.assign({}, this.main.config.openProject);

		let url = this.main.config.baseURL+project.value._id;

		project.value.session = {
			date : null,
			owner : null
		};

		this.main.config.openProject = null;

		Vue.http.put( url , project.value ).then(function (response) {

		}, function (error) {
			//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
			//reject(error);
		});

	}


	check( pass ){
		return (pass == this.main.config.check) ? true : false;
	}

	/**
	 * Allows to send to the user database the secrets of the user.
	 * @param {string} user – The couchDB user ID.
	 * @param {object} secrets – The list of the new secrets to add.
	 */
	addSecretsToUserDB(user, secretsToAdd) {
		if (!user || !secretsToAdd) {
			console.error('Failed to add secrets to the user\'s private database.');
			return;
		}

		this.getUser(user).then((response) => {
			const userSecrets = (response.secrets) ? response.secrets : {};

			Object.keys(secretsToAdd).forEach((secretID) => {
				userSecrets[secretID] = secretsToAdd[secretID];
				
				if (secretsToAdd[secretID].deleted === true) {
					delete userSecrets[secretID];
				}
			});

			const body = {
				userSecrets,
				userName: response._id,
			}
	
			const urlreq = `${this.main.config.previewURL}authoring/manageUserSecrets`;
	
			Vue.http({ url: urlreq, body: body, method: 'POST' })
			.catch((error) => {
				// @todo: contextualiser l'erreur dans le reject [ticket NS-412]
				console.error(error);
			});
		});
	}
}

export let user = new UserStore();
