import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class Share extends ActionAbstract {
    static get ID() {
        return 'actions:projects:share';
    }

    async run(state, resp) {
        var self = this;

        let method = this.getParam('method')
        let project = this.getParam('project')
        let type = this.getParam('type')
        let user = this.getParam('user')

        let callback = this.getParam('callback')

        let requestModification = new Promise ( (resolve, reject) => {
            return this.deps.mainStore.projects.manageRole(method, project, type, user).then( (response)=> {
                resolve(response)
            }).catch( (err) => {
                reject(err)
            })
        })

        await requestModification
        .then( (resReq) => callback(resReq))
        .catch( (error) => callback(null, error))
    }
}

export default Share;
