import React from 'react';
import CommonText from '../CommonText';

import RaisedButton from 'material-ui/RaisedButton';

export default class Button extends CommonText {
    
    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            display : 'block',
        });
    }
    
    render() {

        let content = this.props.innerText ;

        let weight = 500;
        if( this.defaultStyle.fontWeight == "bolder" )
            weight = 600;

        let materialStyle = { 
            backgroundColor : "inherit" , 
            color : "inherit", 
            width : "100%", 
            height :  "100%", 
            textAlign: "inherit" ,
            fontFamily : "inherit" ,
            fontSize : "inherit" ,
            textTransform : this.defaultStyle.textTransform ,
            textDecoration : this.defaultStyle.textDecoration ,
            fontWeight : weight ,
            letterSpacing : "inherit" ,
            fontStyle : "inherit" ,
            boxShadow: 'none'
        } ;

        let textMaterialStyle = Object.assign( {}, materialStyle );
        textMaterialStyle.backgroundColor = null;
        
        
        return this.wrapHelper(  
            <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                <RaisedButton label={content} style={materialStyle} buttonStyle={materialStyle}  labelStyle={textMaterialStyle}  />
            </div>
        );

    }

}