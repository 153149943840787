<template lang="pug">
div
	top-menu(:app-state="appState")
	breadcrumb(:sort="sortList", :searchable="searchable", :app-state="appState")
	project-filter(:current-filter="currentFilter", :navigator-open="navigatorOpen")
	div.draw-zone(v-bind:class="{ 'navigatorOpen': navigatorOpen}")
		div.projects-ctn
			div.padding-ctn
				div.new-project-bt(v-on:click="addProject")
					div.info-bubble
						span New Project

				project-items(v-if="projectsList && projectsList.length",
				:projects-list="projectsList",
				:currentUser="this.appState.user",
				@send-index="ProjectIndexToModal")

	spinner(v-if="waiting")

	modal(v-if="appState.modal && appState.modal.open",
			:settings="appState.modal", @send-refresh="ProjectIndexToModal",
			:selectedProject="selectedProject",
			:index="selectedIndex")
</template>

<script>

	// STORE
	//import MainStore from '../stores/MainStore';
	// import { stores } from '../stores/MainStore.js';
	import actionManager from '../actions';

	// COMMON COMPONENTS
	import TopMenu from  './common/TopMenu.vue';
	import Breadcrumb from  './common/Breadcrumb.vue';
	import Spinner from  './common/Spinner.vue';
	import Modal from  './common/Modal.vue';

	// COMPONENTS FOR PROJECTS
	import ProjectFilter from  './projects/Filter.vue';
	import ProjectItems from  './projects/ProjectItems.vue';

	export default {

		components: { ProjectFilter, ProjectItems, TopMenu, Breadcrumb, Spinner, Modal },

		props: ['appState'],

		data() {
			return {
				// navigatorOpen:true,
				// projects: [],
				// displayedProjects:[],
				// currentFilter: 'all',
				// openedProjects: [],
				sortList : ['Date', 'Test'],
				searchable: true,
				selectedIndex: 0,
				selectedProject: {}, // UUID from projectItems/projectItem
				// waiting: true
			}
		},

		computed : {
			currentFilter: function () { return this.appState.projects === undefined ? 'all' : this.appState.projects.currentFilter },
			navigatorOpen: function () { return this.appState.main === undefined ? 'true' : this.appState.main.navigatorOpen },
			projectsList: function () {
				if(this.appState.projects && this.appState.projects.list !== undefined && this.appState.projects.list.length > 0) {

					// this.selectedProject = this.appState.projects.list[this.selectedIndex].value
					// this.selectedProject.index = this.selectedIndex
				}
				return this.appState.projects === undefined ? [] : this.appState.projects.list
			},
			waiting: {
				get: function () {
					return this.appState.isLoading === undefined ? true : this.appState.isLoading
				},
				set: function (bool) {
					this.appState.isLoading = bool
				}
			}
		},

		created: function(){

			this.getProjects( );

			// if( stores.config.openProject != null ){
			// 	stores.user.freeProject();
			// }
		},


		methods: {

			ProjectIndexToModal(event) {
				this.selectedProject = this.appState.projects.list[event].value
				this.refreshProjectList()
			},

			getProjects( forceRefresh ) {
				actionManager.trigger('projects:listAll', { forceRefresh : true } );
			},

			addProject(){
				actionManager.trigger('project:createProject');
			},

			newProjectAdded( newProject ){

				// var self = this;
				// stores.config.projects.push( newProject );
				// self.$router.push( {name: 'project', params: { projectId: newProject.value._id }} );
			},

			refreshProjectList(){
				this.waiting = true;
				this.getProjects( true );
			},

		}
	}

</script>

<style lang="stylus">

.projects-ctn
	text-align: left
	max-height: 100%
	overflow: auto

	.padding-ctn
		padding: 90px 10px 10px 10px

.new-project-bt
	display:inline-block
	width: 250px
	height: 250px
	position: relative
	margin: 10px
	box-shadow 0px 0px 10px var(--shadowColor)
	border-radius: 20px
	cursor:pointer
	transition: all 0.2s ease-in-out;
	background-color: var(--bgColor)
	background-image: url(../assets/images/bt-plus.png)
	background-size: 50px 50px
	background-position: center center
	background-repeat: no-repeat

	&:before
		content: ''
		display: block
		position: relative
		width: 100%
		padding-top: 80%

	&:after
		content: ''
		display: block
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		background-color: var(--selectedColor)
		border-radius: 20px
		opacity: 0
		transition: all 0.1s ease-in-out;
		background-image: url(../assets/images/bt-plus.png)
		background-size: 50px 50px
		background-position: center center
		background-repeat: no-repeat

	&:hover
		box-shadow 0px 0px 10px rgba(0,0,0,0.1)
		&:after
			opacity: 0.3






.info-bubble
	position: absolute;
	top: 0px
	left: 50%
	width: auto
	height: auto
	white-space: nowrap
	padding: 0px
	background: #FFFFFF
	-webkit-border-radius: 4px
	-moz-border-radius: 4px
	border-radius: 4px
	box-shadow 1px 3px 6px rgba(0,0,0,0.2)
	transform: translateX(-50%) translateY(100%) scale(0)
	z-index: 10000
	opacity: 0
	transition: all .3s ease;

	span
		display: block
		margin: 5px 15px
		font-family: 'DinRegular', 'ComfortaaLight', Helvetica
		color: #58585B
		font-size: 13px

	&:after
		content: ''
		position: absolute
		border-style: solid
		border-width: 7px 7px 0
		border-color: #FFFFFF transparent
		display: block
		width: 0
		z-index: 1
		bottom: -7px
		left: 50%
		margin-left: -7px


.new-project-bt:hover
	.info-bubble
		transform: translateX(-50%) translateY(-50%) scale(1)
		opacity: 1


</style>
