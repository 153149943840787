
<template lang="pug">

div#content
    h1 Nodal.Studio
    p#chapo Nodal.Studio is a visual programming environment developed by Orbe. <br /> It was designed to create interactive multimedia works with audio and visual generation. <br /> The main environment is for creating project is called "Authoring".
    p The authoring is dedicated to create the projects, which are organized with specific elements called <strong>Nodes</strong>:<br /> <br />
        ul#hierarchy
            li <strong>Project : </strong>The application workspace.
            li <strong>Experience : </strong>The sub-application(s) of the project, with specific targets.
            li <strong>Screen : </strong>The visual states of the experience.
            li <strong>Process : </strong>Logical elements used to enhance the experience(s) and the screen(s).
    p These nodes are organized in a specific hierarchy: <br /> <br />
        ul#hierarchy
            li The Project is the root level.
            li Each Project contains one or several Experience(s).
            li Each Experience contains one or several Screen(s) and a Logic level.
            li Each Screen contains one HTML View to edit the visual elements and a Logic level.
            li Each Logic contains one or several Process(es) and Trigger(s).
    
    img.illustration(src="../../assets/images/doc/env/level.jpg")

    h2 Main actions menu
    
    img.illustration(src="../../assets/images/doc/env/top.png")

    p The main actions menu gives access to options available at any moment during the designing in Nodal.Studio :
        ul#hierarchy
            li 
                img(src="../../assets/images/doc/env/home.png", height="20px", width="20px")
                span The home button to get back to all the projects.
            li 
                img(src="../../assets/images/doc/env/save.png", height="20px", width="20px")
                span The save button to save all the changes made on the project.
            li 
                img(src="../../assets/images/doc/env/lib.png", height="20px", width="20px")
                span The library button to access to all the media added to the project.
            li 
                img(src="../../assets/images/doc/env/play.png", height="20px", width="20px")
                span The play button to preview the experience or a specific screen.

    h2 Hierarchy

    img.illustration(src="../../assets/images/doc/env/hierarchy.png")

    p The hierarchy is the place where the entire list of components of the project is displayed.<br /> It contains all the levels of the project and the different elements that has been added to it.<br /> When an element is clicked, the schematic interface will automatically jump to its level to display the associated content.

    p When positioning the cursor over an element in the hierarchy, contextual actions are available :
        ul#hierarchy
            li 
                img(src="../../assets/images/doc/env/delete.png", height="15px", width="15px")
                span Deleting the element
            li 
                img(src="../../assets/images/doc/env/inspect.png", height="15px", width="15px")
                span Opening the inspector of the element
            li 
                img(src="../../assets/images/doc/env/view.png", height="15px", width="15px")
                span Viewing the screen on a new window
    


    h2 Schematic interface

    img.illustration(src="../../assets/images/doc/env/schematic.png")

    p The schematic interface is the place where are the modules are connected and arranged in order to design the project. Each  modules have different inputs and outputs to be connected one to another in order to pass a value, transmit a state, change a color, etc. These inputs and outputs can be set or changed in the inspector (view below).

    p At every level of the authoring, a <span style="background:#58595b; color:#ffffff; display:inline-block; width:20px; height:20px; border-radius:20px; font-weight:bold; text-align:center; line-height:20px;">+</span> button is available at the left bottom corner of the schematic interface. It opens a menu that gives access to all the modules that can be added at this specific level :
        ul#hierarchy
            li 
                strong Project :
                span Types of targets that compose the project.
            li 
                strong Experience :
                span Types of screens that compose the experience.
            li 
                strong Screen :
                span Types of processing modules.
            li 
                strong Screen Editor :
                span Graphical modules to be displayed on the screen.
            li 
                strong Process :
                span Logical modules providing interactions to the project



    h2 Inspector

    img.illustration(src="../../assets/images/doc/env/inspector.png")

    p The inspector is displayed every time an element is selected in the hierarchy or in the schematic interface. It gives all the informations and parameters available for the selected element.
    p From this inspector, an module can be renamed and different parameters can be set. But the main feature of the inspector is to give access to the the inputs and outputs of a module : each one can be displayed or hidden to connected the modules.
    p At the bottom of the inspector, a save button allow to save the changes made on a module.


    h2 Screen editor

    img.illustration(src="../../assets/images/doc/env/editor.png")

    p The screen editor is the main interface to design all the visual element of the screen. It is available for any screen when its HTML View is selected in the hierarchy.
    p The different modules (Paragraphs, Buttons, Boxes, Images, etc.) are available directly via the <span style="background:#58595b; color:#ffffff; display:inline-block; width:20px; height:20px; border-radius:20px; font-weight:bold; text-align:center; line-height:20px;">+</span> button by a simple clic on the selected element, or by dragging the element directly on the screen preview, on one of its container.



    h2 Library

    img.illustration(src="../../assets/images/doc/env/library.png")

    p The library (accessible from the icon <img src="../../assets/images/doc/env/lib.png" "height=20px" width="20px" /> in the Main action menu) is the place where all the media used in the project are stored. There are different types of files that are supported :
        ul#hierarchy
            li Images (JPG, PNG, GIF)
            li Sounds (MP3, WAV)
            li Videos (MP4, WEBM, OGG)
            li Files (Imported JSON)

    p It is a place to find all the media easily after they have been added.<br /><br />


</template>

<script>

	export default {


		props:['appState'],

		data() {
			return {
			};
		},

	    methods:{


	    }

    }

</script>

<style>

</style>