<template lang="pug">

div.accordion.topic.stateManager
	div.accordion-title(v-on:click.stop="topicAccordion = !topicAccordion")
		span.text Event Manager
		span.arrow(v-bind:class="{'close':!topicAccordion}")

	div.form-content(v-bind:class="{'open':topicAccordion}")
		div.children
			div.form-accordion.array-row-fields.group.isAccordion
				div.title(v-on:click.stop="accordion = !accordion")
					span.text Event {{bloc.side}}
					span.arrow(v-bind:class="{'close':!accordion}")


				div.form-accordion-content(v-bind:class="{'open':accordion}")
					div.big-bt.field-group

						div.field-ctn.state(v-for="state in states")

							div.field-wrapper
								span.text {{state}}
								span.trash
									div.action-bt.bt-trash(v-on:click="removeState(state)" )
							div.clear

						div.clear


					div.big-bt.field-group.adder

						div.field-ctn
							div.field-wrapper
								span.textinput-ctn.newStateInput
									input.value.newState(type="text", placeholder="New event name", v-model="newState" )
							div.clear
						div.clear

						div.big-bt.bt-repeat-form(v-on:click="addState", v-bind:class="{'disabled':disabled}" )
							span.text Add
							span.bt-plus +



</template>

<script>

import * as helper from '../../../helper';
import actionManager from '../../../actions';

export default {

	name: 'state-manager',

	props:[ 'project', 'target', 'bloc' ],

	data() {
		return {
            topicAccordion: true,
			accordion: true,
			newState : ""
		}
	},

	computed: {

		'states' : function(){
			if( this.target.value.states && this.target.value.states[ this.bloc.side ] )
				return this.target.value.states[ this.bloc.side ];
			return [];
		},

		disabled: function () {
			return this.newState === "";
		}
	},

	watch: {

	},

	destroyed: function(){

	},

	methods:{

		addState( ){
			if( this.newState != '' && this.states.indexOf( this.newState ) == -1 ){
				actionManager.trigger('inspector:updateState', { subaction : 'add', targetId: this.target.value._id, side : this.bloc.side , state : this.newState });
				this.newState = "";
			}
		},

		removeState( stateName ){
			if( stateName != '' && this.states.indexOf( stateName ) != -1 ){
				//actionManager.trigger('inspector:updateState', { subaction : 'remove', targetId: this.target.value._id, side : this.bloc.side , state : stateName });
				actionManager.trigger('main:modal:openModal', {title:'Are you sure you want to delete this state ?', text:'You will lost definitely all attached data', nextAction:'inspector:updateState', nextActionParam: { subaction : 'remove', targetId: this.target.value._id, side : this.bloc.side , state : stateName }, icon: "delete" })
			}
		}
	},

}

</script>

<style lang="stylus">

.stateManager

	.state
		position relative

		.trash
			position absolute
			top 0
			right 10px
			height 20px
			width 50px
			.bt-trash
				width 10px

	div.big-bt.field-group.adder
		/*padding : 8px 8px 8px 8px */
		padding : 0px 0px 0px 0px
		margin-top: -5px

	.textinput-ctn input.value.newState
		/*width 95%*/
		color white
		border-color white
		text-align left
		line-height 40px
		border-radius: 4px
		font-size: 14px
		height: 40px
		width: calc(100% - 10px)
		padding-left: 10px
		margin-left: 0px
		margin-top: 0
		width: calc(100% - 10px)

	.newStateInput
		width 100%
		height 40px
		margin-left: 0px

	.disabled
		opacity: 0.5

</style>
