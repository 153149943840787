var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.family.length,
      staticClass: "classic-bloc",
      class: {
        instance: _vm.isInstance,
        template: _vm.isTemplate,
        current: _vm.isCurrent,
        deprecated: _vm.isDeprecated,
        buildOnly: _vm.isBuildOnly
      }
    },
    [
      _c("div", { staticClass: "bloc" }, [
        _vm.isBuildOnly
          ? _c("span", { staticClass: "builOnlyBull" }, [
              _vm._v("!"),
              _c("span", { staticClass: "buildOnlyText" }, [
                _vm._v("The module is only available for built applications.")
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "title" }, [
          _c("span", [
            _c(
              "span",
              { staticClass: "icon-ctn" },
              [_c("icon", { attrs: { name: _vm.iconName } })],
              1
            ),
            _c("span", { staticClass: "type" }, [
              _vm._v(_vm._s(_vm.displayTypeName))
            ]),
            _c("span", { staticClass: "name" }, [
              _vm._v(
                _vm._s(_vm.bloc.custom.compositeName) +
                  " " +
                  _vm._s(_vm.bloc.value.name)
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "plugs", class: { empty: _vm.isEmpty } }, [
            _c(
              "div",
              { staticClass: "plug-list output-list" },
              _vm._l(_vm.connexions, function(connexion, name) {
                return _c(
                  "div",
                  [
                    connexion.out
                      ? _c("field", {
                          attrs: {
                            "app-state": _vm.appState,
                            connexion: connexion,
                            fieldname: name,
                            side: "output",
                            "initial-bloc": _vm.bloc
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "plug-list input-list" },
              _vm._l(_vm.connexions, function(connexion, name) {
                return _c(
                  "div",
                  [
                    connexion.in
                      ? _c("field", {
                          attrs: {
                            "app-state": _vm.appState,
                            connexion: connexion,
                            fieldname: name,
                            side: "input",
                            "initial-bloc": _vm.bloc
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "clear" })
          ])
        ])
      ]),
      _c("div", { staticClass: "bottom-menu" }, [
        _c("div", { staticClass: "action-bts" }, [
          _c("div", {
            staticClass: "action-bt bt-inspect",
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.inspectBloc($event)
              }
            }
          })
        ]),
        _c("div", { staticClass: "action-bts" }, [
          _c("div", {
            staticClass: "action-bt bt-duplicate-white",
            on: {
              click: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.duplicateBloc($event)
              }
            }
          })
        ]),
        _c("div", { staticClass: "action-bts" }, [
          _c("div", {
            staticClass: "action-bt bt-export-white",
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.exportBloc($event)
              }
            }
          })
        ]),
        _c("div", { staticClass: "action-bts" }, [
          _c("div", {
            staticClass: "action-bt bt-trash-white",
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.deleteBloc($event)
              }
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }