<!-- Memory vue -->

<template lang="pug">
div.accordion.targets-bloc(v-bind:class="{'hide':isTemplate}")
	div.accordion-title(v-on:click.stop="accordion = !accordion ")
		span.text Process triggering
		span.arrow(v-bind:class="{'close':!accordion}")

	div.form-content(v-bind:class="{'open':accordion}")

		div.form-accordion.isAccordion()

			div.title 
				span.text Process Auto Start

			div.form-accordion-content.open

				span.textinput-ctn.trigger-select.parentScreen
					span.text Starts With Parent Screen 				
					span.checkbox(v-bind:class="{'selected':choice }", v-on:click.stop="toggleValue()")
						input(type="checkbox", v-model="choice")

	div.clear(style="height:10px")
								
</template>

<script>

import actionManager from '../../../actions';

export default {

	props:[ 'target' ],

	data() {
		return {
			accordion: true,
			choice: true
		}
	},

	computed: {

		'isTemplate': function(){
			if( this.target.value.type === "process"){
				let templateField = this.target.value.fields.find((f) => { return f.name === "template" } );
				if( templateField && templateField.value === true )
					return true;
			}
			return false;
		},

	},

	created: function(){

		if (this.target.value.autoStart !== undefined) {
			this.choice = this.target.value.autoStart;
		}

	},

	methods:{

		toggleValue: function() {
			this.choice = !this.choice;
			actionManager.trigger('inspector:updateProcessAutoStart', { targetId: this.target.value._id, choice: this.choice });
		},

	}

}

</script>

<style lang="stylus">
.textinput-ctn
	.checkbox
		float: right
		margin-top : 2px
		margin-right: 2px

.hide
	display: none


</style>



