import React from 'react';

export default class MediaQuery extends React.Component {

    constructor(){
        super();
        
        this.medias = [ 'mobile', 'tablet', 'desktop' ];
        
    }

    render() {

        let display = false;

        let currentIndex = this.medias.indexOf( this.props.displayMode );
        
        if( this.props.maxMode ){
            let indexMax = this.medias.indexOf( this.props.maxMode );
            if( indexMax >= currentIndex )
                display = true;
        }
        else if( this.props.minMode ){
            let indexMin = this.medias.indexOf( this.props.minMode );
            if( indexMin <= currentIndex )
                display = true;
        }
        else if( this.props.mode && this.props.mode == this.props.displayMode ){
            display = true;
        }
        
        if( display ){
            return ( this.props.children );
        }
        return ( null  );
    }

}