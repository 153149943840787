<!-- BlocMenu vue -->
<template lang="pug">
div.block-menu.noselect
	div.blockList(v-if="menu.list", v-bind:class="{'open':menu.open}")
		bloc-group(v-if="menu.open", :group="recentlyUsed", :level="0")
		bloc-group(v-if="menu.open", v-for="group of menu.list", :group="group", :level="0")

		// import options
		div.block-group(v-if="allowImportBlock")
			ul
				li.title
					icon(name="others")
					span.text Others
				li.block.noselect.import(v-on:click="importToScene()")
					span.text Import Block


	div.searchBar(v-bind:class="{'open':menu.open}")
		span.ico-loupe
		input#itemBlocSearch(v-model="search", autofocus, autocomplete="off", v-on:keyup.27="toggleListBlock")

	div.btAddBlock(v-on:click="toggleListBlock", v-bind:class="{'open':menu.open}")
</template>

<script>

import actionManager from '../../../actions';
import BlocGroup from  './BlocGroup.vue';
import Icon from  '../../common/Icon.vue';

export default {

	props: ['menu', 'target'],

	components: { BlocGroup, Icon },

	data() {
		return {
			search: null,
			open: false,
			recentlyUsed: {
				alwaysVisible: true,
				title: 'Recently Used',
				icon: 'recentlyused',
				components: [ ]
			},
			targethasChanged: false,
		}
	},

	created: function(){
		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$on('clickDrop', this.onClickDrop)
	},

	beforeDestroy: function(){
		// HACK: Instead of installing Vuex, and another dependencies,
		// use the root instance as an event hub to communicate between components.
		// If limits appear, have a look at https://vuex.vuejs.org
		// (since migration Vue v1 to v2)
		// TODO: If limits appear, we need to known the component emitter for ex (here event.source : "<Root>"), prefers use Vuex at https://vuex.vuejs.org
		this.$root.$off('clickDrop', this.onClickDrop)
	},

	watch: {

		'search': function( val , oldVal ){
			if( this.targetMenu && this.targethasChanged){
        		actionManager.trigger('editor:menu:filter', { type: this.targetMenu.value.type , search : val, id: this.targetMenu.value._id});
			}
		},

		'menu.open': function( val, oldVal ){
			if( val){
				this.search = '';
				document.getElementById("itemBlocSearch").focus();
			}
			else{
				document.getElementById("itemBlocSearch").blur();
			}
		},

		'targetMenu': function(val, oldval){
			if(val !== oldval){
				this.targethasChanged = true
				this.generateTarget()
			}
		}

	},

	computed: {
		'targetMenu': function(){
			return this.generateTarget()
		},

		allowImportBlock: function() {
			return this.target && this.target.value.type !== 'object';
		}
	},

	methods:{
		generateTarget: function(){
			this.targethasChanged = true
			return this.target
		},

		toggleListBlock: function(){
			actionManager.trigger('editor:menu:filter', { type: this.targetMenu.value.type, id: this.targetMenu.value._id});
			actionManager.trigger('editor:menu:toggleMenu');
		},

		importToScene: function(){
			actionManager.trigger('library:open' , { importJSON: true } );
		},

		// ORIGINAL clickDrop EMITTER NOT FOUND, THIS MAY NEVER BE CALLED
		// (migration Vue v1 to v2)
		onClickDrop: function(){
			actionManager.trigger('editor:menu:toggleMenu', {status: false});
		}
	}

}

</script>

<style lang="stylus">

.block-menu
	z-index: 110
	position: absolute
	display:block
	bottom: 25px
	left: 60px
	transition: all 0.3s ease-in-out;

	.btAddBlock
		z-index: 1000
		position: absolute
		display:block
		bottom: 15px
		right: -40px
		width: 60px
		height: 60px
		background-color: var(--breadColor)
		background-image: url(../../../assets/images/addBtn.png)
		background-repeat: no-repeat
		background-size: 30px 30px
		background-position: center center
		border-radius: 60px
		box-shadow 0px 0px 10px var(--shadowColor)
		cursor: pointer
		transform-origin: 50% 50%
		transform: rotate(0deg)
		transition: all 0.5s cubic-bezier(0.770, 0.000, 0.175, 1.000)
		&:hover
			background-color: var(--selectedColor)

		//&.navigatorOpen
		//	left: 22%

		&.open
			transform: rotate(315deg)


	.blockList
		z-index: 90
		position: absolute
		display:block
		bottom: 90px
		left: -40px
		width: 257px
		background-color: var(--bgColor)
		box-shadow 0px 0px 10px var(--shadowColor)
		padding-bottom: 5px

		overflow-y: auto
		max-height: 0px
		opacity: 0
		transform: translateY(50px) scale(0.8)
		transform-origin: 0% 100%
		transition: all 0.4s cubic-bezier(0.770, 0.000, 0.175, 1.000)

		&.open
			max-height: 500px
			opacity: 1
			transform: translateY(0px) scale(1)
			transition: all 0.8s cubic-bezier(0.770, 0.000, 0.175, 1.000)
			border-radius: 16px
			padding-bottom: 45px

		ul
			margin: 10px
			padding: 0
			list-style-type: none
			overflow: hidden
			border-radius: 8px
			background: var(--addElementColor)

			li
				display: block
				color: #58585B
				font-size: 13px

				&.title
					background-color: var(--level1Color)
					color: white
					padding: 0px 10px 0px 36px
					position: relative
					height: 32px
					line-height: 32px
					font-size: 14px
					font-family: 'DinBold'

					span.icon
						display: block
						width: 20px
						height: 20px
						position: absolute
						top: 5px
						left: 8px
						background-color: white
						transform: none

				&.block
					cursor: pointer
					padding: 0px 10px
					font-size: 14px
					line-height: 34px
					background-color: var(--bgColor)
					height: 32px
					margin: 6px
					border-radius: 4px
					color: var(--mainColor)
					text-transform: capitalize
					transition: linear all .1s !important
					&:hover
						background-color: var(--modalColor);
					&:hover ~ .docLink
						opacity: 1

	.block-group.level1, .block-group.level2, .block-group.level3
		ul
			margin: 0
			font-size: 12px
			border: none
			li
				&.title
					background-color: var(--level2Color)

	.block-group.open.level1 ul
		border-radius: 0

	.searchBar
		background-color: var(--topSelectColor)
		padding: 5px 0px
		position: absolute
		width: 257px
		bottom: 90px
		left: -40px
		z-index: 100
		overflow-y: auto
		max-height: 0px
		opacity: 0
		transform: translateY(50px) scale(0.8)
		transform-origin: 0% 100%
		transition: all 0.4s cubic-bezier(0.770, 0.000, 0.175, 1.000)
		height: 34px
		border-radius: 0px 0px 16px 16px

		&.open
			max-height: 500px
			opacity: 1
			transform: translateY(0px) scale(1)
			transition: all 0.8s cubic-bezier(0.770, 0.000, 0.175, 1.000)

		.ico-loupe
			position: absolute
			top: 14px
			left: 20px
			width: 30px
			height: 20px
			background-image: url(../../../assets/images/ico-loupe.png)
			background-repeat: no-repeat
			background-size: auto 80%

		input
			width: 167px
			margin-left: 10px
			margin-top: 4px
			margin-bottom: 4px
			height: 26px
			border: 0px
			border-radius: 40px
			font-family: 'DinBold'
			font-size: 14px
			padding: 0 35px
			color: #707070
			background: var(--moduleBoxColor)
			outline: none!important


.noselect
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

</style>
