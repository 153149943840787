var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navigator-ui-item" }, [
    _c(
      "div",
      {
        staticClass: "accordion-bloc",
        class: {
          active: _vm.isActive,
          selected: _vm.isSelected,
          deprecated: _vm.isDeprecated
        },
        attrs: { id: _vm.uiobject.value._id },
        on: {
          click: function($event) {
            return _vm.openEditor()
          }
        }
      },
      [
        _c("div", { staticClass: "zone" }),
        _c("icon", {
          attrs: {
            name: _vm.uiobject.value.type + "-" + _vm.uiobject.value.format
          }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.uiobject.value.name,
              expression: "uiobject.value.name"
            }
          ],
          staticClass: "invisibleSimple",
          domProps: { value: _vm.uiobject.value.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.uiobject.value, "name", $event.target.value)
            }
          }
        }),
        _c(
          "div",
          {
            staticClass: "action-bts",
            class: { "arrow-space": _vm.hasChildren }
          },
          [
            _vm.uiobject.value.format != "ui-screen"
              ? _c("div", {
                  staticClass: "action-bt bt-trash",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.deleteNode()
                    }
                  }
                })
              : _vm._e(),
            _vm.uiobject.value.format != "ui-screen"
              ? _c("div", {
                  staticClass: "action-bt bt-duplicate",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.duplicateNode()
                    }
                  }
                })
              : _vm._e(),
            _c("div", {
              staticClass: "action-bt bt-inspector",
              class: { active: _vm.inspectorActive },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.openInspector()
                }
              }
            })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "inspector-open-bts",
            class: [
              { "arrow-space": _vm.hasChildren },
              { active: _vm.inspectorActive }
            ]
          },
          [_c("div", { staticClass: "action-bt bt-inspector" })]
        ),
        _vm.hasChildren
          ? _c("span", {
              staticClass: "ico-arrow",
              class: { close: !_vm.accordion.main },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.switchAccordion("main")
                }
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.accordion.main,
            expression: "accordion.main"
          }
        ],
        staticClass: "accordion-ctn",
        class: { active: _vm.isActive }
      },
      [
        _vm.nbSection > 1
          ? _c(
              "div",
              _vm._l(_vm.sections, function(section, i) {
                return _c("div", [
                  _c(
                    "div",
                    { staticClass: "accordion-bloc" },
                    [
                      _c("div", { staticClass: "zone" }),
                      _c("icon", { attrs: { name: "section" } }),
                      _c("span", [_vm._v("Section " + _vm._s(i + 1))])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "accordion-ctn" },
                    _vm._l(section, function(subobj) {
                      return _c(
                        "div",
                        [
                          _c("navigator-ui-item", {
                            attrs: {
                              uiobject: subobj,
                              "app-state": _vm.appState,
                              main: _vm.main
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              }),
              0
            )
          : _c(
              "div",
              [
                _c(
                  "draggable",
                  {
                    attrs: {
                      tag: "div",
                      disabled: _vm.appState.editor.uiState ? false : true
                    },
                    on: {
                      start: function($event) {
                        _vm.isDragging = true
                      },
                      end: function($event) {
                        _vm.isDragging = false
                      }
                    },
                    model: {
                      value: _vm.objectToDisplay,
                      callback: function($$v) {
                        _vm.objectToDisplay = $$v
                      },
                      expression: "objectToDisplay"
                    }
                  },
                  _vm._l(_vm.objectToDisplay, function(subobj, index) {
                    return _c(
                      "div",
                      [
                        _c("navigator-ui-item", {
                          attrs: {
                            uiobject: subobj,
                            "app-state": _vm.appState,
                            main: _vm.main
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }