var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "curve-fields-ctn" }, [
    _c("div", { staticClass: "field-ctn" }, [
      _c("canvas", {
        staticClass: "curvedraw",
        attrs: {
          id: "curve-editor-" + _vm.bloc.value._id + "-" + _vm.rand,
          width: "150",
          height: "150"
        },
        on: {
          mousedown: _vm.onMouseDown,
          mousemove: _vm.onMouseMove,
          mouseup: _vm.onMouseUp
        }
      }),
      _c("div", { staticClass: "clear" }),
      _vm.gotPreset
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.preset,
                  expression: "preset"
                }
              ],
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.preset = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.presetChange
                ]
              }
            },
            [
              _vm._l(_vm.presets, function(opt, name) {
                return _c("option", { domProps: { value: name } }, [
                  _vm._v(_vm._s(opt.label))
                ])
              }),
              _c("option", { attrs: { value: "custom" } }, [_vm._v("Custom")])
            ],
            2
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "clear" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }