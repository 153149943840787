import React from 'react';
import NodalUi from '../NodalUi';

import MediaQuery from '../helpers/MediaQuery';

export default class MultiColumns extends NodalUi {

    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            position: 'relative',
            width : '100%',
            minHeight : '100px'
        });

        this.specificStyle = {
            
        };
    }

    generateSections( nbCol, colStyle ){
        let sections = [];
        for( let i = 0 ; i < nbCol ; i++ ){

            let subcontent = this.props.children.filter( (child) => {
                if( this.props.cols[i].indexOf( child.props._id ) != -1 )
                    return child;
                return null;
            });
            

            let sectionContent = this.wrapIntoAuthoring( subcontent , i , true );

            let section = (
                <div key={i} className={'section-'+i} style={colStyle}>
                    {sectionContent}
                </div> );

            sections.push( section );
        }
        return sections;
    }

    render() {

        let content = this.props.children;
        
        let colStyle = {
            width:  (100/this.props.nbSection)+"%", 
            height: '100%',
            display : 'inline-block',
            borderColor: 'black',
            position: 'relative',
            minHeight : '100px',
            verticalAlign : 'top'
        };
        
        let sectionsDesktop = this.generateSections( 2, colStyle );

        let colStyleMobile = Object.assign( {}, colStyle );
        colStyleMobile.width = '100%';

        let sectionsMobile = this.generateSections( 2, colStyleMobile );


        /*let otherStyles = {
            // add here custom styles 
             zIndex : this.props.nestedLevel
        }
        
        let style = Object.assign( {}, this.otherStyles, this.defaultStyle, this.props.styles , this.forcedStyle ); // TO DO : automatiser la fusion de ces propriétés de style
        */

        let displayMode = this.props.renderer.displayMode;

        return this.wrapHelper( 
            <div>
                <MediaQuery displayMode={displayMode} maxMode="mobile" >
                    <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                        {sectionsMobile} 
                    </div> 
                </MediaQuery>
                <MediaQuery displayMode={displayMode} minMode="tablet" >
                    <div {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                        {sectionsDesktop} 
                    </div> 
                </MediaQuery>
            </div>
        );

    }

}