<!-- Calculated vue -->

<template lang="pug">

span.textinput-ctn
	input.value.disabled(disabled)
	
</template>

<script>

export default {

	components: { },

	props:[],

	data() {
		return {

		}
	},

	created: function(){

	
	},

	destroyed: function(){

	},

	watch:{

	},

	methods:{


	}

}

</script>

<style lang="stylus">
</style>