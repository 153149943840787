var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "draggable-bloc",
      class: [_vm.bloc.value.type],
      attrs: { id: "bloc-" + _vm.bloc.value._id }
    },
    [
      _vm.renderer == "post-it"
        ? _c("post-it", { attrs: { bloc: _vm.bloc, dragging: _vm.dragging } })
        : _vm._e(),
      _vm.renderer == "puzzle"
        ? _c("puzzle-bloc", {
            class: { selected: _vm.selected },
            attrs: { bloc: _vm.bloc, "app-state": _vm.appState }
          })
        : _vm._e(),
      _vm.renderer == "classic"
        ? _c("classic-bloc", {
            class: { selected: _vm.selected },
            attrs: {
              bloc: _vm.bloc,
              family: _vm.family,
              "bloc-type-name": _vm.blocName,
              "bloc-type-icon": _vm.parentBlocIcon,
              "app-state": _vm.appState,
              workspace: _vm.workspace
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }