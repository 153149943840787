import ActionAbstract from '../../ActionAbstract';

class OpenGeoModal extends ActionAbstract {

    static get ID() {
        return 'actions:main:geomodal:open';
    }

    run(state) {
        return this.composeState(state, {
            main: {
                geomodal: {
                  open: true,
                  fieldvalue: this.getParam('fieldvalue'),
                  modalId: this.getParam('fieldId'),
                }
            }
        });
    }
}

export default OpenGeoModal;
