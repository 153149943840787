import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class CloseModal extends ActionAbstract {

    static get ID() {
        return 'actions:main:modal:closeModal';
    }

    run(state) {

        return this.composeState( state, {
            modal : {
                open : false,
                icon: false,
                share: false,
                duplicate: false,
                sharingError: '',
                projectInfo: null,
                title: null,
                text: null,
                nextAction: null,
                nextActionParam: null,
            }
        });

    }

}

export default CloseModal;
