<template lang="pug">

div.img-ctn
	img(v-bind:src="media.url")

</template>

<script>

export default {
	
	props: ['media'],

	data() {
		return {

		}
	},

	created: function(){

	},

	methods:{


	},

	filters: {

	}	

}
</script>

<style lang="stylus">

.img-ctn
	width: 100%
	height: 100%
	display: table-cell
	text-align: center
	vertical-align: middle

	img
		width: auto
		height: auto
		max-width: 90%
		max-height: 90%

	
			
</style>