import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class DeleteBlock extends ActionAbstract {

    /**
     * @param id : block id
     * @param setDeleteFlag (true by default) : specify if _deleted flag needs to be set to true for the targeted block and all his children
     * 
    **/

    static get ID() {
        return 'actions:block:deleteBlock';
    }

    run() {
        this.trigger('block:deleteBlock', { subaction : "onDelete", id: this.getParam('id'), setDeleteFlag: this.getParam('setDeleteFlag') });
        this.trigger('block:deleteBlock', { subaction : "confirm", id: this.getParam('id'), setDeleteFlag: this.getParam('setDeleteFlag')  });        
    }

    runOnDelete(state) {
        this.trigger('main:toggleInspector', { open: false });
    }

    runConfirm(state) {

        let blockPath;
        [this.block, blockPath] = helper.block.getBlockById( state.project, this.getParam('id'), true );
        let compositeState = { project : {} };

        if (this.block) {

            // this.block.value._deleted = true;
            compositeState.project[blockPath] = { value: { _deleted: true }};

            let parent , parentPath;
            [parent, parentPath] = helper.block.getBlockById( state.project , this.block.custom.uiParent , true );

            if( this.block.value.type = 'object' && this.block.custom.tag == 'ui' ){
                if (this.block.value._id==state.editor.targetLayoutId)
                    state.editor.targetLayoutId = null;
                if (parent) {
                    let parentChildren, pathParentChildren;
                    [parentChildren, pathParentChildren] = helper.block.getField( parent , 'children' , true );

                    if( parentChildren ){
                        this.deps.mainStore.ui.deleteFrom( this.block.value._id, parent.value._id );
                        compositeState.project[parentPath+'.value.fields['+pathParentChildren+'].value'] = this.deps.mainStore.objects.deleteIDFromChildrenField( parentChildren.value , this.block.value._id );
                    }

                }
            } else {
                // if its a schematic bloc
                this.trigger('editor:schematic:checkLinks', { bloc_id: this.block.value['ref_' + this.block.value.level] } );            
            }

            let setDeleteFlag = this.getParam('setDeleteFlag');

            if( setDeleteFlag !== false ){
                // set _deleted flag to true
                this.deleteNodeChildren( this.block );
                compositeState.project[blockPath] = { value: { _deleted: true }};
            }
            else {
                let listPath = this.block.value.type = 'object' && this.block.custom.tag == 'ui' ? '.custom.objects' : '.custom.children';
                let list = this.block.value.type = 'object' && this.block.custom.tag == 'ui' ? parent.custom.objects : parent.custom.children;
                let index = -1;
                for( let i = 0 ; i < list.length ; i++ ){
                    if( list[i].value._id == this.block.value._id )
                        index = i;
                }

                if( index != -1 )
                    compositeState.project[parentPath+listPath] = this.arraySplice(index);
            }

            //si le block a des memories, ont les supprime également
            if(this.block.value.memory && this.block.value.memory.length > 0){
                this.block.value.memory.forEach((mem) => {
                    this.trigger('project:deleteMemory', { memId: mem.id });
                })
            }

            this.composeState( state, compositeState );

            // @todo : check if there is another cleaner method of doing this
            state.project = Object.assign({}, state.project);

        } else {
            //deadcode
            this.trigger('project:deleteMemory', { memId: this.getParam('id') });
        }


        // return this.composeState(state, {
        //     // add object assign to notify vue of the change
        //     project: 
        // });
        return ;
        
    }

    deleteNodeChildren( node ){
		// delete block children
		if( node.custom.children && node.custom.children.length > 0 ){
			for( let subnode of node.custom.children ){
				subnode.value._deleted = true;
				this.deleteNodeChildren( subnode );
			}
		}
		// delete UIobjects children
		if( node.custom.objects && node.custom.objects.length > 0 ){
			for( let subnode of node.custom.objects ){
				subnode.value._deleted = true;
				this.deleteNodeChildren( subnode );
			}
		}
	}


}

export default DeleteBlock;