import ActionAbstract from '../../ActionAbstract';

class OpenPopup extends ActionAbstract {

    static get ID() {
        return 'actions:main:popup:open';
    }

    run(state) {
        return this.composeState(state, {
            main: {
                popupOpen: true
            }
        });
    }

}

export default OpenPopup;