import AdvancedUiObject from '../AdvancedUiObject';

class UiScreen extends AdvancedUiObject {
    constructor(id, props) {
        super(id, props, 'Box');

    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-widget",
            label: "Widget View",
            parent : "ui-layouts",
            isDisplayed : true,
            isEmbeddable: false,
            nbSection: 1,
            childrenType: 'ui'
        };
    }

    
    initParameters(){
        super.initParameters();
    
        let otherParameters = {
            // Add param to positionning common settings
            textAlign: {
                type: 'String', 
                default: "center", 
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Content Alignment",
                    container: "pos-fields",
                    widget: 'select',
                    options: [ {value:'left', label:'left'}, {value:'center', label:'center'}, {value:'right', label:'right'} ],
                    description: "Sets the horizontal alignment of the UI. Inherit: same alignment as the parent / Left: aligned to the left / Center: aligned to the center / Right: aligned to the right."
                }
            }
        };

        this.addToParameters( otherParameters );

        this.setParamInvisible( 'parent' );
        //this.setParamInvisible( 'parent_select' );

        this.setDefaultValue( 'fontFamily' , 'ComfortaaLight' );

    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }
    
    init(){
        super.init();
        
    }
}

export default UiScreen;