import UiObject from './UiObject';

class UiExperience extends UiObject {
    constructor(id, props) {
        super(id, props, 'Experience');


        // this.setDefaultValue( 'width', '100%' );
        // this.setDefaultValue( 'height', '100%' );

        // this.setDefaultValue( 'position', 'absolute' );
        // this.setDefaultValue( 'top', '0px' );
        // this.setDefaultValue( 'left', '0px' );
        // this.setDefaultValue( 'bottom', '0px' );
        // this.setDefaultValue( 'right', '0px' );
        // this.setDefaultValue( 'screenfixed', true );
        // this.setDefaultValue( 'backgroundColor', { hex: '#000000' } );

    }

    static getAuthConfig(){
        return {
            exportFormat : "",
            label:  "",
            parent : "ui-layouts",
            isDisplayed : false,
            isEmbeddable: false,
            nbSection: 1,
            childrenType: 'ui'
        };
    }

}

export default UiExperience;