var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion targets-bloc widget-manager" }, [
    _c(
      "div",
      {
        staticClass: "accordion-title",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.accordion = !_vm.accordion
          }
        }
      },
      [
        _c("span", { staticClass: "text" }, [
          _vm._v("Widget Visibility Manager")
        ]),
        _c("span", { staticClass: "arrow", class: { close: !_vm.accordion } })
      ]
    ),
    _c("div", { staticClass: "form-content", class: { open: _vm.accordion } }, [
      _c("div", { staticClass: "form-accordion isAccordion" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass:
              "form-accordion-content open widget-visibility widgetSelection"
          },
          _vm._l(_vm.widgetslist, function(w, index) {
            return _c("span", { staticClass: "textinput-ctn trigger-select" }, [
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(w.value.name))
              ]),
              _c(
                "span",
                {
                  staticClass: "bt-order bt-top",
                  class: { disabled: index == 0 },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.goUp(w.value._id)
                    }
                  }
                },
                [_vm._v(" ")]
              ),
              _c(
                "span",
                {
                  staticClass: "bt-order bt-bottom",
                  class: { disabled: index == _vm.widgetslist.length - 1 },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.goDown(w.value._id)
                    }
                  }
                },
                [_vm._v(" ")]
              ),
              w.value.type == "widget"
                ? _c(
                    "span",
                    {
                      staticClass: "checkbox",
                      class: {
                        selected: _vm.activated.indexOf(w.value._id) != -1
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.toggleValue(w.value._id)
                        }
                      }
                    },
                    [_c("input", { attrs: { type: "checkbox" } })]
                  )
                : _vm._e()
            ])
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "clear", staticStyle: { height: "10px" } })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "text" }, [_vm._v("Widgets list")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }