import React from 'react'
import ReactDom from 'react-dom'

import Main from './components/Main'

class ReactRenderer {

    constructor(){
        this._container = null;
        this._currentState = null;
        this._mainInstance = null;
        this._props = { };

        this._nextState = null;
        this.applyState();
    }

    setContainer(querySelector) {
        this._container = document.querySelector(querySelector);
    }

    setProp( prop, value, partial = false ){
        let newValue = {};
        if (typeof this._props[prop] === 'object' && partial) {
            newValue[prop] = Object.assign({}, this._props[prop], value);
        } else {
            newValue[prop] = value;
        }

        this._props = Object.assign({}, this._props, newValue);

        //this.render();
    }

    getProp( prop ){
        return _prop[prop];
    }

    applyState( ){

        if( this._nextState !== null ){
            
            let state = this._nextState || this._currentState;
        
            if( !state )
                return;

            if (this._mainInstance === null) {
                this._mainInstance = ReactDom.render(
                        <Main ></Main>
                , this._container);
            }
            
            state.renderer = this._props;
            this._mainInstance.setState({ obj : state });

            this._currentState = state;

            this._nextState = null;
        }
        
        requestAnimationFrame( this.applyState.bind(this) );
    }

    render(_state) {
        this._nextState = _state;
    }

    unmount(){
        ReactDom.unmountComponentAtNode(this._container);
        this._mainInstance = null;
    }

};

export default new ReactRenderer();

export const factory = function(){
    return new ReactRenderer();
}
