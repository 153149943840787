import ActionAbstract from '../ActionAbstract';
import Vue from 'vue';

import * as helper from '../../helper';

class AssetFieldLoad extends ActionAbstract {

    static get ID() {
        return 'actions:library:assetFieldLoad';
    }

    run(state) {
        let bloc, pathToBloc, composite = { project: {}};
        [bloc, pathToBloc] = helper.block.getEntityByIDInWholeProject(state.project, this.getParam('bloc').value._id, true);
        let subMediaId = this.getParam('subMediaId');

        if( this.getParam('mediaId') ){
            this.deps.mainStore.lib.getMediaByID(this.getParam('mediaId')).then( (media) => {
                
                if( !bloc.custom.media )
                    Vue.set( bloc.custom, 'media' , {} );
    
                if( subMediaId ){
                    /*let subMedia = {};
                    subMedia[subMediaId] = media
                    composite.project[pathToBloc+'.custom.media'] = subMedia;*/
    
    
                    Vue.set( bloc.custom.media, subMediaId , media );
                }
                else{
                    composite.project[pathToBloc+'.custom.media'] = media;
                }

                this.composeState(state, composite);        
            });
        }
        else{
            if( !bloc.custom.media )
                Vue.set( bloc.custom, 'media' , {} );

            Vue.set( bloc.custom.media, subMediaId , null )
        }
        

        return false;
    }

}

export default AssetFieldLoad;