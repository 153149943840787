import AdvancedUiObject from './AdvancedUiObject';

class TextUiObject extends AdvancedUiObject {

    constructor(id, props = [], type = 'NodalUi') {
        super(id, props, type);

        
    }


    initParameters(){
        super.initParameters();

        let texts = this.createInspectorNode('group', 'texts', 'Texts' );
        texts.children.push( this.createInspectorNode('group-field', 'texts-fields') );

        let fontstyles = this.createInspectorNode('group', 'fontstyles', 'Font Styles' );
        fontstyles.children.push( this.createInspectorNode('group-field', 'fontstyles-fields') );

        let textTopic = this.createInspectorNode( 'topic', 'textStyles', 'Text styles' );
        textTopic.children = [ texts, fontstyles ];

        this.inspector.unshift( textTopic );


        let textParameters = {

            // Text 
            innerText: {
                type: 'String', 
                default: "", 
                partial: null,
                connection: { in: {pluggable: true, default: true}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Text",
                    widget: "textarea",
                    container: "texts-fields",
                    description: "the text to be displayed"
                }
            },

            // FONTSTYLES
            fontSize: {
                type: 'Int', 
                default: 13, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Font size",
                    container: "fontstyles-fields",
                    unit : 'px',
                    description: "font size of the text to display"
                }
            },
            color: {
                type: 'Color', 
                default: { hex: "#FFFFFF" }, 
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Color",
                    container: "fontstyles-fields",
                    description: "color of the text to display"
                }
            },
            textAlign: {
                type: 'String', 
                default: "left", 
                partial: 'styles',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Text align",
                    container: "fontstyles-fields",
                    widget: 'select',
                    options: [ {value:'left', label:'left'}, {value:'center', label:'center'}, {value:'right', label:'right'} ],
                    description: "alignment of the text"
                }
            },
            lineHeight: {
                type: 'Int', 
                default: 16, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Line height",
                    container: "fontstyles-fields",
                    unit : 'px',
                    description: "height of each line of the text to display"
                }
            },
            letterSpacing: {
                type: 'Int', 
                default: 0, 
                partial: "styles",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Letter spacing",
                    container: "fontstyles-fields",
                    unit : 'px',
                    description: "space between each character"
                }
            },
            underline: {
                type: 'Boolean', 
                default: false, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Underline",
                    container: "fontstyles-fields",
                    description: "specifies whether the text should be underlined or not"
                }
            },
            italic: {
                type: 'Boolean', 
                default: false, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Italic",
                    container: "fontstyles-fields",
                    description: "specifies whether the text should be displayed in italic or not"
                }
            },
            bold: {
                type: 'Boolean', 
                default: false, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Bold",
                    container: "fontstyles-fields",
                    description: "specifies whether the text should be displayed in bold or not"
                }
            },
            uppercase: {
                type: 'Boolean', 
                default: false, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Uppercase",
                    container: "fontstyles-fields",
                    description: "specifies whether the text should be displayed in uppercase letters or not"
                }
            },
        };

        this.addToParameters( textParameters );
        
    }

}

export default TextUiObject;