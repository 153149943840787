import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class HistoryNext extends ActionAbstract {

    static get ID() {
        return 'actions:main:historyNext';
    }

    run(state) {
        
        let history = ( state.breadcrumb && state.breadcrumb.history ) ? state.breadcrumb.history : [];
        let crtHistory = ( state.breadcrumb && state.breadcrumb.crtHistory ) ? state.breadcrumb.crtHistory : 0 ;

        crtHistory--;
        if( crtHistory < 0 )
            crtHistory = 0;

        let target = state.breadcrumb.history[crtHistory];

        this.trigger('editor:openEditor', { id : target, openFromHistory: true } );
        
        return this.composeState( state, {
            breadcrumb : {
                crtHistory : crtHistory
            }
        });
    }

}

export default HistoryNext;