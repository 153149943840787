
<template lang="pug">

div#content
	h1 Examples

	section.module-theme

		h1 Audio
		ul.nodes
			li 
				span Toggle a sound player
			li 
				span Launch a sound with a GPS position
			li 
				span Oscillator with device orientation
			li 
				span Scrub player with device orientation
			li 
				span Play a sound according to compass
			li 
				span FFT with a sound player


</template>

<script>

	export default {


		props:['displayTab'],

		data() {
			return {
				
			};
		},

		created: function(){

		},

		methods:{

		}

	}

</script>

<style>


</style>