var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.projectInfo !== undefined
    ? _c("div", { attrs: { id: "duplicateProjectTab" } }, [
        _vm.duplicateError == "ok"
          ? _c("div", { staticClass: "error" }, [
              _c("p", [
                _vm._v(
                  "The project " +
                    _vm._s(_vm.projectInfo.name) +
                    " has been duplicated as "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.neoProjectName))])
              ])
            ])
          : _c("div", { staticClass: "input duplicateProjectInput" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.neoProjectName,
                    expression: "neoProjectName"
                  }
                ],
                staticClass: "value inputModal",
                attrs: { placeholder: "Enter a new ProjectName", type: "text" },
                domProps: { value: _vm.neoProjectName },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.requestDuplicate($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.neoProjectName = $event.target.value
                  }
                }
              }),
              _c(
                "div",
                {
                  staticClass: "duplicateProjectAdd",
                  attrs: {
                    disabled: !_vm.neoProjectName,
                    value: "Duplicate",
                    type: "button"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.requestDuplicate($event)
                    }
                  }
                },
                [_vm._v("Duplicate")]
              )
            ]),
        _vm.duplicateError == ""
          ? _c("div", [
              _c("p", [_vm._v("Do you want to duplicate this project ? ")]),
              _c("p", [
                _vm._v(
                  "Depends of the size of the project (This may take several minutes)"
                )
              ])
            ])
          : _vm._e(),
        _vm.duplicateError !== "" && _vm.duplicateError !== "ok"
          ? _c("p", { staticClass: "error" }, [
              _c("strong", [_vm._v(_vm._s(_vm.duplicateError))])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }