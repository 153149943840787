import React from 'react';
import NodalUi from './NodalUi';

import {
  HashRouter,
  BrowserRouter,  
  Route,
  Redirect
} from 'react-router-dom'

import { withRouter } from 'react-router'

class Experience extends NodalUi {

    constructor() {
        super();
        this.currentRedirect = null;
    }

    shouldComponentUpdate(nextProps) {
        return true
    }

    render() {

        let urlPrefix = this.props.urlPrefix || "";

        let redirect = this.props.redirectTo && this.props.redirectTo !== this.currentRedirect ? 
            <Redirect push to={urlPrefix + (this.props.redirectTo.substring(0,1) === '/' ? '' : '/') + this.props.redirectTo} /> :
            null;
            
        this.currentRedirect = this.props.redirectTo;

        let children = this.props.children.filter( ( child ) => ( !this.props.widgetsOrder || this.props.widgetsOrder.indexOf( child.props._id ) != -1 || child.props.type === 'screen' ) )

        let orderChildren = ( c1, c2 ) => {
            let index1 = this.props.widgetsOrder.indexOf( c1.props._id );
            let index2 = this.props.widgetsOrder.indexOf( c2.props._id );
            if( index1 < index2 ) return -1;
            if( index1 > index2 ) return 1;
            return 0;
        }
        children.sort( orderChildren );
        
        let content = children.map( (child, id) => {
            if( child.props.type === 'screen' && !this.props.renderer.auth ){
                let url = urlPrefix + "/" + child.props.name;
                return (
                    <Route key={child.key} path={url} render={() => {
                        return (
                            this.props.redirectTo && this.props.redirectTo !== child.props.name ? 
                            redirect
                            :
                            child
                        );
                    }} />
                )
            }
            else{
                return ( child );
            }
        });

        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        // use the same router for both ios and android (made for FAS project)
        const Router = this.props.isCordova /*&& iOS*/ ? HashRouter : BrowserRouter


        return (
            <Router>
                <div>
                    {content}
                    <Route exact path={ urlPrefix } render={() => redirect } />
                </div>
            </Router>
        );

    }

};

export default Experience;