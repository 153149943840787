/*
	Projects Store instance -
	all function to get info of Projects
*/

import { EventEmitter } from 'events';
import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

class ProjectsStore extends EventEmitter {
	constructor(){
		super();
	}

	setMain( main ){
		this.main = main;
	}


	// Get list of project of the current user
	getProjects(forceRefresh ){
		return new Promise( function(resolve, reject){

			if( this.main.state.projects && !forceRefresh ){
				resolve( this.main.state.projects.list );
				return;
			}

			let url = (this.main.config.baseURLAuth+"_design/lists/_view/project-list?startkey=[\""+encodeURIComponent(this.main.state.user)+"\"]&endkey=[\""+encodeURIComponent(this.main.state.user)+"\",{}]");
			let self = this;

			Vue.http({url: url, method: 'GET'}).then(function (response) {

				for( var project of response.body.rows ){
					project.custom = new Object();
					project.custom.display = true;
					project.custom.children = [];
				}

				// // sort by name ASC (see nodal-authoring/src/components/common/sortMenu.vue)
				// response.body.rows.sort((a,b) => {
				// 	if (a.value.name < b.value.name) { return -1; }
				// 	if (a.value.name > b.value.name) { return 1; }
				// 	return 0;
				// });
				// sort by updated DESC (see nodal-authoring/src/components/common/sortMenu.vue)
				response.body.rows.sort((a, b) => {
					if (a.value.updated > b.value.updated) { return -1; }
					if (a.value.updated < b.value.updated) { return 1; }
					return 0;
				});

				resolve( response.body.rows );

			}, function (error) {
				//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
				//reject(error);
			});

		}.bind(this));
	}


	getProjectFromTab( id ){
		return new Promise( function(resolve, reject){

			this.getProjects().then( function( projects ) {

				var proj = null;
				for( let project of projects ){
					if( project.value._id == id ){
						proj = project;
					}
				}

				resolve( proj );

			});


		}.bind(this));
	}

	newProjNumber() {
		let sameName = []

		if (this.main.state.projects.list) {
			this.main.state.projects.list.forEach( (project) => {
				if (project.value.name.includes("New Project #")){
					let currentNumber = parseInt(project.value.name.replace("New Project #", ""))
					sameName.push(currentNumber)
				}
			})

			let sorted = sameName.sort((a, b) => a-b);
			return sorted.length == 0 ? 1 : sorted[sorted.length - 1] + 1
		} else return 1
	}

	generateNewProjectDoc( ){
		var doc = {
			"type": "project",
			"name": `New Project #${this.newProjNumber()}`,
			/*"owner": user,
			"users": [],*/
			"template": [],
			"memory": [],
			"time": null,
			"zones": null,
			"cover": null,
			"session": {
				"date": null,
				"owner": null
			},
			"created": (new Date()).getTime(),
			"updated": (new Date()).getTime()
		};

		return doc;
	}

	generateNewProjectRef(id, user) {
		return {
			"_id": "ref_"+id,
			"name": `New Project #${this.newProjNumber()}`,
			"project" : id ,
			"owner": user,
			"users": [],
			"editors": [],
			"viewers": [],
			"cover": null,
			"created": (new Date()).getTime(),
			"updated": (new Date()).getTime(),
		};
	}

	manageRole(method, project, type, user) { // Handle Project Sharing
		let self = this
		let urlreq = `${self.main.config.previewURL}authoring/${method}/${project.project}/${type}s/${user}`

		return new Promise( (resolve, reject) => {
			Vue.http({url: urlreq, method: 'GET'}).then(function (response) {

				if (!response.body.message.error) {
					self.getProjects({ forceRefresh : true } ).then( (valeur) => {
						// self.main.state.modal.sharingError = response.body.message.message
						resolve(response.body.message.message)
					});
				} else {
					// self.main.state.modal.sharingError = response.body.message.message
					reject(response.body.message.message)
				}
	
				}, function (error) {
					//@todo: contextualiser l'erreur dans le reject [ticket NS-412]
					reject(error)
				});
		})
		

	}

}

export let projects = new ProjectsStore();
