import NodalUi from './NodalUi';

export default class InputField extends NodalUi {
    constructor() {
        super();

        this.prevValue = null;
    }


    setDefaultStyles(nextProps, nextState) {
        super.setDefaultStyles(nextProps, nextState);

        const props = nextProps;

        if (props.input.labelColor) {
            props.input.floatingLabelStyle = { color: props.input.labelColor };
        }
        delete props.input.labelColor;

        if (props.input.focusLabelColor) {
            props.input.floatingLabelFocusStyle = { color: props.input.focusLabelColor };
        }
        delete props.input.focusLabelColor;

        if (props.input.hintColor) {
            props.input.hintStyle = { color: props.input.hintColor };
        }
        delete props.input.hintColor;

        if (props.input.errorColor) {
            props.input.errorStyle = { color: props.input.errorColor };
        }
        delete props.input.errorColor;

        if (props.input.underlineColor) {
            props.input.underlineStyle = { borderColor: props.input.underlineColor };
        }
        delete props.input.underlineColor;

        if (props.input.underlineFocusColor) {
            props.input.underlineFocusStyle = { borderColor: props.input.underlineFocusColor };
        }
        delete props.input.underlineFocusColor;

        if (props.input.valueColor) {
            props.input.inputStyle = { color: props.input.valueColor };
        }
        delete props.input.valueColor;
    }
}
