<!-- Number vue -->

<template lang="pug">

span.textinput-ctn
	input.value.vector2D.x(type="text", v-model="value.x", v-on:focusout="updateX()", v-bind:placeholder="placeholderX", :disabled="field.disabled")
	input.value.vector2D.y(type="text", v-model="value.y", v-on:focusout="updateY()", v-bind:placeholder="placeholderY", :disabled="field.disabled")
	
</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue', 'placeholder' ],

	data() {
		return {
			value: {
				x: 0,
				y: 0
			},
			placeholderX: null,
			placeholderY: null
		}
	},

	created: function(){
		this.value = this.fieldvalue && this.fieldvalue.value != undefined ? this.fieldvalue.value : {x:null, y:null};
		
		if( this.placeholder && this.placeholder.x )
			this.placeholderX = this.placeholder.x;

		if( this.placeholder && this.placeholder.y )
			this.placeholderY = this.placeholder.y;
	},

	watch: {
		fieldvalue: function (obj) {
			this.value = obj && obj.value != undefined ? obj.value : {x:null, y:null};
		}
	},

	methods:{

		checkNumberValue: function(val){
			let floatVal = parseFloat(val);
			if( val === "" || floatVal === NaN || floatVal === 'NaN' || val === null )
				val = null;
			else
				val = floatVal;
			return val;
		},

		updateX: function(){
			this.value.x = this.checkNumberValue( this.value.x );

			if (this.fieldvalue.x !== this.value.x)	{
				this.valueUpdated()
			}
		},

		updateY: function(){
			this.value.y = this.checkNumberValue( this.value.y );

			if (this.fieldvalue.y !== this.value.y)	{
				this.valueUpdated()
			}			
		},

		valueUpdated: function(){
			this.$parent.valueUpdated( this.field, this.value );
		}

	}

}

</script>

<style lang="stylus">

input.value.vector2D
	width: 30% !important
	&.x
		margin-right 0px
		width: 70px !important
		border-radius: 4px
	&.y
		width: 65px !important
		border-radius: 4px
</style>