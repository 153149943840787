
import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class DrawLink extends ActionAbstract {

    /**
     * @subaction from :
     * @param blocID 
     * @param side
     * @param name
    **/

    static get ID() {
        return 'actions:editor:schematic:drawLink';
    }

    runCancel(state){
        return this.composeState(state, { 
            editor :{
                linking : {
                    path : "M0 0",
                    status : false,
                    from : null, 
                    to : null
                }
            }
        });
    }

    runFrom(state) {

        let from = this.getLinkNode();

        let block = helper.block.getBlockById( state.project , from.blocID );

        let anchorFrom = helper.links.getAnchorCoordinates( from.blocID , from.side , from.name );
        let startPath = "M"+(block.value.coordinate.x + anchorFrom.x)+" "+(block.value.coordinate.y + anchorFrom.y)
         
        
        return this.composeState(state, { 
            editor :{
                linking : {
                    path : startPath,
                    from : from,
                    status : true,
                    conversion : null,
                    activated: null
                }
            }
        });
    }

    runTo(state){

        let from = state.editor.linking.from;
        let to = this.getLinkNode();

        let path, blocFrom, blocTo;
        [blocFrom,path] = helper.block.getBlockById(state.project, from.blocID, true);     
        blocTo = helper.block.getBlockById(state.project, to.blocID);     

        this.trigger('editor:schematic:drawLink', { subaction : 'cancel' });     

        if( helper.links.checkLink( from, blocFrom, to , blocTo ) ) {

            let compositeState = {
                editor :{
                    linking : {
                        from : from,
                        to : to ,
                        status : true
                    }
                },
                project: {}
            };

            let newLink = { from: from, to: to, activated: null };
            compositeState.project[path + ".value.links"] = this.arrayPush( newLink );
            
            let anchorTo = helper.links.getAnchorCoordinates( to.blocID , to.side , to.name );
            let toPos = {
                x : state.editor.scene.coordinate.x + blocTo.value.coordinate.x + anchorTo.x - 5,
                y : state.editor.scene.coordinate.y + blocTo.value.coordinate.y + anchorTo.y - 3
            };

            this.trigger('editor:schematic:toggleLinkMenu', { subaction : 'open', x : toPos.x , y : toPos.y , link : newLink, new: true  });     

            return this.composeState(state, compositeState);
        }

        return state;
    }

    getLinkNode(){
        return {
            blocID : this.getParam('blocID'),
            type : this.getParam('type'),
            side : this.getParam('side'),
            name : this.getParam('name')
        };
    }


}

export default DrawLink;