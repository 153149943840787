<template lang="pug">
div.tagModal
	div.cover-back(v-on:click.stop="closeModal")

	div.modal
		div.bt-close(v-on:click.stop="closeModal")
		div.content
			div.visuAll
				div.title 
					h1 Files uploaded
				div.mess 
					h3 Would you like to apply a tag on those files ?
				div.taglistMedias
					div.media(v-for="infos in state.mediasUploaded", v-on:click="") {{infos.type}} - {{infos.filename}}
			Tagging(:library="state", :tagModal="true")

</template>

<script>
import actionManager from  '../../actions';

import Icon from  '../common/Icon.vue';
import Tagging from  './tagging.vue';

export default {

	props: ['state'],

	components: { Icon, Tagging },

	data() {
		return {
			// newTag: '',
			// tags: [],
			// medias: []
		}
	},

	created: function(){
		let self = this
		// this.medias = []

		// this.state.mediaList.forEach( (media) => {
		// 	self.state.mediasUploaded.forEach( (mediaUploaded) => {
		// 		if (media._id == mediaUploaded._id) this.medias.push(Object.assign({}, media))
		// 	})
			
		// })
	},

	computed:{},

	methods:{

		closeModal: function(){
			actionManager.trigger('main:ToggleTagModal', { open : false });
		},

		addTag: function(){ 
			if (this.newTag !== "") {
				if (this.newTag.includes(" ")) {
					this.error = "Space are not allow in tag name."
					setTimeout( () => {this.error = ""}, 3000)
				} 
				else if (this.tags.indexOf(this.newTag) == -1) {
					this.tags.push(this.newTag)
					// update tag on every media document.
					this.medias.forEach( (media) => {
						if (media.tags) media.tags.push(this.newTag)
						else media.tags = [this.newTag]
					})
					this.newTag = ""
					this.error = ""
				}
			}
            
			// clear input value
			this.newTag = ""
			this.error = ""
		},

		deleteTag: function(i){
			this.tags.splice(i, 1)
			// actionManager.trigger('library:tag', { subaction: "remove", tagIndex: i });
		},
		confirmTags: function(){
			// Trigger update of the documents with the new tags.
			actionManager.trigger('library:tag', {
					tagMedias: {
						tags: this.tags, 
						medias: this.medias
					}
			});
			actionManager.trigger('main:ToggleTagModal', {open: false})
			this.state.mediasUploaded = []
		}
    }
}
</script>


<style scoped>

.taglistMedias{
	width: 100%;
	color: var(--inputTextColor);
}
.taglistMedias > .media {
	font-family: 'DinBold';
    font-size: 14px;
    word-wrap: break-word;
    color: var(--inputTextColor);
    background: var(--inspectorTargetColor);
    border-radius: 4px;
    padding: 10px;
    margin: 5px;
    text-align: center;
}
.title > h1 {
	text-align: center;
    color: var(--inputTextColor);
    font-family: 'DinBold';
    font-size: 28px;
    border-bottom: 1px solid var(--inputTextColor);
	padding-top: 8px;
    padding-bottom: 8px;
    margin: 8px;
}
.mess > h3 {
	text-align: center;
    color: var(--inputTextColor);
    font-family: 'DinRegular', 'ComfortaaLight';
    padding-bottom: 8px;
    margin: 8px;
}
</style>

<style lang="stylus" scoped>
.tagModal
	display: block
	position: fixed
	width: 100%
	height: 100%
	top: 0
	right: 0
	z-index: 105

	.cover-back
		display: block
		width: 100%
		height: 100%
		position: fixed
		top: 0
		left: 0
		background-color: var(--modalBgColor)
		opacity: 0.8

	.modal
		display: block
		width: 70%
		height: 70%
		position: fixed
		top: 13%
		left: 15%
		background-color: var(--modalMainColor)
		box-shadow 0px 0px 10px var(--shadowColor)
		border-radius: 16px

		.content
			height: 100%
			.visuAll
				width: calc(100% - 269px)
				height: 100%
				float: left
				display: flex
				flex-flow: column
				justify-content: flex-start
				align-items: center
				overflow-y: scroll
				.img-ctn
					width: 100%
					height: 100%
					display: flex
					justify-content: center
					align-items: center
					.img
						width: 90%
						height: 90%
						object-fit: contain

input#urlToCopy
	border: 0
	margin: 5px
	background: var(--inspectorTargetColor)
	font-family: 'DinBold'
	color: var(--inputTextColor)
	font-size: 14px
	padding: 10px
	border-radius: 4px
	margin-top: 0px
	width: calc(100% - 30px)
	&:focus
		outline: none!important

.clickableField
	position: relative

.clickableMask
	position: absolute
	top: 0
	left: 5px
	height: calc(100% - 5px)
	width: calc(100% - 10px)
	border-radius: 4px
	background: var(--selectedColor)
	opacity: 0
	transition: linear all 0.2s !important
	text-align: center
	line-height: 40px
	font-family: 'DinBold'
	color: white

.clickableMask:hover
	opacity: 1

.vc-chrome-color-wrap .vc-checkerboard
	width: 28px !important
	height: 28px !important

.vc-chrome-active-color
	width: 26px !important
	height: 26px !important
	border-radius: 35px !important
	border: 1px solid var(--inputTextColor) !important

.vc-chrome-body
	background: var(--bgColor) !important

.vc-chrome-hue-wrap .vc-hue
	border-radius: 35px !important

.vc-alpha-checkboard-wrap
	border-radius: 35px !important

.vc-chrome-fields-wrap
	padding-top: 0px !important

.vc-chrome-toggle-btn
	width: 15px !important

.vc-input__input
	line-height: 29px !important
	color: var(--inputTextColor) !important
	font-family: 'DinBold' !important
	box-shadow: none !important
	background-color: var(--menuElementColor) !important
	border-radius: 40px  !important

.vc-chrome-toggle-icon
	margin-top: 8px !important

.vc-chrome-toggle-icon-highlight
	background: none !important

.vc-chrome-fields .vc-input__label
	color: var(--inputTextColor) !important
	font-family: 'DinBold' !important
	margin-top: 0px !important
</style>