import ActionAbstract from '../ActionAbstract';

class ChangeTab extends ActionAbstract {

    static get ID() {
        return 'actions:prehome:changeTab';
    }

    run(state) {
        return this.composeState(state, {
            prehome: {
                tab: this.tab
            }
        });
    }

    tab(state, fullstate) {
        switch(this.getParam('tab')) {
        case 'accueil':
        case 'subscribe':
            return this.getParam('tab');
            break;
        case 'loggedin': 
            if (fullstate.user)    
                return 'loggedin';
            else 
                return 'form'
            break;
        case 'form': 
            if (fullstate.user)
                return 'loggedin';
            else 
                return 'form'
            break;

        default:
            return 'accueil';
        }        
    }

}

export default ChangeTab;