import InspectorActionAbstract from '../InspectorActionAbstract';
import * as helper from '../../../helper' ;

class RemoveCondition extends InspectorActionAbstract {

    /**
     * @param targetId
     */

    static get ID() {
        return 'actions:inspector:trigger:removeCondition';
    }

    runEvent (state) {

        let composite = { project: {}};
        composite.project[this._path + '.value.events'] = this.arraySplice(this.getParam('index'));

        return this.composeState(state, composite);
    }

    runState (state) {

        let composite = { project: {}};

        composite.project[this._path + '.value.trigger_states'] = this.arraySplice(this.getParam('index'));


        return this.composeState(state, composite);
    }

}

export default RemoveCondition;