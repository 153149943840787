
const media = {
    /**
     * Attempts to find and return the mimeType associated with a media from its URL.
     * This method is and should only be used with old media or rewritten to return the correct mimeType.
     * 
     * @param {String} url - the media URL.
     * @returns {String}
     */
    getMimeType: function(url) {
        if (url) {
            if (url.endsWith('.json')) return 'application/json';

            const ext = /[^.]+$/.exec(url)[0];
            // for known extensions
            // about OGG MIME type: https://developer.mozilla.org/en-US/docs/Web/HTTP/Configuring_servers_for_Ogg_media
            switch (ext.toLowerCase()) {
                case 'jpg': case 'jpeg': return 'image/jpeg';
                case 'png': return 'image/png';
                case 'gif': return 'image/gif';
                case 'mp4': case 'mov': return 'video/mp4';
                case 'mp3': return 'audio/mpeg';
                case 'wav': return 'audio/wav';
                case 'ogg': return 'application/ogg';
                case 'ogv': return 'video/ogg';
                case 'oga': return 'audio/ogg';
                case 'flac': return 'audio/flac';
                case 'midi': return 'audio/midi';
                case 'mid': return 'audio/midi';
                // DLS or other
                default: return 'application/octet-stream';
            }
        }
    },
    
    /**
     * Update the given media document to include all missing properties.
     * 
     * @param {Object} doc - The media document to be updated.
     * @param {String} id  - The media owner identifier.
     * @param {String} user - The current user.
     * @returns {Object} The updated media document
     */
    updateDocument: function(doc, id, user) {
        const defaultAssetProps = {
            mimeType: this.getMimeType(doc.url),
            mediaAddedFrom: id,
        }
        const updated = Object.assign(defaultAssetProps, doc);
        if (!updated.user) updated.user = user;

        return updated;
    }
};

export default media;
