
import ActionAbstract from '../../ActionAbstract';
import * as helper from '../../../helper';

class MoveLinkGrabber extends ActionAbstract {

    /**
     * @param id : id of block
     * @param x : new pos x of scene
     * @param y : new pos y of scene
    **/

    static get ID() {
        return 'actions:editor:schematic:moveLinkGrabber';
    }
    

    updateState(state, drag){

        let path;
        let block = null;
        [block,path] = helper.block.getBlockById( state.project, this.getParam('blocId') , true );

        let link = this.getParam('link');
        let linkIndex = helper.links.getIndexOfLink( block, link );

        if( linkIndex != -1 ){
            
            let compositeState = { 
                project: {},
                editor : {
                    draggingGrabber : drag
                },
            };
                
            compositeState.project[path+'.value.links['+linkIndex+'].shift'] = this.getParam( 'shift' );
            
            return this.composeState(state, compositeState );
        }

        return false;
    }

    
    runDrag(state){
        return this.updateState( state, true );
    }

    runDrop(state){
        return this.updateState( state, false );
    }


}

export default MoveLinkGrabber;