import InspectorActionAbstract from './InspectorActionAbstract';
import * as helper from '../../helper' ;
import Vue from 'vue';

class UpdateArray extends InspectorActionAbstract {

    static get ID() {
        return 'actions:inspector:updateArray';
    }

    beforeCreate() {
        super.beforeCreate();
        this._index = null;
    }

    preRun(state) {
        super.preRun(state);
        let field;
        field = helper.block.getField(this._target, this.getParam('fieldName'), true);
        if (field) {
            [field, this._index] = field;
        }

        this._state = state;
        this._composite = { project: {} };
    }

    /**
     * @param targetId
     * @param fieldName
     * @param newValues
     */
    runArrayValues (state) {

        //this._composite.project[this._path + '.value.fields[' + this._index + '].value'] = this.getParam('newValues');
        //return this.composeState(state, this._composite);
        Vue.set( this._target.value.fields[this._index], 'value' , this.getParam('newValues') );

        return state;
    }

    /**
     * @param targetId
     * @param fieldName
     * @param newHeaders
     */
    runArrayHeaders (state) {
        this._composite.project[this._path + '.value.fields[' + this._index + '].header'] = this.getParam('newHeaders');

        /*let owner, path;
        owner = helper.block.getMemoryOwner( this._state.project, this.getParam('targetId') );

        this.trigger('block:updateFields', { id: owner.value._id, recursive: true, filter: { value: { type: "array-row" } } });*/

        return this.composeState(state, this._composite);
    }

    postRun() {

    }

}

export default UpdateArray;
