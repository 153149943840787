var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion memory-bloc" }, [
    _c(
      "div",
      {
        staticClass: "accordion-title",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.accordion = !_vm.accordion
          }
        }
      },
      [
        _c("span", { staticClass: "text" }, [_vm._v("Memories")]),
        _c("span", { staticClass: "arrow", class: { close: !_vm.accordion } })
      ]
    ),
    _c("div", { staticClass: "form-content", class: { open: _vm.accordion } }, [
      _c("div", { staticClass: "form-accordion isAccordion" }, [
        _vm._m(0),
        _c("div", { staticClass: "form-accordion-content open" }, [
          _c(
            "div",
            { staticClass: "list-memory" },
            _vm._l(_vm.items, function(memory, i) {
              return _c(
                "div",
                {
                  staticClass: "memory-line",
                  attrs: { id: memory.value._id },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.checkSecretsRights(memory)
                        ? _vm.openMemory(memory)
                        : _vm.accesRefused("change")
                    }
                  }
                },
                [
                  _c("span", { staticClass: "col col1" }, [
                    _c("span", {
                      staticClass: "icon",
                      class: memory.value.format.toLowerCase()
                    })
                  ]),
                  _c("span", { staticClass: "col col2" }, [
                    _vm._v(_vm._s(memory.value.name))
                  ]),
                  _c("span", { staticClass: "col col3" }),
                  _c(
                    "span",
                    {
                      staticClass: "col col4",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.checkSecretsRights(memory)
                            ? _vm.deleteMemory(memory, i)
                            : _vm.accesRefused("delete")
                        }
                      }
                    },
                    [_c("span", { staticClass: "bt-trash" })]
                  ),
                  _c("div", { staticClass: "clear" })
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "big-bt memory-bt",
              on: {
                click: _vm.newMemoryMenu,
                mouseleave: function($event) {
                  $event.stopPropagation()
                  return _vm.mouseLeaveList()
                }
              }
            },
            [
              _c("span", { staticClass: "text" }, [_vm._v("Add new memory")]),
              _c("span", { staticClass: "bt-plus" }, [_vm._v("+")]),
              _c(
                "div",
                {
                  staticClass: "itemList",
                  class: [{ open: _vm.itemListOpen }, _vm.sideList],
                  style: { "max-height": _vm.listMaxHeight + "px" }
                },
                _vm._l(_vm.memoryTypes, function(parent, i) {
                  return _c(
                    "ul",
                    [
                      _c("li", { staticClass: "title" }, [
                        _c("span", [_vm._v(_vm._s(parent))])
                      ]),
                      _vm._l(_vm.typeObjects[i], function(type) {
                        return _c(
                          "li",
                          {
                            staticClass: "item",
                            on: {
                              click: function($event) {
                                _vm.checkSecretsRights(type)
                                  ? _vm.addMemory(type)
                                  : _vm.accesRefused("add")
                              }
                            }
                          },
                          [_c("span", [_vm._v(_vm._s(type.value.name))])]
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title tab-title" }, [
      _c("span", { staticClass: "col col1" }, [_vm._v("Name")]),
      _c("span", { staticClass: "col col2" }),
      _c("span", { staticClass: "col col3" }),
      _c("span", { staticClass: "col col4" }),
      _c("div", { staticClass: "clear" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }