<!-- MemoryField vue -->

<template lang="pug">

div.memory-fields-ctn(v-bind:class="{'memLink': memLink }")
	div.field-ctn
		span.text {{memoryName}} owner
		div.input-fields.groupfield
			span.memory.textinput-ctn
				span.select-arrow &gt;
				select(v-on:change="getMemoryOfSelectedParent()", v-model="currentParent")
					option(v-for="(option,i) of parentList", v-bind:value="option.value._id") {{option.value.name}}
		div.clear
	div.clear


	div.field-ctn
		span.text {{memoryName}}
		div.input-fields.groupfield(v-if="memoryList.length > 0")
			span.memory.textinput-ctn
				span.select-arrow &gt;
				select(v-on:change="valueUpdated(field)", v-model="value")
					option(v-for="(option,i) of memoryList", v-bind:value="option.value") {{option.title}}
		div.input-fields.groupfield(v-else)
			span.noresult no {{memoryName}} found
		div.clear
	div.clear

	div.field-ctn(style="margin-top:10px", v-if="!memLink")
		div.edit-bt(v-on:click.stop="openMemory(value)")
			span.label Edit {{memoryName}}



</template>

<script>

import actionManager from '../../../actions';
import * as helper from '../../../helper';

export default {

	components: { },

	props:[ 'field' , 'fieldvalue' , 'bloc', 'memLink' ],

	data() {
		return {
			currentParent : null,
			memoryList: [],
			parentList: [],
			value: null,
			// TODO: prefers pass app.state to childrens, $root or $children references could change
			// (migration from v1: this.$root)
			app: this.$root.$children[0],
			memoryName: ''
		}
	},

	created: function(){
		this.value = this.memLink ? this.fieldvalue.memoryLinked : this.fieldvalue.value;
		this.memoryName = this.field.memoryType ? this.field.memoryType : 'memory';

		this.getParents( this.bloc );
		this.getMemoryOfSelectedParent();

	},

	destroyed: function(){

	},

	watch:{

	},

	methods:{

		getParents( node ){

			let self = this;

			let isMemoryOwner = (node) => {
				return node.value.memory.findIndex( (m) => {
					return m.id === self.value
				}) !== -1 ? true : false
			};
			// if( this.value.memoryParent )
			// 	this.currentParent = this.value.memoryParent;
			
			// On parcours récursivement les parents du module à la recherche des memory auquel on a acces
			let level = node.value.type === "object" ? 'block' : node.value.level;
			let parentID = node.value['ref_' + level ]

			if( parentID ){
				// check if there is another way to access global state (via props ?)
				let parent = helper.block.getBlockById( this.app.state.project, parentID );
				if (isMemoryOwner(parent)) {
					this.currentParent = parent.value._id
				} else {
					if (this.currentParent === null) {
						this.currentParent = parentID;
					} 
				}
				this.parentList.push(parent);
				this.getParents( parent );
			}
		},

		getMemoryOfSelectedParent(){

			this.memoryList = [];

			let noParent = this.currentParent ? false : true;
			let noValue = this.value ? false : true;

			let parent = helper.block.getBlockById(this.app.state.project, this.currentParent);

			let memoryTypes = this.field.memoryType ? [ this.field.memoryType ] : null;
			if( this.memLink && Array.isArray( this.memLink ) ){
				memoryTypes = this.memLink.map( (t) => { return t.toLowerCase(); } );
			}
			else if( this.memLink && typeof this.memLink === "String" && this.memLink !== "*" ){
				memoryTypes = [ this.memLink.toLowerCase() ];
			}

			if( parent && parent.value.memory && parent.value.memory.length > 0 ){
				parent.value.memory.forEach( (mem) => {
					let memory = helper.block.getMemoryById( this.app.state.project, mem.id );
					if( memory ){
						if( memoryTypes === null || memoryTypes.indexOf( memory.value.format.toLowerCase() ) !== -1 )
							this.memoryList.push( { title: memory.value.name, value: memory.value._id } );
					}
				});
			}

			// if( noValue && this.memoryList.length > 0 )
			// 	this.value.value = this.memoryList[0].value;
			// if( this.memoryList.length == 0 )
			// 	this.value.value = null;

		},

		valueUpdated: function( fieldUpdated ){
			if( this.memLink ){
				this.$parent.memLinkUpdated( this.value );
			}
			else{
				this.$parent.valueUpdated( fieldUpdated, this.value );
			}
		},

		// getParentsMemoryIDs: function( list, node, memoryType ){
		// 	// On parcours récursivement les parents du module à la recherche des memory auquel on a acces
		// 	if( node.value.memory && node.value.memory.length > 0 ){
		// 		for (var i = 0; i < node.value.memory.length; i++) {
		// 			if( memoryType ){
		// 				// Si un type précis de memory est demandé, on vérifie le type de la memory parcourue
		// 				let mem = stores.memory.getMemoryByIDInProject( stores.config.openProject, node.value.memory[i].id );
		// 				if( mem.value.widget == memoryType )
		// 					list.push( node.value.memory[i].id );
		// 			}
		// 			else{
		// 				// Si aucun type précisé, on ajoute la memory directement
		// 				list.push( node.value.memory[i].id );
		// 			}
		// 		}
		// 	}
		// 	let parentID = stores.block.getParentsID( node );
		// 	if( parentID ){
		// 		let parent = stores.block.getEntityByIDInProject( stores.config.openProject, parentID );
		// 		this.parentList.push(parent);
		// 		this.getParentsMemoryIDs( list , parent , memoryType);
		// 	}
		// },

		openMemory: function( id ){
			if (id) {
				actionManager.trigger('inspector:loadObj', { targetId: id })
			}
		}


	}

}

</script>

<style lang="stylus">

.memory-fields-ctn
	//border-top: 1px solid rgba(0,0,0,0.3)
	//padding-top: 8px
	//margin-top: 2px
	//padding-bottom: 5px
	margin: 0px 10px 10px 0px
	padding-top: 10px
	
	.field-ctn
		position : relative
		background: var(--inspectorTargetColor)
		margin-bottom: 10px
		border-radius: 4px
		padding: 0px 10px
		text-transform: capitalize
		height: 40px

		span.text
			line-height : 40px

		.input-fields.groupfield
			width: 230px
			margin-right: -5px

			.memory.textinput-ctn
				width: 230px

				.select-arrow
					margin-top: 5px

				select
					background: var(--inputValue)

		.edit-bt
			background: var(--bgColor)
			margin-top: -10px
			height: 40px
			border-radius: 4px
			margin-left: -10px
			padding: 0
			width: calc(100% + 20px)
			.label
				font-family: 'DinBold'
				margin-top: 0
				color: var(--inputTextColor)
			
	.noresult
		font-size: 14px
		line-height: 40px

	&.memLink    
		border-top: 1px solid rgba(0,0,0,0.2);
		margin-top: 6px;
		padding-top: 3px;
		margin-bottom: 5px;
		padding-bottom: 6px;

</style>