import React from 'react';
import CommonText from '../CommonText';

/**
 * The ChooseFile UI is a wrapper for the HTML <input> tag with the type "file".
 * This UI is used as a way to select and import one or multiple files in runtime.
 * Depending on the device (desktop, android or ios) and the selected parameters,
 * it will either open a file selector, the default camera or the default dictaphone.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
 */
export default class ChooseFile extends CommonText {
    constructor() {
        super();

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        if (!event.target.files || event.target.files.length === 0) {
            this.eventHandler('error', 'No file selected or invalid file type. Check the Accepted File Type setting.');
        } else {
            const { files } = event.target;

            if (this.props.mode === 'file' && this.props.allowMultiple) {
                const filesArray = [...files];

                try {
                    const urls = filesArray.map((file) => URL.createObjectURL(file));

                    this.eventHandler('multipleObjectURLs', urls);
                    this.eventHandler('multipleBlobs', filesArray);
                } catch (err) {
                    this.eventHandler('error', `Error while importing the files: ${err}`);
                }
            } else {
                const file = files[0];

                try {
                    this.eventHandler('objectURL', URL.createObjectURL(file));
                    this.eventHandler('blob', file);
                } catch (err) {
                    this.eventHandler('error', `Error while importing the file: ${err}`);
                }
            }
        }
    }

    render() {
        const inputAttributes = {
            type: 'file',
            style: {
                display: 'none',
            },
        };

        switch (this.props.mode) {
        case 'file':
            if (this.props.fileType !== 'any') {
                inputAttributes.accept = (this.props.fileType === 'json')
                    ? '.json'
                    : `${this.props.fileType}/*`;
            }
            break;
        case 'camera':
            if (this.props.cameraType !== 'any') {
                inputAttributes.accept = `${this.props.cameraType}/*`;
            }
            inputAttributes.capture = this.props.cameraDefaultOrientation;
            break;
        case 'dictaphone':
            inputAttributes.capture = '';
            inputAttributes.accept = 'audio/*';
            break;
        }

        if (this.props.mode === 'file' && this.props.allowMultiple) {
            inputAttributes.multiple = 'multiple';
        }

        return this.wrapHelper(
            // We use a <label> (more flexible styling capabilities), the <input> tag itself is not displayed.
            <label {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
                {this.props.innerText}
                <input {...inputAttributes} onChange={this.onChange} />
            </label>
        );
    }
}
