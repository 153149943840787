import UiObject, { STATE_CHANGE, EVENT_CALLBACK } from '../../UiObject';


class UiMapOl extends UiObject {
    constructor(id, props) {
        super(id, props, 'MapOL', false);

        this.initParameters();
    }

    static getAuthConfig() {
        return {
            exportFormat: 'ui-map-ol',
            label: 'MapOL',
            parent: 'ui-maps-ol',
            isDisplayed: true,
            canBeCreated: false,
            isEmbeddable: true,
            nbSection: 1,
            childrenType: 'ui-map-ol-layer',
        };
    }


    initParameters() {
        // map styles
        const groupGeneral = this.createInspectorNode('group', 'general', 'General');
        groupGeneral.children.push(this.createInspectorNode('group-field', 'general-fields'));

        const groudChildhood = this.createInspectorNode('group', 'general', 'Childhood');
        groudChildhood.hideForObjInspector = true;
        groudChildhood.children.push(this.createInspectorNode('group-field', 'parents-fields'));
        groudChildhood.children.push(this.createInspectorNode('group-field', 'childs-fields'));

        const groupViewport = this.createInspectorNode('group', 'viewport', 'Viewport');
        groupViewport.children.push(this.createInspectorNode('group-field', 'viewport-fields-style'));
        groupViewport.children.push(this.createInspectorNode('group-field', 'viewport-fields-size'));
        groupViewport.children.push(this.createInspectorNode('group-field', 'viewport-fields-position'));

        const groupControl = this.createInspectorNode('group', 'control', 'Control');
        groupControl.children.push(this.createInspectorNode('group-field', 'control-fields-1'));
        groupControl.children.push(this.createInspectorNode('group-field', 'control-fields-2'));
        groupControl.children.push(this.createInspectorNode('group-field', 'control-fields-nav'));

        const groupAnimation = this.createInspectorNode('group', 'animation', 'Animation');
        groupAnimation.children.push(this.createInspectorNode('group-field', 'animation-fields'));

        const groupEvent = this.createInspectorNode('group', 'events', 'Events');
        groupEvent.children.push(this.createInspectorNode('group-field', 'event-fields-loaded'));
        groupEvent.children.push(this.createInspectorNode('group-field', 'event-fields-position'));

        const stylesTopic = this.createInspectorNode('topic', 'styles', 'Map Styles');
        stylesTopic.children = [
            groupGeneral,
            groudChildhood,
            groupViewport,
            groupControl,
            groupAnimation,
            groupEvent,
        ];

        this.inspector.push(stylesTopic);


        // // cluster options
        // let groupClusterSettings = this.createInspectorNode('group', 'settings', 'Settings');
        // groupClusterSettings.children.push(this.createInspectorNode('group-field', 'cluster-settings'));

        // let groupClusterStyles = this.createInspectorNode('group', 'styles', 'Styles');
        // groupClusterStyles.children.push(this.createInspectorNode('group-field', 'cluster-styles'));

        // let clusterTopic = this.createInspectorNode('topic', 'cluster', 'Cluster Options');
        // clusterTopic.children = [groupClusterSettings, groupClusterStyles];

        // this.inspector.push(clusterTopic);

        const commonParameters = {
            // GENERAL
            children: {
                type: 'Mixed',
                default: [],
                partial: null,
                auth: {
                    label: '',
                    container: 'childs-fields',
                    invisible: true,
                },
            },

            parent: {
                type: 'String',
                // method: 'addToParent',
                callable: true,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'parents-fields',
                    widget: 'calculated',
                    label: 'Parent ref',
                },
            },
            addLayer: {
                type: 'String',
                method: 'addChild',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'childs-fields',
                    widget: 'calculated',
                    label: 'Add Layer',
                },
            },
            removeLayer: {
                type: 'String',
                method: 'removeChild',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'childs-fields',
                    widget: 'calculated',
                    label: 'Remove Layer',
                },
            },
            removeAllLayers: {
                type: 'String',
                method: 'removeAllChildren',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    container: 'childs-fields',
                    widget: 'calculated',
                    label: 'Remove all Layers',
                },
            },

            visibility: {
                type: 'Boolean',
                default: true,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Visibility',
                    container: 'general-fields',
                },
            },

            lock: {
                type: 'Boolean',
                default: false,
                partial: null,
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Lock',
                    container: 'general-fields',
                },
            },

            customTags: {
                type: 'String',
                default: '',
                partial: null,
                method: 'setCustomTags',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Add tags',
                    container: 'general-fields',
                },
            },
            removeTags: {
                type: 'String',
                default: '',
                partial: null,
                method: 'removeTags',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Remove tags',
                    container: 'general-fields',
                },
            },

        };

        const mapParameters = {
            // Add param to positionning common settings
            loaded: {
                type: 'Mixed',
                default: null,
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: true },
                },
                auth: {
                    label: 'Map Loaded',
                    container: 'event-fields-loaded',
                },
            },

            tileloaded: {
                type: 'Mixed',
                default: null,
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Map Tiles Loaded',
                    container: 'event-fields-loaded',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'osm' }],
                },
            },
            imageLoaded: {
                type: 'Mixed',
                default: null,
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Map Image Loaded',
                    container: 'event-fields-loaded',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'image' }],
                },
            },
            imageError: {
                type: 'Mixed',
                default: null,
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Map Image Error',
                    container: 'event-fields-loaded',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'image' }],
                },
            },

            positionClicked: {
                type: 'Geo',
                default: { lat: 0, lng: 0 },
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Position Clicked',
                    container: 'event-fields-position',
                },
            },
            positionDoubleClicked: {
                type: 'Geo',
                default: { lat: 0, lng: 0 },
                event: true,
                connection: {
                    in: { pluggable: false, default: false, disabled: true },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Position Double Clicked',
                    container: 'event-fields-position',
                },
            },

            pixelRatio: {
                type: 'Float',
                default: 1,
                partial: 'map',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Pixel Ratio',
                    description: 'Sets the pixel ratio used to display the map.',
                    container: 'viewport-fields-style',
                },
            },

            mapBackground: {
                type: 'String',
                default: 'osm',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Map Background',
                    description: 'Sets the background type of the map: OpenStreetMap tiles or image.',
                    container: 'viewport-fields-style',
                    widget: 'select',
                    options: [
                        { value: 'osm', label: 'OpenStreetMap tiles' },
                        { value: 'image', label: 'Image' },
                    ],
                },
            },
            mapBackgroundImage: {
                type: 'Asset',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Background Image',
                    description: 'Sets the image applied to the Map as background.',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'image' }],
                    widget: 'asset',
                },
            },
            projectionStartPoint: {
                type: 'Vector2',
                default: {x: 0, y: 0},
                partial: 'projection',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Projection Starting Point',
                    description: 'Sets the starting point of the area covered by the projection. The minimum x and the minimum y of this area, represented by the left/bottom point.',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'image' }],
                },
            },
            projectionEndPoint: {
                type: 'Vector2',
                partial: 'projection',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Projection Ending Point',
                    description: 'Sets the ending point of the area covered by the projection. The maximum x and the maximum y of this area, represented by the top/right point.',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'image' }],
                },
            },
            projectionUnit: {
                type: 'String',
                default: 'pixels',
                partial: 'projection',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Projection Units',
                    description: 'Sets the units of the map projection: degrees, ft, m, pixels, tile-pixels or us-ft.',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'image' }],
                    widget: 'select',
                    options: [
                        { value: 'degrees', label: 'Degrees' },
                        { value: 'ft', label: 'Feet' },
                        { value: 'm', label: 'Meters' },
                        { value: 'pixels', label: 'Pixels' },
                        { value: 'tile-pixels', label: 'Tile Pixels' },
                        { value: 'us-ft', label: 'US Feet' },
                    ],
                },
            },

            // @todo: deprecated: replaced by backgroundVisibility to be used with any background layer
            tileVisibility: {
                type: 'Boolean',
                default: true,
                partial: 'tile',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Show Tiles',
                    description: 'Defines whether tiles should be visible or not.',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'osm' }],
                },
            },
            // @todo: deprecated: replaced by backgroundOpacity to be used with any background layer
            tileOpacity: {
                type: 'Float',
                default: 1.0,
                partial: 'tile',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Tiles Opacity',
                    description: 'Sets the opacity of the tiles.',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'osm' }],
                },
            },

            mapStyle: {
                type: 'String',
                default: '',
                partial: 'map',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Map Style',
                    container: 'viewport-fields-style',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'osm' }],
                },
            },

            textures: {
                type: 'Array',
                connection: null,
                auth: {
                    container: 'viewport-fields-style',
                    label: 'Textures',
                    description: 'texture to load at map initialization. Textures can be used to display markers (inside clustered layer & custom style param).',
                    conditions: [
                        { field: 'moduleMode', operator: '!=', value:'update' },
                        { field: 'mapBackground', operator: '==', value: 'osm' },
                    ],
                    pattern: [
                        { name: 'name', type: 'String', widget: 'string' },
                        { name: 'textures', type: 'Asset', widget: 'asset', assetType: 'image' },
                    ],
                },
            },

            fullWidth: {
                type: 'Boolean',
                default: false,
                partial: 'map',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Full Width (deprecated)',
                    container: 'viewport-fields-size',
                },
            },
            width: {
                type: 'DisplayUnit',
                default: '380px',
                partial: 'viewport',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Width',
                    container: 'viewport-fields-size',
                    conditions: [{ field: 'fullWidth', value: false, operator: '==' }],
                },
            },
            fullHeight: {
                type: 'Boolean',
                default: false,
                partial: 'map',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Full Height (deprecated)',
                    container: 'viewport-fields-size',
                },
            },
            height: {
                type: 'DisplayUnit',
                default: '250px',
                partial: 'viewport',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Height',
                    container: 'viewport-fields-size',
                    conditions: [{ field: 'fullHeight', value: false, operator: '==' }],
                },
            },


            latitude: {
                type: 'Float',
                default: 48.8574825,
                partial: 'viewport',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Latitude',
                    description: 'The north–south position of the map center on the Earth\'s surface',
                    container: 'viewport-fields-position',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'osm' }],
                },
            },
            longitude: {
                type: 'Float',
                default: 2.3318665,
                partial: 'viewport',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Longitude',
                    description: 'The east–west position of the map center on the Earth\'s surface',
                    container: 'viewport-fields-position',
                    conditions: [{ field: 'mapBackground', operator: '==', value: 'osm' }],
                },
            },
            zoom: {
                type: 'Float',
                default: 2,
                partial: 'viewport',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Zoom',
                    description: 'The map zoom level.',
                    container: 'viewport-fields-position',
                },
            },
            angle: {
                type: 'Angle',
                default: 0,
                partial: 'viewport',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: true, default: false },
                },
                auth: {
                    label: 'Angle',
                    description: 'The map rotation angle in degrees (0 for true north).',
                    container: 'viewport-fields-position',
                },
            },

            minZoom: {
                type: 'Int',
                default: 0,
                partial: 'viewport',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Min Zoom',
                    container: 'control-fields-1',
                },
            },
            maxZoom: {
                type: 'Int',
                default: 20,
                partial: 'viewport',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Max Zoom',
                    container: 'control-fields-1',
                },
            },

            scrollZoom: {
                type: 'Boolean',
                default: true,
                partial: 'mapcontrol',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false, disabled: true },
                },
                auth: {
                    label: 'Allow Scroll Zoom',
                    description: 'Whether mousewheel zoom is desired.',
                    container: 'control-fields-2',
                },
            },
            dragPan: {
                type: 'Boolean',
                default: true,
                partial: 'mapcontrol',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false, disabled: true },
                },
                auth: {
                    label: 'Allow Drag Pan',
                    description: 'Whether drag pan is desired.',
                    container: 'control-fields-2',
                },
            },
            allowRotation: {
                type: 'Boolean',
                default: true,
                partial: 'mapcontrol',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false, disabled: true },
                },
                auth: {
                    label: 'Allow Rotation',
                    description: 'Whether map rotation is desired (pinch on mobile, Alt+Shift+drag on desktop).',
                    container: 'control-fields-2',
                },
            },
            doubleClickZoom: {
                type: 'Boolean',
                default: true,
                partial: 'mapcontrol',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false, disabled: true },
                },
                auth: {
                    label: 'Allow Double Click Zoom',
                    description: 'Whether double click zoom is desired.',
                    container: 'control-fields-2',
                },
            },

            // Nav
            displayNavigation: {
                type: 'Boolean',
                default: true,
                partial: 'map',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Display Navigation Control',
                    container: 'control-fields-nav',
                },
            },
            positionNavigation: {
                type: 'String',
                default: 'right',
                partial: 'map',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Position Navigation Control',
                    container: 'control-fields-nav',
                    widget: 'select',
                    options: [{ value: 'left', label: 'Left' }, { value: 'right', label: 'Right' }],
                },
            },
            marginNavigation: {
                type: 'Int',
                default: 10,
                partial: 'map',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Margin Navigation Control',
                    container: 'control-fields-nav',
                    unit: 'px',
                },
            },


            transitionDuration: {
                type: 'Int',
                default: 0,
                partial: 'map',
                connection: {
                    in: { pluggable: true, default: false },
                    out: { pluggable: false, default: false },
                },
                auth: {
                    label: 'Transition Duration',
                    container: 'animation-fields',
                    unit: 'ms',
                },
            },
            // TODO: add different interpolation
            // transitionInterpolator: {
            //     type: 'String',
            //     default: 'easeTo',
            //     partial: 'map',
            //     connection: {
            //         in: { pluggable: true, default: false },
            //         out: { pluggable: false, default: false },
            //     },
            //     auth: {
            //         label: "Transition Interpolator",
            //         container: "animation-fields",
            //         widget: "select",
            //         options: [{ value: 'easeTo', label: 'Ease To' }],
            //     }
            // },
            // transitionEasing: {
            //     type: 'String',
            //     default: 'linear',
            //     partial: 'map',
            //     connection: {
            //         in: { pluggable: true, default: false },
            //         out: { pluggable: true, default: false },
            //     },
            //     auth: {
            //         widget: 'select',
            //         options: [
            //             { value: 'linear', label: 'linear' },
            //             { value: 'easeIn', label: 'easeIn' },
            //             { value: 'easeOut', label: 'easeOut' },
            //             { value: 'inAndOut', label: 'inAndOut' },
            //             { value: 'upAndDown', label: 'upAndDown' },
            //         ],
            //         label: 'Transition Easing',
            //         container: 'animation-fields',
            //         description: 'The easing function used during the animation (see https://openlayers.org/en/latest/apidoc/module-ol_easing.html).'
            //     },
            // },


        };

        // let clusterParameters = {

        //     enable: {
        //         type: 'Boolean',
        //         default: true,
        //         partial: 'cluster',
        //         connection: null,
        //         auth: {
        //             label: "Use Cluster",
        //             container: "cluster-settings",
        //             description: "Do clustering with markers."
        //         }
        //     },
        //     distance: {
        //         type: 'Int',
        //         default: 20,
        //         partial: 'cluster',
        //         connection: {
        //             in:  { pluggable: true, default: false },
        //             out: { pluggable: true, default: false }
        //         },
        //         auth: {
        //             label: "Distance",
        //             container: "cluster-settings",
        //             description: "Minimum distance in pixels between clusters."
        //         }
        //     },


        //     useTexture: {
        //         type: 'Boolean',
        //         default: false,
        //         partial: 'cluster',
        //         connection: null,
        //         auth: {
        //             label: "Use Texture",
        //             container: "cluster-styles",
        //             description: "Use Map container texture as icon."
        //         }
        //     },

        //     iconSize: {
        //         type: 'Float',
        //         default: 10,
        //         partial: 'cluster',
        //         connection: {
        //                in: { pluggable: true, default: false },
        //                out: { pluggable: false, default: false },
        //            },
        //         auth: {
        //             label: "Icon Size",
        //             container: "cluster-styles",
        //             description: "Default cluster icon size.",
        //             conditions: [{ field: 'useTexture', value: false, operator: '==' }]
        //         }
        //     },
        //     iconColor: {
        //         type: 'Color',
        //         default: { hex: "#FFFFFF" },
        //         partial: 'cluster',
        //         connection: {
        //             in: { pluggable: true, default: false },
        //             out: { pluggable: false, default: false },
        //         },
        //         auth: {
        //             label: "Icon Color",
        //             description: "Default cluster icon color.",
        //             container: "cluster-styles",
        //             conditions: [{ field: 'useTexture', value: false, operator: '==' }]
        //         }
        //     },


        //     texture: {
        //         type: 'String',
        //         default: '',
        //         partial: 'cluster',
        //         connection: {
        //             in: { pluggable: true, default: false },
        //             out: { pluggable: false, default: false },
        //         },
        //         auth: {
        //             label: "Texture Reference",
        //             description: "Texture to use to display the cluster (ref to textures parameter in Map container)",
        //             container: "cluster-styles",
        //             conditions: [{ field: 'useTexture', value: true, operator: '==' }]
        //         }
        //     },
        //     textureSize: {
        //         type: 'Float',
        //         default: 1,
        //         partial: 'cluster',
        //         connection: {
        //             in: { pluggable: true, default: false },
        //             out: { pluggable: false, default: false },
        //         },
        //         auth: {
        //             label: "Texture Size",
        //             description: "Cluster texture size.",
        //             container: "cluster-styles",
        //             conditions: [{ field: 'useTexture', value: true, operator: '==' }]
        //         }
        //     },
        //     textureAnchor: {
        //         // TODO: prefers use ArrayType with value like [0.5,0.5]
        //         type: 'String',
        //         default: "center",
        //         partial: 'cluster',
        //         connection: {
        //             in: { pluggable: false, default: false },
        //             out: { pluggable: false, default: false },
        //         },
        //         auth: {
        //             label: "Texture Anchor",
        //             description: "Icon anchor position inside the cluster.",
        //             container: "cluster-styles",
        //             widget: "select",
        //             options: [
        //                 { value: 'center', label: 'center' },
        //                 { value: 'left', label: 'left' },
        //                 { value: 'right', label: 'right' },
        //                 { value: 'top', label: 'top' },
        //                 { value: 'bottom', label: 'bottom' },
        //                 { value: 'top-left', label: 'top-left' },
        //                 { value: 'top-right', label: 'top-right' },
        //                 { value: 'bottom-left', label: 'bottom-left' },
        //                 { value: 'bottom-right', label: 'bottom-right' }
        //             ],
        //             conditions: [{ field: 'useTexture', value: true, operator: '==' }]
        //         }
        //     },

        //     useNumber: {
        //         type: 'Boolean',
        //         default: true,
        //         partial: 'cluster',
        //         connection: null,
        //         auth: {
        //             label: "Show Cluster Number",
        //             description: "Display the number of clustered markers.",
        //             container: "cluster-styles"
        //         }
        //     },
        //     numberColor: {
        //         type: 'Color',
        //         default: { hex: "#333333" },
        //         partial: 'cluster',
        //         connection: {
        //            in: { pluggable: true, default: false },
        //            out: { pluggable: false, default: false },
        //        },
        //         auth: {
        //             label: "Number Color",
        //             description: "Cluster number color.",
        //             container: "cluster-styles",
        //             conditions: [{ field: 'useNumber', value: true, operator: '==' }]
        //         }
        //     },
        //     numberOffset: {
        //         type: 'Vector2',
        //         default: { x: 0, y: 0 },
        //         partial: 'cluster',
        //         connection: {
        //            in: { pluggable: true, default: false },
        //            out: { pluggable: false, default: false },
        //        },
        //         auth: {
        //             label: "Number Offset",
        //             description: "Cluster number offset in pixels relative to center.",
        //             container: 'cluster-styles',
        //             conditions: [{ field: 'useNumber', value: true, operator: '==' }]
        //         }
        //     },
        //     fontSize: {
        //         type: 'Int',
        //         default: 12,
        //         partial: 'cluster',
        //         connection: {
        //            in: { pluggable: true, default: false },
        //            out: { pluggable: false, default: false },
        //        },
        //         auth: {
        //             label: "Font Size",
        //             description: "Cluster number font size.",
        //             container: "cluster-styles",
        //             conditions: [{ field: 'useNumber', value: true, operator: '==' }]
        //         }
        //     },
        //     fontFamily: {
        //         type: 'String',
        //         default: 'sans-serif',
        //         partial: 'cluster',
        //         connection: {
        //            in: { pluggable: true, default: false },
        //            out: { pluggable: false, default: false },
        //         },
        //         auth: {
        //             label: "Font Family",
        //             description: "Cluster number font family.",
        //             container: "cluster-styles",
        //             widget: 'select',
        //             options: [
        //                 { value: 'inherit', label: 'Inherit' },
        //                 { value: 'ComfortaaLight', label: 'Comfortaa' },
        //                 { value: 'Roboto', label: 'Roboto' },
        //                 { value: 'YaahowuLight', label: 'Yaahowu Light' },
        //                 { value: 'YaahowuBold', label: 'Yaahowu Bold' },
        //                 { value: 'PoppinsRegular', label: 'Poppins Regular' },
        //                 { value: 'PoppinsBold', label: 'Poppins Bold' },
        //                 { value: 'DINNextLTPro-Regular', label: 'DIN Next Regular' },
        //                 { value: 'DINNextLTPro-Bold', label: 'DIN Next Bold' },
        //                 { value: 'bemboregular', label: 'Bembo Regular' },
        //                 { value: 'bembobold', label: 'Bembo Bold' },
        //                 { value: 'bemboitalic', label: 'Bembo Regular Italic' },
        //                 { value: 'bembobold_italic', label: 'Bembo Bold Italic' },
        //                 { value: 'DinCondensed', label: 'DIN Condensed' },
        //                 { value: 'DinBoldCondensed', label: 'DIN Bold Condensed' },
        //                 { value: 'Georgia', label: 'Georgia' },
        //                 { value: 'Arial', label: 'Arial' },
        //                 { value: 'Verdana', label: 'Verdana' },
        //                 { value: 'Helvetica', label: 'Helvetica' },
        //                 { value: 'serif', label: 'Serif' },
        //                 { value: 'sans-serif', label: 'Sans-Serif' },
        //                 { value: 'monospace', label: 'Monospace' },
        //                 { value: 'GalanoRegular', label: 'Galano Regular' },
        //                 { value: 'GalanoBold', label: 'Galano Bold' }
        //             ],
        //             conditions: [{ field: 'useNumber', value: true, operator: '==' }]
        //         }
        //     }
        // }

        this.mapOL = null;
        this.mergedViewport = {};
        this.updateRequired = false;

        this.addToParameters(commonParameters);
        this.addToParameters(mapParameters);
        // this.addToParameters(clusterParameters);
    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(),
    // or remove this comment. since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init(); }

    init() {
        super.init();

        this.mapIsLoaded = false;

        this.set('mapRefCallback', this.mapRefCallback.bind(this));
        this.set('mapTileLoadedCallback', this.mapTileLoadedCallback.bind(this));
        this.set('mapImageLoadedCallback', this.mapImageLoadedCallback.bind(this));
        this.set('mapImageErrorCallback', this.mapImageErrorCallback.bind(this));
        this.set('viewportUpdateCallback', this.viewportUpdate.bind(this));
        this.set('viewportAnimationStartedCallback', this.viewportAnimationStarted.bind(this));
        this.set('positionClickedCallback', this.positionClickedCallback.bind(this));
        this.set('positionDoubleClickedCallback', this.positionDoubleClickedCallback.bind(this));

        this.mergedViewport = { ...this.get('viewport') };
        this.previousValues = this.mergedViewport;
        this.set('mergedViewport', this.mergedViewport);
        
        const texturesProp = this.get('textures', true);
        if (texturesProp && Array.isArray(texturesProp)) {
            texturesProp.forEach(row => {
                this.getMedia(row[1].value).then((media) => {
                    row[1].value = (media && media.url) ? media.url : media;
                })
                .catch((err) => {
                    console.error(err);
                });
            })
        }
    }

    mapRefCallback(map) {
        this.mapOL = map;
    }

    mapTileLoadedCallback(evt) {
        if (!this.mapIsLoaded) {
            this.emit(EVENT_CALLBACK, { name: 'loaded', value: this.id });
            this.mapIsLoaded = true;
        } else this.emit(EVENT_CALLBACK, { name: 'tileloaded', value: this.id });
    }

    mapImageLoadedCallback(evt) {
        if (!this.mapIsLoaded) {
            this.emit(EVENT_CALLBACK, { name: 'loaded', value: this.id });
            this.mapIsLoaded = true;
        }
        this.emit(EVENT_CALLBACK, { name: 'imageLoaded', value: this.id });
    }

    mapImageErrorCallback(evt) {
        this.emit(EVENT_CALLBACK, { name: 'imageError', value: this.id });
    }

    viewportAnimationStarted() {
        this.mergedViewport.viewportUpdated = false;
        this.set('mergedViewport', this.mergedViewport);
    }

    viewportUpdate(viewport) {
        // output should be triggered only on change
        const latlonHasChanged = true;
        const zoomHasChanged = true;
        const angleHasChanged = true;

        const radToDeg = (rad) => Math.round(rad * (180 / Math.PI));
        const duration = this.get('transitionDuration', true);

        this.mergedViewport = {
            latitude: viewport.latitude,
            longitude: viewport.longitude,
            zoom: viewport.zoom,
            angle: viewport.angle,
            viewportUpdated: false,
            updated: new Set(),
        };

        this.set('mergedViewport', this.mergedViewport);

        if (latlonHasChanged) {
            this.emit(EVENT_CALLBACK, { name: 'latitude', value: viewport.latitude });
            this.emit(EVENT_CALLBACK, { name: 'longitude', value: viewport.longitude });
        }
        if (zoomHasChanged) this.emit(EVENT_CALLBACK, { name: 'zoom', value: viewport.zoom });
        if (angleHasChanged) this.emit(EVENT_CALLBACK, { name: 'angle', value: radToDeg(viewport.angle) });
    }

    positionClickedCallback(point) {
        this.emit(EVENT_CALLBACK, { name: 'positionClicked', value: point });
    }

    positionDoubleClickedCallback(point) {
        this.emit(EVENT_CALLBACK, { name: 'positionDoubleClicked', value: point });
    }

    get(prop, ignoreMerged = false) {
        const isViewportProp = !!((this.parameters[prop] && this.parameters[prop].partial === 'viewport'));
        const isMergedViewport = this.mergedViewport && this.mergedViewport[prop] !== undefined;
        if (ignoreMerged === false && isViewportProp && isMergedViewport) {
            return this.mergedViewport[prop];
        }
        return super.get(prop);
    }

    set(prop, value, partial = false) {
        super.set(prop, value, partial);

        const isViewportProp = !!((this.parameters[prop] && this.parameters[prop].partial === 'viewport'));
        if (isViewportProp) {
            const rawvalue = this.get(prop, true);
            if (this.previousValues && rawvalue !== this.previousValues[prop]) {
                const update = { viewportUpdated: true };
                update[prop] = rawvalue;

                this.mergedViewport = { ...this.mergedViewport, ...update };
                if (this.mergedViewport.updated) this.mergedViewport.updated.add(prop);
                this.set('mergedViewport', this.mergedViewport);

                this.emit(STATE_CHANGE, this.render());
                this.previousValues[prop] = rawvalue;
            }
        }
    }


    addChild(child, section = null, pos = null) {
        if (!child) return;

        if (typeof child === 'string') {
            if (!this.checkAssetManager()) return;
            child = this.assetManager.get(child, this.ctx);
            if (!child || (Array.isArray(child) && child.length === 0)) return;
        }

        super.addChild(child, section, pos);
    }

    removeAllChildren() {
        if (this.children && Array.isArray(this.children) && this.children.length > 0) {
            for (let i = this.children.length - 1; i >= 0; i--) this.removeChild(i);
        }
    }


    removeChild(child) {
        const indexChild = typeof child === 'string' ? this.children.indexOf(child) : child;
        let childRemoved = null;

        if (indexChild !== -1) {
            childRemoved = super.removeChild(indexChild);
        }

        if (this.mapOL && childRemoved && childRemoved.cmpType === 'MapOLLayer') {
            this.mapOL.removeLayer(childRemoved);
        }
    }
}

export default UiMapOl;
