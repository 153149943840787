import React from 'react';
import ChartsUi from '../ChartsUi';
import { VictoryPie } from 'victory';

class CustomLabel extends React.Component {

    render() {
        let radius = this.props.stroke * 0.5;
        return (
            <g>
                <circle r={radius} cx={this.props.x}  cy={this.props.y} fill={this.props.style.fill} />
            </g>
        );
    }
}

export default class CircularProgressBar extends ChartsUi {
    
    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            display : 'block',
        });
    }

    getFilledData( percent ){
        return [{x: 1, y: percent}, {x: 2, y: 100 - percent}]
    }

    
    getDottedData( nbDots ){

        let data = [];
        for( let i = 1 ; i < nbDots+1 ; i++ ){

            let point = { x : i , y : 100/nbDots };
            data.push( point );

        }

        return data;
    }

    getFilledStyle( color ){
        return { 
            data : {
                fill : (d) => { 
                    return d.x === 1 ? color : "transparent";  
                } , 
            },
            labels :{
                fill: "transparent"
            } 
        }
    }

    
    getDottedStyle( color, bgColor, percent, nbDots ){
        let _bgColor = bgColor ?  bgColor : "transparent";
        return {
            data : {
                fill : "transparent"
            },
            labels: { 
                fill: (d) => { 
                    return ( (d.x-1) < (nbDots*(percent/100)) ) ? color : _bgColor 
                } ,
                textAnchor: "start",
                verticalAnchor: "start"
            } 
        }
    }
    
    
    render() {

        let blockSize = this.props.radius * 2;
        let percent = this.props.percent; // * 100;

        let data = this.props.strokeStyle === "filled" ? this.getFilledData( percent ) : this.getDottedData( this.props.nbDots );

        // PROPS FOR CHART
        let otherChartProps = {
            width : blockSize, height : blockSize,
            standalone : false,
            innerRadius : this.props.radius-this.props.thickness,
            labels : ( (d) => "" )
        };

        if( this.props.strokeStyle === "dotted" ){
            otherChartProps.labels = ( (d) => d.y );
            otherChartProps.labelComponent = <CustomLabel stroke={this.props.thickness} /> ;
            otherChartProps.labelRadius= this.props.radius-this.props.thickness;
        }

        this.chartProps = Object.assign( {}, this.chartProps, this.props.chart, otherChartProps );
        
        // ROTATE TO SIMULATE START POINT 
        let rotate = 0;
        if( this.props.startSide == "right" )
            rotate = 90;
        if( this.props.startSide == "bottom" )
            rotate = 180;
        if( this.props.startSide == "left" )
            rotate = 270;
            
        let domStyle  = { 
            width : blockSize+3, height : blockSize+3,
            paddingTop: "3px",
            transform: "rotate("+rotate+"deg)"
         };

        this.mergedStyle = Object.assign( {}, this.mergedStyle, domStyle );


        // STROKE STYLE
        let strokeStyle = this.props.strokeStyle === "filled" ? this.getFilledStyle( this.props.strokeColor ) : this.getDottedStyle( this.props.strokeColor, this.props.strokeBackgroundColor, percent, this.props.nbDots );


        // BACKGROUND DRAW
        let backgroundColor = this.props.strokeBackgroundColor;
        let backgroundPie = null;
        if( backgroundColor != null && this.props.strokeStyle === "filled" ){

            let bgdata = this.getFilledData( 100 );

            let bgStrokeStyle = this.getFilledStyle( backgroundColor );
            
            backgroundPie = <VictoryPie {...this.chartProps} style={bgStrokeStyle} data={bgdata} />
        }
        
        // RENDER
        return this.wrapHelper(  
            <svg width={blockSize+3} height={blockSize+3} {...this.otherEvents} {...this.props.dom} style={this.mergedStyle} >
                { backgroundPie }
                <VictoryPie {...this.chartProps} style={strokeStyle} data={data}  />
            </svg>
        );

    }


}