const user = {
    // Remove the couchdb syntax for user id
    formatUserName(name) {
		return name.replace('org.couchdb.user:', '');
    },
    //check if user is a viewer for authorisation check
    isViewer(projectsList, currentProject, currentUser) {
        for (var i = 0; i < projectsList.length; i++) {
            if(projectsList[i].value._id.includes(currentProject)) {
                if (projectsList[i].value.viewers == undefined) return false
                if( projectsList[i].value.viewers.includes(currentUser)) return true
            }
        }
        return false
    }
}

export default user;
