import ActionAbstract from '../ActionAbstract';
import deepCopy from 'deepcopy';
import * as helper from '../../helper';
import Vue from 'vue';

import deepEqual from 'deep-equal';

class UpdateFields extends ActionAbstract {

    /**
     * @param id : block id to refresh
     *
    **/

    static get ID() {
        return 'actions:block:updateFields';
    }

    preRun(state) {
        // ensure opened project is available in store methods
        // should not be there but some call to store's method will throw error otherwise
        this.deps.mainStore.config.openProject = state.project;
        this.queryMode = state.inspector ? state.inspector.queryMode : false ;
    }

    run(state) {

        let blocToUpdateAnchor, composite;

        [composite, blocToUpdateAnchor] = this.updateFieldsForBloc(state, this.getParam('id'));
        // let compositeKeys = Object.keys(composite)
        if (blocToUpdateAnchor && blocToUpdateAnchor.length) {

            // let blocs = compositeKeys.map( (k) => composite[k].value._id );
            Vue.nextTick( function(){
                blocToUpdateAnchor.forEach( (blocId) => {
                    this.trigger('block:updateAnchorPositions', { id :  blocId } );
                });
            }.bind(this));
        }

        this.trigger('template:updateFields', { blocId: this.getParam('id') });
        this.trigger('block:updateCompositeName', { blocId : this.getParam('id') } );


        if (composite && Object.keys(composite).length) {
            return this.composeState(state, { project: composite });
        }
        else
            return state;

    }



    updateFieldsForBloc(state, blocId) {
        let path, block = null;
        let composite = {}, blocWithFieldUpdate = [];

        [this.block, path] = helper.block.getEntityByIDInWholeProject( state.project, blocId, true );
        if (!this.block || this.block.value._deleted) {
            return [];
        }

        // this.block = deepCopy(block);


        if ( !this.passFilter(this.block, this.getParam('filter')) ) return {};


        // @todo : use mergedInspector helper instead of merging again in getField
        let format = helper.config.mapConfigConvertion( this.block.value.format, state.xpConfig.mapLibrary )
		this.config = helper.config.getConfigByType( state.config, this.block.value.type, format );

        this._project = state.project;

        let clear = function (arrToClear = null, keysUpdated) {
            let indexToRemove = [];

            if (arrToClear !== null) {

                Object.keys(arrToClear).forEach( (key) => {
                    if (Array.isArray(arrToClear)) {
                        let searchedKey;
                        searchedKey = arrToClear[parseInt(key)].name;

                        if(keysUpdated.find( (k) => k === searchedKey) === undefined) {
                            indexToRemove.push(arrToClear[parseInt(key)]);
                        }
                    } else {
                        if(keysUpdated.find( (k) => k === key) === undefined) {
                            indexToRemove.push(key);
                        }
                    }
                });

                if (Array.isArray(arrToClear)) {
                    indexToRemove.forEach(i => {
                        let index = arrToClear.indexOf(i)
                        arrToClear.splice(index, 1)
                    });
                } else {
                    indexToRemove.forEach( i => Vue.delete(arrToClear, i) );
                }
            }
            return indexToRemove.length;
        }

        if( this.getParam('refresh') === true ){
            // force refresh of all values
            let allFields = [];
            this.getAllFieldsFromInspector( this.config.value.inspector, allFields, this.block );

            //if( this.blocks.value && this.blocks.value.includedInspector )
             //   this.getAllFieldsFromInspector( this.blocks.value.includedInspector, allFields, this.block );

            let keyFields = allFields.map( (field) => { return field.name } );

            clear(this.block.custom.fields, keyFields);
            clear(this.block.value.fields, keyFields);
        }


        let fieldsCount = 0;
        let stateUpdatedCount = 0;
        let fieldsUpdatedCount = 0;

        fieldsCount = Object.keys(this.block.custom.states || {}).length;
        let updatedStates = this.getStates();

        if (!this.block.custom.states) {
            Vue.set(this.block.custom, 'states', updatedStates);
        } else {
            let stateKeys = Object.keys(updatedStates);
            if( stateKeys.length > 0 ){
                stateKeys.forEach((key) => {
                    let state = updatedStates[key];
                    Vue.set(this.block.custom.states, key, state);
                });
            }
            else{
                Vue.set(this.block.custom, 'states', {} );
            }
            // this.block.custom.states = Object.assign({}, this.block.custom.states, updatedStates);
        }

        // clear states
        clear(this.block.custom.states, Object.keys(updatedStates));
        stateUpdatedCount += Math.abs(fieldsCount - Object.keys(this.block.custom.states).length)
        if (stateUpdatedCount === 0) {
            stateUpdatedCount += deepEqual(updatedStates, this.block.custom.states) ? 1 : 0;
        }

        fieldsCount = Object.keys(this.block.custom.fields || {}).length;
        let updatedFields = this.getFields( state );

        if (!this.block.custom.fields) {
            Vue.set(this.block.custom, 'fields', updatedFields);
        } else {
            Object.keys(updatedFields).forEach((key) => {
                let field = updatedFields[key];
                Vue.set(this.block.custom.fields, key, field);
            });
            // this.block.custom.fields = Object.assign( {}, this.block.custom.fields, fields );
        }

        // clear fields
        clear(this.block.custom.fields, Object.keys(updatedFields));
        clear(this.block.value.fields, Object.keys(updatedFields));
        fieldsUpdatedCount += Math.abs(fieldsCount - Object.keys(this.block.custom.fields).length);
        if (fieldsUpdatedCount === 0) {
            fieldsUpdatedCount += deepEqual(updatedFields, this.block.custom.fields) ? 1 : 0;
        }

        if(fieldsUpdatedCount || stateUpdatedCount) {
            blocWithFieldUpdate.push(this.block.value._id);
        }

        composite[path] = this.block;

		if( this.block.custom.objects ){
			this.block.custom.objects.forEach( (obj) => {
                if( !obj.value._deleted ){
                    let objInspector = helper.config.getConfigByType(state.config, obj.value.type, obj.value.format );

                    if( !objInspector.value.isDisplayed ){
                        this.trigger( 'block:updateFields', { id : obj.value._id });
                    }
                }
            } );
        }

        let tmpComposite = { };
        let tmpblocWithFieldUpdate = [];
        if (this.getParam('recursive')) {
            if( this.block.custom.children ){
                this.block.custom.children.forEach( (child) => {
                    let result = this.updateFieldsForBloc(state, child.value._id);
                    Object.assign(tmpComposite, result[0]);
                    tmpblocWithFieldUpdate.concat(result[1]);
                } );
            }
        }

        return [Object.assign(composite, tmpComposite), blocWithFieldUpdate.concat(tmpblocWithFieldUpdate)]
    }



    postRun() {
        let parentId = this.block.value['ref_' + this.block.value.level];
        if( parentId !== undefined ) {
            this.trigger('editor:schematic:checkLinks', { bloc_id: parentId, child_id: this.getParam('id') } );
        }

    }


    passFilter(bloc, filter = {}) {
        let testFail = false,
            keys = Object.keys(filter);

        for (let i=0; i<keys.length; i++) {
            let key = keys[i];
            if (typeof filter[key] === 'object' && typeof bloc[key] === 'object') {
                testFail = this.passFilter(bloc[key], filter[key]);
            } else {
                testFail = bloc[key] !== filter[key]
            }

            if (testFail)
                break;
        }

        return !testFail;
    }

    getFieldData( field, data ){
        let value = Object.assign( {}, field, { pluggable: field.connection });

        if (data !== null) {
            Object.assign( value, data, {
                formatedVal: data && data.value !== null ? this.getFormatedValue(data.value, field) : null,
            } );
        }
        return value;
    }

    createFieldInData(meta, withDefaultValue = true ) {
        let data = {
            name: meta.name,
            in: ( meta.connection && meta.connection.in.pluggable === true && meta.connection.in.default === true ) ? true : false,
            out: ( meta.connection && meta.connection.out.pluggable === true && meta.connection.out.default === true ) ? true : false,
        }

        if( withDefaultValue )
            data.value = this.getDefaultValue( meta );
        if (meta.widget === 'asset') {
            data.isMedia = true;
        }

        this.block.value.fields.push(data);

        return data;
    }

    getDefaultValue( meta ) {
        // do not assign array to object, this could break the correct field value.
        // If this conditionn is a source of issues for existing project or some field requirements, 
        // uncommnent the temporary hack in the NodeMemoriesList.vue file (nodeMemoriesInspector method) 
        // and remove the Array.isArray() condition.
        // since v3.2.0, [ticket NS-453] enable memory type Array in node's inspector
        if (meta.default !== null && typeof meta.default === "object" && !Array.isArray(meta.default)) {
            return Object.assign( {}, meta.default );
        }
        return meta.default;
    }

    extractStates( ref_states , status = true ){

        let states = {};

        let sides = Object.keys( ref_states );
        sides.forEach( (side) => {
            ref_states[side].forEach( (state) => {
                if( !states[ state ] ){
                    let data = {
                        type: 'state',
                        label: null,
                        default: null,
                        connection: null
                    }

                    let dataToKeep = {};
                    if( this.block.custom && this.block.custom.states && this.block.custom.states[state] ){
                        dataToKeep.anchorPos = this.block.custom.states[state].anchorPos;
                        dataToKeep.order = this.block.custom.states[state].order;
                    }

                    data = Object.assign( data , dataToKeep );

                    states[ state ] = this.getFieldData( data , null);
                }
                states[ state ][ side ] = status;
            });

        });

        return states;
    }

    getStates(){

        let states = null;

        if( this.block.value.type === "process" ){
            let templateField = this.block.value.fields.find( (f) => { return f.name === "template" });
            if( templateField && templateField.value === true )
                return this.extractStates( this.block.value.states, false );
        }

        if( this.block.value.states && this.block.value.type != "trigger" ){
            states = this.extractStates( this.block.value.states );
        }

        // Fix for old process/screen block, states are not initialized into the block so we need to get them from the config.
        if( states === null && this.config && this.config.value.states ){
            states = this.extractStates( this.config.value.states );
        }

        return states === null ? {} : states;

    }


    filterFields(state, fields, block, inspector, includedField = null, mergeInspectors = false) {
        let allFields = [];
        this.getAllFieldsFromInspector( inspector, allFields, block );
        allFields.forEach( (field) => {
            let relatedMemory;

            if(block && !block.value.type.includes('template-')) {
                if(field.name.includes('mem-')){
                    let memId = field.name.split('mem-')[1];
                    let memory = helper.block.getMemoryById(state.project, memId);
                    if (memory) relatedMemory = memory.value.fields.filter(field => field.name === "value");
                    else console.warn(`The memory '${field.label}'[${memId}] is attached has reference for the block '${block.value.name}'[${block.value._id}] of type '${block.value.type}' but could not be found in the project.`);
                }
                if(relatedMemory && field) field.default = relatedMemory[0].value
            }

            let dataToKeep = {};
            if( block.custom && block.custom.fields && block.custom.fields[field.name] ){
                dataToKeep.anchorPos = block.custom.fields[field.name].anchorPos;
                dataToKeep.order = block.custom.fields[field.name].order;
            }

            // should use a helper function not a store (cf preRun)

            let value = field.default;

            let fieldInData = helper.block.getField( this.block, field.name );

            if(relatedMemory && fieldInData) fieldInData.value = relatedMemory[0].value

            if (fieldInData === null) {
                let defaultValue = includedField !== null || this.queryMode === true ? false : true;
                fieldInData = this.createFieldInData(field, defaultValue);
            }

            // check if the values in field of type array are of type asset
            if(field.pattern && !(field.hasOwnProperty("isMedia"))){
                field.pattern.forEach((row) => {
                    if(row.widget === "asset") field["isMedia"] = true
                });
            }
            // check is field need to add "isMedia" property
            if( field.isMedia === true && fieldInData.isMedia !== true )
                fieldInData.isMedia = field.isMedia;

            if( fieldInData.value === null && includedField === null && this.queryMode === false )
                fieldInData.value = this.getDefaultValue( field );

            /*if( fieldInData.value === undefined && includedField !== null ){
                let fieldInIncludedField = helper.block.getField( includedField, field.name );
                if( fieldInIncludedField && fieldInIncludedField.value !== undefined )
                    fieldInData.value = fieldInIncludedField.value;
            }*/
            let fieldData = this.getFieldData( field, fieldInData )

            // !!! IMPORTANT: merge inspectors to allow some field conditions
            // outside their own inspector (e.g. the 'translate' field in UiMapOlMarker.js)
            if (mergeInspectors && this.config && this.config.value.inspector.children) {
                let commonInspectorChildren = this.config.value.inspector.children;
                let objectInspectorChildren = inspector.children;

                let allChildren = {children: []};

                allChildren.children = commonInspectorChildren.concat(objectInspectorChildren);

                fieldData.display = this.deps.mainStore.block.checkFieldCondition(block, field, null, allChildren);
            } else {
              fieldData.display = this.deps.mainStore.block.checkFieldCondition(block, field, null, inspector);
            }
            fieldData = Object.assign( dataToKeep, fieldData );

            fields[ field.name ] = fieldData;
        });
    }


    getFields( state ){

        let fields = {};
        if( this.config )
           this.filterFields( state, fields, this.block, this.config.value.inspector );

        //!\ includedInspector deprecated : use included includedBlockType instead /!\\
        if (this.block.value.includedInspector) {
            this.block.value.includedInspector.forEach( (insp) => {
                if (insp) {
                    this.filterFields(state, fields, this.block, insp );
                }
            });
        }
        //!\ ----------------------------------------------------------- deprecated /!\\

        if( this.block.value.includedBlockType ){


            let types = this.block.value.includedBlockType === undefined ? [] : [].concat( this.block.value.includedBlockType );

            types.forEach( (type) => {

                type = helper.config.mapConfigConvertion( type , state.xpConfig.mapLibrary );

                let objInspector = helper.config.getConfigByType(  state.config , 'object', type);
                let subInspector = objInspector && objInspector.value.inspector ? objInspector.value.inspector : null;
                if( subInspector ){
                    this.filterFields(state, fields, this.block, subInspector, null, true );
                }

            });

        }


        let mergeIds = this.block.value.includedBlocks === undefined ? [] : [].concat( this.block.value.includedBlocks );

        mergeIds.forEach( ( id ) => {
            if (id !== null) {
                let subBlock = helper.block.getEntityByIDInWholeProject( state.project, id );
                if (!subBlock) {
                    console.error(`The subBlock referenced by ${id} does not exists in '${this.block.value.name}'`);
                    this.trigger('main:modal:openModal', { title: 'References are missing', text: 'An error occurred : some references are missing in one or more modules on this schematic. Please remove this module and create a new one or select a new object.', continueButton: 'OK', cancelButton: '', icon: 'notfound' })
                    //this.trigger('block:updateIncludedBlocks', { id: this.block.value._id, includedBlocks: null, invalid: true });
                }
                else {
                    let format = helper.config.mapConfigConvertion( subBlock.value.format , state.xpConfig.mapLibrary );
                    let subConfig = subBlock && subBlock.value ? helper.config.getConfigByType( state.config, subBlock.value.type, format ) : null;

                    if( subBlock && subConfig && subConfig.value.inspector ){
                        this.filterFields(state, fields, this.block, subConfig.value.inspector, subBlock );
                        this.trigger('block:updateFields' , { id : id } );
                    }
                    else{
                        this.trigger('main:modal:openModal', {title:'Included object not found', text:'An error occured : the targetted object inside '+this.block.value.name+' module has been deleted. You should to select a new one.', continueButton: 'OK', cancelButton: '', icon: 'notfound' })
                        this.trigger('block:updateIncludedBlocks', { id : this.block.value._id, includedBlocks: null });
                    }
                }
            }
        });

        return fields;
    }

    getAllFieldsFromInspector( inspectorNode, allFields, subject ){
        if( inspectorNode && inspectorNode.children && inspectorNode.children.length > 0 ){

            for( var child of inspectorNode.children ){

                if((child.type == "param" || child.type == "field") && child.widget != 'repeated-form'){
                    //this.setConnection( child );
                    allFields.push( child );
                }

                if( child.type == 'array-row-field' && child.target ){
                    let target = helper.block.getField( subject, child.target );
                    if( target && target.value && target.value != '' ){
                        let array = helper.block.getArrayRowCols( this._project, target.value );
                        if (child.mode && child.mode == "rows") { // ArrayColumnModule
                            if( array && array.value && Array.isArray( array.value )){
                                for (var i = 0; i < array.value.length; i++) {
                                    let field = helper.block.createArrayRowColField( target.value+"_"+i , array.value[i].name || i, 'Row' );

                                    //this.setConnection( field );
                                    allFields.push( field );
                                }
                            }
                        } else { // ArrayRowModule
                            if( array && array.header && Array.isArray( array.header )){
                                for (var i = 0; i < array.header.length; i++) {
                                    let field = helper.block.createArrayRowColField( target.value+"_"+i , array.header[i].name || i, 'col' );

                                    //this.setConnection( field );
                                    allFields.push( field );
                                }
                            }
                        }
                    }
                }

                if( child.widget == "repeated-form" ){
                    // Get all repeated values of this bloc
                    let repeatedValues = this.deps.mainStore.block.getRepeatedValues( subject , child.name );

                    repeatedValues.forEach( (field) => {
                        let newField = Object.assign( {}, child.form[field.formName] );
                        newField.name = field.name;
                        newField.repeatedField = true;
                        newField.parentField = field.parentField;
                        newField.formName = field.formName;

                        // Add an index to the original label for better readability
                        newField.label = newField.label + ' #' + field.index;
                        if( child.form[field.formName] && child.form[field.formName].widget.toLowerCase() === "asset" ){
                          newField.isMedia = true;
                        }//BUG


                        //this.setConnection( newField ); // Check if this field has a connection in ou out possible
                        allFields.push( newField );
                    });
                }

                if( child.children ){
                    this.getAllFieldsFromInspector(child, allFields, subject ); // Recursive function to parse the all setting
                }
            }
        }
    }

    getFormatedValue( value, field ){
        let result = ""+value;
        if (value) {
            switch(field.widget.toLowerCase()) {
                case "vector2":
                    result = "("+value.x+","+value.y+")";
                    break;
                case "vector3":
                    result = "("+value.x+","+value.y+","+value.z+")";
                    break;
                case "euler":
                    result = "("+value.alpha+","+value.beta+","+value.gamma+")";
                    break;
                case "geo":
                    result = "("+value.lat+","+value.lng+")";
                    break;
                case "color":
                    result = value.hex;
                    break;
                case "percent":
                    let percentValue = value * 100
                    result = parseFloat(percentValue.toFixed(2))
                    break;
            }
        } else if (!value) {
            if (field.widget === "boolean") result = "false";
        }

        return result;
    }

}

export default UpdateFields;
