var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "memory-fields-ctn", class: { memLink: _vm.memLink } },
    [
      _c("div", { staticClass: "field-ctn" }, [
        _c("span", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.memoryName) + " owner")
        ]),
        _c("div", { staticClass: "input-fields groupfield" }, [
          _c("span", { staticClass: "memory textinput-ctn" }, [
            _c("span", { staticClass: "select-arrow" }, [_vm._v(">")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentParent,
                    expression: "currentParent"
                  }
                ],
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.currentParent = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      return _vm.getMemoryOfSelectedParent()
                    }
                  ]
                }
              },
              _vm._l(_vm.parentList, function(option, i) {
                return _c("option", { domProps: { value: option.value._id } }, [
                  _vm._v(_vm._s(option.value.name))
                ])
              }),
              0
            )
          ])
        ]),
        _c("div", { staticClass: "clear" })
      ]),
      _c("div", { staticClass: "clear" }),
      _c("div", { staticClass: "field-ctn" }, [
        _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.memoryName))]),
        _vm.memoryList.length > 0
          ? _c("div", { staticClass: "input-fields groupfield" }, [
              _c("span", { staticClass: "memory textinput-ctn" }, [
                _c("span", { staticClass: "select-arrow" }, [_vm._v(">")]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value,
                        expression: "value"
                      }
                    ],
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.value = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.valueUpdated(_vm.field)
                        }
                      ]
                    }
                  },
                  _vm._l(_vm.memoryList, function(option, i) {
                    return _c("option", { domProps: { value: option.value } }, [
                      _vm._v(_vm._s(option.title))
                    ])
                  }),
                  0
                )
              ])
            ])
          : _c("div", { staticClass: "input-fields groupfield" }, [
              _c("span", { staticClass: "noresult" }, [
                _vm._v("no " + _vm._s(_vm.memoryName) + " found")
              ])
            ]),
        _c("div", { staticClass: "clear" })
      ]),
      _c("div", { staticClass: "clear" }),
      !_vm.memLink
        ? _c(
            "div",
            { staticClass: "field-ctn", staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "div",
                {
                  staticClass: "edit-bt",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.openMemory(_vm.value)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("Edit " + _vm._s(_vm.memoryName))
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }