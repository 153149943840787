import ServiceAbstract from './ServiceAbstract';

class Message {

    constructor () {
        this.date = (new Date()).toString();
        this.level = 0;
        this.message = "";
        this.stack = [];
    }

}

var _logPersist = true;

class EventLogger extends ServiceAbstract {

    beforeCreate() {
        this._eventsJournal = [];
        this._eventsJournalLength = 10;
    }

    static get ID() {
        return 'services:EventLogger';
    }

    static set logPersist(value) {
        _logPersist = value;
    }

    log (message, level = 0, stack = '', method = 'print') {
        this._eventsJournal.push( Object.assign(new Message(), {message, level, stack}) );
        this._eventsJournal = this._eventsJournal.splice(-this._eventsJournalLength);

        this.deps.debugOutput[method](message);
    }

    logJSON(json) {
        this.log(json, 1, '', 'printJSON');
    }

    /**
     * Format a message for actions.
     * 
     * @param {string} action - The action ID
     * @param {int} number - The action number
     * @param {string} state - The action stage
     */
    logAction( action, number, state ) {
        action = action.replace('actions:','');
        const message = `action ${number} '${action}' ... ${state}`;
        // @todo: fixme: how can we choose the level number without comments or docs. What's level 1 means ?
        // @todo: update and use the log method above to enable color in message
        //this.log(message, 1, '', 'printAction');
        this.deps.debugOutput.printAction(`%caction ${number}%c '${action}'    %c${state}`, 'color: dimgray', '', 'color: green');
    }

}

export default EventLogger;