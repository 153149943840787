var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.node.value && _vm.displayed
    ? _c("div", { staticClass: "navigator-item", class: _vm.node.value.type }, [
        _vm.checkType(_vm.node.value.type)
          ? _c(
              "div",
              {
                staticClass: "accordion-bloc",
                class: { active: _vm.editorActive },
                attrs: { id: _vm.node.value._id },
                on: {
                  click: function($event) {
                    return _vm.openEditor()
                  }
                }
              },
              [
                _c("div", { staticClass: "zone" }),
                _c("icon", {
                  attrs: { name: _vm.node.value.type.toLowerCase() }
                }),
                _c("span", { staticClass: "navigatorType" }, [
                  _vm._v(_vm._s(_vm.node.value.type))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.node.value.name,
                      expression: "node.value.name"
                    }
                  ],
                  staticClass: "invisible",
                  domProps: { value: _vm.node.value.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.node.value, "name", $event.target.value)
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "action-bts",
                    class: { "arrow-space": _vm.hasChildren }
                  },
                  [
                    _vm.node.value.type == "screen" &&
                    _vm.node.value.level != "template"
                      ? _c("div", {
                          staticClass: "action-bt bt-play",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.openScreen()
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.node.value.type != "project" &&
                    _vm.node.value.level != "template"
                      ? _c("div", {
                          staticClass: "action-bt bt-trash",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.checkSecretsRights(_vm.node.value.type)
                                ? _vm.deleteNode()
                                : _vm.accesRefused("delete")
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.node.value.type != "project" &&
                    _vm.node.value.level == "template"
                      ? _c("div", {
                          staticClass: "action-bt bt-unlink",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.unlinkNode()
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.node.value.type == "experience" &&
                    _vm.node.value.level != "template"
                      ? _c("div", {
                          staticClass: "action-bt bt-download",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.downloadProject(_vm.node.value.slug)
                            }
                          }
                        })
                      : _vm._e(),
                    _c("div", {
                      staticClass: "action-bt bt-inspector",
                      class: { active: _vm.inspectorActive },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.checkSecretsRights(_vm.node.value.type)
                            ? _vm.openInspector()
                            : _vm.accesRefused("read")
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "inspector-open-bts",
                    class: [
                      { "arrow-space": _vm.hasChildren },
                      { active: _vm.inspectorActive }
                    ]
                  },
                  [_c("div", { staticClass: "action-bt bt-inspector" })]
                ),
                _vm.hasChildren
                  ? _c("span", {
                      staticClass: "ico-arrow",
                      class: { close: !_vm.accordion.main },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.switchAccordion("main")
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.accordion.main,
                expression: "accordion.main"
              }
            ],
            staticClass: "accordion-ctn",
            class: { active: _vm.editorActive }
          },
          [
            _vm._l(_vm.uiObjects, function(obj, id) {
              return _c(
                "div",
                [
                  _c("item-ui", {
                    attrs: {
                      uiobject: obj,
                      "app-state": _vm.appState,
                      main: obj
                    }
                  })
                ],
                1
              )
            }),
            _vm.hasGroupLogic()
              ? _c(
                  "div",
                  {
                    staticClass: "accordion-bloc subgroup",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.openEditor()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "zone" }),
                    _c("icon", { attrs: { name: "logic" } }),
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Logic View")
                    ]),
                    _c("span", {
                      staticClass: "ico-arrow",
                      class: { close: !_vm.accordion.logic },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.switchAccordion("logic")
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.blockChildren, function(subnode, id) {
              return _c(
                "div",
                { class: { "accordion-ctn": _vm.hasGroupLogic() } },
                [
                  _vm.node.value.type != "process" && subnode
                    ? _c("navigator-item", {
                        attrs: { node: subnode, "app-state": _vm.appState }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            _vm.assetsList && _vm.assetsList.length
              ? _c(
                  "div",
                  {
                    staticClass: "accordion-bloc subgroup",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.switchAccordion("asset")
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "zone" }),
                    _c("icon", { attrs: { name: "assets" } }),
                    _c("span", { staticClass: "title" }, [_vm._v("Memories")]),
                    _c("span", {
                      staticClass: "ico-arrow",
                      class: { close: !_vm.accordion.asset }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.accordion.asset
              ? _c(
                  "div",
                  { staticClass: "accordion-ctn" },
                  _vm._l(_vm.assetsList, function(asset, id) {
                    return _c(
                      "div",
                      { class: { "accordion-ctn": _vm.hasAssetGroup() } },
                      [
                        asset.type == "memory" && _vm.getMemory(asset.data)
                          ? _c("navigator-item", {
                              attrs: {
                                node: _vm.getMemory(asset.data),
                                "app-state": _vm.appState
                              }
                            })
                          : _vm._e(),
                        asset.type == "object"
                          ? _c("navigator-item", {
                              attrs: {
                                node: asset.data,
                                "app-state": _vm.appState
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.hasTemplates
              ? _c(
                  "div",
                  {
                    staticClass: "accordion-bloc subgroup",
                    on: {
                      click: function($event) {
                        return _vm.switchAccordion("template")
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "zone" }),
                    _c("icon", { attrs: { name: "templates" } }),
                    _c("span", { staticClass: "title" }, [_vm._v("Templates")]),
                    _c("span", {
                      staticClass: "ico-arrow",
                      class: { close: !_vm.accordion.template }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.accordion.template
              ? _c(
                  "div",
                  { staticClass: "accordion-ctn" },
                  _vm._l(_vm.templateList, function(template, i) {
                    return _c(
                      "div",
                      { class: { "accordion-ctn": _vm.hasGroupLogic() } },
                      [
                        _c("navigator-item", {
                          attrs: { node: template, "app-state": _vm.appState }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }