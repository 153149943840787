<!--
    Popup window content for template creation
    
    @desc: Content of the template creation popup
    @require: PopupWindow.vue
    @props:
        settings    the component settings
-->
<template lang="pug">
div#template-create
    div.section
        div.content
            div.icon
                icon(:name="'template-popup-'+type")
            h3.title
                span.before-type Create a 
                span.type {{ type }} 
                span.after-type Template
            input.name(placeholder="template name", v-model="name", v-on:keyup="keyup")
            p.error {{ error }}
    div.section
        div.action
            input(v-on:click.stop="submit", :disabled="!(name && name.trim())", value="Continue", type="button")

</template>

<script>

import actionManager from '../../actions'

import Icon from  '../common/Icon.vue'

export default {

    props: ['settings'],

    components: { Icon },

    data() {
        return {
            name: this.settings.name || '',
            error: ''
        }
    },

    computed: {
        type: function() {
            return this.settings.type
        }
    },

    methods: {

        exists: function() {
            return this.settings.names.indexOf(this.name.toLowerCase())!=-1
        },

        keyup: function(e) {
            if ( e.keyCode === 13 ) this.submit()
            else this.error = this.exists() ? "Template name already existed" : ""
        },

        validate: function() {
            let name = this.name.trim()

            if ( !name ) {
                this.error = "Invalid name"
                return false
            }

            return !this.exists()
        },

        submit: function() {
            if (this.validate()) {
                let name = this.name.trim()
                actionManager.trigger('template:create', {type: this.type, name: name} )
                this.$parent.closeWindow(name)
            }
        },
        
    }
}

</script>

<style lang="stylus">
/*
    TemplateCreatePopup
     see PopupWindow for mutualized styles
*/


// #template-create

</style>
