<!-- Navigator Item vue -->

<template lang="pug">

	div.navigator-item(v-bind:class="node.value.type", v-if="node.value && displayed")

		div.accordion-bloc(v-on:click="openEditor()", v-bind:id="node.value._id", v-bind:class="{'active': editorActive}", v-if="checkType(node.value.type)")

			div.zone
			icon(:name="node.value.type.toLowerCase()")

			span.navigatorType {{node.value.type}}
			input.invisible(v-model="node.value.name")

			div.action-bts(v-bind:class="{'arrow-space':hasChildren}")
				//div.action-bt(v-if="node.value.type=='screen' && node.value.level!='template'", v-on:click.stop="openScreen()")
					icon(name="export")
				div.action-bt.bt-play(v-if="node.value.type=='screen' && node.value.level!='template'", v-on:click.stop="openScreen()")
				div.action-bt.bt-trash(v-if="node.value.type!='project' && node.value.level!='template'", v-on:click.stop.prevent="checkSecretsRights(node.value.type)?deleteNode():accesRefused('delete')")
				div.action-bt.bt-unlink(v-if="node.value.type!='project' && node.value.level=='template'", v-on:click.stop.prevent="unlinkNode()")
				div.action-bt.bt-download(v-if="node.value.type=='experience' && node.value.level!='template'", v-on:click.stop="downloadProject(node.value.slug)")
				div.action-bt.bt-inspector( v-on:click.stop.prevent="checkSecretsRights(node.value.type)?openInspector():accesRefused('read')", v-bind:class="{'active': inspectorActive}")

			div.inspector-open-bts(v-bind:class="[{'arrow-space':hasChildren}, {'active': inspectorActive}]")
				div.action-bt.bt-inspector

			span.ico-arrow(v-if="hasChildren", v-bind:class="{'close': !accordion.main}", v-on:click.stop="switchAccordion('main')")

		div.accordion-ctn(v-show="accordion.main", v-bind:class="{'active': editorActive}")

			// List UI OBJECTS
			div(v-for="(obj, id) in uiObjects")
				item-ui(:uiobject="obj", :app-state="appState", :main="obj")

			// GROUP logic
			div.accordion-bloc.subgroup(v-if="hasGroupLogic()", v-on:click.stop="openEditor()")
				div.zone
				icon(name="logic")
				span.title Logic View

				span.ico-arrow(v-bind:class="{'close': !accordion.logic}", v-on:click.stop="switchAccordion('logic')")

			// List node children
			div(v-for="(subnode,id) in blockChildren", v-bind:class="{'accordion-ctn' : hasGroupLogic()}")
				navigator-item(v-if="node.value.type != 'process' && subnode", :node="subnode", :app-state="appState")


			// GROUP assets
			div.accordion-bloc.subgroup(v-if="assetsList && assetsList.length", v-on:click.stop="switchAccordion('asset')")
				div.zone
				icon(name="assets")
				span.title Memories

				span.ico-arrow(v-bind:class="{'close': !accordion.asset}")

			div.accordion-ctn(v-if="accordion.asset")
				div(v-for="(asset,id) in assetsList", v-bind:class="{'accordion-ctn' : hasAssetGroup()}")
					navigator-item(v-if="asset.type=='memory' && getMemory(asset.data)", :node="getMemory(asset.data)", :app-state="appState")
					navigator-item(v-if="asset.type=='object'", :node="asset.data", :app-state="appState")

			// TEMPLATE
			div.accordion-bloc.subgroup(v-if="hasTemplates", v-on:click="switchAccordion('template')")
				div.zone
				icon(name="templates")
				span.title Templates
				span.ico-arrow(:class="{'close': !accordion.template}")

			div.accordion-ctn(v-if="accordion.template")
				div(v-for="(template,i) in templateList", v-bind:class="{'accordion-ctn' : hasGroupLogic()}")
					navigator-item(:node="template", :app-state="appState")


</template>

<script>

import ItemUi from  './NavigatorUiItem.vue';
import Icon from  './Icon.vue';

import actionManager from '../../actions';
import * as helper from '../../helper';

export default {

	name: 'navigator-item',

	components: { itemUi: ItemUi, Icon },

	props: ['node', 'appState'],

	data() {
		return {
			/*accordion: true,
			accordionLogic: true,
			accordionAsset: true*/
		}
	},

	computed: {

		accordion: function(){

			if( this.node && this.node.value && this.node.value.accordion )
				return this.node.value.accordion;

			actionManager.trigger('inspector:updateAccordion', { targetId : this.node.value._id, type : 'main' , state: true });

			if( this.hasGroupLogic() )
				actionManager.trigger('inspector:updateAccordion', { targetId : this.node.value._id, type : 'logic' , state: true });

			if( this.hasAssetGroup() )
				actionManager.trigger('inspector:updateAccordion', { targetId : this.node.value._id, type : 'asset' , state: true });


			return { main : true , logic : true, asset : true, template: true };
		},

		displayed: function(){
			// need to watch value.type to trigger _deleted change
			if( this.node && !this.node.value.type && this.node.value._deleted )
				return false;
			return true;
		},

		blockChildren: function(){
			let childrenArray = [];
			if( !(this.hasGroupLogic() && !this.accordion.logic) && this.node && this.node.custom.children )
				childrenArray = this.node.custom.children.filter( (block) => { if( !block.value._deleted ) return block ; return null } );

			// sort by name
			if (childrenArray.length) {
				childrenArray.sort((a, b) => {
					const A = a.value.name.toLowerCase();
					const B = b.value.name.toLowerCase();
					if (A < B) return -1;
					if (A > B) return 1;
					return 0;
				});
				return childrenArray;
			}
			
			return null;
		},

		uiObjects: function(){
			if( this.node.custom.objects ){
				let uiArray = this.node.custom.objects.filter( function(obj){
					let objSettings = helper.config.getConfigByType( this.appState.config, 'object', obj.value.format );
					if( !obj.value.deleted && obj.custom.tag == 'ui' && objSettings && objSettings.value.isDisplayed )
						return obj;
					return null;
				}.bind(this) );

				uiArray.sort( (a, b) => {
					return a.value.name > b.value.name;
				});

				return uiArray;
			}
			return [];
		},

		assetsList: function(){
			/*if( !this.hasAssetGroup() )
				return null;*/

			let assets = [];

			if( this.node.value.memory  ){
				this.node.value.memory.forEach( (mem) =>{
					assets.push( {type:'memory', data: mem });
				});
				//assets = assets.concat( this.node.value.memory );
			}

			if( this.node.custom.objects ){
				this.node.custom.objects.forEach( (obj) =>{
					if( !obj.value._deleted && obj.custom.tag != 'ui' )
						assets.push( {type:'object', data: obj });
				});
			}

			assets.sort((a, b) => {
				return a.data.name > b.data.name;
			});

			return assets;
		},

		templateList: function() {
			let list = [];
			if( this.node.templates  ){
				this.node.templates.forEach( (item) =>{
					list.push( item );
				});
			}

			list.sort((a, b) => {
				return a.value.name.toLowerCase() > b.value.name.toLowerCase();
			});

			return list;
		},

		hasChildren: function(){
			let result = false;

			if( this.node.custom.children && this.node.custom.children.length > 0 )
				result = true;

			if( this.node.value.memory && this.node.value.memory.length > 0 )
				result = true;

			if( this.node.custom.objects && this.node.custom.objects.length > 0 )
				result = true;

			return result;
		},

		hasTemplates: function() {
			return this.node.templates && this.node.templates.length;
		},

		inspectorActive: function(){
			if( this.appState.inspector && this.appState.inspector.targetId == this.node.value._id && this.appState.main.inspectorOpen )
				return true;
			if( this.node.value.type == "memory" &&  this.appState.objInspector && this.appState.objInspector.targetId == this.node.value._id && this.appState.main.objInspectorOpen )
				return true;
			return false;
		},

		editorActive: function(){
			if( this.appState.editor && this.appState.editor.targetId == this.node.value._id )
				return true;
			return false;
		}


	},

	created: function(){


	},

	methods:{
		checkSecretsRights(type){ //NS-1176 empecher l'utilisateur non owner d'avoir accès à la mémory
			if (type==="memory" &&this.node.value.format==="secret" && this.appState.project.currentUserType !=='owner') {
	
			return false
			}
			return true
		},
		accesRefused(typeAction){
			actionManager.trigger(
				'main:modal:openModal',
				{
					title:'Action Refused',
					text:`You are not the owner of this project. You do not have the permissions to ${typeAction} memories.`,
					icon:'secret'
				},
			)
		},

		openEditor:function(){
			actionManager.trigger('editor:openEditor', {id : this.node.value._id });
			actionManager.trigger('workspace:update', { id: this.node.value._id, target: this.node.value.level });
		},

		openInspector: function(){
			let action = this.node.value.type == 'memory' ? 'loadObj' : 'load';
			actionManager.trigger('inspector:'+action, {targetId : this.node.value._id });
			actionManager.trigger('workspace:update', { id: this.node.value._id, target: this.node.value.level });
		},

		deleteNode: function(){
			let memIsUsedIn;
			let blocIsMemory = false;
			if(this.node.value.type === "memory"){
				blocIsMemory = true
				memIsUsedIn = helper.block.memIsUsedIn(this.appState.project, this.node.value._id)
			}
			actionManager.trigger('main:modal:openModal', {project: this.appState.project, config: this.appState.config, blocIsMemory: blocIsMemory, title:'Are you sure you want to delete this block ?', isUsedBy: memIsUsedIn, text:'You will lost definitely all attached data', nextAction:'block:deleteBlock', nextActionParam: { id :  this.node.value._id }, icon: 'delete' })
		},

		unlinkNode: function(){
			actionManager.trigger('template:unlink', {id: this.node.value._id, name: this.node.value.name} )
		},

		checkType: function( type ){
			let typeAllowed = ['project', 'experience', 'screen', 'trigger', 'memory', 'process', 'object', 'widget'];
			if( type == 'module' || typeAllowed.indexOf(type) === -1 || (type==='memorye' && this.appState.project.currentUserType !=='owner'))
				return false;
			return true;
		},


		getMemory: function( memory ){
			let memoryData = helper.block.getMemoryById( this.appState.project, memory.id );
			return memoryData;
		},

		openScreen: function( ){
			actionManager.trigger('main:openPreview', { blocId : this.node.value._id })
		},


		hasGroupLogic: function(){
			if( this.node.custom.children && this.node.custom.children.length > 0 && ( this.node.value.type == 'screen' || this.node.value.type == 'widget' ) )
				return true;
			return false;
		},


		hasAssetGroup: function(){
			if( this.node.value.type == 'screen' )
				return true;
			return false;
		},

		switchAccordion: function( type ){
			let current = this.accordion && this.accordion[type] !== undefined ? this.accordion[type] : true;
			actionManager.trigger('inspector:updateAccordion', { targetId : this.node.value._id, type : type , state: !current  });
		},

		downloadProject: function(expName = false) {
			actionManager.trigger('main:modal:openModal', {
				title:`Download ${expName ? `Experience: ${expName}` : `Project ${this.appState.project.value.name}` }`,
				icon: 'share',
				text: '',
				download: Object.assign({experienceName: expName}, this.appState.project.value),
				cancelButton: 'none',
				continueButton: 'none',
			})
		}
	}

}

</script>

<style lang="stylus">

.accordion-bloc

	.navigatorType
		display: inline-block
		font-family: 'DinBold'
		margin-right: 5px
		text-transform: capitalize
		z-index: 0

	&.active
		color: white

	&.deprecated
		background-image: url(../../../assets/textureDeprecated.png) !important;
		background-size: contain;

	input.invisible
		z-index : 5

</style>
