var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sound-ctn" }, [
    _c("audio", { attrs: { src: _vm.media.url, controls: "" } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }