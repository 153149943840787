import ActionAbstract from "../ActionAbstract";
import * as helper from '../../helper';
import Vue from 'vue';

class UpdateQueryMode extends ActionAbstract {

    /*
    @queryMode : Boolean
    */

    static get ID() {
        return 'actions:inspector:updateQueryMode';
    }

    run(state) {

        return this.composeState(state, {
            inspector: {
                queryMode : this.getParam('queryMode')
            }
        });

    }


}

export default UpdateQueryMode;