var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "textinput-ctn" }, [
    _vm.modeList === false
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.displayedValue,
              expression: "displayedValue"
            }
          ],
          staticClass: "value",
          attrs: { type: "text", disabled: "true" },
          domProps: { value: _vm.displayedValue },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.displayedValue = $event.target.value
            }
          }
        })
      : _c("span", [
          _c("span", { staticClass: "select-arrow" }, [_vm._v(">")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value"
                }
              ],
              attrs: { disabled: _vm.field.disabled },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.value = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function($event) {
                    return _vm.valueUpdated(_vm.field)
                  }
                ]
              }
            },
            _vm._l(_vm.nodeList, function(w, i) {
              return _c("option", { domProps: { value: w.value } }, [
                _vm._v(_vm._s(w.label))
              ])
            }),
            0
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }