import ActionAbstract from '../ActionAbstract';

class ToggleTemplate extends ActionAbstract {

	static get ID() {
		return 'actions:main:toggleTemplate';
	}

	run(state) {
		return this.composeState(state, {
			main: {
				templateOpen: this.toggle
			}
		});
	}

	toggle(state) {
		let forcedState = this.getParam('open');
		let newState = forcedState !== undefined ? forcedState : !!!state;
		return newState;
	}

}

export default ToggleTemplate;