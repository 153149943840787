var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "array-inspector" }, [
    _c("div", {
      staticClass: "array-inspector-cover",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.close()
        }
      }
    }),
    _c("div", { staticClass: "array-window" }, [
      _c("div", { staticClass: "array-centerer" }, [
        _c("div", { staticClass: "array-ctn" }, [
          _c("div", { staticClass: "array-header" }, [
            _c("table", { attrs: { cellpadding: "0", cellspacing: "0" } }, [
              _c(
                "tr",
                _vm._l(_vm.localHeader, function(col, i) {
                  return _c("td", { class: col.widget }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: col.name,
                          expression: "col.name"
                        }
                      ],
                      staticClass: "invisible",
                      attrs: { placeholder: "column " + i },
                      domProps: { value: col.name },
                      on: {
                        change: function($event) {
                          return _vm.triggerColUpdates()
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(col, "name", $event.target.value)
                        }
                      }
                    }),
                    _vm._m(0, true),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: col.widget,
                            expression: "col.widget"
                          }
                        ],
                        attrs: { disabled: !!_vm.inspector.field.pattern },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                col,
                                "widget",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function($event) {
                              return _vm.typeUpdated(i)
                            }
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { value: "int" } }, [
                          _vm._v("int")
                        ]),
                        _c("option", { attrs: { value: "float" } }, [
                          _vm._v("float")
                        ]),
                        _c("option", { attrs: { value: "string" } }, [
                          _vm._v("string")
                        ]),
                        _c("option", { attrs: { value: "boolean" } }, [
                          _vm._v("boolean")
                        ]),
                        _c("option", { attrs: { value: "percent" } }, [
                          _vm._v("percent")
                        ]),
                        _c("option", { attrs: { value: "angle" } }, [
                          _vm._v("angle")
                        ]),
                        _c("option", { attrs: { value: "color" } }, [
                          _vm._v("color")
                        ]),
                        _c("option", { attrs: { value: "vector2" } }, [
                          _vm._v("vector2")
                        ]),
                        _c("option", { attrs: { value: "vector3" } }, [
                          _vm._v("vector3")
                        ]),
                        _c("option", { attrs: { value: "asset" } }, [
                          _vm._v("asset")
                        ]),
                        _c("option", { attrs: { value: "object" } }, [
                          _vm._v("object")
                        ]),
                        _c("option", { attrs: { value: "jsontype" } }, [
                          _vm._v("JSONType")
                        ]),
                        _c("option", { attrs: { value: "geo" } }, [
                          _vm._v("Geo Point")
                        ]),
                        _c("option", { attrs: { value: "geoshape" } }, [
                          _vm._v("Geo Shape")
                        ]),
                        _c("option", { attrs: { value: "shape2d" } }, [
                          _vm._v("2D Shape")
                        ])
                      ]
                    ),
                    !_vm.inspector.field.pattern
                      ? _c("span", { staticClass: "action-col bts-ctn" }, [
                          _c(
                            "div",
                            {
                              staticClass: "action-bt bt-insert",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.insertColBefore(i)
                                }
                              }
                            },
                            [_c("span", [_vm._v("+")])]
                          ),
                          _c("div", {
                            staticClass: "action-bt bt-trash bt-trash-white",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deleteCol(i)
                              }
                            }
                          })
                        ])
                      : _vm._e()
                  ])
                }),
                0
              )
            ]),
            !_vm.inspector.field.pattern
              ? _c(
                  "div",
                  {
                    staticClass: "bt-more",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addCol()
                      }
                    }
                  },
                  [_c("span", [_vm._v("+")])]
                )
              : _vm._e()
          ]),
          _c("div"),
          _c("div", { staticClass: "array-data" }, [
            _c(
              "table",
              { attrs: { cellpadding: "0", cellspacing: "0" } },
              _vm._l(_vm.values, function(row, i) {
                return _c(
                  "tr",
                  [
                    _c("td", { staticClass: "column" }, [
                      _vm._v(_vm._s(i)),
                      _c("span", { staticClass: "action-col bts-ctn" }, [
                        _c(
                          "div",
                          {
                            staticClass: "action-bt bt-insert",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.insertRowBefore(i)
                              }
                            }
                          },
                          [_c("span", [_vm._v("+")])]
                        ),
                        _c("div", {
                          staticClass: "action-bt bt-trash bt-trash-white",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.deleteRow(i)
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._l(_vm.localHeader, function(head, col) {
                      return _c(
                        "td",
                        { class: [head.widget, { setted: row[col] != null }] },
                        [
                          head.widget == "boolean"
                            ? _c("boolean-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "string"
                            ? _c("string-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "jsontype"
                            ? _c("json-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "geoshape"
                            ? _c("string-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col],
                                  placeholder: "{GeoJSON}"
                                }
                              })
                            : _vm._e(),
                          head.widget == "shape2d"
                            ? _c("string-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col],
                                  placeholder: "[[x,y],[x,y], … [x,y]]"
                                }
                              })
                            : _vm._e(),
                          head.widget == "float"
                            ? _c("float-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "int"
                            ? _c("int-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "percent"
                            ? _c("percent-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "angle"
                            ? _c("angle-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "color"
                            ? _c("color-field", {
                                staticClass: "color",
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "vector2"
                            ? _c("vector2d", {
                                staticClass: "vector2",
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "vector3"
                            ? _c("vector3d", {
                                staticClass: "vector3",
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col]
                                }
                              })
                            : _vm._e(),
                          head.widget == "asset"
                            ? _c("asset-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col],
                                  bloc: _vm.target,
                                  parentField: _vm.inspector.field,
                                  library: _vm.library
                                }
                              })
                            : _vm._e(),
                          head.widget == "object"
                            ? _c("object-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col],
                                  bloc: _vm.target
                                }
                              })
                            : _vm._e(),
                          head.widget == "geo"
                            ? _c("geo-field", {
                                attrs: {
                                  field: _vm.createFakeField(head, i, col),
                                  fieldvalue: _vm.values[i][col],
                                  geomodalState: _vm.geomodal
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              }),
              0
            ),
            _c(
              "div",
              {
                staticClass: "bt-more",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.addRow()
                  }
                }
              },
              [_c("span", [_vm._v("+")])]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("div", { staticClass: "select-arrow" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }