var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-ctn" }, [
    _c(
      "video",
      { attrs: { controls: "" } },
      _vm._l(_vm.video.sources, function(source) {
        return _c("source", { attrs: { src: source.src, type: source.type } })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }