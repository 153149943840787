<!-- JSON vue -->
<template lang="pug">
	span.textinput-ctn
		div.edit-bt(v-on:click.stop="openJSONEditor()", :disabled="field.disabled")
			span.label Edit JSON
</template>

<script>
	import actionManager from '../../../actions';

	export default {
		components: {},
		props: ['field', 'fieldvalue'],
		data() {
			return {
				value: null,
			}
		},
		created: function() {
			this.value = this.fieldvalue ? this.fieldvalue.value : '';
		},
		methods: {
			updateCallback: function(data) {
				this.value = data;
				this.valueUpdated();
			},
			openJSONEditor: function() {
				actionManager.trigger('main:toggleJson', {
					open: true,
					from: 'FIELD',
					content: this.value,
					callback: () => this.updateCallback,
				});
			},
			valueUpdated: function() {
				this.$parent.valueUpdated(this.field, this.value);
			},
		},
	}
</script>