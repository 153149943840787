var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topmenu" }, [
    !_vm.appState.project
      ? _c("div", { staticClass: "logo" }, [
          _c("p", [_vm._v("Nodal.Studio authoring platfom")])
        ])
      : _vm._e(),
    _vm.appState.project
      ? _c("div", { staticClass: "buttons" }, [
          _c("div", { staticClass: "button", on: { click: _vm.backHome } }, [
            _c(
              "span",
              { staticClass: "icon" },
              [
                _c("icon", { attrs: { name: "HOME" } }),
                _c("span", { staticClass: "title" }, [_vm._v("Home")])
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "button", on: { click: _vm.clickSaveHandler } },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [
                  _c("icon", { attrs: { name: "SAVE" } }),
                  _c("span", { staticClass: "title" }, [_vm._v("Save")])
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "button", on: { click: _vm.clickLibrary } },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [
                  _c("icon", { attrs: { name: "LIBRARY" } }),
                  _c("span", { staticClass: "title" }, [_vm._v("Library")])
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "button", on: { click: _vm.clickTemplate } },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [
                  _c("icon", { attrs: { name: "TEMPLATE" } }),
                  _c("span", { staticClass: "title" }, [_vm._v("Template")])
                ],
                1
              )
            ]
          ),
          _c(
            "a",
            {
              staticClass: "button",
              attrs: { href: "/#/doc", target: "_blank" }
            },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [
                  _c("icon", { attrs: { name: "DOC" } }),
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Documentation")
                  ])
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              class: { unclickable: !_vm.projectHasExperience },
              on: { click: _vm.openSessionExperience }
            },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [
                  _c("icon", { attrs: { name: "SESSION" } }),
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Session Inspector")
                  ])
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "exp-play" }, [
            _c("div", { staticClass: "select" }, [
              _c(
                "span",
                {
                  staticClass: "icon-play",
                  class: { unclickable: !_vm.projectHasExperience },
                  on: { click: _vm.clickPlayExperience }
                },
                [_c("icon", { attrs: { name: "play-exp" } })],
                1
              ),
              _vm.appState.project != undefined &&
              _vm.appState.project.custom != undefined &&
              _vm.appState.project.custom.children != undefined
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value,
                          expression: "value"
                        }
                      ],
                      attrs: { id: "exp" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.value = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.appState.project.custom.children, function(exp) {
                      return _c(
                        "option",
                        { domProps: { value: exp.value._id } },
                        [_vm._v(_vm._s(exp.value.name))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c("span", { attrs: { id: "icon-arrow-down" } })
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }