var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "accordion memory-bloc" }, [
      _c(
        "div",
        {
          staticClass: "accordion-title",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.accordionEvent = !_vm.accordionEvent
            }
          }
        },
        [
          _c("span", { staticClass: "text" }, [_vm._v("Event")]),
          _c("span", {
            staticClass: "arrow",
            class: { close: !_vm.accordionEvent }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "form-content", class: { open: _vm.accordionEvent } },
        [
          _vm._l(_vm.target.value.events, function(event, i) {
            return _c("trigger-field", {
              attrs: {
                index: i,
                condition: event,
                target: _vm.target,
                project: _vm.project
              }
            })
          }),
          _vm.target.value.events.length == 0
            ? _c(
                "div",
                {
                  staticClass: "big-bt memory-bt",
                  on: { click: _vm.addEvent }
                },
                [
                  _c("span", { staticClass: "text" }, [_vm._v("Add an event")]),
                  _c("span", { staticClass: "bt-plus" }, [_vm._v("+")])
                ]
              )
            : _vm._e()
        ],
        2
      )
    ]),
    _c("div", { staticClass: "accordion memory-bloc" }, [
      _c(
        "div",
        {
          staticClass: "accordion-title",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.accordionStates = !_vm.accordionStates
            }
          }
        },
        [
          _c("span", { staticClass: "text" }, [_vm._v("States")]),
          _c("span", {
            staticClass: "arrow",
            class: { close: !_vm.accordionStates }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "form-content", class: { open: _vm.accordionStates } },
        [
          _vm._l(_vm.target.value.trigger_states, function(state, i) {
            return _c("trigger-field", {
              attrs: {
                index: i,
                condition: state,
                target: _vm.target,
                project: _vm.project
              }
            })
          }),
          _c(
            "div",
            { staticClass: "big-bt memory-bt", on: { click: _vm.addState } },
            [
              _c("span", { staticClass: "text" }, [_vm._v("Add new state")]),
              _c("span", { staticClass: "bt-plus" }, [_vm._v("+")])
            ]
          ),
          _c("div", { staticClass: "clear", staticStyle: { height: "10px" } })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }