import React from 'react';
import AdvancedUi from '../AdvancedUi';

export default class Box extends AdvancedUi {

    constructor(){
        super();

        // this object will be filled we all default & setted value of field into the styles partial
        this.defaultStyle = Object.assign( {}, {
            position: 'relative'
        });

        this.specificStyle = {
            
        };

        // merge style order :  
        // mergedStyle <== defaultStyle(parameters) < specificStyle < field props < forcedStyle(auto calculated)
    }

    render() {
        return this.wrapIntoHammer( this.wrapIntoAuthoring( 
            <canvas {...this.otherEvents} {...this.props.dom} style={this.mergedStyle}>
            </canvas>
        , 0  )) ;

    }

}