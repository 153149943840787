import ActionAbstract from '../ActionAbstract'
import * as helper from '../../helper';

class Create extends ActionAbstract {

    static get ID() {
        return 'actions:template:create'
    }

    run(state) {
        let doc, url, ref, id

        switch (this.getParam('action')) {

            case "success":
                doc = this.getParam('template')
                return this.composeState(state, {
                    isLoading: false,
                    templates: {
                        list: this.arrayPush({ id: doc.value._id, value:this.getParam('ref')})
                    }
                }) 

            case "error":
                return this.composeState(state, {
                    isLoading: false,
                    templates: {
                        error: {
                            code: this.getParam('code'),
                            message: this.getParam('message'),
                            action: 'create'
                        }
                    }
                })

            case "saveDoc":
                doc = this.getParam('template')
                url = this.deps.mainStore.config.bdd + 'nodal-template-' + doc.value._id + '/'
                this.deps.mainStore.block.saveDocument(doc, url).then(
                    response => {
                        this.trigger('template:create', { action: 'success', template: doc, ref: this.getParam('ref') })
                    },
                    error => {
                        this.trigger('template:create', { action: 'error', code: error.status, message: error.statusText })
                    },
                )
                return false

            case "saveRef":
                doc = this.getParam('template')
                ref = this.deps.mainStore.template.createNewRef(doc.value, state.user)
                url = this.deps.mainStore.config.baseURLAuth
                this.deps.mainStore.block.saveDocument({ value: ref}, url).then(
                    response => {
                        this.trigger('template:create', { action: 'saveDoc', template: doc, ref: ref })
                    },
                    error => {
                        this.trigger('template:create', { action: 'error', code: error.status, message: error.statusText })
                    },
                )
                return false

            case "createdb":
                id = this.getParam('template').value._id
                this.deps.mainStore.template.createDB(id).then(
                    response => {
                        this.trigger('template:create', { action: "saveRef", template: this.getParam('template') })
                    },
                    error => {
                        error.action = "error"
                        this.trigger('template:create', error)
                    }
                )
                return false

            default:
               
                let type = this.getParam('type')
                let name = this.getParam('name')
                let pattern = helper.config.getConfigByType(state.config, type)
                let bloc = this.deps.mainStore.block.generateNewBlockDoc(pattern, null, null)

                doc = this.deps.mainStore.template.createNewDoc(type, name)
                doc = Object.assign(bloc,doc)

                this.deps.mainStore.block.createNewDoc(doc).then((newdoc) => {
                    this.trigger('template:create', { action: "createdb", template: newdoc })
                })
                
                return this.composeState(state, {
                    isLoading: true
                })
        }
    }
}

export default Create