<!--
    Popup window component

    @desc: Base window to display contents above editor or panels
    @props: 
        component   the popup window embeded component
        settings    the component configuration
    @event:
        popup-open  no argument
        popup-close emit the component response or null
-->
<template lang="pug">
<transition appear name="fade">
div#popup-window
    div.cover-back(v-on:click.stop="abord")
    <component class="popup-content" :is="component" :settings="settings"></component>
</transition>
</template>

<script>

import actionManager from '../../actions'

export default {

    props: ['component','settings'],

    created: function() {
        this.$emit("popup-open")
        actionManager.trigger('main:popup:open')
    },

    methods: {

        abord: function() {
            this.closeWindow(null)
        },

        closeWindow: function(response) {
            this.$emit("popup-close",response)
            actionManager.trigger('main:popup:close')
        }
    }
}

</script>

<style lang="stylus">
/* PopupWindow */

// TODO: place in global styles
.fade-enter
.fade-leave-to
    opacity: 0

.fade-enter-active
.fade-leave-active
    transition: opacity .5s

#popup-window
    position: fixed
    z-index: 100
    width: 100%
    height: 100%
    top: 0
    left: 0

    .popup-content
        position: absolute
        background-color: var(--bgColor)
        width: 50%
        height: 50%
        padding: 40px
        top: calc(25% - 20px)
        left: calc(25% - 20px)
        border-radius: 10px

        // mutualized styles ----------------------------------
        display: flex
        flex-flow: column

        .section:first-child
                flex-grow: 1
                align-items: center
                display: flex

        .content
        .action
            width: 100%
            text-align: center

        .content
            .icon
                width: 96px
                height: 96px
                background: var(--modalIconColor)
                border-radius: 96px
                transform: translateY(0%)
                display: inline-block
                flex-grow: 1
                
                span
                    display: inline-block
                    background-color: #707070
                    transform: translate(0px, 0px)
            
            .title
                text-align: center
                color: var(--inputTextColor)
                font-size: 28px
                border-bottom: 1px solid var(--inputTextColor)
                padding-bottom: 8px
                margin: 8px 0
                font-family: 'DinBold'

            .message
                color: var(--inputTextColor)
                .name
                    font-style: italic
            .error
                color: var(--inputTextColor)
                height: 20px

            input
                color: var(--inputTextColor)
                background-color: var(--addElementColor)
                border: none
                width: 100%
                border-radius: 10px
                line-height: 40px
                text-align: center
                font-size: 18px
                margin-top: 10px
                font-family: 'DinRegular'
                text-transform: capitalize
                outline: none!important

        .action
            input
                display: inline-block
                text-align: center
                color: white
                background-color #F49E75
                width: 160px
                border: none
                border-radius: 8px
                cursor: pointer
                font-size: 16px
                margin: 0px
                height: 40px
                line-height: 40px
                font-family: 'DinBold'
                opacity: 1

                &.nofocus
                    opacity: 0.3
                &.nofocus:hover
                    opacity: 1

                &:disabled
                    opacity: 0.3
                    cursor: inherit
        // mutualized styles ----------------------------------

        
</style>
