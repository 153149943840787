<!-- SessionPatternList vue -->

<template lang="pug">

span.textinput-ctn
	span.select-arrow &gt;
	select(v-on:change="valueUpdated(field)", v-model="value", :disabled="field.disabled")
		option(v-for="(option,i) of options", v-bind:value="option.value") {{option.lbl}}
	
</template>

<script>

export default {

	components: { },

	props:[ 'field' , 'fieldvalue' , 'placeholder' ],

	data() {
		return {
			value: null
		}
	},

	computed : {

		'options': function(){
			// TODO: prefers pass app.state to childrens, $root or $children references could change
			// (migration from v1: this.$root)
            let sessions = this.$root.$children[0].state.project.sessions.map( (session) => {
                return { lbl : session.value.name, value : session.value.slug  };
            });

            if( sessions[0] && (this.value === undefined || this.value === null) ){
                this.value = sessions[0].value;
                this.valueUpdated( this.field, this.value );
            }

            return sessions;
		}

	},

	created: function(){
		this.value = this.fieldvalue ? this.fieldvalue.value : null;
	
		if( !this.value && this.placeholder )
			this.value = this.placeholder;
	},

	destroyed: function(){

	},

	watch:{

	},

	methods:{

		valueUpdated: function( fieldUpdated ){
			this.$parent.valueUpdated( fieldUpdated, this.value);
		}


	}

}

</script>

<style lang="stylus">
</style>