import UiObject from '../UiObject';

class UiLinearProgressBar extends UiObject {
    constructor(id, props) {

        super(id, props, 'LinearProgressBar');
        
    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-linear-progress-bar",
            label: "Linear Progress Bar",
            parent : "ui-progress",
            isDisplayed : true,
            isEmbeddable: true
        };
    }

    initParameters(){
        super.initParameters();

        let globalFieldsGroup = this.createInspectorNode('group', 'common', 'Progress properties' );
        globalFieldsGroup.children.push( this.createInspectorNode('group-field', 'progress-global-fields') );
        
        let styleFieldsGroup = this.createInspectorNode('group', 'common', 'Progress properties' );
        styleFieldsGroup.children.push( this.createInspectorNode('group-field', 'progress-style-fields') );

        let progressBarTopic = this.createInspectorNode( 'topic', 'commonChartSetting', 'Progress bar Settings' );
        progressBarTopic.children = [ globalFieldsGroup, styleFieldsGroup ];

        this.inspector.unshift( progressBarTopic );
        
        let otherParameters = {
            // Add param to positionning common settings
            value : {
                type: 'Int', 
                default: 0, 
                partial: 'input',
                connection: { in: {pluggable: true, default: true}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Value",
                    container: "progress-global-fields"
                }
            },
            min : {
                type: 'Int', 
                default: 0, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Min",
                    container: "progress-global-fields"
                }
            },
            max : {
                type: 'Int', 
                default: 100, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Max",
                    container: "progress-global-fields"
                }
            },
            color : {
                type: 'Color', 
                default: {hex:"#00bcd4"}, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Color",
                    container: "progress-style-fields"
                }
            },
            progressBackground : {
                type: 'Color', 
                default: {hex:"#bdbdbd"}, 
                partial: null,
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Background color",
                    container: "progress-style-fields"
                }
            },
            mode : {
                type: 'String', 
                default: "indeterminate", 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Mode",
                    container: "progress-style-fields",
                    options: [ {value: 'indeterminate', label: 'Indeterminate'}, {value: 'determinate', label: 'Determinate'} ],
                    widget: "select"
                }
            }
        };

        this.addToParameters( otherParameters );
        
        this.setParamInvisible( 'addChild' );
        this.setParamInvisible( 'removeChild' );

        this.setDefaultValue( 'width', '90%' );
        this.setParamInvisible( 'height' ); 

        this.setParamInvisible( 'backgroundColor' );
        this.setParamInvisible( 'backgroundImage' );
        this.setParamInvisible( 'backgroundRepeat' );
        this.setParamInvisible( 'backgroundSize' );
        this.setParamInvisible( 'backgroundWidth' );
        this.setParamInvisible( 'backgroundHeight' );
        this.setParamInvisible( 'backgroundPositionX' );
        this.setParamInvisible( 'backgroundPositionY' );
        this.setParamInvisible( 'fontFamily' );



    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }
    
    init(){
        super.init();

    }
}

export default UiLinearProgressBar;

