import UiInputFields from '../UiInputFields';

class UiSelectfield extends UiInputFields {
    constructor(id, props) {

        super(id, props, 'InputSelectField');


    }

    static getAuthConfig(){
        return {
            exportFormat : "ui-selectfield",
            label: "Select Field",
            parent : "ui-inputs",
            isDisplayed : true,
            isEmbeddable: true
        };
    }
    
    initParameters(){
        super.initParameters();

        let selectOptions = this.createInspectorNode( 'topic', 'select-options', 'Select options' );
        selectOptions.children = [ ];

        this.inspector.unshift( selectOptions );
        
        let otherParameters = {
            // Add param to positionning common settings
            value : {
                type: 'String', 
                default: null, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: true, default: true} },
                auth :  {
                    label: "Value",
                    container: "input-global-fields",
                    widget: 'calculated'
                }
            },

            nullable: {
                type: 'Boolean', 
                default: false, 
                partial: null,
                connection: { in: {pluggable: false, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Nullable value",
                    container: "input-global-fields"
                }
            },
            multiple: {
                type: 'Boolean', 
                default: false, 
                partial: 'input',
                connection: { in: {pluggable: false, default: false}, out: {pluggable: true, default: false} },
                auth :  {
                    label: "Multiple",
                    container: "input-global-fields"
                }
            },
            options:     {   
                type : 'Repeated',
                connection : {
                    in: {pluggable: false, default: false},
                    out: {pluggable: false, default: false}
                },
                auth:{ 
                    container: 'select-options',
                    label: 'Options',
                    widget: 'repeated-form',
                    form : {
                        label: { 
                            name: 'label',
                            type: 'String',
                            label: 'Label',
                            default: '',
                            widget: "string",
                            connection: { 
                                in: {pluggable: false, default: false, disabled:true},  
                                out: {pluggable: false, default: false, disabled:true  }
                            } 
                        },
                        value: { 
                            name: 'value',
                            type: 'String',
                            label: 'Value',
                            default: '', 
                            widget: "string",
                            connection: { 
                                in: {pluggable: false, default: false, disabled:true},  
                                out: {pluggable: false, default: false, disabled:true  }
                            }
                        },
                        selected: { 
                            name: 'selected',
                            type: 'Boolean',
                            label: 'Selected',
                            default: false, 
                            widget: "boolean",
                            connection: { 
                                in: {pluggable: false, default: false, disabled:true},  
                                out: {pluggable: false, default: false, disabled:true  }
                            }
                        }
                    }
                }
            },

            
            selectedItemBgColor : {
                type: 'Color', 
                default: null, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Selected bg color",
                    container: "input-style-fields"
                }
            },
            selectedItemColor : {
                type: 'Color', 
                default: { hex : "#00bcd4" }, 
                partial: 'input',
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Selected color",
                    container: "input-style-fields"
                }
            },

            fontSize: {
                type: 'Int', 
                default: 16, 
                partial: "commonStyle",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Font size",
                    container: "general-fields",
                    unit : 'px'
                }
            },
            textAlign: {
                type: 'String', 
                default: "left", 
                partial: "commonStyle",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Text align",
                    container: "general-fields",
                    widget: 'select',
                    options: [ {value:'left', label:'left'}, {value:'center', label:'center'}, {value:'right', label:'right'} ]
                }
            },
            lineHeight: {
                type: 'Int', 
                default: null, 
                partial: "commonStyle",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Line height",
                    container: "general-fields",
                    unit : 'px'
                }
            },
            letterSpacing: {
                type: 'Int', 
                default: 0, 
                partial: "commonStyle",
                connection: { in: {pluggable: true, default: false}, out: {pluggable: false, default: false} },
                auth :  {
                    label: "Letter spacing",
                    container: "general-fields",
                    unit : 'px'
                }
            },
        };

        this.addToParameters( otherParameters );

        this.disableParam( 'height' );

    }

    // HACK:TODO: check nodal-app requirements and prefers replace init() by beforeCreate(), or remove this comment.
    // since migration Vue v1 to v2 (nodal-authoring)
    beforeCreate() { this.init() }
    
    init(){
        super.init();

    }
}

export default UiSelectfield;

