var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preview-media" }, [
    _c("div", {
      staticClass: "cover-back",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.closeModal($event)
        }
      }
    }),
    _c("div", { staticClass: "modal" }, [
      _c("div", {
        staticClass: "bt-close",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.closeModal($event)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "div",
            { staticClass: "preview" },
            [
              _vm.state.mediaPreview.assetType == "image"
                ? _c("image-visu", { attrs: { media: _vm.state.mediaPreview } })
                : _vm._e(),
              _vm.state.mediaPreview.assetType == "sound"
                ? _c("sound-visu", { attrs: { media: _vm.state.mediaPreview } })
                : _vm._e(),
              _vm.state.mediaPreview.assetType == "video"
                ? _c("video-visu", { attrs: { media: _vm.state.mediaPreview } })
                : _vm._e(),
              _vm.state.mediaPreview.assetType == "file"
                ? _c("file-visu", { attrs: { media: _vm.state.mediaPreview } })
                : _vm._e()
            ],
            1
          ),
          _c("Tagging", { attrs: { library: _vm.state, tagModal: false } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }