import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';
import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

class ImportJson extends ActionAbstract {

    /**
     * @param mediaId
     * @param mediaURL
    **/

    static get ID() {
        return 'actions:block:importJson';
    }

    run(state) {

        this.trigger('main:toggleLibrary', { open : false });
        this.trigger('editor:menu:toggleMenu');

        let self = this;
        Vue.http({url: this.getParam("mediaURL") , method: 'GET'}).then(function (response) {
            self.trigger( 'block:importJson' , { subaction: "download", import : response } );
        });

        return this.composeState(state, {
            isLoading: true
        });

    }

    runDownload(state){
        let importData = this.getParam("import");

        if( importData && importData.data  && importData.data.nodalExport && importData.data.nodalExport.blocks  ){
            this.trigger('block:paste', { importFromFile: true, nodalClipboard: importData.data.nodalExport.blocks, nodalClipboardMedia: importData.data.nodalExport.media, nodalClipboardInfo: importData.data.nodalExport.info })
        }
        else{
            this.trigger('main:modal:openModal', {title:'Unable to import this file', text:'An error occured : this file does not match with the expecteed format file.', continueButton: 'OK', cancelButton: '', icon:'error' })

        }

        return this.composeState(state, {
            isLoading: false
        });
    }



}

export default ImportJson;
