import Vue from 'vue'
import App from './components/App.vue'
import Home from './components/Home.vue'
import Projects from './components/Projects.vue'
import Project from './components/Project.vue'
import Documentation from './components/Documentation.vue'

import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import VueTouch from 'vue-touch'
//import VueVideo from 'vue-video'

import ConfigPlugin from './stores/ConfigPlugin';
import DocKeyup from './directives/DocKeyup';

Vue.use(VueResource);
Vue.use(VueRouter);
Vue.use(VueTouch);
//Vue.use(VueVideo);
Vue.use(ConfigPlugin);
Vue.use(DocKeyup);


Vue.config.devtools = true


export var router = new VueRouter({
  routes: [
    {
      path: '*',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/projects',
      name: 'projects',
      component: Projects
    },
    {
      path: '/project/:projectId',
      name: 'project',
      component: Project
    },
    {
      path: '/doc',
      name: 'doc',
      component: Documentation
    },
    {
      path: '/doc/:page',
      name: 'doc-page',
      component: Documentation
    },
    {
      path: '/doc/:page/:subpage',
      name: 'doc-subpage',
      component: Documentation
    }
  ]
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

