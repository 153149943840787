import LibraryActionAbstract from './LibraryActionAbstract';

class MediaPreview extends LibraryActionAbstract {

    static get ID() {
        return 'actions:library:mediaPreview';
    }

    run(state) {

        this.trigger('main:toggleMediaPreview', { open: true });

        return this.composeState(state, {
            library: {
                mediaPreview: this.getParam('media')
            }
        });

    }

}

export default MediaPreview;