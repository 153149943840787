<template lang="pug">

div.breadcrumb(v-bind:class="{ 'navigatorOpen': !appState.main || ( appState.main && appState.main.navigatorOpen ) }")
	div#sortProjects(v-if="this.$route.path == '/projects'")
		sort-menu(:projects="this.appState.projects")
		search-bar(:projects="true" :placeholder="'Search project'")

	div.nav-bts(v-if="appState.project")
		div.bt-back(v-on:click="goBack()", v-bind:class="{'activate':history && history.length > 1 && crtHistory < history.length-1}")
			icon(:name="'arrowNext'")
		div.bt-next(v-on:click="goNext()", v-bind:class="{'activate':crtHistory > 0}")
			icon(:name="'arrowNext'")
		div.clear

	div.fil(v-if="appState.project && appState.breadcrumb")

		div.stepper.project(v-on:click="openDraw(appState.project)", v-if="appState.project.value")
			span.chevron >
			icon(:name="appState.project.value.type.toLowerCase()")
			span.text {{appState.project.value.name}}

		div.stepper.next(v-for="step of appState.breadcrumb.arborescence", v-on:click="openDraw(step)")
			span.chevron >
			icon(:name="getIcon(step)")
			span.text {{step.value.name}}

</template>

<script>

import Icon from './Icon.vue';
import { stores } from '../../stores/MainStore.js';
import actionManager from '../../actions';
import * as helper from '../../helper';
import sortMenu from './sortMenu.vue'
import SearchBar from  '../common/SearchBar.vue';

export default {

	props: ['appState'],

	components: { 
		Icon, 
		sortMenu, 
		SearchBar
	},

	data(){
		return {
			currentTarget: null,
			searchable: true,
			search: "",
		}
	},

	created: function(){
		actionManager.trigger('main:initHistory');
	},

	computed: {

		'history' : function(){
			if( this.appState.breadcrumb && this.appState.breadcrumb.history)
				return this.appState.breadcrumb.history;
			return null;
		},

		'crtHistory' : function(){
			if( this.appState.breadcrumb && this.appState.breadcrumb.crtHistory != undefined )
				return this.appState.breadcrumb.crtHistory;
			return null;
		}

	},

	methods: {

		openDraw: function( target ){
			actionManager.trigger('editor:openEditor', { id : target.value._id });
		},

		addHistory: function( vue ){
			if( this.crtHistory > 0 )
				this.history.splice(0, this.crtHistory);

			this.history.unshift( vue );
			this.crtHistory = 0;
		},

		goBack: function(){
			actionManager.trigger('main:historyBack');
			this.updateWorkspace();
		},

		goNext: function(){
			actionManager.trigger('main:historyNext');
			this.updateWorkspace();
		},

		getIcon: function( step ){
			if( step.value.type == "object" ) return "object-"+step.value.format;
			return step.value.type;
		},

		updateWorkspace: function(){
			if(helper.block.getTemplateByEntity(this.appState.project, this.history[this.crtHistory])){
				actionManager.trigger('workspace:update', { id: this.history[this.crtHistory], target: "template" });
			} else {
				actionManager.trigger('workspace:update', { id: this.history[this.crtHistory], target: "project" });
			}
		}
		
	},
}
</script>

<style lang="stylus">
#sortProjects
	display: inline-flex
	align-items: center
	height: 40px
	padding-left: 3px
	justify-content: space-between
	width: 100%
	.sortIcon
		width: 36px
		height: 36px
		background-color: white
		transform: translateY(0%)
	.sortMenu
		display: inline-flex
		align-items: center
		.searchbar
			padding-right: 2vw

.breadcrumb
	background-color: var(--breadColor)
	width: 100%
	height: 40px
	position: fixed
	top: 40px
	left: 0%
	color: white
	font-size: 14px
	line-height: 31px
	vertical-align: middle
	z-index: 80
	box-shadow: 0 0 10px var(--shadowColor)
	transition: all 0.2s ease-in-out

	&.navigatorOpen
		left: 420px
		width: calc(100% - 420px)

	.todo
		display: none

	div.sort-ctn
		float:left
		margin-left: 20px
		margin-right: 20px

	div.search
		float: right
		margin-left: 20px
		margin-right: 20px

	div.nav-bts
		float: left
		height: 40px
		margin-left: 0px
		padding-left: 10px
		&:after
			content: ""
			position: absolute
			left: 86px
			margin-top: -52px
			border-radius: 0 20px 20px 0
			z-index: 20
			width: 20px
			height: 40px
			background: var(--breadColor)
			box-shadow: 4px 0 4px rgba(0, 0, 0, 0.1)
		
		.bt-next, .bt-back
			display: inline-block
			width: 40px 
			height: 40px
			position: relative
			z-index: 10
			overflow: hidden
			cursor: pointer
			opacity: 1
			background-color: var(--breadColor)
			
			&.activate
				opacity: 1
			
			.icon
				display: inline-block
				position: absolute
				top: 6px
				left: 16px
				width: 28px
				height: 28px
				background-position: center center
				background-color: white
				transform: translate(-50%, 0)
				z-index: 0
				transition: all .1s ease
				
			&:hover
				.icon
					background-color:#F39A74
			
		.bt-back
			transform: scaleX(-1)

		.bt-next
			//background-image: url(../../assets/images/shadow.png)
			//background-size: 4px
			background-repeat: repeat-y

	div.fil
		float:left
		background-image: url(../../assets/images/shadow.png)
		background-size: 4px
		background-repeat: repeat-y

		div.stepper
			float: left
			display: block
			cursor: pointer
			padding-left: 40px
			font-size: 14px
			height: 40px
			text-decoration: none
			position: relative
			margin-right: 0px
			padding-right: 10px
			transition: all .2s ease

			&:hover > .chevron:after
				background: var(--selectedColor)
					
			.text
				display: inline-block
				line-height: 42px
				margin-left: 20px

			.icon
				width: 22px 
				height: 22px
				position: absolute
				left: 35px
				top: 50%
				background-color: white

			.chevron
				position: absolute
				color: transparent
				font-family: 'DinBold'
				position: absolute
				z-index: 10
				right: -8px
				transition: linear all .2s !important
				&:after
					content: ""
					position: absolute
					left: -2px
					width: 20px
					height: 40px
					margin-left: 0px
					border-radius: 0 20px 20px 0
					box-shadow: 4px 0 4px rgba(0, 0, 0, 0.1)
					background: var(--defaultStepColor)
					transition: all 0.2s ease-in-out
			&.next
				padding-left: 40px
				margin-right: 0
				background: var(--defaultStepColor)
				transition: all 0.2s ease-in-out
				&:hover
					background: var(--selectedColor)

			&.project
				padding-left: 50px
				margin-right: 0
				background: var(--defaultStepColor)
				transition: all 0.2s ease-in-out
				&:hover
					background: var(--selectedColor)

				.icon
					left: 35px

			&:last-child
				font-family: 'DinBold'
				background: var(--currentStepColor)
				margin-left: 0
				color: var(--inputTextColor)
				&:last-child
					font-family: 'DinBold'
					background: var(--currentStepColor)
					margin-left: 0
					color: var(--inputTextColor)

				.icon
					background-color: var(--inputTextColor)

		div.stepper:last-child
			.chevron::after
				background: var(--currentStepColor) !important
	
</style>