import ActionAbstract from '../ActionAbstract';

class Logout extends ActionAbstract {

    static get ID() {
        return 'actions:Logout';
    }

    run(state) {

        this.deps.mainStore.user.logout();

        let composite = {};
        Object.keys(state).forEach((k) => {
            composite[k] = this.removeKey();
        });

        return this.composeState(state, composite);
    }

}

export default Logout;