import ActionAbstract from '../ActionAbstract';

class ReplaceFile extends ActionAbstract {

    static get ID() {
        return 'actions:library:replaceMedia';
    }

    run(state) {
        let denied = false;

        const file = this.getParam('file');
        const media = this.getParam('target');
        const mode = this.getParam('mode');

        if (file.type && media.mimeType) {
            if (mode === 'strict') denied = (file.type !== media.mimeType); // same file type and same format}
            else denied = (file.type.split('/')[0] !== media.mimeType.split('/')[0]); // same file type but could be another codec
            
            if (denied) {
                const msg = 'Replacement denied: the selected file does not match the current file type';
                const text = mode === 'strict' ? `${msg} and format.` : `${msg}.`;
                this.trigger('main:modal:openModal', { title: 'Replace file', text, continueButton: 'OK', cancelButton: 'none', icon: 'error' });
                return false;
            }
        }

        this.deps.mainStore.lib.replaceMedia(media, file)
            .then((response) => {
                this.trigger('main:modal:openModal', { title: 'Replace file', text: response, continueButton: 'OK', cancelButton: 'none', icon: 'save' });
                this.trigger('library:mediaPreview', { media });
                this.trigger('library:fetchAssets');
            })
            .catch((e) => {
                const message = typeof e === 'object' ? e.message : e;
                this.trigger('main:modal:openModal', { title: 'Replace file', text: message, continueButton: 'OK', cancelButton: 'none', icon: 'error' });
                //this.trigger('notification', { message, type: 'error' });
                console.error('notification', { message, type: 'error' });
            });
       
        return this.composeState(state, {
            library: {
                waiting: true
            }
        });
    }
}

export default ReplaceFile;