import InspectorActionAbstract from '../InspectorActionAbstract';
import * as helper from '../../../helper' ;

class AddCondition extends InspectorActionAbstract {

    /**
     * @param targetId
     */

    static get ID() {
        return 'actions:inspector:trigger:addCondition';
    }

    runEvent (state) {

        let composite = { project: {}};

        composite.project[this._path + '.value.events'] = this.arrayPush({
            type:"event", title:"Event", params: { }, fields: []
        })

        return this.composeState(state, composite);
    }

    runState (state) {

        let composite = { project: {}};

        composite.project[this._path + '.value.trigger_states'] = this.arrayPush({ 
            type:"state", title:"State", params: { }, fields: [] 
        })

        return this.composeState(state, composite);
    }

}

export default AddCondition;