import React from 'react';
import Hammer from 'react-hammerjs';
import AuthoringOverlay from './helpers/AuthoringOverlay';


export default class NodalUi extends React.Component {
    constructor(){
        super();

        this.tevents = {};
        this.mevents = {};

        /* deprecated */
        this.touchEvents = {};
        this.otherEvents = {};
        /* --depracated-- */

        this.defaultStyle = {};
        this.specificStyle = {};
        this.forcedStyle = {};

        this.mergedStyle = {};
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.hash === undefined || nextProps.hash !== this.props.hash || nextState !== this.state || ( this.props.renderer.auth != undefined && this.props.renderer.auth.callback != undefined )
    }

    componentWillMount(){
        this.filterEvents();

        this.setDefaultStyles( this.props, this.state );
        this.manageEvents( this.props, this.state );
        this.setMergedStyles( this.props );
        this.setAuthoringStyles( this.props );
    }

    componentWillReceiveProps(){
        //this.filterEvents();
    }

    componentWillUpdate( nextProps , nextState ){
        this.setDefaultStyles( nextProps , nextState );
        this.manageEvents( nextProps, nextState );
        this.setMergedStyles( nextProps );
        this.setAuthoringStyles( nextProps );
    }

    eventHandler( name, payload ){
        if( this.props.eventCallback && name ){
            this.props.eventCallback( name, payload );
        }
    }

    meventsHandler( evtType, evt ){
        evt.persist();
        evt.preventDefault();

        let localPos = { x : 0, y : 0 };
        localPos = evt.nativeEvent ? { x : evt.nativeEvent.offsetX , y : evt.nativeEvent.offsetY } : { x : evt.clientX , y : evt.clientY };

        this.eventHandler( 'mevents_'+evtType+'-trigger', true );
        this.eventHandler( 'mevents_'+evtType+'-triggerObjRef', this.props._id );
        this.eventHandler( 'mevents_'+evtType+'-position', localPos );
    }

    nativeTeventsHandler( evtType, evt ){
        //evt.persist();
        //evt.preventDefault();

        let localPos = { x : 0, y : 0 };
        if( evt.nativeEvent instanceof TouchEvent ){
            let touch = evt.nativeEvent.touches ? evt.nativeEvent.touches[0] : null;

            if( touch == null )
                touch = evt.nativeEvent.changedTouches ? evt.nativeEvent.changedTouches[0] : null; // for onTouchEnd

            if( touch != null ){
                localPos =  { x : touch.clientX , y : touch.clientY };
            }
        }

        this.eventHandler( 'mevents_'+evtType+'-trigger', true );
        this.eventHandler( 'mevents_'+evtType+'-triggerObjRef', this.props._id );
        this.eventHandler( 'mevents_'+evtType+'-position', localPos );
    }

    teventsHandler( evtType, evt ){
        evt.preventDefault();

        let localPos = evt.srcEvent ? { x: evt.srcEvent.offsetX, y: evt.srcEvent.offsetY} : { x : evt.center.x , y : evt.center.y };
        this.eventHandler( 'tevents_'+evtType+'-trigger', true );
        this.eventHandler( 'tevents_'+evtType+'-triggerObjRef', this.props._id );
        this.eventHandler( 'tevents_'+evtType+'-position', localPos );

        let isFirst = evt.isFirst;
        let isFinal = evt.isFinal;
        if( evtType === 'onPinch' && evt.scale){
            this.eventHandler( 'tevents_'+evtType+'-scale',  evt.scale );
            isFirst = evt.srcEvent.type === "pointerdown" ? true : false;
            isFinal = evt.srcEvent.type === "pointerup" ? true : false;
        }

        this.eventHandler( 'tevents_'+evtType+'-isFirst',  isFirst );

        this.eventHandler( 'tevents_'+evtType+'-isFinal',  isFinal );
    }

    /* DEPRECATED */
    mouseeventHandler( idEvent , evt ){
        evt.preventDefault();
        this.eventHandler( 'mouseevents_'+idEvent+'_trigger', true );
        this.eventHandler( 'mouseevents_'+idEvent+'_position', { x : evt.clientX , y : evt.clientY } );
    }

    toucheventHandler( idEvent , evt ){
        evt.preventDefault();
        this.eventHandler( 'touchevents_'+idEvent+'_trigger', true );
        this.eventHandler( 'touchevents_'+idEvent+'_position', { x : evt.center.x , y : evt.center.y } );
        this.eventHandler( 'touchevents_'+idEvent+'_isFirst',  evt.isFirst );
        this.eventHandler( 'touchevents_'+idEvent+'_isFinal',  evt.isFinal );
    }
     /* -- DEPRECATED -- */

    filterEvents( ){

        /*
        let handledTouchEvent = ['onTap', 'onDoubleTap', 'onPan', 'onPanCancel', 'onPanEnd', 'onPanStart', 'onPinch', 'onPinchCancel',
                                 'onPinchEnd', 'onPinchIn', 'onPinchOut', 'onPinchStart', 'onPress', 'onPressUp', 'onRotate', 'onRotateCancel',
                                 'onRotateEnd', 'onRotateMove', 'onRotateStart', 'onSwipe'];

        console.log( this.props );
        Object.keys(this.props).forEach((prop) => {
            if (prop.substring(0, 2) === 'on') {
                let target = null;
                if (handledTouchEvent.indexOf(prop) !== -1) {
                    target = this.touchEvents;
                } else {
                    target = this.otherEvents;
                }
                target[prop] = this._eventHandler;//this.props[prop];
            }
        });*/

        if ( this.props.mouseevents ) {
            Object.keys(this.props.mouseevents).forEach((idEvent) => {
                const event = this.props.mouseevents[idEvent];
                let handler = this.mouseeventHandler.bind(this, idEvent);
                this.otherEvents[event.type] = handler;
            });
        }

        if ( this.props.touchevents ) {
            Object.keys(this.props.touchevents).forEach((idEvent) => {
                const event = this.props.touchevents[idEvent];
                let handler = this.toucheventHandler.bind(this, idEvent);
                this.touchEvents[event.type] = handler;
            });
        }

    }

    notEmpty( val ){
        if( val != null && val !== undefined && val !== "")
            return true;
        return false;
    }


    setDefaultStyles( nextProps, nextState ){

        // set styles for UiObjects parameters

        let styles = {};

        styles.position = nextProps.position;
        if( nextProps.position == "absolute" && nextProps.screenfixed )
            styles.position = "fixed";

        styles.pointerEvents = "auto";
        if( nextProps.lock )
            styles.pointerEvents = "none";

        if( !nextProps.visibility ){  styles.display = "none";  styles.visibility = "hidden";  }
        else { styles.display =  'inline-block' ;  styles.visibility = "visible";  }


        delete this.defaultStyle.border;
        delete this.defaultStyle.borderRight;
        delete this.defaultStyle.borderLeft;
        delete this.defaultStyle.borderTop;
        delete this.defaultStyle.borderBottom;

        if( nextProps.border && nextProps.border.hasBorder ){
            let border = nextProps.border;

            let side = "border";
            if( border.borderSide != 'all' ){
                side += border.borderSide;
            }

            let color = null;
            if( typeof border.borderColor === "string" )
                color = border.borderColor;
            if( border.borderColor !== null && typeof border.borderColor === "object" )
                color = border.borderColor.hex;

            styles[side] = border.borderWeight+"px "+border.borderStyle+" "+color;
            styles.borderRadius = border.borderRadius+"px";
        }

        let backgroundURL = nextProps['backgroundImage__url']
        if( backgroundURL != null && backgroundURL != '' ){
            styles.backgroundImage = 'url('+backgroundURL+')';
        }
        else{
            styles.backgroundImage = 'transparent';
        }

        styles.backgroundSize = nextProps.backgroundSize;
        if( nextProps.backgroundSize == 'custom' || !nextProps.backgroundSize ){
            let bgWidth = nextProps.backgroundWidth ? nextProps.backgroundWidth : "auto";
            let bgHeight = nextProps.backgroundHeight ? nextProps.backgroundHeight : "auto";
            styles.backgroundSize = bgWidth+" "+bgHeight;
        }

        if( nextProps.styles && ( nextProps.styles.backgroundPositionX == '' || !nextProps.styles.backgroundPositionX )  && nextProps.backgroundPosXCustom ){
            styles.backgroundPositionX = nextProps.backgroundPosXCustom;
            delete nextProps.styles.backgroundPositionX;
        }
        if( nextProps.styles && ( nextProps.styles.backgroundPositionY == '' || !nextProps.styles.backgroundPositionY )  && nextProps.backgroundPosYCustom ){
            styles.backgroundPositionY = nextProps.backgroundPosYCustom;
            delete nextProps.styles.backgroundPositionY;
        }

        delete this.defaultStyle.boxShadow;
        if( nextProps.shadow && nextProps.shadow.boxShadowColor ){
            styles.boxShadow = nextProps.shadow.boxShadowColor+" "+nextProps.shadow.boxShadowDirection.x+"px "+nextProps.shadow.boxShadowDirection.y+"px "+nextProps.shadow.boxShadowBlur+"px ";
        }


        this.defaultStyle = Object.assign( {}, this.defaultStyle, styles );

    }


    setMergedStyles( nextProps ){

        // merge style order :
        // mergedStyle <== defaultStyle(parameters) < specificStyle < field props < forcedStyle(auto calculated)

        this.mergedStyle = Object.assign( {}, this.defaultStyle, this.specificStyle, nextProps.styles, this.forcedStyle );

        return this.mergedStyle;
    }

    setAuthoringStyles( nextProps ){

        if( !this.props.renderer.auth || !this.props.renderer.auth.callback )
            return;

        // in authoring mode : authoring container steals styles settings of the real block and replace it by static default settings
        let authBlockChild = {
            position:   "static",
            width :     "100%",
            height :    "100%"
        };

        let zIndex = this.props.nestedLevel;
        if( this.mergedStyle.position != "static" && this.mergedStyle.zIndex )
            zIndex = this.mergedStyle.zIndex;

        this.authCtnStyle = Object.assign( {}, this.mergedStyle, { zIndex : zIndex } );

        this.mergedStyle = authBlockChild;

    }

    manageEvents( nextProps, nextState ){

        this.mevents = {};
        let meventsList = Object.keys( nextProps.mevents );
        meventsList.forEach( (evt) => {
            if( nextProps.mevents[evt].size > 0 ){
                let handler;
                if( evt === "onTouchStart" || evt === "onTouchEnd" )
                    handler = this.nativeTeventsHandler.bind(this, evt);
                else
                    handler = this.meventsHandler.bind(this, evt);

                this.mevents[evt] = handler;
            }
        });

        this.otherEvents = Object.assign( {}, this.otherEvents , this.mevents );

        this.tevents = {};
        let teventsList = Object.keys( nextProps.tevents );
        teventsList.forEach( (evt) => {
            if( nextProps.tevents[evt].size > 0 ){
                let handler = this.teventsHandler.bind(this, evt);
                this.tevents[evt] = handler;
            }
        });
    }


    wrapIntoHammer( content ){
        return (
            <Hammer {...this.touchEvents} {...this.tevents} options={{ recognizers: { swipe: { velocity: 0.8 } } }} >
                {content}
            </Hammer>
        );
    }

    wrapIntoAuthoring( content , idSection = 0 ,  noRelative = false ){

        if( !this.props.renderer.auth || !this.props.renderer.auth.callback )
            return ( content );

        // use it to force authoring overlay to take all space of the current div (mostly used by container box)
        let authBlockChild = Object.assign( {}, this.authCtnStyle );

        if( noRelative ){
            authBlockChild = {
                position:   "static",
                width :     "100%",
                height :    "100%"
            };
        }

        if( this.props.renderer.auth.dragging && this.props.nbSection == 0 )
            authBlockChild.pointerEvents = 'none';

        //Allows to filter the deprecated.json file from the authoring for deprecated ui-objects
        const deprecated = this.props.renderer.deprecated;
        const className = this.props.dom.className;
        let isDeprecated = false;

        Object.keys(deprecated).forEach((i) => {
            if (i.startsWith('ui-')) {
                const objectName = i.replace('ui-', '').toLocaleUpperCase();
                if (className && (className.toUpperCase() === objectName)) {
                    isDeprecated = true
                }
            }
        })

        let authoring = this.props.renderer.auth.callback !== null ? (
            <AuthoringOverlay {...this.props.renderer.auth} _id={this.props._id} nbSection={this.props.nbSection} isDeprecated={isDeprecated} idSection={idSection} nestedLevel={this.props.nestedLevel} />
         ) : null ;

        return (
            <div style={authBlockChild} >
                {authoring}
                {content}
            </div>
        );
    }


    wrapHelper( content ){
        return this.wrapIntoHammer( this.wrapIntoAuthoring( content ) ) ;
    }


    render(){
       let content = this.props.children;
        return this.wrapIntoHammer(
            <div {...this.otherEvents} {...this.props.dom} style={this.props.styles}>
                {content}
            </div>
        );

    }

}
